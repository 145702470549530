import React, { useState, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import closeIcon from "@src/assets/ico_close.png";
import clearIcon from "@src/assets/ico_clear_filter.svg";
import checkIcon from "@src/assets/ico_check_white.svg";
import redArrowIcon from "@src/assets/ico_red_arrow.svg";
import blueCheckIcon from "@src/assets/ico_blue_check.svg";
import lineClearIcon from "@src/assets/ico_line_clear.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useFilters, initUseFilters, initYearFilter, initPriceFilter } from "@src/constants/mapRealPriceFilters";
import RangeSlider from "@src/components/seekbar/RangeSlider";
import { useGetUserRealPriceFilter, usePostUserRealPriceFilter } from "@src/hooks/map/useUserRealPriceFilter";
import AiResetConfirmModal from "@src/pages/aiFilter/AiResetConfirmModal";
import RangeSliderV2 from "@src/components/seekbar/RangeSliderV2";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import { transferToNative } from "@src/lib/WebAppBridge";
interface initUseFiltersType {
    [key: string]: boolean;
}

interface IPropsState {
    product: boolean;
    real: boolean;
    expected: boolean;
}

function RealPriceFilter() {
    const filterRootRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const [isShowBubbleText, setIsShowBubbleText] = useState<boolean>(true);
    const [priceType, setPriceType] = useState<number>(2);
    const [pyongType, setPyongType] = useState<number>(1);
    const [isConfirmModalShow, setIsConfirmModalShow] = useState(false);
    const [isActiveUse, setIsActiveUse] = useState<initUseFiltersType>(initUseFilters);
    const [changedPriceFilter, setChangedPriceFilter] = useState<any[]>([0, initPriceFilter.length - 1]);
    const [changedYearFilter, setChangedYearFilter] = useState<any[]>([0, initYearFilter.length - 1]);
    const priceXAxis = [
        "0원",
        "1천만원",
        "2천만원",
        "4천만원",
        "8천만원",
        "1억원",
        "2억원",
        "4억원",
        "8억원",
        "10억원",
        "20억원",
        "40억원",
        "80억원",
        "100억원",
        "300억원",
        "최대(300억 초과)",
    ];
    const yearXAxis = ["2015년", "2016년", "2017년", "2018년", "2019년", "2020년", "2021년", "2022년", "2023년", "현재"];
    // 가격표시 타입 / 평 타입 상수화
    const PRICE_PYONG_TYPES = ["total", "pyong", "land", "build"];

    const [hideMarkerInfo, setHideMarkerInfo] = useState<IPropsState>({
        product: false,
        real: false,
        expected: false,
    });

    useEffect(() => {
        //이전 실거래 필터 조회 후 초기값 세팅
        useGetUserRealPriceFilter().then((res: any) => {
            const {
                use: lastUse,
                price: lastPrice,
                year: lastYear,
                price_type: lastPriceType,
                pyong_type: lastPyongType,
            } = res.viewParams;
            setIsActiveUse(lastUse);
            setChangedPriceFilter(lastPrice);
            setChangedYearFilter(lastYear);
            setPriceType(lastPriceType === PRICE_PYONG_TYPES[0] ? 1 : 2);
            setPyongType(
                lastPyongType === PRICE_PYONG_TYPES[2]
                    ? 1
                    : lastPyongType === PRICE_PYONG_TYPES[3]
                        ? 2
                        : lastPriceType === PRICE_PYONG_TYPES[0]
                            ? 0
                            : 2,
            );
        });
        if (location.state) {
            console.log("222", location.state);
            setHideMarkerInfo(location.state as IPropsState);
        }
    }, []);

    useEffect(() => {
        // 첫 터치 감지시 말풍선 제거 및 한번만 실행하도록 설정
        filterRootRef.current?.addEventListener("touchstart", () => setIsShowBubbleText(false), { once: true });
    }, []);

    const handleSubmitFilter = async () => {
        let formattedUse = [] as any[];
        Object.keys(isActiveUse).filter((item, index) => {
            isActiveUse[item] && formattedUse.push((index + 1).toString());
        });
        let formattedPrice = [] as any[];
        if (changedPriceFilter.length) {
            let [start, end] = changedPriceFilter;
            // formattedPrice = [initPriceFilter[start] ?? "3000000", initPriceFilter[end]];
            formattedPrice = [start ?? 3000000, end];
        }
        let formattedYear = [] as any[];
        if (changedYearFilter.length) {
            let [start, end] = changedYearFilter;
            formattedYear = [initYearFilter[start] ?? "2023", initYearFilter[end]];
        }
        let submitFilters = {
            use: formattedUse,
            price: formattedPrice,
            year: formattedYear,
            price_type: priceType === 1 ? PRICE_PYONG_TYPES[0] : PRICE_PYONG_TYPES[1],
            pyong_type: pyongType === 0 ? "" : pyongType === 1 ? PRICE_PYONG_TYPES[2] : PRICE_PYONG_TYPES[3],
        };
        const postFilters = {
            use: isActiveUse,
            price: changedPriceFilter,
            year: changedYearFilter,
            price_type: priceType === 1 ? PRICE_PYONG_TYPES[0] : PRICE_PYONG_TYPES[1],
            pyong_type: pyongType === 0 ? "" : pyongType === 1 ? PRICE_PYONG_TYPES[2] : PRICE_PYONG_TYPES[3],
        };
        const params = {
            apiParams: submitFilters,
            viewParams: postFilters,
        };
        await usePostUserRealPriceFilter({
            apiParams: JSON.stringify(submitFilters),
            viewParams: JSON.stringify(postFilters),
        }).then(() => {
            transferToNative(JSON.stringify(params), "setRealPriceFilter");
            transferToNative("done", "closeWebView");
        }).catch((err) => {
            console.log("err >>> ", err)
        })


    };
    const handleReset = () => {
        setIsActiveUse(initUseFilters);
        setChangedPriceFilter([0, initPriceFilter[initPriceFilter.length - 1]]);
        setChangedYearFilter([0, initYearFilter[initYearFilter.length - 1]]);
        setPriceType(2);
        setPyongType(1);
    };

    return (
        <div css={realPriceFilterWrap} ref={filterRootRef}>
            <header css={headerBox}>
                <span>실거래 필터</span>
                <button
                    className="closeBtn"
                    onClick={handleSubmitFilter}
                >
                    X
                </button>
            </header>
            <section css={contents}>
                <div className="usageBox">
                    <div className="useTitleBox">
                        <h4>용도</h4>
                        <button
                            onClick={() => {
                                if (Object.values(isActiveUse).some((u) => u)) {
                                    setIsActiveUse({
                                        land: false,
                                        commercial: false,
                                        factory: false,
                                        apartment: false,
                                        efficiency: false,
                                        townhouse: false,
                                        detachedHouse: false,
                                    });
                                } else {
                                    setIsActiveUse(initUseFilters);
                                }
                            }}
                        >
                            {Object.values(isActiveUse).some((u) => u) ? (
                                <div className="btnTitleBox">
                                    <img src={lineClearIcon} alt="" />
                                    <span>모두 해제</span>
                                </div>
                            ) : (
                                <div className="btnTitleBox">
                                    <img src={blueCheckIcon} alt="" />
                                    <span className="blue_span">모두 선택</span>
                                </div>
                            )}
                        </button>
                    </div>
                    <div css={useBox}>
                        {isShowBubbleText && (
                            <div className="bubbleBox">
                                <div>
                                    토지 관련 정보만 확인하시려면
                                    <br />
                                    나머지 버튼을 해제해주세요!
                                </div>
                                <img src={redArrowIcon} alt="" />
                            </div>
                        )}
                        <ul>
                            {useFilters.map((filter) => {
                                const { idx, title, use } = filter;
                                return (
                                    <li className={use} key={use}>
                                        <span className="title">{title}</span>
                                        <div
                                            className={isActiveUse[use] ? "toggleBox active" : "toggleBox"}
                                            onClick={() => {
                                                setIsActiveUse((prev) => {
                                                    return {
                                                        ...prev,
                                                        [use]: !isActiveUse[use],
                                                    };
                                                });
                                            }}
                                        >
                                            <div className="toggleButton"></div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="priceTypeBox">
                    <h4>금액 표시</h4>
                    <div className="typeBtnBox">
                        <button
                            className={priceType === 1 ? "typeBtn active" : "typeBtn"}
                            onClick={() => {
                                setPyongType(0);
                                setPriceType(1);
                            }}
                        >
                            총액
                        </button>
                        <button
                            className={priceType === 2 ? "typeBtn active" : "typeBtn"}
                            onClick={() => {
                                setPyongType(1);
                                setPriceType(2);
                            }}
                        >
                            평당단가
                        </button>
                    </div>
                    <span className={priceType === 1 ? "subBox disabled" : "subBox"}>일반건물의 평당단가 기준 금액 선택</span>
                    <div className="typeBtnBox addition">
                        <button
                            className={pyongType === 1 ? "typeBtn active" : "typeBtn"}
                            disabled={priceType === 1}
                            onClick={() => setPyongType(1)}
                        >
                            토지면적
                        </button>
                        <button
                            className={pyongType === 2 ? "typeBtn active" : "typeBtn"}
                            disabled={priceType === 1}
                            onClick={() => setPyongType(2)}
                        >
                            건물면적
                        </button>
                    </div>
                    <span className={priceType === 1 ? "subBox disabled" : "subBox"}>
                        * 아파트의 평당단가 기준 금액은 공급면적입니다.
                        <br />
                        <span className="apartmentText">(아파트 외 집합건물의 평당단가 기준 금액은 전용면적입니다)</span>
                        <br />* 토지의 평당단가 기준 금액은 토지면적입니다.
                    </span>
                </div>
                <div className="priceBox">
                    <h4>금액</h4>
                    {/* <RangeSlider
                        xAxis={priceXAxis}
                        handleSubmit={(v: number[]) => {
                            setChangedPriceFilter(v);
                        }}
                        prevIndex={changedPriceFilter}
                    /> */}
                    <div css={inputRootBoxStyle}>
                        <div css={inputBoxStyle}>
                            <input
                                placeholder="0"
                                css={inputStyle}
                                value={changedPriceFilter[0] ? changedPriceFilter[0] : ""}
                                onChange={(evt) => {
                                    const newVal = evt.target.value;
                                    if (isNaN(Number(newVal))) {
                                        return;
                                    }
                                    const newPrice = [Number(newVal), changedPriceFilter[1]];
                                    setChangedPriceFilter(newPrice);
                                    // onChangePriceInput(newPrice);
                                }}
                            />
                            <p className="unit">만원</p>
                            <p className="stringPrice">
                                {!!changedPriceFilter[0] && `${convertNumberToKorean(changedPriceFilter[0] * 10000)}원`}
                            </p>
                        </div>
                        <p>~</p>
                        <div css={inputBoxStyle}>
                            <input
                                placeholder="최대없음"
                                css={inputStyle}
                                value={changedPriceFilter[1] ? changedPriceFilter[1] : ""}
                                onChange={(evt) => {
                                    const newVal = evt.target.value;
                                    if (isNaN(Number(newVal))) {
                                        return;
                                    }
                                    const newPrice = [changedPriceFilter[0], Number(newVal) === 0 ? null : Number(newVal)];
                                    setChangedPriceFilter(newPrice);
                                    // onChangePriceInput(newPrice);
                                }}
                            />
                            <p className="unit">만원</p>
                            <p className="stringPrice">
                                {!!changedPriceFilter[1] && `${convertNumberToKorean(changedPriceFilter[1] * 10000)}원`}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="dateBox">
                    <h4>계약일</h4>
                    <RangeSliderV2
                        xAxis={yearXAxis}
                        handleSubmit={(v: number[]) => {
                            setChangedYearFilter(v);
                        }}
                        prevIndex={changedYearFilter}
                    />
                </div>
            </section>
            <article css={buttons}>
                <button
                    className="initBtn"
                    onClick={() => {
                        setIsConfirmModalShow(true);
                    }}
                >
                    초기화
                </button>
                <button className="submitBtn" onClick={handleSubmitFilter}>
                    적용하기
                </button>
            </article>
            {isConfirmModalShow && (
                <AiResetConfirmModal
                    onClickCancel={() => {
                        setIsConfirmModalShow(false);
                    }}
                    onClickConfirm={() => {
                        handleReset();
                        setIsConfirmModalShow(false);
                    }}
                    text={"실거래필터를 초기화할까요?"}
                ></AiResetConfirmModal>
            )}
        </div>
    );
}
const realPriceFilterWrap = css`
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;
const headerBox = css`
    text-align: center;
    height: 56px;
    line-height: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: ${Common.colors.normalTextColor};
    position: sticky;
    left: 0;
    top: 0;
    background-color: ${Common.colors.white};
    z-index: 19;
    .closeBtn {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0;
        text-indent: -9999px;
        width: 24px;
        height: 24px;
        padding: 15px;
        background: url(${closeIcon}) no-repeat;
        background-position: center;
        background-size: 14px 14px;
    }
`;
const contents = css`
    /* height: 200vh; */
    /* margin-top: 56px; */

    .usageBox {
        margin-bottom: 14px;
    }

    /* 추가 작업 */
    .usageBox .useTitleBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 14px;
        position: relative;
        z-index: 10;
    }
    .btnTitleBox {
        display: flex;
        align-items: center;
        padding: 8px 0;
        height: 35px;
    }
    .btnTitleBox > span {
        padding-left: 5px;
        font-size: 14px;
    }
    .blue_span {
        color: ${Common.colors.blue};
    }
    .priceTypeBox .typeBtnBox {
        display: flex;
        align-items: center;
        padding: 14px 0 22px;
        button:first-of-type {
            margin-right: 8px;
        }
    }
    .priceTypeBox .typeBtnBox.addition {
        padding: 8px 0;
    }
    .priceTypeBox .typeBtnBox .typeBtn {
        padding: 14px 28px;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        width: 50%;
        border-radius: 8px;
        background-color: ${Common.colors.grey01};
        color: ${Common.colors.grey04};
    }
    .priceTypeBox .typeBtnBox .typeBtn:disabled {
        border-radius: 8px;
        background-color: ${Common.colors.grey02};
        color: ${Common.colors.greyPrimary};
    }
    .priceTypeBox .typeBtnBox .typeBtn.active {
        background-color: ${Common.colors.blue};
        color: ${Common.colors.white};
    }
    .priceTypeBox .subBox {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }
    .subBox.disabled {
        color: ${Common.colors.greyPrimary};
    }
    .apartmentText {
        margin-left: 5px;
    }
    .usageBox,
    .priceTypeBox,
    .priceBox,
    .dateBox {
        padding: 13px 14px 0;
    }
    .dateBox {
        padding-bottom: 90px;
    }
    h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: ${Common.colors.normalTextColor};
    }
`;

const useBox = css`
    position: relative;
    @media screen and (min-width: 330px) {
        .bubbleBox {
            position: absolute;
            left: 20%;
            top: -44px;
            background-color: ${Common.colors.white};
            font-size: 11px;
            line-height: 20px;
        }
    }
    @media screen and (max-width: 330px) {
        padding-top: 15px;
        .bubbleBox {
            position: absolute;
            left: 14%;
            top: -8px;
            background-color: ${Common.colors.white};
            font-size: 9px;
            line-height: 13px;
        }
    }
    .bubbleBox > div {
        border: 1px solid #ff0000;
        color: #ff0000;
        border-radius: 50px;
        padding: 2px 8px;
    }
    .bubbleBox > img {
        padding-left: 37%;
        margin-top: -8%;
        height: 28px;
        rotate: 19deg;
    }
    li {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    li .title {
        display: block;
        position: relative;
        padding-left: 26px;
    }
    li .title::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        filter: drop-shadow(0.881701px 0.881701px 0.881701px rgba(0, 0, 0, 0.14));
        position: absolute;
        left: 0;
        top: 0;
    }
    li.land .title::before {
        background-color: ${Common.colors.landColor};
    }
    li.commercial .title::before {
        background-color: ${Common.colors.commercialColor};
    }
    li.factory .title::before {
        background-color: ${Common.colors.factoryColor};
    }
    li.apartment .title::before {
        background-color: ${Common.colors.apartmentColor};
    }
    li.efficiency .title::before {
        background-color: ${Common.colors.efficiencyColor};
    }
    li.townhouse .title::before {
        background-color: ${Common.colors.townhouseColor};
    }
    li.detachedHouse .title::before {
        background-color: ${Common.colors.detachedHouseColor};
    }
    .toggleBox {
        width: 46px;
        height: 24px;
        border-radius: 14px;
        background: ${Common.colors.grey03} url(${clearIcon}) no-repeat 80% 50%;
        position: relative;
        &.active {
            background: ${Common.colors.blue} url(${checkIcon}) no-repeat 20% 50%;
        }
    }
    .toggleButton {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: ${Common.colors.white};
        position: absolute;
        top: 2px;
        left: 2px;
        right: unset;
    }
    .toggleBox.active .toggleButton {
        right: 2px;
        left: unset;
    }
`;

const buttons = css`
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
    width: 100%;
    padding: 13px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    background: ${Common.colors.white};
    button {
        width: 50%;
        padding: 14px 28px;
        color: ${Common.colors.white};
        border-radius: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        white-space: nowrap;
        &.initBtn {
            background-color: ${Common.colors.grey04};
        }
        &.submitBtn {
            background: ${Common.colors.apartmentColor};
            /* background: linear-gradient(
                339.46deg,
                #5f0080 -26.96%,
                #5f0080 7.62%,
                #7b0ba2 47.28%,
                #ae00eb 104.97%,
                #e183ff 165.24%
            ); */
        }
    }
`;

const inputRootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 14px;
    width: 100%;
    box-sizing: border-box;
`;

const inputBoxStyle = css`
    position: relative;
    & > .unit {
        position: absolute;
        top: 50.1%;
        right: 10px;
        transform: translate(0%, -50%);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    & > .stringPrice {
        position: absolute;
        bottom: -24px;
        right: 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }
`;

const inputStyle = css`
    padding: 10px 14px;
    padding-right: 40px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    box-sizing: border-box;
    width: 100%;
    text-align: right;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    ::placeholder {
        color: #8d8d8d;
    }
`;

export default RealPriceFilter;