import React from "react";

import { dialogTitleStyle, dialogContentStyle } from "./styles";

const CancelTicket = () => {
    return (
        <>
            <p css={dialogTitleStyle}>이용권 충전을 취소하시겠습니까?</p>
            <p css={dialogContentStyle}>
                결제액이 환불되며 이용권 충전이
                <br />
                취소됩니다
            </p>
        </>
    );
};

export default CancelTicket;
