import React, { SetStateAction, useEffect, useState } from "react";
import { useTable } from "react-table";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface TableContentsProps {
    color: "white" | "gray";
    columns: any;
    data: any;
    dataList: any;
    setIsOpened: React.Dispatch<SetStateAction<boolean>>;
}

function TableContents({ color, columns, data, dataList, setIsOpened }: TableContentsProps) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });
    useEffect(() => {
        if (dataList?.length && data?.length) {
            if (dataList.length <= data.length) {
                setIsOpened(true);
            }
        }
    }, [data]);

    return (
        <>
            <table {...getTableProps()} css={tableContentsStyle}>
                <thead css={[tableHeaderStyle, colors[color]]}>
                    {headerGroups.map((header, idx) => (
                        <tr {...header.getHeaderGroupProps()} key={idx} css={tableHeaderRowStyle}>
                            {header.headers.map((col, idx) => (
                                <th {...col.getHeaderProps()} key={idx}>
                                    {col.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} css={tableBodyStyle}>
                    {rows.length ? (
                        rows.map((row, idx) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={idx} css={tableBodyRowStyle}>
                                    {row.cells.map((cell, idx) => (
                                        <td {...cell.getCellProps()} key={idx}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })
                    ) : (
                        <tr css={tableBodyRowStyle}>
                            <td colSpan={columns.length} style={{ textAlign: "center", width: "100%", display: "block" }}>
                                정보가 존재하지 않습니다
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

const colors: { [index: string]: any } = {
    white: css`
        background-color: ${Common.colors.white};
        border-bottom: 0.6px solid ${Common.colors.bgGrey03};
        box-sizing: border-box;
    `,
    gray: css`
        background-color: #fbf8f8;
        border: none;
        box-sizing: border-box;
    `,
};

const tableContentsStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    & > tbody {
        box-sizing: border-box;
    }
`;
const tableHeaderStyle = css`
    display: flex;
    padding: 0px 14px;
    width: 100%;
    height: 32px;
    align-items: center;
`;

const tableHeaderRowStyle = css`
    display: flex;
    width: 100%;
    & > th {
        color: ${Common.colors.darkGrey04};
        ${Common.textStyle.text1_R13}
    }
`;

const tableBodyStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const tableBodyRowStyle = css`
    display: flex;
    width: 100%;
    /* gap: 8px; */
    border-bottom: 0.6px solid ${Common.colors.bgGrey03};
    padding: 6px 14px;
    & > td {
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.text1_R13}
        display:flex;
        text-align: center;
        div {
            word-break: keep-all;
            -webkit-word-break: keep-all;
            -moz-word-break: keep-all;
            -o-word-break: keep-all;
        }
    }
`;

export default TableContents;
