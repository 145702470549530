import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import newInquiry from "@src/assets/newInquiry.svg";
import { css } from "@emotion/react";

interface NewItemProps {
    createdAt: any;
}

const NewItem = ({ createdAt }: NewItemProps) => {
    const [newItem, setNewItem] = useState<boolean>(false);
    useEffect(() => {
        if (createdAt) {
            const newCreatedAt = createdAt.split("(");
            const createdDay = format(new Date(newCreatedAt[0].replaceAll(".", "-")), "yyyy.MM.dd");
            if (createdDay === format(new Date(), "yyyy.MM.dd")) {
                setNewItem(true);
            }
        }
    }, [createdAt]);

    return <>{newItem && <img css={newInquiryCss} src={newInquiry} alt="newInquiryImg" />}</>;
};

export default NewItem;

const newInquiryCss = css`
    width: 16px;
    height: 16px;
    margin-left: 4px;
`;
