import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import GoStopIntro1 from "@src/assets/goStop/goStopIntro1.png";
import GoStopIntro2 from "@src/assets/goStop/goStopIntro2.png";
import { useNavigate } from "react-router-dom";
import { transferToNative } from "@src/lib/WebAppBridge";
import closeBtn from "@src/assets/ico_close.png";
import Title from "@src/components/title/Title";

interface Props { }

const GoStop: React.FC<Props> = () => {
    const navigate = useNavigate();
    return (
        <div css={goStopWrapper}>
            <div className="titleWrap">
                <div
                    className="backBtn"
                    onClick={() => {
                        transferToNative("done", "closeWebView");
                    }}
                >
                    <img src={closeBtn} alt="닫기" />
                </div>
                <Title title="주거/토지 Go-Stop" size="large" />
            </div>
            <div className="descWrap">
                <img src={GoStopIntro1} alt="goStopIntro1" />
                <img src={GoStopIntro2} alt="goStopIntro2" />
                <div className="buttonDiv">
                    <button
                        onClick={() => {
                            navigate("/goStopList");
                        }}
                    >
                        매물 보러가기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GoStop;

const goStopWrapper = css`
    width: 100vw;
    height: 100vh;
    position: relative;
    img {
        width: 100vw;
    }
    .buttonDiv {
        position: fixed;
        bottom: 28px;
        left: 14px;
        width: 100%;
        height: 56px;
    }
    button {
        width: calc(100% - 28px);
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background-color: #ff0000;
        color: white;
        display: flex;
        white-space: pre-wrap;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        border: none;
        outline: none;
        font-family: "GmarketSansTTF", sans-serif;
    }
    .titleWrap {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 14px;
        align-items: center;
        .backBtn {
            position: absolute;
            right: 14px;
            width: 10px;
            height: 17px;

            img {
                width: 14px;
                height: 14px;
            }
        }
        .title {
            font-weight: 700;
        }
    }
    .descWrap {
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
        max-height: calc(100vh - 56px);
    }
`;
