import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery} from "react-query";
import { IAdItemListType } from "./dto/expert.dto";

const fetchExpert = async (params: IAdItemListType) => {
    const response = await axiosInstance
        .post(`${axiosPath.expertAdItemList}`, params, {    
        })
        .then((res) => {
            if (!res?.status) {
                throw new Error("Problem fetching data");
            }
            const responseData = res?.data ?? [];
            return responseData;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return err;
        });
    return response;
};


export const useAdItemList = (cursor: any) => {
    return useInfiniteQuery<any>(
        ["expertList", cursor],
        ({ pageParam }) => fetchExpert({ ...cursor, cursor: pageParam ?? cursor?.cursor }),
        {
            getNextPageParam: (lastPage: any, pages: any) => {
                const newPage = pages?.[0]?.totalCnt > pages?.length * 20 ? lastPage.cursor : undefined;
                return newPage;
            },
            enabled:true,
        },
    );
};

export const useDelBanner = async (params: { bannerNo: number; itemId: string }) => {
    const response = await axiosInstance.delete(
        `${axiosPath.expertBanner}?bannerNo=${params?.bannerNo}&itemId=${params?.itemId}`,
    );
    return response;
};

export const useReAdBanner = async (itemId: string) => {
    const response = await axiosInstance.post(`${axiosPath.expertBanner}`, { itemId });
    return response;
};

export const useAdCntBanner = async (ticketType: number) => {
    const response = await axiosInstance.get(`${axiosPath.adTicketCount}/${ticketType}`);
    return response;
};
