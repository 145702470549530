import styled from "@emotion/styled";
import { checkDevice } from "@src/components/deungi/common/utils";
import { CampusMyClassDto } from "@src/hooks/campus/dto/CampusList.dto";
import { useGetCampusDetail } from "@src/hooks/campus/useCampusDetail";
import { Common } from "@src/styles/Common";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import thumbnail from "../../assets/thumbnail.png";

interface MyClassProps {
    item: CampusMyClassDto;
    setIsShowModal: Dispatch<SetStateAction<boolean>>;
    setItemNo: Dispatch<SetStateAction<number>>;
}

const MyClass = ({ item, setIsShowModal, setItemNo }: MyClassProps) => {
    const navigate = useNavigate();
    const now = Date.now();
    const deviceType = checkDevice();

    return (
        <>
            <StyledClass
                onClick={() => {
                    now < new Date(item.expiredAt).getTime() || !item.expiredAt
                        ? navigate(`/campus/sugang/${item.no}`, { state: { no: item.no } })
                        : useGetCampusDetail(item.campusNo, deviceType === "android" ? 6 : 7)
                              .then((res) => {
                                  setIsShowModal(true), setItemNo(item.campusNo);
                              })
                              .catch((err) => {
                                  setIsShowModal(true), setItemNo(-1);
                              });
                }}
            >
                <div className="thumbnailDiv">
                    {now > new Date(item.expiredAt).getTime() && item.expiredAt && <div className="endDim">수강완료</div>}

                    <img src={item.thumbnailUrl ? item.thumbnailUrl : thumbnail} alt="" />
                </div>
                <div className="textBox">
                    <div className="title">{item.title}</div>
                    <div className="sub">
                        <div className="subText">
                            최근수강 :
                            {item.recentAt
                                ? ` ${item.recentSubOrder + 1}강 / ${dayjs(item.recentAt).format("YYYY.MM.DD")}`
                                : " 수강 전"}
                        </div>
                        <div className="subText">
                            수강기한 : {item.expiredAt ? `${dayjs(item.expiredAt).format("YYYY.MM.DD")}까지` : "무제한"}
                        </div>
                    </div>
                </div>
            </StyledClass>
        </>
    );
};

export default MyClass;

const StyledClass = styled.div`
    padding: 14px 0;
    display: flex;
    gap: 8px;
    border-bottom: 1px solid ${Common.colors.grey02};
    .thumbnailDiv {
        width: 140px;
        height: 100px;
        border-radius: 8px;
        position: relative;
        & > img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
        & > .endDim {
            position: absolute;
            background-color: rgba(40, 40, 40, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            color: white;
            font-weight: 400;
            font-size: 20px;
        }
    }
    .textBox {
        width: calc(100% - 140px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > .title {
            ${Common.textStyle.body2_R16};
            color: ${Common.colors.normalTextColor};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        & > .sub {
            display: flex;
            flex-direction: column;
            gap: 6px;
            & > .subText {
                font-weight: 400;
                font-size: 13px;
                color: ${Common.colors.darkGrey03};
            }
        }
    }
`;
