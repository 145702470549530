import styled from "@emotion/styled";
import { Button } from "@src/components";
import { useRefundPayment } from "@src/hooks/payment/useRefund";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction } from "react";
import { ToastType } from "../deungi/DeungiTicketCancelList";

interface CampusRefundModalProps {
    setIsModalShow: Dispatch<SetStateAction<boolean>>;
    tryRefundTicket: (id: number) => any;
    orderNo: number;
    tryGetCancel: (reset: boolean) => void;
    setToast: Dispatch<SetStateAction<ToastType>>;
    setCancelData: Dispatch<SetStateAction<any[]>>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    contents: any;
}

const CampusRefundModal = ({
    setIsModalShow,
    contents,
    orderNo,
    setToast,
    setIsLoading,
    tryGetCancel,
}: CampusRefundModalProps) => {
    console.log(contents);
    return (
        <>
            <StyledWrap>
                <StyledModalDiv>
                    <div className="contents">{contents.text}</div>
                    <div className="buttonDiv">
                        <Button
                            color={contents.type === "refund" ? "gray" : "blue"}
                            label={contents.type === "refund" ? "닫기" : "확인"}
                            handleClick={() => {
                                setIsModalShow(false);
                            }}
                            size={"small"}
                            styles={{ backgroundColor: contents.type === "refund" ? "#EBEBEB" : "" }}
                        />
                        {contents.type === "refund" && (
                            <Button
                                color="blue"
                                label="환불하기"
                                handleClick={() => {
                                    // tryRefundTicket(refundId)
                                    //     .then(() => {
                                    //         setToast({ showToast: true, toastMessage: "취소가 완료되었습니다" });
                                    //         setIsLoading(true);
                                    //         tryGetCancel(true);
                                    //     })
                                    //     .catch(() => {
                                    //         setToast({ showToast: true, toastMessage: "오류가 발생하였습니다 다시 시도해주세요" });
                                    //     });
                                    useRefundPayment(orderNo)
                                        .then(() => {
                                            setToast({ showToast: true, toastMessage: "취소가 완료되었습니다" });
                                            setIsLoading(true);
                                            tryGetCancel(true);
                                        })
                                        .catch(() => {
                                            setToast({
                                                showToast: true,
                                                toastMessage: "오류가 발생하였습니다 다시 시도해주세요",
                                            });
                                        });
                                    setIsModalShow(false);
                                }}
                                size={"small"}
                            />
                        )}
                    </div>
                </StyledModalDiv>
                <div
                    className="shadow"
                    onClick={() => {
                        setIsModalShow(false);
                    }}
                ></div>
            </StyledWrap>
        </>
    );
};

export default CampusRefundModal;

const StyledModalDiv = styled.div`
    z-index: 30;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 14px;
    max-width: 320px;
    width: calc(100vw - 48px);
    border-radius: 14px;
    position: absolute;
    min-height: 166px;
    height: fit-content;
    background-color: #ffffff;
    top: calc(50% - 166px / 2 - 10px);
    & > .contents {
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.body2_R16};
        padding-bottom: 24px;
        text-align: center;
        & > .subs {
            margin-top: 8px;
            font-size: 14px;
            white-space: pre-line;
        }
    }
    & > .buttonDiv {
        display: flex;
        gap: 8px;
        & > button {
            width: 100%;
            height: 56px;
            padding: 0px;
            font-weight: 400;
            font-size: 18px;
            border-radius: 14px;
        }
    }
`;

const StyledWrap = styled.div`
    display: flex;
    justify-content: center;
    & > .shadow {
        width: 100vw;
        height: 100vh;
        background-color: ${Common.colors.darkGrey03};
        opacity: 0.7;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 29;
    }
`;
