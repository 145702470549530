import styled from "@emotion/styled";
import { DeungiInfoResponseDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { useDeungiInfo } from "@src/hooks/deungi/useDeungiInfo";
import React, { useEffect, useRef, useState } from "react";
import { DEUNGI_OPEN_STATE } from "./common/enum";

import gif_loading_deungi from "@src/assets/gif_loading_deungi.gif";
import ico_close_white from "@src/assets/ico_close_white.png";
// TODO: 이거.. 이미지 배열로 못받아오나요?
import background_deungi_01 from "@src/assets/background_deungi/01.png";
import background_deungi_02 from "@src/assets/background_deungi/02.png";
import background_deungi_03 from "@src/assets/background_deungi/03.png";
import background_deungi_04 from "@src/assets/background_deungi/04.png";
import background_deungi_05 from "@src/assets/background_deungi/05.png";
import background_deungi_06 from "@src/assets/background_deungi/06.png";
import background_deungi_07 from "@src/assets/background_deungi/07.png";
import background_deungi_08 from "@src/assets/background_deungi/08.png";
import background_deungi_09 from "@src/assets/background_deungi/09.png";
import background_deungi_10 from "@src/assets/background_deungi/10.png";
import background_deungi_11 from "@src/assets/background_deungi/11.png";
import background_deungi_12 from "@src/assets/background_deungi/12.png";
import background_deungi_13 from "@src/assets/background_deungi/13.png";
import background_deungi_14 from "@src/assets/background_deungi/14.png";
import background_deungi_15 from "@src/assets/background_deungi/15.png";
import background_deungi_16 from "@src/assets/background_deungi/16.png";
import { useNavigate } from "react-router-dom";
import { getDeungiOpenState } from "./common/utils";
import { transferToNative } from "@src/lib/WebAppBridge";

type DeungiWatchPropsType = {
    watchInfo: string;
};

const DeungiWatch = ({ watchInfo }: DeungiWatchPropsType) => {
    const navigate = useNavigate();
    const [deungiInfo, setDeungiInfo] = useState({} as DeungiInfoResponseDto);

    // TODO: 이미지.. 선언.. 배열로.. 못하나요?
    const imgList = [
        background_deungi_01,
        background_deungi_02,
        background_deungi_03,
        background_deungi_04,
        background_deungi_05,
        background_deungi_06,
        background_deungi_07,
        background_deungi_08,
        background_deungi_09,
        background_deungi_10,
        background_deungi_11,
        background_deungi_12,
        background_deungi_13,
        background_deungi_14,
        background_deungi_15,
        background_deungi_16,
    ];
    const titleRef = useRef(null);
    const bgRef_1 = useRef(null);
    const bgRef_2 = useRef(null);

    const getDeungiLoadState = (state: DEUNGI_OPEN_STATE) => {
        switch (state) {
            case DEUNGI_OPEN_STATE.WAITING:
            case DEUNGI_OPEN_STATE.REOPEN:
                return "load-1";
            case DEUNGI_OPEN_STATE.PAYMENT_ING:
            case DEUNGI_OPEN_STATE.PAID:
                return "load-2";
            case DEUNGI_OPEN_STATE.UPLOAD_ING:
                return "load-3";
            case DEUNGI_OPEN_STATE.UPLOADED:
                return "load-4";
            default:
                return "";
        }
    };

    const getDeungiInfo = async () => {
        const res = await useDeungiInfo(watchInfo);
        const deungiInfo: DeungiInfoResponseDto = res.data;

        setDeungiInfo(deungiInfo);
    };

    const onExitHandler = () => {
        navigate({
            pathname: "/deungi",
            search: `?type=list`,
        });
    };

    const onGotoMapHandler = () => {
        transferToNative("", "showDataMapView");
    };

    useEffect(() => {
        if (!watchInfo) return;

        getDeungiInfo();

        const getDeungiTimer = setInterval(getDeungiInfo, 5000);

        return () => clearInterval(getDeungiTimer);
    }, [watchInfo]);

    useEffect(() => {
        const bgEl_1 = bgRef_1.current as any;
        const bgEl_2 = bgRef_2.current as any;
        let imgIdx = 1;

        if (!bgEl_1) return;

        const bgAnimate = setInterval(() => {
            if (imgIdx++ % 2 === 1) {
                bgEl_1.classList.remove("active");
                bgEl_1.src = imgList[imgIdx];
                bgEl_2.classList.add("active");
            } else {
                bgEl_2.classList.remove("active");
                bgEl_2.src = imgList[imgIdx];
                bgEl_1.classList.add("active");
            }

            if (imgIdx > 14) imgIdx = 1;
        }, 5000);

        return () => clearInterval(bgAnimate);
    }, [bgRef_1.current]);

    useEffect(() => {
        const titleEl = titleRef.current as any;

        if (!titleEl) return;

        titleEl.classList.add("active");

        setTimeout(() => titleEl.classList.remove("active"), 1000);

        if (
            deungiInfo.deungi_state === DEUNGI_OPEN_STATE.UPLOADED ||
            deungiInfo.deungi_state === DEUNGI_OPEN_STATE.PAYMENT_FAIL ||
            deungiInfo.deungi_state === DEUNGI_OPEN_STATE.UPLOAD_FAIL
        ) {
            setTimeout(onExitHandler, 5000);
        }
    }, [titleRef.current, deungiInfo.deungi_state]);

    return (
        <>
            {watchInfo && (
                <Container>
                    <div className="background">
                        <img ref={bgRef_1} className="active" src={imgList[0]} />
                        <img ref={bgRef_2} src={imgList[1]} />
                    </div>
                    <div className="cover">
                        <div onClick={onExitHandler}>
                            <img src={ico_close_white} />
                        </div>
                    </div>
                    <div className="contents">
                        <img
                            className={getDeungiLoadState(deungiInfo.deungi_state as DEUNGI_OPEN_STATE)}
                            src={gif_loading_deungi}
                        />
                        <h2
                            ref={titleRef}
                            dangerouslySetInnerHTML={{
                                __html: getDeungiOpenState(
                                    deungiInfo.deungi_state as DEUNGI_OPEN_STATE,
                                    "watch",
                                    deungiInfo.created_at,
                                ),
                            }}
                        ></h2>
                        <span>
                            등기열람 준비는 30초정도 걸려요
                            <br />
                            완료되면 알림을 보내드릴게요
                        </span>
                    </div>
                    <input type="button" value="매물 보러가기" onClick={onGotoMapHandler} />
                </Container>
            )}
        </>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 100;
    animation: fadeIn 1s forwards;

    &.hidden {
        animation: fadeOut 1s forwards;
    }
    .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;

        & > div {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;

            & > img {
                width: 14px;
                height: 14px;
            }
        }
    }
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
                z-index: 1;
                animation: scaleUp 5s forwards;
            }
        }
        @keyframes scaleUp {
            from {
                scale: 1;
                opacity: 1;
            }
            90% {
                scale: 1.1;
            }
            100% {
                opacity: 0;
            }
        }
    }
    .contents {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 150px;
        left: 0;
        width: 100%;
        z-index: 2;

        & > img {
            width: 100px;
            height: 100px;
            margin: 0 auto 60px;
            box-sizing: border-box;
            border: 3px solid rgba(0, 0, 0, 0);
            border-radius: 100px;
            transition: all 2s ease;

            &.load-1 {
                border-top-color: #37dcd0;
            }
            &.load-2 {
                border-color: #37dcd0 #37dcd0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
            }
            &.load-3 {
                border-color: #37dcd0 #37dcd0 #37dcd0 rgba(0, 0, 0, 0);
            }
            &.load-4 {
                border-color: #37dcd0;
            }
        }
        & > h2 {
            width: 100%;
            font-size: 34px;
            font-weight: 500;
            line-height: 1.4;
            color: #fff;
            margin-bottom: 15px;
            padding-left: 24px;
            text-align: left;

            &.active {
                animation: gelatine 1s;
            }
            & > span {
                color: #5b95ff;
                font-weight: 700;

                &.danger {
                    color: #f00;
                }
            }

            @keyframes gelatine {
                from,
                to {
                    transform: scale(1, 1);
                }
                25% {
                    transform: scale(0.9, 1.1);
                }
                50% {
                    transform: scale(1.1, 0.9);
                }
                75% {
                    transform: scale(0.95, 1.05);
                }
            }
        }
        & > span {
            width: 100%;
            font-size: 13px;
            font-weight: 400;
            padding-left: 24px;
            color: #fff;
            line-height: 1.6;
        }
    }
    & > input {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: auto;
        padding: 8px 14px;
        width: 110px;
        height: 40px;
        background: #2275ee;
        color: #fff;
        border: none;
        border-radius: 24px;
        z-index: 2;
    }

    @keyframes fadeIn {
        from {
            scale: 1;
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

export default DeungiWatch;
