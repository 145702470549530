import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import { onChangePriceToKorean } from "@src/util/onChangePriceToKorean";
import { ChangeEvent, useCallback, useRef } from "react";
import React from "react";

interface Props {
    price: any;
    onChangePrice: (evt: any) => void;
    handleSubmit: any;
}

const StoreGoStopPriceFilter: React.FC<Props> = ({
    price,
    onChangePrice,
    handleSubmit
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    return (
        <>
            <div css={stepBoxStyle}>
                <div css={priceTitleStyle}>
                    <p className="subTitle">
                        투자하고자 하는 금액을 입력하면 투자 수익률을
                        <br />
                        계산해 드려요
                    </p>
                </div>
                <div css={inputRootStyle}>
                    <div css={inputBoxStyle}>
                        <input
                            css={inputStyle}
                            onChange={onChangePrice}
                            value={price !== 0 ? price.toLocaleString() : ""}
                            placeholder="Ex) 2000"
                            onKeyUp={(evt) => {
                                if (evt.code === "Enter") {
                                    const newVal = Number(inputRef.current?.value?.replaceAll(",", "")) * 10000;
                                    handleSubmit(newVal);
                                }
                            }}
                            type="tel"
                            ref={inputRef}
                            onBlur={() => {
                                const newVal = Number(inputRef.current?.value?.replaceAll(",", "")) * 10000;
                                handleSubmit(newVal);
                            }}
                        />
                        <span css={inputTextStyle}>만원</span>
                    </div>
                    <p className="desc">
                        {price !== ""
                            ? `${onChangePriceToKorean(Number(price.replaceAll(",", "") * 10000))}원`
                            : '투자금을 입력해주세요'
                        }
                    </p>
                </div>
            </div>
        </>
    )
}

export default StoreGoStopPriceFilter;

const stepBoxStyle = css`
    display: flex;
    padding: 14px 0px;
    flex-direction: column;
    max-height: 30vh;
    height: 266px;
    gap: 24px;
`;

const priceTitleStyle = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .title {
        color: #0c002c;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        span {
            color: #0047ff;
        }
    }
    .subTitle {
        color: #3c3c3c;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const inputBoxStyle = css`
    position: relative;
    display: flex;
    align-items: center;
`;

const inputTextStyle = css`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0%, -50%);
    color: #382e51;
    font-size: 16px;
    font-weight: 700;
`;

const inputStyle = css`
    width: 100%;
    box-sizing: border-box;
    padding: 14px 44px 14px 14px;
    background: #fdfafa;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    border-radius: 14px;
    border: 1px solid #0c002c;
    text-align: right;
    :focus {
        border: 1px solid ${Common.colors.aucMarkerColor};
    }
    ::placeholder {
        color: #8d8d8d;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
`;

const inputRootStyle = css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    .desc {
        margin-right: 14px;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;
