import { transferToNative } from "@src/lib/WebAppBridge";
import axios from "axios";
import fetchJsonp from "fetch-jsonp";
interface paramsProps {
    position: {
        lat: number;
        lng: number;
    };
    bbox?: any[];
    pnu?: string;
}
interface resultType {
    bbox: any[];
    geometryCoordinates: any[];
    center: paramsProps["position"];
    address: string;
    pnu: string;
    isRoad: boolean;
}
const env = process.env;

const V_WORLD_DEFAULT = {
    key: env.REACT_APP_V_WORLD_KEY,
    domain: env.REACT_APP_V_WORLD_DOMAIN,
    request: "GetFeature",
    srsname: "EPSG:4326",
    // output: "application/json",
    output: "text/javascript",
    maxfeatures: 1000,
};

const getQueryString = (options: any) => {
    return Object.entries(options)
        .map((v) => v.join("="))
        .join("&");
};

const fetchData = async (params: paramsProps, isBuilding = false) => {
    const url = process.env.REACT_APP_V_WORLD_API_URL;

    const position = params.position;

    const queryString = getQueryString({
        ...V_WORLD_DEFAULT,
        bbox: params.bbox ? params.bbox : `${position.lng},${position.lat},${position.lng},${position.lat}`,
        typename: isBuilding ? "lt_c_bldginfo" : "lp_pa_cbnd_bubun",
    });

    const res = await fetchJsonp(`${url}?${queryString}`, {
        jsonpCallbackFunction: "parseResponse",
        timeout: 2000,
    });
    const { bbox, features } = await res.json();

    let result: resultType = {
        bbox,
        geometryCoordinates: [],
        center: params.position,
        address: "",
        pnu: "",
        isRoad: true,
    };
    if (!isBuilding) {
        const geoCoord = features?.[0]?.geometry.coordinates[0] ?? [];
        let resultCoord = [];
        resultCoord = geoCoord[0] || [];

        // if (geoCoord.length === 1) {
        //     resultCoord = geoCoord[0] || [];
        // } else
        if (geoCoord.length > 1) {
            // const arr = geoCoord.reduce((acc: any, cur: any) => {
            //     return acc.concat(cur);
            // }, []);
            // resultCoord = [...arr];
            // console.log(geoCoord[0]);
            // resultCoord = geoCoord[0][0];
            resultCoord = geoCoord;
            result.isRoad = false;
        }
        result = {
            ...result,
            geometryCoordinates: resultCoord,
            address: features?.[0]?.properties?.addr,
            pnu: features?.[0]?.properties?.pnu,
        };
    } else {
        features?.map((feature: any) => {
            let coords: any[] = feature?.geometry?.coordinates?.[0]?.[0];
            const isBoundaryIn = params?.pnu == feature?.properties?.pnu;
            isBoundaryIn && result?.geometryCoordinates?.push(coords);
        });
    }
    return result;
};

const useGetMapPolyCoord = async (params: paramsProps, isLand?: boolean) => {
    return fetchData(params, isLand);
};

export default useGetMapPolyCoord;
