import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import create from "zustand";

type useFilterStoreType = {
    storeGoStopFilterState: IFilterParamsType;
    setStoreGoStopFilterState: (filterState: IFilterParamsType) => void;
    resetStoreGoStopFilterState: () => void;
};

type useStoreGoStopFilterMapCheckStoreType = {
    storeGoStopFilterMapCheckState: boolean;
    setStoreGoStopFilterMapCheckState: (status: boolean) => boolean;
};

const initialFilterParams: IFilterParamsType = {
    type: 0,
    use: [],
    loc: [],
    gostop_profit: [0, null],
    status: [1, 2],
    except_item: [],
    auction_master_item: [],
    auction_master_except: false,
    counter_force: 0,
    sortType: 19,
    sales_method: 0,
    assets: [],
    min_price: [],
};

export const useStoreGoStopFilterStore = create<useFilterStoreType>(
    (set: any) =>
        ({
            storeGoStopFilterState: initialFilterParams,
            setStoreGoStopFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        storeGoStopFilterState: {
                            ...filterState,
                        },
                    };
                });
            },
            resetStoreGoStopFilterState: () => set({ storeGoStopFilterState: initialFilterParams }),
        } as any),
);

export const useStoreGoStopFilterMapCheckStore = create<useStoreGoStopFilterMapCheckStoreType>(
    (set: any) =>
        ({
            storeGoStopFilterMapCheckState: false,
            setStoreGoStopFilterMapCheckState: async (status: boolean) => {
                set(() => {
                    return {
                        storeGoStopFilterMapCheckState: status,
                    };
                });
            },
        } as any),
);
