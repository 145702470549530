export const useMajorCategory = [
    { inputValue: [], value: "", name: "전체" },
    { inputValue: ["A01", "A02"], value: "A", name: "주거용" },
    {
        inputValue: [
            "B01",
            "B02",
            "B03",
            "B05",
            "B06",
            "B07",
            "B08",
            "B09",
            "B10",
            "B12",
            "B13",
            "B14",
            "B15",
            "B16",
            "B17",
            "B18",
            "B19",
            "B20",
            "B21",
            "B22",
            "B11",
        ],
        value: "B",
        name: "토지",
    },
    { inputValue: ["D01"], value: "D", name: "상업/업무용" },
];

export const useFilterTextList = [
    {
        name: "주거용 > 전체",
        value: "A",
    },
    {
        name: "주거용 > 아파트",
        value: "A01",
    },
    {
        name: "토지 > 전체",
        value: "B",
    },
    {
        name: "토지 > 대지",
        value: "B01",
    },
    {
        name: "토지 > 전",
        value: "B02",
    },
    {
        name: "토지 > 답",
        value: "B03",
    },
    {
        name: "토지 > 임야",
        value: "B05",
    },
    {
        name: "토지 > 잡종지",
        value: "B06",
    },
    {
        name: "토지 > 과수원",
        value: "B07",
    },
    {
        name: "토지 > 공장용지",
        value: "B08",
    },
    {
        name: "토지 > 창고용지",
        value: "B09",
    },
    {
        name: "토지 > 목장용지",
        value: "B10",
    },
    {
        name: "토지 > 주차장",
        value: "B12",
    },
    {
        name: "토지 > 묘지",
        value: "B13",
    },
    {
        name: "토지 > 학교용지",
        value: "B14",
    },
    {
        name: "토지 > 체육용지",
        value: "B15",
    },
    {
        name: "토지 > 종교용지",
        value: "B16",
    },
    {
        name: "토지 > 구거",
        value: "B17",
    },
    {
        name: "토지 > 유지",
        value: "B18",
    },
    {
        name: "토지 > 제방",
        value: "B19",
    },
    {
        name: "토지 > 하천",
        value: "B20",
    },
    {
        name: "토지 > 염전",
        value: "B21",
    },
    {
        name: "토지 > 주유소용지",
        value: "B22",
    },
    {
        name: "토지 > 기타",
        value: "B11",
    },
    {
        name: "상업/업무용 > 오피스텔",
        value: "D",
    },
    {
        name: "상업/업무용 > 오피스텔",
        value: "D01",
    },
];

export const useFilterList: any = {
    전체: [{ name: "전체", value: "" }],
    주거용: [
        {
            name: "전체",
            value: "A",
        },
        {
            name: "아파트",
            value: "A01",
        },
    ],
    토지: [
        {
            name: "전체",
            value: "B",
        },
        {
            name: "대지",
            value: "B01",
        },
        {
            name: "전",
            value: "B02",
        },
        {
            name: "답",
            value: "B03",
        },
        {
            name: "임야",
            value: "B05",
        },
        {
            name: "잡종지",
            value: "B06",
        },
        {
            name: "과수원",
            value: "B07",
        },
        {
            name: "공장용지",
            value: "B08",
        },
        {
            name: "창고용지",
            value: "B09",
        },
        {
            name: "목장용지",
            value: "B10",
        },
        {
            name: "주차장",
            value: "B12",
        },
        {
            name: "묘지",
            value: "B13",
        },
        {
            name: "학교용지",
            value: "B14",
        },
        {
            name: "체육용지",
            value: "B15",
        },
        {
            name: "종교용지",
            value: "B16",
        },
        {
            name: "구거",
            value: "B17",
        },
        {
            name: "유지",
            value: "B18",
        },
        {
            name: "제방",
            value: "B19",
        },
        {
            name: "하천",
            value: "B20",
        },
        {
            name: "염전",
            value: "B21",
        },
        {
            name: "주유소용지",
            value: "B22",
        },
        {
            name: "기타",
            value: "B11",
        },
    ],
    "상업/업무용": [
        {
            name: "전체",
            value: "D",
        },
        {
            name: "오피스텔",
            value: "D01",
        },
        {
            name: "근린상가",
            value: "D02",
        },
        {
            name: "사무실",
            value: "D03",
        },
        {
            name: "숙박시설",
            value: "D04",
        },
        {
            name: "근린시설",
            value: "D06",
        },
        {
            name: "숙박시설(콘도 등)",
            value: "D07",
        },
        {
            name: "농가관련시설",
            value: "D08",
        },
        {
            name: "노유자시설",
            value: "D09",
        },
        {
            name: "종교시설",
            value: "D10",
        },
        {
            name: "주유소",
            value: "D11",
        },
        {
            name: "의료시설",
            value: "D12",
        },
        {
            name: "자동차관련시설",
            value: "D13",
        },
        {
            name: "장례,묘지시설",
            value: "D14",
        },
        {
            name: "분뇨쓰레기처리",
            value: "D15",
        },
        {
            name: "관광휴게시설",
            value: "D16",
        },
        {
            name: "기타",
            value: "D05",
        },
    ],
};
