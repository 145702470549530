import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { IFilterParamsType } from ".";
import { Common } from "@src/styles/Common";
import { landUseCategoryListLength, useFilterList, useFilterTextList, useMajorCategory } from "./useFilterList";
import ShowSelectedUseLocValueBox from "./ShowSelectedUseLocValueBox";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    from?: string;
    showToast: () => void;
    onClickDone?: () => void;
}

const totalUseLength = 10;

const LandUseFilter: React.FC<Props> = ({ filterParams, setFilterParams, from = "all", showToast, onClickDone }) => {
    const [selectedMajor, setSelectedMajor] = useState<string>("전체");
    const majorRef = useRef<any>();
    const getRealLength = (val: string[]) => {
        const checkALength =
            val.filter((v) => v.includes("A")).length === landUseCategoryListLength["A"]
                ? 1
                : val.filter((v) => v.includes("A")).length;
        const checkBLength =
            val.filter((v) => v.includes("B")).length === landUseCategoryListLength["B"]
                ? 1
                : val.filter((v) => v.includes("B")).length;
        const checkCLength =
            val.filter((v) => v.includes("C")).length === landUseCategoryListLength["C"]
                ? 1
                : val.filter((v) => v.includes("C")).length;
        const checkDLength =
            val.filter((v) => v.includes("D")).length === landUseCategoryListLength["D"]
                ? 1
                : val.filter((v) => v.includes("D")).length;
        return checkALength + checkBLength + checkCLength + checkDLength > totalUseLength;
    };

    useEffect(() => {
        if (filterParams.use.some((ele) => ele.split("").some((data) => data === "A"))) {
            setSelectedMajor("주거용");
        } else if (filterParams.use.some((ele) => ele.split("").some((data) => data === "B"))) {
            setSelectedMajor("토지");
        } else if (filterParams.use.some((ele) => ele.split("").some((data) => data === "C"))) {
            setSelectedMajor("산업용");
        } else if (filterParams.use.some((ele) => ele.split("").some((data) => data === "D"))) {
            setSelectedMajor("상업/업무용");
        } else if (filterParams.use.some((ele) => ele.split("").some((data) => data === "E"))) {
            setSelectedMajor("기타");
        } else return;
    }, []);

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle}>
                <p className="titleText">용도</p>
                {from === "out" && (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            {from === "out" && (
                <div css={summaryBoxStyle}>
                    {!!filterParams.use.length &&
                        Array.from(
                            new Set(
                                filterParams.use.map((v) => {
                                    const checkMajor = v.slice(0, 1);
                                    const checkActiveList = filterParams.use.filter((val) => val.includes(checkMajor));
                                    const activeListLengthCheck =
                                        checkMajor === "A"
                                            ? checkActiveList.length === landUseCategoryListLength["A"]
                                            : checkMajor === "B"
                                                ? checkActiveList.length === landUseCategoryListLength["B"]
                                                : checkMajor === "C"
                                                    ? checkActiveList.length === landUseCategoryListLength["C"]
                                                    : checkMajor === "D"
                                                        ? checkActiveList.length === landUseCategoryListLength["D"]
                                                        : checkMajor === "E" && checkActiveList.length === landUseCategoryListLength["E"];
                                    return activeListLengthCheck ? checkMajor : v;
                                }),
                            ),
                        ).map((val) => {
                            const newText = useFilterTextList.filter((text) => text.value === val);
                            return (
                                <ShowSelectedUseLocValueBox
                                    value={newText?.[0]?.name}
                                    onDeleteValue={() => {
                                        const newUse = [...filterParams.use].filter((val) => !val.includes(newText?.[0]?.value));
                                        setFilterParams({ ...filterParams, use: [...newUse] });

                                        if (newUse.length === 0) {
                                            setSelectedMajor("전체");
                                            return;
                                        }

                                        const isThereSameCategory = newUse.find((val) =>
                                            val.includes(newText?.[0]?.value.slice(0, 1)),
                                        );

                                        if (!isThereSameCategory) {
                                            const category = useMajorCategory.find(
                                                (category) => category.value === newUse.at(-1)?.slice(0, 1),
                                            );
                                            if (category) {
                                                setSelectedMajor(category.name);
                                            }
                                        }
                                    }}
                                    key={val}
                                />
                            );
                        })}
                </div>
            )}
            <div css={subTitleBoxStyle}>
                <p className="subTitleText">대분류</p>
                <p className="subTitleText">중분류</p>
            </div>
            <div css={selectBoxRootStyle}>
                <div css={selectBoxStyle(from)} ref={majorRef}>
                    {useMajorCategory.map((category, idx) => {
                        let checkActive: boolean = false;
                        if (filterParams.use.length) {
                            checkActive = idx ? filterParams.use.some((val) => val?.includes(category.value)) : false;
                        } else {
                            checkActive = !idx;
                        }
                        return (
                            <button
                                key={category.name}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    setSelectedMajor(category.name);
                                    let newUse: string[] = [];
                                    if (checkActive && idx) {
                                        newUse = [...filterParams.use].filter((v) => !v.startsWith(category.value));
                                        setFilterParams({ ...filterParams, use: [...newUse] });
                                    } else {
                                        newUse = idx ? [...filterParams.use, ...category.inputValue] : [];
                                        if (getRealLength(newUse)) {
                                            showToast();
                                            return;
                                        }
                                        setFilterParams({ ...filterParams, use: [...newUse] });
                                    }
                                    newUse.length && majorRef.current.scrollTo(0, 0);
                                }}
                            >
                                {category.name}
                            </button>
                        );
                    })}
                </div>
                <div css={selectBoxStyle(from)}>
                    {selectedMajor !== "전체" &&
                        useFilterList[selectedMajor].map((category: any, i: number) => {
                            const checkMajor = category.value.slice(0, 1);
                            const checkActiveList = filterParams.use.filter((val) => val.includes(checkMajor));
                            const activeListLengthCheck = useFilterList[selectedMajor].length - 1 === checkActiveList.length;
                            let checkActive: boolean = true;
                            activeListLengthCheck
                                ? i
                                    ? (checkActive = false)
                                    : (checkActive = true)
                                : i
                                    ? (checkActive = filterParams.use.some((val) => val.includes(category.value)))
                                    : (checkActive = false);
                            return (
                                (selectedMajor !== "기타" || i === 0) && (
                                    <button
                                        key={`${category.name}${category.value}`}
                                        css={selectButtonStyle(checkActive)}
                                        onClick={() => {
                                            const checkInclude =
                                                !!i && filterParams.use.some((val) => val.includes(category.value));
                                            if (activeListLengthCheck && i === 0) {
                                                const newUse = [...filterParams.use].filter((v) => !v.includes(checkMajor));
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                setFilterParams({ ...filterParams, use: [...newUse] });
                                            } else if (i === 0 || (activeListLengthCheck && checkInclude)) {
                                                const newUse = [...filterParams.use].filter((v) => !v.includes(checkMajor));
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                const addUse = i
                                                    ? [category.value]
                                                    : useFilterList[selectedMajor]?.slice(1).map((v: any) => v.value);
                                                setFilterParams({ ...filterParams, use: [...newUse, ...addUse] });
                                            } else if (checkInclude) {
                                                const newUse = [...filterParams.use].filter((v) => !v.includes(category.value));
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                setFilterParams({ ...filterParams, use: [...newUse] });
                                            } else {
                                                const newUse = [...filterParams.use, category.value];
                                                if (getRealLength(newUse)) {
                                                    showToast();
                                                    return;
                                                }
                                                setFilterParams({ ...filterParams, use: [...newUse] });
                                            }
                                        }}
                                    >
                                        {category.name}
                                    </button>
                                )
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default LandUseFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
    font-size: 14px;
`;

const titleBoxStyle = css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const subTitleBoxStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 0.6px solid #b1aeb8;
    .subTitleText {
        padding: 8px 14px;
        background-color: #ffffff;
        color: #0c002c;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        box-sizing: border-box;
        width: 100%;
    }
`;

const selectBoxRootStyle = css`
    display: flex;
    align-items: center;
`;

const selectBoxStyle = (from: string) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 12px 10px;
    gap: 8px;
    height: ${from === "out" ? "230px" : "180px"};
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;

const selectButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    background: ${Common.colors.grey02};
    transition: color 0.3s linear;
    font-size: 14px;
    font-weight: 500;
    ${active && {
        background: Common.colors.aucMarkerColor,
        color: "#ffffff",
    }}
`;

const summaryBoxStyle = css`
    padding: 8px 14px;
    display: flex;
    align-items: center;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;
