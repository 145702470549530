import { useQuery } from "react-query";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { IProduct } from "../stateLand/useStateLand";

export interface IProductList {
    list: IProduct[];
    totalCnt: number;
}

export const useFindAllProduct = (type: string, enabled: boolean = false, eventType?: number) => {
    return useQuery<IProductList>(
        ["productList"],
        async () => {
            const response = await axiosInstance
                .get(`${axiosPath.productListPath}`, { params: { saleType: type, eventType } })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>>>", err);
                    return err;
                });
            return response;
        },
        {
            enabled,
        },
    );
};

export const useFindAllGoStopProductList = (saleType?: string) => {
    return useQuery<IGoStopProduct>(
        ["goStopProduct", saleType],
        async () => {
            const res = await axiosInstance
                .get(`${axiosPath.goStopProductPath}?saleType=${saleType ?? 8}`)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("findError>>>", err);
                    throw err;
                });
            return res;
        },
        {
            enabled: false,
        },
    );
};

export interface IGoStopProduct {
    gostopList: IProduct[];
}
