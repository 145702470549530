import { css } from "@emotion/react";
import React from "react";
import ico_reload from "@src/assets/ico_reload.svg";
import { useStoreGoStopFilterStore } from "@src/store/StoreGoStopFilterStore";
import { number } from "echarts";

interface IStoreGoStopFooter {
    resetClick: () => void;
    finishClick: () => void;
    step: number;
    setStep: (val: number) => void;
}

const StoreGoStopFooter = ({ resetClick, finishClick, step, setStep }: IStoreGoStopFooter) => {

    const filterParams = useStoreGoStopFilterStore((state) => state.storeGoStopFilterState);

    const nextBtnClick = () => {
        setStep(2)
    }

    const prevBtnClick = () => {
        setStep(1)
    }

    return (
        <div css={rootBoxStyle(step)}>
            <button className="resetBtn" onClick={resetClick}>
                전체 초기화
            </button>
            {
                step === 2 &&
                <button onClick={prevBtnClick} className="prevBtn">이전</button>
            }
            <button
                className="nextBtn"
                disabled={step === 1 && filterParams.invest_price === 0}
                onClick={step === 1 ? nextBtnClick : finishClick}>{step === 1 ? "다음" : "완료"}</button>
        </div >
    );
};

const rootBoxStyle = (step: number) => css`
    background-color: white;
    width: 100%;
    border-top: 1px solid #dadada;
    bottom: 0;
    display: grid;
    grid: ${step === 1 ? "'. .'" : "'. . .'"};
    grid-template-columns: ${step === 1 ? "1fr 2fr" : "1fr 1fr 1fr"};
    z-index: 999;
    align-items: center;
    position: absolute;
    gap: 10px;
    padding: 14px 16px 24px 16px;

    & > button {
        font-size: 16px;
        border-radius: 14px;
        padding: 16px 0px;
    }

    .resetBtn {
        background-color: #FBF8F8;
        color: #505050;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .prevBtn {
        background-color: #DFF0FF;
        color: #0c002c;
    }
    
    .nextBtn {
        background-color: #2275ee;
        color: white;
    }
    
    .nextBtn:disabled {
        background-color: #DADADA;
        color: white
    }
    `;

export default StoreGoStopFooter;
