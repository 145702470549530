import { IProduct } from "@src/hooks/stateLand/useStateLand";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import { differenceInSeconds } from "date-fns";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import timer from "@src/assets/stateLand/sandglassIcon.json";
import eventText from "@src/assets/subs/eventMessage.png";
import { css } from "@emotion/react";
import { Radio } from "@mui/material";
import recommend from "@src/assets/subs/recommend.png";

interface Props {
    product: IProduct[];
    selectedProduct?: IProduct;
    onClickProduct: (val: IProduct) => void;
    type: string;
}

const IosRoadPlanProductCard = ({ product, selectedProduct, onClickProduct, type }: Props) => {
    const [now, setNow] = useState<any>(new Date());
    const showProduct = selectedProduct?.type == type ? selectedProduct : product[0];
    const getEventDuration = (now: any) => {
        const diffTime = differenceInSeconds(new Date(product[0].showEndAt).getTime(), now.getTime());
        const secound = diffTime % 60;
        const min = ((diffTime - secound) % 3600) / 60;
        const hours = ((diffTime - min * 60 - secound) % 86400) / 3600;
        const day = ((diffTime - min * 60 - secound - hours * 3600) % 31536000) / 86400;
        const formatTime = `${day}일 ${hours}시간 ${min}분 ${secound}초`;

        return formatTime;
    };

    useEffect(() => {
        return () => {
            setInterval(() => {
                setNow(new Date());
            }, 1000);
        };
    }, []);

    return (
        <>
            <div
                css={productCardBoxStyle(selectedProduct?.code === product[0].code, showProduct.isEvent)}
                onClick={() => {
                    onClickProduct(product[0]);
                }}
            >
                {showProduct.recommend === 1 && <img src={recommend} alt="" className="recommend" width={45} />}
                {Date.now() < 1710687600000 && Date.now() >= 1708959600000 && (
                    <div className="eventText">
                        <img src={eventText} alt="eventText" width={88} />
                        <div css={lastDiscountTextStyle}>{showProduct?.priceDiscountDisplay1}</div>
                    </div>
                )}
                <div css={cardContentsStyle}>
                    <div css={titleWrapStyle}>
                        <Radio
                            checked={true}
                            onChange={() => { }}
                            sx={{
                                width: 16,
                                height: 16,
                                color: selectedProduct?.type === type ? "#2275EE !important" : "#C9C9C9 !important",
                            }}
                        />
                        <div css={titleStyle}>
                            {showProduct?.type === "subscription" && showProduct.subsGroupName} {showProduct?.name}
                        </div>
                    </div>
                    <div css={priceWrapStyle}>
                        {showProduct?.term1DiscountedPrice !== showProduct.price && (
                            <p css={priceInfoStyle}>{convertNumberToKorean(showProduct.price)}원</p>
                        )}
                        {showProduct?.term2DiscountedPrice ? (
                            <>
                                <div css={term2PriceStyle}>
                                    <p css={discountTextStyle}>
                                        -{Math.floor(100 - (showProduct?.term2DiscountedPrice / showProduct?.price) * 100)}%
                                    </p>
                                    <p css={discountPriceTextStyle}>
                                        {convertNumberToKorean(showProduct?.term2DiscountedPrice)}원
                                    </p>
                                </div>
                                <div css={term1PriceStyle}>
                                    <div css={lastDiscountTextStyle}>
                                        {showProduct?.priceDiscountDisplay1
                                            ? showProduct?.priceDiscountDisplay1
                                            : `-${Math.floor(
                                                100 - (showProduct?.term1DiscountedPrice / showProduct?.price) * 100,
                                            )}%`}
                                    </div>
                                    <span className="priceText">
                                        {convertNumberToKorean(showProduct?.term1DiscountedPrice)}원
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div css={term1PriceStyle}>
                                    <div css={lastDiscountTextStyle}>
                                        {`-${Math.floor(100 - (showProduct?.term1DiscountedPrice / showProduct?.price) * 100)}%`}
                                    </div>
                                    <span className="priceText">
                                        {convertNumberToKorean(showProduct?.term1DiscountedPrice)}원
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {showProduct.isEvent && now >= new Date(showProduct.showStartAt) && now <= new Date(showProduct.showEndAt) && (
                    <div css={eventBoxStyle}>
                        <Lottie className="lottie" animationData={timer} />
                        {getEventDuration(now)}
                    </div>
                )}
            </div>
        </>
    );
};

export default IosRoadPlanProductCard;

const productCardBoxStyle = (active: boolean, isEvent: boolean) => css`
    border-radius: 14px;
    border: ${active ? "2px solid #2275EE" : "1px solid #dadada"};
    background-color: #ffffff;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 14px;
    margin: 14px 0;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    & > .recommend {
        position: absolute;
        transform: translate(-50%, -50%);
        top: -1px;
    }
    & > .eventText {
        position: absolute;
        bottom: ${isEvent ? "33px" : "2px"};
        left: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        /* width: 88px;
        height: 58px; */
    }
    & > .eventDateText {
        position: absolute;
        top: -15px;
        right: 14px;
    }
    ${isEvent && {
        paddingBottom: "44px",
    }};
`;

const cardContentsStyle = css`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
`;

const titleWrapStyle = css`
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    left: 0;
    max-width: 65vw;
`;
const titleStyle = css`
    overflow: hidden;
    color: #0c002c;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
`;

const priceWrapStyle = css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    box-sizing: border-box;
    width: 45%;
`;

const term1PriceStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    .priceText {
        color: #f00000;
        font-family: "M PLUS ExtraBold";
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;
    }
`;

const term2PriceStyle = css`
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    display: flex;
    align-items: center;
    gap: 4px;
`;

const discountTextStyle = css`
    color: #0047ff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`;

const lastDiscountTextStyle = css`
    border-radius: 100px;
    background: var(--PrimaryColor-BlueColor, #0047ff);
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    min-width: 36px;
`;

const priceInfoStyle = css`
    color: #8d8d8d;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration: line-through;
    text-decoration-line: strikethrough;
`;

const discountPriceTextStyle = css`
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: line-through;
    text-decoration-line: strikethrough;
`;

const eventBoxStyle = css`
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff500;
    border-radius: 0 0 14px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lottie {
        height: 40px;
        width: 40px;
    }
`;
