import create from "zustand";

type TOKEN_STORE_TYPE = {
    accessToken: string;
    setAccessToken: (accessToken: string) => void;
};

export const useTokenStore = create<TOKEN_STORE_TYPE>((set) => ({
    accessToken: (typeof window !== "undefined" && localStorage.getItem("accessToken")?.toString()) || "",
    setAccessToken: (accessToken: string) => {
        set({ accessToken });
    },
}));

export const setAccessTokenFunc = (accessToken: string) => {
    useTokenStore.setState((prev) => {
        return {
            ...prev,
            accessToken,
        };
    });
};

type CHECK_PERMISSION_TYPE = {
    permission: string;
    setPermission: (permission: string) => void;
};

export const checkPermissionStore = create<CHECK_PERMISSION_TYPE>((set) => ({
    permission: "",
    setPermission: (permission: string) => {
        set({ permission });
    },
}));

export const setPermissionFunc = (permission: string) => {
    checkPermissionStore.setState((prev) => {
        return {
            permission,
        };
    });
};
