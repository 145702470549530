import React, { useState } from "react";
import { css } from "@emotion/react";
import tooltipIcon from "@src/assets/stateLand/tooltipIcon.svg";
import ConfirmModal from "@src/components/popup/ConfirmModal";

interface Props {
    title: string;
    styles?: any;
}

const StateLandFilterHeader: React.FC<Props> = ({ title, styles }) => {
    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const classificationDesc = () => {
        return (
            <>
                <p css={descTitleStyle}>분류 기준</p>
                <div css={descTextStyle}>
                    <p>∙</p>
                    <p>일반재산: 온비드에서 정보를 제공하는 국·공유지 일반재산</p>
                </div>
                <div css={descTextStyle}>
                    <p>∙</p>
                    <p>일반재산 외: 행정재산을 포함한 매각/대부가 불가능하거나 정보가 제공되지 않은 국·공유지 재산</p>
                </div>
            </>
        );
    };

    const assetManageDesc = () => {
        return (
            <>
                <p css={descTitleStyle}>일반재산 관리상태</p>
                <div css={descTextStyle}>관리상태는 온비드에 공개된 국·공유지 일반재산만 정보가 제공됩니다.</div>
                <div css={descTextStyle}>
                    <p>∙</p>
                    <p>매각대상: 매각가능 물건으로 재산관리기관에 문의하여 매각할 수 있는 물건</p>
                </div>
                <div css={descTextStyle}>
                    <p>∙</p>
                    <p>대부대상: 대부가능 물건으로 재산관리기관에 문의하여 매각할 수 있는 물건</p>
                </div>
                <div css={descTextStyle}>
                    <p>∙</p>
                    <p>대부 사용중: 현재 대부재산으로 사용중인 물건</p>
                </div>
                <div css={descTextStyle}>
                    <p>∙</p>
                    <p>매각제한: 매각이 제한되는 물건</p>
                </div>
            </>
        );
    };

    return (
        <>
            <div css={[rootBoxStyle, styles]}>
                <div css={titleBoxStyle}>
                    {title}
                    {/(분류|일반재산 관리상태)/.test(title) && (
                        <div className="tooltipBox">
                            <img
                                src={tooltipIcon}
                                alt=""
                                // onMouseDown={() => setOpenTooltip(true)}
                                onClick={() => setOpenTooltip(true)}
                            />
                            {/* <div css={tooltipBoxStyle(openTooltip)}>
                                {title === "분류" ? classificationDesc() : assetManageDesc()}
                            </div> */}
                        </div>
                    )}
                </div>
            </div>
            {openTooltip && (
                <ConfirmModal
                    resultMessage={title === "분류" ? classificationDesc() : assetManageDesc()}
                    onConfirm={() => setOpenTooltip(false)}
                />
            )}
        </>
    );
};

export default StateLandFilterHeader;

const rootBoxStyle = css`
    padding: 14px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const titleBoxStyle = css`
    display: flex;
    align-items: center;
    gap: 4px;
    .tooltipBox {
        position: relative;
        display: flex;
        align-items: center;
    }
`;

const tooltipBoxStyle = (open: boolean) => css`
    transition: all 200ms ease-in;
    visibility: ${open ? "visible" : "hidden"};
    opacity: ${open ? 1 : 0};
    position: absolute;
    background-color: #ffffff;
    width: 232px;
    padding: 14px;
    box-sizing: border-box;
    border-radius: 14px;
    top: 24px;
    z-index: 2;
    left: 0;
`;

const descTitleStyle = css`
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 8px;
`;

const descTextStyle = css`
    color: #3c3c3c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    white-space: pre-wrap;
    gap: 4px;
`;
