import React, { useState } from "react";

import ico_arrow_down_bold from "@src/assets/ico_arrow_down_bold.svg";
import { Button } from "@src/components";
import { useStoreGoStopFilterStore } from "@src/store/StoreGoStopFilterStore";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { ICursorType } from "@src/hooks/goStop/dto/goStop.dto";

interface IStoreGoStopBottomSheetTitle {
    buttonWrapper: any;
    sort: any;
    totalCount: number;
    showSortBox: boolean;
    firstButtonRef: any;
    secondButtonRef: any;
    thirdButtonRef: any;
    onChangeShowSortBox: () => void;
}

const StoreGoStopBottomSheetTitle = ({
    buttonWrapper,
    sort,
    totalCount,
    showSortBox,
    firstButtonRef,
    secondButtonRef,
    thirdButtonRef,
    onChangeShowSortBox,
}: IStoreGoStopBottomSheetTitle) => {
    const sortTypeOptions = [
        { id: 19, label: "수익률 높은순" },
        { id: 20, label: "수익률 낮은순" },
        { id: 15, label: "시세차익 높은순" },
        { id: 16, label: "시세차익 낮은순" },
        { id: 5, label: "매각기일 가까운순" },
        { id: 0, label: "업데이트 최신순" },
        { id: 1, label: "감정가 높은순" },
        { id: 2, label: "감정가 낮은순" },
        { id: 4, label: "최저가 낮은순" },
        { id: 3, label: "최저가 높은순" },
        { id: 7, label: "유찰횟수 많은순" },
        { id: 8, label: "유찰횟수 낮은순" },
    ];
    const filterParams = useStoreGoStopFilterStore((state) => state.storeGoStopFilterState);
    const setFilterParams = useStoreGoStopFilterStore((state) => state.setStoreGoStopFilterState);

    return (
        <>
            <div css={rootBoxStyle}>
                <div css={titleButtonBoxStyle} ref={buttonWrapper}>
                    <Button
                        label="전체"
                        color={filterParams.type == 0 ? "newBlue" : "lightBlue"}
                        size="small"
                        styles={{
                            width: "33%",
                            height: "100%",
                            fontSize: "0px",
                            fontWeight: 400,
                            lineHeight: "175%",
                            letterSpacing: "-0.4px",
                            transition: "none",
                            border: "0px",
                            padding: "0px"
                        }}
                        handleClick={() => setFilterParams({ ...filterParams, type: 0 })}
                        buttonRef={firstButtonRef}
                    />
                    <Button
                        label="경매"
                        color={filterParams.type == 1 ? "newBlue" : "lightBlue"}
                        size="small"
                        styles={{
                            width: "33%",
                            height: "100%",
                            fontSize: "0px",
                            fontWeight: 400,
                            lineHeight: "175%",
                            letterSpacing: "-0.4px",
                            transition: "none",
                            border: "0px",
                            padding: "0px",
                        }}
                        handleClick={() => setFilterParams({ ...filterParams, type: 1 })}
                        buttonRef={secondButtonRef}
                    />
                    <Button
                        label="공매"
                        color={filterParams.type == 2 ? "newBlue" : "lightBlue"}
                        size="small"
                        styles={{
                            width: "33%",
                            height: "100%",
                            fontSize: "0px",
                            fontWeight: 400,
                            lineHeight: "175%",
                            letterSpacing: "-0.4px",
                            transition: "none",
                            border: "0px",
                            padding: "0px",
                        }}
                        handleClick={() => setFilterParams({ ...filterParams, type: 2 })}
                        buttonRef={thirdButtonRef}
                    />
                </div>
                <div css={subTitleBoxStyle}>
                    <div css={dataListBoxStyle}>
                        총 매물<span>{totalCount}</span>건
                    </div>
                    <div ref={sort} css={sortBoxStyle} onClick={onChangeShowSortBox}>
                        {sortTypeOptions.find((option) => option.id === filterParams.sortType)?.label}
                        <div />
                    </div>
                </div>
            </div >
        </>
    );
};

export default StoreGoStopBottomSheetTitle;

const rootBoxStyle = css`
    width: 100%;
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const titleButtonBoxStyle = css`
    display: flex;
    gap: 4px;
    width: 100%;
    height: 0px;
    border-radius: 8px;
`;

const subTitleBoxStyle = css`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`;

const dataListBoxStyle = css`
    text-align: center;
    ${Common.textStyle.title6_R14};
    color: ${Common.colors.black};
    & > span {
        margin-left: 4px;
        margin-right: 4px;
        color: ${Common.colors.notiColor};
    }
`;

const sortBoxStyle = css`
    text-align: center;
    color: ${Common.colors.black};
    ${Common.textStyle.title6_R14}
    display: none;
    & > div {
        background: url(${ico_arrow_down_bold});
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        margin-left: 6px;
    }
`;
