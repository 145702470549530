import { css } from "@emotion/react";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import React from "react";
import closeIcon from "@src/assets/subscription/grayCloseIcon.svg";

interface Props {
    selectedProductList?: IProduct[] | any;
    setSelectedProductList?: (val: IProduct[]) => void;
    selectedProductMultiList?: IProduct[] | any;
    landRef: any;
}

const BottomInfoMultiple = ({ selectedProductList, landRef, setSelectedProductList, selectedProductMultiList }: Props) => {
    return (
        <>
            {selectedProductMultiList.length > 0 && (
                <div css={singleContainer}>
                    <div css={titleContainer}>
                        <p>개별 상품</p>
                        <button
                            onClick={() => {
                                landRef?.current.scrollIntoView({
                                    block: "start",
                                    behavior: "smooth",
                                });
                            }}
                        >
                            변경
                        </button>
                    </div>
                    {selectedProductMultiList.map((ele: IProduct) => {
                        return (
                            <div key={ele.code} css={productContainer}>
                                <p className="">
                                    {ele?.subsGroupName} {ele?.name}
                                </p>
                                <button
                                    onClick={() => {
                                        setSelectedProductList &&
                                            setSelectedProductList(
                                                selectedProductList.filter((data: any) => data.code !== ele.code),
                                            );
                                    }}
                                >
                                    <img src={closeIcon} alt="" />
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default BottomInfoMultiple;

const singleContainer = css``;

const titleContainer = css`
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
        font-size: 14px;
        color: #8d8d8d;
    }
    & > button {
        color: white;
        background: #2275ee;
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 16px;
    }
    margin-bottom: 8px;
`;

const productContainer = css`
    display: flex;
    margin: 8px 0px;
    color: #0c002c;
    background-color: #f7f8fa;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    & > p {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
