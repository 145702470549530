import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";
import geobuf from "geobuf";
import Pbf from "pbf";
import { Buffer } from "buffer";

export const useFindStateLand = async (params: IStateLandParams) => {
    const result = await axiosInstance
        .post(axiosPath.stateLandPath, params, { timeout: 15000 })
        .then((res) => {
            const newRes = res?.data?.list;
            return newRes;
        })
        .catch((Err) => {
            console.log("Err>>", Err);
            return [];
        });
    return result;
};

//*국공유지 폴리곤 정보
export const useFindAllStateLand = (params: IStateLandParams) => {
    return useQuery<IStateLand[]>(
        ["stateLand", params, params?.bounds?.getNE(), params?.bounds?.getSW()],
        async () => {
            const NE = params?.bounds?.getNE();
            const SW = params?.bounds?.getSW();
            const ne = { latd: NE.lat(), lntd: NE.lng() };
            const sw = { latd: SW.lat(), lntd: SW.lng() };
            const newParams = {
                filter: params.filter,
                ne,
                sw,
            };
            const result = await axiosInstance
                .post(axiosPath.stateLandPath, newParams, { timeout: 15000 })
                .then((res) => {
                    const newRes = res?.data?.list;
                    return newRes;
                })
                .catch((Err) => {
                    console.log("Err>>", Err);
                    return [];
                });
            return result;
        },
        {
            enabled: false,
        },
    );
};

//*국공유지 폴리곤 정보
export const useFindAllStateLandV2 = (params: IStateLandParams) => {
    return useQuery<IStateLand[]>(
        ["stateLand", params, params?.bounds?.getNE(), params?.bounds?.getSW()],
        async () => {
            const NE = params?.bounds?.getNE();
            const SW = params?.bounds?.getSW();
            const ne = { latd: NE.lat(), lntd: NE.lng() };
            const sw = { latd: SW.lat(), lntd: SW.lng() };
            const newParams = {
                filter: params.filter,
                ne,
                sw,
            };
            const result = await axiosInstance
                .post(axiosPath.stateLandPathV2, newParams, { timeout: 15000 })
                .then((res) => {
                    const newRes = res?.data?.[0]?.encode;
                    //! GeoBuf 디코딩
                    if (newRes === null) {
                        return [];
                    }
                    const binaryData = Buffer.from(newRes, "base64");
                    const decodedData: any = geobuf.decode(new Pbf(binaryData));

                    const resData = decodedData?.features?.map((val: any) => ({
                        geometry: val?.geometry,
                        altitude: val?.properties?.altitude,
                        ownedBy: val?.properties?.statepublic ?? val?.properties?.own_type,
                        pnu: val?.properties?.pnu,
                        status: val?.properties?.status,
                        jimok: val?.properties?.jimok_book ?? val?.properties?.jimok,
                    }));
                    return resData;
                })
                .catch((Err) => {
                    console.log("Err>>", Err);
                    return [];
                });
            return result;
        },
        {
            enabled: false,
        },
    );
};

//* 국공유지 정보
export const useFindOneStateLandInfo = (pnu: string) => {
    return useQuery<IStateLandInfo>(
        ["stateLandInfo", pnu],
        async () => {
            const result = await axiosInstance
                .get(`${axiosPath.stateLandInfoPath}/${pnu}`)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>");
                    throw err;
                });
            return result;
        },
        { enabled: false },
    );
};

//*국공유지 비구매지역 딤처리 폴리곤
export const useFindDimPolygon = (params: IStateLandDimPolygonParams) => {
    return useQuery<IStateLandDimPolygon>(
        ["regionDimStateLand", params, params?.bounds?.getNE(), params?.bounds?.getSW()],
        async () => {
            const NE = params?.bounds?.getNE();
            const SW = params?.bounds?.getSW();
            const sw = { latd: SW.lat(), lntd: SW.lng() };
            const ne = { latd: NE.lat(), lntd: NE.lng() };
            const newParams = {
                sw,
                ne,
            };
            const result = await axiosInstance
                .post(axiosPath.stateLandDimPolygons, newParams, { timeout: 15000 })
                .then((res) => {
                    const newRes = res?.data;
                    return newRes;
                })
                .catch((Err) => {
                    console.log("Err>>", Err);
                    return [];
                });
            return result;
        },
        {
            enabled: false,
        },
    );
};

export interface IStateLandParams {
    ne?: {
        latd: number;
        lntd: number;
    };
    sw?: {
        latd: number;
        lntd: number;
    };
    bounds: any;
    filter: IStateLandFilter;
}

export interface IStateLandFilter {
    ownedBy: string[]; //소유 구분 (국유지 , 시도유지, 군유지, 기타단체)[]
    location: string[]; //소재지 (전국: [], 그 외: 소재지의 cortarNo 배열)[]
    usage: string[]; //용도 (대지 , 농지, 임야, 도로, 잡종지, 과수원 , 공장용지 , 창고용지, 목장용지 , 주차장, 기타 )[]
    type: string[]; //분류 (일반재산 , 일반재산외)[]
    status: string[]; //관리상태 (매각대상, 대부대상, 대부사용중, 점유자 미확인)[]
    area?: any[];
    pyong?: any[];
}
export interface IStateLandInfo {
    ownedBy: string;
    status: string;
    managedBy: string;
    isPremeumUser: boolean;
    isShow: boolean;
    updatedAt: string;
    contact: string;
    propType?: string;
    kamcoId?: string;
    contactSuffix?: string;
}

export interface IStateLand {
    pnu: string;
    jimok: string;
    address: string;
    altitude: string;
    ownedBy: string;
    status: string;
    geometry: {
        type: string;
        coordinates: any[];
    };
}

export interface IStateLandDimPolygon {
    list: {
        type: string;
        bjdName: string;
        bjdFullName: string;
        bjdCode: string;
        geometry: {
            type: string;
            coordinates: any;
        };
    }[];
    unpurchasedRegions: boolean;
}

export const defaultStateLandParams: IStateLandFilter = {
    ownedBy: [],
    location: [],
    usage: [],
    type: [],
    status: [],
    pyong: [null, null],
};

export interface IStateLandDimPolygonParams {
    sw?: {
        lntd: number;
        latd: number;
    };
    ne?: {
        lntd: number;
        latd: number;
    };
    bounds: any;
}
