import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import React, { useEffect, useState } from "react";

type PriceType = {
    value: string;
    title: string;
};

const initialStartValue = {
    value: "",
    title: "전체",
};

const initialEndValue = {
    value: "",
    title: "",
};

const useRangeSelected = (selected?: string[]) => {
    const [startValue, setStartValue] = useState<PriceType>(initialStartValue);
    const [endValue, setEndValue] = useState<PriceType>(initialEndValue);

    useEffect(() => {
        if (selected) {
            if (selected[0].length || selected[1].length) {
                const mappingTitleItemData = itemForAppraisedPrice.filter((item) => {
                    if (item.value === selected[0]) return item;

                    if (item.value === selected[1]) return item;
                });

                if (selected[0] === "30000000000") {
                    setStartValue({
                        title: "300억~",
                        value: "higher",
                    });

                    setEndValue({
                        title: "300억~",
                        value: "higher",
                    });

                    return;
                }

                setStartValue(mappingTitleItemData[0]);
                setEndValue(mappingTitleItemData[1]);
            }
        }
    }, [selected]);

    const IsEmptyStartValue = () => !startValue.value.length;
    const IsEmptyEndValue = () => !endValue?.value?.length;

    const onClickValue = (price: string, title: string) => {
        if (title === "전체") {
            setStartValue({ value: "", title: "전체" });
            setEndValue({ value: "", title: "" });
            return;
        }

        if (price === "higher") {
            setStartValue({ value: "higher", title: "higher" });
            setEndValue({ value: "higher", title: "300억 ~" });
            return;
        }

        if (IsEmptyStartValue()) {
            return setStartValue({
                value: price,
                title: title,
            });
        }

        if (IsEmptyEndValue()) {
            return setEndValue({
                value: price,
                title: title,
            });
        }

        if (!IsEmptyEndValue() && !IsEmptyStartValue()) {
            setEndValue({
                title: "",
                value: "",
            });
            setStartValue({
                value: price,
                title: title,
            });
        }
    };

    const onClickReset = () => {
        setStartValue({
            title: "전체",
            value: "",
        });
        setEndValue({
            title: "전체",
            value: "",
        });
    };

    useEffect(() => {
        if (endValue && startValue && endValue.value.length && Number(startValue.value) > Number(endValue.value)) {
            setStartValue(endValue);
            setEndValue(startValue);
        }
    }, [startValue, endValue]);

    return { startValue, endValue, onClickValue, onClickReset };
};

export default useRangeSelected;
