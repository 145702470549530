import { useEffect, useRef } from "react";

export const useObserver = (navNumber: string, setNavNumber: React.Dispatch<React.SetStateAction<string>>) => {
    const options = navNumber === "topRef" ? {} : { rootMargin: "-50% 0% -50% 0%" };
    const refElement = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const osv = new IntersectionObserver((entries, obxserver) => {
            if (entries[0].isIntersecting) {
                setNavNumber(navNumber); // 뷰포트에 나타난 배너가 무엇인지 갱신해준다.
            }
        }, options);
        if (refElement.current) {
            osv.observe(refElement.current);
        }
        return () => osv.disconnect();
    }, []);
    return refElement; // 관찰하는 대상 요소의 ref를 return 한다.
};
