import { IProduct } from "@src/hooks/stateLand/useStateLand";

export const ConvertPeriodSubs = (data: IProduct) => {
    if (data?.renewTerm === "monthly") {
        return "매달";
    } else if (data?.renewTerm === "yearly") {
        return "매년";
    } else {
        switch (data?.serveDurationType) {
            case "day":
                return `${data?.serveDuration}일`;
            case "month":
                return `${data?.serveDuration}개월`;
            case "year":
                return `${data?.serveDuration}년`;
        }
    }
};
