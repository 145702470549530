import { css } from "@emotion/react";
import BottomSheet from "@src/components/bottomSheet/BottomSheet";
import BottomSheetContents from "@src/components/bottomSheet/BottomSheetContents";
import Item from "@src/components/Item/Item";
import { useFindAllItemList, useFindAllSearchItemListV2 } from "@src/hooks/item/useGetItem";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ico_arrow_down from "@src/assets/boldArrowDown.png";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import useCallToken from "@src/hooks/useCallToken";
import { debounce, isEmpty } from "lodash";
import { removeAll, useFilterStore } from "@src/store/filterStore";
import styled from "@emotion/styled";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import RecentSearchList from "@src/components/Item/RecentSearchList";
import noListImg from "../assets/noRecentList.png";
import { Common } from "@src/styles/Common";
import Lottie from "lottie-react";
import loading from "../assets/Loading.json";
import { usePushSearchKeyword } from "@src/hooks/item/useRecentSearchList";
import NewItemFilter, { initialFilterParams } from "./newFilter/ItemNewFilter";
import { useNewFilterMapCheckStore, useNewFilterSearchText, useNewFilterStore } from "@src/store/newFilterStore";

interface Props {}

export const NewItems: React.FC<Props> = () => {
    const { path } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const [params, setParams] = useState(initialFilterParams);
    const [selectedSortId, setSelectedSortId] = useState<number | undefined>(5);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [isFocusSearch, setIsFocusSearch] = useState<boolean>(false);
    const [isPushKeyword, setIsPushKeyword] = useState<boolean>(true);

    const containerRef = useRef() as any;

    const selectedSido = useFilterStore((state) => state.selectedOutSido);
    const setSelectedSido = useFilterStore((state) => state.setSelectedOutSido);
    const searchInputValue = useFilterStore((state) => state.searchKeyword);
    const setSearchInputValue = useFilterStore((state) => state.setSearchKeyword);
    const filterState = useNewFilterStore((state) => state.newFilterState);
    const filterSearchText = useNewFilterSearchText((state) => state.newFilterSearchText);
    const setStoreFilterState = useNewFilterStore((state) => state.setStoreNewFilterState);
    const filterCheck = useNewFilterMapCheckStore((state) => state.newFilterMapCheckState);
    const getFilterHistory = useFilterStore((state) => state.getFilterHistory);
    const visible = useAiFilterStore((state) => state.visible);
    const setVisible = useAiFilterStore((state) => state.setVisible);
    const dimVisible = useFilterStore((state) => state.dimVisible);
    const postFilterHistory = useFilterStore((state) => state.postFilterHistory);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);
    const [userFilter, setUserFilter] = useState(false);
    const [openOutFilter, setOpenOutFilter] = useState<string>("");
    const [userLocation, setUserLocation] = useState({
        latd: 0,
        lntd: 0,
    });

    const newText =
        Number(location.search.split("=")[1]) === 0
            ? "업데이트순"
            : Number(location.search.split("=")[1]) === 1
            ? "감정가 높은순"
            : Number(location.search.split("=")[1]) === 2
            ? "감정가 낮은순"
            : Number(location.search.split("=")[1]) === 3
            ? "최저가 높은순"
            : Number(location.search.split("=")[1]) === 4
            ? "최저가 낮은순"
            : Number(location.search.split("=")[1]) === 5
            ? "매각기일 가까운순"
            : Number(location.search.split("=")[1]) === 7
            ? "유찰회수 많은순"
            : Number(location.search.split("=")[1]) === 8
            ? "유찰회수 적은순"
            : "매각기일 가까운순";
    const [sortStatus, setSortStatus] = useState<string | undefined>(newText);
    const [initData, setInitData] = useState(false);

    useEffect(() => {
        setStoreFilterState({ ...filterState, sort: selectedSortId ?? 5 });
    }, [selectedSortId]);
    useEffect(() => {
        const type = searchParams.get("type");
        if (type === "auction") {
            setStoreFilterState({
                ...filterState,
                type: 1,
            });
        } else if (type === "publicAuction") {
            setStoreFilterState({
                ...filterState,
                type: 2,
                sales_method: filterState.sales_method,
                assets: filterState.assets,
            });
        } else return;
    }, [searchParams.get("type")]);

    const { data, refetch, hasNextPage, fetchNextPage, isFetching } = useFindAllSearchItemListV2(
        userLocation.latd !== 0 && userLocation.lntd !== 0
            ? {
                  itemFilter: filterState,
                  searchText: filterSearchText,
                  userLocation: userLocation,
              }
            : {
                  itemFilter: filterState,
                  searchText: filterSearchText,
              },
        false,
    );

    const { token } = useCallToken();

    const onChangeParams = useCallback(
        debounce((newParam: any) => {
            setParams(newParam);
        }, 500),
        [],
    );

    useEffect(() => {
        transferToNative("", "onClickUserLoButton");
    }, []);

    WebAppBridge.setUserLocationInMap = (lat: string, lng: string) => {
        setUserLocation({
            latd: Number(lat),
            lntd: Number(lng),
        });
        setUserFilter(true);
        setInitData(true);
    };

    // useEffect(() => {
    //     setUserLocation({
    //         latd: Number(32.751259),
    //         lntd: Number(130.8197),
    //     });
    //     setUserFilter(true);
    //     setInitData(true);
    // }, []);

    useEffect(() => {
        if (!filterCheck) {
            onChangeParams({
                ...params,
                filter: isShowFilter
                    ? {
                          ...filterState,
                          status: isEmpty(filterState.status) ? ["0"] : filterState.status,
                          case_number: searchInputValue,
                          auction_master_item: filterState.auction_master_item[0] == 0 ? [] : filterState.auction_master_item,
                          use: makeUseParam(filterState.use),
                          loc: removeAll(filterState.loc),
                      }
                    : { ...initialFilterParams, status: [0], sort: selectedSortId },
            });
        }
    }, [filterState.sort, isShowFilter, filterCheck, searchInputValue]);
    useEffect(() => {
        refetch();
        if (JSON.stringify(filterState) !== JSON.stringify(initialFilterParams)) {
            setUserLocation({
                latd: 0,
                lntd: 0,
            });
        }
    }, [filterState, filterSearchText]);

    useEffect(() => {
        refetch();
        if (userFilter && userLocation.latd === 0 && userLocation.lntd === 0) {
            setUserFilter(false);
        }
    }, [userLocation]);

    useEffect(() => {
        if (data) {
            containerRef?.current?.scrollIntoView({ block: "end" });
        }
    }, [data]);

    useEffect(() => {
        if (selectedSido === "" && userLocation.latd !== 0 && userLocation.lntd !== 0) {
            setUserLocation({
                latd: 0,
                lntd: 0,
            });
        }
    }, [selectedSido]);

    const [sentryRef] = useInfiniteScroll({
        loading: isFetching,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 300px 0px",
        delayInMs: 10,
    });

    const BottomSheetArea = useMemo(
        () => (
            <BottomSheet visible={visible}>
                <BottomSheetContents
                    setSelectedSortId={setSelectedSortId}
                    setSortStatus={setSortStatus}
                    type="list"
                    currentValueId={Number(location.search.split("=")[1])}
                    currentValue={location.search.split("=")[1]}
                />
            </BottomSheet>
        ),
        [visible, selectedSortId, params],
    );

    useEffect(() => {
        setIsPushKeyword(true);
    }, [searchInputValue]);

    useEffect(() => {
        if (isFocusSearch && !searchInputValue) {
            setSearchParams(new URLSearchParams({ history: "on" }));
        } else {
            setSearchParams(Object.fromEntries(searchParams));
        }
    }, [searchInputValue]);

    useEffect(() => {
        if (searchInputValue.length) {
            setIsShowFilter(false);
        } else {
            setIsShowFilter(true);
        }
    }, [searchInputValue]);
    return (
        <>
            <StyledItems>
                <div css={FilterArea}>
                    <NewItemFilter
                        from="item"
                        isShowFilter={isShowFilter}
                        setIsShowFilter={setIsShowFilter}
                        searchInputValue={searchInputValue}
                        setSearchInputValue={setSearchInputValue}
                        isFocusSearch={isFocusSearch}
                        setIsFocusSearch={setIsFocusSearch}
                        setIsPushKeyword={setIsPushKeyword}
                        isPushKeyword={isPushKeyword}
                        fetchData={(type: string) => {}}
                        isDisableFilter={!isShowFilter}
                        selectedSido={selectedSido}
                        setSelectedSido={setSelectedSido}
                        openOutFilter={openOutFilter}
                        setOpenOutFilter={(val: string) => setOpenOutFilter(val)}
                    />
                    {!filterCheck && (
                        <div css={ListInfo}>
                            <span>
                                총 매물 <span className="color-text">{data?.pages?.[0]?.totalCnt?.toLocaleString() ?? 0}</span> 건
                            </span>
                            <button onClick={() => setVisible(!visible)}>
                                {sortStatus} <img src={ico_arrow_down} />
                            </button>
                        </div>
                    )}
                </div>
                <Container ref={containerRef} onTouchStart={() => (document.activeElement as HTMLElement).blur()}>
                    {data && data?.pages?.length && data?.pages?.[0]?.totalCnt
                        ? data?.pages?.map((page, i) =>
                              page?.list?.map((item: any, index: number) => (
                                  <Item
                                      onClick={async () => {
                                          isPushKeyword &&
                                              searchInputValue.length > 1 &&
                                              usePushSearchKeyword(searchInputValue, item.item_id);
                                          setIsPushKeyword(false);
                                          await postFilterHistory(filterState);
                                          setUpdateHistoryFilterFlag(false);
                                          await getFilterHistory();
                                          if (localStorage.getItem("isSubscription") === "false") {
                                              transferToNative("", "showSubscriptionPopup");
                                          } else {
                                              transferToNative(JSON.stringify(item), "onClickItem");
                                          }
                                      }}
                                      key={`${item?.item_id}_${index}`}
                                      item_id={item.item_id}
                                      bid_type={item.bid_type}
                                      case_number={item.case_number}
                                      is_favorited={item.is_favorited}
                                      use={item.use}
                                      skp_cnt={item.skp_cnt}
                                      skp_cnt_str={item.skp_cnt_str}
                                      image={item.image}
                                      address={item.address}
                                      mid_adrs={item.mid_adrs}
                                      est_price={item.est_price}
                                      est_price_str={item.est_price_str}
                                      min_price={item.min_price}
                                      min_price_str={item.min_price_str}
                                      rate={item.rate}
                                      status={item.status}
                                      d_day={item.d_day}
                                      due_date_str={item.due_date_str}
                                      due_date={item.due_date}
                                      somethings={item.somethings}
                                      updated_at={item.updated_at}
                                      next_it={item.next_it}
                                      status_detail={item?.status_detail}
                                      sale_price_str={item.sale_price_str}
                                      sale_price_rate={item.sale_price_rate}
                                      card_type={item.card_type}
                                      date_info={item.date_info}
                                  />
                              )),
                          )
                        : data &&
                          !data?.pages?.[0]?.total &&
                          !isFetching && (
                              <StyledNoList>
                                  <div className="content">
                                      <img className="noListImg" src={noListImg} alt="" />
                                      <div className="noListText">일치하는 매물이 없습니다</div>
                                      {userFilter && (
                                          <button css={locButtonStyle} onClick={() => setOpenOutFilter("loc")}>
                                              소재지 선택하기
                                          </button>
                                      )}
                                  </div>
                              </StyledNoList>
                          )}
                    {isFetching && (
                        <StyledNoList>
                            <div className="content">
                                <div className="lottie">
                                    <Lottie animationData={loading} />
                                </div>
                                {!data?.pages?.[0]?.total && <div className="loadingText">매물을 불러오는 중입니다</div>}
                            </div>
                        </StyledNoList>
                    )}
                    <div style={{ width: "100px", marginTop: "-720px", height: "50px" }} ref={sentryRef} />
                </Container>
                {/* <Dim visible={dimVisible} /> */}
                {searchParams.get("history") === "on" && (
                    <RecentSearchList
                        setSearchInputValue={setSearchInputValue}
                        setIsFocusSearch={setIsFocusSearch}
                        isPushKeyword={isPushKeyword}
                        setIsPushKeyword={setIsPushKeyword}
                        fetchData={(type: string, textValue: string) => {
                            setSearchParams();
                        }}
                        searchInputValue={searchInputValue}
                    />
                )}
                {BottomSheetArea}
            </StyledItems>
        </>
    );
};

const locButtonStyle = css`
    background-color: #0047ff;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
`;

const StyledItems = styled.div`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    overflow: hidden;
`;

const ListInfo = css`
    display: flex;
    justify-content: space-between;
    height: 46px;
    padding: 0 14px;
    align-items: center;
    background-color: #ffffff;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${Common.colors.normalTextColor};
        .color-text {
            /* color: #9a49f2; */
            color: ${Common.colors.normalTextColor};
        }
    }
    button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${Common.colors.normalTextColor};
        img {
            margin-left: 6px;
            width: 12px;
            height: 7px;
        }
    }
`;

const Container = styled.div`
    min-height: 100vh;
    max-height: 100vh;
    /* padding: 0px 16px; */
    padding-top: 156px;
    z-index: 22;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const FilterArea = css`
    position: fixed;
    z-index: 9998;
    width: 100%;
    /* background: #ffffff; */
    /* padding: 0px 14px; */
`;

const StyledNoList = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .content {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 23px;
        .lottie {
            width: 80px;
            height: 34px;
        }
        .noListImg {
            width: 50px;
            height: 60px;
        }
        .noListText {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
        }
        .loadingText {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
            margin-top: 33px;
        }
    }
`;

const StyledToast = styled.div<{ isShowToast: boolean }>`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${(props) => (props.isShowToast ? 0.7 : 0)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 10000;
    border-radius: 14px;
    position: absolute;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
    left: 50%;
    transform: translate(-50%, 0);
`;
