import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";

interface SortBottomSheetProps {
    sortStatus: string;
    changeSortType: (sortNum: number, sortString: string) => void;
    sortTypeList: string[];
}

const SortBottomSheet = ({ sortStatus, changeSortType, sortTypeList }: SortBottomSheetProps) => {
    return (
        <>
            <div css={sortBottomSheetWrap}>
                {sortTypeList.map((sortType: string, index: number) => (
                    <button
                        css={sortButton(sortStatus === sortType)}
                        onClick={() => {
                            changeSortType(index, sortType);
                        }}
                        key={`${sortType}${index}`}
                    >
                        {sortType}
                    </button>
                ))}
            </div>
        </>
    );
};

export default SortBottomSheet;

const sortBottomSheetWrap = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 14px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 232px;
    border-radius: 14px 14px 0px 0px;
    gap: 8px;
    background-color: white;
    z-index: 3;
    transform: translateY(0);
    @keyframes fadeInUp {
        0% {
            transform: translateY(232px);
        }
    }
    animation: fadeInUp 0.15s ease-in-out;
`;

const sortButton = (active: boolean) => css`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 56px;
    border-radius: 14px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    align-items: center;
    justify-content: center;
    ${active
        ? {
              backgroundColor: "#0047FF",
              color: "white",
              fontWeight: 700,
          }
        : {
              backgroundColor: "white",
              color: "#0C002C",
              fontWeight: 400,
          }};
`;
