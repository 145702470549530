import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SelectedItemFilter } from "@src/hooks/filter/useSelectedFilter";
import { Common } from "@src/styles/Common";
import React, { useState } from "react";

type SelectedFilterPropsType = {
    selectedState: SelectedItemFilter[];
    onSelectedItem: (id: string, isMulti?: boolean) => void;
    isMulti?: boolean;
    onSelectReset?: () => void;
    type?: string;
};

const SelectedFilter = ({ selectedState, onSelectedItem, isMulti, type, onSelectReset }: SelectedFilterPropsType) => {
    return (
        <Container checkCounterForce={Boolean(selectedState[1].title === "대항력 없음")}>
            {selectedState[1].title === "대항력 없음" && (
                <DisabledText>*권리분석의 내용은 입력 착오로 사실과 다를 수 있으며 면책 조건으로 제공됩니다. </DisabledText>
            )}
            <div css={containerBoxStyle}>
                {selectedState.map((item, index) =>
                    type !== "uniary" ? (
                        item.title !== "전체" && (
                            <Item
                                onClick={() => onSelectedItem(item.id, isMulti)}
                                key={`${item.title}_${index}_${item.id}`}
                                isActive={item.isActive}
                            >
                                <span>{item.title}</span>
                            </Item>
                        )
                    ) : (
                        <Item
                            onClick={() => {
                                onSelectedItem(item.id, isMulti);
                                if (item.title === "전체" && onSelectReset) return onSelectReset();
                            }}
                            key={`${index}_${item.title}_${item.id}`}
                            isActive={item.isActive}
                        >
                            <span>{item.title}</span>
                        </Item>
                    ),
                )}
            </div>
        </Container>
    );
};

export default SelectedFilter;

const Container = styled.div<{ checkCounterForce: boolean }>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ checkCounterForce }) => (checkCounterForce ? "column" : "row")};
    row-gap: 14px;
    padding: 0px 14px;
    width: 100%;
    height: auto;
    gap: 6px;
`;

const Item = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    background: ${({ isActive }) => (isActive ? Common.colors.aucMarkerColor : "#fdfafa")};

    height: 36px;
    border-radius: 8px;

    span {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #0c002c;
        color: ${({ isActive }) => (isActive ? "#FFFFFF" : "#0c002c")};
    }

    /* & + & { */
    margin-left: 8px;
    /* } */
`;

const containerBoxStyle = css`
    display: flex;
    flex-wrap: wrap;
    row-gap: 14px;
    gap: 6px;
`;

const Disabled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    background: ${Common.colors.grey02};

    height: 36px;
    border-radius: 8px;

    span {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #0c002c;
        color: ${Common.colors.greyPrimary};
    }

    /* & + & { */
    margin-left: 8px;
    /* } */
`;

const DisabledText = styled.div`
    margin-left: 8px;
    ${Common.textStyle.title8_R12}
    color:${Common.colors.darkGrey04};
`;

const ButtonDiv = styled.div`
    display: flex;
`;
