import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import convertToPyeong from "@src/util/convertToPyeong";
import xmlToJson from "@src/util/convertXmlToJson";
import { makeTypeList } from "./useGetBuildingDetail";
import fetchJsonp from "fetch-jsonp";

const env = process.env;
const INVALID = "-";
const isValid = (input: string) => {
    return input !== INVALID;
};
interface paramsProps {
    pnu: string;
    address: string;
}

export const parseBuildingCard = (total: any, single: any[]) => {
    let result = {
        buildingNm: INVALID,
        mainUse: INVALID,
        useAprDay: INVALID,
        platArea: INVALID,
        totArea: INVALID,
        newPlatPlc: INVALID,
        grndFlrCnt: "0F",
        ugrndFlrCnt: "B0",
    };
    if (!total.length && !single.length) return result;

    if (total.length) {
        let { bldNm, mainPurpsCdNm, useAprDay, platArea, totArea, newPlatPlc } = total[0];
        const platAreaPyeong = platArea.pyong;
        const totAreaPyeong = totArea.pyong;

        if (single.length > 1) {
            const mainBuilding = single.filter((item: any) => item.mainAtchGbCdNm.includes("주"));
            const highFlr =
                mainBuilding.length !== 0
                    ? mainBuilding.map((elem: any) => (isValid(elem.grndFlrCnt) ? Number(elem.grndFlrCnt) : 0))
                    : [0];
            const underFlr =
                mainBuilding.length !== 0
                    ? mainBuilding.map((elem: any) => (isValid(elem.ugrndFlrCnt) ? Number(elem.ugrndFlrCnt) : 0))
                    : [0];

            const highestFlr = Math.max.apply(null, highFlr);
            const lowestFlr = Math.min.apply(null, underFlr);
            const floors = {
                grndFlrCnt: `${highestFlr}F`,
                ugrndFlrCnt: lowestFlr == 0 ? "B0" : `B${lowestFlr}`,
            };
            result = {
                buildingNm: isValid(bldNm) ? bldNm : mainBuilding[0].bldNm,
                mainUse: isValid(mainPurpsCdNm) ? mainPurpsCdNm : mainBuilding[0].mainPurpsCdNm,
                useAprDay: isValid(useAprDay) ? useAprDay : mainBuilding[0].useAprDay,
                platArea: isValid(platAreaPyeong) ? platAreaPyeong : mainBuilding[0].platArea.pyong,
                totArea: isValid(totAreaPyeong) ? totAreaPyeong : mainBuilding[0].totArea.pyong,
                newPlatPlc: isValid(newPlatPlc) ? newPlatPlc : mainBuilding[0].newPlatPlc,
                ...floors,
            };
        }
        if (single.length === 1) {
            const floors = {
                grndFlrCnt: `${isValid(single[0].grndFlrCnt) ? single[0].grndFlrCnt : 0}F`,
                ugrndFlrCnt: !isValid(single[0].ugrndFlrCnt) ? "B0" : `B${single[0].ugrndFlrCnt}`,
            };
            result = {
                buildingNm: isValid(bldNm) ? bldNm : single[0].bldNm,
                mainUse: isValid(mainPurpsCdNm) ? mainPurpsCdNm : single[0].mainPurpsCdNm,
                useAprDay: isValid(useAprDay) ? useAprDay : single[0].useAprDay,
                platArea: isValid(platAreaPyeong) ? platAreaPyeong : single[0].platArea.pyong,
                totArea: isValid(totAreaPyeong) ? totAreaPyeong : single[0].totArea.pyong,
                newPlatPlc: isValid(newPlatPlc) ? newPlatPlc : single[0].newPlatPlc,
                ...floors,
            };
        }
    } else {
        let { bldNm, mainPurpsCdNm, useAprDay, platArea, totArea, newPlatPlc } = single[0];
        const platAreaPyeong = platArea.pyong;
        const totAreaPyeong = totArea.pyong;
        if (single.length > 1) {
            const mainBuilding = single.filter((item: any) => item.mainAtchGbCdNm.includes("주"));
            const highFlr =
                mainBuilding.length !== 0
                    ? mainBuilding.map((elem: any) => (isValid(elem.grndFlrCnt) ? Number(elem.grndFlrCnt) : 0))
                    : [0];
            const underFlr =
                mainBuilding.length !== 0
                    ? mainBuilding.map((elem: any) => (isValid(elem.ugrndFlrCnt) ? Number(elem.ugrndFlrCnt) : 0))
                    : [0];
            const highestFlr = Math.max.apply(null, highFlr);
            const lowestFlr = Math.min.apply(null, underFlr);

            const floors = {
                grndFlrCnt: `${highestFlr}F`,
                ugrndFlrCnt: lowestFlr == 0 ? "B0" : `B${lowestFlr}`,
            };
            result = {
                buildingNm: isValid(bldNm) ? bldNm : mainBuilding[0]?.bldNm ?? single[0].bldNm,
                mainUse: isValid(mainPurpsCdNm) ? mainPurpsCdNm : mainBuilding[0]?.mainPurpsCdNm ?? single[0].mainPurpsCdNm,
                useAprDay: isValid(useAprDay) ? useAprDay : mainBuilding[0]?.useAprDay ?? single[0].useAprDay,
                platArea: isValid(platAreaPyeong) ? platAreaPyeong : mainBuilding[0]?.platArea.pyong ?? single[0].platArea.pyong,
                totArea: isValid(totAreaPyeong) ? totAreaPyeong : mainBuilding[0]?.totArea.pyong ?? single[0].totArea.pyong,
                newPlatPlc: isValid(newPlatPlc) ? newPlatPlc : mainBuilding[0]?.newPlatPlc ?? single[0].newPlatPlc,
                ...floors,
            };
        }
        if (single.length === 1) {
            const floors = {
                grndFlrCnt: `${isValid(single[0].grndFlrCnt) ? single[0].grndFlrCnt : 0}F`,
                ugrndFlrCnt: !isValid(single[0].ugrndFlrCnt) ? "B0" : `B${single[0].ugrndFlrCnt}`,
            };
            result = {
                buildingNm: isValid(bldNm) ? bldNm : single[0].bldNm,
                mainUse: isValid(mainPurpsCdNm) ? mainPurpsCdNm : single[0].mainPurpsCdNm,
                useAprDay: isValid(useAprDay) ? useAprDay : single[0].useAprDay,
                platArea: isValid(platAreaPyeong) ? platAreaPyeong : single[0].platArea.pyong,
                totArea: isValid(totAreaPyeong) ? totAreaPyeong : single[0].totArea.pyong,
                newPlatPlc: isValid(newPlatPlc) ? newPlatPlc : single[0].newPlatPlc,
                ...floors,
            };
        }
    }

    return result;
};

export const getLandDataFromDataPortal = async (pnu: string) => {
    const key = process.env.REACT_APP_V_WORLD_KEY;
    const domain = process.env.REACT_APP_V_WORLD_DOMAIN;
    const path = "https://api.vworld.kr/ned/data/ladfrlList";
    const queryString = `?key=${key}&pageNo=1&numOfRows=10&pnu=${pnu}&domain=${domain}&format=json`;
    const newUrl = `${path}${queryString}`;
    const res = await fetchJsonp(newUrl, {
        jsonpCallbackFunction: "landDetailData",
        timeout: 5000,
    });
    const result = await res.json();
    return result;
};

const fetchData = async ({ pnu, address }: paramsProps) => {
    //필지상세, 필지카드 api
    const res = await axiosInstance.get(`${axiosPath.mapFieldDetail}/${pnu}`);
    let {
        land_list: land,
        title_info_total: total,
        title_info_single: single,
        build_overview: totalBuildingTable,
        wclfInfo,
    } = res.data.payload.result;
    const buildingTypes = [
        "대",
        "대지",
        "공장용지",
        "학교용지",
        "창고용지",
        "종교용지",
        "유원지",
        "체육용지",
        "과수원",
        "광천지",
        "주차장",
        "주유소용지",
        "철도용지",
        "제방",
        "구거",
        "양어장",
        "수도용지",
        "묘지",
        "잡종지",
    ];
    const fieldType =
        !total?.length && !single?.length
            ? "land"
            : single?.length &&
              !total?.length &&
              !single[0]?.regstrKindCdNm.includes("집합") &&
              !single[0]?.regstrKindCdNm.includes("일반") &&
              !single[0]?.regstrKindCdNm
            ? "land"
            : buildingTypes.includes(land[0]?.lndcgrCodeNm)
            ? "building"
            : "land";
    //! 임시
    if (fieldType === "land") {
        const dataFromPortal: any = await getLandDataFromDataPortal(pnu);
        land = [
            {
                ...land[0],
                lndcgrCodeNm: dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndcgrCodeNm ?? "-",
                lndpclAr: {
                    square: `${Number(dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr ?? 0).toLocaleString()}㎡`,
                    pyong: `${Number(
                        convertToPyeong(dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr ?? 0),
                    ).toLocaleString()}평`,
                },
                posesnSeCodeNm: dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.posesnSeCodeNm ?? "-",
                cnrsPsnCo: `(공유인수 ${dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.cnrsPsnCo})`,
            },
        ];
    }
    const currentTotal = total?.length
        ? total.map((val: any) => ({
              base: { ...val },
              building: totalBuildingTable,
              puri: wclfInfo,
          }))
        : [];

    const currentSingle = single?.length
        ? single.map((val: any) => ({
              base: { ...val },
              building: val?.floor_list,
              dates: {
                  pmsDay: val?.pmsDay,
                  stcnsDay: val?.stcnsDay,
                  useAprDay: val?.useAprDay,
              },
              elevator: {
                  emgenUseElvtCnt: val?.emgenUseElvtCnt,
                  rideUseElvtCnt: val?.rideUseElvtCnt,
              },
              parkingLot: {
                  indrAutoUtcnt: val?.indrAutoUtcnt,
                  indrMechUtcnt: val?.indrMechUtcnt,
                  oudrAutoUtcnt: val?.oudrAutoUtcnt,
                  oudrMechUtcnt: val?.oudrMechUtcnt,
                  totPkngCnt: val?.totPkngCnt,
              },
              jeonyu: null,
              puri: wclfInfo,
          }))
        : [];

    const typeList = makeTypeList({
        total: currentTotal[0],
        single: currentSingle,
    });
    const buildingCard = parseBuildingCard(total, single);
    //* altitude add
    const newAltitude = isNaN(Number(land[0]?.altitude))
        ? land[0]?.altitude
        : `${Math.round(Number(land[0]?.altitude)).toString()}m`;
    return {
        land: {
            address,
            use: land[0].prposAreaNm1,
            lndcgrCodeNm: land[0].lndcgrCodeNm,
            owner: land[0].posesnSeCodeNm,
            size: land[0].lndpclAr.pyong,
            origin: {
                res1: { ...land[0] },
                res2: { ...land[0] },
            },
            base: { ...land[0], altitude: newAltitude },
            landUsePlan: {},
            officialPrice: [],
        },
        building: {
            ...buildingCard,
            origin: {
                total,
                single,
            },
            data: {
                total: currentTotal,
                single: currentSingle,
            },
            typeList: typeList,
        },
        totalBuildingTable,
    };
};

const useGetFieldContents = ({ pnu, address }: paramsProps) => {
    return fetchData({ pnu, address });
};

export default useGetFieldContents;
