import styled from "@emotion/styled";
import { FilterParamsType, useFilterStore } from "@src/store/filterStore";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import FilterFooter, { LocationArea, LocationItem } from "./FilterFooter";
import ico_arrow_back from "@src/assets/ico_arrow_back.png";
import useGetLocations, { LocationDataType, LocationsDataType } from "@src/hooks/filter/useGetLocations";
import useGetPurpose, { PurposeDataTypes } from "@src/hooks/filter/useGetPurpose";
import { purposeData } from "@src/data/itemForFilterPurpose";
import ico_x_grey from "@src/assets/ico_x_grey.svg";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import { numberToKorean } from "@src/util/numberToKoreanPrice";
import { auctionStatusList } from "@src/pages/newFilter/AuctionStatusFilter";

type FilterHistoryPropsType = {
    history: FilterParamsType;
    updateAt: string;
    activePNU: any;
    // activePNU: LocationsDataType;
    no: string;
    allPNU: string[];
};
const purposeKeys: string[] = Object.keys(purposeData as PurposeDataTypes);
const FilterHistory = ({ history, updateAt, activePNU, no, allPNU }: FilterHistoryPropsType) => {
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);
    const updateHistoryFilterFlag = useFilterStore((state) => state.updateHistoryFilterFlag);

    const [resultString, setResultString] = useState<string[]>([]);
    const [useResultString, setUseResultString] = useState<string[]>([]);
    const [activeData, setActiveData] = useState<any>({
        city: [],
    });

    const getSelectedPurposeId = () => {
        purposeKeys.forEach((key) => {
            purposeData[key].children.forEach((children) => {
                if (history.use.includes(children.id)) {
                    setUseResultString((prev) => prev.concat(`${key} > ${children.title}`));
                }
            });
        });
    };

    const getSelectedLocation = (activePNU: any) => {
        const data = {
            city: activePNU.city.map((city: any) => {
                return {
                    ...city,
                    isActive: history.loc.includes(city.cortarNo),
                    children: city.children.map((dvsn: LocationDataType) => {
                        return {
                            ...dvsn,
                            isActive: history.loc.includes(dvsn.cortarNo),
                            children: dvsn.children.map((sec: LocationDataType) => {
                                return {
                                    ...sec,
                                    isActive: history.loc.includes(sec.cortarNo),
                                };
                            }),
                        };
                    }),
                };
            }),
        };

        setActiveData(data);
    };

    useEffect(() => {
        getSelectedLocation(activePNU);
        setUpdateHistoryFilterFlag(false);
    }, [activePNU, history]);

    useEffect(() => {
        getSelectedPurposeId();
    }, []);

    const test = (data: any) => {
        const resultData: string[] = [];
        for (const key in data) {
            if (key === "assets" && !isEmpty(data["assets"])) {
                data["assets"].forEach((item: string) => {
                    if (item == "1") {
                        resultData.push("압류재산");
                    }

                    if (item == "2") {
                        resultData.push("국공유재산");
                    }

                    if (item == "3") {
                        resultData.push("신탁재산");
                    }

                    if (item == "4") {
                        resultData.push("기타재산");
                    }
                });
            }

            if (key === "type") {
                if (data["type"] == "1") {
                    resultData.push("경매");
                }
                if (data["type"] == "2") {
                    resultData.push("공매");
                }
            }

            if (key === "counter_force") {
                if (data["counter_force"] == "1") {
                    resultData.push("대항력 없음");
                }

                if (data["counter_force"] == "2") {
                    resultData.push("대항력 있음");
                }

                if (data["counter_force"] == "3") {
                    resultData.push("미상");
                }
            }

            if (key === "auction_master_item" && !isEmpty(data["auction_master_item"])) {
                let stringData = "";
                switch (data["auction_master_item"][0].toString()) {
                    case "1":
                        stringData = "유치권";
                        break;
                    case "2":
                        stringData = "분묘기지권";
                        break;
                    case "3":
                        stringData = "법정지상권";
                        break;
                    case "4":
                        stringData = "위반건축물";
                        break;
                    case "5":
                        stringData = "농취증필요";
                        break;
                    case "6":
                        stringData = "토지만입찰";
                        break;
                    case "7":
                        stringData = "건물만입찰";
                        break;
                    case "8":
                        stringData = "토지별도등기";
                        break;
                    case "9":
                        stringData = "지분매각";
                        break;
                    case "10":
                        stringData = "대지권미등기";
                        break;
                    case "11":
                        stringData = "HUG 임차권 인수조건 변경";
                        break;
                }
                data["auction_master_item"].length > 1
                    ? resultData.push(
                          `${stringData} 외 ${data["auction_master_item"].length - 1} ${
                              data["auction_master_except"] ? "제외" : ""
                          }`,
                      )
                    : resultData.push(`${stringData} ${data["auction_master_except"] ? "제외" : ""}`);
            }

            if (key === "status" && data["status"][0] != "0") {
                auctionStatusList?.[data["status"][0]] &&
                    resultData.push(
                        data["status"].length > 1
                            ? `${auctionStatusList[data["status"][0]]} 외 ${data["status"].length - 1}`
                            : `${auctionStatusList[data["status"][0]]}`,
                    );
                // data["status"].forEach((item: string) => {
                //     if (item === "1") {
                //         resultData.push("진행예정");
                //     }

                //     if (item === "2") {
                //         resultData.push("진행중");
                //     }

                //     if (item === "3") {
                //         resultData.push("매각");
                //     }

                //     if (item === "4") {
                //         resultData.push("기타");
                //     }
                // });
            }
            if (key === "skp_type") {
                if (data["skp_type"] == "1") {
                    resultData.push("신건");
                }
            }
            if (key === "skp_cnt" && data["skp_cnt"][0] != "" && data["skp_cnt"][1] != "") {
                resultData.push(`유찰횟수 ${data["skp_cnt"][0]} ~ ${data["skp_cnt"][1]}`);
            }
            if (key === "skp_cnt" && data["skp_cnt"][0] == "" && data["skp_cnt"][1] != "") {
                resultData.push(`유찰횟수 ~ ${data["skp_cnt"][1]}`);
            }
            if (key === "skp_cnt" && data["skp_cnt"][0] != "" && data["skp_cnt"][1] == "") {
                resultData.push(`유찰횟수  ${data["skp_cnt"][0]} ~ `);
            }

            if (key === "due_date" && data["due_date"][0] != "" && data["due_date"][1] != "") {
                resultData.push(`매각기일 ${data["due_date"][0]} ~ ${data["due_date"][1]}`);
            }

            if (key === "est_price" && data["est_price"][0] != "" && data["est_price"][1] != "") {
                resultData.push(`감정가 ${data["est_price"][0]} ~ ${data["est_price"][1]}`);
            }

            if (key === "est_price" && data["est_price"][0] == "" && data["est_price"][1] != "") {
                resultData.push(`감정가 ~ ${data["est_price"][1]}`);
            }

            if (key === "est_price" && data["est_price"][0] != "" && data["est_price"][1] == "") {
                resultData.push(`감정가 higher ~ higher`);
            }

            if (key === "min_price" && data["min_price"][0] != "" && data["min_price"][1] != "") {
                resultData.push(`최저입찰가 ${data["min_price"][0]} ~ ${data["min_price"][1]}`);
            }

            if (key === "min_price" && data["min_price"][0] != "" && data["min_price"][1] == "") {
                resultData.push(`최저입찰가 higher ~ higher`);
            }

            if (key === "min_price" && data["min_price"][0] == "" && data["min_price"][1] != "") {
                resultData.push(`최저입찰가 ~ ${data["min_price"][1]}`);
            }

            if (key === "sales_method") {
                if (data["sales_method"] == "1") {
                    resultData.push(`매각`);
                }
                if (data["sales_method"] == "2") {
                    resultData.push(`임대`);
                }
            }
        }

        setResultString(resultData);
    };

    useEffect(() => {
        test(history);
    }, []);

    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const getFilterHistory = useFilterStore((state) => state.getFilterHistory);
    const setFilterVisible = useFilterStore((state) => state.setFilterVisible);

    const setFilterOfHistory = () => {
        setStoreFilterState(history, true);
        setUpdateHistoryFilterFlag(false);
        setFilterVisible(false);
    };

    const removeHistory = async (no: string) => {
        await axiosInstance.delete(`${axiosPath.removeFilterHistory}/${no}`);
        getFilterHistory();
    };
    function numberToKorean(number: string) {
        const data = number.split(" ");
        if (data.length === 4) {
            const minPrice = data[1];
            const maxPrice = data[3];

            const test = itemForAppraisedPrice.map((item) => {
                if (item.value === minPrice || item.value === maxPrice) {
                    return item.title;
                }
            });

            const test2 = test.filter((item) => item);

            if (test2[0] === "300억~") {
                return `${test2[0]}`;
            } else {
                return `${test2[0]} ~ ${test2[1]}`;
            }
        } else {
            const price = data[2];

            const test = itemForAppraisedPrice.map((item) => {
                if (item.value === price) {
                    return item.title;
                }
            });
            const test2 = test.filter((item) => item);
            return `~ ${test2[0]}`;
        }
    }

    return (
        <Container>
            <div className="header">
                <span className="update_at">{updateAt}</span>
                <img src={ico_x_grey} onClick={() => removeHistory(no)} />
            </div>
            <div className="contentWrapper">
                <div style={{}}>
                    {/* <div className="selectedItem" style={{ display: "block" }}> */}
                    {!!useResultString?.length && (
                        <div className="selectedItem02">
                            <FilterFooter
                                locationState={activeData}
                                onSubmit={() => console.log("진행상태")}
                                type="부분필터"
                                onClickRemove={() => {}}
                                history={true}
                                style={{ position: "static", width: "fit-content" }}
                                recentSearch={true}
                            />
                            <div style={{ display: "flex", position: "static", width: "fit-content" }}>
                                {useResultString.map((item, index) => (
                                    <LocationItem key={`${item}_${index}`}>
                                        <span>{item}</span>
                                    </LocationItem>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {!isEmpty(resultString) && (
                    <div className="selectedItem">
                        {resultString.map((item, index) => (
                            <LocationItem key={`${item}_${index}`} type={"selected"}>
                                {item.includes("감정가") || item.includes("최저입찰가") ? (
                                    <span>
                                        {item.includes("감정가") ? "감정가" : "최저입찰가"} {numberToKorean(item)}
                                    </span>
                                ) : (
                                    item && <span>{item}</span>
                                )}
                            </LocationItem>
                        ))}
                    </div>
                )}
            </div>

            <div className="search" onClick={setFilterOfHistory}>
                필터 적용해 검색하기 <img src={ico_arrow_back} />
            </div>
        </Container>
    );
};

export default FilterHistory;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px 0px 14px 14px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    overflow-y: scroll;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
        img {
            margin-right: 14px;
        }
        margin-bottom: 12px;
    }

    .update_at {
        font-size: 14px;
        color: #8d8d8d;
        line-height: 24px;
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    .selectedItem02 {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 52px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
        & > div:first-of-type > div {
            padding: 0;
            width: max-content !important;
        }
        & > div > div {
            width: max-content !important;
        }
    }

    .selectedItem {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 52px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
        & > div > div {
            padding: 0px;
        }
    }

    .selectedItem + .selectedItem {
        /* padding-top: 20px; */
    }

    .search {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        color: #2275ee;
        padding-right: 24px;
        margin-top: 12px;

        img {
            width: 7px;
            margin-left: 7px;
            transform: rotate(180deg);
        }
    }
`;
