import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const convertObjToArr = (init: historyItemDto) => {
    return {
        type: init.type,
        date: init.date,
        note: init.note,
        next_it: init.next_it,
        price: init.price.all,
        pricePyeong: init.price.pyeong,
        priceSquare: init.price.square,
        sizeSquare: init.size.square,
        sizePyeong: init.size.pyeong,
    };
};
// 실거래가 요약정보 조회
interface summaryParameterType {
    pnu: string;
    lntd: number;
    latd: number;
    land_category: string;
    land_use_district: string;
    bld_main_use: string;
    area: string;
    sort?: number;
}
const fetchSummary = async (params: summaryParameterType): Promise<realPriceType> => {
    const response = await axiosInstance.get(`${axiosPath.realPrice}/summ`, { params });
    if (response.data.payload.errCode !== 0) throw new Error("error: realPriceSummary fetch");
    const responseData: initRealPriceDetailDataDto = response.data.payload.result;
    const { lately, history, similar, similarTag } = responseData;
    return {
        lately,
        history: history.length !== 0 ? history.map((item: historyItemDto) => convertObjToArr(item)) : [],
        similar: similar.length !== 0 ? similar.map((item: historyItemDto) => convertObjToArr(item)) : [],
        similarTag,
    };
};
const useGetRealPriceSummary = (params: any) => {
    return new Promise((resolve, reject) => {
        resolve(fetchSummary(params));
    }).then((res) => res);
};

// 실거래가 리스트 추가 조회
interface listParameterType {
    pnu: string;
    lntd: number;
    latd: number;
    land_category: string;
    land_use_district: string;
    bld_main_use: string;
    area: number;
    sort?: number;
    type?: 0 | 1 | 2 | 3 | 4;
    next_it?: string;
    limit?: number;
}
const fetchList = async (params: listParameterType) => {
    const response = await axiosInstance.get(`${axiosPath.realPrice}/list`, { params });
    if (response.data.payload.errCode !== 0) throw new Error("error: realPriceList fetch");
    const responseData: historyItemDto[] = response.data.payload.result;
    if (responseData.length === 0) {
        return [];
    }
    const result = responseData.map((item: historyItemDto) => convertObjToArr(item));
    return result;
};
const useGetRealPriceList = async (params: any) => {
    return await fetchList(params);
};

interface historyItemDto {
    type: string;
    date: string;
    price: {
        all: string;
        square: string;
        pyeong: string;
    };
    size: {
        square: string;
        pyeong: string;
    };
    note: string;
    next_it: string;
}

interface initRealPriceDetailDataDto {
    lately: {
        price: string;
        type: string;
        date: string;
    } | null;
    history: historyItemDto[] | [];
    similar: historyItemDto[] | [];
    similarTag: string[] | [];
}

interface historyItemType {
    type: string;
    date: string;
    price: string;
    pricePyeong: string;
    priceSquare: string;
    sizeSquare: string;
    sizePyeong: string;
    note: string;
    next_it: string;
}
interface realPriceType {
    lately: {
        price: string;
        type: string;
        date: string;
    } | null;
    history: historyItemType[] | [];
    similar: historyItemType[] | [];
    similarTag: string[] | [];
}
const initRealPriceDetailData: realPriceType = {
    lately: null,
    history: [],
    similar: [],
    similarTag: [],
};
// const initRealPriceDetailData: realPriceType = {
//     lately: {
//         price: "80억 600만원",
//         type: "매매",
//         date: "YYYY.MM.DD",
//     },
//     history: [
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//     ],
//     similar: [
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//         {
//             type: "매매",
//             date: "2022.07.06",
//             price: "3억 2,500만원",
//             pricePyeong: "2,914만원",
//             priceSquare: "881만원",
//             sizeSquare: "36.86㎡",
//             sizePyeong: "11.15평",
//             note: "14층",
//             next_it: "2022-07-06T00:00:00.000Z_-!-_1111012400100850000",
//         },
//     ],
//     similarTag: ["용도", "용도지역", "강남구"],
// };

export { initRealPriceDetailData, useGetRealPriceList, useGetRealPriceSummary };
export type { initRealPriceDetailDataDto, summaryParameterType, listParameterType, historyItemType, realPriceType };
