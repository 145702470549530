import React, { useEffect, useState } from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import sliderFilterIcon from "@src/assets/goStop/sliderFilterDotIcon.svg";
import Slider from "@mui/material/Slider";
import convertNumberToKorean from "@src/util/convertNumToKorean";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
    onChangeCheckReset: () => void;
    checkReset: boolean;
    selectedRange?: any;
    setSelectedRange?: any;
    applyRange?: any;
}
const GoStopProfitFilter: React.FC<Props> = ({
    filterParams,
    setFilterParams,
    allFilter = true,
    onClickDone,
    checkReset,
    onChangeCheckReset,
    selectedRange,
    setSelectedRange,
    applyRange,
}) => {
    const handleSliderChange = (_: any, newRange: any) => {
        setSelectedRange([newRange[0], newRange[1]]);
    };

    useEffect(() => {
        if (filterParams?.gostop_profit[0] === 0 && filterParams?.gostop_profit[1] === null && checkReset) {
            setSelectedRange([0, 10000000000]);
            onChangeCheckReset();
        }
    }, [checkReset]);

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(filterParams.gostop_profit.some((val: any) => !val) || !filterParams.gostop_profit.length)}>
                <p className="titleText">시세차익</p>
                <button
                    className="downButton"
                    onClick={() => {
                        onClickDone && onClickDone();
                    }}
                >
                    <img src={downArrowIcon} alt="" />
                </button>
            </div>
            <div css={sliderFilterStyle}>
                <div className="sliderContainer">
                    <Slider
                        getAriaLabel={() => "Minimum distance"}
                        value={selectedRange}
                        min={0}
                        max={10000000000}
                        step={100000000}
                        onChange={(_, newRange) => {
                            handleSliderChange(_, newRange);
                        }}
                        valueLabelDisplay="off"
                        disableSwap
                    />
                    <div className="sliderLabel">
                        {selectedRange[0] === 0 ? <span>0원</span> : <span>{convertNumberToKorean(selectedRange[0])}</span>}
                        {selectedRange[1] === 0 ? (
                            <span>0원</span>
                        ) : convertNumberToKorean(selectedRange[1]) !== "100억" ? (
                            <span>{convertNumberToKorean(selectedRange[1])}</span>
                        ) : (
                            <span>최대(100억 초과)</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoStopProfitFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : "#ffffff"};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const sliderFilterStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 14px;
    flex-wrap: wrap;
    .sliderContainer {
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .sliderLabel {
        display: flex;
        justify-content: space-between;
        span {
            font-size: 12px;
            color: #8d8d8d;
        }
    }
    .MuiSlider-root {
        height: 8px;
    }
    .MuiSlider-track {
        background: #0047ff;
        border: none;
    }
    .MuiSlider-thumb {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: url(${sliderFilterIcon}) no-repeat;
        background-size: cover;
    }
`;
