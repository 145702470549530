import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import React from "react";

interface TableButtonProps {
    onClick: () => void;
    isChangeBtn: boolean;
    tryClose: () => void;
}

function TableButton({ onClick, isChangeBtn, tryClose }: TableButtonProps) {
    return (
        <>
            {isChangeBtn ? (
                <button css={tableButtonStyle} onClick={tryClose}>
                    접기
                </button>
            ) : (
                <button css={tableButtonStyle} onClick={onClick}>
                    더보기
                </button>
            )}
        </>
    );
}

const tableButtonStyle = css`
    background-color: ${Common.colors.blue};
    width: 100%;
    height: 56px;
    border-radius: 14px;
    color: ${Common.colors.white};
    ${Common.textStyle.display3_R18};
    line-height: 0;
`;

export default TableButton;
