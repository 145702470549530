import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";

//* 유저 ai정보 가져오기
export const useFindAiRecommendFilter = (enabled: boolean = false) => {
    return useQuery<IAiRecommendFilter | undefined | null>(
        ["aiRecommendFilter"],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.saveAiFilterV2)
                .then((res) => {
                    const newData = res?.data;
                    if (newData?.regions?.length) {
                        newData.regions = newData?.regions?.map((v: any) => (v.includes("All") ? v.slice(0, -3) : v));
                        return newData;
                    }
                    return newData;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return null;
                });
            return result;
        },
        {
            enabled,
        },
    );
};

export const useGetAIRecommendSetting = (enabled: boolean = false) => {
    return useQuery<IAiRecommendSettings>(
        ["aiRecommendSetting"],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.getAiSetting)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return null;
                });
            return result;
        },
        {
            enabled,
        },
    );
};

export const useUpdateUserAiRecommendFilter = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: IAiRecommendFilter) => {
            try {
                const response = await axiosInstance.put(axiosPath.saveAiFilterV2, { ...params });
                return response;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export const useUpdateUserAiSetting = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: IUserAiUpdateSetting) => {
            try {
                const response = await axiosInstance.post(axiosPath.getAiSetting, { ...params });
                return response;
            } catch (err) {
                console.log("err>>>", err);
                throw err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const getAiRecommendList = async (params: IAiRecommendListDTO) => {
    const res = await axiosInstance
        .get(axiosPath.aiItemListV2, { params: { ...params, limit: 20 } })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log("err>>>", err);
            throw err;
        });
    return res;
};

export const useFindAllAiRecommendList = (params: IAiRecommendListDTO) => {
    return useInfiniteQuery<IAiRecommendList>({
        queryKey: ["findAllAiRecommendList", params],
        queryFn: ({ pageParam }) => getAiRecommendList({ ...params, cursor: pageParam }),
        getNextPageParam: (lastPage, _pages) => {
            const newCursor = lastPage?.cursor;
            return newCursor;
        },
        enabled: false,
    });
};

//* 유저 ai푸시 첫 알림
export const useSendAiRecommendPush = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: ISendAi) => {
            try {
                const response = await axiosInstance.post(axiosPath.aiPushTestSendPath, { ...params });
                return response;
            } catch (Err) {
                console.log("sendError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

interface ISendAi {
    dueDate: number[];
    settingOrder: number;
}

export interface IAiRecommendFilter {
    bidType: number;
    categories: string[];
    investmentPrice: number;
    regions: string[];
    sortType: number;
}

export interface IAiRecommendSetting {
    no: number;
    order: number;
    itemFilter: {
        investmentPrice: number;
        regions: string[];
        categories: string[];
        bidType: number;
        sortType: number;
    };
    isFinish: boolean;
}

export interface IAiRecommendSettings extends Array<IAiRecommendSetting> {
    list: any[];
}

export interface IUserAiUpdateSetting {
    investmentPrice?: number;
    regions: string[];
    categories: string[];
    bidType: number | any;
    sortType: number;
    settingOrder: number;
    isFinish: boolean;
    saleMethod: number;
    assetDetails: number[];
}

export const initialAiRecommendFilter: IAiRecommendFilter = {
    bidType: 0,
    categories: [],
    investmentPrice: 0,
    regions: [],
    sortType: 5,
};

export const initialUserAiSetting: IUserAiUpdateSetting = {
    investmentPrice: 0,
    regions: [],
    categories: [],
    bidType: 0,
    sortType: 5,
    settingOrder: 1,
    isFinish: false,
    saleMethod: 0,
    assetDetails: [],
};

export interface IAiRecommendListDTO {
    bidType: number;
    categories: string[];
    investmentPrice?: number;
    regions: string[];
    sortType: number;
    cursor?: any;
    limit?: number;
    // userNo: number;
}

export interface IAiRecommend {
    itemId: string;
    subId: string;
    bidType: number;
    useDetail: string;
    skpCnt: number;
    image: string;
    expectedReturn: string;
    estPrice: number;
    minPrice: number;
    discountRate: string;
    dueDate: number;
    address: {
        addressTop: string;
        addressMid: string;
    };
}

export interface IAiRecommendList {
    cursor: string;
    totalCount: number;
    list: IAiRecommend[];
}
