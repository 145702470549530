import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { ICursorType } from "@src/hooks/goStop/dto/goStop.dto";
import { useGetStoreGoStopFilters, useSaverStoreGoStopFilter, useStoreGoStopList } from "@src/hooks/storeGoStop/useStoreGoStop";
import { useStoreGoStopFilterStore } from "@src/store/StoreGoStopFilterStore";
import Lottie from "lottie-react";
import loading from "@src/assets/Loading.json";
import { IStoreGoStopResListDto } from "@src/hooks/storeGoStop/dto/storeGoStop.dto";
import StoreGoStopListCard from "./StoreGoStopListCard";
import { transferToNative } from "@src/lib/WebAppBridge";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { filter } from "lodash";

import NoMoreImg from "@src/assets/goStop/noMore.png";

interface IStoreGoStopListBox {
    content: any;
    data: any
}

const StoreGoStopListBox = ({ content, data }: IStoreGoStopListBox) => {
    const filterParams = useStoreGoStopFilterStore((state) => state.storeGoStopFilterState);

    const scrollTopRef = useRef<HTMLDivElement | null>(null);

    const cursor: ICursorType = {
        sortType: filterParams.sortType,
        cursorValue: null,
        cursorId: null,
        updatedAt: null,
    };

    const filterParam = {
        cursor,
        filter: filterParams,
        limit: 20,
        gostopType: [],
    };


    const { hasNextPage, fetchNextPage, isFetching } = useStoreGoStopList(filterParam);

    const [storeGoStopRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 300px 0px",
        delayInMs: 100,
    });


    return (
        <>
            <div css={rootBoxStyle} ref={content}>
                {isFetching && (
                    <div css={StyledVimeoLoading}>
                        <div>
                            <Lottie animationData={loading} />
                        </div>
                    </div>
                )}
                {
                    <div css={listBoxStyle} ref={scrollTopRef}>
                        {data?.pages?.map((page: any) =>
                            page.totalCnt === 0 ? (
                                <div key={page.cursor ?? "undefined"} css={noDataBoxStyle}>
                                    <img src={NoMoreImg} alt="" />
                                    조건에 맞는 매물이 없습니다.
                                </div>
                            ) : (
                                page?.list?.map((item: IStoreGoStopResListDto, itemIdx: number) => (
                                    <div
                                        key={String(itemIdx)}
                                        onClick={() => {
                                            transferToNative(JSON.stringify(item), "onClickItem");
                                        }}
                                    >
                                        <StoreGoStopListCard item={item} />
                                    </div>
                                ))
                            ),
                        )}
                        <div className="observer" ref={storeGoStopRef} />
                    </div>
                }
            </div>
        </>
    );
};

export default StoreGoStopListBox;

const StyledVimeoLoading = css`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 500px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;
    position: absolute;
    & > div {
        width: 106px;
        height: 63px;
        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const rootBoxStyle = css`
    overflow: hidden auto;
    -webkit-overflow-scrolling: touch;
    padding: 0px 14px;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    height: calc(100vh - 234px);
`;

const loadMoreBoxStyle = css`
    padding: 20px;
    margin-top: -430px;
`;

const listBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .observer {
        height: 30px;
        width: 100px;
    }
`;

const lottieBoxStyle = css`
    .lottie {
        width: 80px;
        margin: 0 auto;
    }
`;

const noDataBoxStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;

    & > img {
        width: 60px;
    }
`;
