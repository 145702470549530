import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { buildingCardContentsType } from "@src/pages/map/types";
import closeIcon from "@src/assets/ico_close.png";
import { isShowLabel } from "@src/util/isShowBottomSheetLabel";
import BuildingCard from "@src/pages/map/BuildingCard";
import roadViewIcon from "@src/assets/ico_roadview.png";

interface CardBottomSheetHeaderProps {
    type: "land" | "building";
    titleRef: any;
    isBackToAppShow: boolean;
    handleClickBackToApp: () => void;
    handleClickRoadViewButton: (position: naver.maps.LatLng) => void;
    buildingCardContents: buildingCardContentsType;
    hideLandCard: () => void;
    landCardContents?: any;
    address: string;
    convertCardType: (type: "land" | "building") => void;
    fieldJimok: string;
    areaSize?: { pyong: string; square: string };
    handleClickBackToAiChat: () => void;
    fromCheck: string;
}

function CardBottomSheetHeader({
    type,
    titleRef,
    isBackToAppShow,
    handleClickBackToApp,
    handleClickRoadViewButton,
    buildingCardContents,
    hideLandCard,
    landCardContents,
    address,
    convertCardType,
    fieldJimok,
    areaSize,
    fromCheck,
    handleClickBackToAiChat,
}: CardBottomSheetHeaderProps) {
    const convertLandCard = () => {
        convertCardType("land");
    };

    return (
        <>
            {type === "land" ||
            (!buildingCardContents?.origin?.total?.length && !buildingCardContents?.origin?.single?.length) ? (
                <div css={landCard} ref={titleRef} className={"summCard"}>
                    <div css={topButtonBoxStyle}>
                        {isBackToAppShow && (
                            <button css={backToAppButtonStyle("itemDetail")} onClick={handleClickBackToApp}>
                                매물상세
                            </button>
                        )}
                        {isBackToAppShow && fromCheck === "AIChat" && (
                            <button css={backToAppButtonStyle("AIChat")} onClick={handleClickBackToAiChat}>
                                음성검색
                            </button>
                        )}
                        <button
                            css={roadViewButtonStyle}
                            onClick={() => {
                                handleClickRoadViewButton(landCardContents.position);
                            }}
                        >
                            <img src={roadViewIcon} alt="roadViewIcon" width={14} />
                            거리뷰
                        </button>
                    </div>
                    <div className="contents">
                        <button className="closeBtn" onClick={hideLandCard}>
                            X
                        </button>
                        <div className="swipePoint"></div>
                        <p className="address">{address}</p>
                        <div className="contents_bottom">
                            <div className="top">
                                {isShowLabel(fieldJimok) && <span> {fieldJimok}</span>}
                                {isShowLabel(landCardContents?.use) && <span>{landCardContents?.use}</span>}
                            </div>
                            <div className="bottom">
                                {(isShowLabel(areaSize?.pyong ?? "") || isShowLabel(landCardContents?.size)) && (
                                    <span>
                                        토지면적{" "}
                                        {/* {isShowLabel(areaSize?.pyong ?? "") ? areaSize?.pyong : landCardContents?.size ?? "-"} */}
                                        {isShowLabel(landCardContents?.size ?? "")
                                            ? landCardContents?.size
                                            : areaSize?.pyong ?? "-"}
                                    </span>
                                )}
                                {isShowLabel(landCardContents?.owner) && <span> {landCardContents?.owner}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <BuildingCard
                    titleRef={titleRef}
                    isBackToAppShow={isBackToAppShow}
                    handleClickBackToApp={handleClickBackToApp}
                    handleClickRoadViewButton={handleClickRoadViewButton}
                    buildingCardContents={buildingCardContents}
                    landCardContents={landCardContents}
                    hideLandCard={hideLandCard}
                    address={address}
                    convertLandCard={convertLandCard}
                    fieldJimok={fieldJimok}
                    areaSize={areaSize}
                    fromCheck={fromCheck}
                    handleClickBackToAiChat={handleClickBackToAiChat}
                />
            )}
        </>
    );
}

const landCard = css`
    width: 100vw;
    height: fit-content;
    min-height: 120px;
    padding: 25px 14px 14px;
    background: ${Common.colors.white};
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 14px 14px 0px 0px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 98;
    span {
        margin-right: 6px;
    }
    .swipePoint {
        width: 40px;
        height: 4px;
        border-radius: 2px;
        background: ${Common.colors.grey04};
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, 0%);
    }
    .closeBtn {
        cursor: pointer;
        width: 26px;
        height: 26px;
        background: url(${closeIcon}) no-repeat;
        background-position: center;
        background-size: 16px auto;
        font-size: 0;
        text-indent: -9999px;
        position: absolute;
        right: 14px;
        top: 30px;
    }
    .address {
        width: calc(100% - 26px);
        word-break: keep-all;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: ${Common.colors.navyPrimary};
    }
    .contents_bottom {
        margin-top: 8px;
        padding-top: 14px;
        border-top: 0.6px solid ${Common.colors.greySecondary};
        font-style: normal;
        font-weight: 400;
        font-size: 12.5px;
        line-height: 20px;
        .top,
        .bottom {
            color: ${Common.colors.darkGrey03};
        }
        .bottom {
            text-align: left;
        }
        .viewCount {
            color: ${Common.colors.greyPrimary};
        }
    }
    .backToApp {
        position: absolute;
        right: 14px;
        top: -86px;
        padding: 6px 8px;
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        border-radius: 16px;
        background-color: ${Common.colors.blue};
    }
`;

const topButtonBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    position: absolute;
    right: 14px;
    top: -48px;
    width: 100%;
`;

const backToAppButtonStyle = (type: string) => css`
    padding: 4px 8px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    border-radius: 16px;
    background-color: ${type === "itemDetail" ? "#0047ff" : "#000000"};
`;

const roadViewButtonStyle = css`
    padding: 8px 10px;
    border-radius: 24px;
    background-color: #0a2540;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    img {
        margin-top: -1px;
    }
`;
export default CardBottomSheetHeader;
