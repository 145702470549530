import React, { useState } from "react";
import { css } from "@emotion/react";
import { top, mid, bot } from "@src/data/itemForFoldableFilterExample";

function FoldableFilter() {
    const initFilters = { top: "", mid: "", bot: "" };
    const [selectedFilters, setSelectedFilters] =
        useState<{
            [key: string]: string;
        }>(initFilters);
    const [filterForms, setFilterForms] = useState(top);
    const [submitFilter, setSubmitFilter] = useState<any[]>([]);

    return (
        <div css={filterWrap}>
            <div className="selected_filter_wrap">
                {submitFilter &&
                    submitFilter.map((selected, index) => {
                        return (
                            <div
                                key={index}
                                className="selected_filter"
                                onClick={() => {
                                    let newSlice = submitFilter.filter(
                                        (v) => v.toString() !== selected,
                                    );
                                    console.log('newSlice',newSlice)
                                    console.log('selected',selected)
                                    setSubmitFilter(newSlice);
                                }}
                            >
                                {selected}
                            </div>
                        );
                    })}
            </div>
            <div className="filter_header">
                {Object.keys(selectedFilters).map(
                    (key: string, index: number) => {
                        let filter = selectedFilters[key];
                        return (
                            <div key={index}>
                                {filter ? (
                                    filter
                                ) : (
                                    <span style={{ color: "#a3a3a3" }}>
                                        지역 {index + 1} 선택
                                    </span>
                                )}
                            </div>
                        );
                    },
                )}
                {selectedFilters.bot && (
                    <button
                        className="submitBtn"
                        onClick={() => {
                            let undefinedIndex = Object.values(
                                selectedFilters,
                            ).findIndex((v) => v === "");
                            if (undefinedIndex === -1) {
                                const { top, mid, bot } = selectedFilters;
                                let newFilter = `${top} > ${mid} > ${bot} x`;
                                let copy: any[] = [...submitFilter];
                                copy.push(newFilter);
                                setSubmitFilter([...copy]);
                            }
                        }}
                    >
                        선택완료
                    </button>
                )}
            </div>
            <ul className="filter_select_form">
                {filterForms?.map((filter) => {
                    let { name, code, type } = filter;
                    return (
                        <li key={code} className={type}>
                            <button
                                onClick={(e: any) => {
                                    const { value } = e.target;
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        [type]: value,
                                    });
                                    if (type === "top") {
                                        setFilterForms(mid[value]);
                                    } else if (type === "mid") {
                                        setFilterForms(bot[value]);
                                    }
                                }}
                                value={name}
                            >
                                {name}
                            </button>
                        </li>
                    );
                })}
            </ul>
            <p className="notice">
                * 본 화면은 테스트 버전으로서 <strong>서울/강남구</strong> 의
                데이터가 입력되어 있습니다.
            </p>
        </div>
    );
}
const filterWrap = css`
    width: 100%;
    height: 400px;
    background-color: #fff;
    .filter_header {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
            margin-right: 10px;
            &::after {
                content: ">";
                margin-left: 5px;
            }
            &:last-of-type::after {
                display: none;
            }
        }
        border-bottom: 1px solid #ddd;
        margin-bottom: 30px;
    }
    .filter_select_form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        & > li {
            background-color: #ebdbfc;
            padding: 10px;
            border-radius: 4px;
        }
    }
    .submitBtn {
        background-color: #ee5b7a;
        font-size: 10px;
        padding: 5px 8px;
        border-radius: 4px;
        margin-left: 10px;
        color: #fff;
    }
    .selected_filter {
        width: fit-content;

        padding: 5px 8px;
        font-size: 10px;
        background-color: #ae6df5;
        color: #fff;
        border-radius: 4px;
    }
    .notice {
        width: 100%;
        padding: 20px;
        color: #ee5b7a;
        text-align: center;
        word-break: keep-all;
        line-height: 1.3;
    }
    .selected_filter_wrap {
        padding: 5px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
        column-gap: 5px;
    }
`;
export default FoldableFilter;
