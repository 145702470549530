import React, { useEffect, useState } from "react";
import { IRegion, useGetLocation } from "../../hooks/newFilter/useGetLocation";
import ShowSelectedUseLocValueBox from "@src/pages/newFilter/ShowSelectedUseLocValueBox";

interface Props {
    location: string[];
}

const NewFilterHistoryLoc: React.FC<Props> = ({ location }) => {
    const [showLocText, setShowLocText] = useState<any[]>([]);
    const makeLocText = async (loc: string) => {
        let sidoText = "";
        let sigunguText = "";
        let emdText = "";
        sidoText = await useGetLocation("0000000000").then((res) => {
            const selectedSido = res.filter((val: any) => val?.cortarNo?.slice(0, 2) === loc.slice(0, 2));
            return selectedSido?.[0]?.cortarName ?? "";
        });
        if (loc.slice(2) !== "00000000") {
            sigunguText = await useGetLocation(`${loc.slice(0, 2)}00000000`).then((res) => {
                const selectedSigungu = res.filter((val: any) => val?.cortarNo?.slice(0, 5) === loc.slice(0, 5));
                return selectedSigungu?.[0]?.cortarName;
            });
        } else {
            return { name: `${sidoText} > 전체`, value: loc };
        }
        if (loc.slice(5) !== "00000") {
            emdText = await useGetLocation(`${loc.slice(0, 5)}00000`).then((res) => {
                const selectedEmd = res.filter((val: any) => val?.cortarNo === loc);
                return selectedEmd?.[0]?.cortarName;
            });
        } else {
            emdText = "전체";
        }
        return { name: `${sidoText} > ${sigunguText} > ${emdText}`, value: loc };
    };
    const settingLocText = async () => {
        const result = await Promise.all(
            location?.map(async (v: any) => {
                return await makeLocText(v);
            }),
        );
        setShowLocText([...result]);
    };
    useEffect(() => {
        if (location && location.length) {
            settingLocText();
        }
    }, [location]);

    return (
        <>
            {showLocText &&
                !!showLocText.length &&
                showLocText.map((locData: any) => <ShowSelectedUseLocValueBox value={locData.name} key={locData.name} />)}
        </>
    );
};

export default NewFilterHistoryLoc;
