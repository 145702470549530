import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useSortBottomSheet } from "@src/hooks/filter/useSortBottomSheet";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import React, { useEffect, useRef, useState } from "react";

type BottomSheetProps = {
    visible: boolean;
    children: any;
};

const BottomSheet = ({ children }: BottomSheetProps) => {
    const [flag, setFlag] = useState(false);
    const { sheet } = useSortBottomSheet();
    const visible = useAiFilterStore((state) => state.visible);
    const setVisible = useAiFilterStore((state) => state.setVisible);

    const modalContainerRef = useRef() as any;

    const clickModalOutside = (event: any) => {
        if (modalContainerRef.current === event.target) {
            setVisible(false);
        }
    };

    useEffect(() => {
        if (visible) {
            setFlag(true);
        }
    }, [visible]);

    useEffect(() => {
        modalContainerRef.current.addEventListener("click", clickModalOutside);
        return modalContainerRef.current.addEventListener("click", clickModalOutside);
    }, []);

    return (
        <BottomSheetWrapper ref={modalContainerRef} visible={visible} flag={flag}>
            <BottomSheetContents flag={flag} visible={visible} ref={sheet}>
                {children}
            </BottomSheetContents>
        </BottomSheetWrapper>
    );
};

export default BottomSheet;

export const appearBottomSheetBackground = keyframes`
  from {
    opacity: 0;
    background: rgba(0, 0, 0, 0); 
  }

  to {
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
  }
`;

const disAppearBottomSheetBackground = keyframes`
  from {
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);    
  }

  to {
    opacity: 0;
    background: rgba(0, 0, 0, 0);
    z-index: -1
  }
`;

export const disAppearBottomSheetBackgroundT1 = keyframes`
  from {
    height: 0%;
    
  }

  to {
    height: 0%;
  }
`;

const BottomSheetWrapper = styled.div<{ visible: boolean; flag: boolean }>`
    position: fixed;
    display: flex;
    opacity: 0;
    top: 0;
    left: 0;
    align-items: flex-end;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    overflow-y: scroll;
    animation: ${({ visible, flag }) =>
            visible ? appearBottomSheetBackground : flag ? disAppearBottomSheetBackground : disAppearBottomSheetBackgroundT1}
        0.2s linear forwards;
`;

const BottomSheetContents = styled.div<{ visible: boolean; flag: boolean }>`
    display: flex;
    width: 100%;
    opacity: 0;
    background: white;
    border-radius: 15px 15px 0px 0px;
    animation: ${({ visible, flag }) =>
            visible ? appearBottomSheet : flag ? disappearBottomSheet : disAppearBottomSheetBackgroundT1}
        0.2s linear forwards;
    height: 360px;
    transition: transform 150ms ease-out;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
`;

const appearBottomSheet = keyframes`
  from {
    opacity: 0;
    height: 0%;
  }

  to {
    opacity: 1;
    min-height: 450px;
  }
`;

const disappearBottomSheet = keyframes`
  from {
    opacity: 1;
    height:50%;
  }
 
  to {
    opacity: 0;
    height: 0%;
    z-index: -1
  }
`;
