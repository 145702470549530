import React, { useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";
import Lottie from "lottie-react";
import { SerializedStyles, css } from "@emotion/react";
import { Grid, Radio } from "@mui/material";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import { ConvertPeriodSubs } from "@src/util/ConvertPeriodSubs";
import recommend from "@src/assets/subs/recommend.png";
import timer from "@src/assets/stateLand/sandglassIcon.json";

interface Props {
    selectedProduct: IProduct;
    onChangeSelectedProduct: (val: IProduct) => void;
    product: IProduct;
    mainCSS: (active: boolean, isEvent: boolean) => SerializedStyles;
    width: number;
}

const IosProductCard: React.FC<Props> = ({ width, mainCSS, product, selectedProduct, onChangeSelectedProduct }) => {
    const [now, setNow] = useState<any>(new Date());

    const getEventDuration = (now: any) => {
        const diffTime = differenceInSeconds(new Date(product.showEndAt).getTime(), now.getTime());
        const secound = diffTime % 60;
        const min = ((diffTime - secound) % 3600) / 60;
        const hours = ((diffTime - min * 60 - secound) % 86400) / 3600;
        const day = ((diffTime - min * 60 - secound - hours * 3600) % 31536000) / 86400;
        const formatTime = `${day}일 ${hours}시간 ${min}분 ${secound}초`;

        return formatTime;
    };

    useEffect(() => {
        return () => {
            setInterval(() => {
                setNow(new Date());
            }, 1000);
        };
    }, []);

    return (
        <>
            <Grid style={{ height: "100%" }} item xs={width} onClick={() => onChangeSelectedProduct(product)}>
                <div
                    css={[
                        mainCSS(
                            product.subsCategory
                                ? selectedProduct?.code === product?.code
                                : selectedProduct?.subsCategory === product?.subsCategory,
                            product.isEvent && now >= new Date(product.showStartAt) && now <= new Date(product.showEndAt),
                        ),
                    ]}
                >
                    {!!product.recommend && <img src={recommend} alt="" className="recommend" width={45} />}
                    {width === 12 ? (
                        <div className="productInfo">
                            <div className="name">
                                <Radio
                                    checked={true}
                                    onChange={() => { }}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        color:
                                            selectedProduct?.code === product.code ? "#2275EE !important" : "#C9C9C9 !important",
                                        mr: "8px",
                                    }}
                                />
                                {product?.name}
                            </div>
                            <p className="desc">{product?.priceDiscountDisplay1}</p>
                        </div>
                    ) : (
                        <div className="name">
                            <Radio
                                checked={true}
                                onChange={() => { }}
                                sx={{
                                    width: 24,
                                    height: 24,
                                    color: selectedProduct?.code === product.code ? "#2275EE !important" : "#C9C9C9 !important",
                                    mr: "8px",
                                }}
                            />
                            {product?.name}
                        </div>
                    )}
                    <div className="priceBox">
                        <p className="regularPrice">
                            <span className="text">{product?.price?.toLocaleString()}원</span>
                            <span className="discount">
                                {Math.floor(
                                    100 -
                                    ((product?.term1DiscountedPrice ? product?.term1DiscountedPrice : 1) /
                                        (product?.price ? product?.price : 1)) *
                                    100,
                                )}
                                %
                            </span>
                        </p>
                        <p className="salePrice">
                            <span className="text">{product.term1DiscountedPrice.toLocaleString()}원</span>
                            <span className="period">/{ConvertPeriodSubs(product)}</span>
                        </p>
                    </div>
                    {product.isEvent && now >= new Date(product.showStartAt) && now <= new Date(product.showEndAt) && (
                        <div css={eventBoxStyle(width)}>
                            <Lottie className="lottie" animationData={timer} />
                            {getEventDuration(now)}
                        </div>
                    )}
                </div>
            </Grid>
        </>
    );
};

export default IosProductCard;

const eventBoxStyle = (width: number) => css`
    width: 100%;
    height: ${width === 12 ? "40px" : "28px"};
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff500;
    border-radius: 0 0 14px 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 375px) {
        & {
            font-size: 12px;
        }
    }
    .lottie {
        height: 40px;
        width: 40px;
    }
`;
