import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import DeungiAlarmHeader from "./DeungiAlarmHeader";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ico_arrow_next_black from "../../assets/ico_arrow_next_black.png";
import ToastMessage from "./ToastMessage";

const DeungiAlarmOption = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("");
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (searchParams.get("option") === "true") {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [searchParams, location]);

    useEffect(() => {
        if (location.state) {
            if (location.state?.modifyComplete) {
                setMessage("수정이 완료되었습니다");
            }
            navigate("/deungi/alarm?option=true", { replace: true, state: null });
        }
    }, [location]);

    return (
        <Container className={visible ? "visible" : ""}>
            <DeungiAlarmHeader title="설정" path="/deungi/alarm" />
            <div className={"btnGroupBox"}>
                <div onClick={() => navigate("/deungi/alarm/list")}>
                    <h3>신청내역</h3>
                    <img src={ico_arrow_next_black} />
                </div>
                <div onClick={() => navigate("/deungi/alarm/setting")}>
                    <h3>알림설정</h3>
                    <img src={ico_arrow_next_black} />
                </div>
            </div>
            <ToastMessage message={message} setMessage={setMessage} />
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    top: 0;
    right: -100%;
    background: #fff;
    width: 100%;
    height: 100dvh;
    z-index: 2;

    &.visible {
        left: 0;
    }

    & > .btnGroupBox {
        opacity: 0;
        animation: fadeIn 0.3s forwards;
        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 14px;
            cursor: pointer;

            & > h3 {
                font-size: 16px;
                font-weight: 500;
            }
            & > img {
                width: 16px;
                height: 16px;
            }
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export default DeungiAlarmOption;
