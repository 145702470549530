import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "../button/Button";
import ico_arrow_down_bold from "../../assets/ico_arrow_down_bold.svg";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import { useSortBottomSheet } from "@src/hooks/filter/useSortBottomSheet";
import { isEmpty } from "lodash";

interface AiBottomSheetContentTitleProps {
    buttonWrapper: any;
    sort: any;
    totalData: string;
    visible: boolean;
    buttonRef1: any;
    buttonRef2: any;
    buttonRef3: any;
}

const AiBottomSheetContentTitle = ({
    buttonWrapper,
    sort,
    totalData,
    visible,
    buttonRef1,
    buttonRef2,
    buttonRef3,
}: AiBottomSheetContentTitleProps) => {
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const setAiFilterDataList = useAiFilterStore((state) => state.setAiFilterDataList);
    const setDimVisible = useAiFilterStore((state) => state.setDimVisible);
    const sortText = useAiFilterStore((state) => state.selectedSortText);
    const [type, setType] = useState(isEmpty(aiFilterState.type) ? "0" : aiFilterState.type);
    const sortId = useAiFilterStore((state) => state.selectedSortId);
    const AiFilterDataList = useAiFilterStore((state) => state.aiFilterDataList);
    const AiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const setVisible = useAiFilterStore((state) => state.setVisible);
    const setAiSortRef = useAiFilterStore((state) => state.setAiSortRef);

    const handleSaveType = (type: string) => {
        console.log("type", type);
        setAiFilterState(
            {
                ...aiFilterState,
                type: type,
            },
            sortId,
        );
        setAiFilterDataList(
            {
                ...aiFilterState,
                type: type,
            },
            sortId,
        );
    };

    const changeVisible = () => {
        setDimVisible(!visible);
        setVisible(true);
    };

    const showData = () => {
        if (
            AiFilterDataList.length === 0 ||
            AiFilterDataList[0].item_id === "" ||
            AiFilterState.price === "" ||
            AiFilterState.price === "0"
        ) {
            return (
                <>
                    총 매물<span>0</span>건
                </>
            );
        } else {
            return (
                <>
                    총 매물<span>{totalData}</span>건
                </>
            );
        }
    };

    useEffect(() => {
        setAiSortRef(sort, buttonWrapper);
    }, []);

    useEffect(() => {
        setType(!aiFilterState.type ? "0" : aiFilterState.type.toString());
    }, [aiFilterState.type]);

    return (
        <>
            <TitleWrapper>
                <TitleButtonWrapper ref={buttonWrapper}>
                    <Button
                        label="전체"
                        // label=""
                        color={type === "0" ? "newBlue" : "gray"}
                        size="small"
                        // isDisabled={type !== "0"}
                        styles={{
                            width: "36.5%",
                            height: "100%",
                            fontSize: "0px",
                            fontWeight: 700,
                            lineHeight: "175%",
                            letterSpacing: "-0.4px",
                            transition: "none",
                            border: "0px",
                            padding: "0px",
                        }}
                        handleClick={() => {
                            handleSaveType("0");
                            setType("0");
                        }}
                        buttonRef={buttonRef1}
                    />
                    <Button
                        label="경매"
                        // label=""
                        color={type === "1" ? "newBlue" : "gray"}
                        size="small"
                        // isDisabled={type !== "1"}
                        styles={{
                            width: "30%",
                            height: "100%",
                            fontSize: "0px",
                            fontWeight: 700,
                            lineHeight: "175%",
                            letterSpacing: "-0.4px",
                            transition: "none",
                            border: "0px",
                            padding: "0px",
                        }}
                        handleClick={() => {
                            setType("1");
                            handleSaveType("1");
                        }}
                        buttonRef={buttonRef2}
                    />
                    <Button
                        label="공매"
                        // label=""
                        color={type === "2" ? "newBlue" : "gray"}
                        size="small"
                        // isDisabled={type !== "2"}
                        styles={{
                            width: "30%",
                            height: "100%",
                            fontSize: "0px",
                            fontWeight: 700,
                            lineHeight: "175%",
                            letterSpacing: "-0.4px",
                            transition: "none",
                            border: "0px",
                            padding: "0px",
                        }}
                        handleClick={() => {
                            handleSaveType("2");
                            setType("2");
                        }}
                        buttonRef={buttonRef3}
                    />
                </TitleButtonWrapper>
                <SubTitleWrapper>
                    <AllDataList>{showData()}</AllDataList>

                    <StyledSort ref={sort} onClick={() => changeVisible()}>
                        {sortText} <div></div>
                    </StyledSort>
                </SubTitleWrapper>
            </TitleWrapper>
        </>
    );
};

const TitleWrapper = styled.div`
    width: 100%;
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const TitleButtonWrapper = styled.div`
    display: flex;
    gap: 4px;
    width: 100%;
    height: 0px;
    background: ${Common.colors.grey01};
    border-radius: 8px;
    /* display: none; */
`;

const SubTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`;

const AllDataList = styled.div`
    text-align: center;
    ${Common.textStyle.title6_R14};
    color: ${Common.colors.black};
    & > span {
        margin-left: 4px;
        margin-right: 4px;
        color: ${Common.colors.notiColor};
    }
`;

const StyledSort = styled.div`
    text-align: center;
    color: ${Common.colors.black};
    ${Common.textStyle.title6_R14}
    display: none;
    & > div {
        background: url(${ico_arrow_down_bold});
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        margin-left: 6px;
    }
`;

export default AiBottomSheetContentTitle;
