import { css } from "@emotion/react";
import React, { useState } from "react";
import ico_search_black_bold from "@src/assets/ico_search_black_bold.svg";

interface ListSearchInputProps {
    keyword?: string;
}
const ListSearchInput = ({ keyword }: ListSearchInputProps) => {
    return (
        <div css={InputWrapper}>
            <input placeholder="소재지, 사건번호 검색" value={keyword ?? ""} readOnly />
            <div className="imgWrapper">
                <img src={ico_search_black_bold} />
            </div>
        </div>
    );
};

export default ListSearchInput;

const InputWrapper = css`
    position: relative;
    width: 100%;
    height: 56px;
    padding: 4px 0px;
    box-sizing: border-box;
    z-index: 2;

    input {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        opacity: 1;
        height: 100%;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        padding: 0px 10px 0px 50px;
        font-size: 16px;
        background: #ffffff;
    }
    input:disabled {
        background-color: #fff;
        opacity: 1;
    }

    input:focus {
        outline: none;
    }

    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        /* right: 17px; */
        left: 17px;
        top: calc(50% - 12px);
        width: 24px;
        height: 24px;
    }
`;
