import styled from "@emotion/styled";
import ico_close from "@src/assets/ico_close.png";
import { CheckDeungiResponseDto, SubmitDeungiRequestDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { useCheckDeungi } from "@src/hooks/deungi/useDeungiInfo";
import { useDeungiSubmit, useExistDeungiSubmit } from "@src/hooks/deungi/useDeungiSubmit";
import { useTicketCount } from "@src/hooks/deungi/useTicketCount";
import { transferToNative } from "@src/lib/WebAppBridge";
import dayjs from "dayjs";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEUNGI_POPUP_TYPE, TICKET_TYPE } from "./common/enum";

import existDeungiIcon from "../../assets/exist_deungi_icon.json";
import _ from "lodash";
import { DeungiAlarmResponseDto } from "@src/hooks/deungi/dto/DeungiAlarm.dto";
import { useDeungiChangeAlarmExpired, useDeungiChangeAlarmList } from "@src/hooks/deungi/useDeungiAlarm";
import { useSelectedDeungi, useSelectedExistDeungi } from "@src/store/deungiPayment";

type DeungiPopupPropsType = {
    type: DEUNGI_POPUP_TYPE;
    toggle: boolean;
    setToggle: (flag: boolean) => void;
    data?: any;
    address?: string;
};

const DeungiPopup = ({ type, toggle, setToggle, data, address }: DeungiPopupPropsType) => {
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(true);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(type);
    const [ticketCount, setTicketCount] = useState(0);
    const [dataInfo, setDataInfo] = useState({} as any);
    const [ticketSelect, setTicketSelect] = useState(0);
    const setUserSelectedDeungi = useSelectedDeungi((state: any) => state.setSelectedDeungi);
    const setUserSelectedExistDeungi = useSelectedExistDeungi((state: any) => state.setSelectedExistDeungi);

    const getTicketCount = async (ticketType?: TICKET_TYPE) => {
        const res = await useTicketCount(ticketType);
        setTicketCount(res.data.curHaveCnt);
    };

    const submitDeungi = async (params: SubmitDeungiRequestDto) => {
        const res = await useDeungiSubmit(params);
        const id: string = res.data.id;
        return id;
    };

    const submitExistDeungi = async (params: SubmitDeungiRequestDto) => {
        const res = await useExistDeungiSubmit(params);
        const id: string = res.data.id;
        return id;
    };

    const onOkHandler = () => {
        setToggle(false);
    };

    const onNextHandler = () => {
        getTicketCount();
        setPopupType(DEUNGI_POPUP_TYPE.OPEN);
    };

    const onExistNextHandler = () => {
        getTicketCount(TICKET_TYPE.EXIST_DEUNGI_TICKET);
        setPopupType(DEUNGI_POPUP_TYPE.EXIST_OPEN);
    };

    const onYearSubsExistNextHandler = async () => {
        switch (ticketSelect) {
            case 0:
                onNextHandler();
                break;
            case 1:
                if (!data?.myId) {
                    const params: SubmitDeungiRequestDto = {
                        address: data.address,
                        pin: data.pin,
                        deungi_type: data.deungi_type,
                        item_type: data.item_type,
                    };
                    const id = await submitExistDeungi(params);
                    if (id) {
                        data.id = id;
                        navigate("/deungi/pdf", {
                            state: {
                                data,
                                isBtnShow: true,
                            },
                        });
                    }
                } else {
                    navigate("/deungi/pdf", {
                        state: {
                            data,
                            isBtnShow: true,
                        },
                    });
                }

                setUserSelectedDeungi({
                    address: data.address,
                    pin: data.pin,
                    deungi_type: data.deungi_type,
                    item_type: data.item_type,
                })
                setUserSelectedExistDeungi(data)
                setToggle(false);
                break;
        }
    };

    const onSelectNextHandler = () => {
        setPopupType(DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST);
    };

    const onTicketSelectHandler = () => {
        switch (ticketSelect) {
            case 0:
                onNextHandler();
                break;
            case 1:
                onExistNextHandler();
                break;
        }
    };

    const gotoDeungiPage = () => {
        navigate(`/deungi?type=search&searchText=${address ?? ""}`);
        // transferToNative(address, "showDeungiView");
        setToggle(false);
    };

    const onOpenHandler = async () => {
        if (isValid) {
            setIsValid(false);
            const checkDeungiRes = await useCheckDeungi();
            const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

            if (!checkDeungiInfo.isCheck) {
                setDataInfo(checkDeungiInfo);
                setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
                return;
            }

            if (ticketCount > 0) {
                const params: SubmitDeungiRequestDto = {
                    address: data.address,
                    pin: data.pin,
                    deungi_type: data.deungi_type,
                    item_type: data.item_type,
                };
                const id = await submitDeungi(params);

                if (id) {
                    navigate({
                        pathname: "/deungi",
                        search: `?type=search&watch=${id}`,
                    });
                }
            } else {
                navigate("/deungi/payment", { replace: false, state: { type: TICKET_TYPE.DEUNGI_TICKET } });
            }
            setUserSelectedDeungi({
                address: data.address,
                pin: data.pin,
                deungi_type: data.deungi_type,
                item_type: data.item_type,
            });
            setUserSelectedExistDeungi(data)
            setToggle(false);
            setIsValid(true);
        }
    };

    const onExpiredAlarmHandler = async () => {
        if (isValid) {
            setIsValid(false);
            if (data?._id !== "") {
                try {
                    const res: any = await useDeungiChangeAlarmExpired(data._id);

                    if (res.statusCode === 500) {
                        navigate("/deungi/alarm/list", { state: { expiredFail: true } });
                    } else {
                        navigate("/deungi/alarm/list", { state: { expiredComplete: true } });
                    }
                } catch (e: any) {
                    console.log("e", e);
                }
            }
            setToggle(false);
            setIsValid(true);
        }
    };

    const onAlarmOpenHandler = async () => {
        if (isValid) {
            setIsValid(false);
            const checkDeungiRes = await useCheckDeungi();
            const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

            if (!checkDeungiInfo.isCheck) {
                setDataInfo(checkDeungiInfo);
                setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
                return;
            }

            if (ticketCount > 0) {
                const params: SubmitDeungiRequestDto = {
                    address: data.address,
                    pin: data.pin,
                    deungi_type: data.deungi_type,
                    item_type: data.item_type,
                };
                const id = await submitDeungi(params);

                if (id) {
                    transferToNative(id, "showDeungiWatchView");
                }
            } else {
                navigate("/deungi/payment", { replace: false, state: { type: TICKET_TYPE.DEUNGI_TICKET } });
            }
            setToggle(false);
            setIsValid(true);
        }
    };

    const onExistOpenHandler = async () => {
        if (isValid) {
            setIsValid(false);
            const checkDeungiRes = await useCheckDeungi();
            const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

            if (!checkDeungiInfo.isCheck) {
                setDataInfo(checkDeungiInfo);
                setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
                return;
            }

            if (ticketCount > 0) {
                const params: SubmitDeungiRequestDto = {
                    address: data.address,
                    pin: data.pin,
                    deungi_type: data.deungi_type,
                    item_type: data.item_type,
                };
                const id = await submitExistDeungi(params);

                if (id) {
                    data.id = id;
                    navigate("/deungi/pdf", {
                        state: {
                            data,
                            isBtnShow: true,
                        },
                    });
                    setToggle(false);
                }
            } else {
                navigate("/deungi/payment", { replace: false, state: { type: TICKET_TYPE.EXIST_DEUNGI_TICKET } });
            }
            setToggle(false);
            setIsValid(true);
        }
    };

    const onReopenHandler = () => {
        navigate("/deungi/pdf", {
            state: {
                data,
                isBtnShow: true,
            },
        });
        setToggle(false);
    };

    useEffect(() => {
        setPopupType(type);
        setDataInfo(data || {});
        if (toggle && (type === DEUNGI_POPUP_TYPE.OPEN || type === DEUNGI_POPUP_TYPE.OPEN_WITH_ADDRESS)) getTicketCount();
    }, [toggle]);

    if (!toggle) return <></>;

    switch (popupType) {
        case DEUNGI_POPUP_TYPE.LIMIT_BUILDING_REGISTER:
            return (
                <Container>
                    <div>
                        <h4 dangerouslySetInnerHTML={{ __html: data }}></h4>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.ALARM_PERIOD_INFO:
            return (
                <Container>
                    <div>
                        <h4>변동 확인 기간</h4>
                        <ul>
                            <li>
                                변동 확인 기간이란? 알림 신청 후 변동알림을 받는 기간을 말합니다(1개월 선택 시 1개월 동안 신청한
                                주소의 등기변동을 확인하며 기간이 지나면 해당 주소의 변동 알림 전송이 종료됩니다)
                            </li>
                            <li>변동 확인 기간은 선택할 수 있으며 알림 신청 후 수정이 불가합니다</li>
                            <li>
                                기간종료 이전, 알림 전송 중단을 원하는 경우 신청 내역에서 해당 주소를 삭제하여 중단할 수 있습니다
                            </li>
                        </ul>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.INPUT_OWNER_INFO:
            return (
                <Container>
                    <div>
                        <h4>소유자 입력</h4>
                        <ul>
                            <li>신청한 주소의 등기상 소유자와 동일한 소유자를 입력해주세요</li>
                            <li>일치하지 않으면 정확한 변동알림 전송이 불가합니다</li>
                            <li>입력한 소유자는 수정할 수 있으며 일치여부는 사건 변동 발생 시 확인됩니다</li>
                        </ul>
                        <div className="contents">
                            입력 tip
                            <br />
                            {`1. 올바른 입력사례 예) "김갑동" (O)`}
                            <br />
                            {`2. 잘못된 입력 사례(성명에 허용하지 않는 특수문자(한글, 숫자,하이픈,쉼표,마침표,괄호,공백,엔터 외)가
                            사용된 경우)`}{" "}
                            <br />
                            {`예) "김갑동 지분:1/2" (X), "주식회사유&아이[대표자:김갑동]" (X), "김갑동 외 1명"`} (X)
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.OPEN_WITH_ADDRESS:
            return (
                <Container>
                    <div>
                        <h4>해당 등기를 열람하시겠어요?</h4>
                        <div className="contents">
                            주소: {data?.address}
                            <br />
                            구분: {data?.item_type}
                        </div>
                        <div className="info">실시간 등기 열람권 : {ticketCount}장</div>
                        <div className="contents">※ 등기 열람권이 없는 경우 충전 페이지로 이동합니다</div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="취소" onClick={onOkHandler} />
                            <input type="button" value="확인" onClick={onAlarmOpenHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.ALARM_LIST:
            return (
                <Container>
                    <div>
                        <h4>알림전송 내역</h4>
                        <div className="contents" style={{ wordBreak: "keep-all" }}>
                            {(data as DeungiAlarmResponseDto)?.address || ""}
                        </div>
                        <div className="alarmListBox">
                            <table>
                                <thead>
                                    <tr>
                                        <th>목적(사유)</th>
                                        <th>변동상태</th>
                                        <th>전송일시 및 방식</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data as DeungiAlarmResponseDto)?.alarm_logs?.length > 0 ? (
                                        (data as DeungiAlarmResponseDto)?.alarm_logs?.map((el) => {
                                            return (
                                                <tr key={el.id}>
                                                    <td>{el.purpose}</td>
                                                    <td>{el.state}</td>
                                                    <td>
                                                        {dayjs(el.inDate).format("YY.MM.DD HH:mm")} {"앱푸시"}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td className={"noList"} colSpan={3}>
                                                전송 내역이 없습니다
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="inputBox">
                            <input type="button" value="확인" onClick={onOkHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.ALARM_EXPIRED:
            return (
                <Container>
                    <div>
                        <h4>정말 종료하시겠습니까?</h4>
                        <div className="contents">알림 종료시 해당 주소 등기 변동 확인 및 알림 전송이 즉시 종료됩니다</div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="취소" onClick={onOkHandler} />
                            <input type="button" value="확인" onClick={onExpiredAlarmHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.ALARM_OVER_3:
            return (
                <Container>
                    <div>
                        <h4>알람은 최대 3개까지 신청 가능해요</h4>
                        <div className="contents">등록된 알림을 종료하고 신청해주세요</div>
                        <div className="inputBox">
                            <input type="button" value="확인" onClick={onOkHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.INFO:
            return (
                <Container>
                    <div>
                        <h4>소재지 검색 안내</h4>
                        <ul>
                            <li>집합건물(아파트 등)의 경우 동호수를 입력하면 더 정확한 검색이 가능해요</li>
                            <li>도로명주소로 조회되지 않는 경우 지번주소를 입력해주세요</li>
                        </ul>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.SERVICE_DENY:
            return (
                <Container>
                    <div>
                        <h4>
                            인터넷등기소 점검으로 인하여
                            <br />
                            일시적으로 서비스를 이용할 수 없습니다
                        </h4>
                        <div className="warning">
                            {dataInfo.openAtToString} ~ <br />
                            {dataInfo.closeAtToString}
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.OPEN:
            return (
                <Container>
                    <div>
                        <h4>해당 등기를 열람하시겠어요?</h4>
                        <div className="info">내 실시간 등기 열람권 : {ticketCount}장</div>
                        <div className="contents">※ 등기 열람권이 없는 경우 충전 페이지로 이동합니다</div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="취소" onClick={onOkHandler} />
                            <input type="button" value="확인" onClick={onOpenHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.EXIST_OPEN:
            return (
                <Container>
                    <div>
                        <h4>해당 등기를 열람하시겠어요?</h4>
                        <div className="info">내 기존 등기 열람권 : {ticketCount}장</div>
                        <div className="contents">※ 등기 열람권이 없는 경우 충전 페이지로 이동합니다</div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="취소" onClick={onOkHandler} />
                            <input type="button" value="확인" onClick={onExistOpenHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.REOPEN:
            return (
                <Container>
                    <div>
                        <div className="exitBtnBox" onClick={onOkHandler}>
                            <img src={ico_close} />
                        </div>
                        <h4>아래 날짜에 열람된 등기가 있어요</h4>
                        <div className="info">{dayjs(data.updatedAt).format("YYYY.MM.DD HH:mm:ss 열람")}</div>
                        <div className="contents">
                            열람일이 한 달 이상 지난 등기는
                            <br />
                            무료로 재열람할 수 있어요
                        </div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="재열람하기" onClick={onReopenHandler} />
                            <input type="button" value="신규 열람하기" onClick={onNextHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.NO_DEUNGI:
            return (
                <Container>
                    <div>
                        <h4>수집된 등기가 없어요</h4>
                        <div className="contents">해당 지번의 등기를 열람하고 싶으신가요?</div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="취소" onClick={onOkHandler} />
                            <input className="blue" type="button" value="열람하러 가기" onClick={gotoDeungiPage} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.IS_IOS:
            return (
                <Container>
                    <div>
                        <h4>현재 준비중이에요</h4>
                        <div className="contents">
                            필지에서 등기 열람은 현재 준비중이에요
                            <br />
                            5월 초 업데이트에 만나요
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.ERROR:
            return (
                <Container>
                    <div>
                        <h4>
                            인터넷등기소 점검으로 인하여
                            <br />
                            일시적으로 서비스를 이용할 수 없습니다
                        </h4>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.HAS_DEUNGI:
            return (
                <Container>
                    <div>
                        <div className="exitBtnBox" onClick={onOkHandler}>
                            <img src={ico_close} />
                        </div>
                        <h5>열람한 등기가 있어요</h5>
                        <div className="contents-exist">
                            <div className="titleBox">
                                <label>소재지</label>
                                <span>{data.address}</span>
                            </div>
                            <div className="titleBox">
                                <label>구분(상태)</label>
                                <span>
                                    {data.itemType}({data.type})
                                </span>
                            </div>
                            <div className="titleBox">
                                <label>열람일</label>
                                <span>{dayjs(data.updatedAt).format("YY.MM.DD")}</span>
                            </div>
                        </div>
                        <div className="inputBox">
                            <input className="gray" type="button" value="신규 열람하기" onClick={onNextHandler} />
                            <input type="button" value="재열람하기" onClick={onReopenHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.PAY_EXIST_DEUNGI:
            return (
                <Container>
                    <div className="bottomPopup">
                        <div className="exitBtnBox" onClick={onOkHandler}>
                            <img src={ico_close} />
                        </div>
                        <h4>수집된 등기가 있어요</h4>
                        <div className="contents">
                            <Lottie className="lottie" animationData={existDeungiIcon} />
                            스탠다드 1년권, 프리미엄 1년권 구독을 결제하면
                            <br />
                            수집된 3만여개 기존 등기 무제한 열람
                            <label>이외 구독은 한 달이 지난 등기만 무료로 열람할 수 있어요</label>
                        </div>
                        <div className="inputBox">
                            <input
                                className="blue"
                                type="button"
                                value="결제하기"
                                onClick={() => {
                                    transferToNative("", "showSubsView");
                                }}
                            />
                            <input className="gray-2" type="button" value="등기만 결제하기" onClick={onSelectNextHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST:
            return (
                <Container>
                    <div className="bottomPopup">
                        <div className="exitBtnBox" onClick={onOkHandler}>
                            <img src={ico_close} />
                        </div>
                        <h4>등기열람</h4>
                        <div className="titleBox">
                            <label>소재지</label>
                            <span>{data.address}</span>
                        </div>
                        <div className="contents-exist">
                            <div
                                className={ticketSelect === 0 ? "selectBox active" : "selectBox"}
                                onClick={() => setTicketSelect(0)}
                            >
                                <input type="radio" checked={ticketSelect === 0} readOnly />
                                <div>
                                    <h3>실시간 등기열람</h3>
                                    <label>실시간으로 열람된 신규 등기를 확인할 수 있어요</label>
                                </div>
                            </div>
                            <div
                                className={ticketSelect === 1 ? "selectBox active" : "selectBox"}
                                onClick={() => setTicketSelect(1)}
                            >
                                <input type="radio" checked={ticketSelect === 1} readOnly />
                                <div>
                                    <h3>기존 등기열람</h3>
                                    <label>
                                        기존 등기열람을 하면{" "}
                                        <span>
                                            {dayjs(data.updatedAt).format("YY.MM.DD")}({data.itemType}, {data.type})
                                        </span>
                                        에 열람된 등기가 열람돼요{" "}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="inputBox">
                            <input className="blue" type="button" value="열람하기" onClick={onTicketSelectHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST_YEAR_SUBS:
            return (
                <Container>
                    <div className="bottomPopup">
                        <div className="exitBtnBox" onClick={onOkHandler}>
                            <img src={ico_close} />
                        </div>
                        <h4>등기열람</h4>
                        <div className="titleBox">
                            <label>소재지</label>
                            <span>{data.address}</span>
                        </div>
                        <div className="contents-exist">
                            <div
                                className={ticketSelect === 0 ? "selectBox active" : "selectBox"}
                                onClick={() => setTicketSelect(0)}
                            >
                                <input type="radio" checked={ticketSelect === 0} readOnly />
                                <div>
                                    <h3>실시간 등기열람</h3>
                                    <label>실시간으로 열람된 신규 등기를 확인할 수 있어요</label>
                                </div>
                            </div>
                            <div
                                className={ticketSelect === 1 ? "selectBox active" : "selectBox"}
                                onClick={() => setTicketSelect(1)}
                            >
                                <input type="radio" checked={ticketSelect === 1} readOnly />
                                <div>
                                    <h3>
                                        기존 등기열람
                                        <small>무제한 무료</small>
                                    </h3>
                                    <label>
                                        기존 등기열람을 하면{" "}
                                        <span>
                                            {dayjs(data.updatedAt).format("YY.MM.DD")}({data.itemType}, {data.type})
                                        </span>
                                        에 열람된 등기가 열람돼요{" "}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="inputBox">
                            <input className="blue" type="button" value="열람하기" onClick={onYearSubsExistNextHandler} />
                        </div>
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.PACKAGE_INFO_DEUNGI_TICKET:
            return (
                <Container>
                    <div>
                        <h4>실시간 등기부등본 열람권</h4>
                        <div className="contents">
                            전국 등기부등본을
                            <br />
                            실시간으로 열람하실 수 있습니다.
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.PACKAGE_INFO_EXIST_DEUNGI_TICKET:
            return (
                <Container>
                    <div>
                        <h4>기존 등기부등본 열람권</h4>
                        <div className="contents">
                            한달 이내 수집된 등기부등본을 <br />
                            열람할 수 있습니다.
                            <br />
                            ※스탠다드 1년 이용권 구독 시 수집된 모든 등기 무제한 무료 열람이 가능합니다.
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.PACKAGE_INFO_ITEMS_ADS_TICKET:
            return (
                <Container>
                    <div>
                        <h4>매물광고권</h4>
                        <div className="contents">
                            원하는 매물에 한 달 동안
                            <br />
                            광고를 할 수 있습니다.
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.PACKAGE_INFO_REGION_ADS_TICKET:
            return (
                <Container>
                    <div>
                        <h4>지역광고권</h4>
                        <div className="contents">
                            지역광고권 1장으로 한 지역(읍면동)의
                            <br /> 모든 매물을 1개월 동안 광고할 수 있어요!
                            <label>
                                ※ 같은 지역 연속으로 광고하고 싶을 경우
                                <br /> 지역광고 만료 이후 다시 지역광고권을 구입
                                <br /> 해야해요!
                            </label>
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.ERROR_BUILDING_REGISTER:
            return (
                <Container>
                    <div>
                        <h4>
                            건축행정시스템 점검으로 인하여
                            <br />
                            일시적으로 서비스를 이용할 수 없습니다
                        </h4>
                        <div className="warning">
                            {dataInfo.openAtToString} ~ <br />
                            {dataInfo.closeAtToString}
                        </div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
        case DEUNGI_POPUP_TYPE.WAITING:
            return (
                <Container>
                    <div>
                        <h4>서비스 준비중</h4>
                        <div className="contents">※ 해당 서비스는 추후 공개될 예정입니다</div>
                        <input type="button" value="확인" onClick={onOkHandler} />
                    </div>
                </Container>
            );
    }
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s;
    z-index: 1000;

    & > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 24px;
        right: 24px;
        margin: auto;
        height: fit-content;
        color: #0c002c;
        background: #fff;
        border-radius: 14px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        padding: 14px;

        h4 {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            line-height: 1.6;
        }
        h5 {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            line-height: 2;
            margin: 20px 0;
        }
        ul {
            margin: 5px 0;
            line-height: 1.7;
            padding: 0 14px;

            li {
                font-size: 14px;
                list-style: disc;
                word-break: break-all;
            }
        }
        .exitBtnBox {
            position: absolute;
            top: 0;
            right: 0;
            padding: 18px;

            & > img {
                width: 14px;
                height: 14px;
            }
        }
        .warning {
            font-size: 14px;
            text-align: center;
            line-height: 1.8;
            margin-top: 10px;
            color: #ff0000;
        }
        .info {
            font-size: 14px;
            text-align: center;
            line-height: 1.8;
            margin-top: 10px;
            color: #2275ee;
        }
        .contents {
            font-size: 14px;
            color: #0c002c;
            line-height: 1.6;
            margin: 10px 0;
            text-align: center;

            & > label {
                display: block;
                font-size: 14px;
                color: #8d8d8d;
                margin: 10px 0;
            }
        }
        .titleBox {
            word-break: keep-all;
            & > label {
                display: block;
                color: #8d8d8d;
            }
            & > span {
                display: block;
                margin: 3px 0 15px;
            }
        }
        .contents-exist {
            font-size: 14px;
            color: #0c002c;
            line-height: 1.6;
            margin: 10px 0;
            text-align: left;
        }
        .inputBox {
            display: flex;
            justify-content: space-between;
            column-gap: 10px;
        }
        input {
            width: -webkit-fill-available;
            height: 55px;
            background: #2275ee;
            border: none;
            border-radius: 14px;
            color: #fff;
            font-size: 18px;
            margin-top: 15px;
            padding: 0 10px;

            &.gray {
                background: #ebebeb;
                color: #0c002c;
            }
            &.blue {
                background: #0047ff;
            }
        }
        .alarmListBox {
            max-height: calc(100dvh - 300px);
            overflow: scroll;

            & > table {
                border-collapse: separate;
                width: 100%;
                font-size: 11px;

                & > thead {
                    position: sticky;
                    top: 0;
                }
                & > thead > tr {
                    background: #fbf8f8;
                    color: #505050;

                    & > th {
                        word-break: keep-all;
                        font-weight: 500;
                        border-top: 1px solid #c9c9c9;
                        border-bottom: 1px solid #c9c9c9;
                        border-right: 1px solid #c9c9c9;
                        padding: 7px 0;
                    }
                    & > th:nth-of-type(1) {
                        border-left: 1px solid #c9c9c9;
                        border-radius: 4px 0 0 0;
                    }
                    & > th:nth-of-type(3) {
                        border-radius: 0 4px 0 0;
                    }
                }
                & > tbody > tr {
                    color: #0c002c;
                    animation: fadeIn 0.5s;

                    & > td {
                        word-break: keep-all;
                        font-weight: 500;
                        line-height: 1.4;
                        vertical-align: middle;
                        text-align: center;
                        padding: 7px 3px;
                        border-bottom: 1px solid #c9c9c9;
                        border-right: 1px solid #c9c9c9;
                    }
                    & > td:nth-of-type(1) {
                        border-left: 1px solid #c9c9c9;
                    }
                }
                & > tbody > tr:nth-last-of-type(1) {
                    & > td:nth-of-type(1) {
                        border-radius: 0 0 0 4px;
                    }
                    & > td:nth-last-of-type(1) {
                        border-radius: 0 0 4px 0;
                    }
                    .noList {
                        color: #8d8d8d;
                        border-radius: 0 0 4px 4px !important;
                    }
                }
            }
        }
    }
    .bottomPopup {
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 14px 14px 0 0;
        padding: 24px 0 14px;
        animation: slideUp 0.3s ease;
        margin: auto;

        .inputBox {
            display: block;
        }
        .exitBtnBox {
            top: 12px;
        }
        & > h4 {
            font-size: 18px;
            font-weight: bold;
        }
        .titleBox {
            background: #f6faff;
            margin-top: 10px;
            padding: 14px 14px;

            & > label {
                font-size: 16px;
                color: #0c002c;
            }
            & > span {
                margin: 10px 0 0;
                font-size: 14px;
                line-height: 1.6;
                color: #3c3c3c;
            }
        }
        .contents {
            margin: 0 14px 0;
            font-size: 16px;

            .lottie {
                width: 120px;
                height: 120px;
                margin: 6px auto 12px;
            }
        }
        .contents-exist {
            .selectBox {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                border: 1px solid #8d8d8d;
                border-radius: 14px;
                padding: 14px;
                margin: 15px 14px 0;

                &.active {
                    border: 1px solid #0047ff;
                    box-shadow: inset 0 0 0 1px #0047ff;
                }
                & > input {
                    width: 15px;
                    height: 15px;
                    margin: 0 14px 0 0;
                }
                & > div {
                    & > h3 {
                        display: flex;
                        align-items: center;
                        font-size: 18px;

                        & > small {
                            font-size: 14px;
                            font-weight: 500;
                            background: #dff0ff;
                            border-radius: 7px;
                            color: #0c002c;
                            padding: 2px 4px;
                            margin-left: 6px;
                        }
                    }
                    & > label {
                        font-size: 13px;
                        color: #8d8d8d;
                        & > span {
                            color: #ff0000;
                        }
                    }
                }
            }
        }
        input {
            margin: 12px 14px;

            &.gray-2 {
                background: #c9c9c9;
                color: #fff;
            }
            &.blue {
                background: #0047ff;
                font-weight: bold;
            }
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes slideUp {
        from {
            bottom: -500px;
        }
        to {
            bottom: 0;
        }
    }
`;
export default DeungiPopup;
