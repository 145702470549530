import { css } from "@emotion/react";
import AiTitle from "@src/components/aiFilter/AiTitle";
import { useUserAiSettingSotre } from "@src/store/AiRecommendFilterStore";
import React from "react";

interface Props {
    bidType: number;
    onChangeBidTye: (key: string, val: any) => void;
    onSubmitShowToast: () => void;
}

const AiRecommendAuctionStep: React.FC<Props> = ({ bidType, onChangeBidTye, onSubmitShowToast }) => {

    const saleMethodList = ["전체", "매각", "임대(대부)"];
    const assetsList = ["압류재산", '국공유재산', '신탁재산', '기타재산']
    const userAiSettingFilterState = useUserAiSettingSotre((state) => state.userAiSettingState);
    const auctionTypeList = [
        {
            id: 0,
            title: '전체',
        },
        {
            id: 1,
            title: '경매',
        },
        {
            id: 2,
            title: '공매',
        },
    ]


    const onClickAssetDetail = (idx: number) => {
        if (idx === -1) {
            onChangeBidTye('assetDetails', []);
        } else {
            if (userAiSettingFilterState.assetDetails.includes(idx)) {
                onChangeBidTye('assetDetails', [...userAiSettingFilterState.assetDetails.filter((ele) => ele !== idx)])
            } else {
                if (userAiSettingFilterState.assetDetails.length === 3) {
                    onChangeBidTye('assetDetails', []);
                } else {
                    onChangeBidTye('assetDetails', [...userAiSettingFilterState.assetDetails, idx])
                }
            }
        }
    }

    return (
        <div css={rootBoxStyle}>
            <AiTitle>
                <h2>
                    <span className="color-text">경공매</span>를 선택해주세요.
                </h2>
            </AiTitle>
            <div>

            </div>
            <div css={auctionListBox}>
                {
                    auctionTypeList.map((ele, index) => {
                        return (
                            <button onClick={() => onChangeBidTye("bidType", ele.id)} css={auctionBtn(ele.id === bidType)} key={ele.id.toString()}>
                                {ele.title}
                            </button>
                        )
                    })
                }
            </div>
            {bidType === 2 && (
                <>
                    <div>
                        <div css={titleBoxStyle}>
                            <p className="titleText"><span>처분방식</span>을 선택해주세요</p>
                        </div>
                        <div css={selectButtonBoxStyle}>
                            {saleMethodList.map((sale, idx) => (
                                <button
                                    key={sale}
                                    css={selectButtonStyle(userAiSettingFilterState.saleMethod === idx)}
                                    onClick={() => {
                                        onChangeBidTye("saleMethod", idx)
                                    }}
                                >
                                    {sale}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div css={titleBoxStyle}>
                            <p className="titleText"><span>자산구분</span>을 선택해주세요</p>
                        </div>{" "}
                        <div css={lastButtonBoxStyle}>
                            <button
                                css={selectButtonStyle(userAiSettingFilterState.assetDetails.length > 0 ? false : true)}
                                onClick={() => {
                                    onChangeBidTye("assetDetails", [])
                                }}
                            >
                                전체
                            </button>
                            {assetsList.map((sale, idx) => (
                                <button
                                    key={sale}
                                    css={selectButtonStyle(userAiSettingFilterState?.assetDetails?.includes(idx + 1))}
                                    onClick={() => {
                                        onClickAssetDetail(idx + 1)
                                    }}
                                >
                                    {sale}
                                </button>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AiRecommendAuctionStep;

const lastButtonBoxStyle = css`
    display: grid;
    grid: ". . .";
    gap: 8px;
`;

const rootBoxStyle = css`
    background-color: #ffffff;
    font-size: 14px;
    padding: 0px 14px;
    height: 45dvh;
    overflow-y: auto;
`;


const auctionListBox = css`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
`

const auctionBtn = (checked: boolean) => css`
    width: 100%;
    background-color: ${checked ? "#2275EE" : "#f0f0f0"};
    color: ${checked ? "white" : "#0c002c"};
    height: 46px;
    border-radius: 8px;
    font-size: 14px;
`

const titleBoxStyle = css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;

        & > span {
            color: #0047FF;
        }
    }
    .allButton {
        background-color:#ffffff;
        color:#0c002c;
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (checked: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: ${checked ? "#2275ee" : "#fdfafa"};
    color: ${checked ? "#fff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    height: 46px;
    width: 100%;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    align-items: center;
    gaP: 8px;
    width: 100%;
    justify-content: space-between;
`;

const desc = css`
    color: #8d8d8d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 14px;
    margin-bottom: 14px;
`;
