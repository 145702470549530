import styled from "@emotion/styled";
import React, { useState } from "react";
import { checkDevice } from "./common/utils";
import DeungiDropDownCard from "./DeungiDropDownCard";

type DeungiPaymentInfoProps = {
    isUserExpert?: boolean;
};

const DeungiPaymentInfoBox = ({ isUserExpert }: DeungiPaymentInfoProps) => {
    const deviceType = checkDevice();
    const [dropDownInfo, setDropDownInfo] = useState({
        payInfoDeungiToggle: false,
        payInfoItemsAdsToggle: false,
        payInfoRegionAdsToggle: false,
        refundInfoToggle: false,
    });

    return (
        <>
            <DeungiInfoDropDownBox>
                <DeungiDropDownCard
                    title="등기열람권 충전 이용안내"
                    descriptions={[
                        {
                            id: 1,
                            desc: "열람권 결제를 통해 충전한 열람권의 유효기간은 결제시점을 기준으로 1년입니다.",
                        },
                        {
                            id: 2,
                            desc: "정기 서비스 점검으로 인해 일시적으로 등기열람이 제한될 수 있습니다.",
                        },
                        {
                            id: 3,
                            desc: "열람권 충전 취소는 결제 후 7일 내 열람권 미사용 시 가능하며, 결제 후 7일 경과 또는 서비스 이용 시에는 환불이 불가능합니다.",
                        },
                        {
                            id: 4,
                            desc: "충전 취소 및 환불 요청은 ‘내정보>고객센터>자주묻는질문’에서 가능합니다.",
                        },
                        {
                            id: 5,
                            desc: "구독 결제 혹은 무료로 제공된 열람권은 취소 및 환불 대상이 아닙니다.",
                        },
                        {
                            id: 6,
                            desc: "열람권 사용은 유효기간이 임박한 순으로 먼저 사용됩니다.",
                        },
                        {
                            id: 7,
                            desc: "비구독 또는 무료회원의 경우, ‘실시간 등기열람권 1장’ 상품만 구매 가능하며 최대 3장까지 구매 제한됩니다.",
                        },
                    ]}
                    active={dropDownInfo.payInfoDeungiToggle}
                    onClick={() =>
                        setDropDownInfo((prev) => {
                            return { ...prev, payInfoDeungiToggle: !dropDownInfo.payInfoDeungiToggle };
                        })
                    }
                />
                {isUserExpert && (
                    <>
                        <DeungiDropDownCard
                            title="매물광고권 충전 이용안내"
                            descriptions={[
                                {
                                    id: 1,
                                    desc: "매물광고권 사용 시 즉시 광고가 시작되며 한 달간 선택한 매물에서 광고가 노출됩니다.",
                                },
                                { id: 2, desc: "광고시작 3분 이내에 광고 종료 시 광고권이 반환됩니다." },
                                {
                                    id: 3,
                                    desc: "매물의 진행상태 변경으로 사건이 종결되는 경우(매각-배당종결, 취소, 취하, 기각 등)에는 광고매물권 환불이 불가능합니다.",
                                },
                                {
                                    id: 4,
                                    desc: "매물광고권 결제를 통해 충전한 광고권의 유효기간은 결제시점을 기준으로 1년입니다.",
                                },
                                {
                                    id: 5,
                                    desc: "매물광고권 충전 취소는 결제 후 7일 내 미사용 시 가능하며, 결제 후 7일 경과 또는 서비스 이용 시에는 환불이 불가능합니다.",
                                },
                                { id: 6, desc: "충전 취소 및 환불 요청은 ‘내정보>고객센터>자주묻는질문’에서 가능합니다." },
                                { id: 7, desc: "구독 결제 혹은 무료로 제공된 매물광고권은 취소 및 환불 대상이 아닙니다." },
                                { id: 8, desc: "매물광고권 사용은 유효기간이 임박한 순으로 먼저 사용됩니다." },
                            ]}
                            active={dropDownInfo.payInfoItemsAdsToggle}
                            onClick={() =>
                                setDropDownInfo((prev) => ({
                                    ...prev,
                                    payInfoItemsAdsToggle: !dropDownInfo.payInfoItemsAdsToggle,
                                }))
                            }
                        />
                        <DeungiDropDownCard
                            title="지역광고권 충전 이용안내"
                            descriptions={[
                                {
                                    id: 1,
                                    desc: "지역광고권 사용 시 즉시 광고가 시작되며 1개월간 선택한 지역의 모든 매물에서 광고가 노출됩니다.",
                                },
                                { id: 2, desc: "광고시작 3분 이내에 광고 종료 시 광고권이 반환됩니다." },
                                {
                                    id: 3,
                                    desc: "매물의 진행상태 변경으로 사건이 종결되는 경우(매각-배당종결, 취소, 취하, 기각 등)에는 광고매물권 환불이 불가능합니다.",
                                },
                                {
                                    id: 4,
                                    desc: "지역광고권 결제를 통해 충전한 광고권의 유효기간은 결제시점을 기준으로 1년입니다.",
                                },
                                {
                                    id: 5,
                                    desc: "지역광고권 충전 취소는 결제 후 7일 내 미사용 시 가능하며, 결제 후 7일 경과 또는 서비스 이용 시에는 환불이 불가능합니다.",
                                },
                                { id: 6, desc: "충전 취소 및 환불 요청은 ‘내정보>고객센터>자주묻는질문’에서 가능합니다." },
                                { id: 7, desc: "구독 결제 혹은 무료로 제공된 지역광고권은 취소 및 환불 대상이 아닙니다." },
                                { id: 8, desc: "지역광고권 사용은 유효기간이 임박한 순으로 먼저 사용됩니다." },
                            ]}
                            active={dropDownInfo.payInfoRegionAdsToggle}
                            onClick={() =>
                                setDropDownInfo((prev) => ({
                                    ...prev,
                                    payInfoRegionAdsToggle: !dropDownInfo.payInfoRegionAdsToggle,
                                }))
                            }
                        />
                    </>
                )}
                {deviceType !== "android" ? (
                    <>
                        <DeungiDropDownCard
                            title="충전 환불/취소 안내"
                            descriptions={[
                                {
                                    id: 1,
                                    desc: "상품은 사용하지 않은 경우에 한 해, 구매 후 7일 이내에 자주묻는질문에서 직접 구매 취소할 수 있습니다.",
                                },
                                {
                                    id: 2,
                                    desc: "구매 후 7일 이내임에도 ‘내정보>고객센터>자주묻는질문>열람권 취소/환불하기’에 '취소 불가' 상태라면 다음 중 하나 이상의 경우에 해당합니다.",
                                },
                                { id: 3, desc: "1) 상품 패키지의 일부를 사용하여서 직접 환불이 불가능한 경우" },
                                { id: 4, desc: "2) Google Play 결제를 통해 구매한 경우" },
                                { id: 5, desc: "3) iOS APP Store 결제를 통해 구매한 경우" },
                                {
                                    id: 6,
                                    desc: "위 경우에 해당하는 결제 건의 구매 취소를 희망할 경우, 유효 기간 내에 고객센터로 문의해 주세요.",
                                },
                                {
                                    id: 7,
                                    desc: "단, iOS APP Store 결제로 구매한 열람권의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.",
                                },
                                {
                                    id: 8,
                                    desc: "Google Play 결제로 구매한 열람권 는 구매 후 48시간 이내에는 Google Play 고객센터를 통해, 48시간 이후에는 고객센터를 통해 구매 취소할 수 있습니다.",
                                },
                                { id: 9, desc: "구매 취소나 환불 시에는 결제한 수단으로 환불됩니다." },
                                { id: 10, desc: "구독 혜택 등 무료로 지급된 상품은 환불 대상에서 제외됩니다." },
                            ]}
                            active={dropDownInfo.refundInfoToggle}
                            onClick={() =>
                                setDropDownInfo((prev) => ({
                                    ...prev,
                                    refundInfoToggle: !dropDownInfo.refundInfoToggle,
                                }))
                            }
                        />
                        <DeungiInfoBottomBox>
                            <h3>이용 및 환불/취소 안내를 확인 하였으며, 결제에 동의합니다.</h3>
                        </DeungiInfoBottomBox>
                    </>
                ) : (
                    <></>
                )}
            </DeungiInfoDropDownBox>
        </>
    );
};

const DeungiInfoBottomBox = styled.div`
    position: relative;
    height: 300px;

    & > h3 {
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        color: #3c3c3c;
        background: #f0f0f0;
        padding: 4px;
    }
`;

const DeungiInfoDropDownBox = styled.section`
    margin: 30px auto 10px;

    .dropDownItem {
        background: #fbf8f8;
        margin: 14px;
        padding: 14px;
        border-radius: 14px;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > h3 {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }
            & > img {
                width: 12px;
                height: 7px;
                transition: all 0.2s ease;

                &.up {
                    transform: rotate(180deg);
                }
            }
        }
        .contents {
            position: relative;
            overflow: hidden;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            max-height: 0;
            color: #a3a3a3;
            transition: all 0.2s;
            margin-top: 14px;
            padding-inline-start: 2ch;

            &.show {
                animation: dropDown 0.3s forwards;
                overflow: inherit;
            }
            &.hidden {
                animation: dropUp 0.3s forwards;
            }

            & > li {
                list-style-type: disc;
                list-style-position: outside;
            }
            & > li::marker {
            }
        }
        @keyframes dropDown {
            from {
                max-height: 0;
            }
            to {
                max-height: 100vh;
            }
        }
        @keyframes dropUp {
            from {
            }
            to {
                max-height: 0;
            }
        }
    }
`;

const DeungiInfoBox = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #a3a3a3;
    margin-top: 35px;
    padding: 14px;
    background: #fbf8f8;
`;

export default DeungiPaymentInfoBox;
