import { IProduct, initialProductData } from "@src/hooks/stateLand/useStateLand";

interface IFilterProductList {
    selectedProductList: IProduct[];
    selectedProduct: IProduct;
    setSelectedProductList: (val: any) => void;
    setSelectedProduct: (val: IProduct) => void;
    onChangeModalContents: any;
    setModalContents: any;
    setOpenModal: any;
}

export const filterProductList = ({
    selectedProduct,
    selectedProductList,
    setSelectedProduct,
    setSelectedProductList,
    onChangeModalContents,
    setModalContents,
    setOpenModal,
}: IFilterProductList) => {
    const arr: any[] = [];
    selectedProductList.map((e) => {
        e?.includeProductList?.map((ele) => {
            arr.push(ele.code);
        });
    });
    if (selectedProduct?.subsCategory !== 10) {
        if (selectedProductList.some((data: IProduct) => data?.code === selectedProduct?.code)) {
            setSelectedProductList(selectedProductList.filter((product: IProduct) => product?.code !== selectedProduct.code));
            setSelectedProduct(initialProductData);
        } else {
            if (arr.includes(selectedProduct?.code)) {
                setModalContents(onChangeModalContents(6));
                setOpenModal(4);
                setSelectedProduct(initialProductData);
            } else if (
                selectedProductList.some((data: IProduct) => data?.type === "subscription") &&
                selectedProduct?.type === "subscription"
            ) {
                setSelectedProductList(selectedProductList.filter((product: IProduct) => product?.type !== "subscription"));
                setSelectedProductList((prev: IProduct[]) => [...prev, selectedProduct]);
                setSelectedProduct(initialProductData);
            } else if (
                selectedProduct?.type == "state_land_filter" &&
                selectedProduct?.pnusToString == "전국" &&
                selectedProductList.some((data: IProduct) => data?.type === "state_land_filter")
            ) {
                setSelectedProductList(selectedProductList.filter((product: IProduct) => product?.type !== "state_land_filter"));
                setSelectedProductList((prev: IProduct[]) => [...prev, selectedProduct]);
                setSelectedProduct(initialProductData);
            } else if (
                selectedProduct?.type === "state_land_filter" &&
                selectedProduct?.pnusToString !== "전국" &&
                selectedProductList.some((data: IProduct) => data?.type === "state_land_filter" && data?.pnusToString === "전국")
            ) {
                setSelectedProductList(selectedProductList.filter((product: IProduct) => product?.type !== "state_land_filter"));
                setSelectedProductList((prev: IProduct[]) => [...prev, selectedProduct]);
                setSelectedProduct(initialProductData);
            } else {
                setSelectedProductList((prev: IProduct[]) => [...prev, selectedProduct]);
                setSelectedProduct(initialProductData);
            }
        }
    } else return;
};
