import React, { forwardRef, useEffect, useState } from "react";
import Lottie from "lottie-react";
import { css } from "@emotion/react";

import { transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import useCallToken from "@src/hooks/useCallToken";

import noDataIcon from "@src/assets/charginUseBox.png";
import backArrow from "@src/assets/ico_arrow_back.png";
import lottie_loading from "@src/assets/Loading.json";

interface Props {
    children: React.ReactNode;
    guideTextOffsetHeight: any;
    data: any;
    isFetching: boolean;
    onClickCancelButton: (type: number, payment: any) => void;
    ticketType: string;
}

enum IRefundState {
    "취소불가" = 0,
    "취소하기" = 1,
    "취소완료" = 2,
}

const RefundDialog = forwardRef(function RefundDialog(props: Props, ref?: React.ForwardedRef<HTMLDivElement>) {
    const { children, guideTextOffsetHeight, data, isFetching, onClickCancelButton, ticketType } = props;

    const [styleHeight, setStyleHeight] = useState<number>(318);
    const { token } = useCallToken();

    useEffect(() => {
        if (token) {
            setStyleHeight(guideTextOffsetHeight + 106);
        }
    }, [token]);

    return (
        <>
            <div css={titleBoxStyle}>
                <div
                    className="backBtn"
                    onClick={() => {
                        transferToNative("done", "closeWebView");
                    }}
                >
                    <img src={backArrow} alt="" />
                </div>
                <p className="name">{ticketType} 취소/환불하기</p>
            </div>
            <div css={contentRootStyle}>
                <div css={ItemListWrap(styleHeight)}>
                    {data?.pages?.[0]?.list?.length
                        ? data?.pages?.map((page: any) =>
                              page?.list?.map((payment: any, index: number) => {
                                  //* 취소상태 관련 숫자로 변환
                                  const currentStatus: any = payment.refundStateToString;
                                  const numberStatus = IRefundState[currentStatus];
                                  return (
                                      <div key={`${payment.orderNo}-${index.toString()}`} css={contentBoxStyle}>
                                          <div css={contentTopStyle}>
                                              <p className="date">{payment?.startedAt.slice(0, 16) ?? ""}</p>
                                              <button
                                                  css={cancelButtonStyle(Number(numberStatus))}
                                                  onClick={() => onClickCancelButton(Number(numberStatus), payment)}
                                              >
                                                  {payment.refundStateToString}
                                              </button>
                                          </div>
                                          <div css={contentBottomStyle}>
                                              <p className="name">{payment?.productName} 결제</p>
                                              <p className="paid">
                                                  <span>{payment?.paidCardName}</span>
                                                  {payment?.paidPrice?.toLocaleString()}원
                                              </p>
                                          </div>
                                      </div>
                                  );
                              }),
                          )
                        : !isFetching && (
                              <div css={noDataBoxStyle(styleHeight)}>
                                  <img src={noDataIcon} alt="noDataIcon" />
                                  <p>결제한 {ticketType}이 없어요</p>
                              </div>
                          )}
                </div>
                {data?.pages?.[0]?.list?.length < 0 && <div style={{ padding: "20px" }} ref={ref} />}
                {isFetching && (
                    <div css={lottieBoxStyle}>
                        <Lottie className="lottie" animationData={lottie_loading} />
                    </div>
                )}
                {children}
            </div>
        </>
    );
});

export default RefundDialog;

const titleBoxStyle = css`
    position: relative;
    padding: 14px;

    z-index: 22;

    display: flex;
    align-items: center;
    justify-content: center;

    .name {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
    }

    & > .backBtn {
        position: absolute;
        left: 14px;
        width: 10px;
        height: 17px;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
`;

const contentRootStyle = css`
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const ItemListWrap = (styleHeight: number) => css`
    min-height: ${`calc(calc(var(--vh, 1vh) * 100) - ${styleHeight}px)`};
    min-height: ${styleHeight && `calc(100vh - ${styleHeight}px)`};
`;

const contentBoxStyle = css`
    padding: 14px;

    border-bottom: 0.6px solid #c9c9c9;
`;

const contentTopStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
        color: #8d8d8d;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const contentBottomStyle = css`
    margin-top: 10px;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .name {
        word-break: break-word;
        color: #0c002c;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    .paid {
        color: #8d8d8d;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        span {
            color: #0c002c;
            padding-right: 5px;
        }
    }
`;

const noDataBoxStyle = (styleHeight: number) => css`
    width: 100%;
    height: ${styleHeight && `calc(calc(var(--vh, 1vh) * 100) - ${styleHeight}px)`};
    height: ${styleHeight && `calc(100vh - ${styleHeight}px)`};

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
        padding-top: 24px;

        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 17px;
        font-weight: 400;
        line-height: 150%;
    }

    img {
        width: 125px;
        height: 84px;
    }
`;

const cancelButtonStyle = (props: number) => css`
    cursor: ${props === 2 ? "" : "pointer"};

    padding: 4px 14px;

    border-radius: 8px;
    background-color: ${props === 0 ? `${Common.colors.grey03}` : props === 1 ? `#FFDAD8` : `${Common.colors.grey02}`};

    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: ${props === 2 ? "#8d8d8d" : "#0C002C"};
`;

const lottieBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;

    .lottie {
        width: 100px;
    }
`;
