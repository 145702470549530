import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DeungiHeader from "@src/components/deungi/DeungiHeader";
import DeungiAlarmHeader from "@src/components/deungi/DeungiAlarmHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDeungiChangeAlarmAgreement, useDeungiChangeAlarmSubmitAgreement } from "@src/hooks/deungi/useDeungiAlarm";
import ToastMessage from "@src/components/deungi/ToastMessage";

const DeungiAlarmSetting = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("");
    const [isFirst, setIsFirst] = useState(searchParams.get("isFirst") === "true");
    const [isLoaded, setIsLoaded] = useState(false);
    const [setting, setSetting] = useState({
        deungiPushAlarm: false,
        deungiEmailAlarm: false,
    });

    const getAlarmSetting = async () => {
        try {
            const res = await useDeungiChangeAlarmAgreement();
            setSetting({
                deungiPushAlarm: res?.data.some((el: any) => el.type === "agree_push_deungi_change_alarm" && el.agreement),
                deungiEmailAlarm: res?.data.some((el: any) => el.type === "agree_email_deungi_change_alarm" && el.agreement),
            });
        } catch (e) {
            console.log("e", e);
        }
    };

    const onClickHandler = async (type: "push" | "email") => {
        switch (type) {
            case "push":
                setSetting((prev) => {
                    if (!isFirst) {
                        if (!prev.deungiPushAlarm) setMessage("APP 푸시알림이 켜졌어요");
                        else setMessage("APP 푸시알림이 꺼졌어요");
                    }
                    return {
                        ...prev,
                        deungiPushAlarm: !prev.deungiPushAlarm,
                    };
                });
                break;
            case "email":
                setSetting((prev) => {
                    if (!isFirst) {
                        if (!prev.deungiEmailAlarm) setMessage("이메일 알림이 켜졌어요");
                        else setMessage("이메일 알림이 꺼졌어요");
                    }
                    return {
                        ...prev,
                        deungiEmailAlarm: !prev.deungiEmailAlarm,
                    };
                });
                break;
        }
        setIsLoaded(true);
    };

    const onSubmitHandler = async () => {
        const canSubmit = Object.values(setting).some((el) => el);

        if (canSubmit) {
            await useDeungiChangeAlarmSubmitAgreement({
                agree_push_deungi_change_alarm: setting.deungiPushAlarm,
                agree_email_deungi_change_alarm: setting.deungiEmailAlarm,
            });
            if (isFirst) {
                navigate("/deungi/alarm/search");
            } else {
                navigate("/deungi/alarm?option=true", { state: { modifyComplete: true } });
            }
        }
    };

    useEffect(() => {
        if (!isFirst && isLoaded) {
            (async () => {
                await useDeungiChangeAlarmSubmitAgreement({
                    agree_push_deungi_change_alarm: setting.deungiPushAlarm,
                    agree_email_deungi_change_alarm: setting.deungiEmailAlarm,
                });
            })();
        }
    }, [setting, isLoaded]);

    useEffect(() => {
        getAlarmSetting();
    }, []);

    return (
        <Container>
            <DeungiAlarmHeader title="등기 변동 알림 신청" />
            <SettingBox>
                <h3>변동알림 방식 선택</h3>
                <p>변동알림을 전송받을 방식을 선택해주세요</p>
                <div className={"btnBox"}>
                    <button className={setting.deungiPushAlarm ? "active" : ""} onClick={() => onClickHandler("push")}>
                        APP 푸시로 받기
                    </button>
                    <button className={setting.deungiEmailAlarm ? "active" : ""} onClick={() => onClickHandler("email")}>
                        이메일로 받기
                    </button>
                </div>
                <ul>
                    <li>알림 전송 방식은 신청한 모든 등기변동알림에 적용되며 수정은 알림설정에서 가능합니다</li>
                    <li>시스템 알림 설정이 꺼져있으면 알림 전송이 불가합니다</li>
                </ul>
            </SettingBox>
            {isFirst && (
                <button
                    className={Object.values(setting).some((el) => el) ? "submitBtn active" : "submitBtn"}
                    onClick={onSubmitHandler}
                >
                    다음
                </button>
            )}
            <ToastMessage message={message} setMessage={setMessage} />
        </Container>
    );
};

const SettingBox = styled.div`
    position: relative;
    padding: 14px;
    line-height: 1.6;
    animation: fadeIn 0.3s forwards;

    & > h3 {
        font-size: 16px;
        font-weight: 700;
    }
    & > .btnBox {
        margin: 14px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;

        & > button {
            width: 100%;
            padding: 8px;
            font-size: 14px;
            line-height: 1.6;
            text-align: left;
            border: 1px solid #c9c9c9;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
            cursor: pointer;

            &.active {
                border: 1px solid #2275ee;
                background-color: #2275ee;
                color: #ffffff;
            }
        }
    }
    & > ul {
        padding: 0 14px;
        font-size: 13px;
        color: #8d8d8d;

        & > li {
            list-style: disc;
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100dvh;

    .settingBtn {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        cursor: pointer;

        & > img {
            width: 24px;
            height: 24px;
        }
    }
    .submitBtn {
        position: fixed;
        bottom: 28px;
        left: 14px;
        right: 14px;
        border-radius: 14px;
        line-height: 1.6;
        background: #c3c3c3;
        color: #fff;
        padding: 14px;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.3s ease;
        cursor: pointer;

        &.active {
            background: #2275ee;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export default DeungiAlarmSetting;
