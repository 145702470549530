import { transferToNative } from "@src/lib/WebAppBridge";
import { useMapBottomSheetStore } from "@src/store/MapBottomSheetStore";
import React, { useEffect, useRef, useState } from "react";
import { BOTTOM_SHEET_HEIGHT, MAX_Y } from "../../components/Card/constants";

interface BottomSheetMetrics {
    touchStart: {
        sheetY: number; // touchstart에서 BottomSheet의 최상단 모서리의 Y값
        touchY: number; // touchstart에서 터치 포인트의 Y값
    };
    touchMove: {
        prevTouchY?: number; // 다음 touchmove 이벤트 핸들러에서 필요한 터치 포인트 Y값을 저장
        movingDirection: "none" | "down" | "up"; // 유저가 터치를 움직이고 있는 방향
    };
    isContentAreaTouched: boolean; // 컨텐츠 영역을 터치하고 있음을 기록
}

const useTestBottomSheet = (isSubscriptionUser: boolean | null) => {
    const sheet = useRef() as any;
    const content = useRef() as any;
    const titleRef = useRef() as any;
    const data = useRef() as any;
    const wrapperRef = useRef() as any;
    // const articleRef = useRef() as any;
    const [test, setTest] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);
    // const [isArticle,setIsArticel] = useState(false);

    const onChangeChecked = () => setChecked(true);

    const wrapperHeight = useMapBottomSheetStore((state) => state.wrapperHeight);

    const metrics = useRef<BottomSheetMetrics>({
        touchStart: {
            sheetY: 0,
            touchY: 0,
        },
        touchMove: {
            prevTouchY: 0,
            movingDirection: "none",
        },
        isContentAreaTouched: false,
    });

    useEffect(() => {
        const canUserMoveBottomSheet = () => {
            const { touchMove, isContentAreaTouched } = metrics.current;

            if (!isContentAreaTouched) {
                return true;
            }

            if (Math.floor(sheet.current.getBoundingClientRect().y) !== 0) {
                return true;
            }

            if (touchMove.movingDirection === "down") {
                return data.current.scrollTop <= 0;
            }

            return false;
        };

        const handleTouchStart = (e: TouchEvent) => {
            // if (isSubscriptionUser === false) {
            //     transferToNative("", "showSubscriptionPopup");
            // } else {
            const { touchStart, touchMove } = metrics.current;

            touchStart.sheetY = Math.floor(sheet.current.getBoundingClientRect().y);
            touchStart.touchY = e.touches[0].clientY;
            touchMove.prevTouchY = e.touches[0].clientY;
            titleRef.current?.style.setProperty("display", "block");
            // }
        };

        const handleTouchMove = (e: TouchEvent) => {
            if (isSubscriptionUser) {
                const { touchStart, touchMove } = metrics.current;
                const currentTouch = e.touches[0];

                if (touchMove.prevTouchY === undefined) {
                    touchMove.prevTouchY = touchStart.touchY;
                }

                if (touchMove.prevTouchY < currentTouch.clientY) {
                    touchMove.movingDirection = "down";
                }

                if (touchMove.prevTouchY > currentTouch.clientY) {
                    setTest(true);
                    touchMove.movingDirection = "up";
                }

                if (canUserMoveBottomSheet()) {
                    e.stopPropagation();

                    const touchOffset = currentTouch.clientY - touchStart.touchY;
                    let nextSheetY = touchStart.sheetY + touchOffset;

                    if (nextSheetY <= wrapperHeight) {
                        nextSheetY = wrapperHeight;
                    }

                    if (nextSheetY >= MAX_Y) {
                        nextSheetY = MAX_Y;
                    }

                    // titleRef.current.style.setProperty(
                    //     "opacity",
                    //     `${sheet.current.getBoundingClientRect().top / BOTTOM_SHEET_HEIGHT}`,
                    // );

                    // titleRef.current?.style.setProperty("opacity", "0.99"); // ios 오파시티 버그
                    sheet.current?.style.setProperty("transform", `translateY(${nextSheetY - window.innerHeight}px)`);
                    content.current?.style.setProperty("border-radius", "0");
                    // content.current.style.setProperty(
                    //     "opacity",
                    //     `${1 - sheet.current.getBoundingClientRect().top / BOTTOM_SHEET_HEIGHT}`,
                    // );
                } else {
                    // document.body.style.overflowY = "hidden";
                }
            }
        };

        const handlearticleTouchEnd = (e: TouchEvent) => {
            const { touchMove } = metrics.current;

            if (touchMove.movingDirection === "down") {
                setChecked(false);
                sheet.current.style.setProperty("transform", `translateY(0px)`);
                // titleRef.current.style.setProperty("opacity", "1");
                // content.current.style.setProperty("opacity", "0");
            }
        };

        const handleTouchEnd = (e: TouchEvent) => {
            if (isSubscriptionUser) {
                const { touchMove } = metrics.current;
                const currentSheetY = Math.floor(sheet.current.getBoundingClientRect().y);
                // document.body.style.overflowY = "auto";

                if (currentSheetY !== 0) {
                    if (touchMove.movingDirection === "down") {
                        setChecked(false);
                        sheet.current?.style.setProperty("transform", "translateY(0)");
                        titleRef.current?.style.setProperty("opacity", "1");
                        titleRef.current?.style.setProperty("display", "block");
                        content.current?.style.setProperty("border-top-left-radius", "8px");
                        content.current?.style.setProperty("border-top-right-radius", "8px");
                        // content.current?.style.setProperty("opacity", "0");
                    }

                    if (touchMove.movingDirection === "up") {
                        sheet.current?.style.setProperty("transform", `translateY(${wrapperHeight - window.innerHeight}px)`);
                        // titleRef.current?.style.setProperty("display", "none");
                        // titleRef.current?.style.setProperty("opacity", "0");

                        content.current?.style.setProperty("border-radius", "0");
                        content.current?.style.setProperty("opacity", "1");
                        setChecked(true);
                    }
                } else {
                    // titleRef.current.style.setProperty("display", "none");
                }

                // metrics 초기화.
                metrics.current = {
                    touchStart: {
                        sheetY: 0,
                        touchY: 0,
                    },
                    touchMove: {
                        prevTouchY: 0,
                        movingDirection: "none",
                    },
                    isContentAreaTouched: false,
                };
            }
        };
        sheet.current?.addEventListener("touchstart", handleTouchStart);
        sheet.current?.addEventListener("touchmove", handleTouchMove);
        sheet.current?.addEventListener("touchend", handleTouchEnd);

        // articleRef.current.addEventListener("touchend", handlearticleTouchEnd);

        return () => {
            sheet?.current?.removeEventListener("touchstart", handleTouchStart);
            sheet?.current?.removeEventListener("touchmove", handleTouchMove);
            sheet?.current?.removeEventListener("touchend", handleTouchEnd);
            // articleRef.current.removeEventListener("touchend", handlearticleTouchEnd);
        };
    }, [wrapperHeight, isSubscriptionUser]);

    useEffect(() => {
        const handleTouchStart = () => {
            metrics.current.isContentAreaTouched = true;
        };

        content?.current?.addEventListener("touchstart", handleTouchStart);

        return () => content?.current?.removeEventListener("touchstart", handleTouchStart);
    }, []);
    const handleSwipDown = () => {
        sheet.current?.style.setProperty("transform", "translateY(0)");
        // titleRef.current?.style.setProperty("opacity", "1");
        titleRef.current?.style.setProperty("display", "block");
        content.current?.style.setProperty("border-top-left-radius", "8px");
        content.current?.style.setProperty("border-top-right-radius", "8px");
        // content.current.style.setProperty("opacity", "0");
    };
    // useEffect(() => {
    //     if (test === true) {
    //         setChecked(true);
    //     }
    // }, [test]);

    return { sheet, titleRef, content, data, wrapperRef, handleSwipDown, checked, onChangeChecked /*articleRef*/ };
};

export default useTestBottomSheet;
