import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";

export function numberToKorean(number: string) {
    const data = number.split(" ");

    const minPrice = data[data.length === 4 ? 1 : 0];
    const maxPrice = data[data.length === 4 ? 3 : 2];

    const test = itemForAppraisedPrice.map((item) => {
        if (data.length === 4) {
            if (item.value === minPrice || item.value === maxPrice) {
                return item.title;
            }
        }

        if (data.length === 3) {
            if (item.value === maxPrice) {
                return item.title;
            }
        }
    });

    const test2 = test.filter((item) => item);

    if (data.length === 4) {
        return `${test2[0]} ~ ${test2[1]}`;
    }

    if (data.length === 3) {
        return `~ ${test2[0]}`;
    }

    return "";
}
