import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import { transferStringAuctionType } from "@src/util/typeToName";
import React from "react";
import EmptyResult from "./EmptyResult";

type SearchCaseNumberListPropsType = {
    onClickRow: (address: string) => void;
    data: any[];
};

const SearchCaseNumberList = ({ onClickRow, data }: SearchCaseNumberListPropsType) => {
    return (
        <>
            {data.length ? (
                <ul css={ListStyleWrapperStyle}>
                    {data?.map((data: any, index: number) => {
                        return (
                            <li key={index} onClick={() => onClickRow(data)}>
                                <AuctionTypeTag type={data.bid_type}>
                                    <span>{transferStringAuctionType(data.bid_type)}</span>
                                </AuctionTypeTag>
                                {data.bid_type === 1 ? data.sub_id : data.item_id}{" "}
                                {data.item_number ? <>({data.item_number})</> : <></>}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <EmptyResult>
                    <p>
                        <b>일치하는 사건번호가 없습니다.</b>
                    </p>
                    <span>정확한 사건번호 혹은 물건관리번호를 입력해 주세요</span>
                </EmptyResult>
            )}
        </>
    );
};

export default SearchCaseNumberList;

const ListStyleWrapperStyle = css`
    max-height: calc(100vh - 138px);
    overflow-x: hidden;
    /* padding: 0px 14px; */
    /* overflow-y: scroll; */

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    li {
        display: flex;
        align-items: center;
        height: 82px;
        padding: 0px 14px;
        width: 100%;
    }

    li:hover {
        background: ${Common.colors.bgBlue};
        width: 100vw;

        /* & > div {
            background-color: #ffffff;
            & > span {
                color: ${Common.colors.aucMarkerColor};
            }
        } */
    }
`;

const AuctionTypeTag = styled.div<{ type: number }>`
    height: 22px;
    line-height: 20px;
    width: 42px;
    border-radius: 4px;
    padding: 0 8px;
    background: ${Common.colors.pubMarkerColor};
    margin-right: 8px;

    span {
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        color: ${Common.colors.white};
    }

    ${({ type }) =>
        type === 1 &&
        css`
            background: ${Common.colors.aucMarkerColor};
            span {
                color: #ffffff;
            }
        `}
`;
