import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { initialUserSubsInfo } from "../user/userApi";

export interface ISubscribeRefundAmount {
    productPrice: number;
    discountedPrice: number;
    benefitPrice: number;
    paidPrice: number;
    refundPrice: number;
    expiredAt: string;
    firstPaidAt: string;
    penaltyPrice: number;
    totalDays: number;
    usedDays: number;
    usedDaysPrice: number;
    penaltyType: number; // (구독 상품인 경우) [위약금 타입] 0: 위약금 없음, 1: 결제액 30%, 2: 정가 10%, 3: 정가 30%
}

export interface ISubsribeInfo {
    subsNo: number;
    orderProductNo: number;
    name: string;
    productName: string;
    subsName: string;
    nextPrice: number;
    expiredAt: string;
    expiredAtToString?: string;
    nextRenewAt?: string;
    nextRenewAtToString?: string;
    nextRenewAtBackup: string;
    isSubsCancelReserve: boolean;
    isInAppSubs: boolean;
    isFreeIssueSubs: boolean;
}

export interface IUserStateLandSubs {
    no: number;
    isSubs: boolean;
    isActive: boolean;
    expiredAt: string;
    firstUsedAt: string;
    productName: string;
    pnus: number[] | null;
    pnusToString: string;
}

export interface IUserStateLandSubsInfo {
    list?: IUserStateLandSubs[];
    totalCnt: number;
    allPnus: number[] | null;
    allPnusToString: string;
    isActive: boolean;
}

//* 유저 구독정보 가져오기

export const useGetSubsInfo: () => Promise<ISubsribeInfo> = async () => {
    const result = await axiosInstance
        .get(axiosPath.userSubsInfo)
        .then((res) => {
            return res?.data ?? initialUserSubsInfo;
        })
        .catch((err) => {
            console.log("err>>>", err);
            if (err?.response?.data?.message?.includes("구독")) {
                return initialUserSubsInfo;
            }
            return err;
        });
    return result;
};

export const useFindSubsInfo = (enabled: boolean = false) => {
    return useQuery<ISubsribeInfo>(
        ["userSubs", localStorage.getItem("accessToken")],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.userSubsInfo)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return result;
        },
        {
            enabled,
            retry: 0,
        },
    );
};

//* 유저 구독 해지 예상 금액
export const useFindSubsRefundAmount = (enabled: boolean = false) => {
    return useQuery<ISubscribeRefundAmount>(
        ["subsRefundAmout", localStorage.getItem("accessToken")],
        async () => {
            const response = await axiosInstance
                .get(`${axiosPath.userSubsInfo}/refund/amount`)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return response;
        },
        { enabled },
    );
};

//* 유저 구독 해지 예약
export const useRemoveUserSubsReserve = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async () => {
            try {
                const res = await axiosInstance.delete(axiosPath.userSubsInfo);
                return res;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//* 유저 구독 즉시 취소
export const useRemoveUserSubs = (onSuccess: (datum: any) => void = () => {}, onError: (err: AxiosError) => void = () => {}) => {
    return useMutation(
        async () => {
            try {
                const res = await axiosInstance.delete(`${axiosPath.userSubsInfo}/now`);
                return res;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//* 국공유지 상품여부 가져오기
export const useGetStateLandSubs: () => Promise<IUserStateLandSubsInfo> = async () => {
    const result = await axiosInstance
        .get(axiosPath.userStateLandPath)
        .then((res) => {
            return res?.data;
        })
        .catch((err) => {
            console.log("err>>", err);
            return err;
        });
    return result;
};

export const useFindOneStateLandSubs = (enabled: boolean = false) => {
    return useQuery<IUserStateLandSubsInfo>(
        ["stateLandSubs"],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.userStateLandPath)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled },
    );
};
