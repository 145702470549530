import React from "react";
import { css } from "@emotion/react";
import StateLandFilterHeader from "./StateLandFilterHeader";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";

interface Props {}

export const classificationFilterList = [
    { title: "전체", value: "" },
    { title: "일반재산", value: "일반재산" },
    { title: "일반재산 외", value: "일반재산 외" },
];

const ClassificationFilter: React.FC<Props> = () => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);

    return (
        <>
            <StateLandFilterHeader title="분류" styles={noPaddingStyle} />
            <div css={filterBoxStyle}>
                {classificationFilterList.map((filter, idx) => (
                    <button
                        key={filter.title}
                        css={filterButtonStyle(
                            idx ? stateLandFilter.type?.some((val) => val === filter.title) : !stateLandFilter?.type?.length,
                        )}
                        onClick={() => {
                            idx
                                ? setStateLandFilter({
                                      ...stateLandFilter,
                                      type: [filter.title],
                                  })
                                : setStateLandFilter({
                                      ...stateLandFilter,
                                      type: [],
                                  });
                        }}
                    >
                        {filter.title}
                    </button>
                ))}
            </div>
        </>
    );
};

export default ClassificationFilter;

const filterButtonStyle = (active: boolean) => css`
    padding: 8px 4px;
    border-radius: 4px;
    background-color: ${active ? "#FF6933" : "#F0F0F0"};
    color: ${active ? "#ffffff" : "#0C002C"};
    font-weight: ${active ? 700 : 400};
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
`;

const filterBoxStyle = css`
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 14px 24px;
`;

const noPaddingStyle = css`
    padding-top: 0;
`;
