import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface PopUpProps {
    text: any;
    isShowCancelButton: boolean;
    confirmButtonTitle?: string;
    handleCancelClick?: () => void;
    handleConfirmClick: () => void;
    cancelButtonStyle?: any;
    confirmButtonStyle?: any;
    useDimClose?: boolean;
}

function PopUp({
    text,
    confirmButtonTitle = "확인",
    handleCancelClick,
    handleConfirmClick,
    isShowCancelButton = true,
    cancelButtonStyle,
    confirmButtonStyle,
    useDimClose = false,
}: PopUpProps) {
    return (
        <div css={PopUpWrap}>
            <Content
                text={text}
                handleCancelClick={handleCancelClick}
                handleConfirmClick={handleConfirmClick}
                confirmButtonTitle={confirmButtonTitle}
                isShowCancelButton={isShowCancelButton}
                cancelButtonStyle={cancelButtonStyle}
                confirmButtonStyle={confirmButtonStyle}
            />
            <div
                className="shadow"
                onClick={() => {
                    if (useDimClose && handleCancelClick) {
                        handleCancelClick();
                    } else if (useDimClose) {
                        handleConfirmClick();
                    }
                }}
            ></div>
        </div>
    );
}

function Content({
    text,
    confirmButtonTitle,
    handleCancelClick,
    handleConfirmClick,
    isShowCancelButton,
    cancelButtonStyle,
    confirmButtonStyle,
}: PopUpProps) {
    return (
        <div css={PopUpContents}>
            <div className="text">{text}</div>
            <div className="buttonBox">
                {isShowCancelButton && (
                    <button
                        onClick={handleCancelClick}
                        className="cancelButton"
                        css={[cancelButtonStyle, defalutCancelButtonStyle]}
                    >
                        취소
                    </button>
                )}
                <button
                    onClick={handleConfirmClick}
                    className="confirmButton"
                    css={[confirmButtonStyle, defalutConfirmButtonStyle]}
                >
                    {confirmButtonTitle}
                </button>
            </div>
        </div>
    );
}
const PopUpWrap = css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 998;
    overflow: hidden;
    &::-webkit-scrollbar {
        overflow: hidden !important;
        -webkit-appearance: none !important;
        display: none !important;
    }
    .shadow {
        width: 100vw;
        height: 100vh;
        background-color: ${Common.colors.darkGrey01};
        opacity: 0.7;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }
`;
const PopUpContents = css`
    min-width: 312px;
    padding: 14px 18px;
    border-radius: 14px;
    background-color: ${Common.colors.white};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 3;
    .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: ${Common.colors.navyPrimary};
        white-space: pre-line;
    }
    .buttonBox {
        margin-top: 24px;
        width: 100%;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: space-between;
        button {
            flex: 1;
            width: 50%;
            border-radius: 14px;
            padding: 14px 0;
            height: 56px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            /* &.cancelButton {
                background-color: ${Common.colors.grey02};
                color: ${Common.colors.navyPrimary};
            }
            &.confirmButton {
                background: linear-gradient(
                    339.46deg,
                    #5f0080 -26.96%,
                    #5f0080 7.62%,
                    #7b0ba2 47.28%,
                    #ae00eb 104.97%,
                    #e183ff 165.24%
                );
                color: ${Common.colors.white};
            } */
        }
    }
`;

const defalutCancelButtonStyle = css`
    background-color: ${Common.colors.grey02};
    color: ${Common.colors.navyPrimary};
`;

const defalutConfirmButtonStyle = css`
    background: linear-gradient(339.46deg, #5f0080 -26.96%, #5f0080 7.62%, #7b0ba2 47.28%, #ae00eb 104.97%, #e183ff 165.24%);
    color: ${Common.colors.white};
`;

export default PopUp;
