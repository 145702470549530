import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeungiAlarmList from "@src/components/deungi/DeungiAlarmList";
import DeungiAlarmOption from "@src/components/deungi/DeungiAlarmOption";
import DeungiAlarmHeader from "@src/components/deungi/DeungiAlarmHeader";

const DeungiAlarmMain = () => {
    return (
        <Container>
            <DeungiAlarmHeader title="등기 변동 알림" isClose={true} />
            <DeungiAlarmList />
            <DeungiAlarmOption />
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: calc(var(--vh, 1vh) * 100);

    .settingBtn {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 55px;
        height: 55px;
        cursor: pointer;

        & > img {
            width: 24px;
            height: 24px;
        }
    }
`;

export default DeungiAlarmMain;
