import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ico_check_noLine from "@src/assets/ico_check_noLine.png";
import closeIconButton from "@src/assets/deungi/deungi_close_btn.svg";
import ico_refresh from "@src/assets/ico_refresh.png";
import ico_refresh_white from "@src/assets/ico_refresh_white.png";
import ico_arrow_down from "@src/assets/ico_arrow_down_white.png";
import ico_deungi_empty from "@src/assets/ico_deungi_empty.png";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import DeungiCombo from "./DeungiCombo";
import dayjs from "dayjs";
import { DeungiListRequestDto, DeungiListResponseDto } from "@src/hooks/deungi/dto/DeungiList.dto";
import { useDeungiList } from "@src/hooks/deungi/useDeungiList";
import Lottie from "lottie-react";
import { DEUNGI_OPEN_STATE, DEUNGI_POPUP_TYPE } from "./common/enum";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useDeungiRetry } from "@src/hooks/deungi/useDeungiRetry";
import { useNavigate } from "react-router-dom";
import { getDeungiOpenState } from "./common/utils";
import { useCheckDeungi, useDeungiInfo } from "@src/hooks/deungi/useDeungiInfo";
import { CheckDeungiResponseDto, DeungiInfoResponseDto } from "@src/hooks/deungi/dto/Deungi.dto";
import DeungiPopup from "./DeungiPopup";

const DeungiListBox = () => {
    const navigate = useNavigate();
    const filterDateList = [
        "전체",
        dayjs().format("YYYY년 M월"),
        dayjs().subtract(1, "month").format("YYYY년 M월"),
        dayjs().subtract(2, "month").format("YYYY년 M월"),
        dayjs().subtract(3, "month").format("YYYY년 M월"),
    ];
    const [openData, setOpenData] = useState({} as any);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.OPEN);
    const [popupToggle, setPopupToggle] = useState(false);
    const [filterDate, setFilterDate] = useState(filterDateList[0]);
    const [filterDateToggle, setFilterDateToggle] = useState(false);
    const [filterPage, setFilterPage] = useState(1);
    const [firstLoadFlag, setFirstLoadFlag] = useState(false);
    const [deungiToggle, setDeungiToggle] = useState(true);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [fixedState, setFixedState] = useState(false);
    const [deungiTotalCnt, setDeungiTotalCnt] = useState(0);
    const [deungiList, setDeungiList] = useState([] as DeungiListResponseDto[]);
    const [watchDeungiList, setWatchDeungiList] = useState([] as DeungiListResponseDto[]);
    const containerRef = useRef(null);

    const scrollHandler = useCallback((e: any) => {
        const containerEl = containerRef.current as any;
        if (containerEl.scrollTop > 200) setFixedState(true);
        else setFixedState(false);
    }, []);

    const getDeungiList = async (page?: number) => {
        const params = {} as DeungiListRequestDto;
        const filterIdx = filterDateList.indexOf(filterDate);
        params.page = page ? page : filterPage;
        params.limit = 20;

        if (!deungiToggle) return;
        setDeungiToggle(false);

        if (page === 1) {
            setFilterPage(1);
            setDeungiList([]);
        }
        if (filterIdx > 0)
            params.inDate = dayjs()
                .subtract(filterIdx - 1, "month")
                .format("YYYYMM");

        await useDeungiList(params)
            .then((res) => {
                const data = res.data;

                if (data?.totalCnt === 0) {
                    setDeungiTotalCnt(0);
                    setDeungiList([]);
                } else {
                    setDeungiTotalCnt(data?.totalCnt);
                    setFilterPage((prev: number) => prev + 1);
                    setDeungiList((prev: any) => prev.concat(data?.list));
                }

                setDeungiToggle(true);
            })
            .finally(() => setFirstLoadFlag(true));
    };

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: deungiTotalCnt > (filterPage - 1) * 20,
        onLoadMore: getDeungiList,
        disabled: false,
        delayInMs: 100,
    });

    const onRefreshHandler = () => {
        if (!refreshToggle) {
            setRefreshToggle(true);
            getDeungiList(1);
            setTimeout(() => setRefreshToggle(false), 500);
        }
    };

    const onRetryHandler = async (id: string) => {
        const checkDeungiRes = await useCheckDeungi();
        const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

        if (!checkDeungiInfo.isCheck) {
            setOpenData(checkDeungiInfo);
            setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
            setPopupToggle(true);
            return;
        }

        const res = await useDeungiRetry(id);

        if (res.data.id) {
            navigate({
                pathname: "/deungi",
                search: `?type=search&watch=${res.data.id}`,
            });
        }
    };

    const onOpenPdfHandler = (data: DeungiListResponseDto) => {
        if (!data || (data.deungi_state !== DEUNGI_OPEN_STATE.UPLOADED && data.deungi_state !== DEUNGI_OPEN_STATE.EXISTS)) return;

        navigate("/deungi/pdf", {
            state: {
                data,
                isBtnShow: true,
            },
        });
    };

    const onGotoInquireView = () => {
        transferToNative("", "showInquireView");
    };

    useEffect(() => {
        onRefreshHandler();
    }, [filterDate]);

    useEffect(() => {
        // 리스트 열람 상태 자동 갱신 로직
        // 5초마다 열람완료, 열람실패, 열람취소, 결제실패, 재열람실패를 제외한 열람 리스트의 정보를 불러온다
        // 지속적으로 상태를 변경한다
        const watchTimer = setInterval(async () => {
            if (watchDeungiList.length > 0) {
                const currentWatchList = await Promise.all(watchDeungiList.map(async (el) => await useDeungiInfo(el._id)));
                currentWatchList.map((el1) => {
                    const data: DeungiInfoResponseDto = el1.data;
                    setDeungiList(
                        deungiList.map((el2) => {
                            if (el2._id === data._id) {
                                if (el2.deungi_state !== data.deungi_state) {
                                    const element = document.getElementsByClassName(`list${el2._id}`)[0].children[0].children[0]
                                        .classList;
                                    element.add("fadeIn");
                                    setTimeout(() => element.remove("fadeIn"), 500);

                                    el2.deungi_state = data.deungi_state as DEUNGI_OPEN_STATE;
                                    el2.pdf_url = data.pdf_url;
                                }
                            }

                            // 상태 갱신이 필요없는 경우 리스트에서 제외한다
                            if (
                                data.deungi_state === DEUNGI_OPEN_STATE.UPLOADED ||
                                data.deungi_state === DEUNGI_OPEN_STATE.EXISTS ||
                                data.deungi_state === DEUNGI_OPEN_STATE.PAYMENT_FAIL ||
                                data.deungi_state === DEUNGI_OPEN_STATE.REOPEN_FAIL ||
                                data.deungi_state === DEUNGI_OPEN_STATE.UPLOAD_FAIL ||
                                data.deungi_state === DEUNGI_OPEN_STATE.UPLOAD_CANCEL
                            )
                                setWatchDeungiList(watchDeungiList.filter((el3) => el3._id !== data._id));

                            return el2;
                        }),
                    );
                });
            }
        }, 5000);
        return () => clearInterval(watchTimer);
    }, [watchDeungiList]);

    useEffect(() => {
        if (deungiList)
            setWatchDeungiList(
                deungiList.filter(
                    (el) =>
                        el.deungi_state !== DEUNGI_OPEN_STATE.UPLOADED &&
                        el.deungi_state !== DEUNGI_OPEN_STATE.EXISTS &&
                        el.deungi_state !== DEUNGI_OPEN_STATE.PAYMENT_FAIL &&
                        el.deungi_state !== DEUNGI_OPEN_STATE.REOPEN_FAIL &&
                        el.deungi_state !== DEUNGI_OPEN_STATE.UPLOAD_CANCEL &&
                        el.deungi_state !== DEUNGI_OPEN_STATE.UPLOAD_FAIL,
                ),
            );
    }, [deungiList]);

    useEffect(() => {
        const containerEl = containerRef.current as any;

        containerEl.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    }, []);

    return (
        <Container ref={containerRef}>
            <DeungiListInfo>
                <ul>
                    <li>
                        <img src={ico_check_noLine} />
                        해당 페이지는 보유등기를 열람할 수 있는 페이지입니다
                    </li>
                    <li>
                        <img src={ico_check_noLine} />
                        최초 열람일 기준 3개월까지 보관해드려요
                    </li>
                    <li>
                        <img src={ico_check_noLine} />
                        열람 화면에서 캡쳐하거나 저장한 등기사항증명서는 법적 효력이 없습니다
                    </li>
                    <li>
                        <img src={ico_check_noLine} />
                        수집된 지 한 달이 지난 등기는 무료로 열람할 수 있으며 열람내역에 남지 않습니다
                    </li>
                </ul>
            </DeungiListInfo>
            <DeungiListHeader className={fixedState ? "fixed" : ""}>
                <div onClick={onRefreshHandler}>
                    총 <span>{deungiTotalCnt}</span>건
                    <img className={refreshToggle ? "rotate" : ""} src={ico_refresh} />
                </div>
                <div onClick={() => setFilterDateToggle(true)}>
                    <OptionBox>{filterDate}</OptionBox>
                </div>
            </DeungiListHeader>
            {deungiTotalCnt === 0 && firstLoadFlag && (
                <DeungiEmptyBox>
                    <div>
                        <img src={ico_deungi_empty} />
                        <span>열람내역이 없어요</span>
                    </div>
                </DeungiEmptyBox>
            )}
            <DeungiListContents className={fixedState ? "fixed" : ""}>
                {deungiList &&
                    deungiList.map((el: DeungiListResponseDto) => {
                        return (
                            <div className={`list${el._id}`} key={el._id} onClick={() => onOpenPdfHandler(el)}>
                                {el.deungi_state !== DEUNGI_OPEN_STATE.UPLOADED && el.deungi_state !== DEUNGI_OPEN_STATE.EXISTS && (
                                    <div className={"cover"}>
                                        <div className="loadingBox">
                                            {el.deungi_state !== DEUNGI_OPEN_STATE.PAYMENT_FAIL &&
                                                el.deungi_state !== DEUNGI_OPEN_STATE.UPLOAD_FAIL &&
                                                el.deungi_state !== DEUNGI_OPEN_STATE.REOPEN_FAIL &&
                                                el.deungi_state !== DEUNGI_OPEN_STATE.UPLOAD_CANCEL && (
                                                    <Lottie className="lottie" animationData={lottie_loading} />
                                                )}
                                            {el.deungi_state === DEUNGI_OPEN_STATE.PAYMENT_FAIL && (
                                                <img src={ico_refresh_white} onClick={() => onRetryHandler(el._id)} />
                                            )}
                                            {(el.deungi_state === DEUNGI_OPEN_STATE.UPLOAD_FAIL ||
                                                el.deungi_state === DEUNGI_OPEN_STATE.REOPEN_FAIL ||
                                                el.deungi_state === DEUNGI_OPEN_STATE.UPLOAD_CANCEL) && (
                                                <input type="button" value="문의하기" onClick={onGotoInquireView} />
                                            )}

                                            <label
                                                dangerouslySetInnerHTML={{
                                                    __html: getDeungiOpenState(el.deungi_state, "list", el.created_at),
                                                }}
                                            ></label>
                                        </div>
                                    </div>
                                )}
                                {dayjs(el.created_at).add(10, "minutes").diff() > 0 && (
                                    <div id={`Issued${el.id}`} style={{ display: "flex" }} className="Issued">
                                        지금 발급 받았어요!
                                    </div>
                                )}
                                <label>
                                    {el.updatedAtToString} {dayjs(el.created_at).add(1, "day").diff() > 0 && <small>NEW</small>}
                                </label>
                                <h4>{el.address}</h4>
                                <p>
                                    <small>상태</small>
                                    <span>{el.deungi_type}</span>
                                    <small>유형</small>
                                    <span>{el.item_type}</span>
                                </p>
                            </div>
                        );
                    })}

                {deungiTotalCnt > deungiList.length ? (
                    <div className="loadingListBox" ref={loadingRef}>
                        <Lottie className="lottie" animationData={lottie_loading} />{" "}
                    </div>
                ) : (
                    <></>
                )}
            </DeungiListContents>
            <DeungiPopup type={popupType} toggle={popupToggle} setToggle={setPopupToggle} data={openData} />
            <DeungiCombo
                option={filterDate}
                options={filterDateList}
                toggle={filterDateToggle}
                setOption={setFilterDate}
                setToggle={setFilterDateToggle}
            />
        </Container>
    );
};

const Container = styled.div`
    position: absolute;
    width: 100%;
    top: 200px;
    bottom: 0;
    overflow: auto;
    overscroll-behavior-y: none;
`;

const DeungiEmptyBox = styled.div`
    position: absolute;
    top: 280px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    & > div {
        position: absolute;
        display: flex;
        top: 0;
        bottom: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        animation: fadeIn 0.2s;

        & > img {
            width: 140px;
            height: 115px;
            margin-bottom: 24px;
        }
        & > span {
            font-size: 17px;
        }
        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
`;

const DeungiListContents = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overscroll-behavior-y: none;

    .Issued {
        align-items: center;
        gap: 5px;
        padding: 5px 6px;
        color: #0c002c;
        border-radius: 4px;
        width: fit-content;
        background: #fff500;
        margin-bottom: 5px;
    }

    .Issued:after {
        border-top: 8px solid #fff500;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        top: 37px;
        left: 80px;
        border-radius: 10px;
    }

    &.fixed {
        padding-top: 62px;
    }
    & > div {
        position: relative;
        width: 100%;
        padding: 14px;
        font-weight: 400;
        border-bottom: 1px solid #c9c9c9;
        animation: fadeIn 0.5s;

        :active {
            background: #dff0ff;
        }
        .cover {
            position: absolute;
            display: flex;
            flex-direction: column;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7);

            .loadingBox {
                position: relative;
                display: flex;
                width: 100%;
                height: 100px;
                margin: auto;
                padding-bottom: 20px;

                .lottie {
                    width: 60px;
                    margin: 0 auto;
                }
                & > img {
                    width: 32px;
                    height: 32px;
                    margin: 14px auto 0;
                }
                & > label {
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    right: 0;
                    color: #fff;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 1.4;
                }
                & > input {
                    width: 100px;
                    height: 35px;
                    background: #2275ee;
                    border: none;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 13px;
                    margin: 15px auto;
                    padding: 0 10px;
                    transition: all 0.3s ease;

                    &.gray {
                        background: #ebebeb;
                        color: #0c002c;
                    }
                }
            }
        }
        & > label {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #0047ff;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;

            & > small {
                display: inline-block;
                color: #fff;
                font-size: 12px;
                background: #f00;
                border-radius: 100px;
                line-height: 20px;
                padding: 0 4px;
                animation: gelatine 0.5s ease;
            }
        }
        & > h4 {
            color: #0c002c;
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            margin: 3px 0 5px;
        }
        & > p {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;

            small {
                padding: 4px 6px;
                border-radius: 24px;
                margin-right: 8px;
            }
            small:nth-of-type(1) {
                background: #fff9e4;
            }
            small:nth-of-type(2) {
                background: #eefff5;
                margin-left: 20px;
            }
        }
        &.loadingListBox {
            margin: 20px 0;
            border: none;
            background: #fff !important;

            .lottie {
                width: 50px;
                margin: auto;
            }
        }
    }
    .fadeIn {
        animation: fadeIn 0.5s;
    }
    .fadeOut {
        animation: fadeOut 0.5s forwards;
        transform: translateY(-300);
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes gelatine {
        from,
        to {
            transform: scale(1, 1);
        }
        25% {
            transform: scale(0.9, 1.1);
        }
        50% {
            transform: scale(1.1, 0.9);
        }
        75% {
            transform: scale(0.95, 1.05);
        }
    }
`;

const OptionBox = ({ children }: any) => {
    const Container = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #2275ee;
        color: #fff;
        padding: 10px 8px;
        margin-left: 10px;
        font-size: 14px;
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
        border-radius: 24px;

        & > div {
            width: 100%;
            margin: 0 5px;
            text-align: center;
        }
        & > img {
            width: 11px;
            height: 7px;
            margin-right: 5px;
        }
    `;

    return (
        <Container>
            <div>{children}</div>
            <img src={ico_arrow_down} />
        </Container>
    );
};

const DeungiListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 14px;
    background: #fff;
    transition: all 0.3s ease;

    & > div {
        display: flex;
        align-items: center;
        font-size: 14px;

        & > span {
            color: #f00;
            margin-left: 5px;
        }
        & > img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }
    &.fixed {
        position: fixed;
        top: 190px;
        left: 0;
        width: 100%;
        z-index: 2;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    }
    .rotate {
        animation: rotate 0.5s;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0);
            scale: 1;
        }
        50% {
            scale: 1.3;
        }
        100% {
            transform: rotate(360deg);
            scale: 1;
        }
    }
`;

const DeungiListInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 14px;
    padding: 10px 0;
    background: #f6faff;

    ul {
        margin: 5px 0;
        line-height: 1.6;
        padding: 0 14px;

        li {
            display: flex;
            align-items: baseline;
            font-size: 14px;
            word-break: break-all;
            margin: 5px 0;

            img {
                width: 14px;
                height: 10px;
                margin-right: 7px;
            }
        }
    }
`;

export default DeungiListBox;
