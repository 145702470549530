import React, { useState } from "react";
import { css } from "@emotion/react";
import subsCheckIcon from "@src/assets/subscription/subsCheckIcon.svg";
import subsCloseIcon from "@src/assets/subscription/subsCloseIcon.svg";
import tooltipIcon from "@src/assets/basicIcon/tooltipIcon.svg";
import ConfirmModal from "@src/components/popup/ConfirmModal";

interface Props {}

const SubscriptionDesc: React.FC<Props> = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalContents, setModalContents] = useState<any>(<div />);

    const onChangeModalContents = (title: string) => {
        switch (title) {
            case "실거래가":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>실거래가</p>
                        <p css={messageStyle}>매일 업데이트 되는 전국 실거래가를 확인할 수 있어요</p>
                    </>,
                );
            case "데이터맵":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>데이터맵</p>
                        <p css={messageStyle}>경공매, 실거래 마커를 통해 부동산 정보를 한눈에 볼 수 있어요</p>
                    </>,
                );
            case "AI추천매물":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>AI추천</p>
                        <p css={messageStyle}>
                            투자금을 입력하면 세금 및 대출 비중을 고려하여 매물을 추천해드려요 설정한 조건에 맞는 매물이
                            업데이트되면 알려드려요
                        </p>
                    </>,
                );
            case "소액투자":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>소액투자</p>
                        <p css={messageStyle}>소액도 상관없어요 투자금을 입력하면 최적의 매물을 찾아드려요</p>
                    </>,
                );
            case "Go-Stop":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>Go-Stop</p>
                        <p css={messageStyle}>권리분석, 시세 등을 분석하여 입찰을 Go 또는 Stop 할지 AI가 판단해드려요</p>
                    </>,
                );
            case "국•공유지 필터":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>국•공유지 필터</p>
                        <p css={messageStyle}>데이터맵에서 간편하게 국공유지 정보를 파악할 수 있어요</p>
                    </>,
                );
            case "확장예정 도로 데이터맵":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>확장예정 도로 데이터맵</p>
                        <p css={messageStyle}>도로 확장 또는 신설 예정인 토지를 미리 확인할 수 있어요.</p>
                    </>,
                );
            case "실시간 건축대장열람":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>실시간 건축대장열람</p>
                        <p css={messageStyle}>건축물대장을 실시간으로 열람할 수 있어요</p>
                    </>,
                );
            case "실시간 등기열람":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>실시간 등기열람</p>
                        <p css={messageStyle}>
                            부동산 등기를 실시간으로 열람하고 미스고에서 3개월 동안 보관하여 관리할 수 있어요 실시간 열람 시
                            열람권 1장이 소모돼요
                        </p>
                    </>,
                );
            case "기존 등기열람":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>기존 등기열람</p>
                        <p css={messageStyle}>미스고에 수집된 등기를 열람할 수 있는 기능</p>
                    </>,
                );
            case "동시접속":
                return setModalContents(
                    <>
                        <p css={messageTitleStyle}>동시접속</p>
                        <p css={messageStyle}>하나의 아이디로 동시 접속 가능한 PC, APP 기기 수 정보에 대해 알려드릴게요</p>
                    </>,
                );
            default:
                return setModalContents(<div />);
        }
    };

    //* 설명 리스트로 작업
    const descList = {
        info: [
            {
                title: "전국 경·공매 정보",
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: "필지(토지·건물) 정보",
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCheckIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("실거래가");
                            }}
                        />
                        실거래가
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCheckIcon} alt="" width={16} />,
            },
        ],
        func: [
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("데이터맵");
                            }}
                        />
                        데이터맵
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCheckIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("AI추천매물");
                            }}
                        />
                        AI추천매물
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("소액투자");
                            }}
                        />
                        소액투자
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("Go-Stop");
                            }}
                        />
                        Go-Stop
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("국•공유지 필터");
                            }}
                        />
                        국•공유지 필터
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCloseIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("확장예정 도로 데이터맵");
                            }}
                        />
                        <span css={newTextStyle}>확장예정 도로 데이터맵</span>
                    </p>
                ),
                firstContent: (
                    <p css={duplicateTextStyle}>
                        상품에 따라
                        <br />
                        차등 지급
                    </p>
                ),
                secondContent: <img src={subsCloseIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("실시간 건축대장열람");
                            }}
                        />
                        <span>실시간 건축대장열람</span>
                    </p>
                ),
                firstContent: <img src={subsCheckIcon} alt="" width={16} />,
                secondContent: <img src={subsCheckIcon} alt="" width={16} />,
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("실시간 등기열람");
                            }}
                        />
                        실시간 등기열람
                    </p>
                ),
                firstContent: (
                    <p css={deunggiGiveStyle}>
                        1년권 구매 시<br />
                        매월 3장
                    </p>
                ),
                secondContent: (
                    <p css={duplicateTextStyle}>
                        상품에 따라
                        <br />
                        차등 지급
                    </p>
                ),
                thirdContent: (
                    <p css={grayTextStyle}>
                        열람권 구매 시
                        <br />
                        열람 가능
                    </p>
                ),
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("기존 등기열람");
                            }}
                        />
                        기존 등기열람
                    </p>
                ),
                firstContent: (
                    <p css={deunggiGiveStyle}>
                        1년권 구매 시<br />
                        무제한 열람
                    </p>
                ),
                secondContent: (
                    <p css={duplicateTextStyle}>
                        상품에 따라
                        <br />
                        차등 지급
                    </p>
                ),
                thirdContent: <img src={subsCloseIcon} alt="" width={16} />,
            },
            {
                title: (
                    <p css={titleTextStyle}>
                        <img
                            src={tooltipIcon}
                            alt=""
                            width={20}
                            onClick={() => {
                                setOpenModal(true);
                                onChangeModalContents("동시접속");
                            }}
                        />
                        동시접속
                    </p>
                ),
                firstContent: (
                    <p css={duplicateTextStyle}>
                        PC(모바일) 1,
                        <br />
                        APP 1
                    </p>
                ),
                secondContent: (
                    <p css={duplicateTextStyle}>
                        PC(모바일) 1,
                        <br />
                        APP 1
                    </p>
                ),
                thirdContent: (
                    <p css={duplicateTextStyle}>
                        PC(모바일) 1,
                        <br />
                        APP 1
                    </p>
                ),
            },
        ],
    };

    return (
        <>
            <div css={rootBoxStyle}>
                <div css={titleBoxStyle}>기능설명</div>
                <div css={contentRootBoxStyle}>
                    <table>
                        <colgroup>
                            <col width="31%" />
                            <col width="23%" />
                            <col width="23%" />
                            <col width="23%" />
                        </colgroup>
                        <thead>
                            <tr css={tableHeadStyle}>
                                <th />
                                <th>프리미엄</th>
                                <th>베이직/스탠다드</th>
                                <th>미구독</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={3} css={tableSubTitleStyle}>
                                    정보 제공
                                </td>
                            </tr>
                            {descList.info.map((content) => (
                                <tr className="basicTr" key={content.title.toString()}>
                                    <td>{content.title}</td>
                                    <td>{content.firstContent}</td>
                                    <td>{content.secondContent}</td>
                                    <td>{content.thirdContent}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tbody>
                            <tr>
                                <td colSpan={3} css={tableSubTitleStyle}>
                                    기능
                                </td>
                            </tr>
                            {descList.func.map((content, idx) => (
                                <tr className="basicTr" key={idx.toString()}>
                                    <td>{content.title}</td>
                                    <td>{content.firstContent}</td>
                                    <td>{content.secondContent}</td>
                                    <td>{content.thirdContent}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {!!openModal && (
                <ConfirmModal
                    resultMessage={modalContents}
                    onConfirm={() => {
                        setOpenModal(false);
                        setModalContents(<div />);
                    }}
                />
            )}
        </>
    );
};

export default SubscriptionDesc;

const rootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const titleBoxStyle = css`
    text-align: center;
    font-family: "Sandoll Samliphopangche";
    padding-bottom: 24px;
    color: #0c002c;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
`;

const contentRootBoxStyle = css`
    border: 0.6px solid #c9c9c9;
    border-radius: 4px;
    width: calc(100% - 28px);
    overflow-x: scroll;
    box-sizing: border-box;
    white-space: nowrap;
    table {
        width: 100%;
        border-collapse: collapse;
        vertical-align: middle;
        tbody {
            vertical-align: middle;
        }
        td {
            vertical-align: middle;
        }
        .basicTr > td {
            &:first-of-type {
                text-align: left;
                padding-left: 14px;
            }
            box-sizing: border-box;
            border-bottom: 0.6px solid #c9c9c9;
            padding: 8px 4px;
            text-align: center;
            color: #0c002c;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
    }
`;

const tableHeadStyle = css`
    text-align: center;
    color: #0047ff;
    background-color: #dff0ff;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    vertical-align: middle;
    th {
        padding: 4px;
        box-sizing: border-box;
    }
    th:first-of-type {
        border-radius: 4px 0 0 4px;
    }
    th:last-of-type {
        border-radius: 0 4px 4px 0;
        word-break: break-word;
        white-space: pre-wrap;
    }
`;

const tableSubTitleStyle = css`
    text-align: left;
    color: #0c002c;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    padding: 8px 14px;
`;

const deunggiGiveStyle = css`
    color: #0047ff;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

const duplicateTextStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

const grayTextStyle = css`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #8d8d8d;
`;

const titleTextStyle = css`
    display: inline-flex;
    align-items: center;
    position: relative;
    color: #0c002c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    gap: 4px;
`;

const newTextStyle = css`
    background-color: #faff00;
    display: inline-block;
`;

const messageTitleStyle = css`
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 8px;
`;

const messageStyle = css`
    color: #3c3c3c;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    word-break: break-all;
`;
