export default function registDragEvent({
    onDragChange,
    onDragEnd,
    stopPropagation,
}: {
    onDragChange?: (deltaX: number, deltaY: number, startPoint: number) => void;
    onDragEnd?: (deltaX: number, deltaY: number, startPoint: number) => void;
    stopPropagation?: boolean;
}) {
    let startPoint = 0;
    return {
        onTouchStart: (touchEvent: React.TouchEvent<HTMLDivElement>) => {
            if (stopPropagation) touchEvent.stopPropagation();
            const touchStartHandler = (moveEvent: TouchEvent) => {
                startPoint = moveEvent.touches[0].clientY;
            };
            const touchMoveHandler = (moveEvent: TouchEvent) => {
                if (moveEvent.cancelable) moveEvent.preventDefault();

                const deltaX = moveEvent.touches[0].pageX - touchEvent.touches[0].pageX;
                const deltaY = moveEvent.touches[0].pageY;

                onDragChange?.(deltaX, deltaY, startPoint);
            };

            const touchEndHandler = (moveEvent: TouchEvent) => {
                const deltaX = moveEvent.changedTouches[0].pageX - touchEvent.changedTouches[0].pageX;
                const deltaY = moveEvent.changedTouches[0].pageY;
                onDragEnd?.(deltaX, deltaY, startPoint);
                document.removeEventListener("touchstart", touchStartHandler);
                document.removeEventListener("touchmove", touchMoveHandler);
            };
            document.addEventListener("touchstart", touchStartHandler, { passive: false });
            document.addEventListener("touchmove", touchMoveHandler, { passive: false });
            document.addEventListener("touchend", touchEndHandler, { once: true });
        },
    };
}
