import fetchJsonp from "fetch-jsonp";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import convertToPyeongPrice from "@src/util/convertToPyeongPrice";

const env = process.env;

const setTableData = (res: any[]) => {
    if (!res?.length) return [];
    const result = res.map((item) => {
        const { stdrYear, stdrMt, pblntfPclnd } = item;
        return { stdrYear, stdrMt, pblntfPclnd, pblntfPclndPyeong: convertToPyeongPrice(Number(pblntfPclnd)).toString() };
    });
    return result;
};
const isEmpty = (arr: any[]) => {
    return arr.length === 0;
};
const INVALID = "-";

const convertLandUsePlan = (res: any) => {
    let origin = res.landUses?.field ?? [];
    let landLaw: any = { inclusion: [], conflict: [], connect: [] };
    let etcLaw: any = { inclusion: [], conflict: [], connect: [] };
    origin.forEach((item: any) => {
        const { prposAreaDstrcCode, prposAreaDstrcCodeNm, cnflcAt } = item;
        if (prposAreaDstrcCode.includes("UQ")) {
            if (cnflcAt == 1 && !landLaw.inclusion?.includes(prposAreaDstrcCodeNm)) {
                landLaw.inclusion.push(prposAreaDstrcCodeNm);
            }
            if (cnflcAt == 2 && !landLaw.conflict?.includes(prposAreaDstrcCodeNm)) {
                landLaw.conflict.push(prposAreaDstrcCodeNm);
            }
            if (cnflcAt == 3 && !landLaw.connect?.includes(prposAreaDstrcCodeNm)) {
                landLaw.connect.push(prposAreaDstrcCodeNm);
            }
        } else {
            if (cnflcAt == 1 && !etcLaw.inclusion?.includes(prposAreaDstrcCodeNm)) {
                etcLaw.inclusion.push(prposAreaDstrcCodeNm);
            }
            if (cnflcAt == 2 && !etcLaw.conflict?.includes(prposAreaDstrcCodeNm)) {
                etcLaw.conflict.push(prposAreaDstrcCodeNm);
            }
            if (cnflcAt == 3 && !etcLaw.connect?.includes(prposAreaDstrcCodeNm)) {
                etcLaw.connect.push(prposAreaDstrcCodeNm);
            }
        }
    });
    return {
        landLaw: {
            inclusion: isEmpty(landLaw.inclusion) ? INVALID : landLaw.inclusion.join(", "),
            conflict: isEmpty(landLaw.conflict) ? INVALID : landLaw.conflict.join(", "),
            connect: isEmpty(landLaw.connect) ? INVALID : landLaw.connect.join(", "),
        },
        etcLaw: {
            inclusion: isEmpty(etcLaw.inclusion) ? INVALID : etcLaw.inclusion.join(", "),
            conflict: isEmpty(etcLaw.conflict) ? INVALID : etcLaw.conflict.join(", "),
            connect: isEmpty(etcLaw.connect) ? INVALID : etcLaw.connect.join(", "),
        },
    };
};

const convertLandUsePlanTable = (origin: any[]) => {
    if (origin.length === 0) return [];
    let result: { [key: string]: string }[] = [];
    origin.map((item) => {
        let code = Number(item.ladMvmnPrvonshCode);
        let front = [11, 21, 30, 31, 43, 50, 51, 52];
        let middle = 20;
        let reason = () => {
            if (code != middle) {
                if (front.find((e) => e == code)) return `*${item.ladMvmnPrvonshCodeNm}`;
            } else if (code == middle) {
                let index = item.ladMvmnPrvonshCodeNm.indexOf("을");
                return [
                    item.ladMvmnPrvonshCodeNm.slice(0, index),
                    " *",
                    item.ladMvmnPrvonshCodeNm.slice(index, item.ladMvmnPrvonshCodeNm.length),
                ].join(" ");
            }
            return item.ladMvmnPrvonshCodeNm;
        };
        result.push({
            date: item?.ladMvmnDe?.replaceAll("-", ".") ?? "-",
            reason: reason(),
        });
    });
    return result;
};

const fetchVWorldData = async (params: any) => {
    const { origin, pnu } = params;
    const { res1, res2 } = origin;
    //res1: 국토교통부_토지특성정보서비스
    //res2: 국토교통부_토지소유정보서비스

    let result = {
        base: {
            pblntfPclnd: res2?.pblntfPclnd ?? "",
            pblntfPclndPyeong: res2?.pblntfPclnd,
            lastUpdtDt: res1?.lastUpdtDt ?? "",
            posesnSeCodeNm: res2?.posesnSeCodeNm ?? "",
            cnrsPsnCo: res2?.cnrsPsnCo ?? "",
            ownshipChgDe: res2?.ownshipChgDe ?? "",
            ownshipChgCauseCodeNm: res2?.ownshipChgCauseCodeNm ?? "",
            ladUseSittnNm: res1?.ladUseSittnNm ?? "",
            tpgrphHgCodeNm: res1?.tpgrphHgCodeNm ?? "",
            tpgrphFrmCodeNm: res1?.tpgrphFrmCodeNm ?? "",
            roadSideCodeNm: res1?.roadSideCodeNm ?? "",
            lndcgrCodeNm: res1?.lndcgrCodeNm ?? "",
            lndpclAr: res1?.lndpclAr ?? "",
            lndpclArPyeong: res1?.lndpclAr ?? "",
            use: res1?.prposArea1Nm ?? "",
        },
        landUsePlan: {
            landLaw: {
                inclusion: res1.use_law_include,
                conflict: res1.use_law_conflict,
                connect: res1.use_law_exposure,
            },
            etcLaw: {
                inclusion: res1.etc_law_include,
                conflict: res1.etc_law_conflict,
                connect: res1.etc_law_exposure,
            },
            table: [] as any[],
        },
        officialPrice: [] as any[],
    };

    const key = env.REACT_APP_V_WORLD_KEY;
    const domain = env.REACT_APP_V_WORLD_DOMAIN;
    //* vworld: 국토교통부_토지이용계획속성조회 (토지이용계획)
    const path = "https://api.vworld.kr/ned/data/getLandUseAttr";
    const queryString = `?key=${key}&pnu=${pnu}&format=json&domain=${domain}`;
    const url = `${path}${queryString}`;
    //* vworld: 국토교통부_개별공시지가정보서비스 (개별공시지가)
    const path2 = "https://api.vworld.kr/ned/data/getIndvdLandPriceAttr";
    const queryString2 = `?key=${key}&pnu=${pnu}&format=json&numOfRows=40`;
    const url2 = `${path2}${queryString2}`;
    //* 내제된 개별공시지가정보
    const individualLandPriceApi = `${axiosPath.individualLandPrice}/${pnu}`;

    //* vworld: 토지이동이력속성조회 (토지이동사유)
    const path3 = "https://api.vworld.kr/ned/data/getLandMoveAttr";
    const queryString3 = `?pnu=${pnu}&numOfRows=20&key=${key}`;
    const url3 = `${path3}${queryString3}`;
    try {
        const getLandUseData = await fetchJsonp(url, {
            jsonpCallbackFunction: "landUseData",
        });
        const getLandPriceData = await fetchJsonp(url2, {
            jsonpCallbackFunction: "landPriceData",
        });
        const getLandMoveData = await fetchJsonp(url3, {
            jsonpCallbackFunction: "landMoveData",
        });
        const landUseData = await getLandUseData.json();
        const landPriceData = await getLandPriceData.json();
        const landMoveData = await getLandMoveData.json();
        const convertUsePlanResult = convertLandUsePlan(landUseData);
        result.landUsePlan = {
            ...result.landUsePlan,
            ...convertUsePlanResult,
        };
        const newPriceData = landPriceData.indvdLandPrices?.field?.length
            ? landPriceData.indvdLandPrices?.field
            : await axiosInstance.get(individualLandPriceApi).then((res) => {
                  return res.data;
              });
        const officialPrice = setTableData(newPriceData ?? []);
        result.officialPrice = officialPrice;
        const landUsePlanTable = convertLandUsePlanTable(landMoveData?.landMoves?.field ?? []);
        result.landUsePlan.table = landUsePlanTable;
    } catch (e) {
        console.log(e);
    }
    return result;
};

const useGetLandDetail = async (params: any) => {
    return new Promise((resolve, _reject) => {
        resolve(fetchVWorldData(params));
    }).then((res) => res);
};
interface lawDto {
    inclusion: string;
    conflict: string;
    connect: string;
}
interface initLandDetailDataDto {
    base: {
        pblntfPclnd: string;
        pblntfPclndPyeong: string;
        lastUpdtDt: string;
        posesnSeCodeNm: string;
        cnrsPsnCo: string;
        ownshipChgDe: string;
        ownshipChgCauseCodeNm: string;
        ladUseSittnNm: string;
        tpgrphHgCodeNm: string;
        tpgrphFrmCodeNm: string;
        roadSideCodeNm: string;
        lndpclAr: string; //면적
        lndpclArPyeong: string;
        lndcgrCodeNm: string;
        use: string;
    };
    landUsePlan: {
        landLaw?: lawDto;
        etcLaw?: lawDto;
        table?: any;
    };
    officialPrice: any[];
}
const initLandDetailData: initLandDetailDataDto = {
    base: {
        pblntfPclnd: "",
        pblntfPclndPyeong: "",
        lastUpdtDt: "",
        posesnSeCodeNm: "",
        cnrsPsnCo: "",
        ownshipChgDe: "",
        ownshipChgCauseCodeNm: "",
        ladUseSittnNm: "",
        tpgrphHgCodeNm: "",
        tpgrphFrmCodeNm: "",
        roadSideCodeNm: "",
        lndcgrCodeNm: "",
        lndpclAr: "",
        lndpclArPyeong: "",
        use: "",
    },
    landUsePlan: {
        landLaw: {
            inclusion: "",
            conflict: "",
            connect: "",
        },
        etcLaw: {
            inclusion: "",
            conflict: "",
            connect: "",
        },
        table: [],
    },
    officialPrice: [],
};
// const initLandDetailData: initLandDetailDataDto = {
//     base: {
//         pblntfPclnd: "498000",
//         pblntfPclndPyeong: "0",
//         lastUpdtDt: "YYYY.MM.DD",
//         posesnSeCodeNm: "개인",
//         cnrsPsnCo: "(공유인수 0)",
//         ownshipChgDe: "YYYY.MM.DD",
//         ownshipChgCauseCodeNm: "소유권변동원인",
//         ladUseSittnNm: "토지이용상황",
//         tpgrphHgCodeNm: "지형높이",
//         tpgrphFrmCodeNm: "지형형상",
//         roadSideCodeNm: "도로접면",
//         lndcgrCodeNm: "지목",
//         lndpclAr: "면적",
//         lndpclArPyeong: "평면적",
//         use: "용도지역",
//     },
//     landUsePlan: {
//         landLaw: {
//             inclusion: "포함",
//             conflict: "저촉",
//             connect: "접함",
//         },
//         etcLaw: {
//             inclusion: "포함",
//             conflict: "저촉",
//             connect: "접함",
//         },
//         table: [
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//             {
//                 ladMvmnDe: "토지이동일 YYYY.MM.DD",
//                 ladMvmnPrvonshCodeNm: "토지이동사유",
//             },
//         ],
//     },
//     officialPrice: [],
// };
export { initLandDetailData, useGetLandDetail };
export type { initLandDetailDataDto };
