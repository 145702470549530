import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import deungi_ticket_ico from "@src/assets/deungi/deungi_ticket_ico.png";
import exist_deungi_ticket_ico from "@src/assets/deungi/exist_deungi_ticket_ico.png";
import items_ads_ico from "@src/assets/deungi/items_ads_ticket_ico.png";
import region_ads_ico from "@src/assets/deungi/region_ads_ticket_ico.png";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TICKET_TYPE } from "./common/enum";
import { TicketCountResponseDto } from "@src/hooks/deungi/dto/Ticket.dto";
import { useTicketCount } from "@src/hooks/deungi/useTicketCount";
import { useUserSubsInfo } from "@src/hooks/deungi/useUserSubsInfo";
import { UserSubsInfoResponseDto } from "@src/hooks/deungi/dto/UserSubs";
import { useIsUserExpert } from "@src/hooks/expert/useIsUserExpert";

type DeungiTicketProps = {
    ticketBoxRef: any;
    ticketPosition?: TICKET_TYPE;
    onTicketClickHandler?: (ticketType: TICKET_TYPE, boxHeight: number) => void;
    isUserExpert?: boolean;
    pageType?: number;
};

const DeungiTicketBox = ({ ticketBoxRef, ticketPosition, onTicketClickHandler, isUserExpert, pageType }: DeungiTicketProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [isExistDeungi, setIsExistDeungi] = useState(false);
    const [ticketType, setTicketType] = useState(0);
    const [coverHeight, setCoverHeight] = useState(0);
    const [ticketCnt, setTicketCnt] = useState({
        deungiTicketCnt: {} as TicketCountResponseDto,
        existTicketCnt: {} as TicketCountResponseDto,
        itemsAdsTicketCnt: {} as TicketCountResponseDto,
        regionAdsTicketCnt: {} as TicketCountResponseDto,
    });
    const [isUserExpertInner, setIsUserExpertInner] = useState(isUserExpert || false);

    const getIsUserExpert = async () => {
        const res = await useIsUserExpert();
        setIsUserExpertInner(res.data || false);
    };

    const getUserSubsInfo = async () => {
        const subsRes = await useUserSubsInfo();
        const subsInfo: UserSubsInfoResponseDto = subsRes.data;

        // 스탠다드 또는 프리미엄 1년 상품인 경우
        if (subsInfo && ["스탠다드", "프리미엄"].includes(subsInfo.subsName) && subsInfo.serveDurationType === "year")
            setIsExistDeungi(true);
    };

    const getTicketCount = async () => {
        setTicketCnt({
            deungiTicketCnt: (await useTicketCount(TICKET_TYPE.DEUNGI_TICKET)).data || 0,
            existTicketCnt: (await useTicketCount(TICKET_TYPE.EXIST_DEUNGI_TICKET)).data || 0,
            itemsAdsTicketCnt: (await useTicketCount(TICKET_TYPE.ITEMS_ADS_TICKET)).data || 0,
            regionAdsTicketCnt: (await useTicketCount(TICKET_TYPE.REGION_ADS_TICKET)).data || 0,
        });
    };

    const gotoPayment = (type: TICKET_TYPE) => {
        navigate("/deungi/payment", { replace: false, state: { type: type || TICKET_TYPE.DEUNGI_TICKET } });
    };

    useEffect(() => {
        if (ticketBoxRef.current) {
            setCoverHeight(ticketBoxRef.current.clientHeight);
            addEventListener("resize", (ev: any) => {
                setCoverHeight(ticketBoxRef.current.clientHeight);
            });
        }
        return removeEventListener("resize", () => { });
    }, [ticketBoxRef?.current?.clientHeight]);

    useEffect(() => {
        getTicketCount();
        getUserSubsInfo();
    }, []);

    useEffect(() => {
        if (isUserExpert === undefined) getIsUserExpert();
        else if (isUserExpert) setIsUserExpertInner(isUserExpert);
    }, [isUserExpert]);

    useEffect(() => {
        if (coverHeight > 0) {
            const moveCategory = setTimeout(() => {
                if (
                    location?.state?.type === TICKET_TYPE.EXIST_DEUNGI_TICKET ||
                    Number(searchParams.get("type")) === TICKET_TYPE.EXIST_DEUNGI_TICKET
                ) {
                    onTicketClickHandler && onTicketClickHandler(TICKET_TYPE.EXIST_DEUNGI_TICKET, coverHeight);
                    setTicketType(TICKET_TYPE.EXIST_DEUNGI_TICKET);
                } else if (
                    location?.state?.type === TICKET_TYPE.ITEMS_ADS_TICKET ||
                    Number(searchParams.get("type")) === TICKET_TYPE.ITEMS_ADS_TICKET
                ) {
                    onTicketClickHandler && onTicketClickHandler(TICKET_TYPE.ITEMS_ADS_TICKET, coverHeight);
                    setTicketType(TICKET_TYPE.ITEMS_ADS_TICKET);
                } else if (
                    location?.state?.type === TICKET_TYPE.REGION_ADS_TICKET ||
                    Number(searchParams.get("type")) === TICKET_TYPE.REGION_ADS_TICKET
                ) {
                    onTicketClickHandler && onTicketClickHandler(TICKET_TYPE.REGION_ADS_TICKET, coverHeight);
                    setTicketType(TICKET_TYPE.REGION_ADS_TICKET);
                } else {
                    onTicketClickHandler && onTicketClickHandler(TICKET_TYPE.DEUNGI_TICKET, coverHeight);
                    setTicketType(TICKET_TYPE.DEUNGI_TICKET);
                }
            }, 250);
            return () => clearTimeout(moveCategory);
        }
    }, [location?.state?.type, coverHeight]);

    return (
        <>
            <Container ref={ticketBoxRef}>
                <div>
                    <div
                        className={
                            ticketType && ticketPosition === TICKET_TYPE.DEUNGI_TICKET ? "ticketItem active" : "ticketItem"
                        }
                        onClick={() =>
                            onTicketClickHandler
                                ? onTicketClickHandler(TICKET_TYPE.DEUNGI_TICKET, coverHeight)
                                : gotoPayment(TICKET_TYPE.DEUNGI_TICKET)
                        }
                    >
                        <img src={deungi_ticket_ico} />
                        <h3>
                            실시간 등기열람권
                            <label>{ticketCnt.deungiTicketCnt.curHaveCnt || 0}장</label>
                        </h3>
                    </div>
                    {
                        pageType === 0 &&
                        <div
                            className={
                                ticketType && ticketPosition === TICKET_TYPE.EXIST_DEUNGI_TICKET ? "ticketItem active" : "ticketItem"
                            }
                            onClick={() =>
                                onTicketClickHandler
                                    ? onTicketClickHandler(TICKET_TYPE.EXIST_DEUNGI_TICKET, coverHeight)
                                    : gotoPayment(TICKET_TYPE.EXIST_DEUNGI_TICKET)
                            }
                        >
                            <img src={exist_deungi_ticket_ico} />
                            <h3>
                                기존 등기열람권
                                {isExistDeungi ? <label>무제한</label> : <label>{ticketCnt.existTicketCnt.curHaveCnt || 0}장</label>}
                            </h3>
                        </div>
                    }
                </div>
                {isUserExpertInner && (
                    <div>
                        <div
                            className={
                                ticketType && ticketPosition === TICKET_TYPE.ITEMS_ADS_TICKET ? "ticketItem active" : "ticketItem"
                            }
                            onClick={() =>
                                onTicketClickHandler
                                    ? onTicketClickHandler(TICKET_TYPE.ITEMS_ADS_TICKET, coverHeight)
                                    : gotoPayment(TICKET_TYPE.ITEMS_ADS_TICKET)
                            }
                        >
                            <img src={items_ads_ico} />
                            <h3>
                                매물광고
                                <label>{ticketCnt.itemsAdsTicketCnt.curHaveCnt || 0}장</label>
                            </h3>
                        </div>
                        <div
                            className={
                                ticketType && ticketPosition === TICKET_TYPE.REGION_ADS_TICKET
                                    ? "ticketItem active"
                                    : "ticketItem"
                            }
                            onClick={() =>
                                onTicketClickHandler
                                    ? onTicketClickHandler(TICKET_TYPE.REGION_ADS_TICKET, coverHeight)
                                    : gotoPayment(TICKET_TYPE.REGION_ADS_TICKET)
                            }
                        >
                            <img src={region_ads_ico} />
                            <h3>
                                지역광고
                                <label>{ticketCnt.regionAdsTicketCnt.curHaveCnt || 0}장</label>
                            </h3>
                        </div>
                    </div>
                )}
            </Container>
            <div className="backDiv" style={{ height: coverHeight }} />
        </>
    );
};

const Container = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    box-sizing: border-box;
    width: 100%;
    top: 55px;
    gap: 10px;
    padding: 0 14px 14px;
    z-index: 2;

    & > div {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

    .ticketItem {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        background: #f6faff;
        border-radius: 8px;
        padding: 12px 8px;
        transition: all 0.2s ease;

        &.active {
            background: #0047ff;

            &:nth-of-type(3) {
                background: #09aa4b;
            }
            & > h3 {
                color: #fff;
                & > label {
                    color: #fff;
                }
            }
        }
        & > h3 {
            font-size: 13px;
            font-weight: 500;
            line-height: 1.4;
            min-width: 80px;
            width: max-content;
            text-align: center;
            margin: 4px auto 0;

            & > label {
                display: block;
                font-size: 14px;
                font-weight: 700;
                color: #0047ff;
                text-align: center;
            }
        }
        & > img {
            width: 38px;
            height: 38px;
            background: #fff;
            border-radius: 4px;
            padding: 4px;
            margin: auto;
        }
    }
`;

export default DeungiTicketBox;
