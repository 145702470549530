import { cookie } from "@src/lib/WebAppBridge";
import React, { useEffect, useState } from "react";

let count = 1;

const useCallToken = () => {
    const [token, setToken] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const tokenValue = localStorage.getItem("accessToken") || "";
            count = count + 1;

            if (count > 20 || tokenValue?.length) {
                setToken(tokenValue);
                clearInterval(interval);
            }
        }, 500);
    }, []);

    return { token };
};

export default useCallToken;
