import { css } from "@emotion/react";
import React, { useState } from "react";
import ClearIcon from "@src/assets/ico_draw_clear.svg";
import ConvertPyeongButton from "@src/components/button/ConvertPyeongButton";
import convertToPyeong from "@src/util/convertToPyeong";

interface Props {
    totalPolylineDistance: number;
    drawingType: number;
    totalPolygonSize: number;
    onClose: () => void;
}

const MeasureCard: React.FC<Props> = ({ totalPolygonSize, totalPolylineDistance, drawingType, onClose }) => {
    // card에 있는 방식으로 사용
    const [sizeType, setSizeType] = useState<"pyeong" | "meter">("meter");
    const handleCovertType = (type: "meter" | "pyeong") => {
        setSizeType(type);
    };
    return (
        <div css={rootStyle}>
            <div className="contentBox">
                <div className="title">
                    <p>
                        {drawingType === 1
                            ? totalPolylineDistance < 0
                                ? "거리 측정할 지점을 지도에서 터치해주세요"
                                : "총 거리"
                            : totalPolygonSize < 0
                            ? "면적 측정할 지점을 지도에서 터치해주세요"
                            : "총 면적"}
                    </p>
                    <button onClick={onClose} className="clearBtn">
                        <img src={ClearIcon} alt="" />
                    </button>
                </div>
                {drawingType === 1 && totalPolylineDistance >= 0 && (
                    <div className="descDistance">
                        <p>
                            {totalPolylineDistance.toFixed(0).length > 3
                                ? `${(totalPolylineDistance / 1000).toFixed(1)}km`
                                : `${totalPolylineDistance.toFixed(0)}m`}
                        </p>
                    </div>
                )}
                {drawingType === 2 && totalPolygonSize >= 0 && (
                    <div className="descSize">
                        <p>
                            {sizeType === "meter"
                                ? `${new Intl.NumberFormat("ko").format(convertToPyeong(totalPolygonSize))}평`
                                : totalPolygonSize.toFixed(0).length > 6
                                ? `${new Intl.NumberFormat("ko").format(Number((totalPolygonSize / 1000000).toFixed(0)))}km`
                                : `${new Intl.NumberFormat("ko").format(Number(totalPolygonSize.toFixed(0)))}m`}
                            {sizeType === "pyeong" && <sup>2</sup>}
                        </p>
                        {drawingType === 2 && totalPolygonSize >= 0 && (
                            <div className="convertBtn">
                                <ConvertPyeongButton handleConvert={handleCovertType} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const rootStyle = css`
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 991;
    .contentBox {
        margin-top: 24px;
        padding: 18px 14px;
        background: #ffffff;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
        border-radius: 14px 14px 0px 0px;
        .title {
            display: flex;
            justify-content: center;
            p {
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                color: #0c002c;
            }
            @media screen and (max-width: 330px) {
                p {
                    max-width: 200px;
                    word-break: keep-all;
                    line-height: 15px;
                }
            }
            .clearBtn {
                position: fixed;
                right: 18px;
            }
        }
        .descSize {
            display: flex;
            justify-content: center;
            padding-top: 18px;
            height: 33px;
            p {
                display: inline-block;
                font-weight: 500;
                font-size: 16px;
                color: #0c002c;
            }
            .convertBtn {
                position: fixed;
                right: 18px;
                bottom: 7px;
            }
        }
        .descDistance {
            text-align: center;
            padding-top: 14px;
            font-weight: 500;
            font-size: 16px;
            color: #0c002c;
        }
    }
`;
export default MeasureCard;
