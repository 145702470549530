import styled from "@emotion/styled";
import AiBottomSheet from "@src/components/aiFilter/AiBottomSheet";
import AiFooter from "@src/components/aiFilter/AiFooter";
import AiHeader from "@src/components/aiFilter/AiHeader";
import AiResultTotal from "@src/components/aiFilter/AiResultTotal";
import AiTitle from "@src/components/aiFilter/AiTitle";
import { MAX_Y, MIN_Y } from "@src/components/aiFilter/Constants";
import BottomSheet from "@src/components/bottomSheet/BottomSheet";
import BottomSheetContents from "@src/components/bottomSheet/BottomSheetContents";
import FilterFooter from "@src/components/filter/FilterFooter";
import LocationFilter from "@src/components/filter/LocationFilter";
import ToastMessage from "@src/components/filter/ToastMessage";
import useGetLocations from "@src/hooks/filter/useGetLocations";
import { transferToNative } from "@src/lib/WebAppBridge";
import { FilterParamsType, useAiFilterStore } from "@src/store/AiFilterStore";
import { useFilterStore } from "@src/store/filterStore";
import produce from "immer";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AiResetConfirmModal from "./AiResetConfirmModal";
import useCallToken from "@src/hooks/useCallToken";

const AiFilterLocationV2 = () => {
    const [resetVisible, setResetVisible] = useState(false);
    const [sortStatus, setSortStatus] = useState<string | undefined>("업데이트순");
    const { pathname } = useLocation();

    const { state } = useLocation() as {
        state: FilterParamsType;
    };

    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const getAiFilterCount = useAiFilterStore((state) => state.getAiFilterCount);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);
    const updateHistoryFilterFlag = useFilterStore((state) => state.updateHistoryFilterFlag);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const aiFilterType = useAiFilterStore((state) => state.aiFilterState.type);
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const [aiSelectedLocation, setAiSelectedLocation] = useState<string[]>(aiFilterState.loc);
    const getAiFilterDataList = useAiFilterStore((state) => state.getAiFilterDataList);
    const setAiFilterDataList = useAiFilterStore((state) => state.setAiFilterDataList);
    const sortId = useAiFilterStore((state) => state.selectedSortId);
    const setSortText = useAiFilterStore((state) => state.setSelectedSortText);
    const [visible, setVisible] = useState(false);
    const sortText = useAiFilterStore((state) => state.selectedSortText);
    const isConfirm = useAiFilterStore((state) => state.isConfirm);
    const setIsConfirm = useAiFilterStore((state) => state.setIsConfirm);
    const deleteAiFilterState = useAiFilterStore((state) => state.deleteAiFilterState);
    const [selectedSortId, setSelectedSortId] = useState<number | undefined>(sortId);
    const navigate = useNavigate();
    const setIsShowGuidePopup = useAiFilterStore((state) => state.setIsShowGuidePopup);

    useEffect(() => {
        handleSubmit(aiSelectedLocation);

        setUpdateHistoryFilterFlag(false);
    }, [aiSelectedLocation]);

    useEffect(() => {
        // getAiFilterCount({
        //     ...state,
        //     loc: aiSelectedLocation,
        //     type: aiFilterType,
        // });

        setAiFilterState({ ...state, loc: aiSelectedLocation, type: aiFilterType, price: state.price });
        setAiFilterDataList(
            {
                ...state,
                loc: aiSelectedLocation,
                type: aiFilterType,
                price: state.price,
            },
            sortId,
        );

        setUpdateHistoryFilterFlag(false);
    }, [aiFilterType]);

    // 소재지
    const {
        locationData: locationState,
        activeData,
        selectedType,
        handleSelect,
        onClickRemove,
        setActiveData,
        resetActiveData,
        activePNUState,
        setActivePNUState,
        resetLocationData,
        setSubmitPNUData,
        toastVisible,
        setToastVisible,
        getActiveData,
    } = useGetLocations(state.loc);

    useEffect(() => {
        setUpdateHistoryFilterFlag(false);
        if (isEmpty(aiSelectedLocation) && !isEmpty(activeData.city)) {
            const nextState = produce(activeData, (draft) => {
                draft.city[0].isActive = true;
            });
            setActiveData(nextState);
        }
    }, [locationState, activeData]);

    const handleSubmit = (selectedLocation: string[]) => {
        if (!isEmpty(activePNUState) || activeData?.city[0]?.isActive) {
            setAiFilterState({
                ...state,
                loc: selectedLocation,
                type: aiFilterType,
                price: state.price,
            });
            // getAiFilterCount({
            //     ...state,
            //     loc: selectedLocation,
            // });
            setAiFilterDataList(
                {
                    ...state,
                    loc: selectedLocation,
                    type: aiFilterType,
                    price: state.price,
                },
                sortId,
            );
        }
    };
    useEffect(() => {
        if (state.loc && updateHistoryFilterFlag === false) {
            getActiveData();
        }
    }, [state.loc]);

    const locationArea = useMemo(
        () => (
            <FilterContainer>
                <FilterFooter
                    style={{ height: 52 }}
                    locationState={activeData}
                    onSubmit={() => console.log("진행상태!")}
                    type="AI"
                    setAiSelectedLocation={setAiSelectedLocation}
                    onClickRemove={onClickRemove}
                    setSubmitPNUData={setSubmitPNUData}
                />
                <LocationFilter
                    style={{ height: Math.floor(window.innerHeight * 0.3) }}
                    handleSelect={handleSelect}
                    activeData={activeData}
                    locationData={locationState}
                    selectedType={selectedType}
                    setActiveData={setActiveData}
                    resetActiveData={resetActiveData}
                    activePNUState={isEmpty(activePNUState) ? ["All"] : activePNUState}
                    setActivePNUState={setActivePNUState}
                    resetLocationData={resetLocationData}
                />
            </FilterContainer>
        ),
        [
            activePNUState,
            locationState,
            selectedType,
            activeData,
            setSubmitPNUData,
            state,
            aiSelectedLocation,
            updateHistoryFilterFlag,
        ],
    );

    const BottomSheetArea = useMemo(
        () => (
            <BottomSheet visible={visible}>
                <BottomSheetContents setSelectedSortId={setSelectedSortId} setSortStatus={setSortStatus} type="Ai" />
            </BottomSheet>
        ),
        [visible, selectedSortId],
    );

    const onClickNext = () => {
        handleSubmit(aiSelectedLocation);
        pathname.includes("small")
            ? navigate("/smallFilter/locationv2/purposev2", {
                  state: { ...aiFilterState, loc: aiSelectedLocation, type: aiFilterType },
              })
            : navigate("/aiFilterv2/locationv2/purposev2", {
                  state: { ...aiFilterState, loc: aiSelectedLocation, type: aiFilterType },
              });
    };

    const onClickReset = () => {
        setResetVisible(true);
    };

    const onClickCancel = () => {
        setResetVisible(false);
    };

    const onClickConfirm = async () => {
        // await handleSubmit([]);
        setIsConfirm(true);
        setAiFilterState({
            price: "",
            type: "0",
            loc: [],
            use: [],
        });
        // setAiFilterDataList({ price: "", type: "0", loc: [], use: [] }, 5, "setting");
        setAiFilterDataList({ ...aiFilterState, price: "", type: "0", loc: [""], use: [""] }, 0);
        deleteAiFilterState();

        resetActiveData();
        resetLocationData();
        setResetVisible(false);

        setIsShowGuidePopup(false);
        pathname.includes("small")
            ? navigate("/smallFilter", { state: { ...aiFilterState, loc: [], use: [], type: "0", price: "" } })
            : navigate("/aiFilterv2", { state: { ...aiFilterState, loc: [], use: [], type: "0", price: "" } });
    };

    const onClickGoBack = () => {
        handleSubmit(aiSelectedLocation);
        setIsShowGuidePopup(false);
        pathname.includes("small")
            ? navigate("/smallFilter", { state: { ...aiFilterState, loc: aiSelectedLocation, type: aiFilterType } })
            : navigate("/aiFilterv2", { state: { ...aiFilterState, loc: aiSelectedLocation, type: aiFilterType } });
    };

    const onClickClose = () => {
        setIsShowGuidePopup(true);
        transferToNative("done", "closeWebView");
    };

    useEffect(() => {
        setAiFilterState({
            ...state,
            loc: aiSelectedLocation,
            type: aiFilterType,
            price: state.price,
        });
        // getAiFilterCount({
        //     ...state,
        //     loc: selectedLocation,
        // });
        setAiFilterDataList(
            {
                ...state,
                loc: aiSelectedLocation,
                type: aiFilterType,
                price: state.price,
            },
            sortId,
        );
        setSortText(sortText);
    }, [selectedSortId]);

    return (
        <>
            <Wrapper>
                <Container>
                    <AiHeader onClickBack={onClickClose} />
                    <AiTitle>
                        <h2>
                            관심있는 <span className="color-text">소재지</span>를 선택해주세요.
                        </h2>
                        {/* <p>
                        선택한 소재지에 따라 맞춤형
                        <br />
                        매물 정보를 확인할 수 있어요
                    </p> */}
                    </AiTitle>
                    {/* <AiResultTotal result={aiFilterCount} /> */}
                    {/* <Border /> */}
                    {locationArea}
                    <AiFooter
                        style={{ top: `${MAX_Y - 88}px` }}
                        isReset
                        isPrev
                        isNext
                        onClickNext={onClickNext}
                        onClickReset={onClickReset}
                        onClickPrev={onClickGoBack}
                    />
                    <AiBottomSheet price="" visible={visible} setVisible={setVisible} />
                </Container>
                {BottomSheetArea}
            </Wrapper>
            {resetVisible ? (
                <AiResetConfirmModal text="필터를 초기화할까요?" onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />
            ) : (
                <></>
            )}
            <ToastMessage visible={toastVisible} setToastVisible={setToastVisible}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
        </>
    );
};

export default AiFilterLocationV2;

const Wrapper = styled.div`
    overflow: hidden;
    height: 100%;
`;

const Container = styled.div`
    position: relative;
    padding: 0px 14px;
    height: 100vh;
    overflow: hidden;
`;

const Border = styled.div`
    width: 100vw;
    margin-left: -14px;
    height: 12px;
    background: #edf0f6;
`;

const FilterContainer = styled.div`
    position: relative;
    background: white;
    width: 100vw;
    margin-left: -14px;
    max-height: 470px;
    button + button {
        margin-left: 0px;
    }
`;
