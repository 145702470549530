import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import React, { useEffect, useState } from "react";

export const initialState = {
    type: "0",
    loc: [],
    use: [],
    counter_force: "0",
    auction_master: [],
    except_item: [],
    status: ["0"],
    skp_type: "0",
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sales_method: "0",
    assets: [],
    sort: "0",
};

const useGetFilter = () => {
    const [data, setData] = useState<FilterType>(initialState);

    const fetchData = async () => {
        const response = await axiosInstance.get(axiosPath.filterData);

        setData(response.data.payload.data.value);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return { data, setData };
};

export default useGetFilter;

export type FilterType = {
    type: string;
    loc: string[];
    use: string[];
    counter_force: string;
    auction_master: string[];
    except_item: string[];
    status: string[];
    skp_type: string;
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    sales_method: string;
    assets: string[];
    sort: string;
};

// "_opt": {
//     "type": "경공매 구분(0: 전체, 1: 경매, 2: 공매)",
//     "loc": "소재지(전국: [], 그 외: 소재지의 cortarNo 배열)",
//     "use": "용도(주거용: A~, 토지: B~, 산업용: C~, 상업용: D~, 기타: E01)",
//     "counter_force": "대항력(0: 전체, 1: 대항력 있음, 2: 대항력 없음)",
//     "auction_master": "경매고수물건(1: 유치권, 2: 분묘기지권, 3: 지분매각, 4: 토지만입찰, 5: 대지권미등기, 6: HUG 임차권 인수조건 변경)",
//     "except_item": "제외물건(A01: 농취중필요 선택, A02: 농취증필요 제외, B01: 법정지상권 선택, B02: 법정지상권 제외, C01: 위반건축물 선택, C02: 위반건축물 제외, D01: 건물만입찰 선택, D02: 건물만입찰 제외, E01: 토지별도등기 선택, E02: 토지별도등기 제외)",
//     "status": "진행상태(0: 전체, 1: 진행예정, 2: 진행중, 3: 낙찰, 4: 유찰, 5: 기타)",
//     "skp_type": "유찰구분(0: 전체, 1: 신건, 2: 유찰)",
//     "skp_cnt": "유찰횟수(범위 배열로 전달)",
//     "due_date": "매각기일(범위 배열로 전달)",
//     "est_price": "감정가(범위 배열로 전달)",
//     "min_price": "최저입찰가(범위 배열로 전달)",
//     "sales_method": "처분방식(0: 전체, 1: 매각, 2: 임대)",
//     "assets": "자산구분(0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산)",
//     "sort": "정렬(0: 업데이트순, 1: 감정가 높은순, 2: 감정가 낮은순, 3: 최저입찰가 높은순, 4: 최저입찰가 낮은순, 5: 매각기일 가까운순, 6: 매각기일 먼순, 7: 유찰횟수 많은순 8: 유찰횟수 적은순)"
// }
