import React from "react";
import { css } from "@emotion/react";
import { Button } from "@src/components";
import { Common } from "@src/styles/Common";
import { useAiRecommendFilterStore, useUserAiSettingSotre } from "@src/store/AiRecommendFilterStore";
import ico_arrow_down_bold from "@src/assets/ico_arrow_down_bold.svg";

const sortTextList = [
    "업데이트순",
    "감정가 높은순",
    "감정가 낮은순",
    "최저가 높은순",
    "최저가 낮은순",
    "매각기일 가까운순",
    "",
    "유찰회수 많은순",
    "유찰회수 적은순",
];
interface Props {
    buttonWrapper: any;
    sort: any;
    totalCount: number;
    showSortBox: boolean;
    firstButtonRef: any;
    secondButtonRef: any;
    thirdButtonRef: any;
    onChangeShowSortBox: () => void;
}

const AiRecommendBottomSheetTitle = ({
    buttonWrapper,
    sort,
    totalCount,
    showSortBox,
    firstButtonRef,
    secondButtonRef,
    thirdButtonRef,
    onChangeShowSortBox,
}: Props) => {
    const userAiSettingFilterState = useUserAiSettingSotre((state) => state.userAiSettingState);
    const setUserAiSettingFilterState = useUserAiSettingSotre((state) => state.setUserAiSettingState);

    return (
        <>
            <div css={rootBoxStyle}>
                <div style={{ display: "none" }} ref={buttonWrapper}>
                    <Button
                        label="전체"
                        color={userAiSettingFilterState.bidType == 0 ? "newBlue" : "gray"}
                        size="small"
                        styles={{
                            display: "none"
                        }}
                        handleClick={() => setUserAiSettingFilterState({ ...userAiSettingFilterState, bidType: 0 })}
                        buttonRef={firstButtonRef}
                    />
                    <Button
                        label="경매"
                        color={userAiSettingFilterState.bidType == 1 ? "newBlue" : "gray"}
                        size="small"
                        styles={{
                            display: "none"
                        }}
                        handleClick={() => setUserAiSettingFilterState({ ...userAiSettingFilterState, bidType: 1 })}
                        buttonRef={secondButtonRef}
                    />
                    <Button
                        label="공매"
                        color={userAiSettingFilterState.bidType == 2 ? "newBlue" : "gray"}
                        size="small"
                        styles={{
                            display: "none"
                        }}
                        handleClick={() => setUserAiSettingFilterState({ ...userAiSettingFilterState, bidType: 2 })}
                        buttonRef={thirdButtonRef}
                    />
                </div>
                <div css={subTitleBoxStyle}>
                    <div css={dataListBoxStyle}>
                        총 매물<span>{totalCount}</span>건
                    </div>
                    <div ref={sort} css={sortBoxStyle} onClick={onChangeShowSortBox}>
                        {sortTextList?.[userAiSettingFilterState.sortType]}
                        <div />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AiRecommendBottomSheetTitle;

const rootBoxStyle = css`
    width: 100%;
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;


const subTitleBoxStyle = css`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`;

const dataListBoxStyle = css`
    text-align: center;
    ${Common.textStyle.title6_R14};
    color: ${Common.colors.black};
    & > span {
        margin-left: 4px;
        margin-right: 4px;
        color: ${Common.colors.notiColor};
    }
`;

const sortBoxStyle = css`
    text-align: center;
    color: ${Common.colors.black};
    ${Common.textStyle.title6_R14}
    display: none;
    & > div {
        background: url(${ico_arrow_down_bold});
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        margin-left: 6px;
    }
`;
