export const isShowLabel = (text: string): boolean => {
    if (text === "-") {
        return false;
    }

    if (text === "") {
        return false;
    }

    if (text === undefined) {
        return false;
    }

    if (text === "0") {
        return false;
    }

    if (text === " ") {
        return false;
    }

    if (text === "0.00평" || text === "0.0㎡") {
        return false;
    }

    return true;
};
