import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { makeUseParam, purposeData } from "@src/data/itemForFilterPurpose";
import { AiItemListType } from "@src/hooks/filter/useGetAiItemList";
import { GetItemListType } from "@src/hooks/item/useGetItem";
import create from "zustand";
import { configurePersist } from "zustand-persist";
import { removeAll } from "./filterStore";
import { pickBy } from "lodash";

export type FilterParamsType = {
    type: string;
    price: string;
    loc: string[];
    use: string[];
};
export type useFilterStoreType = {
    aiFilterCount: string;
    aiFilterTotalCnt: number;
    aiFilterState: FilterParamsType;
    isLoading: boolean;
    dimVisible: boolean;
    aiFilterDataList: any[];
    next_it: string;
    setDimVisible: (visible: boolean) => void;
    setAiFilterState: (param: FilterParamsType, type?: number | undefined) => void;
    getAiFilterState: (aiFilterstateType: string) => void;
    getAiFilterCount: (aiFilterState: FilterParamsType) => void;
    // setAiFilterDataList: (data: AiItemListType[]) => void;
    getAiFilterDataList: (filter: any, next_it: string | undefined, sort_id: number | undefined) => void;
    setAiFilterDataList: (aiFilterState: FilterParamsType, sort_id: any, setting?: string) => void;
    setNextIt: (nextIt: string) => void;
    selectedSortId: number | undefined;
    setSortId: (sortId: number | undefined) => void;
    selectedSortText: string | undefined;
    setSelectedSortText: (selectedSortText: string | undefined) => void;
    isConfirm: boolean;
    setIsConfirm: (value: boolean) => void;
    deleteAiFilterState: () => void;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    aiBottomSheetRef: any;
    setAiBottomSheetRef: (aiBottomSheetRef: any) => void;
    aiSortRef: any;
    aiButtonWrapperRef: any;
    aiButton1Ref: any;
    aiButton2Ref: any;
    aiButton3Ref: any;
    setAiSortRef: (aiSortRef: any, aiButtonWrapperRef: any) => void;
    setAiButtonRef: (aiButtom1Ref: any, aiButtom2Ref: any, aiButtom3Ref: any) => void;
    isShowGuidePopup: boolean;
    setIsShowGuidePopup: (isShowGuidePopup: boolean) => void;
};

export const initialState: FilterParamsType = {
    type: "0",
    price: "",
    loc: [""],
    use: [""],
};

export const dataListInitialState: AiItemListType[] = [
    {
        item_id: "",
        bid_type: 0,
        bid_type_str: "",
        use: "",
        skp_cnt: 0,
        skp_cnt_str: "",
        image: "",
        address: "",
        mid_adrs: "",
        est_price: 0,
        est_price_str: "",
        min_price: 0,
        min_price_str: "",
        rate: "",
        status: "",
        d_day: "",
        due_date_str: "",
        due_date: 0,
        somethings: [],
        updated_at: "",
        next_it: "",
    },
];

// const { persist } = configurePersist({
//     storage: localStorage, // use `AsyncStorage` in react native
//     rootKey: "filterState", // optional, default value is `root`
// });

export const useAiFilterStore = create<useFilterStoreType>(
    // persist(
    //     {
    //         key: "aiFilterState",
    //         allowlist: ["aiFilterState"],
    //         denylist: [],
    //     },
    (set: any) =>
        ({
            aiFilterState: initialState,
            aiFilterDataList: [],
            aiFilterCount: "0",
            aiFilterTotalCnt: 0,
            isLoading: null,
            dimVisible: false,
            next_it: "",
            selectedSortId: 5,
            selectedSortText: "매각기일 가까운순",
            isConfirm: false,
            visible: false,
            aiBottomSheetRef: null,
            aiSortRef: null,
            aiButtonWrapperRef: null,
            aiButton1Ref: null,
            aiButton2Ref: null,
            aiButton3Ref: null,
            isShowGuidePopup: true,
            getAiFilterCount: async (aiFilterState: FilterParamsType) => {
                set(() => {
                    return {
                        isLoading: true,
                    };
                });

                const response = await axiosInstance.get(axiosPath.aiCount, {
                    params: {
                        filter: {
                            ...aiFilterState,
                            use: makeUseParam(aiFilterState.use)[0] === "" ? [] : makeUseParam(aiFilterState.use),
                            loc: removeAll(aiFilterState.loc)[0] === "" ? [] : removeAll(aiFilterState.loc),
                        },
                    },
                });
                const result = response.data.payload.data;

                set(() => {
                    return {
                        aiFilterCount: result.totalCnt,
                        use: makeUseParam(aiFilterState.use),
                        loc: removeAll(aiFilterState.loc),
                        type: aiFilterState.type,
                    };
                });
                set(() => {
                    return {
                        isLoading: false,
                    };
                });
            },
            setSortId: (sortId: number | undefined) => {
                set(() => {
                    return { selectedSortId: sortId };
                });
            },
            setSelectedSortText: (selectedSortText: string | undefined) => {
                set(() => {
                    return { selectedSortText: selectedSortText };
                });
            },
            setIsConfirm: (value: boolean) => {
                set(() => {
                    return { isConfirm: value };
                });
            },

            // setAiFilterDataList: (data: AiItemListType[]) => {
            //     set(() => {
            //         return { aiFilterDataList: data };
            //     });
            // },
            setNextIt: async (nextIt: string) => {
                await set(() => {
                    return { next_it: nextIt };
                });
            },
            setDimVisible: (visible: boolean) => {
                set(() => {
                    return { dimVisible: visible };
                });
            },
            setVisible: (visible: boolean) => {
                set(() => {
                    return { visible: visible };
                });
            },
            setAiBottomSheetRef: (aiBottomSheetRef: any) => {
                set(() => {
                    return { aiBottomSheetRef: aiBottomSheetRef };
                });
            },
            setAiSortRef: (aiSortRef: any, aiButtonWrapperRef: any) => {
                set(() => {
                    return { aiSortRef: aiSortRef, aiButtonWrapperRef: aiButtonWrapperRef };
                });
            },
            setAiButtonRef: (aiButton1Ref: any, aiButton2Ref: any, aiButton3Ref: any) => {
                set(() => {
                    return {
                        aiButton1Ref: aiButton1Ref,
                        aiButton2Ref: aiButton2Ref,
                        aiButton3Ref: aiButton3Ref,
                    };
                });
            },
            setIsShowGuidePopup: (isShowGuidePopup: boolean) => {
                set(() => {
                    return {
                        isShowGuidePopup: isShowGuidePopup,
                    };
                });
            },
            getAiFilterState: async (aiFilterstateType: string) => {
                const response = await axiosInstance.get(axiosPath.saveAiFilterV2);
                const result = response.data;
                const newData: any = {
                    ...result,
                    price: Number((result?.investmentPrice ?? 0) / 10000).toLocaleString(),
                    loc: result.regions ? result.regions : "",
                    use: result.categories ? result.categories : "",
                    type: result.type === "0" ? null : result.type,
                };
                const newText =
                    newData.sortType === 0
                        ? "업데이트순"
                        : newData.sortType === 1
                        ? "감정가 높은순"
                        : newData.sortType === 2
                        ? "감정가 낮은순"
                        : newData.sortType === 3
                        ? "최저가 높은순"
                        : newData.sortType === 4
                        ? "최저가 낮은순"
                        : newData.sortType === 5
                        ? "매각기일 가까운순"
                        : newData.sortType === 7
                        ? "유찰회수 많은순"
                        : newData.sortType === 8
                        ? "유찰회수 적은순"
                        : "매각기일 가까운순";
                set(() => {
                    return {
                        aiFilterState: {
                            ...newData,
                            type: newData.bidType,
                        },
                        selectedSortId: newData.sortType,
                        selectedSortText: newText,
                    };
                });
            },
            deleteAiFilterState: async () => {
                // const response = await axiosInstance.delete(axiosPath.saveAiFilter);
                const newParam = {
                    investmentPrice: 0,
                    regions: [],
                    categories: [],
                    bidType: null,
                    sortType: 5,
                };
                if (window.location.pathname.includes("ai")) {
                    const result = await axiosInstance.put(axiosPath.saveAiFilterV2, {
                        ...newParam,
                    });
                }
                set(() => {
                    return {
                        aiFilterState: initialState,
                    };
                });
            },
            getAiFilterDataList: async (aiFilterState: FilterParamsType, next_it: string, sort_id: number | undefined) => {
                const newUse = aiFilterState.use
                    ? aiFilterState.use
                          .map((u: any) => {
                              const newU =
                                  u === "allA"
                                      ? purposeData["주거용"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allB"
                                      ? purposeData["토지"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allC"
                                      ? purposeData["산업용"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allD"
                                      ? purposeData["상업/업무용"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allE"
                                      ? purposeData["기타"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u;
                              return newU;
                          })
                          .flat()
                    : "";
                const newParam: any = {
                    investmentPrice: aiFilterState.price ? Number(aiFilterState.price.replaceAll(",", "")) * 10000 : 10000,
                    regions:
                        aiFilterState.loc && aiFilterState.loc.length && aiFilterState.loc[0] !== ""
                            ? aiFilterState.loc.map((q: any) =>
                                  q.slice(q.length - 3, q.length) === "All" ? q.slice(0, q.length - 3) : q,
                              )
                            : [],
                    categories: !newUse || !newUse[0] ? [] : newUse,
                    bidType: aiFilterState.type === "0" ? null : aiFilterState.type,
                    sortType: sort_id?.toString() ? sort_id.toString() : 5,
                    limit: 20,
                    cursor: next_it,
                };
                const noEmptyParam = pickBy(newParam, (value) => {
                    return value;
                });
                // const newUri = `${axiosPath.aiItemListV2}?${new URLSearchParams(noEmptyParam)}`;
                const response = await axiosInstance.get(axiosPath.aiItemListV2, {
                    params: {
                        ...noEmptyParam,
                    },
                });
                // const response = await axiosInstance.get(axiosPath.aiItemList, {
                //     params: {
                //         filter: {
                //             ...aiFilterState,
                //             use: makeUseParam(aiFilterState.use)[0] === "" ? [] : makeUseParam(aiFilterState.use),
                //             loc: removeAll(aiFilterState.loc)[0] === "" ? [] : removeAll(aiFilterState.loc),
                //             type: aiFilterState.type,
                //         },
                //         next_it: next_it,
                //         sort: sort_id,
                //     },
                // });

                const result = response.data.list;

                set((storeState: any) => {
                    return {
                        aiFilterDataList: storeState.aiFilterDataList.concat(result),
                        aiFilterCount: response.data.cursor,
                    };
                });
            },
            setAiFilterDataList: async (aiFilterState: FilterParamsType, sort_id: number | undefined, setting?: string) => {
                const newUse = aiFilterState.use
                    ? aiFilterState.use
                          .map((u: any) => {
                              const newU =
                                  u === "allA"
                                      ? purposeData["주거용"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allB"
                                      ? purposeData["토지"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allC"
                                      ? purposeData["산업용"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allD"
                                      ? purposeData["상업/업무용"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u === "allE"
                                      ? purposeData["기타"].children.slice(1, 20).map((q: any, i: number) => q.id)
                                      : u;
                              return newU;
                          })
                          .flat()
                    : "";
                const newParam: any = {
                    investmentPrice: aiFilterState.price ? Number(aiFilterState.price.replaceAll(",", "")) * 10000 : 10000,
                    regions:
                        aiFilterState.loc && aiFilterState.loc.length && aiFilterState.loc[0] !== ""
                            ? aiFilterState.loc.map((q: any) =>
                                  q.slice(q.length - 3, q.length) === "All" ? q.slice(0, q.length - 3) : q,
                              )
                            : [],
                    categories: !newUse || !newUse[0] ? [] : newUse,
                    bidType: aiFilterState.type === "0" ? null : aiFilterState.type,
                    sortType: sort_id?.toString() ? sort_id.toString() : 5,
                    limit: 20,
                };
                const noEmptyParam = pickBy(newParam, (value) => {
                    return value;
                });
                if (!aiFilterState.price) {
                    set(() => {
                        return {
                            aiFilterDataList: [],
                            aiFilterCount: "0",
                            aiFilterTotalCnt: 0,
                        };
                    });
                    return;
                }
                const response = await axiosInstance.get(axiosPath.aiItemListV2, {
                    params: {
                        ...noEmptyParam,
                    },
                });
                const result = response.data.list;
                const count = response.data.totalCount;
                // const count = response.data.totalCount.totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                if (setting === "reset") {
                    set(() => {
                        return {
                            aiFilterDataList: [],
                            aiFilterCount: "0",
                            aiFilterTotalCnt: 0,
                        };
                    });
                } else {
                    set(() => {
                        return {
                            aiFilterDataList: result,
                            aiFilterCount: response.data.cursor,
                            aiFilterTotalCnt: count,
                        };
                    });
                }
            },
            setAiFilterState: async (aiFilterState: FilterParamsType, sort_id: any /*type?: "post" | "put"*/) => {
                if (!aiFilterState.price) {
                    set(() => {
                        return {
                            aiFilterState: {
                                ...aiFilterState,
                            },
                        };
                    });
                    return;
                }
                const newPrice = aiFilterState.price ? Number(aiFilterState.price.replaceAll(",", "")) * 10000 : 10000;
                const newParam = {
                    ...aiFilterState,
                    investmentPrice: newPrice,
                    regions: aiFilterState.loc ? aiFilterState.loc : [],
                    categories: aiFilterState.use ? aiFilterState.use : [],
                    bidType: aiFilterState.type === "0" ? null : aiFilterState.type,
                    sortType: sort_id?.toString() ? sort_id : 5,
                };
                const newText =
                    sort_id === 0
                        ? "업데이트순"
                        : sort_id === 1
                        ? "감정가 높은순"
                        : sort_id === 2
                        ? "감정가 낮은순"
                        : sort_id === 3
                        ? "최저가 높은순"
                        : sort_id === 4
                        ? "최저가 낮은순"
                        : sort_id === 5
                        ? "매각기일 가까운순"
                        : sort_id === 7
                        ? "유찰회수 많은순"
                        : sort_id === 8
                        ? "유찰회수 적은순"
                        : "매각기일 가까운순";
                if (window.location.pathname.includes("ai")) {
                    const result = await axiosInstance.put(axiosPath.saveAiFilterV2, {
                        ...newParam,
                    });
                }

                set(() => {
                    return {
                        aiFilterState: {
                            ...aiFilterState,
                            use: newParam.use,
                            loc: newParam.loc,
                            type: newParam.type,
                        },
                        selectedSortId: sort_id,
                        selectedSortText: newText,
                    };
                });
            },
        } as any),
    // ) as any,
);
