import fetchJsonp from "fetch-jsonp";

interface IAddrParams {
    position: {
        lat: number;
        lng: number;
    };
    bbox?: any[];
    pnu?: string;
}

const V_WORLD_DEFAULT = {
    key: process.env.REACT_APP_V_WORLD_KEY,
    domain: process.env.REACT_APP_V_WORLD_DOMAIN,
    request: "GetFeature",
    srsname: "EPSG:4326",
    output: "text/javascript",
    maxfeatures: 1000,
    format_options: "parseResponse",
};

export const fetchAddress = async (params: IAddrParams) => {
    const url = process.env.REACT_APP_V_WORLD_API_URL;
    const position = params.position;

    const newParam: any = {
        ...V_WORLD_DEFAULT,
        bbox: `${position.lng},${position.lat},${position.lng},${position.lat}`,
        typename: "lp_pa_cbnd_bubun",
    };
    const res = await fetchJsonp(`${url}?${new URLSearchParams(newParam)}`, {
        jsonpCallbackFunction: "parseResponse",
        timeout: 3000,
    });

    const { features } = await res.json();

    return features[0]?.properties?.addr;
};
