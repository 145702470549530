import create from "zustand";
import { IStateLandFilter, defaultStateLandParams } from "@src/hooks/stateLand/useGetStateLand";

interface IFilterStoreType {
    stateLandFilter: IStateLandFilter;
    setStateLandFilter: (filterState: IStateLandFilter) => void;
}

interface IOpenStateLandFilterType {
    startStateLand: boolean; 
    setStartStateLand: any;
}

export const useStateLandFilterStore = create<IFilterStoreType>(
    (set: any) =>
        ({
            stateLandFilter: defaultStateLandParams,
            setStateLandFilter: async (filterState: any) => {
                set(() => {
                    return {
                        stateLandFilter: {
                            ...filterState,
                        },
                    };
                });
            },
        } as any),
);

export const useOpenStateLandFilterStore = create<IOpenStateLandFilterType>( 
    (set: any) =>
        ({
            startStateLand: false,
            setStartStateLand: async (status: boolean) => {
                set(() => {
                    return {
                        startStateLand: status,
                    };
                });
            },
        } as any),
);

