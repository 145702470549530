import React, { useState } from "react";
import { css } from "@emotion/react";
import { ISubscribeRefundAmount, ISubsribeInfo } from "@src/hooks/subscribe/useSubscribe";
import { differenceInDays, format, set } from "date-fns";
import { transferToNative } from "@src/lib/WebAppBridge";
import ToastMessage from "@src/components/filter/ToastMessage";

interface Props {
    data?: ISubscribeRefundAmount;
    subsInfo?: ISubsribeInfo;
    showToast: boolean;
    onCloseToast: (val: boolean) => void;
    onSubmitReserveRemoveSubs: () => void;
}

const UnsubscribeReservation: React.FC<Props> = ({ data, subsInfo, showToast, onCloseToast, onSubmitReserveRemoveSubs }) => {
    return (
        <>
            <div css={rootBoxStyle}>
                <div css={subsInfoBoxStyle}>
                    <p className="titleText">⏱ 내 잔여 구독기간</p>
                    <p className="contentText">
                        {format(new Date(data?.expiredAt ?? 0), "yy년 MM월 dd일까지")}
                        <br />(
                        {differenceInDays(new Date(data?.expiredAt ?? 0), set(new Date(), { hours: 0, minutes: 0, seconds: 0 }))}
                        일 남음)
                    </p>
                </div>
                <div css={descBoxStyle}>
                    <p>🤚 잠시만요</p>
                    <p>
                        <span className="discountText">{data?.benefitPrice?.toLocaleString()}원 할인혜택</span>을 받고있어요
                    </p>
                </div>
                <div css={priceInfoBoxStyle}>
                    <div className="productPrice">
                        <p>정가</p>
                        <p>{data?.productPrice?.toLocaleString()}원</p>
                    </div>
                    <div className="discountPrice">
                        <p>할인 금액</p>
                        <p>{data?.benefitPrice?.toLocaleString()}원</p>
                    </div>
                    <div className="paidPrice">
                        <p>내 구독 결제금액</p>
                        <p>{data?.paidPrice?.toLocaleString()}원</p>
                    </div>
                </div>
                <div css={footerBoxStyle}>
                    <p>
                        지금 해지 예약을 하시면
                        <br />
                        <span className="dueDateText">{format(new Date(data?.expiredAt ?? 0), "yyyy년 MM월 dd일까지")}</span>이용
                        가능하며
                    </p>
                    <p>
                        이후엔 미스고 핵심기능
                        <br />
                        이용이 제한됩니다.
                    </p>
                    <p className="strongText">그래도 해지 예약하시겠습니까?</p>
                </div>
                <button css={containButtonStyle} onClick={() => transferToNative(false, "closeUnsubscribeView")}>
                    유지하기
                </button>
                <p css={unSubsTextButtonStyle} onClick={onSubmitReserveRemoveSubs}>
                    혜택 포기하고 해지 예약하기
                </p>
            </div>
            <ToastMessage
                visible={showToast}
                setToastVisible={() => {
                    onCloseToast(false);
                }}
                addStyles={{ top: "unset", bottom: "50px" }}
            >
                정기구독 해지 예약이 완료되었습니다
            </ToastMessage>
        </>
    );
};

export default UnsubscribeReservation;

const rootBoxStyle = css`
    padding: 0 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    gap: 24px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 24px;
    height: 100dvh;
    height: 100vh;
`;

const subsInfoBoxStyle = css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #dff0ff;
    padding: 14px;
    width: 100%;
    .titleText {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
    }
    .contentText {
        text-align: right;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
    }
`;

const descBoxStyle = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    .discountText {
        color: #0047ff;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
    .penaltyText {
        color: #f00000;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
`;

const priceInfoBoxStyle = css`
    border-radius: 8px;
    background-color: #f6faff;
    padding: 14px;
    gap: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .productPrice {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
    }
    .discountPrice {
        color: #0047ff;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        border-bottom: 0.6px solid #0c002c;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px 14px;
    }
    .paidPrice {
        border-radius: 8px;
        background-color: #0047ff;
        padding: 14px 24px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .penaltyPrice {
        color: #f00000;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
    }
`;

const footerBoxStyle = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    .strongText {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
    .dueDateText {
        border-radius: 4px;
        border: 1px dashed #f00000;
        background-color: rgba(255, 0, 0, 0.1);
        padding: 0 4px;
        margin-right: 4px;
    }
`;

const containButtonStyle = css`
    margin-top: 10px;
    padding: 14px;
    border-radius: 14px;
    background-color: #2275ee;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
`;

const unSubsTextButtonStyle = css`
    color: #8d8d8d;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-position: under;
    cursor: pointer;
    padding-bottom: 78px;
`;
