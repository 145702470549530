import styled from "@emotion/styled";
import React, { Dispatch, SetStateAction, useEffect } from "react";
// import CurriculumItem from "./CurriculumItem";
import title from "../../../assets/curriculumTitle.png";
import { Common } from "@src/styles/Common";
import SugangCurriculumItem from "./SugangCurriculumItem";
import { CampusSugangCurriculumDataDto } from "@src/hooks/campus/dto/CampusSugang.dto";

interface SugangCurriculumProps {
    curriculumData: CampusSugangCurriculumDataDto[];
    recentMainOrder?: number;
    videoData: { videoUrl: string; no: number; subOrder: number; userViewedTime: number };
    setVideoData: Dispatch<SetStateAction<{ videoUrl: string; no: number; subOrder: number; userViewedTime: number }>>;
}

const SugangCurriculum = ({ curriculumData, recentMainOrder, videoData, setVideoData }: SugangCurriculumProps) => {
    return (
        <>
            <StyledCurriculum>
                <div className="contents">
                    {curriculumData.map((data: CampusSugangCurriculumDataDto, idx: number) => (
                        <SugangCurriculumItem
                            key={idx}
                            item={data}
                            recentMainOrder={recentMainOrder}
                            videoData={videoData}
                            setVideoData={setVideoData}
                        />
                    ))}
                </div>
            </StyledCurriculum>
        </>
    );
};

export default SugangCurriculum;

const StyledCurriculum = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

    .contents {
        padding: 0px 14px 14px 14px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        height: calc(calc(var(--vh, 1vh) * 100) - 424px);

        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
