// function convertNumberToKorean(number) {
//     let inputNumber = number;
//     let isNegative = false;

//     if (number < 0) {
//         inputNumber = -number;
//         isNegative = true;
//     }

//     let unitWords = ["", "만", "억", "조", "경"];
//     let splitUnit = 10000;
//     let splitCount = unitWords.length;
//     let resultArray = [];
//     let resultString = "";

//     for (let i = 0; i < splitCount; i++) {
//         let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
//         unitResult = Math.floor(unitResult);
//         if (unitResult > 0) {
//             resultArray[i] = unitResult;
//         }
//     }

//     for (let i = 0; i < resultArray.length; i++) {
//         if (!resultArray[i]) continue;
//         resultString = String(Number(resultArray[i]).toLocaleString()) + unitWords[i] + resultString;
//     }

//     return isNegative ? '-' + resultString : resultString;
// }
// export default convertNumberToKorean;

function convertNumberToKorean(number) {
    if (number === 0) {
        return '0';
    }

    let inputNumber = number;
    let isNegative = false;

    if (number < 0) {
        inputNumber = -number;
        isNegative = true;
    }

    let unitWords = ["", "만", "억", "조", "경"];
    let splitUnit = 10000;
    let splitCount = unitWords.length;
    let resultArray = [];
    let resultString = "";

    for (let i = 0; i < splitCount; i++) {
        let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0) {
            resultArray[i] = unitResult;
        }
    }

    for (let i = 0; i < resultArray.length; i++) {
        if (!resultArray[i]) continue;
        resultString = String(Number(resultArray[i]).toLocaleString()) + unitWords[i] + resultString;
    }

    return isNegative ? '-' + resultString : resultString;
}

export function convertNumberToKoreanWithThousand(number) {
    const million = Math.floor(number / 10000);
    const remainder = number % 10000;

    const thousand = Math.floor(remainder / 1000);
    const finalRemainder = remainder % 1000;

    let result = '';
    if (million > 0) {
        result += `${million}만`;
    }
    if (thousand > 0) {
        result += `${thousand}천`;
    }
    if (finalRemainder > 0) {
        result += `${finalRemainder}`;
    }

    return result;
}

export default convertNumberToKorean;