import styled from "@emotion/styled";
import React from "react";
import { Common } from "@src/styles/Common.js";
import { SelectedItemFilter } from "@src/hooks/filter/useSelectedFilter";
import ico_arrow_down_bold from "@src/assets/ico_arrow_down_bold.svg";

type ContentHeaderPropsType = {
    title: string;
    buttonText?: string;
    state?: SelectedItemFilter[];
    type?: "uniary";
    onClick?: () => void;
};

const ContentHeader = ({ title, buttonText, onClick, state, type }: ContentHeaderPropsType) => {
    return (
        <>
            {type === "uniary" && <div style={{ height: 12, background: "#ECEFF4" }} />}
            <ContentHeaderWrapper>
                <h3>{title}</h3>
                {type === "uniary" ? (
                    <img src={ico_arrow_down_bold} onClick={onClick} />
                ) : buttonText ? (
                    <Button onClick={onClick} isActive={state && state[0].isActive ? true : false}>
                        <span>{buttonText}</span>
                    </Button>
                ) : (
                    // <Button onClick={onClick} isActive={state && state[0].isActive ? true : false}>
                    //     <span>{buttonText}</span>
                    // </Button>
                    <></>
                )}
            </ContentHeaderWrapper>
        </>
    );
};

export default ContentHeader;

const Button = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    height: 32px;
    width: 53px;
    border-radius: 24px;
    padding: 4px 2px 4px 2px;
    background: ${({ isActive }) => (isActive ? Common.colors.aucMarkerColor : "#fdfafa")};
    cursor: pointer;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isActive }) => (isActive ? "#fdfafa" : "black")};
    }
`;

const ContentHeaderWrapper = styled.div`
    display: flex;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    margin-top: 12px;

    h3 {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
    }
`;

const DisalbeButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 55px;
    border-radius: 8px;
    background: ${Common.colors.grey02};

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.greyPrimary};
    }
`;
