import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { createJWTToken } from "@src/api/axiosConfig";
import { useQuery } from "react-query";

const ENV_URL: any = process.env.REACT_APP_REGION_API;

export interface IRegion {
    centerLat: number;
    centerLon: number;
    cortarName: string;
    cortarNo: string;
    cortarType: string;
}

export const useFindLocation = async (param: string) => {
    const response = await axiosAsync
        .get(`${ENV_URL}/regions/list?cortarNo=${param}`)
        .then((res) => {
            return res?.data?.regionList;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return err;
        });
    return response;
};

export const useGetLocation = async (param: string) => {
    const tokenValue = localStorage.getItem("accessToken");
    const response = await fetch(`${ENV_URL}/regions/list?cortarNo=${param}`, {
        method: "get",
        headers: { "Content-Type": "application/json", Authorization: createJWTToken(tokenValue) },
        cache: "force-cache",
        keepalive: true,
    });
    const newData = await response.json();
    return newData?.regionList;
};
