import styled from "@emotion/styled";
import React from "react";

const AiTitle = ({ children }: any) => {
    return <AiTitleWrapper>{children}</AiTitleWrapper>;
};

export default AiTitle;

const AiTitleWrapper = styled.div`
    h2 {
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 12px;
        margin-top: 16px;
    }

    .color-text {
        color: #2d54df;
    }

    p {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0px;
        color: #505050;
        text-align: left;
    }
`;
