import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const fetchData = async (params?: any): Promise<string[]> => {
    const response = await axiosInstance.get(
        `${axiosPath.searchedList}?name=${params.name}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload.data[0].value.split(";") || [];

    return responseData;
};
const GetCallSearchedList = async (params: any) => {
    return await fetchData(params);
};

export type GetCallSearchedListResponseType = {
    errorCode: number;
    list: getCallSearchedListTypes[];
    total: number;
};

export type getCallSearchedListTypes = {
    user_no?: number;
    name: string;
    value: string;
};
export { GetCallSearchedList };
