import React from "react";

import { dialogTitleStyle, dialogContentStyle } from "./styles";

const RefundsToStore = () => {
    return (
        <>
            <p css={dialogTitleStyle}>취소 불가</p>
            <p css={dialogContentStyle}>
                인앱 스토어 결제의 경우
                <br />
                취소/환불은 APP Store, Google Play
                <br />
                스토어 앱에서 가능합니다
            </p>
        </>
    );
};

export default RefundsToStore;
