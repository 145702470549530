import styled from "@emotion/styled";
import React, { useEffect } from "react";
import CurriculumItem from "./CurriculumItem";
import title from "../../../assets/curriculumTitle.png";
import { Common } from "@src/styles/Common";
import {
    CampusDetailCurriculumDataDto,
    CampusDetailCurriculumItemDto,
    CampusDetailCurriculumListDto,
} from "@src/hooks/campus/dto/CampusDetail.dto";

interface CurriculumProps {
    data?: CampusDetailCurriculumListDto;
}

const Curriculum = ({ data }: CurriculumProps) => {
    const curriculumData: CampusDetailCurriculumDataDto[] = data?.list.reduce(
        (q: any, item: CampusDetailCurriculumItemDto, idx: any) => {
            if (q.findIndex((w: any) => w.mainOrder === item.mainOrder) === -1) {
                const newData: any = {
                    mainOrder: item.mainOrder,
                    sub: data.list
                        .filter((w: any) => w.mainOrder === item.mainOrder)
                        .sort((e: any, r: any): any => e.subOrder - r.subOrder),
                };
                q.push(newData);
            }
            return q;
        },
        [],
    );

    const date = new Date(0);
    data && date.setSeconds(data.totalLength);
    const hhmmssFormat = date.toISOString().substr(11, 8).split(":");

    return (
        <>
            <StyledCurriculum>
                <div className="title">
                    <img src={title} alt="" />
                </div>
                <div className="subTitle">
                    <span>🎈총 {data?.totalCnt}강</span>
                    {Number(hhmmssFormat[0])
                        ? `(${Number(hhmmssFormat[0])}시간 ${Number(hhmmssFormat[1])}분)`
                        : `(${Number(hhmmssFormat[1])}분 ${Number(hhmmssFormat[2])}초)`}
                </div>
                <div className="contents">
                    {curriculumData.map((data: CampusDetailCurriculumDataDto, idx: number) => (
                        <CurriculumItem key={idx} item={data} />
                    ))}
                </div>
            </StyledCurriculum>
        </>
    );
};

export default Curriculum;

const StyledCurriculum = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin-top: 46px;
    .title {
        width: 100%;
        display: flex;
        justify-content: center;
        & > img {
            width: 105px;
            height: 36px;
        }
    }
    .subTitle {
        margin-top: 22px;
        display: flex;
        justify-content: center;
        & > span {
            font-weight: 700;
            font-size: 18px;
            color: ${Common.colors.normalTextColor};
            margin-right: 4px;
        }
        font-weight: 400;
        font-size: 16px;
        color: ${Common.colors.darkGrey04};
    }
    .contents {
        margin-top: 14px;
        padding: 14px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
    }
`;
