import { axiosInstance } from "@src/api/axiosConfig";

const { naver } = window;
const naverMaps = naver.maps;

const fetchData = async (coords: any): Promise<any> => {
    let { _lat: lat, _lng: lng } = coords;
    const url = process.env.REACT_APP_NAVER_MAP_API_URL_MAP_REVERSE;
    const response = await axiosInstance.get(
        `${url}?coords=${lng},${lat}&orders=roadaddr&output=json&X-NCP-APIGW-API-KEY-ID=v3uhenngxa`,
    );
    if (response.status !== naverMaps.Service.Status.OK) return "";

    let results = response?.data?.results[0];
    if (!results) return "일치하는 도로명 주소가 없습니다";
    const { land, region } = results;
    const { number1, number2, name } = land;
    const { area1, area2 } = region;
    const roadAddr = number2
        ? `${area1.name} ${area2.name} ${name} ${number1}-${number2}`
        : `${area1.name} ${area2.name} ${name} ${number1}`;
    return roadAddr;
};

const useGetRoadAddressFromCoords = (coords: any): Promise<any> => {
    return fetchData(coords);
};

export default useGetRoadAddressFromCoords;
