import { css } from "@emotion/react";
import React from "react";

interface Props {
    title: string;
    button: { name: string; value: number };
    secondButton?: { name: string; value: number };
    selectedType: number;
    onChangeSortType: (val: number) => void;
}

const StoreGoStopSortTypeContent = ({ title, button, selectedType, secondButton, onChangeSortType }: Props) => {
    return (
        <>
            <div css={rootBoxStyle}>
                <div className="title">{title}</div>
                <button css={buttonStyle(button.value === selectedType)} onClick={() => onChangeSortType(button.value)}>
                    {button.name}
                </button>
                {secondButton && (
                    <button
                        css={buttonStyle(secondButton?.value === selectedType)}
                        onClick={() => onChangeSortType(secondButton?.value)}
                    >
                        {secondButton?.name}
                    </button>
                )}
            </div>
        </>
    );
};

export default StoreGoStopSortTypeContent;

const rootBoxStyle = css`
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    & > .title {
        min-width: 64px;
        width: 64px;
        white-space: nowrap;
        color: #0c002c;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const buttonStyle = (active: boolean) => css`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${active ? "#2275ee" : "#FDFAFA"};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;
