import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { css } from "@emotion/react";
import AiTitle from "@src/components/aiFilter/AiTitle";
import { Common } from "@src/styles/Common";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import { onChangePriceToKorean } from "@src/util/onChangePriceToKorean";

interface Props {
    price: any;
    onChangePrice: (key: string, val: any) => void;
    handleSubmit: any;
}

const AiRecommendFirstStep = ({ price, onChangePrice, handleSubmit }: Props) => {
    const inputRef = useRef<any>(null);
    const { pathname } = useLocation();

    const onChangeValue = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            const newVal = evt.target.value.replaceAll(',', '');
            if (isNaN(Number(newVal)) || newVal.length > 8) {
                return;
            }
            onChangePrice('investmentPrice', Number(newVal) * 10000);
        },
        [price],
    );

    const onChangePriceToKorean = (val: number) => {
        const check = 10000;
        const newVal = val / check;
        const unitWords = ['조', '억', '만'];
        const maxLength = Math.ceil(newVal.toString().length / 4);
        const result = unitWords.map((word, idx) => {
            const newIndex = idx === 2 ? 0 : !idx ? 2 : idx;
            const rest =
                maxLength > newIndex && Math.floor((newVal % Math.pow(check, newIndex + 1)) / Math.pow(check, newIndex))
                    ? `${Math.floor((newVal % Math.pow(check, newIndex + 1)) / Math.pow(check, newIndex))}${word}`
                    : '';
            return `${rest}`;
        });
        return result.join('');
    };

    return (
        <div css={rootBoxStyle}>
            <AiTitle>
                {pathname.includes("small") ? (
                    <h2>
                        <span className="color-text">보유 현금</span>을 입력해주세요
                    </h2>
                ) : (
                    <h2>
                        <span className="color-text">투자금</span>을 입력해주세요
                    </h2>
                )}
                <p>
                    소액도 상관없어요. 현재 여유자금을 입력하면 <br />
                    최적의 경공매 매물을 찾아드려요
                </p>
            </AiTitle>
            <div css={inputBoxStyle(!!price)}>
                <input
                    onChange={onChangeValue}
                    value={price ? (price / 10000).toLocaleString() : ''}
                    placeholder="Ex) 2000"
                    type="tel"
                    ref={inputRef}
                />
                <span>만원</span>
            </div>
            <div css={koreanPriceBoxStyle}>
                {price
                    ? `${onChangePriceToKorean(price)} 원`
                    : pathname.includes("small")
                        ? '투자금을 입력해주세요'
                        : '보유 현금을 입력해주세요'}
            </div>
        </div>
    );
};

export default AiRecommendFirstStep;

const rootBoxStyle = css`
    padding: 0px 14px;
`;

const inputBoxStyle = (checkInput: boolean) => css`
    position: relative;
    margin-top: 40px;
    input {
        width: 100%;
        background: ${checkInput ? "#FDFAFA" : "#FFFFFF"};
        padding: 14px 60px 14px 14px;
        border: ${checkInput ? "1px solid #0C002C" : "1px solid #B1AEB8"};
        border-radius: 14px;
        color: ${Common.colors.normalTextColor};
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: right;
    }
    input:focus {
        outline: none;
    }

    span {
        position: absolute;
        right: 14px;
        font-weight: 600;
        top: calc(50% - 8px);
        font-size: 18px;
    }
`;

const koreanPriceBoxStyle = css`
    display: flex;
    justify-content: flex-end;
    padding-right: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 8px;
    color: ${Common.colors.navyPrimary};
`;
