import React from "react";

import { transferToNative } from "@src/lib/WebAppBridge";
import { checkDevice } from "@src/components/deungi/common/utils";
import PlusIcon from "@src/assets/expertLocalAdPlus.svg";
import ArrowIcon from "@src/assets/expertArrow.svg";

import * as style from "./styles";

interface SubsInfoProps {
    title: string;
    price: number;
}

const SubsInfo = ({ title, price }: SubsInfoProps) => {
    return (
        <article css={style.subsInfoContainer}>
            <div css={style.subsInfoTitle}>{title}</div>
            <span css={style.subsInfoPrice}>{price.toLocaleString()}원</span>
        </article>
    );
};

const IntroductionPopup = () => {
    const deviceType = checkDevice();

    const subsList = [
        {
            id: 1,
            title: "베이직 1개월 구독권",
            price: {
                android: 110_000,
                ios: 110_000,
                other: 110_000,
            },
        },
        {
            id: 2,
            title: "지역광고권 2개월",
            price: {
                android: 660_000,
                ios: 720_000,
                other: 660_000,
            },
        },
    ];

    const onClick = () => {
        transferToNative("", "moveToExpertRegisterView");
    };

    return (
        <>
            <main css={style.mainContainer}>
                <section css={style.titleSectionContainer}>
                    <h4>경매대행 등록 이벤트</h4>
                    <h6>
                        <span>지금 경매대행 등록 시,</span>
                        <br />
                        <span className="highlight">베이직 구독권 & 지역광고권 지급!</span>
                    </h6>
                </section>
                <section css={style.eventInfoSectionContainer}>
                    {subsList.map(({ id, title, price }, index) => (
                        <React.Fragment key={id.toString()}>
                            <SubsInfo title={title} price={price[deviceType]} />
                            {index < subsList.length - 1 && (
                                <div className="plus">
                                    <img src={PlusIcon} alt="plus" />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                    <div className="arrow">
                        <img src={ArrowIcon} alt="arrow" />
                    </div>
                    <p className="price_result">0원</p>
                    <p className="warn">이미 구독 회원이라면, 구독 기간 이후 3개월 연장되어요!</p>
                </section>
                <section>
                    <p css={style.desc}>
                        지역광고권으로 설정한 지역의 모든 매물에서
                        <br /> 전문가님의 프로필을 광고할 수 있어요!
                    </p>
                </section>
            </main>
            <button onClick={onClick} css={style.registrationButton}>
                경매대행 등록하기
            </button>
        </>
    );
};

export default IntroductionPopup;
