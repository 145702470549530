import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";

export const useFindOneRoadPlanSubs = (enabled: boolean = false) => {
    return useQuery<IUserRoadExpansionSubsInfo>(
        [
            "roadPlanSubs",
            typeof window !== "undefined" && localStorage?.getItem("userSession")
                ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                : "",
        ],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.userRoadPlanPath)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled },
    );
};

interface IUserRoadExpansionSubsInfo {
    list: {
        no?: number;
        pnus?: number[];
        pnusToString?: string;
        isActive?: boolean;
        isSubs?: boolean;
        expiredAt?: string;
        firstUsedAt?: string;
        productName?: string;
    };
    totalCnt: number;
    allPnus: number[] | null;
    allPnusToString: string;
    notSupportedPnus: number[] | null;
    releasedPnus: number[] | null;
    releasedPnusToString: string;
    isActive: boolean;
}
