import { BOTTOM_SHEET_HEIGHT, MAX_Y, MIN_Y } from "@src/components/aiFilter/Constants";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import { useRef, useEffect, useState } from "react";
import { useGetAiItemList } from "./useGetAiItemList";

interface AiBottomSheetMetrics {
    touchStart: {
        sheetY: number; // touchstart에서 BottomSheet의 최상단 모서리의 Y값
        touchY: number; // touchstart에서 터치 포인트의 Y값
    };
    touchMove: {
        prevTouchY?: number; // 다음 touchmove 이벤트 핸들러에서 필요한 터치 포인트 Y값을 저장
        movingDirection: "none" | "down" | "up"; // 유저가 터치를 움직이고 있는 방향
    };
    isContentAreaTouched: boolean; // 컨텐츠 영역을 터치하고 있음을 기록
}

export function useAiBottomSheet() {
    const sheetRef = useRef() as any;
    const content = useRef() as any;
    const buttonWrapper = useRef() as any;
    const sort = useRef() as any;
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const buttonRef1 = useRef() as any;
    const buttonRef2 = useRef() as any;
    const buttonRef3 = useRef() as any;
    const [checkShow, setCheckShow] = useState<boolean>(false);

    const metrics = useRef<AiBottomSheetMetrics>({
        touchStart: {
            sheetY: 0,
            touchY: 0,
        },
        touchMove: {
            prevTouchY: 0,
            movingDirection: "none",
        },
        isContentAreaTouched: false,
    });

    // Touch Event 핸들러들을 등록한다.
    useEffect(() => {
        const canUserMoveBottomSheet = () => {
            const { touchMove, isContentAreaTouched } = metrics.current;

            if (!isContentAreaTouched) {
                return true;
            }

            if (Math.round(sheetRef.current.getBoundingClientRect().y) !== MIN_Y) {
                return true;
            }

            if (touchMove.movingDirection === "down") {
                return content.current.scrollTop <= 0;
            }

            return false;
        };

        const handleTouchStart = (e: TouchEvent) => {
            const { touchStart, touchMove } = metrics.current;

            touchStart.sheetY = sheetRef.current.getBoundingClientRect().y;
            touchStart.touchY = e.touches[0].clientY;
            touchMove.prevTouchY = e.touches[0].clientY;
            // if (canUserMoveBottomSheet()) {
            //     buttonWrapper.current?.style.setProperty("display", "none");
            //     sort.current?.style.setProperty("display", "none");
            // }
            if (sheetRef.current.getBoundingClientRect().y === MAX_Y) {
                setIsTouched(false);
            } else {
                setIsTouched(true);
            }

            // buttonWrapper.current?.style.setProperty("opacity", "0");
            // sort.current?.style.setProperty("opacity", "0");
        };

        const handleTouchMove = (e: TouchEvent) => {
            const { touchStart, touchMove } = metrics.current;
            const currentTouch = e.touches[0];

            if (touchMove.prevTouchY === undefined) {
                touchMove.prevTouchY = touchStart.touchY;
            }

            if (touchMove.prevTouchY < currentTouch.clientY) {
                touchMove.movingDirection = "down";
            }

            if (touchMove.prevTouchY > currentTouch.clientY) {
                touchMove.movingDirection = "up";
            }

            // 터치 시작점에서부터 현재 터치 포인트까지의 변화된 y값
            // const touchOffset = currentTouch.clientY - touchStart.touchY;
            // let nextSheetY = touchStart.sheetY + touchOffset;

            // if (nextSheetY <= 252) {
            //     nextSheetY = 252;
            // }

            // if (nextSheetY >= MAX_Y) {
            //     nextSheetY = MAX_Y;
            // }

            // // sheet 위치 갱신.
            // sheet.current.style.setProperty("transform", `translateY(${nextSheetY - MAX_Y}px)`);
            if (canUserMoveBottomSheet()) {
                // content에서 scroll이 발생하는 것을 막습니다.
                e.preventDefault();

                // 터치 시작점에서부터 현재 터치 포인트까지의 변화된 y값
                const touchOffset = currentTouch.clientY - touchStart.touchY;
                let nextSheetY = touchStart.sheetY + touchOffset;

                // nextSheetY 는 MIN_Y와 MAX_Y 사이의 값으로 clamp 되어야 한다
                if (nextSheetY <= MIN_Y) {
                    nextSheetY = MIN_Y;
                    // buttonWrapper.current?.style.setProperty("display", "flex");
                    // sort.current?.style.setProperty("display", "flex");
                    // buttonWrapper.current?.style.setProperty("opacity", "1");
                    // sort.current?.style.setProperty("opacity", "1");
                }

                if (nextSheetY >= MAX_Y) {
                    nextSheetY = MAX_Y;
                }

                // sheet 위치 갱신.
                sheetRef.current.style.setProperty("transform", `translateY(${nextSheetY - MAX_Y}px)`);

                [buttonWrapper.current.children].map((item: any, idx) => {
                    // item[0].current.style.fontSize = "0px";
                });

                const a = 1.84 - sheetRef.current.getBoundingClientRect().y / MIN_Y;
                const b = 42 * a;

                buttonWrapper.current.style.height = `${b}px`;
                buttonWrapper.current.style.opacity = `0`;
                sort.current.style.display = "none";
                setCheckShow(false);

                // buttonRef1.current.style.padding = `6px 12px`;
                // buttonRef2.current.style.padding = `6px 12px`;
                // buttonRef3.current.style.padding = `6px 12px`;
            } else {
                // 컨텐츠를 스크롤하는 동안에는 body가 스크롤되는 것을 막습니다
                document.body.style.overflowY = "hidden";
            }
        };

        const handleTouchEnd = (e: TouchEvent) => {
            // document.body.style.overflowY = "auto";
            const { touchMove } = metrics.current;

            // Snap Animation
            const currentSheetY = Math.round(sheetRef.current?.getBoundingClientRect().y);
            if (currentSheetY !== MIN_Y) {
                if ((touchMove.movingDirection === "down" && sheetRef, sort, buttonWrapper, buttonRef1, buttonRef2, buttonRef3)) {
                    sheetRef.current?.style.setProperty("transform", "translateY(0)");
                    sort.current.style.display = "none";
                    setCheckShow(false);
                    buttonWrapper.current.style.height = `0px`;
                    buttonWrapper.current.style.height = `none`;
                    buttonWrapper.current.style.opacity = `0`;
                    buttonRef1.current.style.padding = `0px`;
                    buttonRef2.current.style.padding = `0px`;
                    buttonRef3.current.style.padding = `0px`;
                    buttonRef1.current.style.fontSize = `0px`;
                    buttonRef2.current.style.fontSize = `0px`;
                    buttonRef3.current.style.fontSize = `0px`;
                    buttonRef1.current.style.border = `0px`;
                    buttonRef2.current.style.border = `0px`;
                    buttonRef3.current.style.border = `0px`;
                }

                if (touchMove.movingDirection === "up") {
                    sheetRef.current?.style.setProperty("transform", `translateY(${MIN_Y - MAX_Y + 90}px)`);
                    sort.current.style.display = "flex";
                    setCheckShow(true);
                    buttonWrapper.current.style.height = `42px`;
                    buttonWrapper.current.style.opacity = `1`;
                    // buttonRef1.current.style.height = `42px`;
                    // buttonRef2.current.style.height = `42px`;
                    // buttonRef3.current.style.height = `42px`;
                    buttonRef1.current.style.padding = `6px 12px`;
                    buttonRef2.current.style.padding = `6px 12px`;
                    buttonRef3.current.style.padding = `6px 12px`;
                    buttonRef1.current.style.fontSize = `16px`;
                    buttonRef2.current.style.fontSize = `16px`;
                    buttonRef3.current.style.fontSize = `16px`;
                    buttonRef1.current.style.border = `1.5px solid transparent`;
                    buttonRef2.current.style.border = `1.5px solid transparent`;
                    buttonRef3.current.style.border = `1.5px solid transparent`;
                }
            } else {
                buttonWrapper.current.style.opacity = `1`;
                buttonWrapper.current.style.height = `42px`;
                sort.current.style.display = "flex";
                setCheckShow(true);
                // buttonRef1.current.style.height = `42px`;
                // buttonRef2.current.style.height = `42px`;
                // buttonRef3.current.style.height = `42px`;
                buttonRef1.current.style.padding = `6px 12px`;
                buttonRef2.current.style.padding = `6px 12px`;
                buttonRef3.current.style.padding = `6px 12px`;
                buttonRef1.current.style.fontSize = `16px`;
                buttonRef2.current.style.fontSize = `16px`;
                buttonRef3.current.style.fontSize = `16px`;
                buttonRef1.current.style.border = `1.5px solid transparent`;
                buttonRef2.current.style.border = `1.5px solid transparent`;
                buttonRef3.current.style.border = `1.5px solid transparent`;
            }

            // metrics 초기화.
            metrics.current = {
                touchStart: {
                    sheetY: 0,
                    touchY: 0,
                },
                touchMove: {
                    prevTouchY: 0,
                    movingDirection: "none",
                },
                isContentAreaTouched: false,
            };
        };

        sheetRef.current?.addEventListener("touchstart", handleTouchStart);
        sheetRef.current?.addEventListener("touchmove", handleTouchMove);
        sheetRef.current?.addEventListener("touchend", handleTouchEnd);

        return () => {
            sheetRef.current?.removeEventListener("touchstart", handleTouchStart);
            sheetRef.current?.removeEventListener("touchmove", handleTouchMove);
            sheetRef.current?.removeEventListener("touchend", handleTouchEnd);
        };
    }, [sheetRef]);
    useEffect(() => {
        const handleTouchStart = () => {
            metrics.current.isContentAreaTouched = true;
        };
        if (content.current) {
            content.current.addEventListener("touchstart", handleTouchStart);
        }

        return () => content.current?.removeEventListener("touchstart", handleTouchStart);
    }, [sheetRef]);

    const aiBottomSheetRef = useAiFilterStore((state) => state.aiBottomSheetRef);
    const aiSortRef = useAiFilterStore((state) => state.aiSortRef);
    const aiButtonWrapperRef = useAiFilterStore((state) => state.aiButtonWrapperRef);
    const aiButton1Ref = useAiFilterStore((state) => state.aiButton1Ref);
    const aiButton2Ref = useAiFilterStore((state) => state.aiButton2Ref);
    const aiButton3Ref = useAiFilterStore((state) => state.aiButton3Ref);

    const showAiBottomSheet = () => {
        aiBottomSheetRef.current?.style.setProperty("transform", `translateY(${MIN_Y - MAX_Y + 90}px)`);
        aiSortRef.current.style.display = "flex";
        aiButtonWrapperRef.current.style.height = `42px`;
        aiButtonWrapperRef.current.style.opacity = `1`;
        // buttonRef1.current.style.height = `42px`;
        // buttonRef2.current.style.height = `42px`;
        // buttonRef3.current.style.height = `42px`;
        aiButton1Ref.current.style.padding = `6px 12px`;
        aiButton2Ref.current.style.padding = `6px 12px`;
        aiButton3Ref.current.style.padding = `6px 12px`;
        aiButton1Ref.current.style.fontSize = `16px`;
        aiButton2Ref.current.style.fontSize = `16px`;
        aiButton3Ref.current.style.fontSize = `16px`;
        aiButton1Ref.current.style.border = `1.5px solid transparent`;
        aiButton2Ref.current.style.border = `1.5px solid transparent`;
        aiButton3Ref.current.style.border = `1.5px solid transparent`;
    };
    //데이터 조회

    const onScrollUp = () => {
        sheetRef.current?.style.setProperty("transform", `translateY(${MIN_Y - MAX_Y + 90}px)`);
        sort.current.style.display = "flex";
        setCheckShow(true);
        buttonWrapper.current.style.height = `42px`;
        buttonWrapper.current.style.opacity = `1`;
        // buttonRef1.current.style.height = `42px`;
        // buttonRef2.current.style.height = `42px`;
        // buttonRef3.current.style.height = `42px`;
        buttonRef1.current.style.padding = `6px 12px`;
        buttonRef2.current.style.padding = `6px 12px`;
        buttonRef3.current.style.padding = `6px 12px`;
        buttonRef1.current.style.fontSize = `16px`;
        buttonRef2.current.style.fontSize = `16px`;
        buttonRef3.current.style.fontSize = `16px`;
        buttonRef1.current.style.border = `1.5px solid transparent`;
        buttonRef2.current.style.border = `1.5px solid transparent`;
        buttonRef3.current.style.border = `1.5px solid transparent`;
    };

    const onScrollDown = () => {
        sheetRef.current?.style.setProperty("transform", "translateY(0)");
        sort.current.style.display = "none";
        setCheckShow(false);
        buttonWrapper.current.style.height = `0px`;
        buttonWrapper.current.style.height = `none`;
        buttonWrapper.current.style.opacity = `0`;
        buttonRef1.current.style.padding = `0px`;
        buttonRef2.current.style.padding = `0px`;
        buttonRef3.current.style.padding = `0px`;
        buttonRef1.current.style.fontSize = `0px`;
        buttonRef2.current.style.fontSize = `0px`;
        buttonRef3.current.style.fontSize = `0px`;
        buttonRef1.current.style.border = `0px`;
        buttonRef2.current.style.border = `0px`;
        buttonRef3.current.style.border = `0px`;
    };

    const { data, fetchData, setData, totalData, setTotalData } = useGetAiItemList();
    const [type, setType] = useState("0");
    const [price, setPrice] = useState("0");
    const [use, setUse] = useState([""]);
    const [loc, setLoc] = useState([""]);

    // useEffect(() => {
    //     const filter = { type: "0", price: "10000000000", loc: ["1100000000"], use: ["A01,A02"] };
    //     const next_it = data[data.length - 1].next_it;
    //     console.log(data[data.length - 1]);
    //     // console.log("enxt", next_it);
    //     fetchData(filter).then((res) => {
    //         setData(res);
    //         // console.log(res);
    //     });
    //     getTotalItemCount({ type: "0", price: "29000000", loc: ["1100000000"], use: ["A01", "A02"] }).then((res) => {
    //         setTotalData(res.totalCnt);
    //     });
    // }, []);

    return {
        sheetRef,
        content,
        buttonWrapper,
        sort,
        isTouched,
        data,
        totalData,
        buttonRef1,
        buttonRef2,
        buttonRef3,
        showAiBottomSheet,
        checkShow,
        onScrollUp,
        onScrollDown,
    };
}
