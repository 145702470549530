import React from "react";
import { css } from "@emotion/react";

import closeBtn from "@src/assets/ico_close.png";
import StoreGoStopIntro1 from "@src/assets/storeGoStop/storeGoStopIntro1.svg";
import StoreGoStopIntro2 from "@src/assets/storeGoStop/storeGoStopIntro2.png";
import StoreGoStopIntro3 from "@src/assets/storeGoStop/storeGoStopIntro3.png";
import StoreGoStopIntro4 from "@src/assets/storeGoStop/storeGoStopIntro4.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import Title from "@src/components/title/Title";
import { useNavigate } from "react-router-dom";

const StoreGoStop = () => {
    const navigate = useNavigate();
    return (
        <div css={rootBoxStyle}>
            <div className="titleBox">
                <Title title="상가 Go-Stop" size="large" />
                <div
                    className="backBtn"
                    onClick={() => {
                        transferToNative("done", "closeWebView");
                    }}
                >
                    <img src={closeBtn} alt="" />
                </div>
            </div>
            <div className="introBox">
                <img style={{ width: "100%" }} src={StoreGoStopIntro1} alt="" />
                <div className="introDesc">
                    <img src={StoreGoStopIntro2} alt="" />
                    <img src={StoreGoStopIntro3} alt="" />
                    <img style={{ transform: "translateX(6px)" }} src={StoreGoStopIntro4} alt="" />
                </div>
            </div>
            <div className="buttonBox">
                <button
                    onClick={() => {
                        navigate("/storeGoStopList");
                    }}
                >
                    상가 Go-Stop 시작하기
                </button>
            </div>
        </div>
    );
};

const rootBoxStyle = css`
    width: 100%;
    height: 100vh;
    display: flex;
    overflow-y: scroll;
    background-color: #282828;
    overflow-x: hidden;
    flex-direction: column;

    .buttonBox {
        padding: 0px 14px;
        position: relative;
        display: flex;
        justify-content: center;
    }

    button {
        width: calc(100% - 28px);
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background-color: #0047ff;
        color: white;
        display: flex;
        white-space: pre-wrap;
        position: fixed;
        bottom: 28px;
        align-items: center;
        justify-content: center;
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        border: none;
        outline: none;
        font-family: "GmarketSansTTF", sans-serif;
    }

    .introBox {
        width: 100%;
        padding-top: 56px;
        .introDesc {
            padding-bottom: 60px;
            padding-top: 20px;
            background-color: #282828;
            display: flex;
            flex-direction: column;
            align-items: center;


            & > img {
                width: 100%;

            }
        }
    }

    .titleBox {
        display: flex;
        top:0;
        position: fixed;
        width: 100%;
        justify-content: center;
        background-color: white;
        z-index: 1;
        padding: 14px;
        align-items: center;
        .backBtn {
            position: absolute;
            right: 14px;
            width: 10px;
            height: 17px;

            img {
                width: 14px;
                height: 14px;
            }
        }
        .title {
            font-weight: 700;
        }
    }
`;

export default StoreGoStop;
