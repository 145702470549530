import React from "react";
import { css } from "@emotion/react";

import EventPageBanner from "../../../assets/introduction/iosDatamapEventPage.png"
import { useNavigate } from "react-router-dom";
import { getDeviceType } from "@src/lib/WebAppBridge";

const IosDatamap = () => {

    const navigate = useNavigate();

    return (
        <>
            <div css={rootBoxStyle}>
                <button onClick={() => { getDeviceType() === "android" ? navigate("/subscriptionInfo?type=6") : getDeviceType() === "ios" ? navigate("/ios-subscriptionInfo?type=7") : "" }}>구독하러 가기</button>
                <img src={EventPageBanner} alt="" />
            </div>
        </>
    )
}

export default IosDatamap;

const rootBoxStyle = css`
   width: 100%;
    height: 100vh;
    overflow: hidden auto;
    position: relative;

    & > img {
        width: 100%;
    }

    & > button {
        position: fixed;
        z-index: 100;
        font-size: 26px;
        padding: 10px 48px;
        border-radius: 100px;
        bottom: 120px;
        transform: translateX(-50%);
        white-space: nowrap;
        left: 50%;
        background: black;
        box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
        color: #ffffff;
    }
`