import styled from "@emotion/styled";
import { TICKET_TYPE } from "@src/components/deungi/common/enum";
import { Common } from "@src/styles/Common";
import dayjs from "dayjs";
import React from "react";

interface CharginUseItemProps {
    type: string;
    item?: any;
}

const CharginUseItem = ({ type, item }: CharginUseItemProps) => {
    return (
        <>
            <StyledItemWrap>
                {type === "charge" ? (
                    <>
                        <div>
                            <span className="blueText">{item.startedAt.slice(0, 16)}</span>
                        </div>
                        <div className="row">
                            <div>
                                {item.productName} 충전<small>유효기간 : {item.expiredAt.slice(0, 10)}</small>
                            </div>{" "}
                            <div>
                                <span className="blueText">사용 : {item.usedQuantity}장</span>
                                {item.refundQuantity !== 0 && <span className="grayText">환불 : {item.refundQuantity}장</span>}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <span className="blueText">{dayjs(item.createdAt).format("YYYY.MM.DD HH:mm:ss")}</span>
                        </div>
                        <div className="row">
                            <div>
                                {item.title}
                                {item.ticketType === TICKET_TYPE.ITEMS_ADS_TICKET && <small>{item.address}</small>}
                                {item.period && <label>광고기간: {item.period}</label>}
                            </div>
                            {item.isCancelled && <div className="grayText">취소 : 1장</div>}
                        </div>
                    </>
                )}
            </StyledItemWrap>
        </>
    );
};

export default CharginUseItem;

const StyledItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    line-height: 1.6;
    border-bottom: 0.6px solid #c9c9c9;
    padding: 14px;
    justify-content: center;

    .grayText {
        width: 120px;
        text-align: right;
        color: ${Common.colors.darkGrey04};
        ${Common.textStyle.title6_R14};
    }
    .blueText {
        color: ${Common.colors.blue};
        ${Common.textStyle.title6_R14};
    }
    & > .row {
        padding-top: 4px;
        padding-bottom: 2px;

        & > div {
            display: flex;
            flex-direction: column;

            & > small {
                display: block;
                font-size: 13px;
                color: #8d8d8d;
                margin: 4px 0 2px;
            }
            & > label {
                font-size: 13px;
                color: #0c002c;
            }
            & > span {
                font-size: 14px;
                text-align: right;
            }
        }
    }
    & > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.title3_R16};
        letter-spacing: 0px;
    }
`;
