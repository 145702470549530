import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";

export const useFindOneStateLandSubs = (enabled: boolean = false) => {
    return useQuery<IUserStateLandSubs>(
              [
            "stateLandSubs",
            typeof window !== "undefined" && localStorage?.getItem("userSession")
                ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                : "",
        ],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.userStateLandPath)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled },
    );
};
export interface IUserStateLandSubs {
    list?: {        
     no?: number;
     pnus?: number[];
     pnusToString?: string;
     isActive?: boolean;
     isSubs?: boolean;
     expiredAt?: string;
     firstUsedAt?: string;
     productName?: string;
    }[];
    allPnus?: number[] | null;
    allPnusToString?: string;
    isActive?: boolean;
    totalCnt?: number;
}
