import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTicketCount } from "@src/hooks/deungi/useTicketCount";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TICKET_TYPE } from "./common/enum";

const DeungiTicketBoard = () => {
    const navigate = useNavigate();
    const [ticketCount, setTicketCount] = useState(0);
    const [searchParams] = useSearchParams();

    const getTicketCount = async () => {
        await useTicketCount().then((res) => {
            const data = res.data;
            setTicketCount(data.curHaveCnt || 0);
        });
    };

    const onClickHandler = () => {
        navigate("/deungi/payment", { replace: false, state: { type: TICKET_TYPE.DEUNGI_TICKET } });
    };

    useEffect(() => {
        getTicketCount();
    }, [searchParams]);

    return (
        <Container>
            <TicketTitle>
                <p>
                    <span>Live</span>실시간 등기 열람권
                </p>
                <div onClick={onClickHandler}>
                    <small>{ticketCount}장</small>
                    <div>
                        <span>+</span>충전
                    </div>
                </div>
            </TicketTitle>
        </Container>
    );
};

const Container = styled.div`
    padding: 0 14px;
`;

const TicketTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #fdfafa;
    border-radius: 14px;
    padding: 0 14px;

    p {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #0c002c;
        & > span {
            border-radius: 100px;
            background-color: #f6494b;
            display: inline-block;
            padding: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 11px;
            font-weight: 700;
            line-height: 10px;
            margin-right: 4px;
        }
    }
    & > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        small {
            font-weight: 400;
        }
        & > div {
            display: flex;
            align-items: center;
            font-weight: 500;
            height: 28px;
            padding: 4px 6px;
            margin-left: 10px;
            background: #0047ff;
            border: none;
            border-radius: 24px;
            color: #fff;

            & > span {
                font-weight: 700;
                font-size: 15px;
                color: #fff;
                margin-right: 3px;
            }
        }
    }
`;

export default DeungiTicketBoard;
