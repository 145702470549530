import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { SearchListRequestDto } from "./dto/SearchList.dto";

export const useSearchList = async (params: SearchListRequestDto) => {
    const queryString = new URLSearchParams(params as any).toString();
    const response = await axiosInstance.get(`${axiosPath.searchList}?${queryString}`);

    if (!response.status) throw new Error("Problem fetching data");
    
    return response;
};
