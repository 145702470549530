import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import ico_tooltip from "@src/assets/ico_tooltip.png";
import ico_arrow_down_white from "@src/assets/ico_arrow_down_white.png";
import deungi_alarm_info_01 from "@src/assets/deungi/deungi_alarm_info_01.png";
import deungi_alarm_info_02 from "@src/assets/deungi/deungi_alarm_info_02.png";
import DeungiCombo from "@src/components/deungi/DeungiCombo";
import DeungiAlarmHeader from "@src/components/deungi/DeungiAlarmHeader";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DeungiInfoResponseDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { useDeungiChangeAlarmModify, useDeungiChangeAlarmSubmit, useDeungiMaskingOwner } from "@src/hooks/deungi/useDeungiAlarm";
import { DeungiAlarmSubmitRequestDto } from "@src/hooks/deungi/dto/DeungiAlarm.dto";
import ToastMessage from "@src/components/deungi/ToastMessage";
import dayjs from "dayjs";
import { DEUNGI_POPUP_TYPE } from "@src/components/deungi/common/enum";
import { transferToNative } from "@src/lib/WebAppBridge";
import DeungiPopup from "@src/components/deungi/DeungiPopup";

const DeungiAlarmDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("");
    const [popupData, setPopupData] = useState({});
    const [popupToggle, setPopupToggle] = useState(false);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.OPEN_WITH_ADDRESS);
    const [filterTypeToggle, setFilterTypeToggle] = useState(false);
    const [filterType, setFilterType] = useState("1개월");
    const [ownerMaskName, setOwnerMaskName] = useState("-");
    const [ownerName, setOwnerName] = useState("");
    const [deungiInfo, setDeungiInfo] = useState<DeungiInfoResponseDto>();
    const [isModify, setIsModify] = useState(false);

    const onChangeOwnerHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setOwnerName(e.target?.value || "");
    };

    const getOwnerMaskingInfo = async () => {
        // 마스킹 소유주 정보 조회
        try {
            console.log("deungiInfo?.pin", deungiInfo?.pin);
            if (deungiInfo?.pin) {
                const res: any = await useDeungiMaskingOwner(deungiInfo?.pin || "");
                setOwnerMaskName(res.data);
            } else {
                setOwnerMaskName("-");
            }
        } catch (e) {
            console.log("e", e);
            setOwnerMaskName("-");
        }
    };

    const onDeungiOpenHandler = () => {
        if (deungiInfo) {
            setPopupData(deungiInfo);
            setPopupType(DEUNGI_POPUP_TYPE.OPEN_WITH_ADDRESS);
            setPopupToggle(true);
        }
    };

    const onBuildingRegisterOpenHandler = () => {
        if (deungiInfo) {
            const params = JSON.stringify({ address: deungiInfo?.address, splotNm: "", block: "" });
            transferToNative(params, "showBuildingRegisterView");
        }
    };

    const onSubmitAlarmHandler = async () => {
        try {
            if (deungiInfo && ownerName.length > 0) {
                if (isModify) {
                    const res: any = await useDeungiChangeAlarmModify(deungiInfo._id, ownerName);

                    if (res.statusCode === 500) {
                        setMessage(res?.message || "비정상적인 호출입니다");
                    } else {
                        navigate("/deungi/alarm/list", { state: { modifyComplete: true } });
                    }
                } else {
                    const res: any = await useDeungiChangeAlarmSubmit({
                        address: deungiInfo.address,
                        pin: deungiInfo.pin,
                        deungi_type: deungiInfo.deungi_type,
                        item_type: deungiInfo.item_type,
                        owner: ownerName,
                        expiredMonth: Number(filterType.replace("개월", "")) || 0,
                    } as DeungiAlarmSubmitRequestDto);

                    if (res.statusCode === 500) {
                        setMessage(res?.message || "비정상적인 호출입니다");
                    } else {
                        navigate("/deungi/alarm/list", { state: { submitComplete: true } });
                    }
                }
            }
        } catch (e: any) {
            console.log("e", e);
        }
    };

    const onOpenInfoPopupHandler = (popupType: DEUNGI_POPUP_TYPE) => {
        setPopupType(popupType);
        setPopupToggle(true);
    };

    useEffect(() => {
        setDeungiInfo(location.state?.deungiInfo || null);
        setIsModify(location.state?.isModify);
    }, []);

    useEffect(() => {
        if (deungiInfo) {
            getOwnerMaskingInfo();
            if (isModify) setOwnerName(deungiInfo.owner || "");
        }
    }, [deungiInfo, isModify]);

    return (
        <Container>
            <DeungiAlarmHeader title="등기 변동 알림 신청" />
            <AlarmInfoBox>
                <h3>등기정보</h3>
                <div className={"infoBox"}>
                    <h3>소재지</h3>
                    <p>
                        {deungiInfo?.address}
                        <label>
                            {deungiInfo?.item_type} {deungiInfo?.pin?.replace(/(\d{4})(\d{4})(\d{6})/gi, "$1-$2-$3")}
                        </label>
                    </p>
                    <h3>소유자</h3>
                    <p>{ownerMaskName}</p>
                </div>
                <h3 onClick={() => onOpenInfoPopupHandler(DEUNGI_POPUP_TYPE.ALARM_PERIOD_INFO)}>
                    변동 확인 기간 <img src={ico_tooltip} />
                </h3>
                {isModify ? (
                    <span>
                        {dayjs(deungiInfo?.created_at).format("YYYY.MM.DD")}부터{" "}
                        {dayjs(deungiInfo?.expired_at).format("YYYY.MM.DD")}까지
                    </span>
                ) : (
                    <div className={"periodBox"} onClick={() => setFilterTypeToggle(true)}>
                        <OptionBox>{filterType}</OptionBox>
                    </div>
                )}
                <h3 onClick={() => onOpenInfoPopupHandler(DEUNGI_POPUP_TYPE.INPUT_OWNER_INFO)}>
                    <i>*</i>소유자 입력 <img src={ico_tooltip} />
                </h3>
                <input type="text" onChange={onChangeOwnerHandler} value={ownerName} placeholder="등기상 소유자를 입력해주세요" />
                <h3>소유자 정보를 모르시나요?</h3>
                <div className={"openBox"}>
                    <button onClick={onDeungiOpenHandler}>
                        <img src={deungi_alarm_info_01} />
                        실시간 <span>등기부등본</span> 열람하기
                    </button>
                    <button onClick={onBuildingRegisterOpenHandler}>
                        <img src={deungi_alarm_info_02} />
                        실시간 <span>건축물대장</span> 열람하기
                    </button>
                </div>
            </AlarmInfoBox>
            <button className={ownerName.length > 0 ? "submitBtn active" : "submitBtn"} onClick={onSubmitAlarmHandler}>
                {isModify ? "수정하기" : "등기변동 알림 신청"}
            </button>
            <DeungiCombo
                option={filterType}
                options={["1개월", "3개월", "6개월"]}
                toggle={filterTypeToggle}
                setOption={setFilterType}
                setToggle={setFilterTypeToggle}
            />
            <DeungiPopup type={popupType} toggle={popupToggle} setToggle={setPopupToggle} data={popupData} />
            <ToastMessage message={message} setMessage={setMessage} />
        </Container>
    );
};

const AlarmInfoBox = styled.div`
    position: relative;
    height: calc(100dvh - 56px);
    padding: 14px 14px 100px;
    color: #0c002c;
    overflow: scroll;
    animation: fadeIn 0.3s forwards;

    & > h3 {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 14px;

        & > i {
            color: #ff0000;
            margin-right: 4px;
        }
        & > img {
            margin-left: 5px;
            width: 16px;
            height: 16px;
        }
    }
    & > span {
        display: block;
        font-size: 14px;
        color: #0c002c;
        margin-bottom: 25px;
    }
    & > .infoBox {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 8px;
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 1.6;
        background: #f7f8fa;
        border-radius: 4px;

        & > h3 {
            font-size: 13px;
            margin: 4px 0;
        }
        & > p {
            & > label {
                display: block;
                font-size: 13px;
                color: #505050;
            }
        }
    }
    & > input {
        width: 100%;
        padding: 14px;
        line-height: 1.6;
        font-size: 16px;
        background: #f6faff;
        border: none;
        border-radius: 14px;
        margin-bottom: 100px;
    }
    & > .openBox {
        display: flex;
        flex-direction: column;

        & > button {
            display: flex;
            align-items: center;
            width: 100%;
            border: 1px solid #0047ff;
            border-radius: 4px;
            padding: 8px;
            margin: 4px 0;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
            transition: all 0.1s;
            font-size: 14px;

            & > span {
                color: #0047ff;
                margin: 0 4px;
            }
            & > img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
            &:active {
                transform: scale(0.98);
            }
        }
    }
    & > .periodBox {
        width: fit-content;
        margin-bottom: 24px;
        & > div {
            width: 96px;
            margin-left: 0;
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100dvh;

    .submitBtn {
        position: fixed;
        bottom: 28px;
        left: 14px;
        right: 14px;
        border-radius: 14px;
        line-height: 1.6;
        background: #c3c3c3;
        color: #fff;
        padding: 14px;
        font-size: 18px;
        font-weight: 500;
        transition: all 0.3s ease;
        cursor: pointer;

        &.active {
            background: #2275ee;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const OptionBox = ({ children }: any) => {
    const Container = styled.div`
        display: flex;
        justify-content: space-between;
        width: 50%;
        min-width: 90px;
        align-items: center;
        background: #2275ee;
        padding: 12px 8px;
        margin-left: 10px;
        border: 1px solid #2275ee;
        color: #fff;
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
        border-radius: 24px;

        & > div {
            width: 100%;
            text-align: center;
        }
        & > img {
            width: 11px;
            height: 7px;
        }
        @media screen and (max-width: 355px) {
            & {
                margin-left: 4px;
            }
        }
    `;

    return (
        <Container>
            <div>{children}</div>
            <img src={ico_arrow_down_white} />
        </Container>
    );
};

export default DeungiAlarmDetail;
