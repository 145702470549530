import styled from "@emotion/styled";
import { useAiBottomSheet } from "@src/hooks/filter/useAiBottomSheet";
import { AiItemListType } from "@src/hooks/filter/useGetAiItemList";
import React from "react";
import AiBottomSheetItemList from "./AiBottomSheetItemList";
import AiBottomSheetContentTitle from "./AiBottonSheetContentTitle";

interface AiBottomSheetContentProps {
    content: any;
    buttonWrapper: any;
    sort: any;
    isTouched: boolean;
    data: AiItemListType[];
    setTarget: any;
}

const AiBottomSheetContent = ({ content, buttonWrapper, sort, isTouched, data, setTarget }: AiBottomSheetContentProps) => {
    return (
        <>
            <Wrapper ref={content}>
                <AiBottomSheetItemList content={content} data={data} setTarget={setTarget} />
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0px 14px;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export default AiBottomSheetContent;
