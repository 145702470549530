import xmlToJson from "@src/util/convertXmlToJson";
import fetchJsonp from "fetch-jsonp";

interface initBuildingDetailDataDto {}

const env = process.env;
const setTotalBuildingResult = (
    data: {
        mainAtchGbCdNm: string;
        bldNm: string;
        mainPurpsCdNm: string;
        etcPurps: string;
    }[],
) => {
    let result: {
        mainAtchGbCdNm: string;
        bldNm: string;
        etcPurps: string;
    }[] = [];
    if (data.length !== 0) {
        data.map((item, index) => {
            const { mainAtchGbCdNm, bldNm, mainPurpsCdNm, etcPurps } = item;
            let returnMainAtchGbCdNm = mainAtchGbCdNm.includes("주") ? `주${index + 1}` : `부${index + 1}`;
            let returnBldNm = bldNm !== " " ? bldNm : mainPurpsCdNm;
            result.push({ mainAtchGbCdNm: returnMainAtchGbCdNm, bldNm: returnBldNm, etcPurps });
        });
    }
    return result;
};
const setTotalBuildingResultObject = (data: {
    //표제부 객체 형식일때
    mainAtchGbCdNm: string;
    bldNm: string;
    mainPurpsCdNm: string;
    etcPurps: string;
}) => {
    const { mainAtchGbCdNm, bldNm, mainPurpsCdNm, etcPurps } = data;
    let returnMainAtchGbCdNm = mainAtchGbCdNm.includes("주") ? `주1` : `부1`;
    let returnBldNm = bldNm !== " " ? bldNm : mainPurpsCdNm;
    return [{ mainAtchGbCdNm: returnMainAtchGbCdNm, bldNm: returnBldNm, etcPurps }];
};
const convertSingleItem = (origin: any) => {
    return {
        base: {
            regstrGbCdNm: origin.regstrGbCdNm,
            bldNm: origin.bldNm,
            mainAtchGbCdNm: origin.mainAtchGbCdNm,
            mainPurpsCdNm: origin.mainPurpsCdNm,
            etcPurps: origin.etcPurps,
            strctCdNm: origin.strctCdNm,
            roofCdNm: origin.roofCdNm,
            heit: origin.heit,
            grndFlrCnt: origin.grndFlrCnt,
            ugrndFlrCnt: origin.ugrndFlrCnt,
            platArea: origin.platArea,
            bcRat: origin.bcRat,
            archArea: origin.archArea,
            totArea: origin.totArea,
            vlRat: origin.vlRat,
            vlRatEstmTotArea: origin.vlRatEstmTotArea,
            hhldCnt: origin.hhldCnt,
            fmlyCnt: origin.fmlyCnt,
            hoCnt: origin.hoCnt,
            mainBldCnt: origin.mainBldCnt,
            atchBldCnt: origin.atchBldCnt,
            bylotCnt: origin.bylotCnt,
            platAreaPyeong: origin.platArea.pyong,
            archAreaPyeong: origin.archArea.pyong,
            totAreaPyeong: origin.totArea.pyong,
            vlRatEstmTotAreaPyeong: origin.vlRatEstmTotArea.pyong,
        },
        building: null,
        puri: {},
        elevator: {
            rideUseElvtCnt: origin.rideUseElvtCnt,
            emgenUseElvtCnt: origin.emgenUseElvtCnt,
        },
        parkingLot: {
            totPkngCnt: origin.totPkngCnt,
            indrAutoUtcnt: origin.indrAutoUtcnt,
            oudrAutoUtcnt: origin.oudrAutoUtcnt,
            indrMechUtcnt: origin.indrMechUtcnt,
            oudrMechUtcnt: origin.oudrMechUtcnt,
        },
        dates: {
            pmsDay: origin.pmsDay,
            stcnsDay: origin.stcnsDay,
            useAprDay: origin.useAprDay,
        },
        jeonyu: null,
    };
};

const fetch = async ({ buildingOrigin, pnu }: any) => {
    const URL = env.REACT_APP_PUBLIC_DATA_PORTAL_API_URL;
    let sigunguCd = pnu.substring(0, 5);
    let bjdongCd = pnu.substring(5, 10);
    let bun = pnu.substring(11, 15);
    let ji = pnu.substring(15);

    //* vworld: 국토교통부_개별공시지가정보서비스 (개별공시지가)
    const key = env.REACT_APP_V_WORLD_KEY;
    const url = `https://api.vworld.kr/ned/data/getIndvdHousingPriceAttr?key=${key}&pnu=${pnu}&format=json&numOfRows=40`;
    let result = {
        total: {
            base: {},
            building: {},
            puri: {},
        },
        single: [],
    };
    try {
        const getHousePriceData = await fetchJsonp(url, {
            jsonpCallbackFunction: "housePriceData3",
            // timeout: 5000,
        });
        const res = await getHousePriceData.json();
        const housePriceData = res.indvdHousingPrices?.field ?? [];
        let indvdHousingPrices: any[] = [];
        if (housePriceData.length !== 0) {
            housePriceData.forEach((item: any) => {
                indvdHousingPrices.push({
                    stdrYear: item.stdrYear,
                    stdrMt: item.stdrMt,
                    housePc: item.housePc,
                });
            });
        }
        const originWC = []; //오수정화시설
        const totalWc = {}; //오수정화시설 - 총괄표제부
        if (buildingOrigin?.total.length === 0 && buildingOrigin?.single.length === 0) return { total: {}, single: [] }; //총괄표제부, 표제부 둘 다 없을때
        if (buildingOrigin?.total.length === 0 && buildingOrigin?.single.length !== 0)
            return {
                total: {},
                single: buildingOrigin.single.length
                    ? buildingOrigin.single.map((single: any) => convertSingleItem(single))
                    : [convertSingleItem(buildingOrigin.single)],
            }; //총괄표제부 없을때
        if (buildingOrigin.total.length !== 0 && buildingOrigin.single.length === 0) {
            return {
                total: {
                    base: {
                        ...buildingOrigin.total[0],
                        platAreaPyeong: buildingOrigin.total[0].platArea.pyong,
                        bcRatPyeong: buildingOrigin.total[0].bcRat.pyong,
                        archAreaPyeong: buildingOrigin.total[0].archArea.pyong,
                        totAreaPyeong: buildingOrigin.total[0].totArea.pyong,
                        vlRatPyeong: buildingOrigin.total[0].vlRat.pyong,
                        vlRatEstmTotAreaPyeong: buildingOrigin.total[0].vlRatEstmTotArea.pyong,
                    },
                    building: setTotalBuildingResult([]),
                    puri: {},
                },
                single: [],
            }; //표제부 없을때
        }
        const originTotal = buildingOrigin.total[0]; //총괄표제부
        const originSingle = buildingOrigin.single; //표제부
        const singleData =
            originSingle.length !== 0
                ? originSingle.map((single: any) => convertSingleItem(single))
                : [convertSingleItem(originSingle)];
        result = {
            total: {
                base: {
                    ...originTotal,
                    platAreaPyeong: originTotal.platArea.pyong,
                    bcRatPyeong: originTotal.bcRat.pyong,
                    archAreaPyeong: originTotal.archArea.pyong,
                    totAreaPyeong: originTotal.totArea.pyong,
                    vlRatPyeong: originTotal.vlRat.pyong,
                    vlRatEstmTotAreaPyeong: originTotal.vlRatEstmTotArea.pyong,
                },
                building:
                    originSingle.length !== 0 ? setTotalBuildingResult(originSingle) : setTotalBuildingResultObject(originSingle),
                puri: { ...totalWc },
            },
            single: singleData,
        };
    } catch (e) {
        console.log(e);
    }
    return result;
};
const useGetBuildingDetail = async (params: any) => {
    return new Promise((resolve, _reject) => {
        resolve(fetch(params));
    }).then((res) => {
        return { data: res, typeList: makeTypeList(res) };
    });
};

const initBuildingDetailData: initBuildingDetailDataDto = {
    total: {
        base: {
            regstrGbCdNm: "구분",
            bldNm: "건물명",
            mainPurpsCdNm: "주용도",
            etcPurps: "기타용도",
            platArea: 0,
            bcRat: 0,
            archArea: 0,
            totArea: 0,
            vlRat: 0,
            vlRatEstmTotArea: 0,
            platAreaPyeong: 0,
            bcRatPyeong: 0,
            archAreaPyeong: 0,
            totAreaPyeong: 0,
            vlRatPyeong: 0,
            vlRatEstmTotAreaPyeong: 0,
            hhldCnt: "세대수",
            fmlyCnt: "가구수",
            hoCnt: "호수",
            mainBldCnt: 0,
            atchBldCnt: 0,
            bylotCnt: 0,
        },
        building: [
            {
                mainAtchGbCdNm: "구분",
                bldNm: "건축물 건물명",
                mainPurpsCdNm: "건축물 주용도코드명",
                etcPurps: "용도",
            },
            {
                mainAtchGbCdNm: "구분",
                bldNm: "건축물 건물명",
                mainPurpsCdNm: "건축물 주용도코드명",
                etcPurps: "용도",
            },
            {
                mainAtchGbCdNm: "구분",
                bldNm: "건축물 건물명",
                mainPurpsCdNm: "건축물 주용도코드명",
                etcPurps: "용도",
            },
        ],
        parkingLot: {
            totPkngCnt: 0,
            indrAutoUtcnt: 0,
            oudrAutoUtcnt: 0,
            indrMechUtcnt: 0,
            oudrMechUtcnt: 0,
        },
        puri: {
            modeCdNm: "하수처리시설 형식코드명",
            etcMode: "하수처리시설 기타형식",
            capaPsper: 0,
            capaLube: 0,
        },
        dates: {
            pmsDay: "YYYY.MM.DD",
            stcnsDay: "YYYY.MM.DD",
            useAprDay: "YYYY.MM.DD",
        },
    },
    single: [
        {
            base: {
                regstrGbCdNm: "구분",
                bldNm: "건물명",
                mainPurpsCdNm: "주용도",
                etcPurps: "기타용도",
                strctCdNm: "주구조",
                roofCdNm: "지붕구조",
                heit: 0,
                grndFlrCnt: 0,
                ugrndFlrCnt: 0,
                platArea: 0,
                bcRat: 0,
                archArea: 0,
                totArea: 0,
                vlRat: 0,
                vlRatEstmTotArea: 0,
                hhldCnt: "세대수",
                fmlyCnt: "가구수",
                hoCnt: "호수",
                mainBldCnt: 0,
                atchBldCnt: 0,
                bylotCnt: 0,
            },
            building: [
                {
                    mainAtchGbCdNm: "구분",
                    flrGbCdNm: "층 층구분코드명",
                    flrNoNm: "층 층번호명",
                    etcPurps: "용도",
                    area: 0,
                },
                {
                    mainAtchGbCdNm: "구분",
                    flrGbCdNm: "층 층구분코드명",
                    flrNoNm: "층 층번호명",
                    etcPurps: "용도",
                    area: 0,
                },
                {
                    mainAtchGbCdNm: "구분",
                    flrGbCdNm: "층 층구분코드명",
                    flrNoNm: "층 층번호명",
                    etcPurps: "용도",
                    area: 0,
                },
            ],
            elevator: {
                rideUseElvtCnt: 0,
                emgenUseElvtCnt: 0,
            },
            parkingLot: {
                totPkngCnt: 0,
                indrAutoUtcnt: 0,
                oudrAutoUtcnt: 0,
                indrMechUtcnt: 0,
                oudrMechUtcnt: 0,
            },
            puri: {
                modeCdNm: "하수처리시설 형식코드명",
                etcMode: "하수처리시설 기타형식",
                capaPsper: 0,
                capaLube: 0,
            },
            dates: {
                pmsDay: "YYYY.MM.DD",
                stcnsDay: "YYYY.MM.DD",
                useAprDay: "YYYY.MM.DD",
            },
            jeonyu: null,
        },
        {
            base: {
                regstrGbCdNm: "구분",
                bldNm: "건물명",
                mainPurpsCdNm: "주용도",
                etcPurps: "기타용도",
                strctCdNm: "주구조",
                roofCdNm: "지붕구조",
                heit: 0,
                grndFlrCnt: 0,
                ugrndFlrCnt: 0,
                platArea: 0,
                bcRat: 0,
                archArea: 0,
                totArea: 0,
                vlRat: 0,
                vlRatEstmTotArea: 0,
                hhldCnt: "세대수",
                fmlyCnt: "가구수",
                hoCnt: "호수",
                mainBldCnt: 0,
                atchBldCnt: 0,
                bylotCnt: 0,
            },
            building: [
                {
                    mainAtchGbCdNm: "구분",
                    flrGbCdNm: "층 층구분코드명",
                    flrNoNm: "층 층번호명",
                    etcPurps: "용도",
                    area: 0,
                },
                {
                    mainAtchGbCdNm: "구분",
                    flrGbCdNm: "층 층구분코드명",
                    flrNoNm: "층 층번호명",
                    etcPurps: "용도",
                    area: 0,
                },
                {
                    mainAtchGbCdNm: "구분",
                    flrGbCdNm: "층 층구분코드명",
                    flrNoNm: "층 층번호명",
                    etcPurps: "용도",
                    area: 0,
                },
            ],
            elevator: {
                rideUseElvtCnt: 0,
                emgenUseElvtCnt: 0,
            },
            parkingLot: {
                totPkngCnt: 0,
                indrAutoUtcnt: 0,
                oudrAutoUtcnt: 0,
                indrMechUtcnt: 0,
                oudrMechUtcnt: 0,
            },
            puri: {
                modeCdNm: "하수처리시설 형식코드명",
                etcMode: "하수처리시설 기타형식",
                capaPsper: 0,
                capaLube: 0,
            },
            dates: {
                pmsDay: "YYYY.MM.DD",
                stcnsDay: "YYYY.MM.DD",
                useAprDay: "YYYY.MM.DD",
            },
            jeonyu: null,
        },
    ],
};
export const makeTypeList = (data: any): any[] => {
    let arr: any[] = [];
    let singleArr: any[] = [];
    let singleNormalArr: any = [];
    const { total, single } = data;
    if (total && total?.length !== 0 && Object.keys(total)?.length !== 0) {
        arr.push({
            type: "total",
            gubun: "총괄",
            value: `${total?.base?.bldNm} ${total?.base?.mainPurpsCdNm}`,
            index: null,
        });
    }
    if (single.length >= 1) {
        single.map((item: any, index: number) => {
            let type = item?.base?.mainAtchGbCdNm?.includes("주") ? "주" : "부";
            if (item?.base?.mainPurpsCdNm?.includes("공동")) {
                singleArr.push({
                    type: "single",
                    gubun: `${type}${index + 1}`,
                    value: `${item?.base?.bldNm} ${item?.base?.mainPurpsCdNm}`,
                    index,
                });
            } else {
                singleNormalArr.push({
                    type: "single",
                    gubun: `${type}${index + 1}`,
                    value: `${item?.base?.bldNm !== "-" ? item?.base?.bldNm : ""} ${item?.base?.mainPurpsCdNm}`,
                    index,
                });
            }
        });
        arr.push(...singleArr, ...singleNormalArr);
    }
    // else if (single.length < 1) {
    //     console.log("$$$$$$$$", single[0]?.base?.bldNm !== "-" ? single[0]?.base?.bldNm : "", single[0]?.base?.mainPurpsCdNm);
    //     arr.push({
    //         type: "single",
    //         gubun: `${single[0]?.base?.mainAtchGbCdNm?.includes("주") ? "주" : "부"}1`,
    //         value: `${single[0]?.base?.bldNm !== "-" ? single[0]?.base?.bldNm : ""} ${single[0]?.base?.mainPurpsCdNm}`,
    //         index: 0,
    //     });
    // }
    return arr;
};
let initTypeList = makeTypeList(initBuildingDetailData);
export { initBuildingDetailData, initTypeList, useGetBuildingDetail };
export type { initBuildingDetailDataDto };
