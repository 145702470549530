import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import { Common } from "@src/styles/Common";
import React from "react";

type RangeSelectedPropsType = {
    startValue: string;
    endValue: string;
    onClickValue: (price: string, title: string) => void;
};

const RangeSelected = ({ startValue, endValue, onClickValue }: RangeSelectedPropsType) => {
    const getClassName = (price: string, title: string) => {
        const numberStartValue = Number(startValue);
        const numberEndValue = Number(endValue);
        const numberPrice = Number(price);

        if (title === "전체" && startValue === "" && endValue === "") return "selected start";
        if (startValue === "higher" && price === "higher") return "selected end";

        if (numberPrice === numberEndValue && endValue !== "") return "selected end";
        if (startValue !== "" && endValue !== "") {
            if (numberPrice > numberStartValue && numberPrice < numberEndValue) return "range";
        }
        if (startValue !== "" && numberStartValue === numberPrice) return "selected start";

        return "";
    };

    const isRange = startValue.length !== 0 && endValue.length !== 0 && endValue !== startValue;

    return (
        <Container isRange={isRange}>
            {itemForAppraisedPrice.map((price) => (
                <div key={price.value} className="wrapper" onClick={() => onClickValue(price.value, price.title)}>
                    <div className={getClassName(price.value, price.title)}></div>
                    <span>{price.title}</span>
                </div>
            ))}
        </Container>
    );
};

export default RangeSelected;

const Container = styled.div<{ isRange: boolean }>`
    padding: 0px 14px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;

        span {
            font-size: 14px;
            white-space: nowrap;
        }

        .selected.start,
        .selected.end {
            z-index: 1;
            height: 100%;
            width: 100%;
            border-radius: 24px;

            & + span {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                border-radius: 24px;
                background: ${Common.colors.aucMarkerColor};
                color: white;
                font-size: 14px;
            }
        }
        ${({ isRange }) =>
            isRange &&
            css`
                .selected.start::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 50%;
                    height: 100%;
                    z-index: -99;
                    background: transparent;
                    background-color: #dff0ff;
                }
                .selected.end::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 50%;
                    height: 100%;
                    z-index: -99;
                    background: transparent;
                    background-color: #dff0ff;
                }
            `}

        .range {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: #dff0ff;
        }
        span {
            z-index: 2;
        }
    }
`;
