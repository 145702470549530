import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {
    useFindSubsInfo,
    useFindSubsRefundAmount,
    useRemoveUserSubs,
    useRemoveUserSubsReserve,
} from "@src/hooks/subscribe/useSubscribe";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { transferToNative } from "@src/lib/WebAppBridge";
import UnsubsribeNow from "./UnsubsribeNow";
import UnsubscribeReservation from "./UnsubscribeReservation";

interface Props {}

interface IStateProps {
    status: boolean; // true 즉시 false 예약
    checkUsedDay: boolean;
}

const UnsubsribeRefund: React.FC<Props> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [checkView, setCheckView] = useState<string>("");
    const [showRefundDataModal, setShowRefundDataModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showProhibitModal, setShowProhibitModal] = useState<boolean>(false);
    const [showToastMessage, setShowToastMessage] = useState<boolean>(false);
    const [errModalMessage, setErrModalMessage] = useState<any>();
    const reserveRemoveSubs = useRemoveUserSubsReserve(
        () => {
            setShowToastMessage(true);
        },
        (err) => {
            const newErr: any = err?.response?.data;
            setShowErrorModal(true);
            setErrModalMessage(
                <>
                    <p css={dialogTitleStyle}>해지할 수 없습니다</p>
                    <p css={dialogContentStyle}>{newErr?.message ?? "오류가 발생하였습니다"}</p>
                </>,
            );
        },
    );
    const removeSubs = useRemoveUserSubs(
        () => {
            setShowToastMessage(true);
        },
        (err) => {
            const newErr: any = err?.response?.data;
            setShowErrorModal(true);
            setErrModalMessage(
                <>
                    <p css={dialogTitleStyle}>해지할 수 없습니다</p>
                    <p css={dialogContentStyle}>{newErr?.message ?? "오류가 발생하였습니다"}</p>
                </>,
            );
        },
    );

    const onSubmitRemoveSubs = () => {
        removeSubs.mutate();
    };

    const onSubmitReserveRemoveSubs = () => {
        reserveRemoveSubs.mutate();
    };
    const { data: subsInfo, refetch: refetchSubsInfo } = useFindSubsInfo();
    const { data, refetch } = useFindSubsRefundAmount();

    useEffect(() => {
        refetchSubsInfo();
        refetch();
    }, []);

    useEffect(() => {
        if (location.state) {
            const { status, checkUsedDay } = location.state as IStateProps;
            console.log(location.state);
            setCheckView(status ? "now" : "reserve");
        }
    }, []);

    return (
        <>
            <div css={rootBoxStyle}>
                <div css={headStyle}>
                    <ArrowBackIosNewRoundedIcon onClick={() => navigate(-1)} />
                    <p>{checkView === "now" ? "즉시 해지하기" : "해지 예약하기"}</p>
                    <p />
                </div>
                <div css={contentStyle}>
                    {checkView === "now" && (
                        <UnsubsribeNow
                            data={data}
                            showRefundDataModal={showRefundDataModal}
                            onChangeRefundData={(val: boolean) => {
                                if (location.state) {
                                    const { checkUsedDay } = location.state as IStateProps;
                                    if (checkUsedDay) {
                                        setShowProhibitModal(true);
                                        return;
                                    }
                                }
                                setShowRefundDataModal(val);
                            }}
                            showToast={showToastMessage}
                            onCloseToast={(val: boolean) => {
                                setShowToastMessage(val);
                                transferToNative(true, "closeUnsubscribeView");
                            }}
                            onSubmitRemoveSubs={onSubmitRemoveSubs}
                        />
                    )}
                    {checkView === "reserve" && (
                        <UnsubscribeReservation
                            data={data}
                            subsInfo={subsInfo}
                            showToast={showToastMessage}
                            onCloseToast={(val: boolean) => {
                                setShowToastMessage(val);
                                transferToNative(true, "closeUnsubscribeView");
                            }}
                            onSubmitReserveRemoveSubs={onSubmitReserveRemoveSubs}
                        />
                    )}
                </div>
            </div>
            {showErrorModal && <ConfirmModal resultMessage={errModalMessage} onConfirm={() => setShowErrorModal(false)} />}
            {showProhibitModal && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>환불불가</p>
                            <p css={dialogContentStyle}>
                                약관에 따라 구독 결제 시부터
                                <br />
                                8일 이후에는 환불이 불가합니다
                            </p>
                        </>
                    }
                    onConfirm={() => setShowProhibitModal(false)}
                />
            )}
        </>
    );
};

export default UnsubsribeRefund;

const rootBoxStyle = css`
    height: 100dvh;
    height: 100vh;
    overflow: hidden;
`;

const headStyle = css`
    display: flex;
    padding: 14px;
    justify-content: space-between;
    align-items: center;
    color: #0c002c;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    height: 56px;
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: #ffffff;
`;

const contentStyle = css`
    margin-top: 60px;
`;

const dialogTitleStyle = css`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 18px;
    word-break: break-word;
`;
