import { css } from "@emotion/react";
import React from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";
import { Common } from "@src/styles/Common";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

export const auctionCounterForceList = ["전체", "대항력 없음", "대항력 있음", "미상"];

const GoStopAuctionCounterForceFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(!filterParams.counter_force)}>
                <p className="titleText">대항력</p>
                {allFilter ? (
                    <button
                        className="allButton"
                        onClick={() => {
                            setFilterParams({ ...filterParams, counter_force: 0 });
                        }}
                    >
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <p css={descTextStyle}>*권리분석의 내용은 입력 착오로 사실과 다를 수 있으며 면책 조건으로 제공됩니다.</p>
            <div css={selectButtonBoxStyle}>
                {auctionCounterForceList.map(
                    (item, idx) =>
                        (!allFilter || !!idx) && (
                            <button
                                key={item}
                                css={selectButtonStyle(idx ? idx === filterParams.counter_force : !filterParams.counter_force)}
                                onClick={() => {
                                    idx
                                        ? setFilterParams({
                                              ...filterParams,
                                              counter_force: idx,
                                          })
                                        : setFilterParams({ ...filterParams, counter_force: 0 });
                                }}
                            >
                                {item}
                            </button>
                        ),
                )}
            </div>
        </div>
    );
};

export default GoStopAuctionCounterForceFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const descTextStyle = css`
    padding: 0px 14px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : "#ffffff"};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #f0f0f0;
    background-color: ${active ? Common.colors.aucMarkerColor : "#f0f0f0"};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;
