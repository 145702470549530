import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ICursorType } from "@src/hooks/goStop/dto/goStop.dto";
import { useUserSubs } from "@src/hooks/goStop/useGoStop";
import { useGetStoreGoStopFilters, useSaverStoreGoStopFilter, useStoreGoStopList } from "@src/hooks/storeGoStop/useStoreGoStop";
import { useStoreGoStopFilterStore } from "@src/store/StoreGoStopFilterStore";
import React, { useEffect, useMemo, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { transferToNative } from "@src/lib/WebAppBridge";
import Lottie from "lottie-react";

import NoMoreImg from "@src/assets/goStop/noMore.png";
import closeBtn from "@src/assets/ico_close.png";
import Title from "@src/components/title/Title";
import backArrow from "@src/assets/ico_arrow_back.png";
import loading from "@src/assets/Loading.json";
import StoreGoStopConfirmModal from "./StoreGoStopConfirmModal";
import StoreGoStopLocationFilter from "./storeGoStopLocationFilter";
import StoreGoStopBottomSheet from "./storeGoStopBottomSheet";
import { storeBottomSheet } from "@src/hooks/filter/useStoreBottomSheet";
import StoreGoStopFooter from "./storeGoStopFooter";
import StoreGoStopFilterBox from "./StoreGoStopFilterBox";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { Checkbox } from "@mui/material";
import ToastMessage from "@src/components/filter/ToastMessage";
import InfoRounded from "@mui/icons-material/InfoRounded";
import useInfiniteScroll from "react-infinite-scroll-hook";
import StoreGoStopPriceFilter from "./StoreGoStopPriceFilter";

const StoreGoStopList = () => {
    const navigate = useNavigate();
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const filterParams = useStoreGoStopFilterStore((state) => state.storeGoStopFilterState);
    const setFilterParams = useStoreGoStopFilterStore((state) => state.setStoreGoStopFilterState);
    const resetFiltetParams = useStoreGoStopFilterStore((state) => state.resetStoreGoStopFilterState);
    const [checkedFreeModal, setCheckedFreeModal] = useState<boolean>(true);
    const [checkedFilterSubsUserModal, setCheckedFilterSubsUserModal] = useState<boolean>(false);
    const [checkedSubsUserModal, setCheckedSubsUserModal] = useState<boolean>(false);
    const [showSortBox, setShowSortBox] = useState<boolean>(false);
    const [entranceModal, setEntranceModal] = useState<boolean>(false);
    const [checkModalDisable, setCheckModalDisable] = useState<boolean>(false);
    const [resetModal, setResetModal] = useState<boolean>(false);
    const [infoModal, setInfoModal] = useState<boolean>(false);

    const { sheetRef, content, buttonWrapper, sort, isTouched, buttonRef1, buttonRef2, buttonRef3, checkShow, onScrollUp } = storeBottomSheet(
        filterParams.loc.length > 0 ? 140 : 175,
    );

    const saverStoreGoStopFilter = useSaverStoreGoStopFilter();

    const [step, setStep] = useState(1);
    const [price, setPrice] = useState<any>("");

    const [cursor, setCursor] = useState<ICursorType>({
        sortType: filterParams.sortType,
        cursorValue: null,
        cursorId: null,
        updatedAt: null,
    });

    const BottomSheetArea = useMemo(
        () => (
            <StoreGoStopFilterBox
                showSortBox={showSortBox}
                sortType={filterParams.sortType}
                onChangeSortType={(val: number) => {
                    setFilterParams({
                        ...filterParams,
                        sortType: val,
                    });
                    setCursor({
                        ...cursor,
                        sortType: val
                    })
                    setShowSortBox(false);
                }}
                onCloseSortBox={() => setShowSortBox(false)}
            />
        ),
        [showSortBox, filterParams.sortType],
    );


    const filterParam = {
        cursor,
        filter: filterParams,
        limit: 20,
        gostopType: [],
    };

    const {
        data: getInfoFilterData,
        refetch: filterRefetch,
        isFetching: isFetchingFilterData,
    } = useGetStoreGoStopFilters("storeGoStopFilter");

    useEffect(() => {
        if (filterParams.invest_price && filterParams.invest_price !== 0) {
            setPrice((filterParams.invest_price / 10000).toLocaleString())
        }
    }, [filterParams])

    const onSubmitSaveStoreGoStopFilter = () => {
        if (JSON.stringify(getInfoFilterData) !== JSON.stringify(filterParams)) {
            saverStoreGoStopFilter.mutate(filterParams);
        }
    };
    const { data, hasNextPage, refetch, fetchNextPage, isFetching } = useStoreGoStopList(filterParam, true);

    useEffect(() => {
        filterRefetch();
    }, []);

    useEffect(() => {
        if (getInfoFilterData?.type !== undefined) {
            setFilterParams({
                ...filterParams,
                ...getInfoFilterData,
            });
        }
    }, [getInfoFilterData]);

    useEffect(() => {
        !isFetchingFilterData && refetch();
        if (getInfoFilterData?.type !== undefined) {
            onSubmitSaveStoreGoStopFilter();
        }
    }, [cursor, filterParams]);




    const { data: userSubs } = useUserSubs();


    useEffect(() => {
        const now = new Date().getTime();
        const timeSaved = Number(localStorage.getItem("modalDisableTimeClicked"));
        const gapHour = (now - timeSaved) / 1000 / 60 / 60;
        if (gapHour > 168 || localStorage.getItem("modalDisableTimeClicked") === null) {
            setEntranceModal(true);
            localStorage.removeItem("modalDisableTimeClicked");
        } else {
            return;
        }
    }, []);

    const changeEntranceModalState = () => {
        const timeClicked = JSON.stringify(new Date().getTime());
        if (checkModalDisable == true) {
            localStorage.setItem("modalDisableTimeClicked", timeClicked);
            setEntranceModal(false);
        } else {
            setEntranceModal(false);
        }
    };

    const onChangePrice = (evt: any) => {
        const { value } = evt.target;
        const isNumber = /^[0-9]+$/;
        const removeCommaPrice = value.replaceAll(",", "");
        if (removeCommaPrice !== "") {
            if (!isNumber.test(removeCommaPrice)) return;
            if (Number(removeCommaPrice) > 10000000) {
                return;
            }
        }
        if (filterParams.invest_price !== 0) {
            if (removeCommaPrice === "") {
                setPrice(removeCommaPrice);
            } else {
                const commaPrice = Number(removeCommaPrice).toLocaleString();
                setPrice(commaPrice);
            }
        }
    }

    const handleSubmit = (val?: any) => {
        setFilterParams({
            ...filterParams,
            invest_price: val ?? Number(price?.replaceAll(",", "")) * 10000
        })
    }

    return (
        <>
            {entranceModal && (
                <ConfirmModal
                    resultMessage={
                        <div style={{ lineHeight: "26px" }}>
                            {"상가 수익률 계산 시\n인근 지역의 임차데이터를 활용했습니다."}
                            <p style={{ fontSize: "12px", color: "#8D8D8D" }}>대출이 없다는 가정을 바탕으로 나온 결과값입니다.</p>
                            <div style={{ display: "flex", alignItems: "center", fontSize: "14px", justifyContent: "center" }}>
                                <Checkbox
                                    id="weeklyModal"
                                    icon={<CheckCircleIcon style={{ color: "#8D8D8D", width: "20px" }} />}
                                    checkedIcon={<CheckCircleIcon style={{ width: "20px" }} />}
                                    onChange={(target) => setCheckModalDisable(target.currentTarget.checked)}
                                />
                                <span style={{ color: "#8D8D8D" }}>일주일 동안 보지 않기</span>
                            </div>
                        </div>
                    }
                    buttonText="확인"
                    onConfirm={() => changeEntranceModalState()}
                />
            )}
            {infoModal && (
                <ConfirmModal
                    resultMessage={
                        <div style={{ lineHeight: "26px" }}>

                            {"AI 권리조사(Go-Stop)를 통해\nGo인 물건 중 상가수익률이 높은순으로\n보여져요."}
                            <p style={{ fontSize: "12px", color: "#8D8D8D" }}>※대출이 없다는 가정을 토대로 계산되었습니다.</p>
                        </div>
                    }
                    buttonText="확인"
                    onConfirm={() => setInfoModal(false)}
                />
            )}
            <div css={goStopWrap}>
                <div className="titleWrap">
                    <div className="BtnWrap">
                        <button
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <img className="backBtn" src={backArrow} alt="뒤로가기" />
                        </button>
                        <button
                            onClick={() => {
                                transferToNative("done", "closeWebView");
                            }}
                        >
                            <img className="closeBtn" src={closeBtn} alt="닫기" />
                        </button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", position: "relative", zIndex: "2" }}>
                        <Title title="상가 Go-Stop" size="large" />
                        <div onClick={() => setInfoModal(true)}>
                            <InfoRounded sx={{ color: "#B1AEB8", fontSize: 20, marginLeft: "4px" }} />
                        </div>
                    </div>
                </div>
                <div css={filterBox}>
                    {
                        step === 1 &&
                        <>
                            <p className="priceTitle"><span>투자금</span>을 입력해주세요</p>
                            <StoreGoStopPriceFilter
                                price={price ?? 0}
                                onChangePrice={onChangePrice}
                                handleSubmit={handleSubmit}
                            />
                        </>
                    }
                    {
                        step === 2 &&
                        <>
                            <p className="filterTitle">관심있는 소재지를 선택해주세요</p>
                            <StoreGoStopLocationFilter
                                locationList={filterParams.loc}
                                onChangeLocation={(val: string[]) => setFilterParams({ ...filterParams, loc: val })}
                                onSubmitShowToast={() => setShowToast(true)}
                            />
                        </>
                    }
                </div>
                <StoreGoStopBottomSheet
                    sheetRef={sheetRef}
                    content={content}
                    buttonWrapper={buttonWrapper}
                    sort={sort}
                    isTouched={isTouched}
                    cursor={cursor}
                    buttonRef1={buttonRef1}
                    buttonRef2={buttonRef2}
                    buttonRef3={buttonRef3}
                    showSortBox={showSortBox}
                    data={data}
                    onChangeShowSortBox={() => {
                        setShowSortBox((pre) => !pre);
                    }}
                    setCursor={(val: number) => setCursor({ ...cursor, sortType: val })}
                />
                {BottomSheetArea}
                {!checkShow && (
                    <StoreGoStopFooter step={step} setStep={(val: number) => { setStep(val) }} resetClick={() => setResetModal(true)} finishClick={() => onScrollUp()} />
                )}

                <div
                    className={filterVisible ? "dim" : ""}
                    onClick={() => {
                        if (filterVisible) {
                            setFilterVisible(!filterVisible);
                        }
                        if (checkedFreeModal) {
                            setCheckedFreeModal(!checkedFreeModal);
                        }
                    }}
                />
                {checkedSubsUserModal && !userSubs?.subsNo && (
                    <StoreGoStopConfirmModal
                        title={`구독회원에게만\n무료 체험 이용 혜택을 제공합니다`}
                        desc="지금 정기구독을 시작하고 데이터맵과 맞춤형 매물 정보 열람을 이용해 보세요!"
                        onClickCancel={() => {
                            setCheckedSubsUserModal(!checkedSubsUserModal);
                        }}
                        onClickConfirm={() => {
                            transferToNative("", "showSubsView");
                            setCheckedSubsUserModal(!checkedSubsUserModal);
                        }}
                    />
                )}
                {checkedFilterSubsUserModal && (
                    <StoreGoStopConfirmModal
                        title={`미스고 정기구독 회원에게만\n제공되는 기능입니다.`}
                        desc="지금 정기구독을 시작하고 데이터맵과 맞춤형 매물 정보 열람을 이용해 보세요!"
                        onClickCancel={() => {
                            setCheckedFilterSubsUserModal(!checkedFilterSubsUserModal);
                        }}
                        onClickConfirm={() => {
                            transferToNative("", "showSubsView");
                            setCheckedFilterSubsUserModal(!checkedFilterSubsUserModal);
                        }}
                    />
                )}
            </div >
            {showToast &&
                <ConfirmModal
                    resultMessage={<p style={{ textAlign: "center", lineHeight: "24px" }}>소재지는 최대 10개까지 <br />선택 가능합니다.</p>}
                    onConfirm={() => setShowToast(false)}
                />
            }

            {
                resetModal && (
                    <ConfirmModal
                        resultMessage={<p>필터를 초기화 하시겠습니까?</p>}
                        onCancel={() => setResetModal(false)}
                        cancelText="취소"
                        buttonText="확인"
                        onConfirm={() => {
                            resetFiltetParams();
                            setPrice("");
                            setResetModal(false);
                        }}
                    />
                )
            }
        </>
    );
};

export default StoreGoStopList;

const goStopWrap = css`
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: hidden;
    .buttonDiv {
        position: fixed;
        bottom: 28px;
        left: 14px;
        width: 100%;
        height: 56px;
        z-index: 99;
        button {
            width: calc(100% - 28px);
            height: 56px;
            border-radius: 14px;
            padding: 14px;
            background-color: #ff0000;
            color: white;
            display: flex;
            white-space: pre-wrap;
            align-items: center;
            justify-content: center;
            box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            border: none;
            outline: none;
            font-family: "GmarketSansTTF", sans-serif;
        }
    }
    .titleWrap {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 14px;
        align-items: center;
        .BtnWrap {
            position: absolute;
            left: 0;
            width: 10px;
            z-index: 1;
            height: 17px;
            display: flex;
            width: 100%;
            padding: 0 14px;
            justify-content: space-between;
            .closeBtn {
                width: 14px;
                height: 14px;
            }
            .backBtn {
                width: 10px;
                height: 16px;
            }
        }
        .title {
            font-weight: 700;
        }
    }

    .dim {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        z-index: 99;
        background: rgba(0, 0, 0, 0.7);
    }
    .typeWrap {
        display: flex;
        position: relative;
        height: 56px;
        border-radius: 14px;
        background: #f0f0f0;
        margin: 14px 14px 0 14px;
        justify-content: center;
        align-items: center;
        color: #0047ff;
        text-align: center;
        font-family: Gmarket Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
    }
    .typeBtnWrap {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 14px;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .line {
        height: 6px;
        background-color: #f7f8fa;
    }
    .contentsWrap {
        width: 100%;
        padding: 14px;
    }
    .filterWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .lottie {
        width: 60px;
        margin: 150px auto;
    }
    .dropdownFilter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        overflow: hidden;
    }
    .dropdownWrap {
        height: 30px;
    }

    .selectedOption {
        height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        img {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }
    .selectedLockOption {
        height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        color: #8d8d8d;
        font-weight: 500;
        img {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }
    .options {
        position: fixed !important;
        z-index: 100;
        width: 100vw;
        left: 0;
        padding: 24px 24px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 14px 14px 0px 0px;
        animation: appearBottomSheet 0.4s linear forwards;
        @keyframes appearBottomSheet {
            from {
                opacity: 0;
                height: 0;
                bottom: -50%;
            }

            to {
                opacity: 1;
                height: fit-content;
                bottom: 0;
            }
        }
    }
    .sortTypeWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        span {
            width: 74px;
            text-align: center;
            color: #0c002c;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
            margin-right: 10px;
        }
    }
    .btnContainer {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        .dropdownClickedItem {
            background: #0047ff;
            color: #fff;
            font-weight: 800;
            width: 100%;
            height: 46px;
            border-radius: 8px;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }
        .dropdownItem {
            width: 100%;
            height: 46px;
            border-radius: 8px;
            background: #fbf8f8;
            color: #0c002c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }
    }
    .totalCntWrap {
        margin-top: 3px;
    }
    .observer {
        height: 30px;
        width: 100%;
    }
`;
const lottieBoxStyle = css`
    .lottie {
        width: 80px;
        margin: 0 auto;
    }
`;

const filterBox = css`
    padding: 0px 14px;
    .filterTitle {
        font-size: 16px;
        font-weight: bold;
        color: #282828;
    }

    .priceTitle {
        font-size: 24px;
        font-weight: bold;
        color: #0c002c;
        & > span {
            color: #0047FF;
        }
    }
`;

const filterLock = css`
    position: relative;
    .filterWrap {
        position: absolute;
        top: 10px;
        width: 100%;
        height: 40px;
        background: rgba(0, 0, 0, 0.2);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            display: flex;
            justify-content: center;
            align-items: center;
            /* position: absolute;
            top: 10px; */
            width: 19.98px;
            height: 24px;
        }
    }
`;

const noMoreWrap = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #0c002c;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 124px;
    img {
        width: 60px;
        height: 60px;
        margin-bottom: 14px;
    }
`;

const StyledVimeoLoading = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 500px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;
    position: absolute;
    & > div {
        width: 106px;
        height: 63px;
        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;
