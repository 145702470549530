import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const PostData = async (params?: any) => {
    const withoutUndefined = params.value.replace("undefined;", "");
    const response = await axiosInstance.post(`${axiosPath.searchedList}`, {
        ...params,
        value: withoutUndefined,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload.data || [];

    return responseData;
};
const GetPostSearchedList = async (params: any) => {
    return await PostData(params);
};

export type GetPostSearchedListResponseType = {
    errorCode: number;
    list: GetPostSearchedListTypes[];
    total: number;
};

export type GetPostSearchedListTypes = {
    user_no?: number;
    name: string;
    value: string;
};
export { GetPostSearchedList };
