import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import Lottie from "lottie-react";
import loading from "../../assets/Loading.json";

interface LoadingInfoCardProps {
    open: boolean;
    text?: string;
    content?: string;
    onCloseView: () => void;
    count?: number;
    isFetching?: boolean;
    bottomCheck: string;
}

const LoadingInfoCard = ({ open, text, content, onCloseView, count, isFetching, bottomCheck }: LoadingInfoCardProps) => {
    const [step, setStep] = useState<number>(1);
    useEffect(() => {
        let timeout: any;
        if (open && !isFetching) {
            timeout = setTimeout(() => {
                setStep(2);
            }, 1000);
        }
        return () => {
            setStep(1);
            clearTimeout(timeout);
        };
    }, [isFetching]);

    useEffect(() => {
        let timeout: any;
        if (step === 2) {
            timeout = setTimeout(() => {
                onCloseView();
                setStep(1);
            }, 1500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [step]);
    return (
        <div css={loadingCardWrapper(bottomCheck)}>
            {step === 1 && (
                <div css={loadingBoxStyle}>
                    <Lottie className="lottie" animationData={loading} />
                    <p className="desc">{text}</p>
                </div>
            )}
            {step === 2 && (
                <div css={completeBoxStyle}>
                    <p>{content}</p>
                    <p className="countText">{count?.toLocaleString()}건 조회</p>
                </div>
            )}
        </div>
    );
};

export default LoadingInfoCard;

const loadingCardWrapper = (bottomCheck: string) => css`
    position: absolute;
    bottom: ${bottomCheck};
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    width: 128px;
    height: 96px;
    padding: 14px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    z-index: 100;
`;

const loadingBoxStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    position: relative;
    height: 80px;
    & > .lottie {
        width: 144px;
        height: 100px;
        position: absolute;
        top: -30px;
    }
    & > .desc {
        position: absolute;
        bottom: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
`;

const completeBoxStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    & > .countText {
        color: #0047ff;
    }
`;
