/**
 * [경매 타입] 0 : 전체, 1 : 경매, 2 : 공매
 */

export const transferStringAuctionType = (status: number) => {
    const returnStatus = new Map([
        [0, "전체"],
        [1, "경매"],
        [2, "공매"],
    ]);

    return returnStatus.get(Number(status)) ?? "";
};
