import { css } from "@emotion/react";
import React, { useRef, useState } from "react";
import WhitePenIcon from "@src/assets/ico_white_pen.svg";
import RedPenIcon from "@src/assets/ico_red_pen.svg";
import BluePenIcon from "@src/assets/ico_blue_pen.svg";
import YellowPenIcon from "@src/assets/ico_yellow_pen.svg";
import ErasePenIcon from "@src/assets/ico_erase_pen.svg";
import ClearIcon from "@src/assets/ico_draw_clear.svg";
import NextIcon from "@src/assets/ico_image_next_active.svg";
import NextActiveIcon from "@src/assets/ico_image_next.svg";
import BackIcon from "@src/assets/ico_image_back.svg";
import BackActiveIcon from "@src/assets/ico_image_back_active.svg";
import { Common } from "@src/styles/Common";
import ConfirmModal from "@src/components/popup/ConfirmModal";

interface Props {
    onChangeDrawingType: (t: string) => void;
    onChangeShowConvenienceBar: () => void;
    currentIndex: number;
    imageLength: number;
    onClickBack: () => void;
    onClickNext: () => void;
    onClickClear: () => void;
}

const SELECT_LIST = [
    { image: WhitePenIcon, value: "#FFFFFF" },
    { image: RedPenIcon, value: "#FF0000" },
    { image: BluePenIcon, value: "#0047FF" },
    { image: YellowPenIcon, value: "#F1BB40" },
    { image: ErasePenIcon, value: "none" },
    { image: ClearIcon, value: "clear" },
];

const DrawingHelper: React.FC<Props> = ({
    onChangeDrawingType,
    onChangeShowConvenienceBar,
    onClickBack,
    onClickClear,
    onClickNext,
    currentIndex,
    imageLength,
}) => {
    const drawingToolRef = useRef<HTMLDivElement | null>(null);
    const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
    return (
        <>
            <div css={rootStyle}>
                <div className="drawingTool" ref={drawingToolRef}>
                    {SELECT_LIST.map((s, idx) => (
                        <button
                            className={idx === 0 ? "btn active" : "btn"}
                            key={idx.toString()}
                            onClick={(evt: any) => {
                                drawingToolRef.current?.childNodes.forEach((c: any) => {
                                    c.className = "btn";
                                });
                                if (evt.target.className === "drawingImg") {
                                    evt.target.parentElement.className = "btn active";
                                } else if (evt.target.className === "btn") {
                                    evt.target.className = "btn active";
                                }
                                onChangeDrawingType(s.value);
                            }}
                        >
                            <img src={s.image} alt="" className="drawingImg" />
                        </button>
                    ))}
                </div>
                <div className="drawingTool2">
                    <button
                        onClick={onClickBack}
                        disabled={currentIndex === 0}
                        className={currentIndex === 0 ? "btn" : "btn active"}
                    >
                        <img src={currentIndex === 0 ? BackIcon : BackActiveIcon} alt="" />
                    </button>
                    <button
                        onClick={onClickNext}
                        disabled={imageLength === currentIndex}
                        className={imageLength === currentIndex ? "btn" : "btn active"}
                    >
                        <img src={imageLength === currentIndex ? NextActiveIcon : NextIcon} alt="" />
                    </button>
                    <button
                        onClick={() => setIsOpenDialog(true)}
                        disabled={currentIndex === 0}
                        className={currentIndex === 0 ? "btn" : "btn active"}
                    >
                        전체삭제
                    </button>
                </div>
                <div className="convenience">
                    <button onClick={onChangeShowConvenienceBar}>편의</button>
                </div>
            </div>
            {isOpenDialog && (
                <ConfirmModal
                    resultMessage="메모를 다시 작성하시겠습니까?"
                    onConfirm={() => {
                        setIsOpenDialog(false);
                        onClickClear();
                    }}
                    onCancel={() => setIsOpenDialog(false)}
                    buttonText={"확인"}
                    disabled={false}
                />
            )}
        </>
    );
};

const rootStyle = css`
    position: absolute;
    right: 2px;
    top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .drawingTool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        border-radius: 24px;
        padding: 8px;
        .btn {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            margin: 0 5px;
            cursor: pointer;
        }
        .btn.active {
            background-color: #dff0ff;
        }
        .drawingImg {
            margin-top: 4px;
            width: 22px;
            height: 22px;
        }
        button:nth-of-type(6) img {
            margin-top: 4px;
            width: 16px;
            height: 16px;
        }
    }
    .drawingTool2 {
        position: absolute;
        margin-top: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        border-radius: 24px;
        padding: 4px 8px;
        .btn {
            border-radius: 50%;
            padding: 5px;
            margin: 0 5px;
            color: #bebebe;
            cursor: pointer;
            img {
                width: 14px;
                height: 14px;
                margin-top: 2px;
            }
        }
        .btn.active {
            color: #2275ee;
        }
        .drawingImg {
            margin-top: 4px;
            width: 22px;
            height: 22px;
        }
        button:nth-of-type(6) img {
            margin-top: 4px;
            width: 16px;
            height: 16px;
        }
    }
    .convenience {
        position: absolute;
        right: 8px;
        @media screen and (max-width: 375px) {
            & {
                bottom: -50px;
            }
        }
        button {
            width: 44px;
            height: 44px;
            border-radius: 100%;
            text-align: center;
            font-size: 12px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transition: all 0.3s;
            white-space: nowrap;
            background: ${Common.colors.aucMarkerColor};
            color: #fff;
            border-color: #fff;
            cursor: pointer;
        }
    }
`;

export default DrawingHelper;
