import styled from "@emotion/styled";
import AiBottomSheet from "@src/components/aiFilter/AiBottomSheet";
import AiFooter from "@src/components/aiFilter/AiFooter";
import AiHeader from "@src/components/aiFilter/AiHeader";
import AiResultTotal from "@src/components/aiFilter/AiResultTotal";
import AiTitle from "@src/components/aiFilter/AiTitle";
import { BOTTOM_SHEET_HEIGHT, MAX_Y, MIN_Y } from "@src/components/aiFilter/Constants";
import BottomSheet from "@src/components/bottomSheet/BottomSheet";
import BottomSheetContents from "@src/components/bottomSheet/BottomSheetContents";
import FilterFooter from "@src/components/filter/FilterFooter";
import PurposeFilter from "@src/components/filter/PurposeFilter";
import ToastMessage from "@src/components/filter/ToastMessage";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { useAiBottomSheet } from "@src/hooks/filter/useAiBottomSheet";
import useGetPurpose from "@src/hooks/filter/useGetPurpose";
import { transferToNative } from "@src/lib/WebAppBridge";
import SliceImg from "@src/assets/tutorial_investment.png";
import { FilterParamsType, useAiFilterStore } from "@src/store/AiFilterStore";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AiResetConfirmModal from "./AiResetConfirmModal";
import { css } from "@emotion/react";
import useCallToken from "@src/hooks/useCallToken";
import loading from "@src/assets/Loading.json";
import Lottie from "lottie-react";
import LandUseFilter from "../newFilter/LandUseFilter";

const AiFilterPurposeV2 = () => {
    const [aiSelectedPurpose, setAiSelectedPurpose] = useState<string[]>([]);
    const [resetVisible, setResetVisible] = useState(false);
    const getAiFilterState = useAiFilterStore((state) => state.getAiFilterState);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const getAiFilterCount = useAiFilterStore((state) => state.getAiFilterCount);
    const [visible, setVisible] = useState(false);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const aiFilterType = useAiFilterStore((state) => state.aiFilterState.type);
    const getAiFilterDataList = useAiFilterStore((state) => state.getAiFilterDataList);
    const setAiFilterDataList = useAiFilterStore((state) => state.setAiFilterDataList);
    const sortId = useAiFilterStore((state) => state.selectedSortId);
    const setSortText = useAiFilterStore((state) => state.setSelectedSortText);
    const sortText = useAiFilterStore((state) => state.selectedSortText);
    const [selectedSortId, setSelectedSortId] = useState<number | undefined>(0);
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const [sortStatus, setSortStatus] = useState<string | undefined>("업데이트순");
    const setIsConfirm = useAiFilterStore((state) => state.setIsConfirm);
    const deleteAiFilterState = useAiFilterStore((state) => state.deleteAiFilterState);
    const navigate = useNavigate();
    const setIsShowGuidePopup = useAiFilterStore((state) => state.setIsShowGuidePopup);
    const [flag, setFlag] = useState(false);
    const { pathname } = useLocation();
    const { state } = useLocation() as {
        state: FilterParamsType;
    };
    const [searchParams] = useSearchParams();
    const [hiddenImg, setHiddenImg] = useState<boolean>(!!searchParams?.get("from"));
    const [fromExist, setFromExist] = useState<boolean>(!!searchParams?.get("from"));

    const { sheetRef, showAiBottomSheet } = useAiBottomSheet();
    // useEffect(() => {
    //     setAiFilterState(state);
    // }, []);

    //용도
    const {
        handleSelect: handlePurposeSelect,
        purposeState,
        query,
        handleSelectAll,
        onClickRemove: onClickRemovePurpose,
        resetPurpose,
        toastVisible,
        setToastVisible,
        setSubmitPurposeData,
        submitPurposeData,
    } = useGetPurpose(state?.use ?? aiFilterState.use);
    const { token } = useCallToken();
    useEffect(() => {
        if (token.length) {
            setFlag(true);
            pathname.includes("ai") && getAiFilterState("0");
            setTimeout(() => {
                setAiFilterDataList(
                    {
                        ...aiFilterState,
                    },
                    sortId,
                );
            }, 20);
        }
    }, [token]);

    useEffect(() => {
        if (JSON.stringify(aiSelectedPurpose) !== JSON.stringify(aiFilterState.use) && token) {
            if (!isEmpty(aiSelectedPurpose) || purposeState["전체"]?.isActive) {
                if (flag) {
                    setAiFilterState(
                        {
                            ...aiFilterState,
                            use: aiSelectedPurpose.length
                                ? aiSelectedPurpose
                                : purposeState["전체"]?.isActive && !fromExist
                                ? []
                                : aiFilterState.use,
                            type: aiFilterType,
                        },
                        sortId,
                    );
                    setAiFilterDataList(
                        {
                            ...aiFilterState,
                            use: aiSelectedPurpose.length
                                ? aiSelectedPurpose
                                : purposeState["전체"]?.isActive && !fromExist
                                ? []
                                : aiFilterState.use,
                            type: aiFilterType,
                        },
                        sortId,
                    );
                    setFromExist(false);
                }
            }
        }
    }, [aiSelectedPurpose]);

    useEffect(() => {
        // console.log("submitPurposeData2", purposeState["전체"]?.isActive);
        if (flag) {
            if (aiSelectedPurpose.length !== 0 || purposeState["전체"]?.isActive) {
                setAiFilterState(
                    {
                        ...aiFilterState,
                        use: aiSelectedPurpose.length
                            ? aiSelectedPurpose
                            : purposeState["전체"]?.isActive && !fromExist
                            ? []
                            : aiFilterState.use,
                        type: aiFilterType,
                    },
                    sortId,
                );
                setAiFilterDataList(
                    {
                        ...aiFilterState,
                        use: aiSelectedPurpose.length
                            ? aiSelectedPurpose
                            : purposeState["전체"]?.isActive && !fromExist
                            ? []
                            : aiFilterState.use,
                        type: aiFilterType,
                    },
                    sortId,
                );
                setFromExist(false);
            }
        }
    }, [aiFilterType]);

    useEffect(() => {
        // console.log("submitPurposeData3", purposeState["전체"]?.isActive);
        if (flag) {
            if (aiSelectedPurpose.length !== 0 || purposeState["전체"]?.isActive) {
                setAiFilterState(
                    {
                        ...aiFilterState,
                        use: aiSelectedPurpose.length || purposeState["전체"]?.isActive ? aiSelectedPurpose : aiFilterState.use,
                        type: aiFilterType,
                    },
                    sortId,
                );
                setAiFilterDataList(
                    {
                        ...aiFilterState,
                        use: aiSelectedPurpose.length || purposeState["전체"]?.isActive ? aiSelectedPurpose : aiFilterState.use,
                        type: aiFilterType,
                    },
                    sortId,
                );
            }
            setSortText(sortText);
        }
    }, [selectedSortId]);

    // 용도

    // useMemo 디펜던시에 submitPurposeData 추가했습니다.
    const purposeArea = useMemo(
        () => (
            <FilterContainer>
                <FilterFooter
                    style={{ height: 52 }}
                    purposeState={purposeState}
                    onClickRemovePurpose={onClickRemovePurpose}
                    onSubmit={() => console.log("용도!")}
                    setAiSelectedPurpose={setAiSelectedPurpose}
                    type={"AI"}
                    setSubmitPurposeData={setSubmitPurposeData}
                />
                <PurposeFilter
                    // style={{
                    //     height: Math.floor(
                    //         window.innerHeight *
                    //             (window.innerHeight < 710
                    //                 ? 0.260625
                    //                 : window.innerHeight < 750
                    //                 ? 0.290625
                    //                 : window.innerHeight < 780
                    //                 ? 0.300625
                    //                 : 0.340625),
                    //     ),
                    // }}
                    style={{ height: Math.floor(window.innerHeight * 0.3) }}
                    handleSelectAll={handleSelectAll}
                    handleSelect={handlePurposeSelect}
                    purposeState={purposeState}
                    query={query}
                    submitPurposeData={submitPurposeData}
                />
                {/* <LandUseFilter filterParams={aiFilterState as any} */}
            </FilterContainer>
        ),
        [purposeState, query, submitPurposeData],
    );

    const handleSubmit = async (aiSelectedPurpose: string[]) => {
        if (isEmpty(state.use)) {
            await setAiFilterState(
                {
                    ...state,
                    use: aiSelectedPurpose,
                },
                sortId,
            );
        } else {
            await setAiFilterState(
                {
                    ...state,
                    use: aiSelectedPurpose,
                },
                sortId,
            );
        }
    };

    // const onClickNext = async () => {
    //     await handleSubmit(aiSelectedPurpose);
    //     transferToNative("done", "finishAiSetting");
    //     transferToNative("done", "closeWebView");
    // };

    const onClickReset = () => {
        setResetVisible(true);
    };

    const onClickCancel = () => {
        setResetVisible(false);
    };

    const onClickConfirm = async () => {
        // await handleSubmit([]);
        setIsConfirm(true);
        // setAiFilterState({
        //     ...aiFilterState,
        //     price: "0",
        //     loc: [],
        //     use: [],
        //     type: "0",
        // });
        setAiFilterState({
            price: "",
            type: "0",
            loc: [],
            use: [],
        });
        setAiFilterDataList(
            {
                price: "",
                use: [],
                type: "0",
                loc: [],
            },
            5,
            "setting",
        );
        deleteAiFilterState();
        handlePurposeSelect("전체", "중분류", undefined, "All");
        resetPurpose();
        setAiSelectedPurpose([]);

        setResetVisible(false);
        setIsShowGuidePopup(false);
        pathname.includes("ai")
            ? navigate("/aiFilterv2", { state: { ...aiFilterState, price: "", loc: [], use: [], type: "0" } })
            : navigate("/smallFilter", { state: { ...aiFilterState, price: "", loc: [], use: [], type: "0" } });
    };

    const onClickBack = () => {
        pathname.includes("ai")
            ? navigate("/aiFilterv2/locationv2", { state: { ...aiFilterState, use: aiSelectedPurpose } })
            : navigate("/smallFilter/locationv2", { state: { ...aiFilterState, use: aiSelectedPurpose } });
    };

    const onClickClose = () => {
        setIsShowGuidePopup(true);
        transferToNative("done", "closeWebView");
    };

    // const onClickShowList = () => {
    //     console.log("showlist");

    //     console.log("asdfdsaf");
    //     sheetRef.current?.style.setProperty("transform", `translateY(${MIN_Y - MAX_Y}px)`);
    // };

    const BottomSheetArea = useMemo(
        () => (
            <BottomSheet visible={visible}>
                <BottomSheetContents setSelectedSortId={setSelectedSortId} setSortStatus={setSortStatus} type="Ai" />
            </BottomSheet>
        ),
        [visible, selectedSortId],
    );

    return (
        <>
            <Container>
                <AiHeader onClickBack={onClickClose} />
                <AiTitle>
                    <h2>
                        관심있는 <span className="color-text">용도</span>를 선택해주세요.
                    </h2>
                    {/* <p>
                        선택한 소재지에 따라 맞춤형
                        <br />
                        매물 정보를 확인할 수 있어요
                    </p> */}
                </AiTitle>
                {/* <AiResultTotal result={aiFilterCount} /> */}
                {/* <Border /> */}
                {aiFilterCount === "0" ? (
                    <StyledLottie>
                        <Lottie className="lottie" animationData={loading} />
                    </StyledLottie>
                ) : (
                    <>
                        {purposeArea}
                        <div onTouchStart={() => setHiddenImg(false)} onMouseOver={() => setHiddenImg(false)}>
                            <AiFooter
                                style={{ top: `${MAX_Y - 88}px` }}
                                isReset
                                isPrev
                                isShowList
                                onClickPrev={onClickBack}
                                onClickReset={onClickReset}
                                onClickShowList={showAiBottomSheet}
                            />
                            {hiddenImg && (
                                <div css={tutorialImg}>
                                    <img src={SliceImg} alt="" />
                                </div>
                            )}
                            <AiBottomSheet price="" visible={visible} setVisible={setVisible} />
                        </div>
                    </>
                )}
            </Container>
            {BottomSheetArea}
            {resetVisible ? (
                <AiResetConfirmModal text="필터를 초기화할까요?" onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />
            ) : (
                <></>
            )}
            <ToastMessage visible={toastVisible} setToastVisible={setToastVisible}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
        </>
    );
};

export default AiFilterPurposeV2;

const StyledLottie = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    & > .lottie {
        width: 28%;
    }
`;

const Container = styled.div`
    position: relative;
    padding: 0px 14px;
    height: 100vh;
`;

const Border = styled.div`
    width: 100vw;
    margin-left: -14px;
    height: 12px;
    background: #edf0f6;
`;

const FilterContainer = styled.div`
    position: relative;
    background: white;
    width: 100vw;
    margin-left: -14px;
    max-height: 470px;

    button + button {
        margin-left: 0px;
    }
`;

const tutorialImg = css`
    position: absolute;
    left: 50%;
    transform: translate(-38%, 0);
    top: ${MAX_Y - 25}px;
    z-index: 999;
    img {
        width: 242px;
    }
`;
