export const Common = {
    colors: {
        white: "#fff",
        black: "#222",

        purple800: "linear-gradient(339.46deg, #5F0080 -26.96%, #5F0080 7.62%, #7B0BA2 47.28%, #AE00EB 104.97%, #E183FF 165.24%)",
        purple600: "radial-gradient(116.28% 116.28% at 0% -16.28%, #634AFD 0%, #634AFD 0.01%, #9A49F2 100%, #9A49F2 100%)",
        purple500: "#9A49F2",
        purple400: "#AE6DF5",
        purple300: "#C292F7",
        purple200: "#D7B6FA",
        purple100: "#EBDBFC",
        purple50: "#F8F1FF",

        purple05: "#DDD8E9",
        purple04: "#E8E5F0",
        purple03: "#EEECF4",
        purple02: "#F5F3FB",
        purple01: "#F5F1FF",

        blue05: "#D8DEE9",
        blue04: "#E5E9F0",
        blue03: "#ECEFF4",
        blue02: "#F6FAFF",
        blue01: "#F7F8FA",

        grey04: "#C3C3C3",
        grey03: "#D7D7D7",
        grey02: "#F0F0F0",
        grey01: "#FDFAFA",
        bgGrey03: "#DADADA",

        purple: "#8F00FF",
        purpleSecondary: "#9365FF",

        gray700: "#666666",
        gray600: "#818181",
        gray500: "#9D9FA5",
        gray400: "#B4B4B9",
        gray300: "#D4D4D8",
        gray200: "#E3E3E6",
        gray100: "#F4F5F7",

        darkGrey04: "#8D8D8D",
        darkGrey03: "#282828",
        darkGrey02: "#3C3C3C",
        darkGrey01: "#505050",
        grey: "#B1AEB8",
        greyPrimary: "#A3A3A3",
        greySecondary: "#B1AEB8",
        bluePrimary: "#A3B0C6",
        blueSecondary: "#CDD5E4",
        navyPrimary: "#0C002C",
        navySecondary: "#382E51",
        normalTextColor: "#0C002C",
        discoColor: "#0A2540",

        orange: "#FF752F",
        blue: "#0047FF",
        bgBlue: "#DFF0FF",
        bgBlue06: "#E9F1FD",
        mint: "#37DCD0",
        bgMint: "#EBFBFA",
        yellow: "#FFF500",
        yellowSecondary: "#FFE3AC",
        lemon: "#FAFF00",
        chartDotColor: "#00FF00",
        solidGreen: "#43CA7B",
        paleGreen: "#C5FFE9",

        lightYellowColor: "#FFF9E4",

        redSecondary: "#FE513F",
        notiColor: "#FF0000",

        success: "#4B87FC",
        error: "#F45B5B",
        focus: "#9A49F2",

        //지도 내 용도 컬러
        landColor: "#63CF12",
        commercialColor: "#F6A72D",
        factoryColor: "#9DA6B3",
        apartmentColor: "#3478F6",
        efficiencyColor: "#A570F7",
        townhouseColor: "#ED78AC",
        detachedHouseColor: "#EA4459",

        //지도 내 마커 컬러
        aucMarkerColor: "#2275EE",
        pubMarkerColor: "#F1BB40",
        realMarkerColor: "#5FCF7E",
        expcMarkerColor: "#F6494B",
        
    },
    mapColor: ["#63CF12", "#F6A72D", "#9DA6B3", "#3478F6", "#A570F7", "#ED78AC", "#EA4459"],
    fontSize: {
        fs18: "18px",
        fs16: "16px",
        fs14: "14px",
        fs12: "12px",
        fs11: "11px",
    },
    textStyle: {
        display1_B18: `
        font-size: 18px;
        font-weight: 600;
        line-height: 165%;
    `,
        display2_M18: `
        font-size: 18px;
        font-weight: 500;
        line-height: 165%;
    `,
        display3_R18: `
        font-size: 18px;
        font-weight: 400;
        line-height: 175%;
    `,
        title1_B16: `
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.4px;
    `,
        title2_M16: `
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.4px;
    `,
        title3_R16: `
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.4px;
    `,
        title4_B14: `
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
    `,
        title5_M14: `
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
    `,
        title6_R14: `
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
    `,
        title7_M12: `
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
    `,
        title8_R12: `
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
    `,
        body1_M16: `
        font-size: 16px;
        font-weight: 500;
        line-height: 175%;
        letter-spacing: -0.4px;
    `,
        body2_R16: `
        font-size: 16px;
        font-weight: 400;
        line-height: 175%;
        letter-spacing: -0.4px;
    `,
        body3_R14: `
        font-size: 14px;
        font-weight: 400;
        line-height: 170%;
    `,
        body4_R12: `
        font-size: 12px;
        font-weight: 400;
        line-height: 180%; 
    `,
        caption_R11: `
        font-size: 11px;
        font-weight: 400;
        line-height: 165%;
    `,
        text1_R13: `
        font-size: 13px;
        font-weight: 400;
        line-height: 160%;
    `,
        mapMarker_10_medium: `
        font-size: 10px;
        line-height: 10px;
        font-weight: 500;

    `,
        mapMarker_11_medium: `
        font-size: 11px;
        line-height: 11px;
        font-weight: 500;
    `,
        mapMarker_12_medium: `
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
    `,
        mapMarker_12_bold: `
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;

    `,
        mapMarker_13_medium: `
        font-size: 13px;
        line-height: 13px;
        font-weight: 500;
    `,
        mapMarker_13_bold: `
        font-size: 13px;
        line-height: 13px;
        font-weight: 700;

    `,
        mapMarker_14_medium: `
        font-size: 14px;
        line-height: 14px;
    `,
    },
};
