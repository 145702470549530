import Title from "@src/components/title/Title";
import { transferToNative } from "@src/lib/WebAppBridge";
import React, { useEffect, useRef, useState } from "react";
import backArrow from "@src/assets/ico_arrow_back.png";
import { Common } from "@src/styles/Common";
import useCallToken from "@src/hooks/useCallToken";
import box from "../../assets/charginUseBox.png";
import Lottie from "lottie-react";
import loading from "../../assets/Loading.json";
import { ToastType } from "../deungi/DeungiTicketCancelList";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import TicketCancelItem from "../deungi/TicketCancelItem";
import { css } from "@emotion/react";
import { useFindAllStateLandPayments } from "@src/hooks/stateLand/useStateLand";
import { useSaverPgRefund, useSaverPgRefundV2 } from "@src/hooks/payment/useRefund";
import CancelModal from "./CancelModal";

const StateLandRefund = () => {
    const [styleHeight, setStyleHeight] = useState<number>(318);
    const guideTextRef = useRef<any>(null);
    const { token } = useCallToken();
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [refundId, setRefundId] = useState<any>();
    const [toast, setToast] = useState<ToastType>({ showToast: false, toastMessage: "" });
    const [isStoreModalShow, setIsStoreModalShow] = useState<boolean>(false);
    const [isNotPossibleModalShow, setIsNotPossibleModalShow] = useState<boolean>(false);
    const [isNotPossibleModalContent, setIsNotPossibleModalContent] = useState<any>(<div />);
    const { data, refetch, isLoading, fetchNextPage, hasNextPage, isFetching } = useFindAllStateLandPayments(
        {
            page: 1,
            limit: 20,
        },
        true,
    );
    const StoreModal = (
        <div css={storeModal}>
            <div className="title">취소 불가</div>
            인앱 스토어 결제의 경우
            <br />
            취소/환불은 APP Store, Google Play 스토어 앱에서 가능합니다
        </div>
    );
    const onChangeNotPossibleModal = (val: string) => {
        if (new Date(val) >= new Date("2024-01-10")) {
            setIsNotPossibleModalContent(
                <div css={storeModal}>
                    <div className="title">취소 불가</div>
                    국공유지 필터를 사용하거나 구매한 지 8일이 지나 직접 환불이 불가한 결제내역입니다
                    <br />
                    <br />
                    고객센터 1:1 문의하기로 문의주시기 바랍니다
                </div>,
            );
        } else {
            setIsNotPossibleModalContent(
                <div css={storeModal}>
                    <div className="title">취소 불가</div>
                    국공유지 필터를 사용하거나 구매한 지 2일이
                    <br />
                    지난 결제내역입니다
                </div>,
            );
        }
        setIsNotPossibleModalShow(true);
    };
    useEffect(() => {
        if (token) {
            setStyleHeight(guideTextRef.current?.offsetHeight + 106);
        }
    }, [token]);

    useEffect(() => {
        refetch();
    }, []);

    const refundOrder = useSaverPgRefund(
        (res) => {
            refetch();
            res?.payload?.errCode !== -1
                ? setToast({ showToast: true, toastMessage: "취소가 완료되었습니다" })
                : setToast({
                      showToast: true,
                      toastMessage: res?.data?.payload?.message ?? "오류가 발생하였습니다 다시 시도해주세요",
                  });
        },
        () => {
            setToast({ showToast: true, toastMessage: "오류가 발생하였습니다 다시 시도해주세요" });
        },
    );
    const refundOrderToss = useSaverPgRefundV2(
        (res) => {
            refetch();
            res?.payload?.errCode !== -1
                ? setToast({ showToast: true, toastMessage: "취소가 완료되었습니다" })
                : setToast({
                      showToast: true,
                      toastMessage: res?.data?.payload?.message ?? "오류가 발생하였습니다 다시 시도해주세요",
                  });
        },
        () => {
            setToast({ showToast: true, toastMessage: "오류가 발생하였습니다 다시 시도해주세요" });
        },
    );

    const onClickRefund = () => {
        if (refundId) {
            if (refundId.pgAgency === "toss") {
                refundOrderToss.mutate({
                    orderNo: refundId?.orderNo,
                    amount: refundId?.totalPrice,
                    comments: "",
                });
            } else {
                refundOrder.mutate(refundId?.orderNo);
            }
        }
    };

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 0px 0px",
        delayInMs: 1000,
    });
    useEffect(() => {
        if (toast.showToast) {
            setTimeout(() => setToast({ showToast: false, toastMessage: "" }), 2500);
        }
    }, [toast]);

    return (
        <>
            <div css={CancelRefundWrap}>
                <div css={TitleWrap}>
                    <div
                        className="backBtn"
                        onClick={() => {
                            transferToNative("done", "closeWebView");
                        }}
                    >
                        <img src={backArrow} alt="" />
                    </div>
                    <Title title="이용권 취소/환불하기" size="large" />
                </div>
                <div css={ContentsWrap}>
                    <div css={ItemListWrap(styleHeight)}>
                        {data?.pages !== null && data?.pages[0]?.list?.length ? (
                            data?.pages?.map((page: any, idx: number) =>
                                page?.list?.map((item: any, idx: string) => (
                                    <TicketCancelItem
                                        key={item + idx}
                                        item={item}
                                        setIsModalShow={setIsModalShow}
                                        setRefundId={setRefundId}
                                        setToast={setToast}
                                        setIsStoreModal={setIsStoreModalShow}
                                        setIsNotPossibleModalShow={setIsNotPossibleModalShow}
                                        onChangeIsNotPossibleModal={onChangeNotPossibleModal}
                                    />
                                )),
                            )
                        ) : isLoading ? (
                            <div css={lottieDiv}>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </div>
                        ) : (
                            data?.pages !== null && (
                                <div css={emptyList(styleHeight)}>
                                    <img src={box} alt="" />
                                    <div>결제한 이용권이 없어요</div>
                                </div>
                            )
                        )}
                        <div className="observed" ref={sentryRef} />
                    </div>
                    {isModalShow && <CancelModal setIsModalShow={setIsModalShow} onClickRefund={onClickRefund} />}
                    {isStoreModalShow && (
                        <ConfirmModal
                            resultMessage={StoreModal}
                            buttonText="확인"
                            onConfirm={() => {
                                setIsStoreModalShow(false);
                            }}
                        />
                    )}
                    {isNotPossibleModalShow && (
                        <ConfirmModal
                            resultMessage={isNotPossibleModalContent}
                            buttonText="확인"
                            onConfirm={() => {
                                setIsNotPossibleModalShow(false);
                                setIsNotPossibleModalContent(<div />);
                            }}
                        />
                    )}
                    <div css={toastStyle(toast.showToast)}>{toast.toastMessage}</div>
                    <div css={GuideText} ref={guideTextRef}>
                        국•공유지 필터 이용권 환불/취소 안내
                        <li>
                            “국•공유지 검색 이용권”는 결제 시부터 7일 이내 서비스 미이용 시 전액 환불 받을 수
                            있습니다.(서비스이용기준은 로그기준으로 합니다.)
                        </li>
                        <li>
                            결제 후 사용내역이 있거나 결제 시부터 7일 이후에는 할인혜택이 소멸되어 정상금액에서 결제일부터
                            해지신청일까지의 이용일수에 해당하는 금액(=이용금액) 및 상품 정가 금액(결제 금액이 아님)의
                            20%(=위약금)를 제외한 나머지 금액이 환불됩니다.
                        </li>
                        <li>구매 취소나 환불 시에는 결제한 수단으로 환불됩니다</li>
                        <li>
                            구매 후 7일 이내임에도 ‘내정보&gt;고객센터&gt;자주묻는질문&gt;국•공유지 필터 이용권 취소/환불하기’에{" "}
                            {"취소 불가"} 상태라면 다음 중 하나 이상의 경우에 해당합니다.1) 공유지 필터를 사용하여 직접 환불이
                            불가능한 경우2) Google Play 결제를 통해 구매한 경우3) iOS APP Store 결제를 통해 구매한 경우
                        </li>
                        <li>단, iOS APP Store 결제로 구매한 이용권의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.</li>
                        <li>
                            Google Play 결제로 구매한 이용권 는 구매 후 48시간 이내에는 Google Play 고객센터를 통해, 48시간
                            이후에는 고객센터를 통해 구매 취소할 수 있습니다.
                        </li>
                        <li>구매 취소나 환불 시에는 결제한 수단으로 환불됩니다.</li>
                        <li>구독 혜택 등 무료로 지급된 이용권은 환불 대상에서 제외됩니다</li>
                        <li>본 약관은 2024년 1월 10일부터 시행됩니다</li>
                        <br />
                        <br />
                        이전약관
                        <li
                            className="linkLi"
                            onClick={() => {
                                const params = {
                                    title: "국•공유지 필터 이용권 결제 이용안내",
                                    url: "https://www.notion.so/triplenine/2024-1-9-4d048b55f0714c7cbe383413706e4cd6?pvs=4",
                                };
                                transferToNative(JSON.stringify(params), "openExternalMap");
                            }}
                        >
                            2024년 1월 9일까지
                        </li>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StateLandRefund;

const CancelRefundWrap = css`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const TitleWrap = css`
    display: flex;
    justify-content: center;
    position: fixed;
    background: #ffffff;
    width: 100%;
    z-index: 22;
    padding: 14px;
    align-items: center;
    & > .backBtn {
        position: absolute;
        left: 14px;
        width: 10px;
        height: 17px;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
    & > .title {
        font-weight: 700;
    }
`;

const GuideText = css`
    background-color: ${Common.colors.grey01};
    color: ${Common.colors.greyPrimary};
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 20px 14px;
    margin-top: 30px;
    box-sizing: border-box;
    li {
        list-style: inside;
    }
    & > .linkLi {
        text-decoration-line: underline;
        text-underline-position: under;
    }
`;

const ContentsWrap = css`
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 56px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    ::-webkit-scrollbar {
        display: none;
    }
    & > .observed {
        height: 30px;
        width: 100%;
    }
`;

const ItemListWrap = (styleHeight: number) => css`
    min-height: ${`calc(calc(var(--vh, 1vh) * 100) - ${styleHeight}px)`};
    min-height: ${styleHeight && `calc(100vh - ${styleHeight}px)`};
`;

const lottieDiv = css`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    height: calc(100vh - 222px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const emptyList = (styleHeight: number) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    gap: 24px;
    height: ${styleHeight && `calc(calc(var(--vh, 1vh) * 100) - ${styleHeight}px)`};
    height: ${styleHeight && `calc(100vh - ${styleHeight}px)`};
    & > img {
        width: 125px;
        height: 84px;
    }
    & > div {
        color: ${Common.colors.normalTextColor};
        font-size: 17px;
        font-weight: 400;
    }
`;
const storeModal = css`
    width: 100%;
    text-align: center;
    line-height: 24px;
    ${Common.textStyle.body3_R14};
    & > .title {
        ${Common.textStyle.body2_R16};
        margin-bottom: 8px;
    }
`;

const toastStyle = (isShowToast: boolean) => css`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${isShowToast ? 0.7 : 0};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 50px;
    border-radius: 14px;
    position: absolute;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
`;
