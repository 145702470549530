import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { IGoStopFilterType, IGoStopResponseData, IGoStopResListDto } from "./dto/goStop.dto";

const fetchGoStop = async (params: IGoStopFilterType) => {
    const response = await axiosInstance
        .post(`${axiosPath.goStopList}`, params, {
            timeout: 10000, //속도개선 후 제거예정
        })
        .then((res) => {
            if (!res?.status) {
                throw new Error("Problem fetching data");
            }
            const responseData = res?.data ?? [];
            return responseData;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return err;
        });
    return response;
};

export const useGoStopList = (filters: any, enabled: boolean = false) => {
    return useInfiniteQuery<any>(
        ["goStopList", filters],
        ({ pageParam }) => fetchGoStop({ ...filters, cursor: pageParam ?? filters?.cursor }),
        {
            getNextPageParam: (lastPage: any, pages: any) => {
                const newPage = pages?.[0]?.totalCnt > pages?.length * 20 ? lastPage.cursor : undefined;
                return newPage;
            },
            enabled,
        },
    );
};

//* 경공매 상세 정보
export const useAuctionDetail = (auctionId: string, enabled: boolean = false) => {
    const fetchAuctionDetail = async () => {
        const response = await axiosInstance.get(`${axiosPath.itemDetail}/${auctionId}`, {
            timeout: 10000,
        });
        // if (!response.status) throw new Error('Problem fetching data');
        return response?.data;
    };

    return useQuery(
        [
            'auctionDetail',
            auctionId,
            typeof window !== 'undefined' && localStorage?.getItem('userSession')
                ? JSON.parse(localStorage?.getItem('userSession') ?? '')?.value?.accessToken
                : '',
        ],
        fetchAuctionDetail,
        {
            enabled,
            retry: 1,
        },
    );
};

const getUserSubs = async () => {
    const response = await axiosInstance.get(`${axiosPath.userSubs}`);
    return response.data;
};

const getGoStopSubs = async () => {
    const response = await axiosInstance.get(`${axiosPath.gostopSubs}`);
    return response.data;
}

export const useGoStopSubs = () => {
    return useQuery("goStopSubs", getGoStopSubs);
};

export const useUserSubs = () => {
    return useQuery("userSubs", getUserSubs);
};

export const useSaverGoStopFilter = (
    onSuccess: (v: any) => void = () => { },
    onError: (v: any) => void = (err) => {
        console.log("err");
    },
) => {
    return useMutation(
        async (params: any) => {
            const param = {
                key: "gostopFilter",
                value: JSON.stringify(params),
            };
            try {
                const res = axiosInstance.post(axiosPath.inventory, param);
                return res;
            } catch (error) {
                console.log("saveERROR", error);
                throw error;
            }
        },
        {
            onSuccess,
            onError,
        },
    );
};

const getInventoryInfo = async (type: string) => {
    try {
        const response = await axiosInstance.get(`${axiosPath.inventory}?key=${type}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const useGetGoStopFilters = (type: string) => {
    return useQuery(["infoGoStopFilters", type], () => getInventoryInfo(type));
};
