import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { mapApiParams } from "@src/pages/map/types";
import { initialState } from "../filter/useGetFilter";

const fetchData = async (params: any) => {
    // const response = await axiosInstance.post(`${axiosPath.mapRealPriceAggr}`, params);
    const response = await axiosInstance.post(`${axiosPath.newMapAggr}`, params);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload?.list ?? [];

    return responseData;
};
const useGetMapAggregation = (params: any) => {
    return fetchData(params);
};

const initParams: mapApiParams = {
    zoom: 14,
    filter: initialState,
    real_filter: {},
};
export { useGetMapAggregation, initParams };
