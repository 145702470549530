import styled from "@emotion/styled";
import React from "react";
import AiBottomSheet from "./AiBottomSheet";
import AiBottomSheetHeader from "./AiBottomSheetHeader";

const TestAiPage = () => {
    return (
        <>
            <div>testPage</div>
            {/* <AiBottomSheet /> */}
        </>
    );
};

const Wrapper = styled.div`
    height: 100vh;
`;

export default TestAiPage;
