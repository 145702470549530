import { PurposeDataTypes } from "@src/hooks/filter/useGetPurpose";

export const purposeData: PurposeDataTypes = {
    전체: {
        children: [],
        isActive: true,
    },
    주거용: {
        children: [
            {
                title: "전체",
                id: "allA",
                isActive: false,
            },
            {
                title: "아파트",
                id: "A01",
                isActive: false,
            },
            {
                title: "다세대",
                id: "A02",
                isActive: false,
            },
            {
                title: "다가구",
                id: "A03",
                isActive: false,
            },
            {
                title: "단독주택",
                id: "A05",
                isActive: false,
            },
            {
                title: "상가주택",
                id: "A06",
                isActive: false,
            },
            {
                title: "주거용기타",
                id: "A04",
                isActive: false,
            },
        ],
        isActive: false,
    },
    토지: {
        children: [
            {
                title: "전체",
                id: "allB",
                isActive: false,
            },
            {
                title: "대지",
                id: "B01",
                isActive: false,
            },
            {
                title: "농지",
                id: "B02",
                isActive: false,
            },
            {
                title: "임야",
                id: "B03",
                isActive: false,
            },
            {
                title: "도로",
                id: "B04",
                isActive: false,
            },
            {
                title: "잡종지",
                id: "B05",
                isActive: false,
            },
            {
                title: "과수원",
                id: "B06",
                isActive: false,
            },
            {
                title: "공장용지",
                id: "B07",
                isActive: false,
            },
            {
                title: "창고용지",
                id: "B08",
                isActive: false,
            },
            {
                title: "목장용지",
                id: "B09",
                isActive: false,
            },
            {
                title: "주차장",
                id: "B10",
                isActive: false,
            },
            {
                title: "묘지",
                id: "B12",
                isActive: false,
            },
            {
                title: "학교용지",
                id: "B13",
                isActive: false,
            },
            {
                title: "체육용지",
                id: "B14",
                isActive: false,
            },
            {
                title: "종교용지",
                id: "B15",
                isActive: false,
            },
            {
                title: "구거",
                id: "B16",
                isActive: false,
            },
            {
                title: "유지",
                id: "B17",
                isActive: false,
            },
            {
                title: "제방",
                id: "B18",
                isActive: false,
            },
            {
                title: "하천",
                id: "B19",
                isActive: false,
            },
            {
                title: "염전",
                id: "B20",
                isActive: false,
            },
            {
                title: "주유소용지",
                id: "B21",
                isActive: false,
            },
            {
                title: "기타",
                id: "B11",
                isActive: false,
            },
        ],
        isActive: false,
    },
    산업용: {
        children: [
            {
                title: "전체",
                id: "allC",
                isActive: false,
            },
            {
                title: "창고",
                id: "C01",
                isActive: false,
            },
            {
                title: "공장",
                id: "C02",
                isActive: false,
            },
            {
                title: "아파트형공장",
                id: "C04",
                isActive: false,
            },
            {
                title: "기타",
                id: "C03",
                isActive: false,
            },
        ],
        isActive: false,
    },
    "상업/업무용": {
        children: [
            {
                title: "전체",
                id: "allD",
                isActive: false,
            },
            {
                title: "오피스텔",
                id: "D01",
                isActive: false,
            },
            {
                title: "근린상가",
                id: "D02",
                isActive: false,
            },
            {
                title: "사무실",
                id: "D03",
                isActive: false,
            },
            {
                title: "숙박시설",
                id: "D04",
                isActive: false,
            },
            {
                title: "근린시설",
                id: "D06",
                isActive: false,
            },
            {
                title: "숙박시설(콘도 등)",
                id: "D07",
                isActive: false,
            },
            {
                title: "농가관련시설",
                id: "D08",
                isActive: false,
            },
            {
                title: "노유자시설",
                id: "D09",
                isActive: false,
            },
            {
                title: "종교시설",
                id: "D10",
                isActive: false,
            },
            {
                title: "주유소",
                id: "D11",
                isActive: false,
            },
            {
                title: "의료시설",
                id: "D12",
                isActive: false,
            },
            {
                title: "자동차관련시설",
                id: "D13",
                isActive: false,
            },
            {
                title: "장례,묘지시설",
                id: "D14",
                isActive: false,
            },
            {
                title: "분뇨쓰레기처리",
                id: "D15",
                isActive: false,
            },
            {
                title: "관광휴게시설",
                id: "D16",
                isActive: false,
            },
            {
                title: "기타",
                id: "D05",
                isActive: false,
            },
        ],
        isActive: false,
    },
    기타: {
        children: [
            {
                title: "전체",
                id: "allE",
                isActive: false,
            },

            {
                title: "기타",
                id: "E01",
                isActive: false,
            },
        ],
        isActive: false,
    },
};

const removeDuplicateArray = (arrayParam: any[]) => {
    const uniqueArray = arrayParam.filter((item, index) => {
        return arrayParam.indexOf(item) === index;
    });

    return uniqueArray;
};

export const makeUseParam = (arrayParam: string[]) => {
    const result: string[] = [];
    arrayParam.forEach((item) => {
        if (item === "allA") {
            purposeData["주거용"].children.map((item) => {
                if (item.title !== "전체") {
                    result.push(item.id);
                }
            });
        }

        if (item === "allB") {
            purposeData["토지"].children.map((item) => {
                if (item.title !== "전체") {
                    result.push(item.id);
                }
            });
        }

        if (item === "allC") {
            purposeData["산업용"].children.map((item) => {
                if (item.title !== "전체") {
                    result.push(item.id);
                }
            });
        }

        if (item === "allD") {
            purposeData["상업/업무용"].children.map((item) => {
                if (item.title !== "전체") {
                    result.push(item.id);
                }
            });
        }

        if (item === "allE") {
            purposeData["기타"].children.map((item) => {
                if (item.title !== "전체") {
                    result.push(item.id);
                }
            });
        }
    });

    const array = arrayParam.filter((item) => item !== "All");
    const array2 = array.filter((item) => !item.includes("all"));

    return removeDuplicateArray(result.concat(array2));
};
