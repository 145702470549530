import { css } from "@emotion/react";
import { BOTTOM_SHEET_HEIGHT, MAX_Y } from "@src/components/aiFilter/Constants";
import { useAiBottomSheet } from "@src/hooks/filter/useAiBottomSheet";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import AiRecommendBottomSheetTitle from "./AiRecommendBottomSheetTitle";
import {
    useFindAllAiRecommendList,
    useGetAIRecommendSetting,
    useUpdateUserAiSetting,
} from "@src/hooks/aiRecommend/useAiRecommend";
import { useAiRecommendFilterStore, useUserAiSettingSotre } from "@src/store/AiRecommendFilterStore";
import AiRecommendListBox from "./AiRecommendListBox";
import AiRecommendFooter from "./AiRecommendFooter";
import { IPushAgreement, useFindOneUserAiPushAgreement, useUpdateUserPushAgreement } from "@src/hooks/user/userApi";
import { check } from "@src/hooks/buildingRegister/axiosPath";

interface Props {
    sheetRef: any;
    content: any;
    buttonWrapper: any;
    checkedFilter: number;
    sort: any;
    isTouched: any;
    buttonRef1: any;
    buttonRef2: any;
    buttonRef3: any;
    checkShow: any;
    onScrollUp: any;
    showSortBox: boolean;
    onChangeShowSortBox: () => void;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
    onClickReset: () => void;
    handleOpenCheckPushModal: () => void;
    pushAgreement?: IPushAgreement[] | any;
    sendAiPush: any;
    setPrice: (val: any) => void;
    price: any;
    flag: boolean;
}

const AiRecommendBottomSheet = ({
    sheetRef,
    content,
    buttonWrapper,
    sort,
    isTouched,
    buttonRef1,
    buttonRef2,
    price,
    setPrice,
    buttonRef3,
    checkShow,
    showSortBox,
    onScrollUp,
    onChangeShowSortBox,
    setStep,
    step,
    flag,
    checkedFilter,
    onClickReset,
    handleOpenCheckPushModal,
    pushAgreement,
    sendAiPush,
}: Props) => {
    const userAiSettingFilterState = useUserAiSettingSotre((state) => state.userAiSettingState);
    const resultFilter = { ...userAiSettingFilterState };
    delete resultFilter.investmentPrice;

    const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useFindAllAiRecommendList({
        ...userAiSettingFilterState.saleMethod === 2 ? resultFilter : userAiSettingFilterState,
    });
    const { refetch: refetchPushAgreement } = useFindOneUserAiPushAgreement(true);
    const setUserAiSettingFilterState = useUserAiSettingSotre((state) => state.setUserAiSettingState);
    const { data: userAiSetting, refetch: refetchUserAiSetting } = useGetAIRecommendSetting(true);
    const updateUserAiSetting = useUpdateUserAiSetting();
    const updateUserAiSettingWithRefetch = useUpdateUserAiSetting(() => {
        refetchUserAiSetting();
    });
    const updatePushAgreement = useUpdateUserPushAgreement(() => {
        refetchPushAgreement();
    });

    const findSettingIndex = (order: number) => {
        return userAiSetting?.list.findIndex((item) => item.order === order) ?? 0;
    };
    useEffect(() => {
        if (userAiSettingFilterState.bidType !== undefined || userAiSettingFilterState.saleMethod) {
            refetch();
        }
    }, [userAiSettingFilterState]);

    const onClickNext = () => {
        if (step === 1) {
            if (step == 1 && userAiSettingFilterState.bidType !== undefined && flag) {
                if (userAiSettingFilterState.bidType === 2 && userAiSettingFilterState.saleMethod === 2) {
                    if (userAiSetting && userAiSettingFilterState.isFinish === true) {
                        if (
                            userAiSettingFilterState.bidType !==
                            userAiSetting?.list[findSettingIndex(checkedFilter)].itemFilter.bidType
                        ) {
                            updateUserAiSettingWithRefetch.mutate({
                                ...userAiSettingFilterState,
                                isFinish: false,
                            });
                            updatePushAgreement.mutate([
                                {
                                    type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                                    agreement: !!pushAgreement[findSettingIndex(checkedFilter)]?.agreement,
                                    dueDate: [],
                                },
                            ]);
                        }
                    } else {
                        updateUserAiSetting.mutate(userAiSettingFilterState);
                    }
                    setStep((pre) => pre + 2);
                } else {
                    if (userAiSetting && userAiSettingFilterState.isFinish === true) {
                        if (
                            userAiSettingFilterState.bidType !==
                            userAiSetting?.list[findSettingIndex(checkedFilter)].itemFilter.bidType
                        ) {
                            updateUserAiSettingWithRefetch.mutate({
                                ...userAiSettingFilterState,
                                isFinish: false,
                            });
                            updatePushAgreement.mutate([
                                {
                                    type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                                    agreement: false,
                                    dueDate: [],
                                },
                            ]);
                        }
                    } else {
                        updateUserAiSetting.mutate(userAiSettingFilterState);
                    }
                    setStep((pre) => pre + 1);
                }
            }
            return;
        } else if (step === 2) {
            if (step == 2 && userAiSettingFilterState.investmentPrice && flag) {
                if (userAiSetting && userAiSettingFilterState.isFinish === true) {
                    if (
                        userAiSettingFilterState.investmentPrice !==
                        userAiSetting?.list[findSettingIndex(checkedFilter)].itemFilter.investmentPrice
                    ) {
                        updateUserAiSettingWithRefetch.mutate({
                            ...userAiSettingFilterState,
                            isFinish: false,
                        });
                        updatePushAgreement.mutate([
                            {
                                type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                                agreement: false,
                                dueDate: [],
                            },
                        ]);
                    }
                } else {
                    updateUserAiSetting.mutate(userAiSettingFilterState);
                }
            }
            setStep((pre) => pre + 1);
            return;
        } else if (step === 3) {
            if (userAiSettingFilterState.regions && flag) {
                if (userAiSetting && userAiSettingFilterState.isFinish === true) {
                    if (
                        userAiSettingFilterState.regions !==
                        userAiSetting?.list[findSettingIndex(checkedFilter)]?.itemFilter.regions
                    ) {
                        updateUserAiSettingWithRefetch.mutate({
                            ...userAiSettingFilterState,
                            isFinish: false,
                        });
                        updatePushAgreement.mutate([
                            {
                                type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                                agreement: false,
                                dueDate: [],
                            },
                        ]);
                    }
                } else {
                    updateUserAiSetting.mutate(userAiSettingFilterState);
                }
            }
            setStep((pre) => pre + 1);
            return;
        } else if (step === 4) {
            if (userAiSettingFilterState.categories && flag) {
                if (userAiSettingFilterState.isFinish === true && userAiSetting) {
                    if (
                        userAiSettingFilterState.categories !==
                        userAiSetting?.list[findSettingIndex(checkedFilter)].itemFilter.categories
                    ) {
                        updateUserAiSettingWithRefetch.mutate({
                            ...userAiSettingFilterState,
                            isFinish: false,
                        });
                        updatePushAgreement.mutate([
                            {
                                type: `agree_push_recommend_item${checkedFilter === 1 ? "" : "_" + checkedFilter}`,
                                agreement: false,
                                dueDate: [],
                            },
                        ]);
                    }
                } else {
                    updateUserAiSetting.mutate(userAiSettingFilterState);
                }
            }
            handleOpenCheckPushModal();
            return;
        } else return;
    };

    const onClickPrev = () => {
        if (step > 1) {
            if (userAiSettingFilterState.bidType === 2 && userAiSettingFilterState?.saleMethod === 2 && step == 3) {
                setStep(prev => prev - 2);
            } else {
                setStep(prev => prev - 1);
            }
        } else return;
    }

    //! 알림설정관련 여부, 알림관련 네이티브 통신

    return (
        <>
            <div css={rootBoxStyle(step, showSortBox)} ref={sheetRef}>
                <div css={topHandlerStyle}>
                    <div />
                </div>
                <AiRecommendBottomSheetTitle
                    buttonWrapper={buttonWrapper}
                    sort={sort}
                    totalCount={data?.pages?.[0]?.totalCount ?? 0}
                    showSortBox={showSortBox}
                    firstButtonRef={buttonRef1}
                    secondButtonRef={buttonRef2}
                    thirdButtonRef={buttonRef3}
                    onChangeShowSortBox={onChangeShowSortBox}
                />
                <AiRecommendListBox
                    content={content}
                    data={data}
                    hasNextPage={!!hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                />
            </div>
            {step < 6 && (
                <AiRecommendFooter
                    step={step}
                    isReset
                    isNext={
                        step < 4 ||
                        (!(
                            userAiSettingFilterState.isFinish && pushAgreement.filter(
                                (data: any) =>
                                    data.type ==
                                    (checkedFilter == 1 ? "agree_push_recommend_item" : `agree_push_recommend_item_${checkedFilter}`),
                            )[0].agreement === true
                        ) &&
                            step === 4)
                    }
                    isPrev={step > 1}
                    isShowList={
                        step >= 5 ||
                        !!(
                            step > 3 &&
                            userAiSettingFilterState.isFinish === true &&
                            pushAgreement.filter(
                                (data: any) =>
                                    data.type ==
                                    (checkedFilter == 1 ? "agree_push_recommend_item" : `agree_push_recommend_item_${checkedFilter}`),
                            )[0].agreement === true
                        )
                    }
                    onClickPrev={onClickPrev}
                    onClickReset={onClickReset}
                    onClickNext={() => {
                        onClickNext();
                    }}
                    onClickShowList={() => {
                        if (userAiSettingFilterState.isFinish == false) {
                            setUserAiSettingFilterState({
                                ...userAiSettingFilterState,
                                isFinish: true,
                            });
                            updateUserAiSettingWithRefetch.mutate({
                                ...userAiSettingFilterState,
                                isFinish: true,
                            });
                        }
                        if (step === 5) {
                            const pushTarget =
                                pushAgreement !== undefined &&
                                pushAgreement.filter(
                                    (data: any) =>
                                        data.type ==
                                        (checkedFilter == 1
                                            ? "agree_push_recommend_item"
                                            : `agree_push_recommend_item_${checkedFilter}`),
                                )?.[0]?.dueDate;
                            sendAiPush.mutate({
                                dueDate: pushTarget,
                                settingOrder: checkedFilter,
                            });
                        }

                        onScrollUp();
                    }}
                    // style={{ top: `${MAX_Y - 88}px` }}
                    style={{
                        opacity: checkShow ? "0" : "100",
                        zIndex: 999,
                        width: checkShow ? "0" : "100vw",
                        padding: checkShow ? "0" : "14px 12px",
                        height: checkShow ? "0" : "auto",
                        borderTop: checkShow ? "none" : "6px solid #f0f0f0",
                    }}
                />
            )}
        </>
    );
};

export default AiRecommendBottomSheet;

const rootBoxStyle = (step: number, showSortBox: boolean) => css`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    border-radius: 14px 14px 0px 0px;
    background-color: #fff;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    /* height: ${step > 3 ? `${BOTTOM_SHEET_HEIGHT + 140}` : `${BOTTOM_SHEET_HEIGHT + 90}`}px; */
    height: ${BOTTOM_SHEET_HEIGHT + 90}px;
    transition: transform 150ms ease-out;
    width: 100vw;
    /* top: ${step > 3 && !showSortBox ? `${MAX_Y - 110}` : `${MAX_Y - 90}`}px; */
    top: ${MAX_Y - 90}px;
    z-index: 994;
`;

const topHandlerStyle = css`
    height: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    padding-top: 16px;
    padding-bottom: 4px;
    & > div {
        width: 32px;
        height: 4px;
        border-radius: 2px;
        background-color: #d0d0d0;
        margin: auto;
    }
`;
