import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import RecentSearchItem from "./RecentSearchItem";
import noRecentList from "../../assets/noRecentListWhite.png";
import { useDeleteAllRecentSearch, useGetRecentSearchList } from "@src/hooks/item/useRecentSearchList";
import { useSearchParams } from "react-router-dom";

interface RecentSearchListProps {
    setSearchInputValue: (SearchKeyword: string) => void;
    setIsFocusSearch: Dispatch<SetStateAction<boolean>>;
    isPushKeyword: boolean;
    setIsPushKeyword: Dispatch<SetStateAction<boolean>>;
    fetchData: (type: string, textValue: string) => void;
    searchInputValue: string;
}

const RecentSearchList = ({
    setSearchInputValue,
    setIsFocusSearch,
    isPushKeyword,
    setIsPushKeyword,
    fetchData,
    searchInputValue,
}: RecentSearchListProps) => {
    const [items, setItems] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const tryGetRecentSearchList = async () => {
        const response = await useGetRecentSearchList();
        setItems(response.data.list);
    };

    useEffect(() => {
        tryGetRecentSearchList();
    }, []);

    return (
        <>
            <StyledRecentSearchList>
                <div className="titleBox">
                    <div className="title">최근검색어</div>
                    <div className="deleteClose">
                        {items.length !== 0 && (
                            <div
                                className="delete"
                                onClick={() => {
                                    items.length &&
                                        useDeleteAllRecentSearch(4).then((res) => {
                                            useGetRecentSearchList().then((res) => {
                                                setItems(res.data.list);
                                            });
                                        });
                                }}
                            >
                                전체삭제
                            </div>
                        )}

                        <div
                            className="close"
                            onClick={() => {
                                setIsFocusSearch(false);
                                setSearchParams();
                            }}
                        >
                            닫기
                        </div>
                    </div>
                </div>
                <div className="list">
                    {items.length === 0 ? (
                        <StyledNoRecentSearchList>
                            <div className="content">
                                <img className="noListImg" src={noRecentList} alt="" />
                                <div className="noListText">최근 검색 기록이 없어요</div>
                            </div>
                        </StyledNoRecentSearchList>
                    ) : (
                        items.map((item: any, idx: number) => (
                            <RecentSearchItem
                                text={item.keyword}
                                key={idx}
                                setItems={setItems}
                                items={items}
                                item={item}
                                setSearchInputValue={setSearchInputValue}
                                isPushKeyword={isPushKeyword}
                                setIsPushKeyword={setIsPushKeyword}
                                fetchData={fetchData}
                                searchInputValue={searchInputValue}
                            />
                        ))
                    )}
                </div>
            </StyledRecentSearchList>
        </>
    );
};

export default RecentSearchList;

const StyledRecentSearchList = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 9998;
    top: 58px;
    /* background-color: white; */
    background-color: rgba(40, 40, 40, 0.8);
    /* padding: 14px; */
    padding-top: 14px;
    .titleBox {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0px 14px;
        height: 26px;
        .title {
            font-weight: 700;
            font-size: 16px;
            /* color: ${Common.colors.normalTextColor}; */
            color: white;
            display: flex;
            align-items: center;
        }
        .deleteClose {
            font-weight: 400;
            font-size: 13px;
            /* color: ${Common.colors.normalTextColor}; */
            color: white;
            display: flex;
            align-items: flex-end !important;
            gap: 10px;
        }
    }
    .list {
        padding-top: 14px;
        height: calc(100vh - 98px);
        padding-bottom: 20px;
        overflow-y: scroll;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const StyledNoRecentSearchList = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 88px);
    display: flex;

    justify-content: center;
    .content {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 23px;
        .noListImg {
            width: 50px;
            height: 60px;
        }
        .noListText {
            font-weight: 400;
            font-size: 14px;
            color: white;
            /* color: ${Common.colors.normalTextColor}; */
        }
    }
`;
