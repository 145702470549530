export const itemForAppraisedPrice = [
    {
        title: "전체",
        showText: "",
        value: "",
    },
    {
        title: "1천",
        showText: "1천",
        value: "10000000",
    },
    {
        title: "2천",
        showText: "2천",
        value: "20000000",
    },
    {
        title: "3천",
        showText: "3천",
        value: "30000000",
    },

    {
        title: "4천",
        showText: "4천",
        value: "40000000",
    },
    {
        title: "5천",
        showText: "5천",
        value: "50000000",
    },
    {
        title: "6천",
        showText: "6천",
        value: "60000000",
    },
    {
        title: "7천",
        showText: "7천",
        value: "70000000",
    },
    {
        title: "8천",
        showText: "8천",
        value: "80000000",
    },
    {
        title: "9천",
        showText: "9천",
        value: "90000000",
    },
    {
        title: "1억",
        showText: "1억",
        value: "100000000",
    },
    {
        title: "2억",
        showText: "2억",
        value: "200000000",
    },
    {
        title: "3억",
        showText: "3억",
        value: "300000000",
    },
    {
        title: "4억",
        showText: "4억",
        value: "400000000",
    },
    {
        title: "5억",
        showText: "5억",
        value: "500000000",
    },
    {
        title: "6억",
        showText: "6억",
        value: "600000000",
    },
    {
        title: "7억",
        showText: "7억",
        value: "700000000",
    },
    {
        title: "8억",
        showText: "8억",
        value: "800000000",
    },
    {
        title: "9억",
        showText: "9억",
        value: "900000000",
    },
    {
        title: "10억",
        showText: "10억",
        value: "1000000000",
    },
    {
        title: "12억",
        showText: "12억",
        value: "1200000000",
    },
    {
        title: "14억",
        showText: "14억",
        value: "1400000000",
    },
    {
        title: "16억",
        showText: "16억",
        value: "1600000000",
    },
    {
        title: "18억",
        showText: "18억",
        value: "1800000000",
    },
    {
        title: "20억",
        showText: "20억",
        value: "2000000000",
    },
    {
        title: "40억",
        showText: "40억",
        value: "4000000000",
    },
    {
        title: "60억",
        showText: "60억",
        value: "6000000000",
    },
    {
        title: "80억",
        showText: "80억",
        value: "8000000000",
    },
    {
        title: "100억",
        showText: "100억",
        value: "10000000000",
    },
    {
        title: "200억",
        showText: "200억",
        value: "20000000000",
    },
    {
        title: "300억",
        showText: "300억",
        value: "30000000000",
    },
    {
        title: "300억~",
        showText: "300억",
        value: "higher",
    },
];
