import React from "react";
import { css } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary, Divider, styled } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useSearchParams } from "react-router-dom";
import { transferToNative } from "@src/lib/WebAppBridge";

interface Props { }

const SubscriptionUsingInfo: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const subscriptionUsingInfoTextList = [
        {
            title: "미스고 구독 이용안내",
            content: (
                <div css={contentStyle}>
                    <li>본 동의서에서 사용하는 용어의 정의는 별도로 정하지 않는 한 [미스고 유료 서비스 이용약관]에 정의된 의미와 동일한 의미를 가집니다.</li>
                    <li>“미스고 구독상품“은 주식회사 미스고(이하 “회사“)가 제공하는 정보 등을 열람하기 위하여 필요한 일종의 이용권입니다. “미스고 구독상품“을 구매하시면 회사가 제공하는 정보 등을 열람하실 수 있습니다.</li>
                    <li>“국•공유지 검색추가권“은 주식회사 미스고(이하 “회사“)가 제공하는 국•공유지 필터를 이용하기 위하여 필요한 일종의 이용권입니다.</li>
                    <li>“확장예정 도로 데이터맵“은 주식회사 미스고(이하 “회사“)가 제공하는 확장예정 도로 안내 지도 서비스를 이용하기 위하여 필요한 일종의 이용권입니다.</li>
                    <li>“주거/ 토지 수익 필터“는 주식회사 미스고(이하 “회사“)가 제공하는 시세차익 등 필터 서비스를 이용하기 위하여 필요한 일종의 이용권입니다.</li>
                    <li>“상가 예상수익률 Go-Stop”은 주식회사 미스고(이하 “회사“)가 제공하는 상가의 예상수익률 계산 서비스를 이용하기 위하여 필요한 일종의 이용권입니다.</li>
                    <li>“미스고 구독상품“은 정기결제 유료 서비스 방식, 또는 기간만료 유료 서비스 방식으로 이용할 수 있습니다. 정기결제 유료 서비스 방식으로 이용하실 경우 1개월 단위로 자동결제 되며, 이를 해지할 경우 결제가 이루어진 유료서비스의 남은 이용기간 동안은 유료서비스를 정상 이용할 수 있고 다음 이용기간부터 자동결제가 이루어지지 않습니다. 기간만료 유료 서비스 방식으로 이용하실 경우 1개월 단위, 또는 1년 단위로 결제할 수 있고, 일시납이며, 해당 기간 만료 시 자동결제 및 자동갱신이 되지 않습니다.</li>
                    <li>할인 이벤트를 통해 구매한 경우 구독 중도 해지 시 위약금이 공제됩니다.(자세한 내용은 “미스고 구독 및 상품 해지/취소/환불 안내” 참고)</li>
                    <li className='redBox'>“미스고 베이직 구독상품“을 이용하시면 매물/필지 상세 정보가 제공되며 등기부등본 실시간 열람권이 제공되지 않습니다.</li>
                    <li className='redBox'>“미스고 스탠다드 구독상품“을 이용하시면 매물/필지 상세 정보 자유 열람과 1년권의 경우 월 3회 등기부등본 실시간 열람권이 제공됩니다.</li>
                    <li className='redBox'>“미스고 프리미엄 BEST 1년 구독상품“을 이용하시면 매물/필지 상세 정보 자유 열람과 국•공유지 이용권이 제공됩니다.</li>
                    <li className='redBox'>“미스고 프리미엄에 추가된 확장예정 도로 데이터맵(제주) 구독상품“을 이용하시면 매물/필지 상세 정보 자유 열람과 국•공유지 이용권 및 확장예정 도로 데이터맵 서비스가 제공됩니다.</li>
                    <li className='redBox'>“미스고 프리미엄 VIP(상가 예상수익률 포함 모든 기능) 1년 구독상품“을 이용하시면 매물/필지 상세 정보 자유 열람, 국•공유지 이용권, 확장예정 도로 데이터맵 서비스를 포함한 주거/ 토지 수익필터 및 상가 예상수익률 Go-Stop 서비스가 제공됩니다.</li>
                    <li>“미스고 구독상품“에서 지급된 등기부등본 실시간 열람권은 지급일로부터 30일 동안 사용할 수 있습니다.</li>
                    <li>“미스고 구독상품” 무료 체험 혹은 추가로 제공된 기간에는 등기부등본 실시간 열람권이 지급되지 않습니다.</li>
                    <li>“미스고 구독상품“을 정기결제 유료 서비스 방식으로 이용하는 경우, 등록한 결제 수단으로 자동 결제됩니다. 또한 자동 결제일 7일 전 미스고부동산 APP 알림으로 결제 안내 알림이 발송됩니다.</li>
                    <li>“미스고 구독상품“을 정기결제 유료 서비스 방식으로 이용하는 경우, 등록된 결제수단의 유효기간 만료 등 여하의 사유로 정기결제일에 이용요금의 결제가 정상적으로 이루어지지 않으면 당일 1회 결제 재시도하며, 그 후에도 결제가 정상적으로 이루어지지 않을 경우 “미스고 구독상품” 정기결제 유료 서비스가 자동으로 해지됩니다.</li>
                    <li>“미스고 구독상품“을 정기결제 유료 서비스 방식으로 이용하는 경우, 자동결제는 구독 기간 마지막 날에 다음 정기구독 기간에 대한 금액이 결제됩니다.</li>
                    <li>미스고 구독상품을 이용중에는 다른 방식으로 변경할 수 있으며 구독 변경 시 이전 구독의 복구, 환불이 어렵습니다. 또한 변경 전 구독 잔여기간에 대한 연장 및 할인은 이루어지지 않습니다. (예를 들어 현재 1개월 단위 기간만료 유료 서비스 방식으로 이용 중일 경우 1년 단위 기간만료 유료 서비스 방식으로 변경할 수 있습니다.)</li>
                    <li>동일한 아이디로 2개 이상의 기기에서 동시접속은 불가합니다.</li>
                    <li>회사가 제공하는 모든 정보는 여러 유관기관에 광범위하게 퍼져 있는 정보를 손쉽게 이용할 수 있도록 정리하여 제공하는 것으로 이용자는 해당 정보를 참고 자료로만 활용하여야 하며, 회사는 해당 정보로 인해 발생한 이용자의 손해에 대해서는 법적 책임을 지지 않습니다.</li>
                    <li>예를 들어, 제공된 정보가 실제 매각 물건의 정보와 일치하지 않거나 정보 제공 이후 중요한 변동사항이 생길 수 있으므로, 이용자는 대법원의 관련 제반 정보(물건명세서, 현황조사서, 감정평가서 등)를 반드시 재확인한 후 입찰에 참여해야 하며, 이용자가 이러한 재확인 절차 없이 회사가 제공하는 정보만으로 참여한 입찰과 관련한 문제에 대해서 회사는 법적 책임을 지지 않으며, 이는 회사가 제공하는 모든 서비스에 동일하게 적용됩니다.</li>
                    <li>본 약관은 24년 6월 4일부터 시행됩니다.</li>
                </div>
            ),
        },
        {
            title: searchParams.get("type") === "7" ? "Apple 전용 구독권 해지/취소/환불 안내" : "미스고 구독 해지/취소/환불 안내",
            content:
                searchParams.get("type") === "7" ? (
                    <p css={contentStyle}>
                        <li>iPhone 및 iPad 전용 구독권으로, Apple ID에 등록된 결제수단으로 결제됩니다.</li>
                        <li>iOS 이용권은 Apple 미디어 서비스 이용 약관의 정책을 따릅니다.</li>
                        <li>
                            <span>
                                결제 금액은 부가세(VAT) 및 Apple 수수료 포함되어 있으며, 해외 원화 결제(DCC)에 따른 추가 수수료가
                                발생할 수 있습니다.
                            </span>
                        </li>
                        <li>
                            <span>
                                Apple ID에 등록된 결제수단으로 결제를 진행한 경우, 애플 정책에 따라 애플 웹 또는 iTunes를 통해서만
                                환불이 가능합니다. 자세한 환불 방법은 ‘자주묻는질문’에서 확인 부탁드립니다.
                            </span>
                        </li>
                        <li>
                            <span>
                                자동 결제 해지는 결제 예정일 최소 24시간 전에 해지 신청 해야 합니다. 결제 예정일 전 24시간
                                이내에는 자동 결제를 해지해도 이용권이 결제될 수 있습니다.
                            </span>
                        </li>
                        <li>
                            <span>
                                자동결제 해지는 [iTunes 및 App Store&gt;Apple ID 선택(우측 상단 프로필 아이콘)&gt;구독]에서
                                가능합니다. 이 경우 더이상 다음 결제가 이루어 지지 않으며, 남은 기간 동안 서비스 이용이
                                가능합니다.
                            </span>
                        </li>
                        <li>
                            <span>
                                자동 결제 예정일 전 24시간 이내에 결제가 이루어지며, App Store 자동 결제 예정일과 실제 이용권
                                구매일/만료일 표기는 결제 시점 및 시차 등의 이유로 다르게 표기될 수 있습니다.
                            </span>
                        </li>
                        <li>
                            미스고 앱을 삭제해도 iTunes 구독이 취소되지 않으니 iTunes 및 App Store에서 구독을 취소해야 합니다.
                        </li>
                    </p>
                ) : (
                    <div css={contentStyle}>
                        <li className="redBox">모든 상품은 결제 시부터 7일 이내 서비스 미이용 시 전액 환불 받을 수 있습니다.</li>
                        <li className="redBox">서비스이용기준은 로그기준으로 합니다.</li>
                        <li className="redBox">
                            결제 후 사용내역이 있거나 결제 시부터 8일 이후에는 할인혜택이 소멸되어 정상금액에서 결제일부터
                            해지신청일까지의 이용일수에 해당하는 금액(=이용금액) 및 상품 정가 금액(결제 금액이 아님)의
                            20%(=위약금)를 제외한 나머지 금액이 환불됩니다.
                        </li>
                        <li className="redBox">
                            구독 중도 해지 시 혜택으로 제공된 등기부등본 열람권, 캠퍼스 강의 등에 대한 서비스도 더 이상 제공되지
                            않습니다.
                        </li>
                        <li>
                            정기결제 구독을 구매한 경우 구독 갱신을 원하지 않는 경우 구독 기간이 끝나는 날로부터 최소 24시간 전에
                            자동 갱신 해지 예약을 해야 합니다
                        </li>
                        <li>구독 해지 및 해지예약은 ‘내정보&gt;고객센터&gt;자주묻는질문’에서 가능합니다.</li>
                        <li>
                            단, 앱마켓 결제의 경우 앱마켓 정책에 따라 결제/해지/환불이 이루어지며 해지/환불을 원하는 경우 앱마켓을
                            통해 신청해주기 바랍니다.
                        </li>
                        <li>추천인 구독 혜택 등 무료로 지급된 혜택은 환불 대상에서 제외됩니다.</li>
                        <p>이전 약관</p>
                        <li
                            className="linkLi"
                            onClick={() => {
                                const params = {
                                    title: "국•공유지 필터 이용권 결제 이용안내",
                                    url: "https://triplenine.notion.site/2023-10-22-42b9392714d6495c9a010abe47c66a88?pvs=4",
                                };
                                transferToNative(JSON.stringify(params), "openExternalMap");
                            }}
                        >
                            2023년 10월 22일까지
                        </li>
                        <li
                            className="linkLi"
                            onClick={() => {
                                const params = {
                                    title: "국•공유지 필터 이용권 결제 이용안내",
                                    url: "https://triplenine.notion.site/2023-10-23-2023-11-13-4dd1085c191f4a0185abf7f8b1afaed1",
                                };
                                transferToNative(JSON.stringify(params), "openExternalMap");
                            }}
                        >
                            2023년 10월 23일부터 2023년 11월 13일까지
                        </li>
                        <li
                            className="linkLi"
                            onClick={() => {
                                const params = {
                                    title: "국•공유지 필터 이용권 결제 이용안내",
                                    url: "https://triplenine.notion.site/2023-11-14-2024-1-9-233a16d0d88e4fa9bfa67525d335b409",
                                };
                                transferToNative(JSON.stringify(params), "openExternalMap");
                            }}
                        >
                            2023년 11월 14일부터 2024년 1월 9일까지
                        </li>
                        <li
                            className="linkLi"
                            onClick={() => {
                                const params = {
                                    title: "국•공유지 필터 이용권 결제 이용안내",
                                    url: "https://www.notion.so/triplenine/2024-1-10-2024-5-23-3b8d4c88e87c4a72bdbf6a952196730d",
                                };
                                transferToNative(JSON.stringify(params), "openExternalMap");
                            }}
                        >
                            2024년 1월 10일부터 2024년 5월 23일까지
                        </li>
                    </div>
                ),
        },
    ];
    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle}>이용안내</div>
            <div css={contentRootStyle}>
                {subscriptionUsingInfoTextList?.map((info, idx) => (
                    <TabAccordion square key={idx.toString()}>
                        <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                            <div>{info?.title}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider sx={{ margin: "0 18px", padding: 0 }} />
                            <div css={accordionContentStyle}>{info.content}</div>
                        </AccordionDetails>
                    </TabAccordion>
                ))}
            </div>
        </div>
    );
};

export default SubscriptionUsingInfo;

const TabAccordion = styled(Accordion)({
    boxShadow: "none",
    margin: "0 0 24px 0",
    backgroundColor: "#ffffff",
    borderRadius: "14px",
    border: "0.6px solid #0C002C",
    "&:before": {
        display: "none",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
        color: "#2275EE",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "inherit",
        backgroundColor: "#ffffff",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "14px",
        borderRadius: "14px 14px 0 0",
        padding: "14px",
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-root": {
        backgroundColor: "#ffffff",
        color: "#282828",
        padding: "14px",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "14px",
        translation: "all 0.2s",
        borderRadius: "14px",
    },
    "& .MuiAccordionDetails-root": {
        color: "#282828",
        padding: 0,
    },
});

const rootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
`;

const titleBoxStyle = css`
    text-align: center;
    font-family: "Sandoll Samliphopangche";
    padding-bottom: 24px;
    color: #0c002c;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
`;

const accordionContentStyle = css`
    padding: 14px;
`;

const contentRootStyle = css`
    max-width: 1000px;
    width: calc(100% - 60px);
`;

const titleStyle = css`
    color: #0c002c;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;
const contentStyle = css`
    white-space: pre-wrap;
    word-break: break-word;
    color: #505050;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    & > li {
        list-style: inside;
        margin-bottom: 2px;
        list-style-position: outside;
        color: #505050;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }
    b {
        font-size: 13px;
        font-weight: 700;
    }
    .redBox {
        color: #ff0000;
    }
    & > .linkLi {
        text-decoration-line: underline;
        text-underline-position: under;
    }
    p {
        margin: 8px 0;
        font-size: 13px;
        font-weight: 700;
    }
`;
