import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import ico_arrow_down_white from "@src/assets/ico_arrow_down_white.png";
import ico_tooltip from "@src/assets/ico_tooltip.png";
import ico_warning from "@src/assets/ico_warning.png";
import deungi_search_info from "@src/assets/deungi/deungi_search_info2.png";
import ico_list_search_black from "@src/assets/ico_list_search_black.png";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import DeungiCombo from "./DeungiCombo";
import { DEUNGI_FILTER_STATE, DEUNGI_FILTER_TYPE, DEUNGI_POPUP_TYPE, DEUNGI_TYPE_ENUM } from "./common/enum";
import { useSearchList } from "@src/hooks/deungi/useSearchList";
import { SearchListRequestDto, SearchListResponseDto } from "@src/hooks/deungi/dto/SearchList.dto";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Lottie from "lottie-react";
import DeungiPopup from "./DeungiPopup";
import { usePrevDeungi } from "@src/hooks/deungi/usePrevDeungi";
import { CheckDeungiResponseDto, PrevDeungiResponseDto, SubmitDeungiRequestDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCheckDeungi } from "@src/hooks/deungi/useDeungiInfo";
import { validSearchText } from "./common/utils";
import dayjs from "dayjs";
import { useUserSubsInfo } from "@src/hooks/deungi/useUserSubsInfo";
import { UserSubsInfoResponseDto } from "@src/hooks/deungi/dto/UserSubs";
import deunggiAlarmBanner from "@src/assets/deungi/deunggiAlarmBanner.png";
import { WebAppBridge, transferToNative } from "@src/lib/WebAppBridge";
import micIcon from "@src/assets/basicIcon/micIcon.svg";
import { useDeungiPinStore, useDeungiSearchText, useOpenDeungi, useSelectedDeungi, useSelectedExistDeungi } from "@src/store/deungiPayment";
import { useDeungiSubmit, useExistDeungiSubmit } from "@src/hooks/deungi/useDeungiSubmit";

interface Props {
    type?: "deungi" | "alarm";
}

const DeungiSearchBox = ({ type = "deungi" }: Props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState(null);
    const [openData, setOpenData] = useState({} as any);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.OPEN);
    const [popupToggle, setPopupToggle] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchTotalCnt, setSearchTotalCnt] = useState(0);
    const [searchList, setSearchList] = useState<any[]>([]);
    const [filterTypeToggle, setFilterTypeToggle] = useState(false);
    const [filterStateToggle, setFilterStateToggle] = useState(false);
    const [filterType, setFilterType] = useState(DEUNGI_FILTER_TYPE.ALL as DEUNGI_FILTER_TYPE);
    const [filterState, setFilterState] = useState(DEUNGI_FILTER_STATE.ACTIVE as DEUNGI_FILTER_STATE);
    const [filterPage, setFilterPage] = useState(1);
    const setUserDeungiPinState = useDeungiPinStore((state: any) => state.setDeungiPinState);
    const userDeungiSearchText = useDeungiSearchText((state: any) => state.deungiSearchText);
    const setUserDeungiSearchText = useDeungiSearchText((state: any) => state.setDeungiSearchText);
    const setUserOpenDeungi = useOpenDeungi((state: any) => state.setOpenDeungi);
    const userSelectedDeungi = useSelectedDeungi((state: any) => state.selectedDeungi);
    const userSelectedExistDeungi = useSelectedExistDeungi((state: any) => state.selectedExistDeungi);
    const setUserSelectedDeungi = useSelectedDeungi((state: any) => state.setSelectedDeungi);
    const setUserSelectedExistDeungi = useSelectedExistDeungi((state: any) => state.setSelectedExistDeungi);

    const submitDeungi = async (params: SubmitDeungiRequestDto) => {
        const res = await useDeungiSubmit(params);
        const id: string = res.data.id;
        return id;
    };

    const submitExistDeungi = async (params: SubmitDeungiRequestDto) => {
        const res = await useExistDeungiSubmit(params);
        const id: string = res.data.id;
        return id;
    };

    const checkId = async () => {
        const id = await submitDeungi(userSelectedDeungi);

        if (id) {
            navigate({
                pathname: "/deungi",
                search: `?type=search&watch=${id}`,
            });
        }
    }

    const checkExistId = async () => {
        if (!userSelectedExistDeungi?.myId) {
            const id = await submitExistDeungi(userSelectedDeungi);
            if (id) {
                navigate("/deungi/pdf", {
                    state: {
                        data: {
                            address: userSelectedExistDeungi.address,
                            addressIdx: userSelectedExistDeungi.addressIdx,
                            deungi_type: userSelectedExistDeungi.deungi_type,
                            id: id,
                            myId: userSelectedExistDeungi.myId,
                            item_type: userSelectedExistDeungi.item_type,
                            itemType: userSelectedExistDeungi.itemType,
                            pin: userSelectedExistDeungi.pin,
                            type: userSelectedExistDeungi.type,
                            updatedAt: userSelectedExistDeungi.updatedAt,
                        },
                        isBtnShow: true,
                    },
                });
            }
        } else {
            navigate("/deungi/pdf", {
                state: {
                    data: {
                        address: userSelectedExistDeungi.address,
                        addressIdx: userSelectedExistDeungi.addressIdx,
                        deungi_type: userSelectedExistDeungi.deungi_type,
                        id: userSelectedExistDeungi.id,
                        myId: userSelectedExistDeungi.myId,
                        item_type: userSelectedExistDeungi.item_type,
                        itemType: userSelectedExistDeungi.itemType,
                        pin: userSelectedExistDeungi.pin,
                        type: userSelectedExistDeungi.type,
                        updatedAt: userSelectedExistDeungi.updatedAt,
                    },
                    isBtnShow: true,
                },
            });
        }
    }


    useEffect(() => {
        if (searchParams.get("product") === "1" && userSelectedDeungi.pin !== "") {
            checkId();
            setUserOpenDeungi(true);
            setUserSelectedDeungi({
                address: "",
                pin: "",
                deungi_type: "",
                item_type: "",
            })
            setUserSelectedExistDeungi({
                address: "",
                addressIdx: "",
                deungi_type: "",
                id: "",
                myId: "",
                item_type: "",
                itemType: "",
                pin: "",
                type: "",
                updatedAt: "",
            })
        } else if (searchParams.get("product") === "2") {
            checkExistId();
            setUserOpenDeungi(true);
            setUserSelectedDeungi({
                address: "",
                pin: "",
                deungi_type: "",
                item_type: "",
            })
            setUserSelectedExistDeungi({
                address: "",
                addressIdx: "",
                deungi_type: "",
                id: "",
                myId: "",
                item_type: "",
                itemType: "",
                pin: "",
                type: "",
                updatedAt: "",
            })
        }
    }, [searchParams])


    const searchInputRef = useRef() as any;
    WebAppBridge.setSpeechResult = async (val: string) => {
        setUserDeungiSearchText(val);
        if (val.length < 3) {
            setMessage("검색어는 세글자 이상 입력해주세요." as any);
            setSearchList([]);
            setSearchToggle(true);
            return;
        }
        if (!validSearchText(val)) {
            setMessage("검색된 주소가 너무 많습니다. 더욱 상세히 적어주세요." as any);
            setSearchList([]);
            setSearchToggle(true);
            return;
        }

        const checkDeungiRes = await useCheckDeungi();
        const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

        if (!checkDeungiInfo.isCheck) {
            setOpenData(checkDeungiInfo);
            setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
            setPopupToggle(true);
            return;
        }
        setMessage(null);
        setSearchToggle(false);
        setFirstLoading(true);
        setFilterPage(1);
        setSearchList([]);
        setSearchTotalCnt(0);
        navigate({ search: `?type=search&searchText=${val}` }, { replace: true });

        const params = {} as SearchListRequestDto;
        params.type = Object.values(DEUNGI_FILTER_TYPE).indexOf(filterType);
        params.state = Object.values(DEUNGI_FILTER_STATE).indexOf(filterState);
        params.searchText = val;
        params.page = 1;

        await useSearchList(params)
            .then((res) => {
                const data = res.data;
                if (data?.totalCnt === 0) {
                    setMessage(data.msg);
                    setSearchList([]);
                } else {
                    setSearchTotalCnt(data?.totalCnt);
                    setFilterPage((prev: number) => prev + 1);
                    setSearchList((prev: any) => prev.concat(data?.list));
                }
            })
            .catch((e) => {
                setPopupType(DEUNGI_POPUP_TYPE.ERROR);
                setPopupToggle(true);
            })
            .finally(() => {
                setSearchToggle(true);
                setFirstLoading(false);
            });
        // navigate(`/deungi?searchText=${val}`, { replace: true });
    };

    const onInfoHandler = () => {
        setPopupType(DEUNGI_POPUP_TYPE.INFO);
        setPopupToggle(true);
    };

    const onSelectHandler = async (deungiInfo: SearchListResponseDto) => {
        navigate(`/deungi/alarm/detail`, { state: { deungiInfo, isModify: false } });
    };

    const onOpenHandler = async (deungiInfo: SearchListResponseDto) => {
        if (!deungiInfo) return;

        setOpenData(deungiInfo);
        setUserDeungiPinState(deungiInfo.pin)

        const res = await usePrevDeungi(deungiInfo.pin);
        const prevDeungiInfo: PrevDeungiResponseDto = res.data;

        if (prevDeungiInfo && prevDeungiInfo.id.length > 0) {
            setOpenData((prev: any) => {
                return { ...prev, ...prevDeungiInfo };
            });

            // 1개월이 지난 등기부등본인 경우
            if (dayjs(prevDeungiInfo.updatedAt).add(1, "month").diff() < 0) {
                // 모든 회원 열람 가능
                setPopupType(DEUNGI_POPUP_TYPE.REOPEN);
            } else {
                const subsRes = await useUserSubsInfo();
                const subsInfo: UserSubsInfoResponseDto = subsRes.data;

                // 스탠다드 또는 프리미엄 1년 상품인 경우
                if (subsInfo && ["스탠다드", "프리미엄"].includes(subsInfo.subsName) && subsInfo.serveDurationType === "year") {
                    setPopupType(DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST_YEAR_SUBS);
                    // 스탠다드 1년 상품이 아닌 경우
                } else {
                    // 내가 열람한 등기부등본이 있는 경우
                    if (prevDeungiInfo.myId) {
                        setPopupType(DEUNGI_POPUP_TYPE.HAS_DEUNGI);
                    } else {
                        // 내가 열람한 등기부등본이 아닌 경우
                        if (subsInfo && !subsInfo.isFreeIssueSubs) {
                            // 유료 구독 상품인 경우
                            setPopupType(DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST);
                        } else {
                            // 무료 상품 또는 미구독 회원인 경우
                            setPopupType(DEUNGI_POPUP_TYPE.PAY_EXIST_DEUNGI);
                        }
                    }
                }
            }
        } else {
            setPopupType(DEUNGI_POPUP_TYPE.OPEN);
        }

        setPopupToggle(true);
    };

    useEffect(() => {
        if (searchParams.get("searchText") !== null) {
            setUserDeungiSearchText(searchParams.get("searchText") ?? "");
            getSearchListOnce(1);
        }
    }, [])

    const onSearchTextHandler = (e: any) => {
        setUserDeungiSearchText(e?.target?.value);
    };

    const onSearchKeyHandler = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            getSearchList(1);
            searchInputRef.current.blur();
        }
    };

    const getSearchListOnce = async (page?: number) => {
        if (!validSearchText(userDeungiSearchText)) {
            setMessage("검색된 주소가 너무 많습니다. 더욱 상세히 적어주세요." as any);
            setSearchList([]);
            setSearchToggle(true);
            return;
        }

        const checkDeungiRes = await useCheckDeungi();
        const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

        if (!checkDeungiInfo.isCheck) {
            setOpenData(checkDeungiInfo);
            setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
            setPopupToggle(true);
            return;
        }

        if (page === 1) {
            setMessage(null);
            setSearchToggle(false);
            setFirstLoading(true);
            setFilterPage(1);
            setSearchList([]);
            setSearchTotalCnt(0);
        }

        const params = {} as SearchListRequestDto;
        params.type = Object.values(DEUNGI_FILTER_TYPE).indexOf(filterType);
        params.state = Object.values(DEUNGI_FILTER_STATE).indexOf(filterState);
        params.searchText = searchParams.get("searchText") ?? "";
        params.page = page ? page : filterPage;

        await useSearchList(params)
            .then((res) => {
                const data = res.data;

                if (data?.totalCnt === 0) {
                    setMessage(data.msg);
                    setSearchList([]);
                } else {
                    setUserDeungiPinState(data?.list[0]?.pin)
                    setSearchTotalCnt(data?.totalCnt);
                    setFilterPage((prev: number) => prev + 1);
                    setSearchList((prev: any) => prev.concat(data?.list));
                }
            })
            .catch((e) => {
                setPopupType(DEUNGI_POPUP_TYPE.ERROR);
                setPopupToggle(true);
            })
            .finally(() => {
                setSearchToggle(true);
                setFirstLoading(false);
            });
    };

    const getSearchList = async (page?: number) => {
        if (userDeungiSearchText.length < 3) {
            setMessage("검색어는 세글자 이상 입력해주세요." as any);
            setSearchList([]);
            setSearchToggle(true);
            return;
        }
        if (!validSearchText(userDeungiSearchText)) {
            setMessage("검색된 주소가 너무 많습니다. 더욱 상세히 적어주세요." as any);
            setSearchList([]);
            setSearchToggle(true);
            return;
        }

        const checkDeungiRes = await useCheckDeungi();
        const checkDeungiInfo: CheckDeungiResponseDto = checkDeungiRes?.data;

        if (!checkDeungiInfo.isCheck) {
            setOpenData(checkDeungiInfo);
            setPopupType(DEUNGI_POPUP_TYPE.SERVICE_DENY);
            setPopupToggle(true);
            return;
        }

        if (page === 1) {
            setMessage(null);
            setSearchToggle(false);
            setFirstLoading(true);
            setFilterPage(1);
            setSearchList([]);
            setSearchTotalCnt(0);
            navigate({ search: `?type=search&searchText=${userDeungiSearchText}` }, { replace: true });
        }

        const params = {} as SearchListRequestDto;
        params.type = Object.values(DEUNGI_FILTER_TYPE).indexOf(filterType);
        params.state = Object.values(DEUNGI_FILTER_STATE).indexOf(filterState);
        params.searchText = userDeungiSearchText;
        params.page = page ? page : filterPage;

        await useSearchList(params)
            .then((res) => {
                const data = res.data;

                if (data?.totalCnt === 0) {
                    setMessage(data.msg);
                    setSearchList([]);
                } else {
                    setUserDeungiPinState(data?.list[0]?.pin)
                    setSearchTotalCnt(data?.totalCnt);
                    setFilterPage((prev: number) => prev + 1);
                    setSearchList((prev: any) => prev.concat(data?.list));
                }
            })
            .catch((e) => {
                setPopupType(DEUNGI_POPUP_TYPE.ERROR);
                setPopupToggle(true);
            })
            .finally(() => {
                setSearchToggle(true);
                setFirstLoading(false);
            });
    };

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: searchTotalCnt > (filterPage - 1) * 10,
        onLoadMore: getSearchList,
        disabled: false,
        delayInMs: 100,
    });

    useEffect(() => {
        if (searchToggle && userDeungiSearchText.length > 0) getSearchList(1);
    }, [filterType, filterState]);

    useEffect(() => {
        if (userDeungiSearchText.length === 0) {
            setMessage("" as any);
            if (!searchList || searchList.length === 0) setSearchToggle(false);
        }
    }, [userDeungiSearchText]);

    return (
        <>
            <DeungiFilter>
                <div onClick={() => setFilterTypeToggle(true)}>
                    <label>부동산 구분</label>
                    <OptionBox>{filterType}</OptionBox>
                </div>
                <div onClick={() => setFilterStateToggle(true)}>
                    <label>상태</label>
                    <OptionBox>{filterState}</OptionBox>
                </div>
            </DeungiFilter>
            <DeungiSearchBar className={searchToggle ? "isSearch" : ""}>
                <div className="searchLabel" onClick={onInfoHandler}>
                    <label>소재지</label>
                    <img src={ico_tooltip} />
                </div>
                <div className="searchBox">
                    <input
                        ref={searchInputRef}
                        type="text"
                        placeholder="주소 또는 건물명 입력"
                        onKeyPress={onSearchKeyHandler}
                        onChange={onSearchTextHandler}
                        value={userDeungiSearchText}
                        autoFocus={!!userDeungiSearchText}
                    />
                    <div className="searchSubmitBox" onClick={() => getSearchList(1)}>
                        <img src={ico_list_search_black} />
                    </div>
                    {navigator.userAgent.toLowerCase().includes("iphone") && (
                        <div
                            className="searchMicBox"
                            onClick={(evt) => {
                                evt.stopPropagation();
                                transferToNative("webSearch", "onClickSearchMic");
                            }}
                        >
                            <img src={micIcon} />
                        </div>
                    )}
                </div>
            </DeungiSearchBar>
            <DeungiSearchList style={{ top: `${type === "alarm" ? 192 : 330}px` }}>
                {message ? (
                    <div className="topLabel">
                        <img src={ico_warning} />
                        <span>{message}</span>
                    </div>
                ) : searchToggle ? (
                    <>
                        <div className="topLabel">
                            총 <span>{searchTotalCnt > 0 ? searchTotalCnt?.toLocaleString() : 0}</span>건의 등기정보가 있습니다.
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>소재지</th>
                                    <th>구분</th>
                                    <th>상태</th>
                                    {type === "alarm" ? <th>알림신청</th> : <th>열람신청</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {searchList.map((el: SearchListResponseDto, idx: number) => {
                                    return (
                                        <tr
                                            key={el.pin + idx.toString()}
                                            onClick={() => (type === "alarm" ? onSelectHandler(el) : onOpenHandler(el))}
                                        >
                                            <td>{el.address}</td>
                                            <td>{el.item_type}</td>
                                            <td>{el.deungi_type}</td>
                                            <td>
                                                {type === "alarm" ? <span className={"alarm"}>선택</span> : <span>열람</span>}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="loadingBox" ref={loadingRef}>
                            {searchTotalCnt > searchList.length ? (
                                <Lottie className="lottie" animationData={lottie_loading} />
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                ) : firstLoading ? (
                    <>
                        <div className="loadingBox">
                            <Lottie className="lottie" animationData={lottie_loading} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </DeungiSearchList>
            {!message && !firstLoading && !userDeungiSearchText && searchList.length === 0 && (
                <DeungiSearchInfo style={{ top: `${type === "alarm" ? 192 : 330}px` }}>
                    <img src={type === "alarm" ? deunggiAlarmBanner : deungi_search_info} />
                </DeungiSearchInfo>
            )}
            <DeungiPopup type={popupType} toggle={popupToggle} setToggle={setPopupToggle} data={openData} />
            <DeungiCombo
                option={filterType}
                options={Object.values(DEUNGI_FILTER_TYPE)}
                toggle={filterTypeToggle}
                setOption={setFilterType}
                setToggle={setFilterTypeToggle}
            />
            <DeungiCombo
                option={filterState}
                options={Object.values(DEUNGI_FILTER_STATE)}
                toggle={filterStateToggle}
                setOption={setFilterState}
                setToggle={setFilterStateToggle}
            />
        </>
    );
};

const DeungiSearchInfo = styled.div`
    position: absolute;
    width: 100%;
    top: 330px;
    bottom: -5px;
    overflow: scroll;

    & > img {
        width: 100%;
        animation: fadeIn 0.3s;
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const DeungiSearchList = styled.div`
    position: absolute;
    width: 100%;
    top: 330px;
    bottom: 0;
    overflow: auto;

    .topLabel {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.4;
        padding: 0 14px;
        margin: 10px 0;

        & > span {
            color: #ff0000;
            margin-left: 3px;
        }
        & > img {
            width: 16px;
            height: 16px;
            margin-right: 3px;
        }
    }
    & > table {
        width: 100%;
        table-layout: fixed;
        border-collapse: initial;
        font-size: 13px;

        & > thead > tr {
            background: #fbf8f8;
            color: #8d8d8d;

            & > th {
                word-break: keep-all;
                font-weight: 500;
                border: 10px solid #fbf8f8;
            }
            & > th:nth-of-type(1) {
                width: 65%;
            }
            & > th:nth-of-type(3) {
                width: 50px;
            }
            & > th:nth-of-type(2),
            & > th:nth-of-type(4) {
                width: 65px;
            }
        }
        & > tbody > tr {
            color: #0c002c;
            animation: fadeIn 0.5s;

            :active {
                background: #dff0ff;
            }
            & > td {
                word-break: keep-all;
                font-weight: 500;
                line-height: 1.4;
                vertical-align: middle;
                text-align: center;
                padding: 15px 3px;
                border-bottom: 1px solid #dadada;
            }
            & > td:nth-of-type(1) {
                text-align: left;
                padding-left: 14px;
                word-break: normal;
            }
            & > td:nth-of-type(4) > span {
                background: #0047ff;
                color: #fff;
                padding: 8px;
                border-radius: 24px;

                &.alarm {
                    background: #ebfbfa;
                    color: #0c002c;
                }
            }
        }
    }
    .loadingBox {
        margin: 20px 0 80px;

        .lottie {
            width: 50px;
            margin: auto;
        }
    }
    .loadingBottomBox {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        line-height: 1.3;

        & > h1 {
            font-size: 74px;
            color: #faff00;
            -webkit-text-stroke: 2.5px #000;
            margin-bottom: 16px;
        }
        & > h2 {
            font-size: 44px;
            font-weight: 500;
        }
        & > h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        & > h4 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 58px;
        }
        .mainImg {
            width: 88px;
            height: 88px;
            margin: 10px 0;
        }
        .arrowImg {
            width: 33px;
            height: 17px;
            margin: 10px 0 16px;
        }
        & > span {
            text-align: center;
            line-height: 1.5;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const DeungiSearchBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
    padding: 0 14px 14px;

    &.isSearch {
        transition: all 0.3s ease;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    }
    .searchLabel {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > img {
            width: 18px;
            height: 18px;
            margin-left: 5px;
        }
    }
    .searchBox {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 15px;

        & > input {
            width: 100%;
            height: 40px;
            font-weight: 400;
            font-size: 14px;
            padding: 0 40px;
            background: #fbf8f8;
            border-radius: 24px;
            text-align: left;
            border: 0;

            &.hidden {
                padding: 0 15px;
            }
        }
        .searchSubmitBox {
            position: absolute;
            display: flex;
            left: 0;
            width: 50px;
            height: 50px;
            align-items: center;

            & > img {
                width: 24px;
                height: 24px;
                margin: auto;
                transition: all 0.2s;

                &.hidden {
                    opacity: 0;
                }
            }
        }
        .searchMicBox {
            position: absolute;
            display: flex;
            right: 0;
            width: 50px;
            height: 50px;
            align-items: center;

            & > img {
                width: 24px;
                height: 24px;
                margin: auto;
                transition: all 0.2s;

                &.hidden {
                    opacity: 0;
                }
            }
        }
    }
`;

const DeungiFilter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 14px;

    & > label {
        line-height: 50px;
        word-break: keep-all;
    }
    & > div {
        display: flex;
        align-items: center;
        width: 60%;

        &:nth-of-type(2) {
            justify-content: end;
            width: 40%;
        }
    }
`;

const OptionBox = ({ children }: any) => {
    const Container = styled.div`
        display: flex;
        justify-content: space-between;
        width: 50%;
        min-width: 90px;
        align-items: center;
        background: #2275ee;
        padding: 12px 8px;
        margin-left: 10px;
        border: 1px solid #2275ee;
        color: #fff;
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
        border-radius: 24px;

        & > div {
            width: 100%;
            text-align: center;
        }
        & > img {
            width: 11px;
            height: 7px;
        }
        @media screen and (max-width: 355px) {
            & {
                margin-left: 4px;
            }
        }
    `;

    return (
        <Container>
            <div>{children}</div>
            <img src={ico_arrow_down_white} />
        </Container>
    );
};

export default DeungiSearchBox;
