import React, { MutableRefObject, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import stateLandDesc1 from "@src/assets/stateLand/stateLandDesc1.png";
import stateLandDesc1_1 from "@src/assets/stateLand/stateLandDesc1-1.png";
import stateLandDesc1_2 from "@src/assets/stateLand/stateLandDesc1-2.png";
import stateLandDesc1_3 from "@src/assets/stateLand/stateLandDesc1-3.png";
import stateLandDesc2_1 from "@src/assets/stateLand/stateLandDesc2-1.png";
import stateLandDesc3_1 from "@src/assets/stateLand/stateLandDesc3-1.png";
import stateLandDesc3_2 from "@src/assets/stateLand/stateLandDesc3-2.png";
import stateLandDesc3_3 from "@src/assets/stateLand/stateLandDesc3-3.png";
import stateLandDesc3_4 from "@src/assets/stateLand/stateLandDesc3-4.png";
import stateLandDesc4 from "@src/assets/stateLand/stateLandDesc4.png";
import stateLandDesc5 from "@src/assets/stateLand/stateLandDesc5.png";
import stateLandDesc6 from "@src/assets/stateLand/stateLandDesc6.png";
import intro1 from "@src/assets/stateLand/intro1.png";
import intro2 from "@src/assets/stateLand/intro2.png";
import intro4 from "@src/assets/stateLand/intro4.png";
import intro5 from "@src/assets/stateLand/intro5.png";
import intro6 from "@src/assets/stateLand/intro6.png";
import desc2 from "@src/assets/stateLand/desc2.gif";
import desc3 from "@src/assets/stateLand/desc3.gif";
import desc4 from "@src/assets/stateLand/desc4.gif";
import desc5 from "@src/assets/stateLand/desc5.gif";
import infoDesc4 from "@src/assets/stateLand/infoDesc4.png";
import blackDownArrowIcon from "@src/assets/basicIcon/blackDownArrowIcon.svg";
import blueUpArrowIcon from "@src/assets/basicIcon/blueUpArrowIcon.svg";
import { InAppRefundInfo, PgRefundInfo, SubsUseInfo } from "./StateLandDescUseInfo";

interface Props {
    videoRef: MutableRefObject<HTMLVideoElement | null>;
}

const StateLandDesc: React.FC<Props> = ({ videoRef }) => {
    const [searchParams] = useSearchParams();
    const [openUseInfo, setOpenUseInfo] = useState<boolean[]>([false, false]);

    const onChangeOpenUseInfo = (val: number) => {
        const newOpenUseInfo = [...openUseInfo];
        newOpenUseInfo[val] = !newOpenUseInfo[val];
        setOpenUseInfo(newOpenUseInfo);
    };

    return (
        <>
            <div css={rootBoxStyle}>
                <img src={stateLandDesc1} alt="" width="60%" />
                <video
                    css={mainVideoStyle}
                    ref={videoRef}
                    playsInline={true}
                    controls={true}
                    src={"https://missgo-landing.s3.ap-northeast-2.amazonaws.com/image/pcImage/statelandIntro.mp4"}
                    autoPlay={true}
                    controlsList="nodownload"
                    muted={true}
                    loop
                />
                <img src={intro1} alt="intro1" width="90%" />
                <div css={descBoxStyle}>
                    <p className="numberBox">1</p>
                    <img src={intro4} alt="" width="90%" />
                    <img src={intro2} alt="" width="100%" />
                    <div className="videoBox">
                        <video
                            playsInline={true}
                            src={"https://missgo-landing.s3.ap-northeast-2.amazonaws.com/image/pcImage/statelandIntro1.mp4"}
                            controlsList="nodownload"
                            controls={true}
                            poster={stateLandDesc1_2}
                        />
                    </div>
                </div>
                <img src={intro5} alt="" width="90%" />
                <div css={descBoxStyle}>
                    <p className="numberBox">3</p>
                    <img src={stateLandDesc3_1} alt="" width="90%" />
                    <img src={intro6} alt="" width="100%" />
                    <div className="videoBox">
                        <video
                            playsInline={true}
                            src={"https://missgo-landing.s3.ap-northeast-2.amazonaws.com/image/pcImage/statelandIntro3.mp4"}
                            controlsList="nodownload"
                            controls={true}
                            poster={stateLandDesc3_3}
                        />
                    </div>
                </div>
                <div css={useInfoWrapper}>
                    <b>국•공유지 필터 사용법</b>
                    <div className="gifBox">
                        <img src={desc2} alt="desc2" className="img" />
                        <p>1. 국공유지 버튼을 클릭하면 기능이 실행되어 국공유지가 표시돼요</p>
                        <span>※기능 사용은 상품 구매가 필요합니다</span>
                    </div>
                    <div className="gifBox">
                        <img src={desc3} alt="desc3" className="img" />
                        <p>2. 필터를 설정하여 원하는 토지를 찾을 수 있어요</p>
                    </div>
                    <div className="gifBox">
                        <img src={desc4} alt="desc4" className="img" />
                        <p>3. 데이터맵에서 한 눈에 국공유지를 확인할 수 있어요</p>
                    </div>
                    <div className="gifBox">
                        <img src={infoDesc4} alt="infoDesc4" className="img" />
                        <img src={desc5} alt="desc5" className="img" />
                        <div className="text">
                            1. 분류에서 일반재산을 선택하고 <br />
                            2. 관리상태에서 매각대상, 대부대상을 선택해주세요
                        </div>
                        <div className="text"></div>
                    </div>
                </div>
                <img src={stateLandDesc6} alt="" width="100%" />
            </div>

            <div css={useInfoStyle}>
                <p className="title">이용안내</p>
                {searchParams.get("type") === "7" ? (
                    <>
                        <div css={useBoxStyle} onClick={() => onChangeOpenUseInfo(0)}>
                            <div className="titleBox">
                                <p>미스고 구독 및 상품 이용안내</p>
                                <img src={openUseInfo[0] ? blueUpArrowIcon : blackDownArrowIcon} alt="" />
                            </div>
                            {openUseInfo[0] && <SubsUseInfo />}
                        </div>
                        <div css={useBoxStyle} onClick={() => onChangeOpenUseInfo(1)}>
                            <div className="titleBox">
                                <p>Apple 전용 구독권 해지/취소/환불 안내</p>
                                <img src={openUseInfo[1] ? blueUpArrowIcon : blackDownArrowIcon} alt="" />
                            </div>
                            {openUseInfo[1] && <InAppRefundInfo />}
                        </div>
                    </>
                ) : (
                    <>
                        <div css={useBoxStyle} onClick={() => onChangeOpenUseInfo(0)}>
                            <div className="titleBox">
                                <p>미스고 구독 및 상품 이용안내</p>
                                <img src={openUseInfo[0] ? blueUpArrowIcon : blackDownArrowIcon} alt="" />
                            </div>
                            {openUseInfo[0] && <SubsUseInfo />}
                        </div>
                        <div css={useBoxStyle} onClick={() => onChangeOpenUseInfo(1)}>
                            <div className="titleBox">
                                <p>미스고 구독 및 상품 해지/취소/환불 안내</p>
                                <img src={openUseInfo[1] ? blueUpArrowIcon : blackDownArrowIcon} alt="" />
                            </div>
                            {openUseInfo[1] && <PgRefundInfo />}
                        </div>
                    </>
                )}
            </div>
            {searchParams.get("type") === "7" && (
                <div css={inAppAgreeBoxStyle}>구독 이용 및 해지/취소 안내를 확인 하였으며, 결제에 동의합니다.</div>
            )}
        </>
    );
};

export default StateLandDesc;

const rootBoxStyle = css`
    background-color: #282828;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
`;

const mainVideoStyle = css`
    margin-top: 20px;
    margin-bottom: 40px;
    width: 90%;
    height: 100%;
    border: 4.5px solid black;
    border-radius: 12px;
    position: relative;
    & > video {
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }
`;

const descBoxStyle = css`
    margin: 80px 0 30px;
    width: 90%;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    padding: 48px 14px 14px;
    position: relative;
    & > .numberBox {
        color: #00ff00;
        background-color: #000000;
        border-radius: 50%;
        text-align: center;
        font-size: 6.5vw;
        font-weight: 700;
        line-height: 48px;
        width: 13vw;
        height: 13vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 8vw;
        transform: translate(0%, -50%);
    }
    & > .desc {
        color: #ffffff;
        font-weight: 700;
        span {
            color: #00ff00;
        }
    }
    & > .videoBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 12px;
        video {
            border-radius: 12px;
            box-shadow: 6.972px 6.972px 6.972px 0px rgba(0, 0, 0, 0.14);
            border: 8px solid #0f0;
            box-sizing: border-box;
            width: 100%;
        }
    }
`;

const useInfoStyle = css`
    & > .title {
        color: #0c002c;
        text-align: center;
        font-family: Sandoll Samliphopangche;
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        padding: 14px;
    }
    background-color: #ffffff;
    padding: 14px;
`;

const useBoxStyle = css`
    border-radius: 14px;
    border: 0.6px solid #0c002c;
    background-color: #ffffff;
    padding: 14px;
    margin-bottom: 14px;
    & > .titleBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const inAppAgreeBoxStyle = css`
    color: #3c3c3c;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    padding: 4px 14px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
`;

const useInfoWrapper = css`
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .gifBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 24px 14px;
        height: fit-content;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.1);
        margin: 24px 0;
        p {
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 14px;
        }
        span {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 200;
            line-height: 26px;
        }
        img {
            border-radius: 24px;
            margin-bottom: 22px;
            width: 100%;
            height: 100%;
            max-width: 421px;
        }
    }
    b {
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 34px;
    }
    .text {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.4px;
    }
`;

const imaWrap = css`
    width: 100%;
    height: 100%;
    max-width: 1133px;
    max-height: 1544px;
`;
