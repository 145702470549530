import React from "react";
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";
import StateLandFilterHeader from "./StateLandFilterHeader";

interface Props {}

export const ownedFilterList = [
    { title: "전체", value: "" },
    { title: "국유지", value: "국유지" },
    { title: "시•도유지", value: "시•도유지" },
    { title: "군유지", value: "군유지" },
    { title: "기타단체", value: "기타단체" },
];

const OwnedFilter: React.FC<Props> = () => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);

    const onClickFilter = (filter: string) => {
        if (stateLandFilter.ownedBy.some((v) => v === filter)) {
            const newOwnedBy = [...stateLandFilter.ownedBy].filter((owned) => owned !== filter);
            setStateLandFilter({
                ...stateLandFilter,
                ownedBy: newOwnedBy,
            });
        } else {
            const newOwnedBy = [...stateLandFilter.ownedBy, filter];
            newOwnedBy.length === 4
                ? setStateLandFilter({ ...stateLandFilter, ownedBy: [] })
                : setStateLandFilter({
                      ...stateLandFilter,
                      ownedBy: newOwnedBy,
                  });
        }
    };
    return (
        <>
            <StateLandFilterHeader title="소유구분" />
            <Grid container css={filterBoxStyle} spacing={1}>
                {ownedFilterList.map((filter, idx) => (
                    <Grid item xs={4} key={filter.title}>
                        <button
                            key={filter.title}
                            css={filterButtonStyle(
                                idx
                                    ? stateLandFilter.ownedBy?.some((val) => val === filter.title)
                                    : !stateLandFilter.ownedBy.length,
                            )}
                            onClick={() => {
                                idx
                                    ? onClickFilter(filter.title)
                                    : setStateLandFilter({
                                          ...stateLandFilter,
                                          ownedBy: [],
                                      });
                            }}
                        >
                            {filter.title}
                        </button>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default OwnedFilter;

const filterButtonStyle = (active: boolean) => css`
    padding: 8px 4px;
    border-radius: 4px;
    background-color: ${active ? "#FF6933" : "#F0F0F0"};
    color: ${active ? "#ffffff" : "#0C002C"};
    font-weight: ${active ? 700 : 400};
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
`;

const filterBoxStyle = css`
    width: 100%;
    padding: 0 14px 24px;
`;
