import { css } from "@emotion/react";
import { stringify } from "querystring";
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import TableButton from "./TableButton";
import TableContents from "./TableContents";
import { testColumns } from "./testColumns";

interface TableProps {
    color: "white" | "gray";
    columns: any;
    data: any; //화면에 보여줄 데이터 리스트 (최초 5개 셋팅)
    dataList: any; //전체 데이터 리스트
    setData: React.Dispatch<SetStateAction<any>>;
}

function Table({ color, columns, data, dataList, setData }: TableProps) {
    //현재 몇번째 값까지 보여줬는지
    const [current, setCurrent] = useState<number>(5);
    //접기or더보기 버튼 정해주는것
    const [isChangeBtn, setIsChangeBtn] = useState<boolean>(false);
    //한번다 확인했는지 안했는지 확인
    const [isOpened, setIsOpened] = useState<boolean>(false);

    //데이터 보여줄 배열에 값 5개씩 추가해주는 함수
    const tryPushData = () => {
        if (isOpened) {
            const allList = dataList.slice(5, dataList.length);
            setData(data.concat(allList));
            setIsChangeBtn(!isChangeBtn);
        } else {
            const picked = dataList.slice(current, current + 5);
            setData(data.concat(picked));
        }
    };

    //data 바뀔때 current 같이 바꿔주는 함수
    useEffect(() => {
        setCurrent(data.length);
    }, [data]);

    //접기하는 함수(초기 5개만 보이게)
    const tryClose = () => {
        setData(data.splice(0, 5));
        setIsChangeBtn(!isChangeBtn);
    };

    useEffect(() => {
        if (data?.length && dataList?.length) {
            if (data.length === dataList.length) {
                setIsChangeBtn(true);
            }
        }
    }, [data, dataList]);

    return (
        <>
            <div css={tableStyle}>
                <TableContents color={color} columns={columns} data={data} dataList={dataList} setIsOpened={setIsOpened} />
                {dataList?.length > 5 && (
                    <TableButton onClick={() => tryPushData()} isChangeBtn={isChangeBtn} tryClose={tryClose} />
                )}
            </div>
        </>
    );
}

const tableStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 14px;
`;

export default Table;
