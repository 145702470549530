import React from "react";
import ico_reload from "@src/assets/ico_reload.svg";
import styled from "@emotion/styled";

type ResetFilterButtonPropsType = {
    onResetClick: () => void;
    onConfirmClick?: () => void;
};

const ResetFilterButton = ({ onResetClick, onConfirmClick }: ResetFilterButtonPropsType) => {
    return (
        <ResetButtonWrapper>
            <button className="resetButton" onClick={onResetClick}>
                <span>초기화</span>
            </button>
            <button className="confirmButton" onClick={onConfirmClick}>
                <span>적용하기</span>
            </button>
        </ResetButtonWrapper>
    );
};

export default ResetFilterButton;

const ResetButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;

    gap: 12px;

    .confirmButton {
        background: #2275ee;
    }
    button {
        background: #c3c3c3;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 14px;

        span {
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -0.4000000059604645px;
            text-align: center;
            color: #ffffff;
        }
    }
`;
