import styled from "@emotion/styled";
import ico_arrow_back from "@src/assets/ico_arrow_back.png";
import icoClose from "@src/assets/ico_close.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import React from "react";
import { useLocation } from "react-router-dom";

type BuildingRegisterHeaderPropsType = {
    title: string;
    subTitle?: string;
    hasCloseBtn?: boolean;
};

const BuildingRegisterHeader = ({ title, subTitle, hasCloseBtn }: BuildingRegisterHeaderPropsType) => {
    const onPrevHandler = () => {
        // const currentLocation = location.href;
        // history.back();
        // setTimeout(() => currentLocation === location.href && transferToNative("done", "closeWebView"), 100);
        transferToNative("done", "closeWebView");
    };

    const onBackHandler = () => {
        const currentLocation = location.href;
        history.back();
        setTimeout(() => currentLocation === location.href && transferToNative("done", "closeWebView"), 100);
    };

    return (
        <DeungiHeaderContainer>
            {!hasCloseBtn && (
                <div className="prevBtn" onClick={onPrevHandler}>
                    <img src={ico_arrow_back} />
                </div>
            )}
            <div className="content">
                <span>{title}</span>
                <small>{subTitle}</small>
            </div>
            {hasCloseBtn && (
                <div className="exitBtn" onClick={onBackHandler}>
                    <img src={icoClose} />
                </div>
            )}
        </DeungiHeaderContainer>
    );
};

export default BuildingRegisterHeader;

const DeungiHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    padding: 14px;

    .prevBtn {
        position: absolute;
        left: 5px;
        top: 10px;
        width: 40px;
        height: 40px;
        padding: 10px;

        & > img {
            width: 10px;
            height: 17px;
        }
    }
    .exitBtn {
        position: absolute;
        right: 5px;
        top: 8px;
        width: 40px;
        height: 40px;
        padding: 10px;
    }

    .content {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;

        & > small {
            font-size: 12px;
            font-weight: 500;
            margin-left: 5px;
        }
    }
`;
