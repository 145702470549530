import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

interface Props {
    open: boolean;
    width?: string;
    maxWidth?: string;
    onCloseView: () => void;
    onClickSubmit?: () => void;
    cancelText?: string;
    cancelStyle?: any;
    confirmText?: string;
    confirmStyle?: any;
    useOnCloseView?: boolean;
    disabled?: boolean;
    addDialogStyle?: any;
}

const MgDialog= ({
    open,
    width = 'max-content',
    maxWidth = '444px',
    confirmStyle,
    cancelStyle,
    cancelText,
    confirmText = '확인',
    onCloseView,
    onClickSubmit,
    useOnCloseView = true,
    disabled = false,
    addDialogStyle,
    children,
}: PropsWithChildren<Props>) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={useOnCloseView ? onCloseView : () => {}}
                sx={{
                    ...addDialogStyle,
                    '& .MuiDialog-paper': {
                        width: width,
                        maxWidth: maxWidth,
                        padding: '14px',
                        borderRadius: '12px',
                    },
                }}
            >
                {children}
                <ActionBox>
                    {cancelText && (
                        <button className="btn cancel" css={cancelStyle} onClick={onCloseView}>
                            {cancelText}
                        </button>
                    )}
                    <button
                        className="btn confirm"
                        css={confirmStyle}
                        disabled={disabled}
                        onClick={() => (cancelText ? onClickSubmit && onClickSubmit() : onCloseView())}
                    >
                        {confirmText}
                    </button>
                </ActionBox>
            </Dialog>
        </>
    );
};

export default MgDialog;

const ActionBox = styled.div`
    padding: 14px 0 0;
    display: flex;
    align-items: center;
    .btn {
        padding: 14px;
        gap: 10px;
        color: #0c002c;
        border-radius: 14px;
        width: 100%;
    }
    button:nth-of-type(2) {
        margin-left: 10px;
    }
    .cancel {
        background: #ebebeb;
    }
    .confirm {
        color: #ffffff;
        background: #2275ee;
    }
`;
