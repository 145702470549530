import { useReducer } from "react";

interface State {
    email: string;
    couponCode: string;
    isModalShow: boolean;
    isRegistered: boolean;
    resultMessage: string;
}

export default function useMultipleStates() {
    const reducer = (state: State, action: { type: keyof State; payload: any }): State => {
        if (action.type === "email" || action.type === "couponCode") {
            let { name, value } = action.payload;
            const newValue = {
                [name]: action.type === "couponCode" ? String(value).toUpperCase() : value,
            };
            state = { ...state, ...newValue };
        } else {
            const newValue = {
                [action.type]: action.payload,
            };
            state = { ...state, ...newValue };
        }

        return state;
    };

    const initialState: State = {
        email: "",
        couponCode: "",
        isModalShow: false,
        isRegistered: false,
        resultMessage: "",
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return {
        state,
        action: dispatch,
    };
}
