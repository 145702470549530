import React, { useEffect, useState } from "react";
import StoreGoStopListCard from "./StoreGoStopListCard";
import { transferToNative } from "@src/lib/WebAppBridge";
import { IStoreGoStopResListDto } from "@src/hooks/storeGoStop/dto/storeGoStop.dto";
import { useStoreGoStopList } from "@src/hooks/storeGoStop/useStoreGoStop";
import { useStoreGoStopFilterStore } from "@src/store/StoreGoStopFilterStore";
import { ICursorType } from "@src/hooks/goStop/dto/goStop.dto";
import { css } from "@emotion/react";
import { useUserSubs } from "@src/hooks/goStop/useGoStop";

import StoreGoStopBottomSheetTitle from "./storeGoStopBottomSheetTitle";
import StoreGoStopListBox from "./storeGoStopListBox";
import { BOTTOM_SHEET_HEIGHT, MAX_Y } from "@src/components/storeGoStop/Constatns";

interface IStoreGoStopBottomSheet {
    sheetRef: any;
    content: any;
    buttonWrapper: any;
    sort: any;
    data: any
    isTouched: any;
    buttonRef1: any;
    buttonRef2: any;
    buttonRef3: any;
    showSortBox: boolean;
    onChangeShowSortBox: () => void;
    cursor: any;
    setCursor: (val: number) => void;
}

const StoreGoStopBottomSheet = ({
    sheetRef,
    content,
    buttonWrapper,
    sort,
    cursor,
    setCursor,
    isTouched,
    buttonRef1,
    buttonRef2,
    buttonRef3,
    showSortBox,
    data,
    onChangeShowSortBox,
}: IStoreGoStopBottomSheet) => {
    const filterParams = useStoreGoStopFilterStore((state) => state.storeGoStopFilterState);



    return (
        <div css={rootBoxStyle(filterParams.loc.length > 0 ? 140 : 175)} ref={sheetRef}>
            <div css={topHandlerStyle}>
                <div />
            </div>
            <StoreGoStopBottomSheetTitle
                buttonWrapper={buttonWrapper}
                sort={sort}
                totalCount={data?.pages?.[0]?.totalCnt ?? 0}
                showSortBox={showSortBox}
                firstButtonRef={buttonRef1}
                secondButtonRef={buttonRef2}
                thirdButtonRef={buttonRef3}
                onChangeShowSortBox={onChangeShowSortBox}
            />
            <StoreGoStopListBox data={data} content={content} />
        </div>
    );
};

export default StoreGoStopBottomSheet;

const rootBoxStyle = (top: number) => css`
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        border-radius: 14px 14px 0px 0px;
        background-color: #fff;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
        height: ${BOTTOM_SHEET_HEIGHT + top}px;
        transition: transform 150ms ease-out;
        width: 100vw;
        top: ${MAX_Y - top}px;
        z-index: 994;
        `;

const topHandlerStyle = css`
        height: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: relative;
        padding-top: 16px;
        padding-bottom: 4px;
    & > div {
            width: 32px;
        height: 4px;
        border-radius: 2px;
        background-color: #d0d0d0;
        margin: auto;
    }
        `;
