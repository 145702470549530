import styled from "@emotion/styled";
import AiFooter from "@src/components/aiFilter/AiFooter";
import AiHeader from "@src/components/aiFilter/AiHeader";
import AiResultTotal from "@src/components/aiFilter/AiResultTotal";
import AiTitle from "@src/components/aiFilter/AiTitle";
import PriceInput from "@src/components/aiFilter/PriceInput";
import { transferToNative } from "@src/lib/WebAppBridge";
import { FilterParamsType, useAiFilterStore } from "@src/store/AiFilterStore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import AiResetConfirmModal from "./AiResetConfirmModal";
import lottie_money from "@src/assets/lottie_money.json";
import lottie_loading from "@src/assets/lottie_loading.json";
import useCallToken from "@src/hooks/useCallToken";

const AiFilterMain = () => {
    const [input, setInput] = useState("");
    const [resetVisible, setResetVisible] = useState(false);

    const navigate = useNavigate();

    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const isLoading = useAiFilterStore((state) => state.isLoading);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const getAiFilterState = useAiFilterStore((state) => state.getAiFilterState);
    const getAiFilterCount = useAiFilterStore((state) => state.getAiFilterCount);

    const { state } = useLocation() as {
        state: FilterParamsType;
    };

    const { token } = useCallToken();

    useEffect(() => {
        // token && getAiFilterState();
    }, [token]);

    useEffect(() => {
        setInput(state?.price || aiFilterState.price);
    }, [aiFilterState]);

    const handleSubmit = (input: string) => {
        setAiFilterState({
            ...aiFilterState,
            price: input,
        });
    };

    const handleKeyUP = async (e: any) => {
        if (e.key === "Enter") {
            await handleSubmit(input);
            getAiFilterCount({
                ...aiFilterState,
                price: input,
            });
        }
    };

    const onClickReset = () => {
        setResetVisible(true);
    };

    const onClickCancel = () => {
        setResetVisible(false);
    };

    const onClickConfirm = async () => {
        setInput("0");
        handleSubmit("0");
        setResetVisible(false);
    };

    const onClickGoBack = () => {
        transferToNative("done", "closeWebView");
    };

    return (
        <Wrapper>
            <Container>
                <AiHeader onClickBack={onClickGoBack} />
                <AiTitle>
                    <h2>
                        <span className="color-text">투자금</span>을 입력해주세요
                    </h2>
                    <p>
                        소액도 상관없어요. 현재 여유자금을 입력하면 <br />
                        최적의 경공매 매물을 찾아드려요
                    </p>
                </AiTitle>
                <PriceInput input={input} setInput={setInput} onKeyUp={handleKeyUP} handleSubmit={handleSubmit} />
                <div className="lottie-area">
                    {isLoading ? (
                        <Lottie className="lottie" animationData={lottie_loading} />
                    ) : (
                        isLoading !== null && (
                            <>
                                <AiResultTotal result={aiFilterCount} />
                                <Lottie className="lottie" animationData={lottie_money} loop={false} />
                            </>
                        )
                    )}
                </div>
                <AiFooter
                    isReset
                    isNext
                    onClickReset={onClickReset}
                    onClickNext={() => {
                        navigate("/aiFilter/location", { state: { ...aiFilterState, price: input } });
                    }}
                    style={{ bottom: "0" }}
                />
            </Container>
            {resetVisible ? (
                <AiResetConfirmModal text="필터를 초기화할까요?" onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />
            ) : (
                <></>
            )}
        </Wrapper>
    );
};

export default AiFilterMain;

const Container = styled.div`
    position: relative;
    padding: 0px 14px;
    height: 100vh;

    .lottie-area {
        height: calc(100% - 54px);
    }

    .lottie {
        width: 100%;
        margin-top: 20px;
    }
`;

const Wrapper = styled.div``;
