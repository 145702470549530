import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { IRegion, useGetLocation } from "@src/hooks/newFilter/useGetLocation";
import React, { useEffect, useRef, useState } from "react";
import ShowSelectedUseLocValueBox from "../newFilter/ShowSelectedUseLocValueBox";
import { MAX_Y } from "@src/components/aiFilter/SortConstants";
import { Common } from "@src/styles/Common";

interface Props {
    locationList: string[];
    onChangeLocation: (val: string[]) => void;
    onSubmitShowToast: () => void;
}

const StoreGoStopLocationFilter: React.FC<Props> = ({ locationList, onChangeLocation, onSubmitShowToast }) => {
    const [sidoList, setSidoList] = useState<any>();
    const [sigunguList, setSigunguList] = useState<any>();
    const [emdList, setEmdList] = useState<any>();
    const [selectedSido, setSelectedSido] = useState<any>();
    const [selectedSigungu, setSelectedSigungu] = useState<any>();
    const [showLocText, setShowLocText] = useState<any[]>([]);
    const [lastRegionForFirst, setLastRegionForFirst] = useState<string | null>();
    const sidoRef = useRef<any>();


    const makeLocText = async (loc: string) => {
        let sidoText = "";
        let sigunguText = "";
        let emdText = "";
        sidoText = await useGetLocation("0000000000").then((res) => {
            const selectedSido = res.filter((val: any) => val?.cortarNo?.slice(0, 2) === loc.slice(0, 2));
            return selectedSido?.[0]?.cortarName ?? "";
        });
        if (loc.slice(2) !== "00000000") {
            sigunguText = await useGetLocation(`${loc.slice(0, 2)}00000000`).then((res) => {
                const selectedSigungu = res.filter((val: any) => val?.cortarNo?.slice(0, 5) === loc.slice(0, 5));
                return selectedSigungu?.[0]?.cortarName;
            });
        } else {
            return { name: `${sidoText} > 전체`, value: loc };
        }
        if (loc.slice(5) !== "00000") {
            emdText = await useGetLocation(`${loc.slice(0, 5)}00000`).then((res) => {
                const selectedEmd = res.filter((val: any) => val?.cortarNo === loc);
                return selectedEmd?.[0]?.cortarName;
            });
        } else {
            emdText = "전체";
        }
        return { name: `${sidoText} > ${sigunguText} > ${emdText}`, value: loc };
    };
    const settingLocText = async () => {
        const result = await Promise.all(
            locationList?.map(async (val: any) => {
                return await makeLocText(val);
            }),
        );
        setShowLocText(result);
    };
    useEffect(() => {
        if (locationList?.length) {
            settingLocText();
        } else {
            setSelectedSido(null);
            setSelectedSigungu(null);
            setShowLocText([]);
        }
    }, [locationList]);

    useEffect(() => {
        useGetLocation('0000000000').then((res) => {
            setSidoList(res);
            if (locationList.length) {
                const lastInvestmentFilterRegion = locationList[locationList.length - 1];
                setLastRegionForFirst(lastInvestmentFilterRegion);
                const newSido = res.filter(
                    (val: any) => val.cortarNo.slice(0, 2) === lastInvestmentFilterRegion.slice(0, 2),
                );
                setSelectedSido(newSido?.[0]);
            } else {
                setSigunguList([]);
                setEmdList([]);
            }
        });
    }, []);

    useEffect(() => {
        if (selectedSido) {
            useGetLocation(selectedSido.cortarNo)
                .then((res: any) => {
                    setSigunguList(res);
                    setEmdList([]);
                    if (lastRegionForFirst) {
                        const newSigungu = res.filter(
                            (val: any) => val.cortarNo.slice(0, 5) === lastRegionForFirst.slice(0, 5),
                        );
                        setSelectedSigungu(newSigungu?.[0]);
                    }
                })
                .then(() => {
                    setLastRegionForFirst(null);
                });
        } else {
            setSigunguList([]);
        }
    }, [selectedSido]);

    useEffect(() => {
        if (selectedSigungu) {
            useGetLocation(selectedSigungu.cortarNo).then((res) => {
                setEmdList(res);
            });
        } else {
            setEmdList([]);
        }
    }, [selectedSigungu]);
    return (
        <>
            <div css={summaryBoxStyle}>
                {!!showLocText.length &&
                    showLocText.map((locData: any) => (
                        <ShowSelectedUseLocValueBox
                            value={locData.name}
                            onDeleteValue={() => {
                                const newLoc = [...locationList].filter((val) => val !== locData.value);
                                onChangeLocation(newLoc);
                            }}
                            key={locData.name}
                        />
                    ))}
            </div>
            <Grid container css={subTitleBoxStyle}>
                <Grid item xs={3.5}>
                    <p className="subTitleText">시/도</p>
                </Grid>
                <Grid item xs={4.3}>
                    <p className="subTitleText">시/구/군</p>
                </Grid>
                <Grid item xs={4.2}>
                    <p className="subTitleText">동/읍/면</p>
                </Grid>
            </Grid>
            <Grid container css={selectBoxRootStyle}>
                <Grid item xs={3.5} css={selectBoxStyle(MAX_Y)} ref={sidoRef}>
                    <button
                        css={selectButtonStyle(!locationList.length)}
                        onClick={() => {
                            setSelectedSigungu(null);
                            setSelectedSido(null);
                            onChangeLocation([])
                        }}
                    >
                        전국
                    </button>
                    {sidoList?.map((item: any, idx: number) => {
                        let checkActive: boolean = false;
                        if (locationList.length) {
                            checkActive = locationList.some((val: any) => val?.startsWith(item.cortarNo.slice(0, 2)));
                        }
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    setSelectedSido(item);
                                    let newLoc: string[] = [];
                                    if (checkActive) {
                                        newLoc = [...locationList].filter((v) => !v.startsWith(item.cortarNo.slice(0, 2)));
                                        onChangeLocation([...newLoc])
                                    } else {
                                        newLoc = [...locationList, item.cortarNo];
                                        onChangeLocation([...newLoc]);
                                    }
                                    !newLoc.length && sidoRef.current.scrollTo(0, 0);
                                }}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
                <Grid item xs={4.3} css={selectBoxStyle(MAX_Y)}>
                    {!!sigunguList?.length && (
                        <button
                            css={selectButtonStyle(locationList.some((val: any) => val === selectedSido?.cortarNo))}
                            onClick={() => {
                                if (locationList.some((val: any) => val === selectedSido?.cortarNo)) {
                                    const newLoc = [...locationList].filter((v) => v !== selectedSido?.cortarNo);
                                    onChangeLocation([...newLoc]);
                                    setSelectedSido(null);
                                    setSelectedSigungu(null);
                                } else {
                                    const newLoc = [
                                        ...locationList.filter((v: any) => !v.startsWith(selectedSido?.cortarNo.slice(0, 2))),
                                        selectedSido?.cortarNo,
                                    ];
                                    onChangeLocation([...newLoc]);
                                    setSelectedSigungu(null);
                                }
                            }}
                        >
                            전체
                        </button>
                    )}
                    {sigunguList?.map((item: any) => {
                        const activeListLengthCheck = locationList.some((val: any) => val === selectedSido?.cortarNo);
                        let checkActive: boolean = true;
                        activeListLengthCheck
                            ? (checkActive = false)
                            : (checkActive = locationList.some((val: any) => val.slice(0, 5) === item.cortarNo.slice(0, 5)));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    setSelectedSigungu(item);
                                    if (checkActive) {
                                        const newLoc = [...locationList].filter(
                                            (v) => !v.startsWith(item.cortarNo.slice(0, 5)),
                                        );
                                        !newLoc.some((v) => v.startsWith(selectedSido?.cortarNo?.slice(0, 2))) &&
                                            setSelectedSigungu(null);
                                        onChangeLocation([...newLoc]);
                                    } else {
                                        const newLoc = [
                                            ...locationList.filter((v: any) => v !== selectedSido?.cortarNo),
                                            item.cortarNo,
                                        ];
                                        const checkMajor = item.cortarNo.slice(0, 2);
                                        const dulplicateCheck = new Set(newLoc.map((v) => v.slice(0, 5)));
                                        const checkActiveList = Array.from(dulplicateCheck).filter(
                                            (val) =>
                                                val.startsWith(checkMajor) &&
                                                val.slice(0, 5) !== selectedSido?.cortarNo.slice(0, 5),
                                        );
                                        const listLengthCheck = sigunguList.length === checkActiveList.length;
                                        if (listLengthCheck) {
                                            const changeLoc = [
                                                ...locationList.filter((v: any) => !v.startsWith(checkMajor)),
                                                selectedSido?.cortarNo,
                                            ];
                                            if (changeLoc.length > 10) {
                                                onSubmitShowToast();
                                                return;
                                            }
                                            onChangeLocation([...changeLoc]);
                                        } else {
                                            if (newLoc.length > 10) {
                                                onSubmitShowToast();
                                                return;
                                            }
                                            onChangeLocation([...newLoc]);
                                        }
                                    }
                                }}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
                <Grid item xs={4.2} css={selectBoxStyle(MAX_Y)}>
                    {!!emdList?.length && (
                        <button
                            css={selectButtonStyle(locationList.some((val: any) => val === selectedSigungu?.cortarNo))}
                            onClick={() => {
                                if (locationList.some((val: any) => val === selectedSigungu?.cortarNo)) {
                                    const newLoc = [...locationList].filter((v) => v !== selectedSigungu?.cortarNo);
                                    onChangeLocation([...newLoc]);
                                    setSelectedSigungu(null);
                                } else {
                                    const newLoc = [
                                        ...locationList.filter(
                                            (v: any) => !v.startsWith(selectedSigungu?.cortarNo.slice(0, 5)),
                                        ),
                                        selectedSigungu?.cortarNo,
                                    ];
                                    onChangeLocation([...newLoc]);
                                }
                            }}
                        >
                            전체
                        </button>
                    )}
                    {emdList?.map((item: any) => {
                        const activeListLengthCheck = locationList.some((val: any) => val === selectedSigungu?.cortarNo);
                        let checkActive: boolean = true;
                        activeListLengthCheck
                            ? (checkActive = false)
                            : (checkActive = locationList.some((val: any) => val === item.cortarNo));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    // setSelectedSigungu(item);
                                    if (checkActive) {
                                        const newLoc = [...locationList].filter((v) => v !== item.cortarNo);
                                        onChangeLocation([...newLoc]);
                                        !newLoc.some((v) => v.startsWith(selectedSigungu?.cortarNo?.slice(0, 5))) &&
                                            setSelectedSigungu(null);
                                    } else {
                                        const newLoc = [
                                            ...locationList.filter((v: any) => v !== selectedSigungu?.cortarNo),
                                            item.cortarNo,
                                        ];
                                        const checkMajor = item.cortarNo.slice(0, 5);
                                        const checkActiveList = newLoc.filter(
                                            (val) => val.startsWith(checkMajor) && val !== selectedSigungu?.cortarNo,
                                        );
                                        const listLengthCheck = emdList.length === checkActiveList.length;
                                        if (listLengthCheck) {
                                            const changeLoc = [
                                                ...locationList.filter((v: any) => !v.startsWith(checkMajor)),
                                                selectedSigungu?.cortarNo,
                                            ];
                                            if (changeLoc.length > 10) {
                                                onSubmitShowToast();
                                                return;
                                            }
                                            onChangeLocation([...changeLoc]);
                                        } else {
                                            if (newLoc.length > 10) {
                                                onSubmitShowToast();
                                                return;
                                            }
                                            onChangeLocation([...newLoc]);
                                        }
                                    }
                                }}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
};

export default StoreGoStopLocationFilter;

const subTitleBoxStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 0.6px solid #b1aeb8;
    font-size: 14px;
    .subTitleText {
        padding: 8px 14px;
        background-color: #ffffff;
        color: #0c002c;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        box-sizing: border-box;
        width: 100%;
    }
`;

const selectBoxRootStyle = css`
    display: flex;
    align-items: center;
`;

const selectBoxStyle = (MAXY: number) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 12px 10px;
    gap: 8px;
    height: ${window.innerHeight - MAXY - window.innerHeight / 4}px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    background: #f0f0f0;
    transition: color 0.3s linear;
    font-size: 14px;
    font-weight: 500;
    ${active && {
        background: Common.colors.aucMarkerColor,
        color: "#ffffff",
    }}
`;

const summaryBoxStyle = css`
    padding: 8px 14px;
    display: flex;
    align-items: center;
    width: 100vw;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`;
