import styled from "@emotion/styled";
import { useDeungiAlarm } from "@src/hooks/deungi/useDeungiAlarm";
import ico_deungi_ticket from "@src/assets/ico_deungi_ticket.png";
import React, { useEffect, useState } from "react";

type DeungiContentsPropsType = {
    handleSelect: string
};

const DeungiReadingAlarm = ({ handleSelect }: DeungiContentsPropsType) => {
    const [alarmFlag, setAlarmFlag] = useState(false);

    const getAlarmState = async () => {
        await useDeungiAlarm().then(res => {
            const data = res.data;
            setAlarmFlag(data || false);
        });
    };

    useEffect(() => {
        getAlarmState();
    },[]);

    useEffect(() => {
        if(handleSelect === 'search' && alarmFlag) setTimeout(() => setAlarmFlag(false), 2000);
    }, [handleSelect, alarmFlag]);

    return (
        <Container className={ handleSelect === 'search' && alarmFlag ? "show" : ""}>
            <div className="contents">
                <img src={ico_deungi_ticket} />
                <p>
                    <h3>등기 열람 진행중</h3>
                    <span>잠시만 기다려주세요</span>
                </p>
                <label className="cover"/>
                <label className={ handleSelect === 'search' && alarmFlag ? "active" : ""} />
            </div>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 140px;
    left: 0;
    bottom: -200px;
    background: #0047FF;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: all .5s;
    z-index: 2;

    &.show {
        bottom: 0;
    }
    .contents {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 305px;
        padding-bottom: 20px;
        line-height: 1.4;

        & > img {
            width: 50px;
            height: 50px;
            margin-right: 14px;
        }
        & > p {
            & > h3 {
                font-size: 20px;
                font-weight: 700;
                color: #FFF500;
            }
            & > span {
                font-size: 14px;
                font-weight: 400;
                color: #fff;
            }
        }
        & > label {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 8px;
            transition: all .2s ease;

            &.cover {
                width: 100%;
                background: #fff;
            }
            &.active {
                width: 0;
                background: #FFF500;
                animation: percentUp 1.5s forwards;
                animation-delay: .3s;
            }
        }
    }
    @keyframes percentUp {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
`;

export default DeungiReadingAlarm;
