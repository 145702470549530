import styled from "@emotion/styled";
import { Button } from "@src/components";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction } from "react";

interface CancelModalProps {
    setIsModalShow: Dispatch<SetStateAction<boolean>>;
    onClickRefund: () => void;
}

const CancelModal = ({ setIsModalShow, onClickRefund }: CancelModalProps) => {
    return (
        <>
            <StyledWrap>
                <StyledModalDiv>
                    <div className="contents">
                        <div>이용권 충전을 취소하시겠습니까?</div>
                        <div className="subs">
                            결제액이 환불되며 이용권 충전이
                            <br />
                            취소됩니다.
                        </div>
                    </div>
                    <div className="buttonDiv">
                        <Button
                            color="gray"
                            label="닫기"
                            handleClick={() => {
                                setIsModalShow(false);
                            }}
                            size={"small"}
                            styles={{ backgroundColor: "#EBEBEB" }}
                        />
                        <Button
                            color="blue"
                            label="환불하기"
                            handleClick={() => {
                                onClickRefund();
                                setIsModalShow(false);
                            }}
                            size={"small"}
                        />
                    </div>
                </StyledModalDiv>
                <div
                    className="shadow"
                    onClick={() => {
                        setIsModalShow(false);
                    }}
                ></div>
            </StyledWrap>
        </>
    );
};

export default CancelModal;

const StyledModalDiv = styled.div`
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;
    max-width: 320px;
    width: calc(100vw - 48px);
    border-radius: 14px;
    position: absolute;
    height: 190px;
    background-color: #ffffff;
    top: calc(50% - 190px / 2 - 10px);
    & > .contents {
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.body2_R16};
        text-align: center;
        & > .subs {
            margin-top: 8px;
            font-size: 14px;
        }
    }
    & > .buttonDiv {
        display: flex;
        gap: 8px;
        & > button {
            width: 50%;
            height: 56px;
            padding: 0px;
            font-weight: 400;
            font-size: 18px;
            border-radius: 14px;
        }
    }
`;

const StyledWrap = styled.div`
    display: flex;
    justify-content: center;
    & > .shadow {
        width: 100vw;
        height: 100vh;
        background-color: ${Common.colors.darkGrey03};
        opacity: 0.7;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 29;
    }
`;
