import { mapOptions } from "@src/constants/mapOptions";

export class NaverMap {
    map: naver.maps.Map;

    constructor(mapCurrent: any, position: { lat: number; lng: number }) {
        this.map = new naver.maps.Map(mapCurrent, {
            ...mapOptions,
            center: new naver.maps.LatLng(position.lat, position.lng),
            scaleControlOptions: {
                position: naver.maps.Position.LEFT_BOTTOM,
            },
            logoControl: false,
            mapDataControl: false,
        });
    }
    setPosition(position: any) {
        this.map.setOptions("center", new naver.maps.LatLng(position.lat, position.lng));
    }
    polygon(coordinates: any): naver.maps.Polygon {
        return new naver.maps.Polygon({
            map: this.map,
            paths: [polyPaths(coordinates)],
            fillColor: "#008FF8",
            fillOpacity: 0.3,
            strokeOpacity: 0,
            clickable: false,
        });
    }
    roadPolygon(coordinates: any[]): naver.maps.Polygon {
        return new naver.maps.Polygon({
            map: this.map,
            paths: coordinates,
            fillColor: "#008FF8",
            fillOpacity: 0.3,
            strokeOpacity: 1,
            clickable: false,
            strokeStyle: "dash",
            strokeColor: "#5F0080",
            strokeWeight: 1,
            strokeLineCap: "square",
        });
    }
    polyline(coordinates: any): naver.maps.Polyline {
        return new naver.maps.Polyline({
            map: this.map,
            path: polyPaths(coordinates),
            strokeStyle: "dash",
            strokeColor: "#5F0080",
            strokeWeight: 1,
            strokeLineCap: "square",
            clickable: true,
        });
    }

    marker(markerElem: string | HTMLElement, position: { lat: number; lng: number }, zIndex: number, visible: boolean) {
        return new naver.maps.Marker({
            position: new naver.maps.LatLng(position.lat, position.lng),
            map: this.map,
            clickable: true,
            zIndex,
            icon: {
                content: markerElem,
                anchor: new naver.maps.Point(50, 70),
                origin: new naver.maps.Point(50, 50),
            },
            visible,
        });
    }
}

export const polyPaths = (coordinates: any) => {
    return coordinates?.map((value: any) => {
        const [lng = null, lat = null] = value;
        if (lat && lng) {
            return new naver.maps.LatLng(lat, lng);
        }
        return null;
    });
};
