export const itemForFilterProgress = [
    {
        title: "전체",
        id: "0",
        isActive: true,
    },
    {
        title: "진행예정",
        id: "1",
        isActive: false,
    },
    {
        title: "진행중",
        id: "2",
        isActive: false,
    },
    {
        title: "매각",
        id: "3",
        isActive: false,
    },
    {
        title: "기타",
        id: "4",
        isActive: false,
    },
];
