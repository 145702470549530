import styled from "@emotion/styled";
import icoClose from "@src/assets/ico_close.png";
import { DEUNGI_POPUP_TYPE } from "@src/components/deungi/common/enum";
import { checkDevice } from "@src/components/deungi/common/utils";
import DeungiPopup from "@src/components/deungi/DeungiPopup";
import { PrevDeungiResponseDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { DeungiListResponseDto } from "@src/hooks/deungi/dto/DeungiList.dto";
import { UserSubsInfoResponseDto } from "@src/hooks/deungi/dto/UserSubs";
import { useDeungiListByPnu } from "@src/hooks/deungi/useDeungiList";
import { usePrevDeungi } from "@src/hooks/deungi/usePrevDeungi";
import { useUserSubsInfo } from "@src/hooks/deungi/useUserSubsInfo";
import { transferToNative } from "@src/lib/WebAppBridge";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";

function DeungiList() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [openData, setOpenData] = useState({} as any);
    const [popupToggle, setPopupToggle] = useState(false);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.OPEN);
    const [deungiList, setDeungiList] = useState([] as DeungiListResponseDto[]);
    const [isLoading, setIsLoading] = useState(true);

    const gotoDeungiPage = () => {
        const address = searchParams.get("address") || "";
        navigate({
            pathname: "/deungi",
            search: `?type=search&searchText=${address || ""}`,
        });
    };

    const onOpenDeungiHandler = async (data: DeungiListResponseDto) => {
        if (!data) return;

        setOpenData(data);

        const res = await usePrevDeungi(data.pin);
        const prevDeungiInfo: PrevDeungiResponseDto = res.data;

        if (prevDeungiInfo && prevDeungiInfo.id.length > 0) {
            setOpenData((prev: any) => {
                return { ...prev, ...prevDeungiInfo };
            });

            // 1개월이 지난 등기부등본인 경우
            if (dayjs(prevDeungiInfo.updatedAt).add(1, "month").diff() < 0) {
                // 모든 회원 열람 가능
                setPopupType(DEUNGI_POPUP_TYPE.REOPEN);
            } else {
                const subsRes = await useUserSubsInfo();
                const subsInfo: UserSubsInfoResponseDto = subsRes.data;

                // 스탠다드 또는 프리미엄 1년 상품인 경우
                if (subsInfo && ["스탠다드", "프리미엄"].includes(subsInfo.subsName) && subsInfo.serveDurationType === "year") {
                    setPopupType(DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST_YEAR_SUBS);
                    // 스탠다드 1년 상품이 아닌 경우
                } else {
                    // 내가 열람한 등기부등본이 있는 경우
                    if (prevDeungiInfo.myId) {
                        setPopupType(DEUNGI_POPUP_TYPE.HAS_DEUNGI);
                    } else {
                        // 내가 열람한 등기부등본이 아닌 경우
                        if (subsInfo && !subsInfo.isFreeIssueSubs) {
                            // 유료 구독 상품인 경우
                            setPopupType(DEUNGI_POPUP_TYPE.SELECT_NEW_OR_EXIST);
                        } else {
                            // 무료 상품 또는 미구독 회원인 경우
                            setPopupType(DEUNGI_POPUP_TYPE.PAY_EXIST_DEUNGI);
                        }
                    }
                }
            }
        } else {
            setPopupType(DEUNGI_POPUP_TYPE.OPEN);
        }

        setPopupToggle(true);
    };

    const getDeungiListByPnu = async () => {
        const isLand = searchParams.get("isLand") || "false";
        const pnu = searchParams.get("pnu") || "";
        const res = await useDeungiListByPnu(pnu);

        if (isLand === "true") {
            const data = res.data.list.filter((el2: DeungiListResponseDto) => el2.item_type === "토지");
            setDeungiList(data);
            onOpenDeungiHandler(data[0]);
        } else {
            setDeungiList(res.data.list.filter((el: DeungiListResponseDto) => el.item_type !== "토지"));
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getDeungiListByPnu();
    }, []);

    return (
        <>
            <Container>
                <div className="exitBtn" onClick={() => transferToNative("done", "closeWebView")}>
                    <img src={icoClose} />
                </div>
                <h3>{searchParams.get("isLand") === "true" ? "토지" : "건물"}등기열람</h3>
                <div className="listBox">
                    <table>
                        <thead>
                            <tr>
                                <th>소재지</th>
                                <th>상태</th>
                                <th>열람일(구분)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deungiList &&
                                deungiList.map((el) => {
                                    return (
                                        <tr key={el._id} onClick={() => onOpenDeungiHandler(el)}>
                                            <td>{el.address}</td>
                                            <td>{el.deungi_type}</td>
                                            <td>
                                                {dayjs(el.updated_at).format("YY.MM.DD")}
                                                <br />({el.item_type})
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    {isLoading && <Lottie className="lottie" animationData={lottie_loading} />}
                    {!isLoading && deungiList.length === 0 && <div className={"noDeungi"}>조회된 등기부등본이 없습니다.</div>}
                </div>
                <div className="inputBox">
                    <input type="button" value="다른 등기 열람하러 가기" onClick={gotoDeungiPage} />
                </div>
            </Container>
            <DeungiPopup type={popupType} toggle={popupToggle} setToggle={setPopupToggle} data={openData} />
        </>
    );
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 998;
    background: #fff;
    transition: all 0.5s ease;

    .exitBtn {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;

        & > img {
            width: 14px;
            height: 14px;
            margin: auto;
        }
    }
    h3 {
        font-weight: 700;
        font-size: 18px;
        color: #0c0c0c;
        margin: 16px 0;
    }
    .listBox {
        position: absolute;
        top: 50px;
        bottom: 80px;
        left: 0;
        width: 100%;
        overflow: auto;
        overscroll-behavior-y: none;

        & > table {
            border-collapse: separate;
            width: 100%;
            font-size: 13px;

            & > thead {
                position: sticky;
                top: 0;
            }
            & > thead > tr {
                background: #fbf8f8;
                color: #505050;

                & > th {
                    word-break: keep-all;
                    font-weight: 500;
                    border-top: 1px solid #c9c9c9;
                    border-bottom: 1px solid #c9c9c9;
                    border-right: 1px solid #c9c9c9;
                    padding: 7px 0;
                }
                & > th:nth-of-type(1) {
                    width: 65%;
                }
                & > th:nth-of-type(3) {
                    border-right: 0;
                    width: 80px;
                }
            }
            & > tbody > tr {
                color: #0c002c;
                animation: fadeIn 0.5s;

                & > td {
                    word-break: keep-all;
                    font-weight: 500;
                    line-height: 1.4;
                    vertical-align: middle;
                    text-align: center;
                    padding: 15px 3px;
                    border-bottom: 1px solid #c9c9c9;
                    border-right: 1px solid #c9c9c9;
                }
                & > td:nth-of-type(1) {
                    text-align: left;
                    padding-left: 8px;
                    word-break: normal;
                }
                & > td:nth-of-type(4) > span {
                    background: #ebfbfa;
                    padding: 8px 10px;
                    border-radius: 24px;
                    border-right: 0;
                }
            }
        }
    }
    .inputBox {
        position: absolute;
        width: 100%;
        bottom: 14px;
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding: 0 14px;

        input {
            width: 100%;
            height: 55px;
            background: #0047ff;
            border: none;
            border-radius: 14px;
            color: #fff;
            font-size: 18px;
            margin-top: 15px;
            padding: 0 10px;
            transition: all 0.5s ease;

            &.hidden {
                display: none;
            }
        }
    }
    .lottie {
        width: 50px;
        margin: 20px auto;
    }
    .noDeungi {
        margin: 28px 0;
        width: 100%;
        text-align: center;
        font-size: 13px;
    }
`;

export default DeungiList;
