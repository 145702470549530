import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";
import { useGetAreaSize } from "./useFindRoadWidth";
import useGetRoadAddressFromCoords from "./useMapCoordToRoadAddr";
import { getLandDataFromDataPortal, parseBuildingCard } from "./useGetFieldContents";
import convertToPyeong from "@src/util/convertToPyeong";

export const fetchAreaCardData = async (params: { pnu: string; lat: number; lng: number; address: string; jimok: string }) => {
    const { lat, lng, pnu, address, jimok } = params;
    const response = await axiosInstance.get(`${axiosPath.areaCardDataPath}/${pnu}`).then((res) => {
        const newData = res?.data?.payload?.result;
        return newData;
    });
    const roadAddress = await useGetRoadAddressFromCoords({ _lat: lat, _lng: lng });
    const landSize = await useGetAreaSize(pnu);
    let { land_list: land, title_info_total: total, title_info_single: single } = response;
    const buildingTypes = [
        "대",
        "대지",
        "공장용지",
        "학교용지",
        "창고용지",
        "종교용지",
        "유원지",
        "체육용지",
        "과수원",
        "광천지",
        "주차장",
        "주유소용지",
        "철도용지",
        "제방",
        "구거",
        "양어장",
        "수도용지",
        "묘지",
        "잡종지",
    ];
    const fieldType =
        !total?.length && !single?.length
            ? "land"
            : single?.length &&
              !total?.length &&
              !single[0]?.regstrKindCdNm.includes("집합") &&
              !single[0]?.regstrKindCdNm.includes("일반") &&
              !single[0]?.regstrKindCdNm
            ? "land"
            : buildingTypes.includes(jimok ? jimok : land[0]?.lndcgrCodeNm)
            ? "building"
            : "land";
    let dataFromPortal: any;
    if (fieldType === "land") {
        dataFromPortal = await getLandDataFromDataPortal(pnu);
        console.log(dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr, land[0]?.lndpclAr?.square, landSize.square);
        land = [
            {
                ...land[0],
                lndcgrCodeNm: jimok
                    ? jimok
                    : dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndcgrCodeNm ?? land[0]?.lndcgrCodeNm ?? "-",
                lndpclAr: {
                    square: dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr
                        ? `${Number(dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr ?? 0).toLocaleString()}㎡`
                        : land[0]?.lndpclAr?.square
                        ? land[0]?.lndpclAr?.square
                        : landSize.square ?? "",
                    pyong: dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr
                        ? `${Math.round(
                              Number(convertToPyeong(dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.lndpclAr ?? 0)),
                          ).toLocaleString()}평`
                        : land[0]?.lndpclAr?.pyong
                        ? land[0]?.lndpclAr?.pyong
                        : landSize.pyong ?? "",
                },
                posesnSeCodeNm:
                    dataFromPortal?.ladfrlVOList?.ladfrlVOList?.[0]?.ladfrlVOList?.posesnSeCodeNm ??
                    land[0]?.posesnSeCodeNm ??
                    "-",
            },
        ];
    }
    let buildingType: number = 1;
    if (total && total?.length) {
        if (Array.isArray(total)) {
            const code = total[0]?.regstrKindCdNm ?? "집합";
            if (code.includes("집합")) buildingType = 3;
            if (code.includes("일반")) {
                if (land?.[0]?.lndcgrCodeNm === "대") buildingType = 1;
                if (land?.[0]?.lndcgrCodeNm !== "대") buildingType = 2;
            }
        }
    } else if (single && single?.length) {
        if (Array.isArray(single)) {
            const arr = single.filter((v) => v?.regstrKindCdNm.includes("집합"));
            buildingType = 3;
            if (!arr[0]) {
                const code = single[0]?.regstrKindCdNm;
                if (code.includes("일반")) {
                    if (land?.[0]?.lndcgrCodeNm === "대") buildingType = 1;
                    if (land?.[0]?.lndcgrCodeNm !== "대") buildingType = 2;
                }
            }
        }
    }
    const buildingCard = parseBuildingCard(total, single);
    const returnData = {
        fieldType,
        address: address,
        roadAddress: roadAddress === "일치하는 도로명 주소가 없습니다" ? "" : roadAddress,
        size: land[0].lndpclAr?.square ? land[0].lndpclAr : landSize,
        land: {
            use: land[0].prposAreaNm1,
            lndcgrCodeNm: land[0].lndcgrCodeNm,
            owner: land[0].posesnSeCodeNm,
            size: land[0].lndpclAr.pyong ? land[0].lndpclAr.pyong : landSize?.pyong,
            landUsePlan: dataFromPortal?.landUsePlan ?? {},
            officialPrice: dataFromPortal?.officialPrice ?? [],
            ...land[0],
        },
        building: {
            ...buildingCard,
            buildingType,
            origin: {
                total,
                single,
            },
        },
        pnu,
        lat,
        lng,
    };
    return returnData;
};

export const useFindOneSimpleLandData = (params: { pnu: string; lat: number; lng: number; address: string; jimok: string }) => {
    return useQuery<ISimpleLandData>(["simpleLandData", params], () => fetchAreaCardData(params), {
        enabled: false,
    });
};

export interface ISimpleLandData {
    fieldType: string;
    address: string;
    roadAddress: string;
    size: {
        pyong: string;
        square: string;
    };
    land: any;
    building: any;
    pnu?: string;
    lat?: number;
    lng?: number;
}
