import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { WebAppBridge } from "./lib/WebAppBridge";
(window as any)["webapp"] = WebAppBridge;
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 100000,
            retry: 1,
            onError: (err: any) => {
                console.log("err>>>>>>>>>>>>>>>.", err);
            },
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);
