import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DeungiAlarmHeader from "@src/components/deungi/DeungiAlarmHeader";
import deungi_alarm_dot_ico from "@src/assets/deungi/deungi_alarm_dot_ico.png";
import DeungiCombo from "@src/components/deungi/DeungiCombo";
import DeungiPopup from "@src/components/deungi/DeungiPopup";
import ico_deungi_empty from "@src/assets/ico_deungi_empty.png";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import { DEUNGI_CHANGE_STATE, DEUNGI_POPUP_TYPE } from "@src/components/deungi/common/enum";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import ToastMessage from "@src/components/deungi/ToastMessage";
import { DeungiAlarmResponseDto } from "@src/hooks/deungi/dto/DeungiAlarm.dto";
import { useDeungiChangeAlarmList } from "@src/hooks/deungi/useDeungiAlarm";
import dayjs from "dayjs";

const DeungiAlarmSubmitList = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoad, setIsLoad] = useState(false);
    const [message, setMessage] = useState("");
    const [alarmTotalCount, setAlarmTotalCount] = useState(0);
    const [alarmList, setAlarmList] = useState([] as DeungiAlarmResponseDto[]);
    const [popupToggle, setPopupToggle] = useState(false);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.ALARM_LIST);
    const [popupData, setPopupData] = useState({} as any);
    const [menuTypeToggle, setMenuTypeToggle] = useState(false);
    const [menuType, setMenuType] = useState("");

    const getAlarmList = async () => {
        const res = await useDeungiChangeAlarmList();

        if (res.data && res.data.list.length > 0) {
            setAlarmList(() => {
                return res.data.list.filter((el: DeungiAlarmResponseDto) => el.deleted_at === null);
            });
            setAlarmTotalCount(res.data.totalCnt);
        } else {
            setAlarmList([]);
            setAlarmTotalCount(0);
        }

        setIsLoad(true);
    };

    const onClickOptionHandler = (el: DeungiAlarmResponseDto) => {
        setPopupData(el);
        setMenuTypeToggle(true);
    };

    useEffect(() => {
        if (menuType !== "") {
            switch (menuType) {
                case "알림전송내역":
                    setPopupType(DEUNGI_POPUP_TYPE.ALARM_LIST);
                    setPopupToggle(true);
                    break;
                case "알림종료":
                    setPopupType(DEUNGI_POPUP_TYPE.ALARM_EXPIRED);
                    setPopupToggle(true);
                    break;
                case "수정":
                    navigate(`/deungi/alarm/detail`, { state: { deungiInfo: popupData, isModify: true } });
                    break;
            }
            setMenuType("");
        }
    }, [menuType]);

    useEffect(() => {
        getAlarmList();
    }, []);

    useEffect(() => {
        if (location.state) {
            if (location.state?.submitComplete) {
                setMessage("신청이 완료되었습니다");
            } else if (location.state?.expiredComplete) {
                setMessage("알림이 종료되었습니다");
                setTimeout(() => getAlarmList(), 100);
            } else if (location.state?.expiredFail) {
                setMessage("알림을 종료할 수 없습니다\n 고객센터로 문의해주세요");
            } else if (location.state?.modifyComplete) {
                setMessage("수정이 완료되었습니다");
            }
            navigate(location.pathname, { replace: true, state: null });
        }
    }, [location]);

    return (
        <Container>
            <DeungiAlarmHeader title="신청내역" path="/deungi/alarm?option=true" />
            {!isLoad ? (
                <div className={"loading"}>
                    <Lottie animationData={lottie_loading} />
                </div>
            ) : (
                <AlarmBox>
                    {alarmList.length === 0 ? (
                        <div className={"emptyBox"}>
                            <img src={ico_deungi_empty} />
                            <h3>등기 변동 알림</h3>
                            <p>신청 내역이 없어요</p>
                        </div>
                    ) : (
                        <>
                            {alarmList.map((el, idx) => {
                                return (
                                    <div key={el._id} className={"alarmItem"} style={{ animationDelay: `${0.2 * idx}s` }}>
                                        <div className={"top"}>
                                            {[DEUNGI_CHANGE_STATE.EXPIRED].includes(el.change_state) ||
                                            dayjs(el.expired_at) <= dayjs() ? (
                                                <label className={"darkGray"}>알림종료</label>
                                            ) : [DEUNGI_CHANGE_STATE.FAIL].includes(el.change_state) ? (
                                                <>
                                                    <label className={"red"}>확인불가</label>

                                                    <p onClick={() => onClickOptionHandler(el)}>
                                                        <img src={deungi_alarm_dot_ico} />
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <label className={"blue"}>확인중</label>
                                                    <p onClick={() => onClickOptionHandler(el)}>
                                                        <img src={deungi_alarm_dot_ico} />
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <div className={"contents"}>
                                            {el.address}
                                            <small>
                                                {el?.item_type} {el?.pin?.replace(/(\d{4})(\d{4})(\d{6})/gi, "$1-$2-$3")}
                                            </small>
                                            {[DEUNGI_CHANGE_STATE.EXPIRED].includes(el.change_state) ||
                                            dayjs(el.expired_at) < dayjs() ? (
                                                <></>
                                            ) : [DEUNGI_CHANGE_STATE.INVALID_OWNER].includes(el.change_state) ? (
                                                <label>⚠️ 소유자를 수정해주세요</label>
                                            ) : [DEUNGI_CHANGE_STATE.FAIL].includes(el.change_state) ? (
                                                <>
                                                    <label>⚠️ 등기변동 확인에 문제가 생겼어요</label>
                                                    <span>알림종료 후 재등록하거나 고객센터로 문의주시기 바랍니다</span>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className={"bottom"}>
                                            {[DEUNGI_CHANGE_STATE.WAITING].includes(el.change_state)
                                                ? "확인 전"
                                                : [DEUNGI_CHANGE_STATE.EXPIRED].includes(el.change_state)
                                                ? `${dayjs(el.updated_at).format("YYYY.MM.DD HH:mm:ss")} 종료`
                                                : `${dayjs(el.updated_at).format("YYYY.MM.DD HH:mm:ss")} 확인`}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </AlarmBox>
            )}
            <DeungiCombo
                option={menuType}
                options={["알림전송내역", "수정", "알림종료"]}
                toggle={menuTypeToggle}
                setOption={setMenuType}
                setToggle={setMenuTypeToggle}
            />
            <DeungiPopup type={popupType} data={popupData} toggle={popupToggle} setToggle={setPopupToggle} />
            <ToastMessage message={message} setMessage={setMessage} />
        </Container>
    );
};

const AlarmBox = styled.div`
    position: relative;
    width: 100%;
    height: calc(100dvh - 56px);
    overflow: scroll;
    padding: 14px;
    animation: fadeIn 0.3s forwards;

    .alarmItem {
        position: relative;
        display: flex;
        flex-direction: column;
        background: #f7f8fa;
        border-bottom: 1px solid #8d8d8d;
        margin-bottom: 8px;
        opacity: 0;
        animation: moveUp 0.4s forwards;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > label {
                color: #fff;
                padding: 4px;
                font-size: 13px;
                margin: 8px;

                &.blue {
                    background: #0047ff;
                }
                &.darkGray {
                    background: #282828;
                }
                &.red {
                    background: #ff0000;
                }
            }
            & > p {
                padding: 8px 8px 4px;
                cursor: pointer;

                & > img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .contents {
            padding: 0 8px;
            line-height: 1.6;
            font-size: 14px;

            & > small {
                display: block;
                font-size: 13px;
                color: #505050;
            }
            & > label {
                display: block;
                margin-top: 4px;
                color: #0c002c;
                line-height: 1.6;
            }
            & > span {
                display: block;
                margin-bottom: 2px;
                font-size: 12px;
                color: #ff0000;
            }
        }
        .bottom {
            padding: 8px;
            text-align: right;
            font-size: 12px;
            color: #8d8d8d;
        }
    }
    @keyframes moveUp {
        from {
            transform: translateY(50px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100dvh;

    .emptyBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 13px;
        line-height: 1.4;
        height: calc(100dvh - 210px);
        padding: 14px;
        word-break: keep-all;
        text-align: center;

        & > h3 {
            font-size: 16px;
            margin-bottom: 8px;
        }
        & > img {
            width: 110px;
            margin-bottom: 48px;
        }
    }
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100dvh - 56px);

        & > div {
            width: 78px;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export default DeungiAlarmSubmitList;
