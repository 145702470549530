import { Grid } from "@mui/material";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import React from "react";
import { css } from "@emotion/react";
import ProductCard from "./ProductCard";

interface ISubscriptionSmallCard {
    selectedProduct: IProduct;
    onChangeSelectedProduct: (val: IProduct) => void;
    subsCategory: number;
    selectedProducList?: IProduct[] | any[];
    product: IProduct;
}

const SubscriptionSmallCard = ({
    selectedProduct,
    subsCategory,
    product,
    selectedProducList,
    onChangeSelectedProduct,
}: ISubscriptionSmallCard) => {
    return (
        <div css={productRootBoxStyle}>
            {product && (
                <ProductCard
                    selectedProductList={selectedProducList}
                    key={JSON.stringify(product)}
                    product={product}
                    width={6}
                    mainCSS={productCardBoxSecondStyle}
                    selectedProduct={selectedProduct}
                    onChangeSelectedProduct={onChangeSelectedProduct}
                />
            )}
        </div>
    );
};

export default SubscriptionSmallCard;

const productRootBoxStyle = css`
    padding-top: 14px;
    width: 50%;
`;
const productCardBoxSecondStyle = (active: boolean, isEvent: boolean) => css`
    position: relative;
    border-radius: 14px;
    border: ${active ? "2px solid #0047FF" : "1px solid #dadada"};
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 18px 12px;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    justify-content: center;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    & > .recommend {
        position: absolute;
        top: -2px;
        left: 15px;
        transform: translate(-50%, -50%);
    }
    & > .name {
        color: #0c002c;
        font-size: 16px;
        line-height: 26px;
    }
    & > .priceBox {
        text-align: right;
        & > .regularPrice {
            & > .text {
                color: #8d8d8d;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-decoration: line-through;
                text-decoration-color: #8d8d8d;
            }
            & > .discount {
                margin-left: 8px;
                color: #f00000;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
        & > .salePrice {
            & > .text {
                color: #0047ff;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
            & > .period {
                color: #3c3c3c;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
`;
