import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import { useAiFilterStore } from "@src/store/AiFilterStore";

type ButtonType = {
    buttonText: string;
    id: number;
    isFocus: boolean;
};

type ContentPropsType = {
    title: string;
    button1: ButtonType;
    button2?: ButtonType;
    onSelect: (id: number | undefined, buttonText: string | undefined) => void;
};

const Content = ({ title, button1, button2, onSelect }: ContentPropsType) => {
    const setVisible = useAiFilterStore((state) => state.setVisible);

    return (
        <div css={ContentsWrapper} key={title}>
            <div css={ButtonWrapper}>
                <span>{title}</span>
                <SelectButton
                    isSelected={button1.isFocus}
                    onClick={() => {
                        onSelect(button1.id, `${title} ${button1.buttonText}`);
                        setVisible(false);
                    }}
                >
                    <span>{button1.buttonText}</span>
                </SelectButton>
                {button2 && (
                    <SelectButton
                        isSelected={button2?.isFocus}
                        onClick={() => {
                            onSelect(button2?.id, `${title} ${button2?.buttonText}`);
                            setVisible(false);
                        }}
                        style={{ marginLeft: "8px" }}
                    >
                        <span>{button2?.buttonText}</span>
                    </SelectButton>
                )}
            </div>
        </div>
    );
};

export default Content;

const ContentsWrapper = css`
    display: flex;
    align-items: center;
    padding: 14px 0px;
`;

const ButtonWrapper = css`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    & > span {
        display: flex;
        align-items: center;
        width: 64px;
        margin-right: 8px;
        color: ${Common.colors.navyPrimary};
        ${Common.textStyle.title5_M14}
        font-weight:700;
    }
`;

const SelectButton = styled.button<{ isSelected: boolean | undefined }>`
    height: 46px;
    flex: 1;
    border-radius: 8px;
    background: #fdfafa;
    ${({ isSelected }) =>
        isSelected &&
        css`
            color: #ffffff;
            background: ${Common.colors.aucMarkerColor};
        `}

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
    }
`;
