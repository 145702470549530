import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import DrawingHelper from "./DrawingHelper";

interface Props {
    onClose: () => void;
    showConvenienceBar: boolean;
    onChangeShowConvenienceBar: () => void;
}

const DrawScreen: React.FC<Props> = ({ onClose, showConvenienceBar, onChangeShowConvenienceBar }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const eraseRef = useRef<any>(false);
    const [drawingStart, setDrawingStart] = useState<boolean>(false);
    const [ctx, setCtx] = useState<any>();
    const [drawingScreenList, setDrawingScreenList] = useState<any>([]);
    const [drawingNumber, setDrawingNumber] = useState<number>(0);

    // 터치 그리기
    const canvasTouchEventListener = (evt: any) => {
        const x = evt.changedTouches[0].clientX;
        const y = evt.changedTouches[0].clientY;
        if (evt.type === "touchstart" && !eraseRef.current) {
            setDrawingStart(true);
            ctx.beginPath();
            ctx.moveTo(x, y);
        }
        if (evt.type === "touchmove") {
            if (!eraseRef.current) {
                ctx.lineTo(x, y);
                ctx.stroke();
            } else {
                const width = 24;
                const height = 24;
                ctx.clearRect(x - width / 2, y - height / 2, width, height);
            }
        }
        if (evt.type === "touchend") {
            if (!eraseRef.current) {
                ctx.lineTo(x, y);
                ctx.closePath();
                ctx.save();
                setDrawingStart(false);
            }
            setDrawingScreenList([...drawingScreenList.slice(0, drawingNumber), canvasRef.current?.toDataURL()]);
            setDrawingNumber([...drawingScreenList.slice(0, drawingNumber), canvasRef.current?.toDataURL()].length);
        }
    };

    // 마우스로 그리기(피씨로 하는 경우 대비)
    const canvasMouseEventListener = (evt: any) => {
        const x = evt.nativeEvent.offsetX;
        const y = evt.nativeEvent.offsetY;
        if (evt.type === "mousedown") {
            setDrawingStart(true);
            ctx.beginPath();
            ctx.moveTo(x, y);
        }
        if (evt.type === "mousemove" && drawingStart) {
            if (!eraseRef.current) {
                ctx.lineTo(x, y);
                ctx.stroke();
            } else {
                const width = 24;
                const height = 24;
                ctx.clearRect(x - width / 2, y - height / 2, width, height);
            }
        }
        if (evt.type === "mouseup" || evt.type === "mouseleave") {
            if (!eraseRef.current) {
                ctx.lineTo(x, y);
                ctx.closePath();
                ctx.save();
                setDrawingStart(false);
            }
            setDrawingScreenList([...drawingScreenList.slice(0, drawingNumber), canvasRef.current?.toDataURL()]);
            setDrawingNumber([...drawingScreenList.slice(0, drawingNumber), canvasRef.current?.toDataURL()].length);
        }
    };

    const onChangeDrawingType = (t: string) => {
        ctx.lineWidth = 4;
        ctx.strokeStyle = t;
        t === "none" ? (eraseRef.current = true) : (eraseRef.current = false);
        t === "clear" && onClose();
    };

    const onClickToPreviewImage = () => {
        if (drawingNumber === 1) {
            ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
            setDrawingNumber(0);
            return;
        }
        const previousImage = new Image();
        console.log(drawingNumber, drawingScreenList.length);
        previousImage.src = drawingScreenList[drawingNumber - 2];
        previousImage.onload = () => {
            ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
            ctx.drawImage(
                previousImage,
                0,
                0,
                window.innerWidth,
                window.innerHeight,
                0,
                0,
                window.innerWidth,
                window.innerHeight,
            );
            setDrawingNumber((pre) => pre - 1);
        };
    };

    const onClickToNextImage = () => {
        const nextImage = new Image();
        nextImage.src = drawingScreenList[drawingNumber];
        nextImage.onload = () => {
            ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
            ctx.drawImage(nextImage, 0, 0, window.innerWidth, window.innerHeight, 0, 0, window.innerWidth, window.innerHeight);
            setDrawingNumber((pre) => pre + 1);
        };
    };

    const onClickClear = () => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        setDrawingNumber(0);
        setDrawingScreenList([]);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            const context = canvas.getContext("2d");
            if (context) {
                context.lineCap = "round";
                context.lineJoin = "round";
                context.lineWidth = 4;
                context.strokeStyle = "#FFFFFF";
                setCtx(context);
            }
        }
    }, []);

    return (
        <div css={rootStyle}>
            <DrawingHelper
                onChangeDrawingType={onChangeDrawingType}
                onChangeShowConvenienceBar={onChangeShowConvenienceBar}
                onClickBack={onClickToPreviewImage}
                onClickNext={onClickToNextImage}
                onClickClear={onClickClear}
                currentIndex={drawingNumber}
                imageLength={drawingScreenList.length}
            />
            <canvas
                ref={canvasRef}
                onTouchStart={(evt) => canvasTouchEventListener(evt)}
                onTouchEnd={(evt) => canvasTouchEventListener(evt)}
                onTouchMove={(evt) => canvasTouchEventListener(evt)}
                onMouseDown={(evt) => canvasMouseEventListener(evt)}
                onMouseMove={(evt) => canvasMouseEventListener(evt)}
                onMouseLeave={(evt) => canvasMouseEventListener(evt)}
                onMouseUp={(evt) => canvasMouseEventListener(evt)}
            />
        </div>
    );
};

const rootStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 995;
    width: 100vw;
    height: 100vh;
    background-color: rgba(158, 158, 158, 0.4);
`;

export default DrawScreen;
