import styled from "@emotion/styled";
import { appearBottomSheetBackground } from "@src/components/bottomSheet/BottomSheet";
import { Common } from "@src/styles/Common";
import React from "react";

type AiResetConfirmModalPropsType = {
    onClickCancel: () => void;
    onClickConfirm: () => void;
    text: string;
};

const AiResetConfirmModal = ({ onClickCancel, onClickConfirm, text }: AiResetConfirmModalPropsType) => {
    return (
        <Container>
            <Confirm>
                <Content>
                    <div className="text-wrapper">
                        <span>{text}</span>
                    </div>
                    <ButtonArea>
                        <button className="cancel" onClick={onClickCancel}>
                            취소
                        </button>
                        <button className="confirm" onClick={onClickConfirm}>
                            확인
                        </button>
                    </ButtonArea>
                </Content>
            </Confirm>
        </Container>
    );
};

export default AiResetConfirmModal;

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    animation: ${appearBottomSheetBackground} 0.2s linear forwards;
`;

const Confirm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 312px;
    border-radius: 14px;
    padding: 14px;
    background: #ffffff;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: center;

    .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;

        span {
            font-size: 16px;
            font-weight: 400;
        }
    }
`;

const ButtonArea = styled.div`
    display: flex;
    justify-content: space-between;

    button {
        flex: 1;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #ebebeb;
        font-size: 18px;
        line-height: 28px;
    }
    button + button {
        margin-left: 8px;
    }

    .confirm {
        background: ${Common.colors.aucMarkerColor};
        color: white;
    }
`;
