import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import React, { useEffect, useState } from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

export const typeList = ["전체", "경매", "공매"];
export const saleMethodList = ["매각"];

const GoStopAuctionTypeFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    return (
        <div css={rootStyle}>
            {!allFilter && (
                <div css={titleBoxStyle(!filterParams.sales_method)}>
                    <p className="titleText">경공매</p>
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                </div>
            )}
            <div css={rootBoxStyle}>
                <div css={typeButtonBoxStyle}>
                    {typeList.map((auctionType, idx) => (
                        <button
                            key={auctionType}
                            css={typeButtonStyle(filterParams.type === idx)}
                            onClick={() => {
                                setFilterParams({
                                    ...filterParams,
                                    type: idx,
                                    sales_method: idx !== 2 ? 0 : filterParams.sales_method,
                                    assets: idx === 2 ? [1] : filterParams.assets,
                                });
                            }}
                        >
                            {auctionType}
                        </button>
                    ))}
                </div>
            </div>
            {allFilter && (
                <div css={expectedAuctionDescStyle}>
                    <p className="text">
                        예정매물 필터는 &nbsp;<span>{`[메뉴판 > 예정매물]`}</span>에서 확인하실 수 있어요
                    </p>
                </div>
            )}
            {filterParams.type === 2 && (
                <>
                    <div>
                        <div css={titleBoxStyle(!filterParams.sales_method)}>
                            <p className="titleText">처분방식</p>
                        </div>
                        <div css={selectButtonBoxStyle}>
                            {saleMethodList.map((sale, idx) => (
                                <button
                                    key={sale}
                                    css={selectButtonStyle(idx === filterParams.sales_method)}
                                    onClick={() => {
                                        setFilterParams({ ...filterParams, sales_method: idx });
                                    }}
                                >
                                    {sale}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div css={titleBoxStyle(filterParams.assets.some((val: any) => !val) || !filterParams.assets.length)}>
                            <p className="titleText">자산구분</p>
                        </div>{" "}
                        <p css={desc}>*공매는 압류재산에 해당하는 물건만 포합됩니다.</p>
                        <div css={lastButtonBoxStyle}>
                            <button css={selectButtonStyle(filterParams?.assets?.length > 0)}>압류재산</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default GoStopAuctionTypeFilter;

const rootStyle = css`
    background-color: #ffffff;
`;

const titleTextStyle = css`
    padding: 4px 14px;
    color: #0c002c;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.4px;
    margin-bottom: 24px;
`;

const rootBoxStyle = css`
    width: 100%;
    height: 56px;
    /* padding: 0 20px; */
    display: flex;
    background-color: #ffffff;
    padding: 0 14px 24px 14px;
    flex-wrap: wrap;
    border-bottom: 1px solid #ebebeb;
`;

const typeButtonBoxStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 6px;
`;

const typeButtonStyle = (active: boolean) => css`
    background-color: ${active ? Common.colors.aucMarkerColor : "#f0f0f0"};
    color: ${active ? "#ffffff" : "#0c002c"};
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    padding: 8px;
    width: 33.3%;
`;

const expectedAuctionDescStyle = css`
    width: 100%;
    padding: 14px;
    .text {
        height: 38px;
        border-radius: 24px;
        background-color: ${Common.colors.blue01};
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${Common.colors.normalTextColor};
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
    }
    span {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
    }
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : "#ffffff"};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #f0f0f0;
    background-color: ${active ? Common.colors.aucMarkerColor : "#f0f0f0"};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 0 14px 24px 14px;
    flex-wrap: wrap;
    border-bottom: 1px solid #ebebeb;
`;

const lastButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 0 14px 14px 14px;
    flex-wrap: wrap;
`;

const desc = css`
    color: #8d8d8d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 14px;
    margin-bottom: 14px;
`;
