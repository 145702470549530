import styled from "@emotion/styled";
import React from "react";
import { Common } from "@src/styles/Common";

type StoreGoStopConfirmModalPropsType = {
    onClickCancel: () => void;
    onClickConfirm: () => void;
    title?: string;
    desc?: string;
};

const StoreGoStopConfirmModal = ({ onClickCancel, onClickConfirm, title, desc }: StoreGoStopConfirmModalPropsType) => {
    return (
        <Container>
            <Confirm>
                <Content>
                    <div className="text-wrapper">
                        <b>{title}</b>
                        <span>{desc}</span>
                    </div>
                    <ButtonArea>
                        <button className="cancel" onClick={onClickCancel}>
                            닫기
                        </button>
                        <button className="confirm" onClick={onClickConfirm}>
                            시작하기
                        </button>
                    </ButtonArea>
                </Content>
            </Confirm>
        </Container>
    );
};

export default StoreGoStopConfirmModal;

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
`;

const Confirm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 312px;
    border-radius: 14px;
    padding: 14px;
    gap: 10px;
    background: #ffffff;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: center;

    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        b {
            width: 200px;
            color: #0c002c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            white-space: pre-wrap;
        }
        span {
            color: #505050;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 14px;
        }
    }
    .date-wrapper {
        height: fit-content;
        gap: 10px;
        border-radius: 4px;
        background-color: #f0f0f0;
        padding: 14px 8px;
        margin-top: 12px;
        p {
            color: #0c002c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }
    .info-wrapper {
        color: #f00;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }
`;

const ButtonArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    button {
        flex: 1;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #ebebeb;
        font-size: 18px;
        line-height: 28px;
    }
    button + button {
        margin-left: 8px;
    }

    .confirm {
        background: ${Common.colors.aucMarkerColor};
        color: white;
    }
`;
