import React from "react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import sadJson from "@src/assets/unSubscription/sad.json";
import { transferToNative } from "@src/lib/WebAppBridge";
import { ISubscribeRefundAmount, IUserStateLandSubsInfo } from "@src/hooks/subscribe/useSubscribe";

interface Props {
    subsRefundAmount?: ISubscribeRefundAmount;
    checkUsedDay: boolean;
    showModal: () => void;
    onSubmitUseStateLand: () => void;
    stateLandSubs?: IUserStateLandSubsInfo;
    firstPaidAt?: string;
}

const UnRegularlySubsctibe: React.FC<Props> = ({
    subsRefundAmount,
    checkUsedDay,
    showModal,
    onSubmitUseStateLand,
    stateLandSubs,
    firstPaidAt,
}) => {
    const navigate = useNavigate();
    return (
        <div css={rootBoxStyle}>
            <div css={imageBoxStyle}>
                <Lottie className="lottie" animationData={sadJson} />
            </div>
            <div css={contentRootBoxStyle}>
                <div css={contentBoxStyle}>
                    <p className="content">
                        지금 해지하시면
                        <br />
                        {subsRefundAmount?.penaltyType === 0 && (
                            <>
                                <br />
                                할인 혜택이 소멸되어
                                <br />
                            </>
                        )}
                        {subsRefundAmount?.penaltyType === 1 && (
                            <>
                                할인 혜택이 소멸되어
                                <br />
                                <br />
                                <span css={penaltyTextStyle}>결제액의 30% 위약금</span>
                                과&nbsp;
                            </>
                        )}
                        {subsRefundAmount?.penaltyType === 2 && (
                            <>
                                할인 혜택이 소멸되어
                                <br />
                                <br />
                                <span css={penaltyTextStyle}>정가의 10% 위약금</span>
                                과&nbsp;
                            </>
                        )}
                        {subsRefundAmount?.penaltyType === 3 && (
                            <>
                                할인 혜택이 소멸되어
                                <br />
                                <br />
                                <span css={penaltyTextStyle}>정가의 30% 위약금</span>
                                과&nbsp;
                            </>
                        )}
                        {subsRefundAmount?.penaltyType === 4 && (
                            <>
                                할인 혜택이 소멸되어
                                <br />
                                <br />
                                <span css={penaltyTextStyle}>정가의 20% 위약금</span>
                                과&nbsp;
                            </>
                        )}
                        {subsRefundAmount?.penaltyType === undefined && (
                            <>
                                할인 혜택이 소멸되어
                                <br />
                                <span css={penaltyTextStyle}>위약금</span>
                                과&nbsp;
                            </>
                        )}
                        사용금액이
                        {subsRefundAmount?.penaltyType !== 0 ? <br /> : " "}
                        공제되고 환불돼요
                    </p>
                    <p css={strongTextStyle}>그래도 해지하시겠습니까?</p>
                    <p css={descTextStyle}>공제액은 구독 구매 시 명시된 이용약관 속 환불정책에 따라 계산됩니다.</p>
                    <button css={containButtonStyle} onClick={() => transferToNative(false, "closeUnsubscribeView")}>
                        유지하기
                    </button>
                    <p
                        css={unSubsTextButtonStyle}
                        onClick={() => {
                            if (checkUsedDay) {
                                showModal();
                                return;
                            }
                            const checkSubsStateLandInfo = stateLandSubs?.list?.filter((val) => val.isSubs);
                            if (checkSubsStateLandInfo?.[0]?.firstUsedAt && new Date(firstPaidAt ?? 0) < new Date("2024-01-10")) {
                                onSubmitUseStateLand();
                                return;
                            }
                            navigate("refund", { state: { status: true, checkUsedDay } });
                        }}
                    >
                        즉시 해지하기
                    </p>
                </div>
            </div>
        </div>
    );
};
export default UnRegularlySubsctibe;

const rootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100dvh - 56px);
    height: calc(100vh - 56px);
`;

const imageBoxStyle = css`
    padding-bottom: 48px;
    .lottie {
        width: 200px;
        height: 200px;
    }
`;

const contentRootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 48px;
    width: 100vw;
    padding: 0 14px;
`;

const contentBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    .content {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
    }
`;

const strongTextStyle = css`
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
    font-size: 16px;
    line-height: 26px;
`;

const titleTextStyle = css`
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    line-height: 26px;
`;

const descTextStyle = css`
    color: #8d8d8d;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 18px;
    border-radius: 8px;
    background-color: #f7f8fa;
    padding: 8px 14px;
`;

const unSubsTextButtonStyle = css`
    color: #8d8d8d;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-position: under;
    cursor: pointer;
    margin-top: 10px;
`;

const containButtonStyle = css`
    margin-top: 10px;
    padding: 14px;
    border-radius: 14px;
    background-color: #2275ee;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
`;

const penaltyTextStyle = css`
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    background-color: #ff0000;
    margin-right: 3px;
    padding: 4px 3px;
`;
