import { css } from "@emotion/react";
import React, { useRef, useState } from "react";
import HandlebarIcon from "@src/assets/ico_handle_bar.svg";
import LineMeasureIcon from "@src/assets/ico_line_measure.svg";
import PenIcon from "@src/assets/ico_pen.svg";
import SizeMeasureIcon from "@src/assets/ico_size_measure.svg";
import LineMeasureSelectedIcon from "@src/assets/ico_line_measure_selected.svg";
import PenSelectedIcon from "@src/assets/ico_pen_selected.svg";
import SizeMeasureSelectedIcon from "@src/assets/ico_size_measure_selected.svg";
import landSizeIcon from "@src/assets/mapConvience/landSizeIcon.svg";
import landSizeSelectedIcon from "@src/assets/mapConvience/landSizeSelectedIcon.svg";
import { Common } from "@src/styles/Common";

interface Props {
    showConvenienceBar: boolean;
    showCanvas: boolean;
    drawingType: number;
    onChangeShowConvenienceBar: () => void;
    isStartDraw: () => void;
    isEndDraw: () => void;
    isHideCard: () => void;
    isStartMeasure: (i: number) => void;
}

const ConvenienceBottomSheet: React.FC<Props> = ({
    showCanvas,
    showConvenienceBar,
    drawingType,
    onChangeShowConvenienceBar,
    isStartDraw,
    isEndDraw,
    isStartMeasure,
    isHideCard,
}) => {
    const rootBoxRef = useRef<HTMLDivElement | null>(null);
    const [touchStart, setTouchStart] = useState<boolean>(false);

    return (
        <div>
            <div css={rootDivStyle} className={showConvenienceBar ? "active" : ""}>
                <div className={showConvenienceBar ? "dim active" : "dim"} aria-hidden onClick={onChangeShowConvenienceBar} />
                <div
                    className={showConvenienceBar ? "sidebar active" : "sidebar"}
                    ref={rootBoxRef}
                    onTouchMove={(evt) => {
                        setTouchStart(true);
                        230 - (window.innerHeight - evt.touches[0].clientY) > 0 &&
                            rootBoxRef.current?.style.setProperty(
                                "transform",
                                `translateY(${230 - (window.innerHeight - evt.touches[0].clientY)}px)`,
                            );
                        rootBoxRef.current?.style.setProperty("transition", "all 0.1s");
                    }}
                    onTouchEnd={(evt) => {
                        if (touchStart) {
                            setTouchStart(false);
                            rootBoxRef.current?.style.removeProperty("transform");
                            rootBoxRef.current?.style.removeProperty("transition");
                            210 - (window.innerHeight - evt.changedTouches[0].clientY) > 0 && onChangeShowConvenienceBar();
                        }
                    }}
                >
                    <ul>
                        <li
                            css={buttonStyle(drawingType === 1 && !showCanvas)}
                            // className={drawingType === 1 && !showCanvas ? "menu active" : "menu"}
                            onClick={() => {
                                isStartMeasure(1);
                                isEndDraw();
                                onChangeShowConvenienceBar();
                                isHideCard();
                            }}
                        >
                            <img
                                width={15}
                                height={15}
                                src={drawingType === 1 && !showCanvas ? LineMeasureSelectedIcon : LineMeasureIcon}
                                alt=""
                            />
                            <p>거리측정</p>
                        </li>
                        <li
                            // className={drawingType === 2 && !showCanvas ? "menu active" : "menu"}
                            css={buttonStyle(drawingType === 2 && !showCanvas)}
                            onClick={() => {
                                isStartMeasure(2);
                                isEndDraw();
                                onChangeShowConvenienceBar();
                                isHideCard();
                            }}
                        >
                            <img
                                width={15}
                                height={15}
                                src={drawingType === 2 && !showCanvas ? SizeMeasureSelectedIcon : SizeMeasureIcon}
                                alt=""
                            />
                            <p>면적측정</p>
                        </li>
                        <li
                            // className={drawingType === 3 && !showCanvas ? "menu active" : "menu"}
                            css={buttonStyle(drawingType === 3 && !showCanvas)}
                            onClick={() => {
                                isStartMeasure(3);
                                isEndDraw();
                                onChangeShowConvenienceBar();
                                isHideCard();
                            }}
                        >
                            <img
                                width={15}
                                height={15}
                                src={drawingType === 3 && !showCanvas ? landSizeSelectedIcon : landSizeIcon}
                                alt=""
                            />
                            <p>면적합산</p>
                        </li>
                        <li
                            css={buttonStyle(showCanvas)}
                            onClick={() => {
                                isStartDraw();
                                onChangeShowConvenienceBar();
                            }}
                        >
                            <img width={15} height={15} src={showCanvas ? PenSelectedIcon : PenIcon} alt="" />
                            <p>펜 · 메모</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const rootDivStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    opacity: 0;
    visibility: hidden;
    * {
        transition: all 0.3s;
    }
    &.active {
        z-index: 996;
        opacity: 1;
        visibility: visible;
    }
    .dim {
        height: 100vh;
    }
    .dim.active {
        background-color: rgba(40, 40, 40, 0.7);
        height: 100vh;
        width: 100vw;
    }
    .sidebar {
        position: fixed;
        bottom: 0;
        height: 284px;
        width: 100vw;
        transform: translateY(230px);
        background: #ffffff url(${HandlebarIcon}) no-repeat 50% 14px;
        border-radius: 10px 10px 0 0;
        padding: 42px 24px 0;
        /* .menu {
            padding: 14px 16px;
            display: flex;
            align-items: center;
            color: #0c002c;
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            :active {
                background: ${Common.colors.bgBlue};
                border-radius: 8px;
            }
            p {
                margin-left: 15px;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
            }
        }
        .menu.active {
            color: #ffffff;
            background-color: ${Common.colors.aucMarkerColor};
            border-radius: 8px;
        } */
    }
    .sidebar.active {
        transform: translateY(0);
    }
`;

const buttonStyle = (active: boolean) => css`
    padding: 14px 16px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: ${active ? "#ffffff" : "#0c002c"};
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    :active {
        background: ${Common.colors.bgBlue};
        border-radius: 8px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
    }
    ${active && {
        backgroundColor: Common.colors.aucMarkerColor,
        borderRadius: "8px",
    }}
`;
export default ConvenienceBottomSheet;
