import React, { useEffect, useState } from "react";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import { isEmpty } from "lodash";
import { add, format } from "date-fns";
import ko from "date-fns/locale/ko";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { IFilterParamsType } from ".";
import { Common } from "@src/styles/Common";

import ico_arrow_left from "@src/assets/ico_arrow_left.svg";
import ico_arrow_left_disable from "@src/assets/ico_arrow_left_disable.svg";
import ico_arrow_right_disable from "@src/assets/ico_arrow_right_disable.svg";
import ico_arrow_right_green from "@src/assets/ico_arrow_right_green.svg";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";

interface Props {
    filterParams: IFilterParamsType;
    allFilter?: boolean;
    setFilterParams: (filterState: IFilterParamsType) => void;
    onClickDone?: () => void;
}

const DueDateFilter: React.FC<Props> = ({ filterParams, allFilter = true, setFilterParams, onClickDone }) => {
    const [monthState, setMonthState] = useState(format(new Date(), "yyyy년 MM월"));
    const [state, setState] = useState<Range[]>([
        {
            startDate: filterParams.due_date[0] ? new Date(filterParams.due_date[0]) : undefined,
            endDate: filterParams.due_date[1] ? new Date(filterParams.due_date[1]) : undefined,
            key: "selection",
        },
    ]);

    const minDate = new Date("2020-01-01");
    const maxDate = add(new Date(), {
        years: 1,
    });

    const handleChangeDateRangeMonthState = (month: Date) => {
        const newMonth = new Date(month);
        const result = format(newMonth, "yyyy년 MM월");
        setMonthState(result);
    };

    const handleResetDate = () => {
        setState([
            {
                startDate: undefined,
                endDate: undefined,
                key: "selection",
            },
        ]);
    };

    const handleChangeDateRange = (item: RangeKeyDict) => {
        if (item.selection.startDate === undefined && item.selection.endDate !== undefined) {
            item.selection.startDate = item.selection.endDate;
            setState([item.selection]);
        } else {
            setState([item.selection]);
        }
        changeDate(item.selection);
    };

    const changeDate = (item: any) => {
        if (state && !isEmpty(state)) {
            const startDate = item.startDate && format(item.startDate, "yyyy-MM-dd");
            const endDate = item.endDate && format(item.endDate, "yyyy-MM-dd");
            const newDate = [startDate, endDate];
            setFilterParams({ ...filterParams, due_date: newDate });
            const result = format(item.startDate, "yyyy년 MM월");
            setMonthState(result);
        }
    };

    useEffect(() => {
        if (!filterParams.due_date?.[0]) {
            handleResetDate();
        }
    }, [filterParams.due_date]);

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle}>
                <p className="titleText">매각기일</p>
                {!allFilter && (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <CustomDateRange
                monthState={monthState}
                editableDateInputs={true}
                onShownDateChange={handleChangeDateRangeMonthState}
                onChange={handleChangeDateRange}
                moveRangeOnFirstSelection={false}
                ranges={state}
                locale={ko}
                minDate={minDate}
                maxDate={maxDate}
            />
        </div>
    );
};

export default DueDateFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const CustomDateRange = styled<any>(DateRange)`
    width: 100%;

    .rdrMonth {
        width: 100%;
    }

    .rdrDayHovered {
        border: none !important;
    }

    .rdrDayEndPreview.rdrDayStartPreview {
        display: none !important;
    }

    .rdrDayStartPreview {
        display: none !important;
    }

    .rdrDayEndPreview {
        display: none !important;
    }

    .rdrDayInPreview {
        display: none !important;
    }

    .rdrDayDisabled {
        opacity: 0.3;
    }
    .rdrStartEdge {
        background: ${Common.colors.aucMarkerColor};

        height: 28px;
        width: 28px;
        z-index: 1;
        border-radius: 24px;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            width: 100%;

            ${({ ranges }) => {
                if (ranges[0].endDate !== ranges[0].startDate) {
                    return css`
                        background-image: linear-gradient(to right, white 50%, #dff0ff 50%);
                    `;
                }
                if (ranges[0].endDate === ranges[0].startDate) {
                    return css`
                        background-image: none;
                        background: none;
                    `;
                }
            }}

            span {
                z-index: 2;
                font-weight: 400;
                color: blue;
            }
        }

        .rdrDayActive {
            display: none;
        }
    }

    .rdrStartEdge.rdrEndEdge {
        background: ${Common.colors.aucMarkerColor};
        height: 28px;
        width: 28px;
        z-index: 1;
        border-radius: 24px;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            span {
                z-index: 2;
                font-weight: 400;
                color: white;
            }
        }

        .rdrDayActive {
            display: none;
        }
    }

    .rdrEndEdge {
        background: ${Common.colors.aucMarkerColor};
        height: 28px;
        width: 28px;
        border-radius: 24px;
        z-index: 1;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            background: #dff0ff;

            ${({ ranges }) => {
                if (ranges[0].endDate !== ranges[0].startDate) {
                    return css`
                        background-image: linear-gradient(to right, #dff0ff 50%, white 50%);
                    `;
                }

                if (ranges[0].endDate === ranges[0].startDate) {
                    return css`
                        background: none;
                        background-image: none;
                    `;
                }
            }}

            span {
                z-index: 2;
                color: white;
            }
        }
    }

    .rdrDayNumber {
        span {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
            color: black;
            z-index: 1;
        }
    }

    .rdrInRange {
        ${({ ranges }) => {
            if (ranges[0].endDate === undefined && ranges[0].startDate === undefined) {
                return css`
                    background-color: white !important;
                `;
            }
        }};
    }

    .rdrInRange:first-of-type {
        background-color: #dff0ff;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after {
        background: none;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after {
        background: none;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrDayNumber span:after {
        background: none;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
        color: black;
    }

    .rdrDateDisplayWrapper {
        display: none;
    }

    .rdrMonthAndYearPickers {
        flex: none;
        .rdrMonthPicker {
            display: none;
        }

        .rdrYearPicker {
            display: none;
        }
    }

    .rdrMonthAndYearPickers::before {
        content: ${({ monthState }) => `"${monthState}"`};
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }

    .rdrMonthAndYearWrapper {
        justify-content: center;

        .rdrNextPrevButton.rdrPprevButton {
            ${({ monthState }) => {
                const selectedMonth = Number(monthState.split(" ")?.[1]?.slice(0, -1) ?? "");
                const selectedYear = Number(monthState.split(" ")?.[0]?.slice(0, -1) ?? "");
                const limitMonth = 1;
                const limitYear = 2020;
                if (selectedMonth === limitMonth && selectedYear === limitYear) {
                    return css`
                        background: url(${ico_arrow_left_disable});
                    `;
                }
                return css`
                    background: url(${ico_arrow_left});
                `;
            }};
            background-repeat: no-repeat;
            width: 17px;
            height: 20px;
            margin-right: 20px;
            i {
                display: none;
            }
        }

        .rdrNextPrevButton.rdrNextButton {
            ${({ monthState }) => {
                const selectedMonth =
                    add(new Date(), {
                        years: 1,
                    }).getMonth() + 1;
                const selectedYear = add(new Date(), {
                    years: 1,
                }).getFullYear();
                const currentMonth = Number(monthState.split(" ")?.[1]?.slice(0, -1) ?? "");
                const currentYear = Number(monthState.split(" ")?.[0]?.slice(0, -1) ?? "");
                if (currentMonth === selectedMonth && selectedYear === currentYear) {
                    return css`
                        background: url(${ico_arrow_right_disable});
                    `;
                }
                add(new Date(), {
                    years: 1,
                });
                return css`
                    background: url(${ico_arrow_right_green});
                `;
            }};
            background-repeat: no-repeat;
            width: 17px;
            height: 20px;
            margin-left: 20px;
            i {
                display: none;
            }
        }
    }

    .rdrDayPassive {
        opacity: 0.3;
    }

    .rdrDay {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rdrEndEdge {
        position: initial;
    }

    .rdrStartEdge {
        position: initial;
    }

    .rdrStartEdge.rdrEndEdge + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }

    .rdrDayStartPreview + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }

    .rdrDayEndPreview + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }
`;
