import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction } from "react";

type PriceInputPropsType = {
    input: string;
    setInput: (value: string) => void;
    onKeyUp: (e: any) => void;
    handleSubmit: (input: string) => void;
    inputRef?: any;
    setSubInput?: Dispatch<SetStateAction<number>>;
};

const PriceInput = ({ input, setInput, onKeyUp, handleSubmit, inputRef, setSubInput }: PriceInputPropsType) => {
    const onChange = (e: any) => {
        const { value } = e.target;
        const isNumber = /^[0-9]+$/;

        const removeCommaPrice = value.replaceAll(",", "");
        if (setSubInput) {
            if (removeCommaPrice < 10000000) setSubInput(removeCommaPrice);
        }

        if (removeCommaPrice !== "") {
            if (!isNumber.test(removeCommaPrice)) return;
            if (Number(removeCommaPrice) > 10000000) {
                return;
            }
        }
        if (removeCommaPrice === "") {
            setInput(removeCommaPrice);
        } else {
            console.log(23);
            const commaPrice = Number(removeCommaPrice).toLocaleString();
            setInput(commaPrice);
        }
    };

    const outFocus = () => {
        handleSubmit(input);
    };

    return (
        <PriceInputWrapper value={Boolean(input)}>
            <input
                onChange={onChange}
                value={input}
                placeholder="Ex) 2000"
                onKeyUp={onKeyUp}
                type="tel"
                onBlur={outFocus}
                ref={inputRef}
            />
            <span>만원</span>
        </PriceInputWrapper>
    );
};

export default PriceInput;

const PriceInputWrapper = styled.div<{ value: boolean }>`
    position: relative;
    margin-top: 40px;
    input {
        width: 100%;
        background: ${(props) => (props.value ? "#FDFAFA" : "#FFFFFF")};
        padding: 14px 60px 14px 14px;
        border: ${(props) => (props.value ? "1px solid #0C002C" : "1px solid #B1AEB8")};
        border-radius: 14px;
        color: ${Common.colors.normalTextColor};
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: right;
    }

    input:focus {
        outline: none;
    }

    span {
        position: absolute;
        right: 14px;
        font-weight: 600;
        top: calc(50% - 8px);
        font-size: 18px;
    }
`;
