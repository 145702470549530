import React, { useEffect, useMemo, useState } from "react";
import Table from "./Table";
import { test2Columns } from "./test2Columns";
import { testColumns } from "./testColumns";

const TestPage = () => {
    // const columnData = testColumns();
    const columnData = test2Columns();

    const columns = useMemo(() => columnData, []);

    // const dataList = useMemo(()=>[
    //     {
    //         'day':'2021.01',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.02',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.03',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.04',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.05',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.06',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.07',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.08',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.09',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.10',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.11',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.12',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.13',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.14',
    //         'price':'23,370,000원'
    //     },
    //     {
    //         'day':'2021.15',
    //         'price':'23,370,000원'
    //     },
    // ],[])

    const dataList = useMemo(
        () => [
            {
                a: "주1",
                b: "헬리오시티101동",
                c: "공동주택(아파트)",
            },
            {
                a: "주2",
                b: "헬리오시티102동",
                c: "공동주택(아파트)",
            },
            {
                a: "주3",
                b: "헬리오시티103동",
                c: "공동주택(아파트)",
            },
            {
                a: "주4",
                b: "헬리오시티104동",
                c: "공동주택(아파트)",
            },
            {
                a: "주5",
                b: "헬리오시티105동",
                c: "공동주택(아파트)",
            },
            {
                a: "주6",
                b: "헬리오시티106동",
                c: "공동주택(아파트)",
            },
            {
                a: "주7",
                b: "헬리오시티107동",
                c: "공동주택(아파트)",
            },
            {
                a: "주8",
                b: "헬리오시티108동",
                c: "공동주택(아파트)",
            },
            {
                a: "주9",
                b: "헬리오시티109동",
                c: "공동주택(아파트)",
            },
            {
                a: "주10",
                b: "헬리오시티110동",
                c: "공동주택(아파트)",
            },
            {
                a: "주11",
                b: "헬리오시티111동",
                c: "공동주택(아파트)",
            },
        ],
        [],
    );

    const [data, setData] = useState<any>([]);

    useEffect(() => {
        setData(dataList.slice(0, 5));
    }, []);

    return (
        <>
            <Table color="gray" columns={columns} data={data} dataList={dataList} setData={setData} />
        </>
    );
};

export default TestPage;
