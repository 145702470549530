import { css } from "@emotion/react";
import Title from "@src/components/title/Title";
import { Common } from "@src/styles/Common";
import React, { useEffect, useRef, useState } from "react";
import ico_arrow_down_white from "@src/assets/ico_arrow_down_white.png";
import ConvertPyeongButton from "@src/components/button/ConvertPyeongButton";
import Table from "@src/components/table/Table";
import {
    normalHousingHeaderInBuildingDetail,
    singleTabHeaderInBuildingDetail,
    totalTabHeaderInBuildingDetail,
} from "./getColums";
import useGetBuildingPrice from "@src/hooks/map/useGetBuildingPrice";
import LineChart from "@src/components/chart/LineChart";

interface BuildingDetailProps {
    data: any;
    typeList: { type: "total" | "single"; gubun: string; value: string; index: any }[];
    pnu: string;
    checked: boolean;
    onBuildingRegisterHandler: () => void;
}
function BuildingDetail({ data, typeList, pnu, checked, onBuildingRegisterHandler }: BuildingDetailProps) {
    const INVALID = "-";

    const [apiData, setApiData] = useState<any>({ total: [], single: [] });
    const [currentPage, setCurrentPage] = useState<{
        type: "total" | "single";
        index: number | null;
        gubun: string;
        value: string;
    }>({
        type: "total",
        index: null,
        gubun: "",
        value: "",
    });
    const [pageChecked, setPageChecked] = useState(false);
    useEffect(() => {
        setPageChecked(checked);
    }, [checked]);
    useEffect(() => {
        setCurrentPage({ ...typeList[0] });
        setApiData(data);
    }, [data, typeList]);

    const dateConvert = (date: any) => {
        if (typeof date === "string") {
            if (date !== " ") return date.replace(/(\d{4})(\d{2})(\d{2})/g, "$1.$2.$3");
        }
        return INVALID;
    };
    const stringValidChecker = (input: any): string => {
        if (typeof input === "string") {
            if (input !== " ") return input;
        }
        return INVALID;
    };

    const TotalTabTableHeader = totalTabHeaderInBuildingDetail();
    const SingleTabTableHeader = singleTabHeaderInBuildingDetail();

    const TotalItem = ({ data, convertType }: any) => {
        if (!data) return null;
        const { base, building, puri } = data;
        const [totalTabTableData, setTotalTabTableData] = useState([]);
        useEffect(() => {
            if (data.building) {
                if (building.length > 5)
                    return setTotalTabTableData(
                        building
                            .slice(0, 5)
                            ?.map((v: any) => (v?.bldNm?.replaceAll("undefined", "") ? { ...v } : { ...v, bldNm: "-" })),
                    );
                if (building.length <= 5 && building.length >= 1)
                    return setTotalTabTableData(
                        building?.map((v: any) => (v?.bldNm?.replaceAll("undefined", "") ? { ...v } : { ...v, bldNm: "-" })),
                    );
            }
        }, [data]);
        const sizeUnit = "";

        return (
            <div css={totalItemWrap}>
                <section className="base">
                    <ul>
                        <li>
                            <span className="name">구분</span>
                            <span className="value">{stringValidChecker(base?.regstrGbCdNm)}건축물</span>
                        </li>
                        <li>
                            <span className="name">건물명</span>
                            <span className="value">{stringValidChecker(base?.bldNm)}</span>
                        </li>
                        <li>
                            <span className="name">주용도</span>
                            <span className="value">{stringValidChecker(base?.mainPurpsCdNm)}</span>
                        </li>
                        <li>
                            <span className="name">기타용도</span>
                            <span className="value">{stringValidChecker(base?.etcPurps)}</span>
                        </li>
                        <li>
                            <span className="name">건폐율</span>
                            <span className="value">{base?.bcRat ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">건축면적</span>
                            <span className="value">
                                {/* {convertType === "meter" ? base?.archArea.square : base?.archArea.pyong} */}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">용적률</span>
                            <span className="value">{base?.vlRat ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name" style={{ whiteSpace: "nowrap" }}>
                                용적률산정연면적
                            </span>
                            <span className="value">
                                {convertType === "meter" ? base?.vlRatEstmTotArea?.square : base?.vlRatEstmTotArea?.pyong}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">대지면적</span>
                            <span className="value">
                                {convertType === "meter" ? base?.platArea?.square : base?.platArea?.pyong}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">연면적</span>
                            <span className="value">
                                {convertType === "meter" ? base?.totArea?.square : base?.totArea?.pyong}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">총 호수/가구수/세대수</span>
                            <span className="value">
                                {base?.hoCnt ?? INVALID}/{base?.fmlyCnt ?? INVALID}/{base?.hhldCnt ?? INVALID}
                            </span>
                        </li>
                        <li>
                            <span className="name">주 건축물</span>
                            <span className="value">{base?.mainBldCnt ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">부속 건축물</span>
                            <span className="value">{base?.atchBldCnt ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">외 필지 수</span>
                            <span className="value">{base?.bylotCnt ?? INVALID}</span>
                        </li>
                        {/* <li>
                            <span className="name">위반건축물 여부</span>
                            <span className="value">위반건축물여부</span>
                        </li> */}
                    </ul>
                </section>
                <section className="building" css={buildingTableWrap}>
                    <Title title={"건축물 현황"} size={"large"} />
                    <Table
                        color={"gray"}
                        columns={TotalTabTableHeader}
                        data={totalTabTableData}
                        dataList={building}
                        setData={setTotalTabTableData}
                    />
                    <div className={"buildingRegisterBtn"} onClick={onBuildingRegisterHandler}>
                        {/* <label>NEW</label> */}
                        <label>Live</label>
                        건축물대장 실시간 열람
                    </div>
                </section>
                <section className="parkingLot">
                    <Title title={"주차장"} size={"large"} />
                    <ul>
                        <li>
                            <span className="name">총 주차대수</span>
                            <span className="value">{base?.totPkngCnt ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">자주식</span>
                            <span className="value">
                                옥내 {base?.indrAutoUtcnt ?? INVALID}/옥외 {base?.oudrAutoUtcnt ?? INVALID}
                            </span>
                        </li>
                        <li>
                            <span className="name">기계식</span>
                            <span className="value">
                                옥내 {base?.indrMechUtcnt ?? INVALID}/옥외 {base?.oudrMechUtcnt ?? INVALID}
                            </span>
                        </li>
                    </ul>
                </section>
                <section className="puri">
                    <Title title={"하수처리시설"} size={"large"} />
                    <ul>
                        <li>
                            <span className="name">형식</span>
                            <span className="value">{puri?.etcMode ?? puri?.modeCdNm}</span>
                        </li>
                        <li>
                            <span className="name">용량</span>
                            <span className="value">
                                {puri?.capaPsper !== "0.0" && !puri?.capaPsper?.includes("undefined") ? puri?.capaPsper : INVALID}
                                {/* 인용/ */}
                                {puri?.capaLube !== "0.0" && !puri?.capaLube?.includes("undefined") ? puri?.capaLube : INVALID}
                                {sizeUnit}
                            </span>
                        </li>
                    </ul>
                </section>
                <section className="dates">
                    <Title title={"일자정보"} size={"large"} />
                    <ul>
                        <li>
                            <span className="name">허가일</span>
                            <span className="value">{dateConvert(base?.pmsDay)}</span>
                        </li>
                        <li>
                            <span className="name">착공일</span>
                            <span className="value">{dateConvert(base?.stcnsDay)}</span>
                        </li>
                        <li>
                            <span className="name">사용승인일</span>
                            <span className="value">{dateConvert(base?.useAprDay)}</span>
                        </li>
                    </ul>
                </section>
            </div>
        );
    };
    const SingleItem = ({ data, convertType }: any) => {
        if (!data) return null;
        const sizeUnit = "";
        const { base, building, parkingLot, puri, dates, elevator, jeonyu } = data;

        const isNormalBuilding = base?.regstrGbCdNm?.includes("일반");
        const normalHousingPriceHeader = normalHousingHeaderInBuildingDetail();
        const [priceTableViewingData, setPriceTableViewingData] = useState([]);
        const [priceTableAllData, setPriceTableAllData] = useState([]);
        const [originPriceChartData, setOriginChartData] = useState<any[]>([]);
        const [priceChartData, setPriceChartData] = useState<any>({ xAxis: [], chartData: [] });
        const pushPriceChartData = () => {
            if (!originPriceChartData?.length)
                setPriceChartData({
                    xAxis: [],
                    chartData: [],
                });
            let xAxis: string[] = [];
            let chartData: string[] = [];
            originPriceChartData.map((item) => {
                xAxis.push(item.stdrYear);
                chartData.push(item.price);
            });

            setPriceChartData({ xAxis: xAxis.reverse(), chartData: chartData.reverse() });
        };
        const [singleTabTableData, setSingleTabTableData] = useState([]);
        useEffect(() => {
            // isNormalBuilding.current = base.regstrGbCdNm.includes("일반");
            const getBuildingPrice = useGetBuildingPrice({ pnu, isNormalBuilding });
            isNormalBuilding &&
                getBuildingPrice.then((res: any) => {
                    setOriginChartData(res);
                    res.length ? setPriceTableAllData(res.reverse()) : [];
                    res.length ? setPriceTableViewingData(res.slice(0, 5)) : [];
                });
            if (building && building.length) {
                if (building.length > 5) return setSingleTabTableData(building.slice(0, 5));
                if (building.length <= 5) return setSingleTabTableData(building);
            }
            return () => {
                setOriginChartData([]);
                setPriceTableAllData([]);
                setPriceTableViewingData([]);
            };
        }, []);

        useEffect(() => {
            originPriceChartData.length && pushPriceChartData();
        }, [originPriceChartData]);

        return (
            <div css={singleItemWrap}>
                <section className="base">
                    <ul>
                        <li>
                            <span className="name">구분</span>
                            <span className="value">{stringValidChecker(base?.regstrGbCdNm)}건축물</span>
                        </li>
                        <li>
                            <span className="name">건물명</span>
                            <span className="value">{stringValidChecker(base?.bldNm)}</span>
                        </li>
                        <li>
                            <span className="name">주용도</span>
                            <span className="value">{stringValidChecker(base?.mainPurpsCdNm)}</span>
                        </li>
                        <li>
                            <span className="name">기타용도</span>
                            <span className="value">{stringValidChecker(base?.etcPurps)}</span>
                        </li>
                        <li>
                            <span className="name">주구조</span>
                            <span className="value">{stringValidChecker(base?.strctCdNm)}</span>
                        </li>
                        <li>
                            <span className="name">지붕구조</span>
                            <span className="value">{stringValidChecker(base?.roofCdNm)}</span>
                        </li>
                        <li>
                            <span className="name">높이</span>
                            <span className="value">{base?.heit ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">지상층/지하층</span>
                            <span className="value">
                                {base?.grndFlrCnt ?? INVALID}F/
                                {base?.ugrndFlrCnt == 0 || base?.ugrndFlrCnt === "-" ? `B0` : `B${base?.ugrndFlrCnt}`}
                            </span>
                        </li>

                        <li>
                            <span className="name">건폐율</span>
                            <span className="value">{base?.bcRat ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">건축면적</span>
                            <span className="value">
                                {convertType === "meter" ? base?.archArea.square ?? INVALID : base?.archArea.pyong ?? INVALID}
                                {sizeUnit}
                            </span>
                        </li>

                        <li>
                            <span className="name">용적률</span>
                            <span className="value">{base?.vlRat ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name" style={{ whiteSpace: "nowrap" }}>
                                용적률산정연면적
                            </span>
                            <span className="value">
                                {convertType === "meter"
                                    ? base?.vlRatEstmTotArea.square ?? INVALID
                                    : base?.vlRatEstmTotArea.pyong ?? INVALID}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">대지면적</span>
                            <span className="value">
                                {convertType === "meter" ? base?.platArea.square ?? INVALID : base?.platArea.pyong ?? INVALID}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">연면적</span>
                            <span className="value">
                                {convertType === "meter" ? base?.totArea.square ?? INVALID : base?.totArea.pyong ?? INVALID}
                                {sizeUnit}
                            </span>
                        </li>
                        <li>
                            <span className="name">총 호수/가구수/세대수</span>
                            <span className="value">
                                {base?.hoCnt ?? INVALID}/{base?.fmlyCnt ?? INVALID}/{base?.hhldCnt ?? INVALID}
                            </span>
                        </li>
                        <li>
                            <span className="name">주 건축물</span>
                            <span className="value">{base?.mainBldCnt ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">부속 건축물</span>
                            <span className="value">{base?.atchBldCnt ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">외 필지 수</span>
                            <span className="value">{base?.bylotCnt ?? INVALID}</span>
                        </li>
                        {/* <li>
                            <span className="name">위반건축물 여부</span>
                            <span className="value">위반건축물여부</span>
                        </li> */}
                    </ul>
                </section>
                <section className="building" css={singleBuildingTableWrap}>
                    <Title title={"건축물 현황"} size={"large"} />
                    {building !== null && (
                        <Table
                            color={"gray"}
                            columns={SingleTabTableHeader}
                            data={singleTabTableData}
                            dataList={building}
                            setData={setSingleTabTableData}
                        />
                    )}
                    <div className={"buildingRegisterBtn"} onClick={onBuildingRegisterHandler}>
                        {/* <label>NEW</label> */}
                        <label>Live</label>
                        건축물대장 실시간 열람
                    </div>
                </section>
                <section className="elevator">
                    <Title title={"승강기 정보"} size={"large"} />

                    <ul>
                        <li>
                            <span className="name">승용</span>
                            <span className="value">{elevator?.rideUseElvtCnt ?? INVALID}</span>
                        </li>
                        <li>
                            <span className="name">비상용</span>
                            <span className="value">{elevator?.emgenUseElvtCnt ?? INVALID}</span>
                        </li>
                    </ul>
                </section>
                <section className="parkingLot">
                    <Title title={"주차장"} size={"large"} />
                    <ul>
                        {/* <li>
                            <span className="name">총 주차대수</span>
                            <span className="value">{parkingLot?.totPkngCnt ?? INVALID}대</span>
                        </li> */}
                        <li>
                            <span className="name">자주식</span>
                            <span className="value">
                                옥내 {parkingLot?.indrAutoUtcnt ?? INVALID}/옥외 {parkingLot?.oudrAutoUtcnt ?? INVALID}
                            </span>
                        </li>
                        <li>
                            <span className="name">기계식</span>
                            <span className="value">
                                옥내 {parkingLot?.indrMechUtcnt ?? INVALID}/옥외 {parkingLot?.oudrMechUtcnt ?? INVALID}
                            </span>
                        </li>
                    </ul>
                </section>
                <section className="puri">
                    <Title title={"하수처리시설"} size={"large"} />
                    <ul>
                        <li>
                            <span className="name">형식</span>
                            <span className="value">
                                {puri?.etcMode?.includes("undefined") ? INVALID : puri?.etcMode ?? INVALID}
                            </span>
                        </li>
                        <li>
                            <span className="name">용량</span>
                            <span className="value">
                                {puri?.capaPsper?.includes("undefined") ? INVALID : puri?.capaPsper ?? INVALID}
                            </span>
                        </li>
                    </ul>
                </section>
                <section className="dates">
                    <Title title={"일자정보"} size={"large"} />

                    <ul>
                        <li>
                            <span className="name">허가일</span>
                            <span className="value">{dateConvert(dates?.pmsDay)}</span>
                        </li>
                        <li>
                            <span className="name">착공일</span>
                            <span className="value">{dateConvert(dates?.stcnsDay)}</span>
                        </li>
                        <li>
                            <span className="name">사용승인일</span>
                            <span className="value">{dateConvert(dates?.useAprDay)}</span>
                        </li>
                    </ul>
                </section>
                {/* <section className="jeonyu">
                    <Title title="전유부" size="large" />
                    {jeonyu === null && <div css={nullBox}>전유부 데이터 준비 중입니다</div>}
                </section> */}
                {!!isNormalBuilding && (
                    <section className="price">
                        <Title title={isNormalBuilding ? "개별주택공시가격" : "공동주택공시가격"} size={"large"} />
                        {isNormalBuilding ? (
                            priceChartData.chartData.length === 0 ? (
                                <div css={[nullBox, { marginTop: 0 }]}>개별주택공시가격 정보가 존재하지 않습니다</div>
                            ) : (
                                <div>
                                    <LineChart
                                        xAxis={priceChartData.xAxis}
                                        chartData={priceChartData.chartData}
                                        isPyeong={false}
                                        isUnitShow={false}
                                    />
                                    <div css={housingPriceWrap}>
                                        <Table
                                            color={"gray"}
                                            columns={normalHousingPriceHeader}
                                            data={priceTableViewingData}
                                            dataList={originPriceChartData}
                                            setData={setPriceTableViewingData}
                                        />
                                    </div>
                                </div>
                            )
                        ) : (
                            <div css={[nullBox, { marginTop: 0 }]}>공동주택공시가격 데이터 준비 중입니다</div>
                        )}
                    </section>
                )}
            </div>
        );
    };
    const [contentsTitle, setContentsTitle] = useState("");
    const [contentsData, setContentsData] = useState<any>();
    const [convertType, setConvertType] = useState<"pyeong" | "meter">("meter");

    useEffect(() => {
        if (currentPage.type === "total") {
            setContentsTitle("총괄표제부");
            setContentsData(data?.total);
        } else {
            setContentsTitle(`표제부 ${currentPage.gubun}`);
            setContentsData(data?.single[currentPage?.index ?? 0]);
        }
    }, [currentPage]);

    return (
        <div css={buildingDetailWrap} id={"building"}>
            <Title title="건축물 대장 선택" size={"small"} />
            <ul css={tabDropdown}>
                <li
                    className="init"
                    onClick={() => {
                        let init = document.querySelector("li.init");
                        init?.classList.toggle("active");
                    }}
                >
                    <span className="gubun">{currentPage.gubun}</span>
                    <span className="value">{currentPage.value?.includes("undefined") ? "-" : currentPage.value ?? "-"}</span>
                    <img src={ico_arrow_down_white} alt="" />
                </li>
                {typeList.map((item: any, i: number) => {
                    const { type, gubun, value, index } = item;
                    return (
                        <li
                            key={i}
                            onClick={() => {
                                setCurrentPage({ type, index, gubun, value });
                                let init = document.querySelector("li.init");
                                init?.classList.toggle("active");
                            }}
                        >
                            <span className="gubun">{gubun}</span>
                            <span className="value">{value.includes("undefined") ? "-" : value ?? "-"}</span>
                        </li>
                    );
                })}
            </ul>
            <div className={"contents"}>
                <div>
                    <div className="titleBoxWithButton">
                        <Title title={contentsTitle} size={"large"} />
                        <ConvertPyeongButton
                            handleConvert={(type) => {
                                setConvertType(type === "meter" ? "pyeong" : "meter");
                            }}
                        />
                    </div>
                    {currentPage.type === "total" ? (
                        <TotalItem data={contentsData} convertType={convertType} />
                    ) : (
                        <SingleItem data={contentsData} convertType={convertType} />
                    )}
                </div>
            </div>
        </div>
    );
}

const buildingDetailWrap = css`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 14px 0;
    .title.large {
        margin: 24px 14px;
    }
    .title.small {
        margin: 0 14px;
    }
    .titleBoxWithButton {
        padding-right: 14px;
    }
`;
const tabDropdown = css`
    width: calc(100% - 28px);
    margin: 14px auto;
    position: relative;
    box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
    border-radius: 4px;

    li.init {
        width: 100%;
        height: 40px;
        background: ${Common.colors.blue};
        border-radius: 4px;
        margin-bottom: 4px;
    }
    li {
        padding: 0 14px;
        display: flex;
        align-items: center;
        column-gap: 4px;
        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${Common.colors.white};
        }
        span.gubun {
            display: block;
            width: 28px;
            text-align: center;
            white-space: nowrap;
        }
        span.value {
            flex: 1;
            text-align: center;
        }
    }
    li:not(.init) {
        display: none;
        padding: 0 14px 14px;
        border-left: 1px solid ${Common.colors.bgMint};
        border-right: 1px solid ${Common.colors.bgMint};
        background-color: ${Common.colors.white};
        span {
            color: ${Common.colors.normalTextColor};
        }
        &:nth-of-type(2) {
            border: 1px solid ${Common.colors.bgMint};
            border-bottom: 1px solid transparent;
            border-radius: 4px 4px 0 0;
            padding-top: 8px;
        }
        &:last-of-type {
            border-bottom: 1px solid ${Common.colors.bgMint};
            border-radius: 0 0 4px 4px;
            padding-bottom: 8px;
        }
    }
    li.init.active ~ * {
        display: flex;
    }
    li.init img {
        margin-top: 2px;
        width: 12px;
        height: 7px;
        transform: rotate(00deg);
    }
    li.init.active {
        box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
    }
    li.init.active img {
        transform: rotate(180deg);
    }
`;
const totalItemWrap = css`
    section {
        border-bottom: 6px solid ${Common.colors.grey02};

        .buildingRegisterBtn {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: auto;
            height: 56px;
            border-radius: 14px;
            background-color: #0047ff;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #fff;
            cursor: pointer;
            margin-top: 10px;
            margin: 0 14px 14px;
            gap: 10px;

            & > label {
                /* position: absolute; */
                color: #fff;
                font-size: 12px;
                font-weight: 700;
                background: #f00;
                line-height: 22px;
                border-radius: 24px;
                padding: 0 4px;
                /* top: -8px; */
                /* left: 8px; */
            }
        }
        ul {
            padding: 0 14px;
            margin-bottom: 14px;
        }
        li {
            border-bottom: 1px solid ${Common.colors.grey03};
            padding: 8px 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;

            span.name {
                text-align: left;
                width: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: ${Common.colors.darkGrey04};
                /* white-space: nowrap; */
            }
            span.value {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: ${Common.colors.normalTextColor};
            }
            span.date {
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: ${Common.colors.darkGrey04};
            }
            span.blue {
                color: ${Common.colors.blue};
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
`;

const singleItemWrap = css`
    ${totalItemWrap}
`;
const nullBox = css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${Common.colors.greyPrimary};
    background-color: ${Common.colors.grey02};
    border-radius: 14px;
    text-align: center;
    padding: 24px 0;
    margin: 14px;
`;

const housingPriceWrap = css`
    table {
        width: 100%;
        thead > tr > th {
            text-align: center;

            &:nth-of-type(1) {
                width: 100px !important;
                & > div {
                    width: 100px !important;
                }
            }
            &:nth-of-type(2) {
                width: calc(100% - 100px) !important;
                & > div {
                    width: 100% !important;
                }
            }
        }

        tbody > tr > td {
            text-align: center;
            div {
                width: 100%;
                height: fit-content;
                word-break: keep-all;
            }
            &:nth-of-type(1) {
                width: 100px;
                & > div {
                    width: 100px !important;
                }
            }
            &:nth-of-type(2) {
                width: calc(100% - 100px);
                & > div {
                    width: 100% !important;
                }
            }
        }
        thead tr th:first-of-type div {
            text-align: left !important;
        }
        tr td:first-of-type div {
            text-align: left !important;
        }
        thead tr th:last-of-type div {
            text-align: right !important;
        }
        tr td:last-of-type div {
            text-align: right !important;
        }
    }
`;

const buildingTableWrap = css`
    table {
        width: 100%;
        thead > tr > th {
            &:nth-of-type(1) {
                width: 32px;
            }
            &:nth-of-type(2) {
                width: 50%;
            }
            &:nth-of-type(3) {
                width: calc(50% - 32px);
            }
        }
        tbody > tr > td {
            text-align: center;
            div {
                width: 100%;
                height: fit-content;
                word-break: break-all;
                -webkit-word-break: break-all;
                -moz-word-break: break-all;
                -o-word-break: break-all;
            }
            &:nth-of-type(1) {
                width: 32px;
            }
            &:nth-of-type(2) {
                width: 50%;
            }
            &:nth-of-type(3) {
                width: calc(50% - 32px);
            }
        }
    }
`;

const singleBuildingTableWrap = css`
    table {
        width: 100%;
        thead > tr > th {
            div {
                width: 100% !important;
            }
            &:nth-of-type(1) {
                width: 44px;
            }
            &:nth-of-type(2) {
                width: 44px;
            }
            &:nth-of-type(3) {
                width: 50%;
            }
        }
        tbody > tr > td {
            text-align: center;
            div {
                width: 100% !important;
                height: fit-content;
                word-break: break-all;
                -webkit-word-break: break-all;
                -moz-word-break: break-all;
                -o-word-break: break-all;
            }
            &:nth-of-type(1) {
                width: 44px;
            }
            &:nth-of-type(2) {
                width: 44px;
            }
            &:nth-of-type(3) {
                width: 50%;
            }
        }
    }
`;
export default BuildingDetail;
