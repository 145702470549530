import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { ISimpleLandData } from "@src/hooks/map/useSimpleLandData";
import MeasureLandCard from "./MeasureLandCard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface Props {
    landDataList: ISimpleLandData[];
    onDelete: (index: number) => void;
    selectedLandSizePolygonPnu: string;
    resetSelectedLandSizePolygonPnu: () => void;
    onChangeMapCenter: (position: naver.maps.LatLng) => void;
}

const MeasureLandCardList: React.FC<Props> = ({
    landDataList,
    onDelete,
    selectedLandSizePolygonPnu,
    resetSelectedLandSizePolygonPnu,
    onChangeMapCenter,
}) => {
    const swiper = useSwiper();
    const swiperRef = useRef<any>();

    useEffect(() => {
        if (landDataList.length) {
            console.log("landDataList", landDataList);
            setTimeout(() => {
                const swiper: any = document.querySelector(".swiper");
                const newSwiper = swiper?.swiper;
                newSwiper.updateSlides();
                swiperRef.current?.swiper?.slideTo(landDataList.length - 1);
            }, 100);
        }
    }, [landDataList]);

    useEffect(() => {
        if (selectedLandSizePolygonPnu) {
            const findIndex = landDataList?.findIndex((val) => val.pnu === selectedLandSizePolygonPnu);
            swiperRef.current?.swiper?.slideTo(findIndex);
            resetSelectedLandSizePolygonPnu();
        }
    }, [selectedLandSizePolygonPnu]);

    return (
        <>
            <div css={rootBoxStyle}>
                <Swiper
                    centeredSlides={true}
                    modules={[Navigation, Pagination]}
                    // loop={true}
                    // pagination={true}
                    rewind={true}
                    observeSlideChildren={true}
                    scrollbar={{ draggable: true }}
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    onSlideChange={(swiper: any) => {
                        const activeIndex = swiper?.activeIndex;
                        const currentData = landDataList?.[activeIndex];
                        if (currentData.lat && currentData.lng) {
                            onChangeMapCenter(new naver.maps.LatLng(currentData.lat, currentData.lng));
                        }
                    }}
                    ref={swiperRef}
                >
                    {landDataList?.map((data, idx) => (
                        <SwiperSlide key={idx.toString()} id={data.pnu}>
                            <MeasureLandCard data={data} index={idx} onDelete={onDelete} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
};

export default MeasureLandCardList;

const rootBoxStyle = css`
    position: fixed;
    z-index: 200;
    width: 100vw;
    bottom: 158px;
    left: 0;
    padding: 8px 0;
    box-sizing: border-box;
    .swiper {
        height: 140px;
        box-sizing: border-box;
        width: 100%;
    }
    .swiper-slide {
        box-sizing: border-box;
        width: 332px;
        height: 140px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-pagination {
        bottom: 1px !important;
    }
`;
