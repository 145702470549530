import { css } from "@emotion/react";
import React from "react";
import ico_remove_grey from "@src/assets/ico_remove_grey.svg";
import { reverse } from "dns/promises";
import { isEmpty } from "lodash";
import EmptyResult from "./EmptyResult";
import ico_empty_caseNumber from "@src/assets/ico_empty_caseNumber.svg";
import { Common } from "@src/styles/Common";

type SearchHistoryPropsType = {
    historyList: string[] | undefined;
    onRemove: (event: any, item: string) => void;
    onClickHistory: (item: string) => void;
    type: string;
};

const SearchHistory = ({ historyList, onRemove, onClickHistory, type }: SearchHistoryPropsType) => {
    return (
        <div css={Container}>
            {isEmpty(historyList) ? (
                <EmptyResult>
                    {type === "location" ? (
                        <>
                            <img src={ico_empty_caseNumber} />
                            <p>소재지 주소 검색으로</p>
                            <span>등기를 자유롭게 열람해 보세요</span>
                        </>
                    ) : (
                        <>
                            {" "}
                            <img src={ico_empty_caseNumber} />
                            <p>사건번호와 물건관리번호 검색으로</p>
                            <span>등기를 자유롭게 열람해 보세요</span>
                        </>
                    )}
                </EmptyResult>
            ) : (
                <>
                    <div css={ContentsWrapper}>
                        <b>최근 검색어</b>
                    </div>

                    <div css={ScrollWRapper}>
                        {historyList?.map((item: string, key) => (
                            <div
                                css={ListWrapper}
                                key={item + key}
                                onClick={() => onClickHistory(item)}
                                className="searchHistoryItem"
                            >
                                <div className="text">{item}</div>
                                <img
                                    onClick={(event) => {
                                        onRemove(event, item);
                                    }}
                                    src={ico_remove_grey}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

const Container = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const ScrollWRapper = css`
    overflow-y: scroll;
    height: calc(100vh - 188px);
`;

const ContentsWrapper = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    padding: 4px 10px;
    cursor: pointer;
`;
const ListWrapper = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    padding: 4px 10px;
    & > .text {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 90%;
        text-overflow: ellipsis;
    }

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    :active {
        background: ${Common.colors.bgBlue};
        width: 100vw;
    }
`;
export default SearchHistory;
