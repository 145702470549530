export const itemForFilterBidCount = [
    {
        title: "전체",
        id: "0",
        isActive: true,
    },
    {
        title: "신건",
        id: "1",
        isActive: false,
    },
    {
        title: "유찰",
        id: "2",
        isActive: false,
    },
];
