import { css } from "@emotion/react";
import useGetInternalTable from "@src/hooks/map/useGetInternalTable";
import { initRealPriceDetailData } from "@src/hooks/map/useGetRealPriceDetail";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import InternalTableButton from "./InternalTableButton";
import InternalTableContents from "./InternalTableContents";

interface InternalTableProps {
    columns: any;
    tryGetData: (params?: any) => any;
    setAllTableList: (data: any) => void;
    viewingData: any[];
    setViewingData: Dispatch<SetStateAction<any[]>>;
    allTableDataList: any[];
    isTabChange: boolean;
}

function InternalTable({
    columns,
    tryGetData,
    setAllTableList,
    viewingData,
    setViewingData,
    allTableDataList,
    isTabChange = false,
}: InternalTableProps) {
    //한번 다 봤었는지 확인해주는 값
    const [isOpen, setIsOpen] = useState<boolean>(false);
    //어떤 종류의 버튼인지 확인해주는 값 (더보기,접기)
    const [isKindOfButton, setIsKindOfButton] = useState<boolean>(true);

    //버튼 눌렀을떄 실행
    const onClickGetData = async () => {
        let lastIndex = allTableDataList.length - 1;
        await tryGetData(allTableDataList[lastIndex].next_it).then((res: any) => {
            if (res?.length < 5 && res.length > 0) {
                setIsKindOfButton(true);
            } else if (res.length === 0) {
                if (allTableDataList.length !== 5) {
                    // alert("더이상 데이터가 없습니다.");
                    setIsKindOfButton(false);
                } else {
                    // alert("더이상 데이터가 없습니다.");
                }
            }
            trySetAllTableData(res);
        });
    };

    const trySetAllTableData = (res: any) => {
        // res?.map((data: any) => {
        //     setAllTableList([...allTableDataList, data]);
        // });
        setAllTableList([...allTableDataList, ...res]);
    };

    //접는 부분
    const tryClose = () => {
        setViewingData(allTableDataList.slice(0, 5));
        setIsKindOfButton(true);
        setIsOpen(true);
    };

    //전체 다시 열기 부분
    const tryReOpen = () => {
        setViewingData(allTableDataList);
        setIsKindOfButton(false);
    };

    //전체 데이터가 바뀌고 난후 보여지는 데이터에 넣기위해 빼서 처리
    useEffect(() => {
        setViewingData(allTableDataList);
    }, [allTableDataList]);

    //처음 실행할때 데이터 추가
    // useEffect(() => {
    //     tryGetData().then((res: any) => {
    //         trySetAllTableData(res);
    //     });
    // }, []);
    useEffect(() => {
        if (isTabChange) {
            setIsOpen(false);
            // setIsKindOfButton(true);
            setIsKindOfButton(true);
        }
    }, [isTabChange]);
    return (
        <>
            <div css={wrapper}>
                <InternalTableContents data={viewingData} columns={columns} />
                <InternalTableButton
                    data={viewingData}
                    trySetData={onClickGetData}
                    isOpen={isOpen}
                    isKindOfButton={isKindOfButton}
                    tryClose={tryClose}
                    tryReOpen={tryReOpen}
                />
            </div>
        </>
    );
}

const wrapper = css`
    width: 100%;
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export default InternalTable;
