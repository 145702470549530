import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React from "react";

interface AiBottomSheetTagItemProps {
    item: string;
}

const AiBottomSheetTagItem = ({ item }: AiBottomSheetTagItemProps) => {
    return (
        <>
            <Wrapper>{item}</Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    /* width: 75px; */
    /* height: 20px; */
    /* border-radius: 4px;
    background-color: ${Common.colors.bgMint}; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${Common.colors.normalTextColor};
    font-size: 13px;
    font-weight: 400;
    /* width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    padding: 0px 2px; 
    
`;

export default AiBottomSheetTagItem;
