import styled from "@emotion/styled";
import React from "react";

const AiResultTotal = ({ result }: { result: string }) => {
    return (
        <Container>
            👉<span>{result}</span>건의 매물이 있어요
        </Container>
    );
};

export default AiResultTotal;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    height: 44px;
    border-radius: 8px;
    padding: 8px;
    background: #fff9e4;
    margin-top: 14px;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: right;

    span {
        color: #0047ff;
        margin-left: 8px;
    }
`;
