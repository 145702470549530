import styled from "@emotion/styled";
import { Button } from "@src/components";
import { Common } from "@src/styles/Common";
import { numberToCommaNumber } from "@src/util/numberToCommaNumber";
import React, { Dispatch, SetStateAction } from "react";
import { ToastType } from "./DeungiTicketCancelList";

interface TicketCancelItemProps {
    item: any;
    setIsModalShow: Dispatch<SetStateAction<boolean>>;
    setRefundId: Dispatch<SetStateAction<any>>;
    setToast: Dispatch<SetStateAction<ToastType>>;
    setIsStoreModal: Dispatch<SetStateAction<boolean>>;
    setIsNotPossibleModalShow: Dispatch<SetStateAction<boolean>>;
    onChangeIsNotPossibleModal?: (val: string) => void;
}

const TicketCancelItem = ({
    item,
    setIsModalShow,
    setRefundId,
    setToast,
    setIsStoreModal,
    setIsNotPossibleModalShow,
    onChangeIsNotPossibleModal,
}: TicketCancelItemProps) => {
    const CancelButton = () => {
        if (item.refundStateToString === "취소하기") {
            return (
                <Button
                    label="취소하기"
                    color="blue"
                    size="small"
                    handleClick={() => {
                        setIsModalShow(true);
                        setRefundId(item);
                    }}
                    styles={{ height: "28px", backgroundColor: "#FFDAD8", color: "#0C002C", lineHeight: 0 }}
                />
            );
        }
        if (item.refundStateToString === "취소불가") {
            return (
                <Button
                    label="취소불가"
                    color="blue"
                    size="small"
                    handleClick={() => {
                        if (item.paidCardName === "App Store") {
                            setIsStoreModal(true);
                        } else {
                            if (onChangeIsNotPossibleModal) {
                                onChangeIsNotPossibleModal(item.startedAt);
                            } else {
                                setIsNotPossibleModalShow(true);
                            }
                        }
                    }}
                    styles={{ height: "28px", backgroundColor: "#DADADA", color: "#0C002C", lineHeight: 0 }}
                />
            );
        }
        if (item.refundStateToString === "취소완료") {
            return (
                <Button
                    label="취소완료"
                    color="blue"
                    size="small"
                    handleClick={() => {
                        setToast({ showToast: true, toastMessage: "취소가 완료된 내역입니다" });
                    }}
                    styles={{ height: "28px", backgroundColor: "#F0F0F0", color: "#8D8D8D", lineHeight: 0 }}
                />
            );
        }
    };

    return (
        <>
            <StyledItemWrap>
                <div>
                    <span className="subsText">{item.startedAt.slice(0, 16)}</span>
                    <>{CancelButton()}</>
                </div>
                <div className="row">
                    <div>{item.productName} 결제</div>
                    <div className="cardText">
                        {item.paidCardName} <span className="subsText">{numberToCommaNumber(item.totalPrice)}원</span>
                    </div>
                </div>
            </StyledItemWrap>
        </>
    );
};

export default TicketCancelItem;

const StyledItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-bottom: 0.6px solid #c9c9c9;
    padding: 14px;
    gap: 12px;
    justify-content: center;
    .subsText {
        color: ${Common.colors.darkGrey04};
        ${Common.textStyle.title6_R14};
    }
    & > .row {
        display: flex;
        align-items: center;
        & > div {
            font-size: 16px;
        }
        & > .cardText {
            color: ${Common.colors.normalTextColor};
            ${Common.textStyle.title6_R14};
        }
    }
    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.title3_R16};

        & > .grayText {
            color: ${Common.colors.darkGrey04};
            ${Common.textStyle.title6_R14};
        }
        & > span {
            color: ${Common.colors.blue};
            ${Common.textStyle.title6_R14};
            text-align: center;
        }
    }
`;
