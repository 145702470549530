import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { BottomToUp } from "../animation/FeadIn";

type ToastMessagePropsType = {
    children: any;
    visible: boolean;
    setToastVisible: (visible: boolean) => void;
    addStyles?: any;
};

const ToastMessage = ({ children, visible, setToastVisible, addStyles }: ToastMessagePropsType) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            visible && setToastVisible(false);
        }, 2000);
        return () => {
            !visible && clearTimeout(timer);
        };
    }, [visible]);
    return (
        <>
            {visible && (
                <Container style={addStyles}>
                    <div className="content">{children}</div>
                </Container>
            )}
        </>
    );
};

export default ToastMessage;

const Container = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    width: calc(100vw - 28px);
    left: 14px;
    margin: 0 auto;
    height: 20px;
    animation: ${BottomToUp} 3.5s forwards;
    top: calc(100vh - 170px);
    z-index: 9999;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 100%;
        border-radius: 18px;
        padding: 8px 14px;
        background: #282828;
        opacity: 70%;
        color: white;
    }
`;
