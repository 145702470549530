import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const fetchData = async () => {
    const response = await axiosInstance.get(`${axiosPath.searchedList}?name=mapUserLastMapType`);
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response?.data?.payload.data || [];
    const value = responseData.length !== 0 ? JSON.parse(responseData[0].value) : undefined;
    return value;
};

const useGetUserLastMapType = async () => {
    return await fetchData();
};

const usePostUserLastMapType = async (params: { type: string }) => {
    const PARAMS = JSON.stringify(params);
    const response = await axiosInstance.post(`${axiosPath.searchedList}`, { name: "mapUserLastMapType", value: PARAMS });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
};

export { useGetUserLastMapType, usePostUserLastMapType };
