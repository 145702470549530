import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
interface IConfirmModal {
    resultMessage: any;
    onCancel?: () => void;
    onConfirm: () => void;
    buttonText?: string;
    disabled?: boolean;
    cancelText?: string;
    titleStyle?: any;
}

function ConfirmModal({
    resultMessage,
    onCancel,
    onConfirm,
    buttonText = "확인",
    disabled = false,
    cancelText,
    titleStyle,
}: IConfirmModal) {
    return (
        <div>
            <Modal
                resultMessage={resultMessage}
                handleClick={onConfirm}
                buttonText={buttonText}
                disabled={disabled}
                onCancel={onCancel}
                cancelText={cancelText}
                titleStyle={titleStyle}
            />
        </div>
    );
}

function Modal({ resultMessage, handleClick, buttonText, disabled, onCancel, cancelText, titleStyle }: any) {
    return (
        <>
            <div css={modalStyle}>
                <div css={contentStyle} style={titleStyle}>
                    {resultMessage}
                </div>
                <div className="buttonBox">
                    {onCancel && (
                        <button onClick={onCancel} className="cancelButton">
                            {cancelText ?? "취소"}
                        </button>
                    )}
                    <button onClick={handleClick} className="confirmButton" disabled={disabled}>
                        {buttonText}
                    </button>
                </div>
            </div>
            <div className="shadow" css={shadowStyle}></div>
        </>
    );
}
const modalStyle = css`
    width: 312px;
    max-width: 90dvw;
    padding: 14px;
    height: max-content;
    background-color: ${Common.colors.white};
    border-radius: 14px;
    position: absolute;
    z-index: 9999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .buttonBox {
        margin-top: 20px;
        width: 100%;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: space-between;
        button {
            flex: 1;
            width: 50%;
            border-radius: 14px;
            padding: 14px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            &.cancelButton {
                background-color: ${Common.colors.grey02};
                color: ${Common.colors.navyPrimary};
            }
            &.confirmButton {
                background-color: ${Common.colors.aucMarkerColor};
                color: ${Common.colors.white};
            }
        }
    }
`;
const contentStyle = css`
    padding: 8px 0;
    text-align: center;
    white-space: pre-wrap;
`;
const shadowStyle = css`
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.5);
`;
export default ConfirmModal;
