import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import ko from "date-fns/locale/ko";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import ico_arrow_left from "@src/assets/ico_arrow_left.svg";
import ico_arrow_left_disable from "@src/assets/ico_arrow_left_disable.svg";
import ico_arrow_right_disable from "@src/assets/ico_arrow_right_disable.svg";
import ico_arrow_right_green from "@src/assets/ico_arrow_right_green.svg";
import { css } from "@emotion/react";
import produce from "immer";
import { isEmpty } from "lodash";
import { add, format } from "date-fns";
import { useFilterStore } from "@src/store/filterStore";

type CustomDateRangePickerPropsType = {
    monthState: any;
    onChangeMonthState: (any: any) => void;
    state: any;
    setState: (any: any[]) => void;
    type?: string;
};

const CustomDateRangePicker = ({ monthState, onChangeMonthState, state, setState, type }: CustomDateRangePickerPropsType) => {
    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const filterState = useFilterStore((state) => state.filterState);
    const lastDate = new Date();

    const changeDate = (item: any) => {
        if (type === "setStore" && state && !isEmpty(state)) {
            const startDate = item.startDate && format(item.startDate, "yyyy-MM-dd");
            const endDate = item.endDate && format(item.endDate, "yyyy-MM-dd");

            const nextState = produce(filterState, (draft) => {
                draft.due_date = [startDate, endDate];
            });

            setStoreFilterState(nextState, true);
        }
    };

    return (
        <Container>
            <CustomDateRange
                monthState={monthState}
                editableDateInputs={true}
                onShownDateChange={onChangeMonthState}
                onChange={(item: { selection: any }) => {
                    if (item.selection.startDate === null && item.selection.endDate !== null) {
                        item.selection.startDate = item.selection.endDate;
                        setState([item.selection]);
                    } else {
                        setState([item.selection]);
                    }
                    changeDate(item.selection);
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
                locale={ko}
                minDate={new Date()}
                maxDate={add(lastDate, {
                    years: 1,
                })}
            />
        </Container>
    );
};

export default CustomDateRangePicker;

const Container = styled.div``;

const CustomDateRange = styled<any>(DateRange)`
    width: 100%;

    .rdrMonth {
        width: 100%;
    }

    .rdrDayHovered {
        border: none !important;
    }

    .rdrDayEndPreview.rdrDayStartPreview {
        display: none !important;
    }

    .rdrDayStartPreview {
        display: none !important;
    }

    .rdrDayEndPreview {
        display: none !important;
    }

    .rdrDayInPreview {
        display: none !important;
    }

    .rdrDayDisabled {
        opacity: 0.3;
    }
    .rdrStartEdge {
        background: ${Common.colors.aucMarkerColor};

        height: 28px;
        width: 28px;
        z-index: 1;
        border-radius: 24px;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            width: 100%;

            ${({ ranges }) => {
                if (ranges[0].endDate !== ranges[0].startDate) {
                    return css`
                        background-image: linear-gradient(to right, white 50%, #dff0ff 50%);
                    `;
                }
                if (ranges[0].endDate === ranges[0].startDate) {
                    return css`
                        background-image: none;
                        background: none;
                    `;
                }
            }}

            span {
                z-index: 2;
                font-weight: 400;
                color: blue;
            }
        }

        .rdrDayActive {
            display: none;
        }
    }

    .rdrStartEdge.rdrEndEdge {
        background: ${Common.colors.aucMarkerColor};
        height: 28px;
        width: 28px;
        z-index: 1;
        border-radius: 24px;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            span {
                z-index: 2;
                font-weight: 400;
                color: white;
            }
        }

        .rdrDayActive {
            display: none;
        }
    }

    .rdrEndEdge {
        background: ${Common.colors.aucMarkerColor};
        height: 28px;
        width: 28px;
        border-radius: 24px;
        z-index: 1;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            background: #dff0ff;

            ${({ ranges }) => {
                if (ranges[0].endDate !== ranges[0].startDate) {
                    return css`
                        background-image: linear-gradient(to right, #dff0ff 50%, white 50%);
                    `;
                }

                if (ranges[0].endDate === ranges[0].startDate) {
                    return css`
                        background: none;
                        background-image: none;
                    `;
                }
            }}

            span {
                z-index: 2;
                color: white;
            }
        }
    }

    .rdrDayNumber {
        span {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
            color: black;
            z-index: 1;
        }
    }

    .rdrInRange {
        ${({ ranges }) => {
            if (ranges[0].endDate === null && ranges[0].startDate === null) {
                return css`
                    background-color: white !important;
                `;
            }
        }};
    }

    .rdrInRange:first-of-type {
        background-color: #dff0ff;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after {
        background: none;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after {
        background: none;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrDayNumber span:after {
        background: none;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
        color: black;
    }

    .rdrDateDisplayWrapper {
        display: none;
    }

    .rdrMonthAndYearPickers {
        flex: none;
        .rdrMonthPicker {
            display: none;
        }

        .rdrYearPicker {
            display: none;
        }
    }

    .rdrMonthAndYearPickers::before {
        content: ${({ monthState }) => `"${monthState}"`};
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }

    .rdrMonthAndYearWrapper {
        justify-content: center;

        .rdrNextPrevButton.rdrPprevButton {
            ${({ monthState }) => {
                const selectedMonth = new Date().getMonth() + 1;
                const selectedYear = new Date().getFullYear();
                const currentMonth = Number(monthState.split(" ")?.[1]?.slice(0, -1) ?? "");
                const currentYear = Number(monthState.split(" ")?.[0]?.slice(0, -1) ?? "");
                if (currentMonth === selectedMonth && selectedYear === currentYear) {
                    return css`
                        background: url(${ico_arrow_left_disable});
                    `;
                }
                return css`
                    background: url(${ico_arrow_left});
                `;
            }};
            background-repeat: no-repeat;
            width: 17px;
            height: 20px;
            margin-right: 20px;
            i {
                display: none;
            }
        }

        .rdrNextPrevButton.rdrNextButton {
            ${({ monthState }) => {
                const selectedMonth =
                    add(new Date(), {
                        years: 1,
                    }).getMonth() + 1;
                const selectedYear = add(new Date(), {
                    years: 1,
                }).getFullYear();
                const currentMonth = Number(monthState.split(" ")?.[1]?.slice(0, -1) ?? "");
                const currentYear = Number(monthState.split(" ")?.[0]?.slice(0, -1) ?? "");
                if (currentMonth === selectedMonth && selectedYear === currentYear) {
                    return css`
                        background: url(${ico_arrow_right_disable});
                    `;
                }
                add(new Date(), {
                    years: 1,
                });
                return css`
                    background: url(${ico_arrow_right_green});
                `;
            }};
            background-repeat: no-repeat;
            width: 17px;
            height: 20px;
            margin-left: 20px;
            i {
                display: none;
            }
        }
    }

    .rdrDayPassive {
        opacity: 0.3;
    }

    .rdrDay {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rdrEndEdge {
        position: initial;
    }

    .rdrStartEdge {
        position: initial;
    }

    .rdrStartEdge.rdrEndEdge + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }

    .rdrDayStartPreview + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }

    .rdrDayEndPreview + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }
`;
