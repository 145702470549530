import React, { useEffect } from "react";
import Router from "./Router";
import "./App.css";
import "./styles/global.css";
import { BrowserRouter } from "react-router-dom";
import { transferToNative } from "./lib/WebAppBridge";

function App() {
    const url = new URL(process.env.PUBLIC_URL || "http://localhost");
    const basePath = url.pathname;

    useEffect(() => {
        if (!window.location.pathname.includes("registerCoupon") && !window.location.pathname.includes("successCoupon")) {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            transferToNative("done", "onLoad");
        }
    }, []);

    return basePath !== "/" ? (
        <BrowserRouter basename={basePath}>
            <Router />
        </BrowserRouter>
    ) : (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
}

export default App;
