import { css } from "@emotion/react";
import closeIcon from "../assets/ico_close.png";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Common } from "@src/styles/Common";
import downImg from "@src/assets/isDown.png";
import upImg from "@src/assets/isUp.png";
import equalImg from "@src/assets/isEqual.png";

interface PopulationStatusPageProps {
    items: any[];
    setIsPopulationStatusCardShow: Dispatch<SetStateAction<boolean>>;
}

const PopulationStatusCard = ({ items, setIsPopulationStatusCardShow }: PopulationStatusPageProps) => {
    const [smallHeight, setSmallHeight] = useState<number>(0);
    const [startY, setStartY] = useState(0);
    const [endY, setEndY] = useState(0);
    const [isCount, setIsCount] = useState<boolean>(false);

    const SLIDER_WIDTH = screen.width;

    const convertStrToPrice = (origin: string | number): string => {
        let originPrice = Number(origin);
        if (isNaN(originPrice)) return "-";
        let result = Math.round(originPrice).toLocaleString("ko-KR");
        return result;
    };

    useEffect(() => {
        if (items?.[0]?.percent) {
            const heightValue = (50 * (80 - Number(items?.[0]?.percent.toString().replaceAll("-", "")))) / 100;
            setSmallHeight(heightValue);
        } else if (Number(items?.[0]?.population) - Number(items?.[1]?.population) === 0) {
            setIsCount(true);
        }
    }, [items?.[0], isCount]);

    return (
        <div css={wrap(SLIDER_WIDTH)}>
            <div
                className="flex"
                onTouchStart={(e) => {
                    setStartY(e.touches[0].clientY);
                }}
                onTouchMove={(e) => {
                    setEndY(e.touches[0].clientY);
                }}
                onTouchEnd={() => {
                    if (startY - endY < -100) {
                        setIsPopulationStatusCardShow(false);
                    }
                }}
            >
                {items?.[0]?.unitList.length !== 0 ? (
                    <div css={populationCard} className={"summCard"}>
                        <div className="Header">
                            <div className="handle" />
                        </div>
                        <div className="Contents">
                            <div className="topSideData">
                                <div className="title">
                                    {items?.[0]?.bjdFullName}의 인구현황
                                    <div>
                                        <div className="cancle">
                                            <img
                                                className="cancleImg"
                                                css={closeIcoImg}
                                                src={closeIcon}
                                                alt="닫기"
                                                onClick={() => {
                                                    setIsPopulationStatusCardShow(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div css={contentWrapper}>
                                <div css={chartWrap}>
                                    <div css={chartUnit}>(단위 : 명)</div>
                                    <div css={chartContents}>
                                        {items?.[0]?.unitList.map((data: any, index: number) => (
                                            <div css={barArea(index, data?.percent, isCount)} key={JSON.stringify(data)}>
                                                <div css={barLine}>
                                                    {data?.percent !== 0 && data?.percent && !!index && (
                                                        <div css={deltaTag}>
                                                            <img src={data?.percent > 0 ? upImg : downImg} alt="인구변화량" />
                                                            {`${Math.abs(items?.[0]?.percent)}%`}
                                                        </div>
                                                    )}
                                                    <span css={chartData(index)}>{convertStrToPrice(data?.population)}</span>
                                                    <div css={chartBar(index, items?.[0]?.delta, smallHeight)}></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div css={chartXLine}>
                                        {items?.[0]?.unitList.map((data: any, index: number) => (
                                            <div key={index} css={chartXLabel(index)}>
                                                {data?.date}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div css={populationNoDataCard}>
                        <div className="Header">
                            <div className="handle" />
                        </div>
                        <div className="Contents">
                            <div className="topSideData">
                                <div className="title">
                                    {items?.[0]?.bjdFullName}의 인구현황
                                    <div className="cancle">
                                        <div className="cancle">
                                            <img
                                                className="cancleImg"
                                                css={closeIcoImg}
                                                src={closeIcon}
                                                alt="닫기"
                                                onClick={() => {
                                                    setIsPopulationStatusCardShow(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div css={contentWrapper}>
                                <div css={noData}>인구수 데이터 분석 중입니다</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const wrap = (SLIDER_WIDTH: any) => css`
    width: ${SLIDER_WIDTH};
    overflow: hidden;
`;

const contentWrapper = css`
    display: flex;
    width: 92vw;
    justify-content: center;
    align-items: center;
`;

const populationCard = css`
    min-width: 100vw;
    width: 100vw;
    height: fit-content;
    min-height: 200px;
    box-sizing: border-box;
    padding: 10px 14px 14px 14px;
    background: ${Common.colors.white};
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 14px 14px 0px 0px;
    z-index: 1000;
    .Header {
        height: 20px;
        margin-top: 10px;
        & > .handle {
            width: 34px;
            height: 4px;
            border-radius: 2px;
            background-color: ${Common.colors.grey04};
            margin: auto;
        }
    }
    .Contents {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 6px;
        padding: 0;
    }
    .topSideData {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;
    }
    .titleContent {
        display: flex;
        gap: 4px;
        align-items: center;
        & > span {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
        }
    }
    .titleText {
        ${Common.textStyle.title2_M16}
        color:${Common.colors.normalTextColor};
        font-weight: 700;
    }
`;

const closeIcoImg = css`
    width: 16px;
    height: 16px;
    box-sizing: border-box;
`;
const chartWrap = css`
    width: 362px;
    height: 190px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    margin-top: 8px;
`;
const chartUnit = css`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
`;

const chartContents = css`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 200px;
    border-left: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;
`;

const barArea = (index: number, isUp: number, isCount: boolean) => css`
    width: 50%;
    height: 100%;
    max-height: 140px;
    display: flex;
    justify-content: center;
    background-color: ${!index ? "#ffffff" : isCount ? " #EEFFF5" : isUp > 0 ? "rgba(255, 218, 216, 0.3)" : "#F6FAFF"};
`;

const barLine = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    justify-content: flex-end;
    gap: 6px;
`;

const chartBar = (index: number, isUp: number, smallHeight: number) => css`
    width: 60px;
    border-radius: 4px 4px 0 0;
    background-color: ${!index ? "#f0f0f0" : isUp === 0 ? "#09AA4B" : isUp > 0 ? "#FF0000" : "#0047FF"};
    height: ${isUp > 0 ? (index ? "55px" : `${smallHeight}px`) : index ? `${smallHeight}px` : "55px"};
    @keyframes fadeInUp {
        0% {
            height: 0px;
        }
    }
    animation: fadeInUp 0.7s ease-in-out;
`;

const chartData = (index: number) => css`
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    color: ${index ? "#0C002C" : "#505050"};
`;

const chartXLine = css`
    width: 100%;
    display: flex;
    height: 24px;
    gap: 10px;
`;

const chartXLabel = (index: number) => css`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${index ? "#0C002C" : "#505050"};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
const populationNoDataCard = css`
    min-width: 100vw;
    width: 100vw;
    height: fit-content;
    min-height: 138px;
    box-sizing: border-box;
    padding: 10px 14px 10px 14px;
    background: ${Common.colors.white};
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 14px 14px 0px 0px;
    z-index: 1000;
    .Header {
        height: 20px;
        margin-top: 10px;
        & > .handle {
            width: 34px;
            height: 4px;
            border-radius: 2px;
            background-color: ${Common.colors.grey04};
            margin: auto;
        }
    }
    .Contents {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 18px;
        padding: 0;
    }
    .topSideData {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;
    }
    .titleContent {
        display: flex;
        gap: 4px;
        align-items: center;
        & > span {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
        }
    }
`;
const deltaTag = css`
    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-10px);
        }
        60% {
            transform: translateY(-5px);
        }
    }
    @keyframes fadeIn {
        0%,
        20%,
        50%,
        80%,
        90% {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    width: 73px;
    height: 32px;
    border-radius: 14px;
    padding: 4px 8px;
    display: flex;
    box-sizing: border-box;
    gap: 4px;
    align-items: center;
    background-color: white;
    box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    justify-content: center;
    position: relative;
    & > img {
        width: 18px;
        height: 18px;
    }
    ::after {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border-radius: 3px;
        border-top: 11px solid white;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translate(-50%, -50%);
    }
    animation-name: fadeIn, bounce;
    animation-delay: 0s, 0.7s;
    animation-duration: 0.7s, 1.7s;
    animation-timing-function: ease-in, ease;
`;
const noData = css`
    width: calc(100% - 28px);
    background-color: #f0f0f0;
    border-radius: 14px;
    padding: 14px;
    box-sizing: border-box;
    height: 56px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
`;
export default PopulationStatusCard;
