import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

export const useGetRecentSearchList = async () => {
    const response = await axiosInstance.get(`${axiosPath.userKeywords}s`, {
        params: {
            froms: "list",
        },
    });
    return response;
};

export const usePushSearchKeyword = async (keyword: string, itemId?: string) => {
    const body = {
        keyword,
        froms: "list",
        searchType: 4,
        itemId,
    };
    const response = await axiosInstance.put(`${axiosPath.userKeywords}`, body);
    return response;
};

export const useDeleteRecentSearch = async (keywordId: number) => {
    const response = await axiosInstance.delete(`${axiosPath.userKeywords}/${keywordId}`);
    return response;
};

export const useDeleteAllRecentSearch = async (searchType: number) => {
    const response = await axiosInstance.delete(`${axiosPath.userKeywords}/all/${searchType}`);
    return response;
};
