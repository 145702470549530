import { NaverMap } from "@src/lib/NaverMap";
import create from "zustand";

type mapStoreType = {
    map: naver.maps.Map | null;
    setMap: (map: naver.maps.Map) => void;
};

export const useMapStore = create<mapStoreType>((set) => ({
    map: null,
    setMap: (map: naver.maps.Map) => {
        set({ map });
    },
    isRoad: false,
}));
