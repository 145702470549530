import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

export const useFindAllFilterHistory = async () => {
    const response = await axiosInstance.get(axiosPath.RecentFilter);
    const resultResponse = response?.data?.payload?.list?.filter((val: any) => val?.value?.type !== undefined);
    return resultResponse;
};

export const useRemoveHistory = async (no: string) => {
    await axiosInstance.delete(`${axiosPath.removeFilterHistory}/${no}`);
};
