import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery } from "react-query";
import { expertInquiryFilterState, InquiriesResponseType } from "./dto/inquiry.dto";

const fetchExpertInquiry = async (params: expertInquiryFilterState) => {
    const response = await axiosInstance
        .get(`${axiosPath.userExpertInfo}/inquiries`, { params })
        .then((res) => {
            if (!res?.status) {
                throw new Error("Problem fetching data");
            }
            const responseData = res?.data ?? [];
            return responseData;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return err;
        });
    return response;
};

export const useGetExpertInquiry = (filters: expertInquiryFilterState, enabled: boolean = false) => {
    return useInfiniteQuery<InquiriesResponseType>(
        ["expertInquiry", filters],
        ({ pageParam = 1 }) => fetchExpertInquiry({ ...filters, page: pageParam }),
        {
            getNextPageParam: (lastPage: any, pages: any) => {
                const newPage = lastPage?.list.length ? pages.length + 1 : undefined;
                return newPage;
            },
            enabled,
        },
    );
};
