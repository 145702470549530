import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React from "react";

type AdEndConfirmModalPropsType = {
    onClickCancel: () => void;
    onClickConfirm: () => void;
    title?: string;
    desc?: string;
    type?: number;
    adCount?: number;
    start_date?: string;
    end_date?: string;
};

const AdEndConfirmModal = ({
    onClickCancel,
    onClickConfirm,
    title,
    desc,
    type,
    adCount,
    start_date,
    end_date,
}: AdEndConfirmModalPropsType) => {
    return (
        <Container>
            <Confirm>
                <Content>
                    {type === 1 && (
                        <>
                            <div className="text-wrapper">
                                <b>{title}</b>
                                <span>{desc}</span>
                            </div>
                            <div className="date-wrapper">
                                <p>광고시작일 {start_date}</p>
                                <p>광고종료일 {end_date}</p>
                            </div>
                        </>
                    )}
                    {type === 2 && (
                        <>
                            <div className="text-wrapper">
                                <b>{title}</b>
                                <span>
                                    광고기간: {start_date} ~ {end_date}
                                </span>
                            </div>
                            <div className="date-wrapper">
                                <p>보유 매물광고권 {adCount}장</p>
                            </div>
                            {adCount === 0 && <div className="info-wrapper ">매물광고권을 충전해주세요.</div>}
                        </>
                    )}

                    <ButtonArea>
                        <button className="cancel" onClick={onClickCancel}>
                            취소
                        </button>
                        <button className="confirm" onClick={onClickConfirm}>
                            {type === 1 ? "광고종료 " : adCount === 0 ? "충전하기" : "광고하기"}
                        </button>
                    </ButtonArea>
                </Content>
            </Confirm>
        </Container>
    );
};

export default AdEndConfirmModal;

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.7);
`;

const Confirm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 312px;
    border-radius: 14px;
    padding: 14px;
    gap: 10px;
    background: #ffffff;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    text-align: center;

    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        b {
            width: 190px;
            color: #0c002c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
        }
        span {
            color: #505050;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 14px;
        }
    }
    .date-wrapper {
        height: fit-content;
        gap: 10px;
        border-radius: 4px;
        background-color: #f0f0f0;
        padding: 14px 8px;
        margin-top: 12px;
        p {
            color: #0c002c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
        }
    }
    .info-wrapper {
        color: #f00;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 8px;
    }
`;

const ButtonArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    button {
        flex: 1;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #ebebeb;
        font-size: 18px;
        line-height: 28px;
    }
    button + button {
        margin-left: 8px;
    }

    .confirm {
        background: ${Common.colors.aucMarkerColor};
        color: white;
    }
`;
