import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import GoStopAuctionTypeFilter from "./GoStopAuctionTypeFilter";
import GoStopLandUseFilter from "./GoStopLandUseFilter";
import GoStopNewLocationFilter from "./GoStopNewLocationFilter";
import GoStopProfitFilter from "./GoStopProfitFilter";
import GoStopAuctionMinPriceFilter from "./GoStopAuctionMinPriceFilter";
import AiResetConfirmModal from "../../aiFilter/AiResetConfirmModal";
import GoStopAuctionMasterItemFilter, { auctionMasterItemList } from "./GoStopAuctionMasterItemFilter";
import GoStopAuctionStatusFilter, { auctionStatusList } from "./GoStopAuctionStatusFilter";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import { useSearchParams } from "react-router-dom";
import { Common } from "@src/styles/Common";
import ToastMessage from "@src/components/filter/ToastMessage";
import ResetFilterButton from "@src/components/filter/ResetFilterButton";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useGoStopFilterMapCheckStore, useGoStopFilterStore } from "@src/store/GoStopFilterStore";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import styled from "@emotion/styled";
import GoStopAuctionCounterForceFilter from "./GoStopAuctionCounterForceFilter";
import { use } from "echarts";

export interface IFilterParamsType {
    type: number; //0: 전체, 1: 경매, 2: 공매
    sales_method: number; //0: 전체, 1: 매각, 2: 임대
    assets: number[]; //0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산
    loc: string[];
    use: string[];
    counter_force: number;
    auction_master: string[];
    auction_master_item: number[]; //1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경
    except_item: string[];
    status: number[];
    skp_type: number; //0: 전체, 1: 신건, 2: 유찰
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    gostop_profit?: any[];
    sort: number;
    auction_master_except: boolean;
}

export const initialFilterParams: IFilterParamsType = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force: 0,
    auction_master_item: [],
    auction_master_except: false,
    status: [1, 2],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    gostop_profit: [0, null],
    sort: 5,
    auction_master: [],
    except_item: [],
};

const check = {
    type: "경공매 구분(단일값)(0: 전체, 1: 경매, 2: 공매)",
    loc: "소재지(전국: [], 그 외: 소재지의 cortarNo 배열)[]",
    use: "용도(주거용: A~, 토지: B~, 산업용: C~, 상업용: D~, 기타: E01)[]",
    counter_force: "대항력(0: 전체, 1: 대항력 있음, 2: 대항력 없음)",
    auction_master_item:
        "경매고수물건(기존 경매고수물건과 제외물건 합쳐진 필터)(1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경)[]",
    auction_master_except: "경매고수물건 제외 여부(true: 제외, false: 포함)",
    status: "진행상태(1,2: 전체, 1: 진행예정, 2: 진행중)[]",
    skp_type: "유찰구분(0: 전체, 1: 신건, 2: 유찰)",
    skp_cnt: "유찰횟수[]",
    due_date: "매각기일(날짜 하나만 선택한 경우, 둘다 같은 날짜로 전달)[]",
    est_price: "감정가[]",
    min_price: "최저입찰가[]",
    sales_method: "처분방식(0: 전체, 1: 매각, 2: 임대)",
    assets: "자산구분(0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산)[]",
    sort: "정렬(0: 업데이트순, 1: 감정가 높은순, 2: 감정가 낮은순, 3: 최저입찰가 높은순, 4: 최저입찰가 낮은순, 5: 매각기일 가까운순, 6: 매각기일 먼순, 7: 유찰횟수 많은순 8: 유찰횟수 적은순)",
};

interface Props {
    from?: string;
    text?: string;
    subs?: boolean;
    setClickedGoStopFilter: any;
    onSubmitSaveGoStopFilter: () => void;
    getInfoFilterData: IFilterParamsType;
}

const GoStopFilter: React.FC<Props> = ({
    from = "map",
    text = "",
    subs,
    setClickedGoStopFilter,
    onSubmitSaveGoStopFilter,
    getInfoFilterData,
}) => {
    const [searchParam, setSearchParam] = useSearchParams();
    const filterParams = useGoStopFilterStore((state) => state.goStopFilterState);
    const setFilterParams = useGoStopFilterStore((state) => state.setStoreGoStopFilterState);
    const setFilterCheck = useGoStopFilterMapCheckStore((state) => state.setGoStopFilterMapCheckState);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false); // 1 열린상태 0 취소나 닫힌 2 확인 닫힌
    const [checkReset, setCheckReset] = useState<boolean>(false);

    /*시세차익*/
    const [selectedRange, setSelectedRange] = useState<any>([
        filterParams?.gostop_profit[0] ?? 0,
        filterParams?.gostop_profit[1] ?? 10000000000,
    ]);
    const applyRange = () => {
        if (selectedRange[0] === 0 && selectedRange[1] === 10000000000) {
            setFilterParams({
                ...filterParams,
                gostop_profit: [0, null],
            });
        } else {
            setFilterParams({
                ...filterParams,
                gostop_profit: selectedRange,
            });
        }
    };

    const tabButtons = [
        {
            title: "시세차익",
            key: "gostop_profit",
            selected: !!filterParams.gostop_profit.some((val: any) => val),
            selectedChild: `시세차익${convertNumberToKorean(filterParams.gostop_profit?.[0])}~${convertNumberToKorean(
                filterParams.gostop_profit?.[1],
            )}`,
        },
        {
            title: "경공매",
            key: "type",
            selected: !!filterParams.type,
            selectedChild: filterParams.type === 1 ? "경매" : "공매",
        },
        {
            title: "소재지",
            key: "loc",
            selected: !!filterParams.loc.length,
            selectedChild: "소재지",
        },
        {
            title: "용도",
            key: "use",
            selected: !!filterParams.use.length,
            selectedChild: "용도",
        },
        {
            title: "대항력",
            key: "counter_force",
            selected: !!filterParams.counter_force,
            selectedChild:
                filterParams.counter_force === 2 ? "대항력 있음" : filterParams.counter_force === 1 ? "대항력 없음" : "미상",
        },
        {
            title: "최저입찰가",
            key: "min_price",
            selected: !!filterParams.min_price.some((val: any) => val),
            selectedChild:
                `최저입찰가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value === (filterParams.min_price[0] === "30000000000" ? "higher" : filterParams.min_price[0]),
                    )?.[0]?.showText
                } ~ ` +
                `${
                    filterParams.min_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterParams.min_price[1])?.[0]?.title
                }`,
        },
        {
            title: "경매고수물건",
            key: "auction_master_item",
            selected: !!filterParams.auction_master_item.length,
            selectedChild:
                filterParams.auction_master_item.length > 1
                    ? `${auctionMasterItemList[filterParams.auction_master_item[0]]} 외 ${
                          filterParams.auction_master_item.length - 1
                      }${filterParams.auction_master_except ? " 제외" : ""}`
                    : `${auctionMasterItemList[filterParams.auction_master_item[0]]}${
                          filterParams.auction_master_except ? " 제외" : ""
                      }`,
        },
        {
            title: "진행상태",
            key: "status",
            // selected: filterParams.status.length === 1,
            selected: false,
            selectedChild:"진행상태"
                // filterParams.status.length > 1
                //     ? "진행상태"
                //     : filterParams.status.length > 0
                //     ? `${findKeyByValueFromStatus(filterParams.status[0])}`
                //     : "진행상태",
        },
    ];
    const [openOutFilter, setOpenOutFilter] = useState<string>("");

    const onClickDone = () => {
        setFilterCheck(false);
        transferToNative(JSON.stringify(filterParams), "setFilterDone");
        setOpenOutFilter("");
        applyRange();
        setClickedGoStopFilter(false);
        onSubmitSaveGoStopFilter();
    };

    useEffect(() => {
        if (searchParam.get("close")) {
            onClickDone();
            const currentParams = Object.fromEntries(searchParam);
            delete currentParams?.close;
            setSearchParam(currentParams);
        }
    }, [searchParam.get("close")]);

    //필터값을 저장되어있는 값 패칭할 때 setSelectedRange해주는 부분 생성
    useEffect(() => {
        if (getInfoFilterData?.type !== undefined && getInfoFilterData?.gostop_profit) {
            setSelectedRange([getInfoFilterData?.gostop_profit[0] ?? 0, getInfoFilterData?.gostop_profit[1] ?? 10000000000]);
        }
    }, [getInfoFilterData]);

    return (
        <div css={rootStyle(!!openOutFilter)}>
            <div css={rootBoxStyle(!!openOutFilter)}>
                <div css={tabListBoxStyle(isShowFilter)}>
                    <div className="filter_button_scroll_wrapper">
                        {tabButtons.map((item, index) => (
                            <TabButton
                                isSelected={openOutFilter === item.key || item.selected}
                                disabled={false}
                                subs={subs}
                                key={`${item}_${index}`}
                                onClick={() => {
                                    transferToNative("", "setFilterStart");
                                    setClickedGoStopFilter(true);
                                    if (openOutFilter === item.key) {
                                        onClickDone();
                                    } else {
                                        setFilterCheck(true);
                                        setOpenOutFilter(item.key);
                                    }
                                }}
                            >
                                <span>{item.selected ? item.selectedChild : item.title}</span>
                            </TabButton>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                {openOutFilter && (
                    <>
                        <div css={dividerStyle} />
                        <div css={whiteDividerStyle} />
                    </>
                )}
                {openOutFilter === "gostop_profit" && (
                    <GoStopProfitFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                        checkReset={checkReset}
                        onChangeCheckReset={() => setCheckReset(false)}
                        selectedRange={selectedRange}
                        setSelectedRange={setSelectedRange}
                        applyRange={applyRange}
                    />
                )}
                {openOutFilter === "type" && (
                    <GoStopAuctionTypeFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "loc" && (
                    <GoStopNewLocationFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        showToast={() => setToastVisible(true)}
                        from="out"
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "use" && (
                    <GoStopLandUseFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        from="out"
                        showToast={() => setToastVisible(true)}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "counter_force" && (
                    <GoStopAuctionCounterForceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "auction_master_item" && (
                    <GoStopAuctionMasterItemFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "status" && (
                    <GoStopAuctionStatusFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}
                {openOutFilter === "min_price" && (
                    <GoStopAuctionMinPriceFilter
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        allFilter={false}
                        onClickDone={onClickDone}
                    />
                )}

                {openOutFilter && openOutFilter !== "history" && (
                    <ResetFilterButton onResetClick={() => setResetModalVisible(true)} onConfirmClick={onClickDone} />
                )}
            </div>
            <ToastMessage visible={toastVisible} setToastVisible={() => setToastVisible(false)}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="고스톱필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={() => {
                        setFilterParams(initialFilterParams);
                        setResetModalVisible(false);
                        setCheckReset(true);
                    }}
                />
            )}
            {openOutFilter && <div css={{ height: "100%", background: "rgba(0,0,0,1)", opacity: 0.3 }} onClick={onClickDone} />}
        </div>
    );
};

export default GoStopFilter;

const rootStyle = (isShowFilterList: boolean) => css`
    width: 100vw;
    height: ${isShowFilterList ? "calc(var(--vh, 1vh) * 100)" : "fit-content"};
    height: ${isShowFilterList ? "100dvh" : "fit-content"};
    height: ${isShowFilterList ? "100vh" : "fit-content"};
`;

const rootBoxStyle = (isShowFilterList: boolean) => css`
    padding: 3px 14px;
    background-color: ${isShowFilterList ? "#ffffff" : "rgba(255, 255,2 55, 0"};
`;

const inputBoxStyle = css`
    position: relative;
    width: 100%;
    height: 44px;
    padding: 4px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const inputStyle = css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    opacity: 1;
    height: 40px;
    border: 1px solid #e3e3e6;
    border-radius: 8px;
    padding: 0px 10px 0px 48px;
    font-size: 16px;
    background: #ffffff;
    &:focus {
        outline: none;
    }
`;

const searchImageStyle = css`
    position: absolute;
    left: 58px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
`;

const tabListBoxStyle = (isShowFilter: boolean) => css`
    display: flex;
    align-items: center;
    height: ${isShowFilter ? "56px" : "0px"};
    opacity: ${isShowFilter ? 1 : 0};
    visibility: ${isShowFilter ? "visible" : "hidden"};
    transition: all 0.2s ease;
    z-index: 3;
    button + button {
        margin-left: 8px;
    }
    & > a {
        & > button {
            opacity: ${isShowFilter ? 1 : 0};
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);
            visibility: ${isShowFilter ? "visible" : "hidden"};
            height: ${isShowFilter ? "30px !important" : "0px"};
            transition: all 0.2s ease;
        }
    }
    .filter_button_scroll_wrapper {
        display: flex;
        overflow-x: scroll;
        z-index: 2;
        height: ${isShowFilter ? "32px" : "0px"};
        opacity: ${isShowFilter ? 1 : 0};
        visibility: ${isShowFilter ? "visible" : "hidden"};
        transition: all 0.2s ease;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const allFilterButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: ${Common.colors.aucMarkerColor};
    min-width: 102px;
    border-radius: 24px;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.white};
    }
    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const dividerStyle = css`
    height: 12px;
    background: rgb(236, 239, 244);
`;

const whiteDividerStyle = css`
    height: 10px;
    background-color: #ffffff;
`;

const TabButton = styled.button<{ isSelected: boolean; disabled: boolean; subs: boolean | undefined }>`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 24px;
    box-shadow: ${({ disabled, subs }) => (disabled ? 0 : !subs ? 0 : "1px 2px 4px rgba(0, 0, 0, 0.14)")};
    padding: 8px 16px 8px 16px;
    background: ${({ isSelected, disabled, subs }) =>
        disabled
            ? `${Common.colors.blue01}`
            : isSelected
            ? ` ${Common.colors.aucMarkerColor}`
            : subs
            ? `none`
            : ` ${Common.colors.white}`};
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isSelected, disabled, subs }) =>
            disabled
                ? `${Common.colors.darkGrey04}`
                : isSelected
                ? ` ${Common.colors.white}`
                : subs
                ? `${Common.colors.normalTextColor}`
                : `${Common.colors.darkGrey04}`};
    }
`;
