import styled from "@emotion/styled";
import { useAiBottomSheet } from "@src/hooks/filter/useAiBottomSheet";
import { useGetAiItemList } from "@src/hooks/filter/useGetAiItemList";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import AiBottomSheetContent from "./AiBottomSheetContent";
import AiBottomSheetHeader from "./AiBottomSheetHeader";
import AiBottomSheetContentTitle from "./AiBottonSheetContentTitle";
import { BOTTOM_SHEET_HEIGHT, MAX_Y, MIN_Y } from "./Constants";

interface AiBottomSheetProps {
    price: string;
    aiFilterCount?: string;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const AiBottomSheet = ({ price, visible, setVisible }: AiBottomSheetProps) => {
    const { sheetRef, content, buttonWrapper, sort, isTouched, buttonRef1, buttonRef2, buttonRef3 } = useAiBottomSheet();
    // const { fetchData, setData, getTotalItemCount, setTotalData, data, totalData } = useGetAiItemList();
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const [target, setTarget] = useState();
    const aiFilterDataList = useAiFilterStore((state) => state.aiFilterDataList);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const aiFilterTotalCnt = useAiFilterStore((state) => state.aiFilterTotalCnt);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const aiBottomSheetRef = useAiFilterStore((state) => state.aiBottomSheetRef);
    const setAiBottomSheetRef = useAiFilterStore((state) => state.setAiBottomSheetRef);
    const setAibuttomRef = useAiFilterStore((state) => state.setAiButtonRef);
    // useEffect(() => {
    //     if (aiFilterState.price === "") {
    //         setAiFilterState({
    //             ...aiFilterState,
    //             price: "0",
    //         });
    //     }
    // }, [aiFilterState.price]);
    useEffect(() => {
        setAiBottomSheetRef(sheetRef);
        setAibuttomRef(buttonRef1, buttonRef2, buttonRef3);
    });
    return (
        <>
            <Wrapper ref={sheetRef}>
                <AiBottomSheetHeader />
                <AiBottomSheetContentTitle
                    buttonWrapper={buttonWrapper}
                    sort={sort}
                    totalData={Number(aiFilterTotalCnt).toLocaleString()}
                    visible={visible}
                    buttonRef1={buttonRef1}
                    buttonRef2={buttonRef2}
                    buttonRef3={buttonRef3}
                />
                <AiBottomSheetContent
                    content={content}
                    buttonWrapper={buttonWrapper}
                    sort={sort}
                    isTouched={isTouched}
                    data={aiFilterDataList}
                    setTarget={setTarget}
                />
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    border-radius: 14px 14px 0px 0px;
    background-color: #fff;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    height: ${BOTTOM_SHEET_HEIGHT}px;
    transition: transform 150ms ease-out;
    width: 100vw;
    top: ${MAX_Y}px;
    z-index: 994;
`;

export default AiBottomSheet;
