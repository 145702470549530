import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";

export const useBannerList = async (type: string) => {
    const response = await axiosInstance.get(`${axiosPath.bannerList}?type=${type}`);
    return response;
};

export const useFindAllBannerList = (type: string) => {
    return useQuery<IBanner[]>(
        [type, "banner"],
        async () => {
            const res = await useBannerList(type);
            return res?.data?.list;
        },
        { enabled: false },
    );
};

export interface IBanner {
    closeAt: string;
    imgurl: string;
    linkurl: string;
    no: number;
    openAt: string;
    type: string;
}
