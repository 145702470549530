import create from "zustand";

type useMapBottomSheetStoreType = {
    wrapperHeight: number;
    setWrapperHeight: (value: number) => void;
    currentIndex: number;
    setCurrentIndex: (value: number) => void;
    animate: boolean;
    setAnimate: (value: boolean) => void;
};

export const useMapBottomSheetStore = create<useMapBottomSheetStoreType>((set) => ({
    wrapperHeight: 0,
    currentIndex: 0,
    animate: false,
    setWrapperHeight: (value: number) => {
        set({ wrapperHeight: value });
    },
    setCurrentIndex: (value: number) => {
        set({ currentIndex: value });
    },
    setAnimate: (value: boolean) => {
        set({ animate: value });
    },
}));
