export const opposingPowerData = [
    {
        title: "전체",
        id: "0",
        isActive: true,
    },
    {
        title: "대항력 없음",
        id: "1",
        isActive: false,
    },
    {
        title: "대항력 있음",
        id: "2",
        isActive: false,
    },
    {
        title: "미상",
        id: "3",
        isActive: false,
    },
];
