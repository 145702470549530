import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import seekbarIcon from "@src/assets/ico_seekbar.png";

interface rangeSliderProps {
    xAxis: any[];
    handleSubmit: Function;
    prevIndex?: number[];
}

export default function RangeSliderV2({ xAxis, handleSubmit, prevIndex }: rangeSliderProps) {
    const [value, setValue] = useState<number[]>([0, xAxis.length]);
    const [displayValue, setDisplayValue] = useState<string[]>(["0", `${xAxis.length}`]);
    const [displayValueIndex, setDisplayValueIndex] = useState<number[]>([0, 1]);

    useEffect(() => {
        const xLength = xAxis.length;
        if (prevIndex) {
            // 이전 값이 있을 경우 초기화
            let [prevStart, prevEnd] = prevIndex;
            // let standardRange = 100 / (xLength - 1); // 1구간 당 값
            let standardRange = 1; // 1구간 당 값
            let startPosition = standardRange * prevStart;
            let endPosition = prevEnd !== null ? standardRange * prevEnd : null;
            setValue([startPosition, endPosition ?? xAxis.length]);
            setDisplayValue([xAxis[prevStart], xAxis[prevEnd ?? xLength - 1]]);
            setDisplayValueIndex(prevIndex);
        } else {
            // 이전 값이 없을 경우 초기화
            setDisplayValue([xAxis[0], xAxis[xLength - 1]]);
            setDisplayValueIndex([0, xLength - 1]);
        }
    }, [prevIndex]);

    const formattingValue = (value: number[], xAxis: string[], activeThumb: number): void => {
        const range = xAxis; // 전체 구간
        // let standardRange = 100 / (xAxis.length - 1); // 1구간 당 값
        let standardRange = 1; // 1구간 당 값
        let changedValue = value[activeThumb]; // 왼쪽, 오른쪽 thumb 중 변경된 값
        let currentIndex = Math.floor(changedValue / standardRange); // 현재 값이 해당하는 구간
        let formattedValue = range[currentIndex];
        const newDisplayValue = [...displayValue];
        const newDisplayValueIndex = [...displayValueIndex];
        newDisplayValue[activeThumb] = formattedValue;
        setDisplayValue(newDisplayValue);

        newDisplayValueIndex[activeThumb] = currentIndex;
        setDisplayValueIndex(newDisplayValueIndex);

        handleSubmit(newDisplayValueIndex); // 부모 컴포넌트로 변경사항 전달
    };
    const handleChange = (event: Event, newValue: number | number[], activeThumb: number): void => {
        setValue(newValue as number[]);
        if (typeof newValue !== "number") {
            formattingValue(newValue, xAxis, activeThumb);
        }
    };
    return (
        <div css={sliderWrap}>
            <Slider
                getAriaLabel={() => "Minimum distance"}
                value={value}
                step={1}
                onChange={handleChange}
                valueLabelDisplay="off"
                max={xAxis?.length - 1}
                disableSwap
            />
            <div css={values}>
                <span>{displayValue[0]}</span>
                <span>{displayValue[1]}</span>
            </div>
        </div>
    );
}
const sliderWrap = css`
    width: 100%;
    height: fit-content;
    padding: 14px 28px;
    .MuiSlider-root {
        height: 8px;
    }
    .MuiSlider-rail {
        background: ${Common.colors.paleGreen};
        opacity: 1;
    }
    .MuiSlider-track {
        background: ${Common.colors.mint};
        border: none;
    }
    .MuiSlider-thumb {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: url(${seekbarIcon}) no-repeat;
        background-size: contain;
    }
`;
const values = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    /* padding: 0 8px; */
    span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: ${Common.colors.darkGrey04};
    }
`;
