import styled from "@emotion/styled";
import ico_left_arrow_black from "@src/assets/ico_left_arrow_black.png";
import ico_close from "@src/assets/ico_close.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import React from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

type AIHeaderPropsType = {
    onClickBack: () => void;
};

const AiHeader = ({ onClickBack }: AIHeaderPropsType) => {
    const { pathname } = useLocation();
    return (
        <AiHeaderContainer>
            {!pathname.includes("small") ? (
                <div className="content">
                    <span>AI</span> 추천 매물
                </div>
            ) : (
                <div className="content">소액 투자</div>
            )}
            <button onClick={onClickBack}>
                <img src={ico_close} />
            </button>
        </AiHeaderContainer>
    );
};

export default AiHeader;

const AiHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    padding: 14px 0px;
    & > img {
        width: 14px;
        height: 14px;
    }

    .content {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;
    }

    button {
        position: absolute;
        /* right: 7px; */
        /* top: calc(50% - 9px); */
        right: 0;
        top: 0;
        padding: 0 15px;
        height: 56px;
        cursor: pointer;
    }
    img {
        width: 15px;
    }

    span {
        color: #2d54df;
    }
`;
