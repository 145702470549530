import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Common } from "@src/styles/Common";
import { useNavigate } from "react-router-dom";
import flightViewIcon from "@src/assets/floatMapMenu/flightViewIcon.svg";
import flightViewActiveIcon from "@src/assets/floatMapMenu/flightViewActiveIcon.svg";
import roadViewIcon from "@src/assets/floatMapMenu/roadViewIcon.svg";
import roadViewActionIcon from "@src/assets/floatMapMenu/roadViewActionIcon.svg";
import positionSearchIcon from "@src/assets/floatMapMenu/positionSearchIcon.svg";
import positionSearchDisabledIcon from "@src/assets/floatMapMenu/positionSearchDisabledIcon.svg";
import positionSearchActiveIcon from "@src/assets/floatMapMenu/positionSearchActiveIcon.svg";
import stateLandlockIcon from "@src/assets/stateLand/stateLandlockIcon.svg";
import { transferToNative } from "@src/lib/WebAppBridge";
import { IUserStateLandSubs } from "@src/hooks/stateLand/useGetStateLandSubsInfo";
import { useOpenStateLandFilterStore } from "@src/store/stateLandFilter";

interface Props {
    map: naver.maps.Map | null;
    mapLayerHandler: {
        activeRoadMap: (map: naver.maps.Map) => void;
        activeCadastralMap: (map: naver.maps.Map) => void;
        activeSatelliteMap: (map: naver.maps.Map, mapType: "HYBRID" | "NORMAL") => void;
    };
    userLocation: any;
    handleClickUseButton: any;
    handleMarkerHideBtnClick: (type: "product" | "real" | "expected", isActive: boolean) => void;
    isMarkerHide: any;
    onChangeShowConvenienceBar: () => void;
    showCanvas: boolean;
    drawingType: number;
    showConvenienceBar: boolean;
    isCadastralShow: boolean;
    onChangeCadastralShow: () => void;
    isStreetActive: boolean;
    onChangeIsStreetActive: () => void;
    isFlightActive: boolean;
    onChangeIsFlightActive: () => void;
    checkMyPosition: boolean;
    onChangeCheckMyPosition: () => void;
    openStateLandFilter?: boolean;
    onChangeStateLandFilter?: any;
    startStateLand: boolean;
    onChangeStartStateLand: () => void;
    stateLandSubs?: IUserStateLandSubs;
    refetchStateLandSubs: any;
}

interface IFloatMenuWrap {
    showCanvas: boolean;
    drawingType: number;
}

const mapTypeList: any = [
    { name: "지적", value: "cadastral" },
    { name: "일반", value: "NORMAL" },
    { name: "위성", value: "HYBRID" },
];

const markerTypeList = [
    { name: "실거래", value: "real" },
    { name: "경공매", value: "product" },
    { name: `예정\n매물`, value: "expected" },
];

const NewFloatingMenu: React.FC<Props> = ({
    map,
    mapLayerHandler,
    userLocation,
    handleClickUseButton,
    handleMarkerHideBtnClick,
    isMarkerHide,
    onChangeShowConvenienceBar,
    showCanvas,
    drawingType,
    showConvenienceBar,
    isCadastralShow,
    onChangeCadastralShow,
    isStreetActive,
    onChangeIsStreetActive,
    isFlightActive,
    onChangeIsFlightActive,
    checkMyPosition,
    onChangeCheckMyPosition,
    openStateLandFilter,
    onChangeStateLandFilter,
    startStateLand,
    onChangeStartStateLand,
    stateLandSubs,
    refetchStateLandSubs,
}) => {
    const navigate = useNavigate();
    const [openMapTypeList, setOpenMapTypeList] = useState<boolean>(false);
    const [openMarkerHide, setOpenMarkerHide] = useState<boolean>(false);
    const [mapType, setMapType] = useState("hybrid");

    useEffect(() => {
        if (map) {
            setMapType(map.getMapTypeId());
        }
    }, [map]);

    return (
        <>
            <div css={floatingRightMenuWrap(showCanvas)}>
                <div css={buttonBoxStyle(drawingType)}>
                    <button
                        css={rootButtonStyle(openMapTypeList)}
                        onClick={() => {
                            setOpenMarkerHide(false);
                            setOpenMapTypeList((pre) => !pre);
                        }}
                        disabled={!!drawingType}
                    >
                        지도
                    </button>
                    <div css={subButtonBoxStyle(openMapTypeList)}>
                        {mapTypeList.map((type: any, idx: number) => {
                            const activeCheck = idx === 0 ? isCadastralShow : mapType === type.value.toLowerCase();
                            return (
                                <button
                                    css={rootButtonStyle(activeCheck)}
                                    key={type.value}
                                    onClick={() => {
                                        if (idx === 0) {
                                            onChangeCadastralShow();
                                        } else {
                                            map && mapLayerHandler.activeSatelliteMap(map, type.value);
                                            setMapType(type.value.toLowerCase());
                                        }
                                    }}
                                >
                                    {type.name}
                                </button>
                            );
                        })}
                    </div>
                </div>
                <div css={buttonBoxStyle(drawingType)}>
                    <button
                        css={rootButtonStyle(false)}
                        disabled={!!drawingType}
                        onClick={() => {
                            if (drawingType === 0) {
                                onChangeCheckMyPosition();
                                transferToNative("", "onClickUserLoButton");
                            }
                        }}
                        id="myPosition"
                    >
                        <img
                            src={
                                drawingType
                                    ? positionSearchDisabledIcon
                                    : checkMyPosition
                                    ? positionSearchActiveIcon
                                    : positionSearchIcon
                            }
                            alt=""
                            width="44"
                            height="44"
                        />
                    </button>
                </div>
                <div css={buttonBoxStyle(drawingType)}>
                    <button
                        css={rootButtonStyle(false)}
                        onClick={() => {
                            if (drawingType === 0) {
                                navigate("/realPriceFilter", {
                                    state: { ...isMarkerHide },
                                });
                            }
                        }}
                        disabled={!!drawingType}
                    >
                        실거래
                    </button>
                </div>
                <div css={buttonBoxStyle(0)}>
                    <button
                        css={rootButtonStyle(Boolean(drawingType || showConvenienceBar))}
                        onClick={() => {
                            setOpenMarkerHide(false);
                            setOpenMapTypeList(false);
                            onChangeShowConvenienceBar();
                        }}
                    >
                        편의
                    </button>
                </div>
                <div css={buttonBoxStyle(drawingType)}>
                    <button css={rootButtonStyle(isStreetActive)} disabled={!!drawingType} onClick={onChangeIsStreetActive}>
                        <img src={isStreetActive ? roadViewActionIcon : roadViewIcon} alt="roadViewIcon" />
                        거리뷰
                    </button>
                </div>
                <div css={buttonBoxStyle(drawingType)}>
                    <button css={rootButtonStyle(isFlightActive)} onClick={onChangeIsFlightActive} disabled={!!drawingType}>
                        <img src={isFlightActive ? flightViewActiveIcon : flightViewIcon} alt="flightViewIcon" />
                        항공뷰
                    </button>
                </div>
                <div css={buttonBoxStyle(drawingType)}>
                    <button css={rootButtonStyle(startStateLand, true)} onClick={onChangeStartStateLand} disabled={!!drawingType}>
                        {!stateLandSubs?.isActive && <img src={stateLandlockIcon} alt="" css={stateLandLockStyle} />}
                        국∙공
                        <br />
                        유지
                    </button>
                    {stateLandSubs?.isActive && (
                        <div css={subRightButtonBoxStyle(startStateLand)}>
                            <button css={premiumButtonStyle} onClick={onChangeStateLandFilter}>
                                필터
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div css={floatingLeftMenuWrap(showCanvas)}>
                <div css={buttonBoxStyle(drawingType)}>
                    <button
                        css={rootButtonStyle(openMarkerHide || Object.values(isMarkerHide).some((v) => v))}
                        onClick={() => {
                            setOpenMapTypeList(false);
                            setOpenMarkerHide((pre) => !pre);
                        }}
                        disabled={!!drawingType}
                    >
                        마커
                        <br />
                        숨김
                    </button>
                    <div css={subLeftButtonBoxStyle(openMarkerHide)}>
                        {markerTypeList.map((type) => (
                            <button
                                css={rootButtonStyle(isMarkerHide[type.value])}
                                key={type.value}
                                onClick={(e) => {
                                    e.preventDefault();
                                    const newVal: any = type.value;
                                    handleMarkerHideBtnClick(newVal, !isMarkerHide[type.value]);
                                }}
                            >
                                {type.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewFloatingMenu;

const floatingRightMenuWrap = (showCanvas: boolean) => css`
    position: fixed;
    right: 10px;
    top: 110px;
    z-index: 990;
    ${showCanvas && {
        display: "none",
    }}
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
`;

const floatingLeftMenuWrap = (showCanvas: boolean) => css`
    position: fixed;
    left: 10px;
    top: 110px;
    z-index: 990;
    ${showCanvas && {
        display: "none",
    }}
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
`;

const buttonBoxStyle = (drawingType: number) => css`
    position: relative;
    ${drawingType && {
        display: "none",
    }}
`;

const premiumButtonStyle = css`
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background-color: #2275ee;
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const rootButtonStyle = (active: boolean, checkPremium: boolean = false) => css`
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background-color: ${active ? (checkPremium ? "#282828" : Common.colors.aucMarkerColor) : "#ffffff"};
    color: ${active ? "#ffffff" : Common.colors.navyPrimary};
    text-align: center;
    font-size: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${checkPremium && {
        lineHeight: "18px",
    }}
    :disabled {
        background: #dadada;
        color: #0c002c;
    }
`;

const subButtonBoxStyle = (active: boolean) => css`
    visibility: ${active ? "visible" : "hidden"};
    opacity: ${active ? 1 : 0};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    right: 52px;
    transition: all 0.3s;
`;

const subLeftButtonBoxStyle = (active: boolean) => css`
    visibility: ${active ? "visible" : "hidden"};
    opacity: ${active ? 1 : 0};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 52px;
    transition: all 0.3s;
`;

const subRightButtonBoxStyle = (active: boolean) => css`
    visibility: ${active ? "visible" : "hidden"};
    opacity: ${active ? 1 : 0};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 0;
    right: 52px;
    transition: all 0.3s;
`;

const stateLandLockStyle = css`
    position: absolute;
    top: 17px;
    left: -11px;
`;
