import React from "react";

import { Checkbox, css } from "@mui/material";

import filterImg from "@src/assets/goStop/filter.png";
import checkedIcon from "@src/assets/goStop/unchecked.svg";
import closeWhiteIcon from "@src/assets/goStop/closeWhite.svg";
import uncheckedIcon from "@src/assets/goStop/checked.svg";
import btnImg from "@src/assets/goStop/btn.png";

interface IGoStopTutorial {
    tutorialEnable: boolean;
    setTutorial: (val: boolean) => void;
    setTutorialEnable: (val: any) => void;
}

const GoStopTutorial = ({ tutorialEnable, setTutorial, setTutorialEnable }: IGoStopTutorial) => {

    const disableTutorial = () => {
        if (tutorialEnable === true) {
            window.localStorage.setItem("tutorialDisable", "true");
        }
        setTutorial(false);
    }

    return (
        <div onClick={() => { disableTutorial(); }} css={tutorialBox}>
            <div className="closeBox">
                <div onClick={(e) => { e.stopPropagation(); setTutorialEnable((prev: boolean) => !prev) }}>
                    <Checkbox
                        id="weeklyModal"
                        icon={<img src={uncheckedIcon} alt="" />}
                        checked={!tutorialEnable}
                        checkedIcon={<img src={checkedIcon} alt="" />}
                    />
                    <span style={{ color: "#fff" }}>다시 보지 않기</span>
                </div>
                <div onClick={() => { disableTutorial(); }}>
                    <img src={closeWhiteIcon} alt="" />
                </div>
            </div>
            <div className="filterBox">
                <img src={filterImg} alt="" />
                <p>주거/토지 수익 필터 구매 시, <br />시세차익을 확인할 수 있어요</p>
            </div>
            <div className="btnBox">
                <p>지금 상품을 구매하고 <br />시세차익 높은 매물을 확인해 보세요!</p>
                <img src={btnImg} alt="" />
            </div>
        </div >
    )
}

export default GoStopTutorial;

const tutorialBox = css`
    width: 100vw;
    height: 100vh;
    background-color: #282828D1;
    position: absolute;
    z-index: 9999;

    .closeBox {
        display: flex;
        align-items: center;
        padding: 0px 14px;
        justify-content: space-between;
        margin-top: 10px;


        & > div:nth-of-type(1) {
            margin-left: -9px;
        }

        & > div:nth-of-type(2) {
            display: flex;
            align-items: center;
        }
    }

    .filterBox{
        position: absolute;
        top: 130px;
        width: 100%;
        & > img {
            width: 100%;
        }
        & > p {
            position: relative;
            font-size: 14px;
            background-color: #fff500;
            color: #0c002c;
            width: fit-content;
            padding: 8px;
            border-radius: 4px;
            margin-left: 30px;
            top: -10px;
            line-height: 20px;
        }

        & > p::after {
            content: '';
            position: absolute;
            top: -12px;
            left: 10%;
            margin-left: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent #fff500 transparent;
        }
    }

    .btnBox{
        position: absolute;
        bottom: 16px;
        width: 100%;
        & > img {
            width: 100%;
        }

        & > p {
            position: relative;
            font-size: 14px;
            background-color: #fff500;
            color: #0c002c;
            width: fit-content;
            padding: 8px;
            border-radius: 4px;
            margin-left: 130px;
            line-height: 20px;
            margin-bottom: 0px;
        }

        & > p::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 20%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #fff500 transparent transparent transparent;
        }
    }

`