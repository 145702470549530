import {
    IAiRecommendFilter,
    IAiRecommendSetting,
    IUserAiUpdateSetting,
    initialAiRecommendFilter,
    initialUserAiSetting,
} from "@src/hooks/aiRecommend/useAiRecommend";
import create from "zustand";

interface IAiRecommendFilterStore {
    aiRecommendFilterState: IAiRecommendFilter;
    setAiRecommendFilterState: (state: IAiRecommendFilter) => void;
    resetAiRecommendFilterState: () => void;
}

interface IUserAiSettingStore {
    userAiSettingState: IUserAiUpdateSetting;
    setUserAiSettingState: (state: IUserAiUpdateSetting) => void;
    resetUserAiSettingState: () => void;
}

export const useAiRecommendFilterStore = create<IAiRecommendFilterStore>((set) => ({
    aiRecommendFilterState: initialAiRecommendFilter,
    setAiRecommendFilterState: (state: IAiRecommendFilter) => set({ aiRecommendFilterState: state }),
    resetAiRecommendFilterState: () => set({ aiRecommendFilterState: initialAiRecommendFilter }),
}));

export const useUserAiSettingSotre = create<IUserAiSettingStore>((set) => ({
    userAiSettingState: initialUserAiSetting,
    setUserAiSettingState: (state: IUserAiUpdateSetting) => set({ userAiSettingState: state }),
    resetUserAiSettingState: () =>
        set({
            userAiSettingState: {
                investmentPrice: 0,
                regions: [],
                categories: [],
                bidType: 0,
                sortType: 0,
                settingOrder: 1,
                isFinish: false,
                saleMethod: 0,
                assetDetails: [],
            },
        }),
}));
