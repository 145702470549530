import React from 'react';
import StoreGoStopBannerPage from "@src/assets/storeGoStop/storeGoStopBannerPage.png";
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { getDeviceType } from '@src/lib/WebAppBridge';

const ServiceBannerPage = () => {
    const navigate = useNavigate();
    return (
        <div css={bannerContainer}>
            <button onClick={() => { getDeviceType() === "android" ? navigate("/subscriptionInfo?type=6") : getDeviceType() === "ios" ? navigate("/ios-subscriptionInfo?type=7") : "" }}>구독하러 가기</button>
            <img src={StoreGoStopBannerPage} alt="" />
        </div>
    )
}

export default ServiceBannerPage;

const bannerContainer = css`
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    & > img {
        width: 100%;
    }

    & > button {
        position: fixed;
        z-index: 100;
        font-size: 26px;
        padding: 10px 48px;
        border-radius: 100px;
        bottom: 20px;
        transform: translateX(-50%);
        white-space: nowrap;
        left: 50%;
        background: #0047ff;
        box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
        color: #ffffff;
    }
`