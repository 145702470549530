import convertStrToPrice from "@src/util/convertStrToPrice";
import React from "react";

// 실거래가_실거래가
const realPriceHeaderInRealPriceDetail = () => {
    return [
        {
            accessor: "date",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>거래일</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "price",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>금액</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "sizeSquare",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>면적</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "note",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>비고</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
    ];
};

// 실거래가_유사실거래가
const similarRealPriceHeaderInRealPriceDetail = () => {
    return [
        {
            accessor: "date",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>거래일</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "price",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>금액</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "sizeSquare",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>면적</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "note",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>위치</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
    ];
};

// 토지상세_토지이동계획
const landChangeHeaderInLandDetail = () => {
    return [
        {
            accessor: "date",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>토지이동일</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "reason",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>토지이동사유</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
    ];
};
// 토지상세_개별공시지가
const landPriceHeaderInLandDetail = (sizeUnit: "meter" | "pyeong") => {
    return [
        {
            accessor: "stdr",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>기준연도</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "pblntfPclnd",
            Header: () => (
                <div style={{ width: "100%", textAlign: "center" }}>{sizeUnit === "meter" ? "공시지가(㎡)" : "공시지가(평)"}</div>
            ),
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
    ];
};
// 건물상세_총괄표제부
const totalTabHeaderInBuildingDetail = () => {
    return [
        {
            accessor: "mainAtchGbCdNm",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>구분</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "bldNm",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>건축물</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "etcPurps",
            Header: () => <div style={{ width: "100%", textAlign: "center" }}>용도</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
    ];
};
// 건물상세_표제부
const singleTabHeaderInBuildingDetail = () => {
    return [
        {
            accessor: "mainAtchGbCdNm",
            Header: () => <div style={{ width: "32px", textAlign: "center" }}>구분</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "32px", textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "flrNoNm",
            Header: () => <div style={{ width: "158px", textAlign: "center" }}>층</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "158px", textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "etcPurps",
            Header: () => <div style={{ width: "98px", textAlign: "center" }}>용도</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "98px", textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "area",
            Header: () => <div style={{ width: "98px", textAlign: "center" }}>면적(㎡)</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "98px", textAlign: "center" }}>{value}</div>,
        },
    ];
};
// 건물상세_표제부_개별주택공시가격
const normalHousingHeaderInBuildingDetail = () => {
    return [
        {
            accessor: "stdr",
            Header: () => <div style={{ width: "32px", textAlign: "center" }}>기준연도</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "32px", textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "price",
            Header: () => <div style={{ width: "158px", textAlign: "center" }}>공시가격</div>,
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "158px", textAlign: "center" }}>{convertStrToPrice(value)}원</div>
            ),
        },
    ];
};

export {
    totalTabHeaderInBuildingDetail,
    singleTabHeaderInBuildingDetail,
    landChangeHeaderInLandDetail,
    landPriceHeaderInLandDetail,
    realPriceHeaderInRealPriceDetail,
    similarRealPriceHeaderInRealPriceDetail,
    normalHousingHeaderInBuildingDetail,
};
