import axios from "axios";

let abortController = new AbortController();
let signal = abortController.signal;
let axiosAsync = axios.create({
    headers: {
        Accept: "*/*",
    },
    signal,
    timeout: 2000,
});
const onApiCancelHandler = () => {
    abortController.abort();
    abortController = new AbortController();
    signal = abortController.signal;

    axiosAsync = axios.create({
        headers: {
            Accept: "*/*",
        },
        signal,
        timeout: 2000,
    });
};

axiosAsync.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosAsync.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        const code = error.code;

        if (code === "ECONNABORTED") {
            return axios.request(error.config);
        }

        if (axios.isCancel(error)) {
            console.log("요청 취소");
        }
        return Promise.reject(error);
    },
);
export { axiosAsync, onApiCancelHandler };
