import React, { useEffect } from "react";
import { css } from "@emotion/react";
import AiTitle from "@src/components/aiFilter/AiTitle";
import grayCheckIcon from "@src/assets/basicIcon/grayCheckIcon.svg";
import blueCheckIcon from "@src/assets/basicIcon/blueCheckIcon.svg";
import { IPushAgreement } from "@src/hooks/user/userApi";
import { useGetAIRecommendSetting } from "@src/hooks/aiRecommend/useAiRecommend";

const buttonList = [
    { name: "7일 전", value: 7 },
    { name: "14일 전", value: 14 },
    { name: "30일 전", value: 30 },
];

interface Props {
    alarmList: number[];
    setAlarmList: any;
    updatePushAgreement: any;
    pushAgreement?: IPushAgreement[] | any;
    checkedFilter: number;
}

const AiRecommendFourthStep = ({ alarmList, setAlarmList, checkedFilter, updatePushAgreement, pushAgreement }: Props) => {
    return (
        <div css={rootBoxStyle}>
            <AiTitle>
                <h2>
                    경공매 <span className="color-text">입찰일 알림</span>을 선택해주세요
                </h2>
            </AiTitle>
            <div css={rootStyle}>
                <p css={alarmTextStyle}>경공매 입찰일 알림</p>
                <div css={buttonBoxStyle}>
                    {buttonList.map((val) => (
                        <button
                            css={buttonStyle(alarmList.some((v) => v === val.value))}
                            key={val.name}
                            onClick={() => {
                                if (alarmList.some((v) => v === val.value)) {
                                    const newList = alarmList.filter((v) => v !== val.value);
                                    setAlarmList(newList);
                                    updatePushAgreement.mutate([
                                        {
                                            type: `agree_push_recommend_item${checkedFilter == 1 ? "" : "_" + checkedFilter}`,
                                            agreement: true,
                                            dueDate: newList,
                                        },
                                    ]);
                                } else {
                                    const newList = [...alarmList, val.value];
                                    setAlarmList(newList);
                                    updatePushAgreement.mutate([
                                        {
                                            type: `agree_push_recommend_item${checkedFilter == 1 ? "" : "_" + checkedFilter}`,
                                            agreement: true,
                                            dueDate: newList,
                                        },
                                    ]);
                                }
                            }}
                        >
                            {val.name}
                        </button>
                    ))}
                </div>
                <div
                    css={descBoxStyle}
                    onClick={() => {
                        const curDueDate = pushAgreement.filter(
                            (data: any) =>
                                data.type ==
                                (checkedFilter == 1 ? "agree_push_recommend_item" : `agree_push_recommend_item_${checkedFilter}`),
                        )[0];
                        const newDueDate = curDueDate.dueDate?.includes(1)
                            ? curDueDate.dueDate?.filter((val: any) => val !== 1)
                            : curDueDate?.dueDate && [...curDueDate.dueDate, 1];
                        setAlarmList(newDueDate)
                        updatePushAgreement.mutate([
                            {
                                type: `agree_push_recommend_item${checkedFilter == 1 ? "" : "_" + checkedFilter}`,
                                agreement: true,
                                dueDate: newDueDate,
                            },
                        ]);
                    }}
                >
                    <img
                        src={
                            pushAgreement
                                .filter(
                                    (data: any) =>
                                        data.type ==
                                        (checkedFilter == 1
                                            ? "agree_push_recommend_item"
                                            : `agree_push_recommend_item_${checkedFilter}`),
                                )[0]
                                ?.dueDate?.includes(1)
                                ? blueCheckIcon
                                : grayCheckIcon
                        }
                        alt="grayCheckIcon"
                        width={24}
                        height={24}
                    />
                    <p>입찰 하루 전 잊지 않게 알림 드릴게요!</p>
                </div>
            </div>
        </div>
    );
};

export default AiRecommendFourthStep;

const rootBoxStyle = css`
    padding: 0px 14px;
`;

const rootStyle = css`
    width: 100%;
`;

const alarmTextStyle = css`
    padding: 8px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #0c002c;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    border-bottom: 0.6px solid #b1aeb8;
`;

const buttonBoxStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 10px 24px;
    box-sizing: border-box;
    width: 100%;
`;

const buttonStyle = (active: boolean) => css`
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${active ? "#2275ee" : "#FDFAFA"};
    color: ${active ? "#ffffff" : "#0c002c"};
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    box-sizing: border-box;
    width: 100%;
`;

const descBoxStyle = css`
    padding: 0 14px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
