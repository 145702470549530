import { IFilterParamsType } from "@src/pages/newFilter";
import { configurePersist } from "zustand-persist";
import create from "zustand";

export interface IMapBidItemsParams {
    limit: number;
    cursor?: {
        sortType: number;
        cursorValue?: string;
        updatedAt?: string;
    };
    afterKey?: any;
    ne?: {
        lntd: number;
        latd: number;
    };
    sw?: {
        lntd: number;
        latd: number;
    };
    itemFilter: IFilterParamsType;
    itemId?: string;
    groupKey?: string;
}

type useFilterStoreType = {
    newFilterState: IFilterParamsType;
    setStoreNewFilterState: (filterState: IFilterParamsType) => void;
};

type listFilterStoreType = {
    listFilterState: IMapBidItemsParams;
    setStoreListFilterState: (filterState: IMapBidItemsParams) => void;
};

type useFilterSearchTextType = {
    newFilterSearchText: string;
    setNewFilterSearchText: (text: string) => void;
};

type useNewFilterMapCheckStoreType = {
    newFilterMapCheckState: boolean;
    setNewFilterMapCheckState: (status: boolean) => void;
};

const initialFilterParams: IFilterParamsType = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force: 0,
    auction_master_item: [],
    auction_master_except: false,
    status: [1, 2, 5],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sort: 5,
    auction_master: [],
    except_item: [],
};

export const useNewFilterStore = create<useFilterStoreType>(
    // persist(
    //     {
    //         key: "newFilterState",
    //         allowlist: ["newFilterState"],
    //         denylist: [],
    //     },
    (set: any) =>
        ({
            newFilterState: initialFilterParams,
            setStoreNewFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        newFilterState: {
                            ...filterState,
                        },
                    };
                });
            },
        } as any),
    // ) as any,
);

export const useNewFilterMapCheckStore = create<useNewFilterMapCheckStoreType>(
    (set: any) =>
        ({
            newFilterMapCheckState: false,
            setNewFilterMapCheckState: async (status: boolean) => {
                set(() => {
                    return {
                        newFilterMapCheckState: status,
                    };
                });
            },
        } as any),
);

export const useNewFilterSearchText = create<useFilterSearchTextType>(
    (set: any) =>
        ({
            newFilterSearchText: "",
            setNewFilterSearchText: async (text: string) => {
                set(() => {
                    return {
                        newFilterSearchText: text,
                    };
                });
            },
        } as any),
);
export const useListFilterFilterStore = create<listFilterStoreType>(
    (set: any) =>
        ({
            listFilterState: "",
            setStoreListFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        ...filterState,
                    };
                });
            },
        } as any),
);
