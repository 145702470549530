import { css } from "@emotion/react";
import { ConvertPhoneNumber } from "@src/util/convertPhoneNumber";
import React from "react";
import smallPhone from "../../../assets/smallPhone.svg";
import smallMessage from "../../../assets/smallComments.svg";
import NewItem from "./NewItem";

interface CardProps {
    item: any;
    openPopup: (userPhone: string) => void;
}

const Card = ({ item, openPopup }: CardProps) => {
    return (
        <>
            <div css={cardWrap}>
                <div css={cardTitle}>
                    <span>
                        문의번호 {item?.inquiryNo}
                        <NewItem createdAt={item.createdAt} />
                    </span>

                    <span>{item?.createdAt}</span>
                </div>
                <div css={cardContents}>
                    <div css={contentsTitle}>
                        <div>
                            <span>{item?.userName}님</span>
                            <span>({ConvertPhoneNumber(item?.userPhone)})</span>
                        </div>

                        <img
                            src={item?.type === "call" ? smallPhone : smallMessage}
                            onClick={() => {
                                openPopup(item?.userPhone);
                            }}
                            alt="messageTypeImg"
                        />
                    </div>
                    {item?.itemSubId !== null ? (
                        <div css={bidContents}>
                            <div css={bidTitle}>
                                <span>{item?.bidType}</span>
                                {item?.itemSubId}
                            </div>
                            <div css={bidSubTitle}>
                                <span className="status">{item?.itemStatus}</span>
                                <span className="court">{item?.court}</span>
                                <span className="date">{item?.dueDate}</span>
                            </div>
                            <div css={bidAddress}>{item?.address}</div>
                        </div>
                    ) : (
                        <div css={nomralInquiryStyle}>일반 문의</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Card;

const cardWrap = css`
    width: 100%;
    height: fit-content;
    margin: 14px 0px;
    box-sizing: border-box;
    border: 0.6px solid #c9c9c9;
`;

const cardTitle = css`
    width: 100%;
    background-color: #edf0f6;
    padding: 4px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #3c3c3c;
        display: flex;
        align-items: center;
    }
`;

const cardContents = css`
    display: flex;
    flex-direction: column;
    padding: 8px 14px;
    box-sizing: border-box;
`;

const contentsTitle = css`
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    border-bottom: 0.6px solid #c9c9c9;
    justify-content: space-between;
    & > div {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    img {
        width: 16px;
        height: 16px;
    }
`;

const bidContents = css`
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    margin-top: 8px;
    box-sizing: border-box;
`;
const bidTitle = css`
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 4px;
    }
`;

const bidSubTitle = css`
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 4px;
    & > .status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 20px;
        border-radius: 4px;
        padding: 0px 4px;
        background-color: #f0f0f0;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #505050;
        white-space: nowrap;
    }
    & > .court {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
    }
    & > .date {
        color: #505050;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
    }
`;

const bidAddress = css`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #505050;
`;

const newInquiryCss = css`
    width: 16px;
    height: 16px;
    margin-left: 4px;
`;

const nomralInquiryStyle = css`
    height: 44px;
    display: flex;
    align-items: center;
`;
