export const itemForFilterDisposition = [
    {
        title: "전체",
        id: "0",
        isActive: true
    },
    {
        title: "매각",
        id: "1",
        isActive: false
    },
    {
        title: "임대",
        id: "2",
        isActive: false
    },
];
