import styled from "@emotion/styled";
import AiFooter from "@src/components/aiFilter/AiFooter";
import AiHeader from "@src/components/aiFilter/AiHeader";
import AiResultTotal from "@src/components/aiFilter/AiResultTotal";
import AiTitle from "@src/components/aiFilter/AiTitle";
import FilterFooter from "@src/components/filter/FilterFooter";
import PurposeFilter from "@src/components/filter/PurposeFilter";
import useGetPurpose from "@src/hooks/filter/useGetPurpose";
import { transferToNative } from "@src/lib/WebAppBridge";
import { FilterParamsType, useAiFilterStore } from "@src/store/AiFilterStore";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AiResetConfirmModal from "./AiResetConfirmModal";

const AiFilterPurpose = () => {
    const [aiSelectedPurpose, setAiSelectedPurpose] = useState<string[]>([]);
    const [resetVisible, setResetVisible] = useState(false);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const getAiFilterCount = useAiFilterStore((state) => state.getAiFilterCount);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const navigate = useNavigate();

    const { state } = useLocation() as {
        state: FilterParamsType;
    };

    useEffect(() => {
        setAiFilterState(state);
    }, []);

    //용도
    const {
        handleSelect: handlePurposeSelect,
        purposeState,
        query,
        handleSelectAll,
        onClickRemove: onClickRemovePurpose,
        resetPurpose,
    } = useGetPurpose(state?.use);

    useEffect(() => {
        getAiFilterCount({
            ...state,
            use: aiSelectedPurpose,
        });
    }, [aiSelectedPurpose, state, aiFilterCount]);

    // 용도
    const purposeArea = useMemo(
        () => (
            <FilterContainer>
                <FilterFooter
                    purposeState={purposeState}
                    onClickRemovePurpose={onClickRemovePurpose}
                    onSubmit={() => console.log("용도!")}
                    setAiSelectedPurpose={setAiSelectedPurpose}
                    type={"AI"}
                />
                <PurposeFilter
                    style={{ height: "270px" }}
                    handleSelectAll={handleSelectAll}
                    handleSelect={handlePurposeSelect}
                    purposeState={purposeState}
                    query={query}
                />
            </FilterContainer>
        ),
        [purposeState, query],
    );

    const handleSubmit = async (aiSelectedPurpose: string[]) => {
        if (isEmpty(state.use)) {
            await setAiFilterState({
                ...state,
                use: aiSelectedPurpose,
            });
        } else {
            await setAiFilterState({
                ...state,
                use: aiSelectedPurpose,
            });
        }
    };

    const onClickNext = async () => {
        await handleSubmit(aiSelectedPurpose);
        transferToNative("done", "finishAiSetting");
        transferToNative("done", "closeWebView");
    };

    const onClickReset = () => {
        setResetVisible(true);
    };

    const onClickCancel = () => {
        setResetVisible(false);
    };

    const onClickConfirm = async () => {
        await handleSubmit([]);
        handlePurposeSelect("전체", "중분류", undefined, "All");
        resetPurpose();
        setResetVisible(false);
    };

    const onClickBack = () => {
        navigate("/aiFilter/location", { state });
    };

    return (
        <>
            <Container>
                <AiHeader onClickBack={onClickBack} />
                <AiTitle>
                    <h2>
                        관심있는 <span className="color-text">용도</span>를 선택해주세요.
                    </h2>
                    <p>
                        선택한 소재지에 따라 맞춤형
                        <br />
                        매물 정보를 확인할 수 있어요
                    </p>
                </AiTitle>
                <AiResultTotal result={aiFilterCount} />
                <Border />
                {purposeArea}

                <AiFooter style={{ bottom: "0" }} isReset isNext onClickNext={onClickNext} onClickReset={onClickReset} />
            </Container>
            {resetVisible ? (
                <AiResetConfirmModal text="필터를 초기화할까요?" onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />
            ) : (
                <></>
            )}
        </>
    );
};

export default AiFilterPurpose;

const Container = styled.div`
    position: relative;
    padding: 0px 14px;
    height: 100vh;
`;

const Border = styled.div`
    width: 100vw;
    margin-left: -14px;
    height: 12px;
    background: #edf0f6;
`;

const FilterContainer = styled.div`
    position: relative;
    background: white;
    width: 100vw;
    margin-left: -14px;
    max-height: 470px;

    button + button {
        margin-left: 0px;
    }
`;
