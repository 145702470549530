import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery, useQuery } from "react-query";

const getItemsStateLandPayments = async (params: any) => {
    const reponse = await axiosInstance.get(`${axiosPath.stateLandItems}`, {
        params,
    });
    return reponse.data;
};

export const useFindAllStateLandPayments = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["itemsStateLandList"],
        queryFn: ({ pageParam }) => getItemsStateLandPayments({ ...params, page: pageParam }),
        getNextPageParam: (_lastPage: any, pages: any) => {
            return pages[0]?.totalCnt > pages?.length * 20 ? pages.length + 1 : null;
        },
        enabled,
    });
};

export const useFindAllStateLandProducts = (saleType?: string) => {
    return useQuery<IStateLandProduct>(
        ["stateLandProduct", saleType],
        async () => {
            const res = await axiosInstance
                .get(`${axiosPath.stateLandProductPath}?saleType=${saleType ?? 8}`)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("findError>>>", err);
                    throw err;
                });
            return res;
        },
        {
            enabled: false,
        },
    );
};

export const useFindAllRoadPlanProducts = (saleType?: string) => {
    return useQuery<IRoadPlanProduct>(
        ["roadPlanProduct", saleType],
        async () => {
            const res = await axiosInstance
                .get(`${axiosPath.roadPlanProductPath}?saleType=${saleType ?? 8}`)
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    console.log("findError>>>", err);
                    throw err;
                });
            return res;
        },
        {
            enabled: false,
        },
    );
};

export interface IProduct {
    displayDescription: string;
    no: number;
    code: string;
    name: string;
    nameColor: string;
    nameColorBg: string;
    type: string;
    subsGroup: string;
    subsGroupName: string;
    duplRestrict: number;
    saleType: number;
    serveDurationType: string;
    serveDuration: number;
    serveQuantity: number;
    renewTerm: string;
    price: number;
    priceIncludedVat: number;
    priceDiscountDisplay1: string;
    priceDiscountDisplay2: string;
    term1Duration: number;
    term1DiscountedPrice: number;
    term1IncludedVat: number;
    term2Duration: any;
    term2DiscountedPrice: any;
    term2IncludedVat: any;
    firstPayDelayDay: any;
    includeProductCode: any;
    includeProductIssueTerm: any;
    status: number;
    description: string;
    displayOrder: number;
    recommend: number;
    isEvent: boolean;
    showStartAt: string;
    showEndAt: string;
    subsCategory: number;
    pnus?: number[] | null;
    pnusToString?: string;
    includeProductList?: any[];
    extraData?: {
        installment?: number;
        originProductCode?: string;
    };
}

export interface IStateLandProduct {
    subsList: IProduct[];
    stateLandList: IProduct[];
}

export interface IRoadPlanProduct {
    subsList: IProduct[];
    roadPlanList: IProduct[];
}
export const initialProductData: IProduct = {
    no: 0,
    code: "",
    name: "",
    nameColor: "",
    nameColorBg: "",
    type: "",
    subsGroup: "",
    subsGroupName: "",
    duplRestrict: 0,
    saleType: 0,
    serveDurationType: "",
    serveDuration: 0,
    serveQuantity: 0,
    renewTerm: "",
    price: 0,
    priceIncludedVat: 0,
    priceDiscountDisplay1: "",
    priceDiscountDisplay2: "",
    term1Duration: 0,
    term1DiscountedPrice: 0,
    term1IncludedVat: 0,
    term2Duration: 0,
    term2DiscountedPrice: 0,
    term2IncludedVat: 0,
    firstPayDelayDay: 0,
    includeProductCode: "",
    includeProductIssueTerm: "",
    includeProductList: [],
    status: 0,
    description: "",
    displayDescription: "",
    displayOrder: 0,
    recommend: 0,
    isEvent: false,
    showStartAt: "",
    showEndAt: "",
    subsCategory: 10,
};
