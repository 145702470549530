import React, { MouseEvent, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { useAiFilterStore } from "@src/store/AiFilterStore";

interface IButton {
    label: string;
    color: string;
    size: "xSmall" | "small" | "medium" | "big";
    isDisabled?: boolean;
    handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
    deleteBtn?: boolean;
    defaultValue?: string;
    styles?: any;
    buttonRef?: any;
}

function Button({
    label,
    color,
    size = "small",
    isDisabled = false,
    handleClick = () => { },
    deleteBtn = false,
    defaultValue,
    styles,
    buttonRef,
}: IButton) {
    const [disabled, setDisabled] = useState(isDisabled);

    useEffect(() => {
        setDisabled(isDisabled);
    }, [isDisabled]);
    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <>
            <button
                ref={buttonRef}
                css={[buttonStyle, sizes[size], colors[color], styles]}
                disabled={isDisabled}
                onClick={handleClick}
                className={deleteBtn ? "deleteBtn submitBtn" : "submitBtn"}
                value={defaultValue}
                onKeyDown={onKeyDown}
            >
                {label}
            </button>
        </>
    );
}

const sizes: { [index: string]: any } = {
    xSmall: css`
        padding: 1.5px 8px 0.5px;
        border-radius: 4px;
        ${Common.textStyle.body4_R12}
    `,
    small: css`
        padding: 6px 12px;
    `,
    medium: css`
        width: 160px;
        height: 36px;
        min-width: 160px;
        font-size: 18px;
    `,
    big: css`
        width: 328px;
        height: 36px;
        min-width: 328px;
    `,
};
const colors: { [index: string]: any } = {
    white: css`
        background-image: linear-gradient(#fff, #fff), ${Common.colors.purple600};
        background-origin: border-box;
        background-clip: content-box, border-box;
        border-color: transparent;
        color: ${Common.colors.purple500};
    `,
    gray: css`
        background: ${Common.colors.grey01};
        color: ${Common.colors.black};
    `,
    gd: css`
        background: ${Common.colors.purple600};
        color: ${Common.colors.white};
    `,
    gdPurple: css`
        background: ${Common.colors.purple800};
        color: ${Common.colors.white};
    `,
    newBlue: css`
        background: ${Common.colors.aucMarkerColor};
        color: ${Common.colors.white};
    `,
    purple: css`
        background-color: ${Common.colors.purple500};
        color: ${Common.colors.white};
    `,
    orange: css`
        background-color: ${Common.colors.orange};
        color: ${Common.colors.white};
    `,
    black: css`
        background-color: ${Common.colors.black};
        color: ${Common.colors.white};
    `,
    red: css`
        background-color: ${Common.colors.error};
        color: ${Common.colors.white};
    `,
    blue: css`
        background: #2275ee;
        color: ${Common.colors.white};
    `,
    lightBlue: css`
     background: #F6FAFF;
     color: #000;
 `,
};
const buttonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.4s;
    ${Common.textStyle.title5_M14}

    &:disabled {
        ${colors.gray}
    }
`;
export default Button;
