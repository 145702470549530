import styled from "@emotion/styled";
import { Button } from "@src/components";
import Title from "@src/components/title/Title";
import { Common } from "@src/styles/Common";
import React, { useEffect, useRef, useState } from "react";
import backArrow from "../../assets/ico_arrow_back.png";
import CharginUseItem from "./CharginUseItem";
import box from "../../assets/charginUseBox.png";
import { useGetCharginList, useGetLiveCnt, useGetUsedList, useGetUseList } from "@src/hooks/deungi/useCharginUseList";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useCallToken from "@src/hooks/useCallToken";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import loading from "../../assets/Loading.json";
import { DEUNGI_OPEN_STATE, DEUNGI_STATE_ENUM, DEUNGI_TYPE_ENUM, TICKET_TYPE } from "@src/components/deungi/common/enum";
import { useTicketCount } from "@src/hooks/deungi/useTicketCount";
import DeungiTicketBox from "@src/components/deungi/DeungiTicketBox";
import DeungiHeader from "@src/components/deungi/DeungiHeader";

const CharginUseList = () => {
    const ticketBoxRef = useRef(null) as any;
    const [type, setType] = useState<string>("charge");
    const [chargeData, setChargeDate] = useState<any[]>([]);
    const [useData, setUseData] = useState<any[]>([]);
    const [totalCnt, setTotalCnt] = useState<number>(0);
    const [isMore, setIsMore] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();

    const { token } = useCallToken();

    const tryGetChargeData = async () => {
        const ticketType = (searchParams.get("ticketType") as any) || TICKET_TYPE.DEUNGI_TICKET;
        const page = Math.floor(chargeData.length / 20) + 1;
        const data = await useGetCharginList({ page: page ? page : 1, limit: 20, type: 0, ticketType });
        const totalData = await useTicketCount(ticketType);
        setTotalCnt(totalData.data.curHaveCnt);

        if (chargeData.length + data.data.list.length === data.data.totalCnt) {
            setIsMore(false);
        } else {
            setIsMore(true);
        }

        setIsLoading(false);
        if (chargeData.length) {
            setChargeDate(chargeData.concat(data.data.list));
        } else {
            setChargeDate(data.data.list);
        }
    };

    const tryGetUseData = async () => {
        const ticketType = (searchParams.get("ticketType") as any) || TICKET_TYPE.DEUNGI_TICKET;
        const page = Math.floor(useData.length / 20) + 1;
        const data = await useGetUsedList();
        const totalData = await useTicketCount(ticketType);
        setTotalCnt(totalData.data.curHaveCnt);

        if (useData.length + data.data.list.length === data.data.totalCnt) {
            setIsMore(false);
        } else {
            setIsMore(true);
        }

        setIsLoading(false);
        if (useData.length) {
            setUseData(useData.concat(data.data.list));
        } else {
            setUseData(data.data.list);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        setUseData([]);
        setChargeDate([]);
        if (type === "charge") {
            tryGetChargeData();
        } else {
            tryGetUseData();
        }
    }, [type]);

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: token.length ? isMore : false,
        onLoadMore: type === "charge" ? tryGetChargeData : tryGetUseData,
        disabled: false,
        rootMargin: "0px 0px 0px 0px",
        delayInMs: 1000,
    });

    return (
        <>
            <StyledCharginUseForm>
                <DeungiHeader title="충전/사용내역" />
                <DeungiTicketBox ticketBoxRef={ticketBoxRef} />
                <div className="buttonWrap">
                    <Button
                        label="충전내역"
                        color="blue"
                        size="small"
                        handleClick={() => {
                            setIsLoading(true);
                            setType("charge");
                        }}
                        styles={{
                            borderBottom: type === "charge" ? "4px solid #0047FF" : "4px solid #DADADA",
                            color: type === "charge" ? "#0047FF" : "#8D8D8D",
                            fontWeight: type === "charge" ? 700 : 500,
                            transition: "all 0s",
                        }}
                    />
                    <Button
                        label="사용내역"
                        color="blue"
                        size="small"
                        handleClick={() => {
                            setIsLoading(true);
                            setType("use");
                        }}
                        styles={{
                            borderBottom: type === "use" ? "4px solid #0047FF" : "4px solid #DADADA",
                            color: type === "use" ? "#0047FF" : "#8D8D8D",
                            fontWeight: type === "use" ? 700 : 500,
                            transition: "all 0s",
                        }}
                    />
                </div>
                <div className="list">
                    {type === "charge" ? (
                        chargeData.length ? (
                            chargeData.map((item: any, idx) => {
                                return <CharginUseItem key={item + idx} type={type} item={item} />;
                            })
                        ) : isLoading ? (
                            <StyledLottieDiv>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </StyledLottieDiv>
                        ) : (
                            <StyledEmptyList>
                                <img src={box} alt="" />
                                <div>충전내역이 없어요</div>
                            </StyledEmptyList>
                        )
                    ) : useData.length ? (
                        useData.map((item: any, idx) => {
                            return <CharginUseItem key={item + idx} type={type} item={item} />;
                        })
                    ) : isLoading ? (
                        <StyledLottieDiv>
                            <div>
                                <Lottie animationData={loading} />
                            </div>
                        </StyledLottieDiv>
                    ) : (
                        <StyledEmptyList>
                            <img src={box} alt="" />
                            <div>사용내역이 없어요</div>
                        </StyledEmptyList>
                    )}
                    <div className="observed" ref={sentryRef} />
                </div>
            </StyledCharginUseForm>
        </>
    );
};

export default CharginUseList;

const StyledCharginUseForm = styled.div`
    & > .titleWrap {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 14px;
        align-items: center;

        & > .backBtn {
            position: absolute;
            left: 14px;
            width: 10px;
            height: 17px;
            & > img {
                width: 100%;
                height: 100%;
            }
        }
        & > .title {
            font-weight: 700;
        }
    }
    & > .buttonWrap {
        display: flex;
        justify-content: center;
        padding: 0 0 14px 0;
        width: 100%;

        & > button {
            width: 50%;
            height: 52px;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            background: #fff;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }
    }

    & > .list {
        overflow-y: scroll;
        height: calc(calc(var(--vh, 1vh) * 100) - 192px);
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-bottom: 50px;

        & > .observed {
            height: 30px;
            width: 100%;
        }
    }
    & > .list::-webkit-scrollbar {
        display: none;
    }
`;

const StyledEmptyList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    align-items: center;
    gap: 24px;

    & > img {
        width: 125px;
        height: 84px;
    }
    & > div {
        color: ${Common.colors.normalTextColor};
        font-size: 17px;
        font-weight: 400;
    }
`;

const StyledLottieDiv = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;
        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;
