import { SelectedExistDeungiRequestDto, SubmitDeungiRequestDto } from "@src/hooks/deungi/dto/Deungi.dto";
import create from "zustand";
import { persist } from "zustand/middleware";

interface IuseDeungiPinStore {
    deungiPinState: string;
    setDeungiPinState: (state: string) => void
}

interface IuseDeungiSearchText {
    deungiSearchText: string;
    setDeungiSearchText: (state: string) => void;
}


interface IuseSelectedDeungi {
    selectedDeungi: SubmitDeungiRequestDto;
    setSelectedDeungi: (state: SubmitDeungiRequestDto) => void;
}

interface IuseOpenDeungi {
    openDeungi: boolean;
    setOpenDeungi: (state: boolean) => void;
}


interface IuseSelectedExistDeungi {
    selectedExistDeungi: SelectedExistDeungiRequestDto;
    setSelectedExistDeungi: (state: SelectedExistDeungiRequestDto) => void;
}

export const useDeungiPinStore = create(
    persist<IuseDeungiPinStore>(
        (set) => ({
            deungiPinState: '',
            setDeungiPinState: (state: string) => set({ deungiPinState: state }),
        }),
        {
            name: 'deungiPinStorage'
        }
    )
);

export const useSelectedDeungi = create(
    persist<IuseSelectedDeungi>(
        (set) => ({
            selectedDeungi: {
                address: "",
                pin: "",
                deungi_type: "",
                item_type: "",
            },
            setSelectedDeungi: (state: SubmitDeungiRequestDto) => set({ selectedDeungi: state }),
        }),
        {
            name: "deungiSelectedStorage"
        }
    )
);

export const useOpenDeungi = create(
    persist<IuseOpenDeungi>(
        (set) => ({
            openDeungi: false,
            setOpenDeungi: (state: boolean) => set({ openDeungi: state }),
        }),
        {
            name: "deungiOpenStorage"
        }
    )
);

export const useSelectedExistDeungi = create(
    persist<IuseSelectedExistDeungi>(
        (set) => ({
            selectedExistDeungi: {
                address: "",
                addressIdx: "",
                deungi_type: "",
                id: "",
                myId: "",
                item_type: "",
                itemType: "",
                pin: "",
                type: "",
                updatedAt: "",
            },
            setSelectedExistDeungi: (state: SelectedExistDeungiRequestDto) => set({ selectedExistDeungi: state }),
        }),
        {
            name: "deungiExistStorage"
        }
    )
);


export const useDeungiSearchText = create(
    persist<IuseDeungiSearchText>(
        (set) => ({
            deungiSearchText: '',
            setDeungiSearchText: (state: string) => set({ deungiSearchText: state }),
        }),
        {
            name: 'deungiSearchTextStorage'
        }
    )
);