import useIsLive from "@src/hooks/useIsLive";
import React from "react";

interface MarkerProps {
    type: "top" | "mid" | "bot" | "item" | "low" | "multi" | "real" | "realDetail" | "expGroup" | "expBot";
    displayName: string;
    count: number;
    item?: any;
    isSearch?: boolean;
    real: null | any;
    markerType: "product" | "real" | "expected";
    use?: string;
}
const matchBidType = (type: 1 | 2 | 3 | 4 | 5 | 6) => {
    let bidType;
    switch (type) {
        case 1:
            bidType = "경매";
            break;
        case 2:
            bidType = "공매";
            break;
        case 3:
            bidType = "공매임대";
            break;
        case 4:
            bidType = "지분경매";
            break;
        case 5:
            bidType = "지분공매";
            break;
        case 6:
            bidType = "예정";
            break;
    }
    return bidType;
};
const Marker = ({ type, displayName, count, item, isSearch = false, real, markerType, use }: MarkerProps): any => {
    const isLive = useIsLive();

    let bidType = matchBidType(item?.bid_type_detail);

    let squareText = item?.square ? `${item?.square}평` : "";

    if (type === "top") {
        return (
            <div className="top marker_clu">
                <p className="marker_clu_region">{displayName}</p>
                <p className="marker_clu_count">{count}건</p>
            </div>
        );
    }
    if (type === "mid") {
        return (
            <div className="mid marker_clu">
                <p className="marker_clu_region">{displayName}</p>
                <p className="marker_clu_count">{count}건</p>
            </div>
        );
    }
    if (type === "bot") {
        return (
            <div className="bot marker_clu">
                <p className="marker_clu_region">{displayName}</p>

                <p className="marker_clu_count">{count}건</p>
            </div>
        );
    }
    if (type === "item" && bidType !== undefined) {
        return (
            <div className={isSearch ? `detailMarker ${bidType} searchMarker` : `detailMarker ${bidType}`}>
                <div className="marker_detail_container marker_detail_top">
                    <p className="marker_detail_bidType">
                        {/^(매각|취하|변경|기각|기타)$/.test(item?.status) ? item?.status : bidType}
                    </p>
                    {bidType !== "예정" && <p className="marker_detail_price">{item?.min_price_marker}</p>}
                </div>
                <div className="marker_detail_container marker_detail_top marker_detail_btm">
                    {item?.use && <p className="marker_detail_use">{item?.use}</p>}
                </div>
                <div className="marker_detail_container marker_detail_btm">
                    {bidType === "예정" && item?.claim_amount_marker !== "비공개" && item.claim_amount_marker !== "" ? (
                        <div className="maker_detail_claim">
                            <span className="marker_detail_amount">청구금액</span>
                            <p className="marker_detail_price">{item?.claim_amount_marker}</p>
                        </div>
                    ) : null}
                    {bidType === "예정" ? null : !!squareText && <p className="marker_detail_square">{squareText}</p>}
                </div>
            </div>
        );
    }
    if (type === "low") {
        return (
            <div className={`detailMarker ${bidType}`}>
                <div className="marker_detail_count">{count}</div>
                <div className="marker_detail_container marker_detail_top">
                    <p className="marker_detail_bidType">
                        {/^(매각|취하|변경|기각|기타)$/.test(item?.status) ? item?.status : bidType}
                    </p>
                    {bidType !== "예정" && <p className="marker_detail_price">{item?.min_price_marker}</p>}
                </div>
                <div className="marker_detail_container marker_detail_top marker_detail_btm">
                    <p className="marker_detail_use">{item?.use}</p>
                </div>
                <div className="marker_detail_container marker_detail_btm">
                    {bidType === "예정" && item?.claim_amount_marker !== "비공개" && item.claim_amount_marker !== "" ? (
                        <div className="maker_detail_claim">
                            <span className="marker_detail_amount">청구금액</span>
                            <p className="marker_detail_price">{item?.claim_amount_marker}</p>
                        </div>
                    ) : null}
                    {bidType === "예정" ? null : !!squareText && <p className="marker_detail_square">{squareText}</p>}
                    {/* <p className="marker_detail_square">{squareText}</p> */}
                </div>
            </div>
        );
    }
    if (type === "multi") {
        //경공매-실거래 모두 있는 경우, 경공매 마커를 우선 노출함
        const { area, contract_date, price, type_str } = real;
        return (
            <>
                {markerType === "product" ? (
                    <>
                        <button className={`convertMarkerType ${bidType}`}>실거래 보기</button>
                        <div className={`detailMarker ${bidType} 멀티`}>
                            <div className={`${bidType}`}>
                                <div className="marker_detail_container marker_detail_top">
                                    <p className="marker_detail_bidType">
                                        {/^(매각|취하|변경|기각|기타)$/.test(item?.status) ? item?.status : bidType}
                                    </p>
                                    <p className="marker_detail_price">{item?.min_price_marker}</p>
                                </div>
                                <div className="marker_detail_container marker_detail_top marker_detail_btm">
                                    <p className="marker_detail_use">{item?.use}</p>
                                </div>
                                <div className="marker_detail_container marker_detail_btm">
                                    {bidType === "예정" && (
                                        <>
                                            {item.claim_amount_marker === "비공개" ? null : (
                                                <div className="maker_detail_claim">
                                                    <span className="marker_detail_amount">청구금액</span>
                                                    <p className="marker_detail_price">{item?.claim_amount_marker}</p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {bidType === "예정" ? null : !!squareText && <p>{squareText}</p>}
                                    {/* {bidType === "예정" ? null : <p className="marker_detail_square">{squareText}</p>} */}
                                    {/* <p className="marker_detail_square">{squareText}</p> */}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <button className="convertMarkerType_real">매물 보기</button>
                        <div className={`실거래 real`}>
                            <div className={`detail detailMarker real`}>
                                <div className="marker_real_container marker_real_top">
                                    <p className="marker_real_bidType">실거래가</p>
                                    <p className="marker_real_use">{type_str}</p>
                                </div>
                                <div className="marker_real_container marker_real_btm">
                                    <p className="marker_real_price">{price}</p>
                                    <p className="marker_real_area">{area}</p>
                                    <p className="marker_real_contract_date">계약일 {contract_date}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
    if (type === "real") {
        if (real !== null) {
            const { contract_date2, price, type } = real;
            return (
                <div className={`실거래`}>
                    <div className={`summ type${type}`}>
                        <p className="marker_real_price">{price}</p>
                        <p className="marker_real_contract_date2">{contract_date2}</p>
                    </div>
                </div>
            );
        }
    }
    if (type === "realDetail") {
        if (real !== null) {
            const { area, contract_date, price, type_str } = real;
            return (
                <div className={`실거래`}>
                    <div className={`detail detailMarker`}>
                        <div className="marker_real_container marker_real_top">
                            <p className="marker_real_bidType">실거래가</p>
                            <p className="marker_real_use">{type_str}</p>
                        </div>
                        <div className="marker_real_container marker_real_btm">
                            <p className="marker_real_price">{price}</p>
                            <p className="marker_real_area">{area}</p>
                            <p className="marker_real_contract_date">계약일 {contract_date}</p>
                        </div>
                    </div>
                </div>
            );
        }
    }
    if (type === "expGroup") {
        return (
            <div className={`expGroup`}>
                <div className="marker_detail_container marker_detail_center">
                    <div className="marker_detail_container marker_detail_use">
                        <p className="marker_detail_bidType">예정</p>
                        <p className="marker_detail_use">{use}</p>
                    </div>
                    <p className="marker_detail_use" style={{ marginTop: "4px" }}>
                        {count}건
                    </p>
                </div>
            </div>
        );
    }
    if (type === "expBot") {
        return (
            <div className={`expBot`}>
                <div className="marker_detail_container marker_detail_center">
                    <div className="marker_detail_bidType_bold">예정매물</div>
                    <span className="marker_detail_use">{displayName}</span>
                    <p className="marker_detail_use" style={{ marginTop: "4px" }}>
                        {count}건
                    </p>
                </div>
            </div>
        );
    }
    return <div />;
};

export default Marker;
