import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const fetchData = async (params?: string): Promise<getCaseNumberSearchedList> => {
    const response = await axiosInstance.get(`${axiosPath.caseNumberList}/${params}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload.result || {};

    return responseData;
};
const useGetClickSearchItemCaseNumber = async (params: any) => {
    return await fetchData(params);
};

export type GetCallSearchedListResponseType = {
    errorCode: number;
    list: getCaseNumberSearchedList[];
    total: number;
};

export type getCaseNumberSearchedList = {
    item_id: string;
    sub_id: string;
    bid_type: number;
    bid_type_str: string;
    address: string;
    use: string;
    min_price: string;
    min_price_marker: string;
    min_price_detail: string;
    lntd: number;
    latd: number;
    square: number;
    view_count: number;
};
export { useGetClickSearchItemCaseNumber };
