import styled from "@emotion/styled";
import Title from "@src/components/title/Title";
import { Common } from "@src/styles/Common";
import backArrow from "../../assets/ico_arrow_back.png";
import React, { useEffect, useRef, useState } from "react";
import TicketCancelItem from "./TicketCancelItem";
import { useFindAllTicketPayments, useGetCharginList, useRefundTicket } from "@src/hooks/deungi/useCharginUseList";
import box from "../../assets/charginUseBox.png";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import PopUp from "@src/components/popup/PopUp";
import CancelModal from "./CancelModal";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useCallToken from "@src/hooks/useCallToken";
import { isEmpty, toPath } from "lodash";
import { transferToNative } from "@src/lib/WebAppBridge";
import Lottie from "lottie-react";
import loading from "../../assets/Loading.json";
import { useSaverPgRefund } from "@src/hooks/payment/useRefund";

export interface ToastType {
    showToast: boolean;
    toastMessage: string;
}

const DeungiTicketCancelList = () => {
    const [cancelData, setCancelData] = useState<any[]>([]);
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [toast, setToast] = useState<ToastType>({ showToast: false, toastMessage: "" });
    const [refundId, setRefundId] = useState<number>(0);
    const [totalCnt, setTotalCnt] = useState<number>(0);
    const [styleHeight, setStyleHeight] = useState<number>(318);
    const [isMore, setIsMore] = useState<boolean>(false);
    const guideTextRef = useRef(null) as any;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isStoreModalShow, setIsStoreModalShow] = useState<boolean>(false);
    const [isNotPossibleModalShow, setIsNotPossibleModalShow] = useState<boolean>(false);

    const { token } = useCallToken();

    useEffect(() => {
        if (token) {
            setIsLoading(true);
            tryGetCancel();
            setStyleHeight(guideTextRef.current?.offsetHeight + 56);
        }
    }, [token]);

    const tryGetCancel = async (reset: boolean = false) => {
        if (reset) {
            const data = await useGetCharginList({ page: 1, limit: 20, type: 1 });
            setTotalCnt(data.data?.totalCnt);
            setCancelData(data.data?.list);
        } else {
            const page = Math.floor(cancelData?.length / 20) + 1;

            const data = await useGetCharginList({ page: page ? page : 1, limit: 20, type: 1 });
            setTotalCnt(data.data?.totalCnt);

            if (cancelData?.length + data.data.list?.length === data.data?.totalCnt) {
                setIsMore(false);
            } else {
                setIsMore(true);
            }
            setIsLoading(false);
            if (cancelData?.length) {
                setCancelData(cancelData.concat(data.data?.list));
            } else {
                setCancelData(data.data?.list);
            }
        }
    };

    const tryRefundTicket = async (id: number) => {
        const response = await useRefundTicket(id);
        return response;
    };

    const { data, refetch, fetchNextPage, hasNextPage, isFetching } = useFindAllTicketPayments(
        {
            page: 1,
            limit: 20,
        },
        true,
    );
    // const refundOrder = useSaverPgRefund(
    //     () => {
    //         refetch();
    //         onCloseDialog();
    //         queryClient.invalidateQueries({
    //             queryKey: ["regionAdsPaymentItems"],
    //         });
    //     },
    //     (err) => {
    //         setSelectedPayment(null);
    //         const newError: any = err.response?.data;
    //         setErrorMessage(newError?.message);
    //         setDialogType("RefundFailed");
    //     },
    // );

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: token.length ? isMore : false,
        onLoadMore: tryGetCancel,
        disabled: false,
        rootMargin: "0px 0px 0px 0px",
        delayInMs: 1000,
    });

    useEffect(() => {
        if (toast.showToast) {
            setTimeout(() => setToast({ showToast: false, toastMessage: "" }), 2500);
        }
    }, [toast]);

    const StoreModal = (
        <StyledStoreModal>
            <div className="title">취소 불가</div>
            인앱 스토어 결제의 경우
            <br />
            취소/환불은 APP Store, Google Play 스토어 앱에서 가능합니다
        </StyledStoreModal>
    );
    const NotPossibleModal = (
        <StyledStoreModal>
            <div className="title">취소 불가</div>
            열람권 패키지 일부를 사용하거나 구매한 지
            <br />
            7일이 지난 결제내역입니다
        </StyledStoreModal>
    );

    return (
        <>
            <StyledCancelForm styleHeight={styleHeight}>
                <div className="titleWrap">
                    <div
                        className="backBtn"
                        onClick={() => {
                            transferToNative("done", "closeWebView");
                        }}
                    >
                        <img src={backArrow} alt="" />
                    </div>
                    <Title title="열람권 취소/환불하기" size="large" />
                </div>
                <div className="list">
                    <div className="listItemDiv">
                        {cancelData !== null && cancelData?.length ? (
                            cancelData.map((item: any, idx: number) => (
                                <TicketCancelItem
                                    key={item + idx}
                                    item={item}
                                    setIsModalShow={setIsModalShow}
                                    setRefundId={setRefundId}
                                    setToast={setToast}
                                    setIsStoreModal={setIsStoreModalShow}
                                    setIsNotPossibleModalShow={setIsNotPossibleModalShow}
                                />
                            ))
                        ) : isLoading ? (
                            <StyledLottieDiv>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </StyledLottieDiv>
                        ) : (
                            cancelData !== null && (
                                <StyledEmptyList styleHeight={styleHeight}>
                                    <img src={box} alt="" />
                                    <div>충전된 열람권이 없어요</div>
                                </StyledEmptyList>
                            )
                        )}

                        <div className="observed" ref={sentryRef} />
                    </div>
                    <StyledGuideText ref={guideTextRef}>
                        실시간 등기열람권 충전 환불/취소 안내
                        <br /> - 열람권은 사용하지 않은 경우에 한 해, 구매 후 7일 이내에 자주묻는질문에서 직접 구매 취소할 수
                        있습니다.
                        <br /> - 구매 후 7일 이내임에도 {"‘내정보>고객센터>자주묻는질문>열람권 취소/환불하기’"}에 {"'취소 불가'"}
                        상태라면 다음 중 하나 이상의 경우에 해당합니다.
                        <br />
                        1) 열람권 패키지의 일부를 사용하여서 직접 환불이 불가능한 경우
                        <br /> 2) Google Play 결제를 통해 구매한 경우 <br />
                        3) iOS APP Store 결제를 통해 구매한 경우 <br />- 위 경우에 해당하는 결제 건의 구매 취소를 희망할 경우,
                        열람권 유효 기간 내에 고객센터로 문의해 주세요.
                        <br /> - 단, iOS APP Store 결제로 구매한 열람권의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.
                        <br /> - Google Play 결제로 구매한 열람권은 구매 후 48시간 이내에는 Google Play 고객센터를 통해, 48시간
                        이후에는 고객센터를 통해 구매 취소할 수 있습니다.
                        <br /> - 구매 취소나 환불 시에는 결제한 수단으로 환불됩니다.
                        <br /> - 구독 혜택 등 무료로 지급된 열람권은 환불 대상에서 제외됩니다.
                    </StyledGuideText>
                </div>

                {isModalShow && (
                    <CancelModal
                        setIsModalShow={setIsModalShow}
                        tryRefundTicket={tryRefundTicket}
                        refundId={refundId}
                        tryGetCancel={tryGetCancel}
                        setToast={setToast}
                        setCancelData={setCancelData}
                        setIsLoading={setIsLoading}
                    />
                )}
                {isStoreModalShow && (
                    <ConfirmModal
                        resultMessage={StoreModal}
                        buttonText="확인"
                        onConfirm={() => {
                            setIsStoreModalShow(false);
                        }}
                    />
                )}
                {isNotPossibleModalShow && (
                    <ConfirmModal
                        resultMessage={NotPossibleModal}
                        buttonText="확인"
                        onConfirm={() => {
                            setIsNotPossibleModalShow(false);
                        }}
                    />
                )}

                <StyledToast isShowToast={toast.showToast}>{toast.toastMessage}</StyledToast>
            </StyledCancelForm>
        </>
    );
};

export default DeungiTicketCancelList;

const StyledCancelForm = styled.div<{ styleHeight: number }>`
    position: relative;
    /* height: 100vh;
    overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .titleWrap {
        display: flex;
        justify-content: center;
        position: fixed;
        background: #ffffff;
        width: 100%;
        z-index: 22;
        padding: 14px;
        align-items: center;
        & > .backBtn {
            position: absolute;
            left: 14px;
            width: 10px;
            height: 17px;
            & > img {
                width: 100%;
                height: 100%;
            }
        }
        & > .title {
            font-weight: 700;
        }
    }
    & > .list {
        width: 100%;
        overflow-y: scroll;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        padding-top: 56px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        position: relative;
        & > .observed {
            height: 30px;
            width: 100%;
        }
        & > .listItemDiv {
            min-height: ${(props) => `calc(calc(var(--vh, 1vh) * 100) - ${props.styleHeight}px)`};
        }
    }
    & > .list::-webkit-scrollbar {
        display: none;
    }
`;
const StyledLottieDiv = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const StyledGuideText = styled.div`
    background-color: ${Common.colors.grey01};
    color: ${Common.colors.greyPrimary};
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 20px 14px;
    margin-top: 30px;
`;

const StyledEmptyList = styled.div<{ styleHeight: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    gap: 24px;
    height: ${(props) => `calc(calc(var(--vh, 1vh) * 100) - ${props.styleHeight}px)`};
    & > img {
        width: 125px;
        height: 84px;
    }
    & > div {
        color: ${Common.colors.normalTextColor};
        font-size: 17px;
        font-weight: 400;
    }
`;

const StyledToast = styled.div<{ isShowToast: boolean }>`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${(props) => (props.isShowToast ? 0.7 : 0)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 50px;
    border-radius: 14px;
    position: absolute;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
`;

const StyledStoreModal = styled.div`
    width: 100%;
    text-align: center;
    line-height: 24px;
    ${Common.textStyle.body3_R14};
    & > .title {
        ${Common.textStyle.body2_R16};
        margin-bottom: 8px;
    }
`;
