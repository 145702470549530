import { axiosInstance } from "@src/api/axiosConfig";
import { IStoreGoStopFilterType } from "./dto/storeGoStop.dto";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";

const fetchStoreGoStop = async (params: IStoreGoStopFilterType) => {
    const response = await axiosInstance
        .post(`${axiosPath.storeGoStopList}`, params, {
            timeout: 10000,
        })
        .then((res) => {
            if (!res?.status) {
                throw new Error("Problem fetching data");
            }
            const responseData = res?.data ?? [];
            return responseData;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return err;
        });
    return response;
};

export const useStoreGoStopList = (filters: any, enabled: boolean = false) => {
    filters?.invest_price === 0 && delete filters?.invest_price;
    return useInfiniteQuery<any>(
        ["storeGoStopList", filters],
        ({ pageParam }) => fetchStoreGoStop({ ...filters, cursor: pageParam ?? filters.cursor }),
        {
            getNextPageParam: (lastPage: any, pages: any) => {
                const newPage = pages?.[0]?.totalCnt > pages?.length * 20 ? lastPage.cursor : undefined;
                return newPage;
            },
            enabled,
        },
    );
};

export const useGoStopAuctionDetail = (auctionId: string, enabled: boolean = false) => {
    const fetchGoStopDetail = async () => {
        const response = await axiosInstance.get(`${axiosPath.goStopDetail}/${auctionId}`, {
            timeout: 10000,
        });
        // if (!response.status) throw new Error('Problem fetching data');
        return response?.data;
    };

    return useQuery(
        [
            'goStopDetail',
            auctionId,
            typeof window !== 'undefined' && localStorage?.getItem('userSession')
                ? JSON.parse(localStorage?.getItem('userSession') ?? '')?.value?.accessToken
                : '',
        ],
        fetchGoStopDetail,
        {
            enabled,
            retry: 1,
        },
    );
};

const getUserSubs = async () => {
    const response = await axiosInstance.get(`${axiosPath.userSubs}`);
    return response.data;
};

export const useUserSubs = () => {
    return useQuery("userSubs", getUserSubs);
};

export const useSaverStoreGoStopFilter = (
    onSuccess: (v: any) => void = () => { },
    onError: (v: any) => void = (err) => {
        console.log("err");
    },
) => {
    return useMutation(
        async (params: any) => {
            const param = {
                key: "storeGoStopFilter",
                value: JSON.stringify(params),
            };
            try {
                const res = axiosInstance.post(axiosPath.inventory, param);
                return res;
            } catch (error) {
                console.log("saverError", error);
                throw error;
            }
        },
        {
            onSuccess,
            onError,
        },
    );
};

const getInventoryInfo = async (type: string) => {
    try {
        const response = await axiosInstance.get(`${axiosPath.inventory}?key=${type}`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const useGetStoreGoStopFilters = (type: string) => {
    return useQuery(["infoStoreGoStopFilters", type], () => getInventoryInfo(type));
};
