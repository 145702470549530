import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import DeungiListBox from "./DeungiListBox";
import DeungiSearchBox from "./DeungiSearchBox";

type DeungiContentsPropsType = {
    handleSelect: string
};

const DeungiContents = ({ handleSelect}: DeungiContentsPropsType) => {
    return (
        <>
            { handleSelect === 'search' ? 
                <DeungiSearchBox /> : 
                <DeungiListBox /> 
            }
        </>
    );
};
export default DeungiContents;
