import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import React, { useState } from "react";

const useGetAiItemList = () => {
    const [data, setData] = useState<AiItemListType[]>(initialState);
    const [totalData, setTotalData] = useState<number>(0);

    const fetchData = async (filter: any /*next_it: string | undefined*/) => {
        const response = await axiosInstance.get(`${axiosPath.aiItemList}`, {
            params: { filter: JSON.stringify(filter) /*next_it: "2022-12-29T00:51:59.175Z" next_it: { next_it }*/ },
        });

        if (response.data.payload.errCode !== 0) throw new Error("error: realPriceList fetch");
        const responseData = response.data.payload.result;
        if (responseData.length === 0) {
            return [];
        }

        return responseData;
    };

    // const getTotalItemCount = async (filter: any) => {
    //     const response = await axiosInstance.get(`${axiosPath.aiItemList}/itemscount`, {
    //         params: { filter: JSON.stringify(filter) },
    //     });
    //     if (response.data.payload.errCode !== 0) throw new Error("error: realPriceList fetch");
    //     const responseData = response.data.payload.data;

    //     return responseData;
    // };

    return { data, setData, fetchData, setTotalData, totalData };
};

const initialParams: any = {
    filter: {
        type: "0",
        price: "",
        loc: [""],
        use: [""],
    },
    next_it: "",
};

const deleteAiFilterState = async () => {
    const response = await axiosInstance.delete(`${axiosPath.saveAiFilter}`);

    if (response.data.payload.errCode !== 0) throw new Error("error: realPriceList fetch");
    const responseData = response.data.payload.result;

    return responseData;
};

export const initialState = [
    {
        item_id: "",
        bid_type: 0,
        bid_type_str: "",
        use: "",
        skp_cnt: 0,
        skp_cnt_str: "",
        image: "",
        address: "",
        mid_adrs: "",
        est_price: 0,
        est_price_str: "",
        min_price: 0,
        min_price_str: "",
        rate: "",
        status: "",
        d_day: "",
        due_date_str: "",
        due_date: 0,
        somethings: [],
        updated_at: "",
        next_it: "",
    },
];

export type AiItemListType = {
    item_id: string;
    bid_type: number;
    bid_type_str: string;
    use: string;
    skp_cnt: number;
    skp_cnt_str: string;
    image: string;
    address: string;
    mid_adrs: string;
    est_price: number;
    est_price_str: string;
    min_price: number;
    min_price_str: string;
    rate: string;
    status: string;
    d_day: string;
    due_date_str: string;
    due_date: number;
    somethings: string[];
    updated_at: string;
    next_it: string;
};

export { useGetAiItemList, initialParams, deleteAiFilterState };
