import React, { useState } from "react";
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";
import StateLandFilterHeader from "./StateLandFilterHeader";

interface Props {}

export const useFilterList = [
    { title: "전체", value: "" },
    { title: "대지", value: "대지" },
    { title: "농지", value: "농지" },
    { title: "임야", value: "임야" },
    { title: "도로", value: "도로" },
    { title: "잡종지", value: "잡종지" },
    { title: "과수원", value: "과수원" },
    { title: "공장용지", value: "공장용지" },
    { title: "창고용지", value: "창고용지" },
    { title: "목장용지", value: "목장용지" },
    { title: "주차장", value: "주차장" },
    { title: "묘지", value: "묘지" },
    { title: "학교용지", value: "학교용지" },
    { title: "체육용지", value: "체육용지" },
    { title: "종교용지", value: "종교용지" },
    { title: "구거", value: "구거" },
    { title: "유지", value: "유지" },
    { title: "제방", value: "제방" },
    { title: "하천", value: "하천" },
    { title: "염전", value: "염전" },
    { title: "주유소용지", value: "주유소용지" },
    { title: "기타", value: "기타" },
];

const UseFilter: React.FC<Props> = () => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);

    const onClickFilter = (filter: string) => {
        if (stateLandFilter.usage.some((v) => v === filter)) {
            const newOwnedBy = [...stateLandFilter.usage].filter((owned) => owned !== filter);
            setStateLandFilter({
                ...stateLandFilter,
                usage: newOwnedBy,
            });
        } else {
            const newOwnedBy = [...stateLandFilter.usage, filter];
            newOwnedBy.length === useFilterList.length
                ? setStateLandFilter({ ...stateLandFilter, usage: [] })
                : setStateLandFilter({
                      ...stateLandFilter,
                      usage: newOwnedBy,
                  });
        }
    };

    return (
        <>
            <StateLandFilterHeader title="지목" />
            <Grid container css={filterBoxStyle} spacing={1}>
                {useFilterList.map((filter, idx) => (
                    <Grid item xs={3} key={filter.title}>
                        <button
                            css={filterButtonStyle({
                                active: idx
                                    ? stateLandFilter.usage?.some((val) => val === filter.title)
                                    : !stateLandFilter.usage.length,
                                idx: idx,
                            })}
                            onClick={() => {
                                idx
                                    ? onClickFilter(filter.title)
                                    : setStateLandFilter({
                                          ...stateLandFilter,
                                          usage: [],
                                      });
                            }}
                        >
                            {filter.title}
                        </button>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default UseFilter;

const filterButtonStyle = ({ active, idx }: { active: boolean; idx: number }) => css`
    padding: 8px 4px;
    border-radius: 4px;
    background-color: ${active ? (idx === 4 ? "#63CF12" : "#FF6933") : "#F0F0F0"};
    color: ${active ? "#ffffff" : "#0C002C"};
    font-weight: ${active ? 700 : 400};
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
`;

const filterBoxStyle = css`
    width: 100%;
    padding: 0 14px 24px;
`;
