import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DeungiSearchBox from "@src/components/deungi/DeungiSearchBox";
import DeungiAlarmHeader from "@src/components/deungi/DeungiAlarmHeader";

const DeungiAlarmSearch = () => {
    return (
        <Container>
            <DeungiAlarmHeader title="등기 변동 알림 신청" />
            <DeungiSearchBox type={"alarm"} />
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: 100dvh;
`;

export default DeungiAlarmSearch;
