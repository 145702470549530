const basePath = process.env.REACT_APP_API_URL;
const nestPath = process.env.REACT_APP_NEST_API_URL;
const mapList = `${basePath}/items/v3/map/list`;
const itemSearch = `${basePath}/items/search`;
const mapAggr = `${basePath}/items/v3/map/aggr`;
const login = `${basePath}/auth/login`;
const items = `${basePath}/items/v3/searchList`;
const itemsV2 = `${nestPath}/items/totalSearch`;
const itemsV3 = `${nestPath}/datamap/web/tabListElastic`;
const searchedList = `${basePath}/user/kvinventory`;
const caseNumberList = `${basePath}/items/v3/search`;
const filterData = `${basePath}/user/filter/map`;
const postFilter = `${basePath}/items/v3/list`;
const saveFilter = `${basePath}/user/filter`;
const saveAiFilter = `${basePath}/user/recommend`;
const itemsCount = `${basePath}/user/recommend/itemscount`;
const aiCount = `${basePath}/user/recommend/itemscount`;
const filterHistory = `${basePath}/user/v3/filter/history`;
const removeFilterHistory = `${basePath}/user/v3/filter/history`;
const realPrice = `${basePath}/map/realprice`;
const aiItemList = `${basePath}/user/recommend/items`;
const mapRealPriceAggr = `${basePath}/items/v3/map/aggregate`;
const mapFieldDetail = `${basePath}/items/v3/getLotDetails`;
const refundTicket = `${basePath}/payment/refund/ticket`;
const RecentFilter = `${basePath}/user/v4/filter/history`;

const bannerList = `${nestPath}/banner/items`;
const searchList = `${nestPath}/deungi/search`;
const deungiList = `${nestPath}/deungi/items`;
const deungiListByPnu = `${nestPath}/deungi/pnu/items`;
const deungiCountByPnu = `${nestPath}/deungi/pnu/count`;
const ticketCount = `${nestPath}/deungi/count`;
const prevDeungi = `${nestPath}/v2/deungi/prev`;
const deungiInfo = `${nestPath}/v2/deungi`;
const deungiPdf = `${nestPath}/deungi/pdf`;
const deungiOwner = `${nestPath}/deungi/owner`;
const checkDeungi = `${nestPath}/deungi/check`;
const deungiAlarm = `${nestPath}/deungi/reading`;
const submitDeungi = `${nestPath}/deungi`;
const submitExistDeungi = `${nestPath}/deungi/exist`;
const ticketUsedList = `${nestPath}/ticket/used/items`;
const ticketPackageList = `${nestPath}/ticket/packages`;
const retryDeungi = `${nestPath}/deungi/retry`;
const CharginPath = `${nestPath}/ticket/items`;
const aiItemListV2 = `${nestPath}/items/small-investments`;
const saveAiFilterV2 = `${nestPath}/user/ai-recommend-setting`;
const getAiSetting = `${nestPath}/ai-setting`;
const individualLandPrice = `${nestPath}/items/individual-official-land-price`;
const newMapAggr = `${nestPath}/datamap/aggregate`;
const newMapList = `${nestPath}/datamap/list`;
const userKeywords = `${nestPath}/user/keyword`;
const campusClass = `${nestPath}/campus/class`;
const campusUser = `${nestPath}/campus/user`;
const refundPG = `${nestPath}/payment/pg`;
const mapFieldInformationPath = `${nestPath}/datamap/getPolygon`;
const mapFieldPolygonPath = `${nestPath}/datamap/app/getPolygonCenter`;
const mapClickFieldPolygonPath = `${nestPath}/datamap/app/getPolygonBjd`;
const userSubsInfo = `${nestPath}/user/subs`;
const isUserExpert = `${nestPath}/user/expert/is`;
const goStopList = `${nestPath}/gostop/list_v2`;
const storeGoStopList = `${nestPath}/gostop/store`;
const userSubs = `${nestPath}/user/subs`;
const inventory = `${nestPath}/user/inventory`;
const expertAdItemList = `${nestPath}/expert-banner/mylist`;
const expertBanner = `${nestPath}/expert-banner/banner`;
const userExpertInfo = `${nestPath}/user-expert-infos`;
const adTicketCount = `${nestPath}/ticket/count`;
const ticket = `${nestPath}/ticket`;
const stateLandPath = `${nestPath}/datamap/getStateLand`;
const stateLandInfoPath = `${nestPath}/datamap/getStateLandInfo`;
const nestLogin = `${nestPath}/v1/auth/login/web`;
const stateLandDimPolygons = `${nestPath}/datamap/getDimPolygons`;
const userStateLandPath = `${nestPath}/v2/user/state-land`;
const stateLandItems = `${nestPath}/state-land/items`;
const userRoadPlanPath = `${nestPath}/user/road-plan`;
const paymentRefundPath = `${nestPath}/payment/pg/refund`;
const paymentV2RefundPath = `${nestPath}/v2/payment/pg/refund`;
const stateLandProductPath = `${nestPath}/product/stateLand`;
const roadPlanProductPath = `${nestPath}/product/roadPlan`;
const productListPath = `${nestPath}/v1/product/subs`;
const areaCardDataPath = `${basePath}/items/v3/getLotDetailSimple`;
const stateLandPathV2 = `${nestPath}/datamap/getEncodedStateLand`;
const fieldDetailCountPath = `${nestPath}/datamap/viewCount`;
const userInfoPath = `${basePath}/user`;
const userSubsInfoPath = `${nestPath}/user/subs`;
const checkDeungiPaymentPath = `${nestPath}/payment/pg/checkDeungiPayment`;
const aiPushTestSendPath = `${nestPath}/push/set-due-date`;
const gostopSubs = `${nestPath}/user/gostop`;
const goStopDetail = `${nestPath}/items/detail/gostop`;
const itemDetail = `${basePath}/items/v3/item`;
const roadPlanPaymentPath = `${nestPath}/road-plan/items`;
const goStopPaymentPath = `${nestPath}/user/gostop/payment`;
const goStopProductPath = `${nestPath}/product/gostop`;
const inquiry = `${nestPath}/inquiry-chat`;
const favoriteItemPath = `${basePath}/items/v3/favorite`;
const itemPath = `${nestPath}/items`;

// 설정
const agreements = `${nestPath}/user/agreements`;
// 등기 변동 알림
const deungiChangeAlarm = `${nestPath}/deungi/alarm`;

//등기 티켓
const deungiMerPath = `${nestPath}/deungi/tickets`;

export const axiosPath = {
    mapList,
    itemSearch,
    mapAggr,
    login,
    items,
    itemsV2,
    searchedList,
    caseNumberList,
    filterData,
    postFilter,
    saveFilter,
    saveAiFilter,
    itemsCount,
    aiCount,
    filterHistory,
    removeFilterHistory,
    realPrice,
    aiItemList,
    aiItemListV2,
    saveAiFilterV2,
    getAiSetting,
    mapRealPriceAggr,
    mapFieldDetail,
    searchList,
    deungiList,
    deungiListByPnu,
    deungiCountByPnu,
    ticketCount,
    prevDeungi,
    deungiInfo,
    checkDeungi,
    deungiAlarm,
    submitDeungi,
    submitExistDeungi,
    bannerList,
    ticketUsedList,
    ticketPackageList,
    retryDeungi,
    refundTicket,
    CharginPath,
    individualLandPrice,
    newMapAggr,
    newMapList,
    userKeywords,
    RecentFilter,
    campusClass,
    campusUser,
    refundPG,
    mapFieldInformationPath,
    mapFieldPolygonPath,
    mapClickFieldPolygonPath,
    userSubsInfo,
    deungiPdf,
    isUserExpert,
    userExpertInfo,
    expertAdItemList,
    expertBanner,
    goStopList,
    storeGoStopList,
    userSubs,
    inventory,
    adTicketCount,
    ticket,
    stateLandPath,
    userStateLandPath,
    stateLandInfoPath,
    userRoadPlanPath,
    nestLogin,
    stateLandDimPolygons,
    stateLandItems,
    paymentRefundPath,
    paymentV2RefundPath,
    stateLandProductPath,
    roadPlanProductPath,
    productListPath,
    areaCardDataPath,
    stateLandPathV2,
    deungiChangeAlarm,
    agreements,
    deungiOwner,
    fieldDetailCountPath,
    userInfoPath,
    userSubsInfoPath,
    checkDeungiPaymentPath,
    aiPushTestSendPath,
    roadPlanPaymentPath,
    goStopPaymentPath,
    goStopProductPath,
    gostopSubs,
    goStopDetail,
    itemDetail,
    itemsV3,
    deungiMerPath,
    inquiry,
    favoriteItemPath,
    itemPath,
};
