const useFilters = [
    { idx: 1, title: "토지", use: "land" },
    { idx: 2, title: "상업 | 업무용", use: "commercial" },
    { idx: 3, title: "공장 | 창고", use: "factory" },
    { idx: 4, title: "아파트", use: "apartment" },
    { idx: 5, title: "오피스텔", use: "efficiency" },
    { idx: 6, title: "다세대 | 연립", use: "townhouse" },
    { idx: 7, title: "단독 | 다가구", use: "detachedHouse" },
];
const initUseFilters = {
    land: true,
    commercial: true,
    factory: true,
    apartment: true,
    efficiency: true,
    townhouse: true,
    detachedHouse: true,
};
const initPriceFilter = [
    "0",
    "1000",
    "2000",
    "4000",
    "8000",
    "10000",
    "20000",
    "40000",
    "80000",
    "100000",
    "200000",
    "400000",
    "800000",
    "1000000",
    "3000000",
    null,
];
const initYearFilter = ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", null];
const initPriceTypeFilter = "pyong";
const initPyoungTypeFilter = "land";
const initApiParams = {
    use: ["1", "2", "3", "4", "5", "6", "7"],
    price: ["0", null],
    year: ["2015", null],
    price_type: "pyong",
    pyong_type: "land",
};
export { useFilters, initUseFilters, initPriceFilter, initYearFilter, initApiParams, initPriceTypeFilter, initPyoungTypeFilter };
