import styled from "@emotion/styled";
import Title from "@src/components/title/Title";
import { Common } from "@src/styles/Common";
import backArrow from "../../assets/ico_arrow_back.png";
import React, { useEffect, useRef, useState } from "react";
import { useGetCharginList, useRefundTicket } from "@src/hooks/deungi/useCharginUseList";
import box from "../../assets/charginUseBox.png";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useCallToken from "@src/hooks/useCallToken";
import { transferToNative } from "@src/lib/WebAppBridge";
import Lottie from "lottie-react";
import loading from "../../assets/Loading.json";
import CancelModal from "../deungi/CancelModal";
import CampusRefundItem from "./CampusRefundItem";
import { useGetRefundList, useRefundPayment, useSaverPgRefund, useSaverPgRefundV2 } from "@src/hooks/payment/useRefund";
import CampusRefundModal from "./CampusRefundModal";

export interface ToastType {
    showToast: boolean;
    toastMessage: string;
}

const CampusRefund = () => {
    const [refundData, setRefundData] = useState<any[]>([]);
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [modalContents, setModalContents] = useState<any>();
    const [toast, setToast] = useState<ToastType>({ showToast: false, toastMessage: "" });
    const [orderNo, setOrderNo] = useState<number>(0);
    const [totalCnt, setTotalCnt] = useState<number>(0);
    const [styleHeight, setStyleHeight] = useState<number>(318);
    const [isMore, setIsMore] = useState<boolean>(false);
    const guideTextRef = useRef(null) as any;
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { token } = useCallToken();

    useEffect(() => {
        if (token) {
            setIsLoading(true);
            tryGetCancel();
            setStyleHeight(guideTextRef.current?.offsetHeight + 56);
        }
    }, [token]);

    const tryGetCancel = async (reset: boolean = false) => {
        if (reset) {
            const data = await useGetRefundList("campus_registration", { page: 1, limit: 20 });
            setTotalCnt(data.data?.totalCnt);
            setRefundData(data.data?.list);
        } else {
            const page = Math.floor(refundData?.length / 20) + 1;

            const data = await useGetRefundList("campus_registration", { page: page ? page : 1, limit: 20 });
            setTotalCnt(data.data?.totalCnt);

            if (refundData?.length + data.data.list?.length === data.data?.totalCnt) {
                setIsMore(false);
            } else {
                setIsMore(true);
            }
            setIsLoading(false);
            if (refundData?.length) {
                setRefundData(refundData.concat(data.data?.list));
            } else {
                setRefundData(data.data?.list);
            }
        }
    };
    const refundOrder = useSaverPgRefund(
        () => {
            tryGetCancel();
        },
        (err) => {
            console.log(err);
        },
    );
    const refundOrderToss = useSaverPgRefundV2(
        () => {
            tryGetCancel();
        },
        (err) => {
            console.log(err);
        },
    );

    const tryRefundTicket = async (id: number) => {
        const response = await useRefundPayment(id);
        return response;
    };

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: token.length ? isMore : false,
        onLoadMore: tryGetCancel,
        disabled: false,
        rootMargin: "0px 0px 0px 0px",
        delayInMs: 1000,
    });

    useEffect(() => {
        if (toast.showToast) {
            setTimeout(() => setToast({ showToast: false, toastMessage: "" }), 2500);
        }
    }, [toast]);

    return (
        <>
            <StyledRefundForm styleHeight={styleHeight}>
                <div className="titleWrap">
                    <div
                        className="backBtn"
                        onClick={() => {
                            transferToNative("done", "closeWebView");
                        }}
                    >
                        <img src={backArrow} alt="" />
                    </div>
                    <Title title="강의 취소/환불하기" size="large" />
                </div>
                <div className="list">
                    <div className="listItemDiv">
                        {refundData !== null && refundData?.length ? (
                            refundData.map((item: any, idx: number) => (
                                <CampusRefundItem
                                    key={item + idx}
                                    item={item}
                                    setIsModalShow={setIsModalShow}
                                    setOrderNo={setOrderNo}
                                    setToast={setToast}
                                    setModalContents={setModalContents}
                                />
                            ))
                        ) : isLoading ? (
                            <StyledLottieDiv>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </StyledLottieDiv>
                        ) : (
                            refundData !== null && (
                                <StyledEmptyList styleHeight={styleHeight}>
                                    <img src={box} alt="" />
                                    <div>수강한 강의가 없어요</div>
                                </StyledEmptyList>
                            )
                        )}

                        <div className="observed" ref={sentryRef} />
                    </div>
                    <StyledGuideText ref={guideTextRef}>
                        부동산 경매 캠퍼스 강의 환불/취소 안내
                        <br />
                        - 결제한 강의는 수강하지 않고 강의 자료를 다운로드하지 않은 경우에 한 해, 구매 후 7일 이내에
                        자주묻는질문에서 직접 구매 취소할 수 있으며 강의 영상 일부를 수강하거나 자료 다운로드 시 부분환불은
                        불가합니다.
                        <br />
                        - 강의를 구성하는 영상의 일부 재생 시 수강한 것으로 간주합니다.
                        <br />- 구매 후 7일 이내임에도 {`‘내정보>고객센터>자주묻는질문>강의 취소/환불하기’에 '취소 불가'`}{" "}
                        상태라면 다음 중 하나 이상의 경우에 해당합니다.
                        <br />
                        1) 결제한 강의를 수강한 경우
                        <br />
                        2) Google Play 결제를 통해 구매한 경우
                        <br />
                        3) iOS APP Store 결제를 통해 구매한 경우
                        <br />
                        - iOS APP Store 결제로 구매한 강의의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.
                        <br />
                        - Google Play 결제로 구매한 강의는 구매 후 48시간 이내에는 Google Play 고객센터를 통해, 48시간 이후에는
                        고객센터를 통해 구매 취소할 수 있습니다.
                        <br />
                        - 구매 취소나 환불 시에는 결제한 수단으로 환불됩니다.
                        <br />- 구독 혜택, 쿠폰 등 무료로 지급된 강의는 환불 대상에서 제외됩니다.
                    </StyledGuideText>
                </div>

                {isModalShow && (
                    <CampusRefundModal
                        setIsModalShow={setIsModalShow}
                        tryRefundTicket={tryRefundTicket}
                        orderNo={orderNo}
                        tryGetCancel={tryGetCancel}
                        setToast={setToast}
                        setCancelData={setRefundData}
                        setIsLoading={setIsLoading}
                        contents={modalContents}
                    />
                )}
                <StyledToast isShowToast={toast.showToast}>{toast.toastMessage}</StyledToast>
            </StyledRefundForm>
        </>
    );
};

export default CampusRefund;

const StyledRefundForm = styled.div<{ styleHeight: number }>`
    position: relative;
    /* height: 100vh;
    overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .titleWrap {
        display: flex;
        justify-content: center;
        position: fixed;
        background: #ffffff;
        width: 100%;
        z-index: 22;
        padding: 14px;
        align-items: center;
        & > .backBtn {
            position: absolute;
            left: 14px;
            width: 10px;
            height: 17px;
            & > img {
                width: 100%;
                height: 100%;
            }
        }
        & > .title {
            font-weight: 700;
        }
    }
    & > .list {
        width: 100%;
        overflow-y: scroll;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        padding-top: 56px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        position: relative;
        & > .observed {
            height: 30px;
            width: 100%;
        }
        & > .listItemDiv {
            min-height: ${(props) => `calc(calc(var(--vh, 1vh) * 100) - ${props.styleHeight}px)`};
        }
    }
    & > .list::-webkit-scrollbar {
        display: none;
    }
`;
const StyledLottieDiv = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const StyledGuideText = styled.div`
    background-color: ${Common.colors.grey01};
    color: ${Common.colors.greyPrimary};
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 20px 14px;
    margin-top: 30px;
`;

const StyledEmptyList = styled.div<{ styleHeight: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    gap: 24px;
    height: ${(props) => `calc(calc(var(--vh, 1vh) * 100) - ${props.styleHeight}px)`};
    & > img {
        width: 125px;
        height: 84px;
    }
    & > div {
        color: ${Common.colors.normalTextColor};
        font-size: 17px;
        font-weight: 400;
    }
`;

const StyledToast = styled.div<{ isShowToast: boolean }>`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${(props) => (props.isShowToast ? 0.7 : 0)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 50px;
    border-radius: 14px;
    position: absolute;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
`;
