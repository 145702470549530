import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import useCallToken from "@src/hooks/useCallToken";
import AiTitle from "@src/components/aiFilter/AiTitle";
import AiFooter from "@src/components/aiFilter/AiFooter";
import AiHeader from "@src/components/aiFilter/AiHeader";
import { MAX_Y } from "@src/components/aiFilter/Constants";
import PriceInput from "@src/components/aiFilter/PriceInput";
import BottomSheet from "@src/components/bottomSheet/BottomSheet";
import AiBottomSheet from "@src/components/aiFilter/AiBottomSheet";
import BottomSheetContents from "@src/components/bottomSheet/BottomSheetContents";
import { transferToNative } from "@src/lib/WebAppBridge";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import { FilterParamsType, useAiFilterStore } from "@src/store/AiFilterStore";
import { Common } from "@src/styles/Common";
import AiResetConfirmModal from "./AiResetConfirmModal";

const AiFilterMainV2 = () => {
    const [resetVisible, setResetVisible] = useState(false);
    const [selectedSortId, setSelectedSortId] = useState<number | undefined>(0);
    const [sortStatus, setSortStatus] = useState<string | undefined>("업데이트순");
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const aiFilterType = useAiFilterStore((state) => state.aiFilterState.type);
    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const getAiFilterState = useAiFilterStore((state) => state.getAiFilterState);
    const setAiFilterDataList = useAiFilterStore((state) => state.setAiFilterDataList);
    const sortId = useAiFilterStore((state) => state.selectedSortId);
    const setSortText = useAiFilterStore((state) => state.setSelectedSortText);
    const sortText = useAiFilterStore((state) => state.selectedSortText);
    const setIsConfirm = useAiFilterStore((state) => state.setIsConfirm);
    const deleteAiFilterState = useAiFilterStore((state) => state.deleteAiFilterState);
    const isShowGuidePopup = useAiFilterStore((state) => state.isShowGuidePopup);
    const setIsShowGuidePopup = useAiFilterStore((state) => state.setIsShowGuidePopup);

    const visible = useAiFilterStore((state) => state.visible);
    const setVisible = useAiFilterStore((state) => state.setVisible);
    const inputRef = useRef() as any;

    const { state } = useLocation() as {
        state: FilterParamsType;
    };
    const [input, setInput] = useState(aiFilterState.price ?? "");
    const [subInput, setSubInput] = useState(Number(aiFilterState.price ? aiFilterState.price.replaceAll(",", "") : ""));

    const { token } = useCallToken();

    const handleSubmit = async (input: string) => {
        setAiFilterState(
            {
                ...aiFilterState,
                price: input,
                type: aiFilterType,
            },
            sortId,
        );
        setAiFilterDataList({ ...aiFilterState, price: input, type: aiFilterType }, sortId);

        setIsConfirm(false);
    };

    const handleKeyUP = async (e: any) => {
        if (e.keyCode === 13) {
            await handleSubmit(input);
            inputRef.current.blur();
        }
    };

    const onClickReset = () => {
        setResetVisible(true);
    };

    const onClickCancel = () => {
        setResetVisible(false);
    };

    const onClickConfirm = async () => {
        setInput("");
        setSubInput(0);
        setAiFilterState({
            ...aiFilterState,
            price: "",
            type: "0",
            loc: [""],
            use: [""],
        });
        setAiFilterDataList({ ...aiFilterState, price: "", type: "0", loc: [""], use: [""] }, 0);
        deleteAiFilterState();
        setResetVisible(false);
    };

    const onClickGoBack = () => {
        setIsShowGuidePopup(true);
        transferToNative("done", "closeWebView");
    };

    const BottomSheetArea = useMemo(
        () => (
            <BottomSheet visible={visible}>
                <BottomSheetContents setSelectedSortId={setSelectedSortId} setSortStatus={setSortStatus} type="Ai" />
            </BottomSheet>
        ),
        [visible, selectedSortId],
    );

    const priceToKorean = useMemo(
        () => (
            <PriceToKorean>
                {convertNumberToKorean(subInput * 10000).replace("억", "억 ") &&
                    convertNumberToKorean(subInput * 10000).replace("억", "억 ") + "원"}
            </PriceToKorean>
        ),
        [subInput],
    );

    useEffect(() => {
        if (token.length) {
            if (pathname.includes("ai")) {
                getAiFilterState(aiFilterState.type);
                setInput(aiFilterState.price === "0" ? "" : aiFilterState.price);
                setSubInput(
                    aiFilterState.price.includes(",")
                        ? Number(aiFilterState.price.replaceAll(",", ""))
                        : aiFilterState.price === "0"
                        ? 0
                        : Number(aiFilterState.price),
                );
                handleSubmit(aiFilterState.price);
            } else {
                const newPrice = searchParams.get("price") ? searchParams.get("price") ?? "0" : state?.price ? state.price : "";
                setInput(newPrice === "0" ? "" : searchParams.get("price") ? Number(newPrice).toLocaleString() : newPrice);
                setSubInput(Number(newPrice.replaceAll(",", "")));
                handleSubmit(newPrice);
            }
            isShowGuidePopup && transferToNative("", "showGuidePopup");
        }
    }, [token]);

    useEffect(() => {
        input && setAiFilterDataList({ ...aiFilterState }, sortId);
    }, [sortId]);

    useEffect(() => {
        const debounceSubmit = setTimeout(() => {
            handleSubmit(input);
        }, 600);

        return () => {
            clearTimeout(debounceSubmit);
        };
    }, [input]);

    useEffect(() => {
        if (input && state?.price) {
            handleSubmit(input);
            setSortText(sortText);
        }
    }, [selectedSortId]);

    return (
        <Wrapper>
            <Container>
                <AiHeader onClickBack={onClickGoBack} />
                <AiTitle>
                    {pathname.includes("small") ? (
                        <h2>
                            <span className="color-text">보유 현금</span>을 입력해주세요
                        </h2>
                    ) : (
                        <h2>
                            <span className="color-text">투자금</span>을 입력해주세요
                        </h2>
                    )}
                    <p>
                        소액도 상관없어요. 현재 여유자금을 입력하면 <br />
                        최적의 경공매 매물을 찾아드려요
                    </p>
                </AiTitle>
                <PriceInput
                    input={input}
                    setInput={setInput}
                    onKeyUp={handleKeyUP}
                    handleSubmit={handleSubmit}
                    inputRef={inputRef}
                    setSubInput={setSubInput}
                />
                {priceToKorean}
                <AiFooter
                    isReset
                    isNext
                    onClickReset={onClickReset}
                    onClickNext={() => {
                        pathname.includes("small")
                            ? navigate("/smallFilter/locationv2", {
                                  state: {
                                      ...aiFilterState,
                                      price: input,
                                      use: aiFilterState.use,
                                      loc: aiFilterState.loc,
                                      type: aiFilterType,
                                  },
                              })
                            : navigate("/aiFilterV2/locationv2", {
                                  state: {
                                      ...aiFilterState,
                                      price: input,
                                      use: aiFilterState.use,
                                      loc: aiFilterState.loc,
                                      type: aiFilterType,
                                  },
                              });
                    }}
                    style={{ top: `${MAX_Y - 88}px` }}
                />
                <AiBottomSheet price={input} aiFilterCount={aiFilterCount} setVisible={setVisible} visible={visible} />
            </Container>
            {BottomSheetArea}
            {resetVisible ? (
                <AiResetConfirmModal text="필터를 초기화할까요?" onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />
            ) : (
                <></>
            )}
        </Wrapper>
    );
};

export default AiFilterMainV2;

const Container = styled.div`
    padding: 0px 14px;

    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* .lottie-area {
        height: calc(100% - 54px);
    } */
    .lottie {
        width: 100%;
        margin-top: 20px;
    }
`;

const Wrapper = styled.div`
    overflow: hidden;
    height: 100%;
`;

const PriceToKorean = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 8px;
    color: ${Common.colors.navyPrimary};
`;
