import React, { useEffect, useState } from "react";

interface useScrollProps {
    scrollRef: React.MutableRefObject<HTMLDivElement | null>;
    deps: any[];
}

const useScroll = ({ scrollRef, deps }: useScrollProps) => {
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) setScrollHeight(scrollRef.current.scrollTop);
        };

        if (scrollRef.current) {
            scrollRef.current.addEventListener("scroll", handleScroll, true);
        }

        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener("scroll", handleScroll, true);
            }
        };
    }, [scrollRef.current, ...deps]);

    return scrollHeight;
};

export default useScroll;
