import React from "react";
import styled from "@emotion/styled";
import { useAiBottomSheet } from "@src/hooks/filter/useAiBottomSheet";
import { Common } from "@src/styles/Common";

const BottomSheetHeader = () => {
    // const { sheet } = useAiBottomSheet();
    return (
        <>
            <Wrapper>
                <Handle />
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    padding-bottom: 12px;
`;

const Handle = styled.div`
    width: 34px;
    height: 4px;
    border-radius: 2px;
    background-color: ${Common.colors.grey04};
    margin: auto;
`;

export default BottomSheetHeader;
