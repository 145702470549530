import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";

const fetchData = async (params: { latitude: number; longitude: number; pnu?: string }) => {
    try {
        const response = await axiosInstance.get(axiosPath.mapFieldInformationPath, {
            params,
        });
        return response?.data;
    } catch (err: any) {
        if (err?.response?.status === 404) {
            return null;
        }
        console.log("err>>>", err?.response?.status);
        throw err;
    }
};

export const useGetMapFieldInformation = async (params: { latitude: number; longitude: number; pnu?: string }) => {
    return !!params?.latitude && fetchData(params);
};

export const useFindFieldAddress = (lat: number, lng: number) => {
    return useQuery<any>(
        [lat, lng, "address"],
        async () => {
            const res = await fetchData({ latitude: lat, longitude: lng });
            return {
                address: res?.land?.address ?? "",
                jimok: res?.land?.jimok ?? "",
            };
        },
        { enabled: false },
    );
};
