import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction } from "react";
import textImg from "../../assets/searchTextIcon.png";
import map from "../../assets/searchMapIcon.png";
import sale from "../../assets/searchSaleIcon.png";
import deleteImg from "../../assets/RecentDelete.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useDeleteRecentSearch, useGetRecentSearchList, usePushSearchKeyword } from "@src/hooks/item/useRecentSearchList";

interface RecentSearchItemProps {
    text: string;
    setItems: Dispatch<SetStateAction<any[]>>;
    items: string[];
    item: any;
    // setSearchInputValue: Dispatch<SetStateAction<string>>;
    setSearchInputValue: (SearchKeyword: string) => void;
    isPushKeyword: boolean;
    setIsPushKeyword: Dispatch<SetStateAction<boolean>>;
    fetchData: (type: string, textValue: string) => void;
    searchInputValue: string;
}

const RecentSearchItem = ({
    text,
    setItems,
    items,
    item,
    setSearchInputValue,
    isPushKeyword,
    setIsPushKeyword,
    fetchData,
    searchInputValue,
}: RecentSearchItemProps) => {
    return (
        <>
            <StyledRecentSearchItem>
                <div
                    className="clickDiv"
                    onClick={() => {
                        setSearchInputValue(text);
                        text !== searchInputValue && usePushSearchKeyword(text);
                        fetchData("filterChanged", text);
                        setIsPushKeyword(false);
                    }}
                >
                    <img src={textImg} alt="" className="typeMarker" />
                    <div className="recentTextDiv">
                        <div className="recentSearchText">{text}</div>
                    </div>
                </div>
                <ClearIcon
                    className="deleteBtn"
                    onClick={() => {
                        // setItems(items.filter((item) => item !== text));
                        useDeleteRecentSearch(item.no).then(() => {
                            useGetRecentSearchList().then((res) => {
                                setItems(res.data.list);
                            });
                        });
                    }}
                />
                {/* <object data={deleteImg} className="deleteBtn" /> */}
            </StyledRecentSearchItem>
        </>
    );
};

export default RecentSearchItem;

const StyledRecentSearchItem = styled.div`
    display: flex;
    gap: 11px;
    min-height: 34px;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    position: relative;
    padding: 10px 17px 0 17px;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    :active {
        background: ${Common.colors.blue};
        width: 100vw;
        .recentTextDiv {
            .recentSearchText {
                color: ${Common.colors.white};
            }
        }
        .deleteBtn {
            color: ${Common.colors.white};
        }
    }
    /* align-items: flex-start; */
    .clickDiv {
        display: flex;
        width: calc(100% - 20px);
        gap: 11px;
        .typeMarker {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: flex-start;
        }

        .recentTextDiv {
            display: flex;
            width: 100%;
            border-bottom: 1px solid ${Common.colors.grey03};
            padding-bottom: 10px;
            align-items: flex-start;
            min-height: 34px;
            height: fit-content;
            .recentSearchText {
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                height: fit-content;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* color: ${Common.colors.darkGrey02}; */
                color: white;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
        }
    }

    .deleteBtn {
        /* color: ${Common.colors.darkGrey04}; */
        color: white;
        position: absolute;
        top: 14px;
        right: 17px;
        font-size: 18px;
    }
`;
