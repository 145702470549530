import React from "react";
import { css } from "@emotion/react";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import Lottie from "lottie-react";
import aiRecommendMotion from "@src/assets/aiRecommend/aiRecommendMotion.json";

interface Props {
    onCloseView: () => void;
    onClickConfirm: () => void;
}

const messageContent = () => (
    <div css={contentBoxStyle}>
        <div>
            <Lottie className="lottie" animationData={aiRecommendMotion} />
        </div>
        추천매물이 설정되었어요
        <br />
        <br />
        경공매 입찰일 설정을 통해
        <br />
        알림을 상세히 받아보시겠어요?
    </div>
);

const CheckPushModal = ({ onCloseView, onClickConfirm }: Props) => {
    return (
        <>
            <ConfirmModal resultMessage={messageContent()} onConfirm={onClickConfirm} onCancel={onCloseView} cancelText="취소" />
        </>
    );
};

export default CheckPushModal;

const contentBoxStyle = css`
    & > div {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }
    .lottie {
        width: 110px;
        height: 110px;
    }
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;
