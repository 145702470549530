import React, { useState, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { buildingCardContentsType } from "@src/pages/map/types";
import closeIcon from "@src/assets/ico_close.png";
import { isShowLabel } from "@src/util/isShowBottomSheetLabel";
import roadViewIcon from "@src/assets/ico_roadview.png";

interface CardBottomSheetHeaderProps {
    titleRef: any;
    isBackToAppShow: boolean;
    handleClickBackToApp: () => void;
    handleClickRoadViewButton: (position: naver.maps.LatLng) => void;
    buildingCardContents: buildingCardContentsType;
    landCardContents: any;
    hideLandCard: () => void;
    address: string;
    convertLandCard: () => void;
    fieldJimok: string;
    areaSize?: { pyong: string; square: string };
    handleClickBackToAiChat?: () => void;
    fromCheck?: string;
    showTopBtn?: boolean;
}

const BuildingCard = ({
    titleRef,
    isBackToAppShow,
    handleClickBackToApp,
    handleClickRoadViewButton,
    buildingCardContents,
    landCardContents,
    hideLandCard,
    address,
    convertLandCard,
    fieldJimok,
    areaSize,
    fromCheck,
    handleClickBackToAiChat,
    showTopBtn = true,
}: CardBottomSheetHeaderProps) => {
    //건물 카드 타입
    // 1: 일반건물 카드 (지목 대)
    // 2: 일반건물 카드 (지목 대X)
    // 3: 집합건물 카드
    const [buildingCardType, setBuildingCardType] = useState<1 | 2 | 3>();
    const [viewData, setViewData] = useState<any>({});
    const landCode = useMemo(() => {
        return landCardContents.lndcgrCodeNm;
    }, [landCardContents]);
    useEffect(() => {
        if (!buildingCardContents) return;
        const { origin } = buildingCardContents;
        const { total, single } = origin;

        if (!total || !Object.keys(total).length) return matchCard(single, false);
        matchCard(total, true);
    }, [buildingCardContents]);

    const matchCard = (data: any, isTotal: boolean) => {
        // 총괄표제부, 표제부 데이터 모두 없을 경우 토지카드 노출
        if (!data || !Object.keys(data).length) return convertLandCard();
        const item = data;

        if (isTotal) {
            //총괄표제부 일때
            if (item.length !== 0) {
                if (Array.isArray(item)) {
                    const code = item[0]?.regstrGbCdNm ?? "집합";

                    if (code.includes("집합")) setBuildingCardType(3);
                    if (code.includes("일반")) {
                        if (["대", "대지"].some((val) => val === fieldJimok)) setBuildingCardType(1);
                        if (["대", "대지"].every((val) => val !== fieldJimok)) setBuildingCardType(2);
                    }

                    setViewData(item[0]);
                } else {
                    const code = item?.regstrGbCdNm ?? "집합";

                    if (code.includes("집합")) setBuildingCardType(3);
                    if (code.includes("일반")) {
                        if (["대", "대지"].some((val) => val === fieldJimok)) setBuildingCardType(1);
                        if (["대", "대지"].every((val) => val !== fieldJimok)) setBuildingCardType(2);
                    }

                    setViewData(item);
                }
            }
        } else {
            //표제부 일때
            if (item.length !== 0) {
                if (Array.isArray(item)) {
                    const arr = item.filter((v) => v?.regstrKindCdNm.includes("집합"));
                    setViewData(arr[0]);
                    setBuildingCardType(3);
                    if (!arr[0]) {
                        setViewData(item[0]);
                        const code = item[0]?.regstrKindCdNm;
                        if (code.includes("일반")) {
                            if (["대", "대지"].some((val) => val === fieldJimok)) setBuildingCardType(1);
                            if (["대", "대지"].every((val) => val !== fieldJimok)) setBuildingCardType(2);
                        }
                    }
                } else {
                    const code = item?.regstrKindCdNm;
                    if (!code) convertLandCard();
                    if (code.includes("집합") === false && code.includes("일반") === false) convertLandCard();
                    if (code.includes("집합")) setBuildingCardType(3);
                    if (code.includes("일반")) {
                        if (["대", "대지"].some((val) => val === fieldJimok)) setBuildingCardType(1);
                        if (["대", "대지"].every((val) => val !== fieldJimok)) setBuildingCardType(2);
                    }
                    setViewData(item);
                }
            }
        }
    };

    return (
        <>
            <div css={buildingCard} ref={titleRef} className={"summCard"}>
                {showTopBtn && (
                    <div css={topButtonBoxStyle}>
                        {isBackToAppShow && (
                            <button css={backToAppButtonStyle("itemDetail")} onClick={handleClickBackToApp}>
                                매물상세
                            </button>
                        )}
                        {isBackToAppShow && fromCheck === "AIChat" && (
                            <button
                                css={backToAppButtonStyle("AIChat")}
                                onClick={() => handleClickBackToAiChat && handleClickBackToAiChat()}
                            >
                                음성검색
                            </button>
                        )}
                        <button
                            css={roadViewButtonStyle}
                            onClick={() => {
                                handleClickRoadViewButton(landCardContents.position);
                            }}
                        >
                            <img src={roadViewIcon} alt="roadViewIcon" width={14} />
                            거리뷰
                        </button>
                    </div>
                )}
                <div className="contents">
                    <button className="closeBtn" onClick={hideLandCard}>
                        X
                    </button>
                    <div className="swipePoint"></div>
                    <p className="address">{address}</p>
                    {buildingCardContents?.roadAddr !== "일치하는 도로명 주소가 없습니다" && (
                        <div className="roadAddress">
                            <div className="badge">도로명</div>
                            <p className="roadAddressText">
                                {buildingCardContents?.roadAddr !== "일치하는 도로명 주소가 없습니다"
                                    ? buildingCardContents?.roadAddr
                                    : buildingCardContents?.newPlatPlc}
                            </p>
                        </div>
                    )}
                    <div className="contents_bottom">
                        {buildingCardType === 1 && (
                            <>
                                <div className="left">
                                    <ul>
                                        {/* 
                                            용도지역 | 건물명 | 주용도 | 준공일자
                                            대지면적(평) | 연면적(평) | 층수 (지상/지하)
                                        */}
                                        <li>
                                            {isShowLabel(landCardContents?.use) && <span>{landCardContents?.use}</span>}
                                            {isShowLabel(buildingCardContents?.buildingNm) && (
                                                <span> {buildingCardContents?.buildingNm}</span>
                                            )}
                                            {isShowLabel(buildingCardContents?.mainUse) && (
                                                <span> {buildingCardContents?.mainUse}</span>
                                            )}
                                            {isShowLabel(buildingCardContents?.useAprDay) && (
                                                <span> 준공년도 {buildingCardContents?.useAprDay}</span>
                                            )}
                                        </li>
                                        <li>
                                            {isShowLabel(buildingCardContents?.platArea?.split(".")?.[0]) ? (
                                                <span>대지면적 {buildingCardContents?.platArea}</span>
                                            ) : (
                                                isShowLabel(areaSize?.pyong ?? "") && <span>대지면적 {areaSize?.pyong}</span>
                                            )}
                                            {/* {isShowLabel(buildingCardContents?.totArea?.split(".")?.[0]) && ( */}
                                            {isShowLabel(buildingCardContents?.totArea) && (
                                                <span>연면적 {buildingCardContents?.totArea}</span>
                                            )}
                                            <div>
                                                층수({buildingCardContents?.grndFlrCnt}/{buildingCardContents?.ugrndFlrCnt})
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right">{/* <p className="viewCount">조회수 0회</p> */}</div>
                            </>
                        )}
                        {buildingCardType === 2 && (
                            <>
                                <div className="left">
                                    <ul>
                                        {/* 
                                            용도지역 | 건물명 | 주용도 | 준공일자
                                            토지면적(평) | 연면적(평) | 층수 (지상/지하)
                                        */}
                                        <li>
                                            {isShowLabel(landCardContents?.use) && <span>{landCardContents?.use}</span>}
                                            {isShowLabel(buildingCardContents?.buildingNm) && (
                                                <span> {buildingCardContents?.buildingNm}</span>
                                            )}
                                            {isShowLabel(buildingCardContents?.mainUse) && (
                                                <span> {buildingCardContents?.mainUse}</span>
                                            )}
                                            {isShowLabel(buildingCardContents?.useAprDay) && (
                                                <span> 준공년도 {buildingCardContents?.useAprDay}</span>
                                            )}
                                        </li>
                                        <li>
                                            {isShowLabel(areaSize?.pyong ?? "") ? (
                                                <span>토지면적 {areaSize?.pyong}</span>
                                            ) : (
                                                isShowLabel(landCardContents?.size) && (
                                                    <span>토지면적 {landCardContents?.size} </span>
                                                )
                                            )}
                                            {isShowLabel(buildingCardContents?.totArea) && (
                                                <span>연면적 {buildingCardContents?.totArea}</span>
                                            )}
                                            <div>
                                                층수({buildingCardContents?.grndFlrCnt}/{buildingCardContents?.ugrndFlrCnt})
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right">{/* <p className="viewCount">조회수 0회</p> */}</div>
                            </>
                        )}
                        {buildingCardType === 3 && (
                            <>
                                <div className="left">
                                    <ul>
                                        {/* 
                                            단지명(건물명) | 용도지역
                                            준공년도 | 세대수 | 호수 | 층수 (지상/지하)
                                        */}
                                        <li>
                                            {isShowLabel(viewData?.bldNm) && <span> {viewData?.bldNm} </span>}
                                            {isShowLabel(landCardContents?.use) && <span>{landCardContents?.use}</span>}
                                        </li>
                                        <li>
                                            {isShowLabel(viewData?.useAprDay) && (
                                                <span>
                                                    준공년도 {viewData?.useAprDay.toString().replace(/(\d{4})(\d{4})/g, "$1년")}{" "}
                                                </span>
                                            )}
                                            {isShowLabel(viewData?.hhldCnt) && <span> {viewData?.hhldCnt} </span>}
                                            {isShowLabel(viewData?.hoCnt) && <span> {viewData?.hoCnt} </span>}

                                            <div>
                                                층수({buildingCardContents?.grndFlrCnt}/{buildingCardContents?.ugrndFlrCnt})
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right">{/* <p className="viewCount">조회수 0회</p> */}</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const buildingCard = css`
    width: 100vw;
    height: fit-content;
    min-height: 144px;
    padding: 25px 14px 14px;
    background: ${Common.colors.white};
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 14px 14px 0px 0px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 994;
    span {
        margin-right: 6px;
    }
    .swipePoint {
        width: 40px;
        height: 4px;
        border-radius: 2px;
        background: ${Common.colors.grey04};
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, 0%);
    }
    .closeBtn {
        cursor: pointer;
        width: 26px;
        height: 26px;
        background: url(${closeIcon}) no-repeat;
        background-position: center;
        background-size: 16px auto;
        font-size: 0;
        text-indent: -9999px;
        position: absolute;
        right: 14px;
        top: 30px;
    }
    .address {
        width: calc(100% - 26px);
        word-break: keep-all;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: ${Common.colors.navyPrimary};
    }
    .roadAddress {
        width: calc(100% - 26px);
        word-break: keep-all;
        margin-top: 4px;
        display: flex;
        align-items: center;
        column-gap: 4px;
        .roadAddressText {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }
    .badge {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${Common.colors.redSecondary};
        border: 1px solid ${Common.colors.redSecondary};
        border-radius: 4px;
        padding: 0 4px;
    }
    .contents_bottom {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 8px;
        padding-top: 14px;
        border-top: 0.6px solid ${Common.colors.greySecondary};
        font-style: normal;
        font-weight: 400;
        font-size: 12.5px;
        line-height: 20px;
        .left,
        .right {
            color: ${Common.colors.darkGrey03};
            & > ul {
                & > li {
                    & > div {
                        display: inline-block;
                    }
                }
            }
        }
        .right {
            text-align: right;
        }
        .viewCount {
            color: ${Common.colors.greyPrimary};
        }
    }
    /* .roadViewButton {
        position: absolute;
        right: 14px;
        top: -46px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: ${Common.colors.white};
        padding: 6px 0;
        gap: 4px;
        width: 109px;
        height: 32px;
        background: #0a2540;
        border-radius: 24px;
    } */
    .backToApp {
        position: absolute;
        right: 14px;
        top: -86px;
        padding: 6px 8px;
        color: #fff;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        background-color: ${Common.colors.blue};
        border-radius: 16px;
    }
`;

const topButtonBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    position: absolute;
    right: 14px;
    top: -48px;
    width: 100%;
`;

const backToAppButtonStyle = (type: string) => css`
    padding: 4px 8px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    border-radius: 16px;
    background-color: ${type === "itemDetail" ? "#0047ff" : "#000000"};
`;

const roadViewButtonStyle = css`
    padding: 8px 10px;
    border-radius: 24px;
    background-color: #0a2540;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    img {
        margin-top: -1px;
    }
`;
export default BuildingCard;
