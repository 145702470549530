import { css } from "@emotion/react";
import BottomSheet, { appearBottomSheetBackground } from "@src/components/bottomSheet/BottomSheet";
import BottomSheetContents from "@src/components/bottomSheet/BottomSheetContents";
import OutFilter from "@src/components/filter/OutFilter";
import ListSearchInput from "@src/components/input/ListSearchInput";
import Item from "@src/components/Item/Item";
import { GetItemListType, initialParams, useGetItem } from "@src/hooks/item/useGetItem";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ico_arrow_down from "@src/assets/boldArrowDown.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import useCallToken from "@src/hooks/useCallToken";
import { debounce, isEmpty } from "lodash";
import { initialState, removeAll, useFilterStore } from "@src/store/filterStore";
import styled from "@emotion/styled";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Dim from "@src/components/DIM/Dim";
import EmptyResult from "@src/components/SearchItem/EmptyResult";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import ItemsSearchInput from "@src/components/Item/ItemsSearchInput";
import RecentSearchList from "@src/components/Item/RecentSearchList";
import noListImg from "../assets/noRecentList.png";
import { Common } from "@src/styles/Common";
import Lottie from "lottie-react";
import loading from "../assets/Loading.json";
import { usePushSearchKeyword } from "@src/hooks/item/useRecentSearchList";
import NewFilter from "./newFilter";

const Items = () => {
    const { path } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const initialFilterState = {
        type: "0",
        loc: [],
        use: [],
        counter_force: "0",
        auction_master: [],
        auction_master_item: [],
        auction_master_except: false,
        except_item: [],
        status: [""],
        skp_type: "",
        skp_cnt: ["", ""],
        due_date: ["", ""],
        est_price: ["", ""],
        min_price: ["", ""],
        sales_method: "0",
        assets: [],
        sort: "5",
    };

    const [items, setItems] = useState<GetItemListType[]>([]);
    const [isNextIt, setIsNextIt] = useState("");
    const [params, setParams] = useState(initialParams);
    const [totalItem, setTotalItem] = useState(0);
    const [selectedSortId, setSelectedSortId] = useState<number | undefined>(0);
    const [sortStatus, setSortStatus] = useState<string | undefined>("매각기일 가까운순");
    const [isMore, setIsMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [isFocusSearch, setIsFocusSearch] = useState<boolean>(false);
    const [isPushKeyword, setIsPushKeyword] = useState<boolean>(true);

    const containerRef = useRef() as any;

    const searchInputValue = useFilterStore((state) => state.searchKeyword);
    const setSearchInputValue = useFilterStore((state) => state.setSearchKeyword);
    const filterState = useFilterStore((state) => state.filterState);
    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const getFilterHistory = useFilterStore((state) => state.getFilterHistory);
    const visible = useAiFilterStore((state) => state.visible);
    const setVisible = useAiFilterStore((state) => state.setVisible);
    const dimVisible = useFilterStore((state) => state.dimVisible);
    const postFilterHistory = useFilterStore((state) => state.postFilterHistory);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);

    useEffect(() => {
        const queryPath = searchParams.get("path");
        if (path === "useA" || queryPath === "useA") {
            // 주거용
            setStoreFilterState({
                ...initialState,
                use: ["A01", "A02", "A03", "A04"],
            });
        }

        if (path === "useB" || queryPath === "useB") {
            // 토지
            setStoreFilterState({
                ...initialState,
                use: ["B01", "B02", "B03", "B04", "B05", "B06", "B07", "B08", "B09", "B10", "B11"],
            });
        }

        if (path === "useC" || queryPath === "useC") {
            // 산업용
            setStoreFilterState({
                ...initialState,
                use: ["C01", "C02", "C03"],
            });
        }

        if (path === "useD" || queryPath === "useD") {
            // 상업용
            setStoreFilterState({
                ...initialState,
                use: ["D01", "D02", "D03", "D04", "D05"],
            });
        }

        if (path === "type1" || queryPath === "type1") {
            // 경매
            setStoreFilterState({
                ...initialState,
                type: "1",
            });
        }

        if (path === "type2" || queryPath === "type2") {
            // 공매
            setStoreFilterState({
                ...initialState,
                type: "2",
            });
        }

        if (path === "progressItems" || queryPath === "progressItems") {
            // 진행매물

            setStoreFilterState({
                ...initialState,
                status: ["1", "2"],
                sort: "5",
            });
        }

        if (path === "newItems" || queryPath === "newItems") {
            // 신규매물

            setStoreFilterState({
                ...initialState,
                status: ["2"],
                skp_type: "1",
                sort: "5",
            });
        }
        if (path === undefined) {
            setStoreFilterState({
                ...filterState,
            });
        }
    }, [path, searchParams]);
    const fetchData = async (type?: string, textValue?: string) => {
        setIsLoading(true);
        setIsMore(false);
        const response = await useGetItem({
            ...params,
            keyword: textValue ?? searchInputValue,
            filter: isShowFilter
                ? {
                      ...filterState,
                      status: isEmpty(filterState.status) ? ["0"] : filterState.status,
                      auction_master_item: filterState.auction_master_item[0] === "0" ? [] : filterState.auction_master_item,
                      use: makeUseParam(filterState.use),
                      loc: removeAll(filterState.loc),
                  }
                : { ...initialFilterState, sort: selectedSortId },

            next_it: type ? "" : isNextIt,
        });

        setTotalItem(response.total);

        if (!isEmpty(response.list)) {
            setIsMore(true);
            setIsNextIt(response.list[response.list.length - 1].next_it);
        }

        if (isEmpty(response.list)) {
            setIsMore(false);
        }

        setParams({
            ...params,
            keyword: searchInputValue,
            filter: {
                ...filterState,
                use: makeUseParam(filterState.use),
                loc: removeAll(filterState.loc),
            },
        });
        setIsLoading(false);

        if (type === "filterChanged") {
            return setItems(response.list ?? []);
        }

        if (items?.length) {
            return setItems(items.concat(response.list ?? []));
        }
        if (!items?.length) {
            setItems(response.list ?? []);
        }
    };

    const { token } = useCallToken();

    useEffect(() => {
        if (location.state) {
            const currentStatus = location.state?.status;
        }
    }, []);

    useEffect(() => {
        setItems([]);
        setIsNextIt("");
        if (token.length) {
            fetchData("filterChanged");
        }
    }, [dimVisible, token, filterState.sort, isShowFilter]);

    useEffect(() => {
        if (items?.length) {
            containerRef?.current?.scrollIntoView({ block: "end" });
        }
    }, [items]);

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: token.length ? isMore : false,
        onLoadMore: fetchData,
        disabled: false,
        rootMargin: "0px 0px 300px 0px",
        delayInMs: 100,
    });

    const BottomSheetArea = useMemo(
        () => (
            <BottomSheet visible={visible}>
                <BottomSheetContents
                    setSelectedSortId={setSelectedSortId}
                    setSortStatus={setSortStatus}
                    type="list"
                    currentValue={location?.state?.status}
                />
            </BottomSheet>
        ),
        [visible, selectedSortId, params],
    );

    useEffect(() => {
        setItems([]);
        setIsNextIt("");

        if (token.length) {
            const debounceSubmit = setTimeout(() => {
                fetchData("filterChanged");
            }, 500);

            return () => {
                clearTimeout(debounceSubmit);
            };
        }
    }, [searchInputValue]);

    useEffect(() => {
        setIsPushKeyword(true);
    }, [searchInputValue]);

    useEffect(() => {
        if (isFocusSearch && !searchInputValue) {
            setSearchParams(new URLSearchParams({ history: "on" }));
        } else {
            setSearchParams();
        }
    }, [searchInputValue]);

    useEffect(() => {
        if (searchInputValue.length) {
            setIsShowFilter(false);
        } else {
            setIsShowFilter(true);
        }
    }, [searchInputValue]);

    return (
        <>
            <StyledItems>
                <div css={FilterArea}>
                    <ItemsSearchInput
                        isShowFilter={isShowFilter}
                        setIsShowFilter={setIsShowFilter}
                        searchInputValue={searchInputValue}
                        setSearchInputValue={setSearchInputValue}
                        isFocusSearch={isFocusSearch}
                        setIsFocusSearch={setIsFocusSearch}
                        setIsPushKeyword={setIsPushKeyword}
                        isPushKeyword={isPushKeyword}
                        fetchData={(type: string) => {
                            setItems([]);
                            fetchData(type);
                            setSearchParams();
                        }}
                    />
                    {/* <NewFilter text={""} /> */}
                    <OutFilter isShowFilter={true} isDisableFilter={!isShowFilter} />
                    {!dimVisible && (
                        <div css={ListInfo}>
                            <span>
                                총 매물 <span className="color-text">{totalItem?.toLocaleString() ?? 0}</span> 건
                            </span>
                            <button onClick={() => setVisible(!visible)}>
                                {sortStatus} <img src={ico_arrow_down} />
                            </button>
                        </div>
                    )}
                </div>

                <Container ref={containerRef} onTouchStart={() => (document.activeElement as HTMLElement).blur()}>
                    {isEmpty(items) ? (
                        <StyledNoList>
                            {isLoading ? (
                                <div className="content">
                                    <div className="lottie">
                                        <Lottie animationData={loading} />
                                    </div>

                                    <div className="loadingText">매물을 불러오는 중입니다</div>
                                </div>
                            ) : (
                                <div className="content">
                                    <img className="noListImg" src={noListImg} alt="" />
                                    <div className="noListText">일치하는 매물이 없습니다</div>
                                </div>
                            )}
                        </StyledNoList>
                    ) : (
                        items?.map((item, index) => (
                            <Item
                                onClick={async () => {
                                    isPushKeyword &&
                                        searchInputValue.length > 1 &&
                                        usePushSearchKeyword(searchInputValue, item.item_id);
                                    setIsPushKeyword(false);
                                    await postFilterHistory(filterState);
                                    setUpdateHistoryFilterFlag(false);
                                    await getFilterHistory();
                                    if (localStorage.getItem("isSubscription") === "false") {
                                        transferToNative("", "showSubscriptionPopup");
                                    } else {
                                        transferToNative(JSON.stringify(item), "onClickItem");
                                    }
                                }}
                                key={`${item?.item_id}_${index}`}
                                item_id={item.item_id}
                                bid_type={item.bid_type}
                                case_number={item.case_number}
                                is_favorited={item.is_favorited}
                                use={item.use}
                                skp_cnt={item.skp_cnt}
                                skp_cnt_str={item.skp_cnt_str}
                                image={item.image}
                                address={item.address}
                                mid_adrs={item.mid_adrs}
                                est_price={item.est_price}
                                est_price_str={item.est_price_str}
                                min_price={item.min_price}
                                min_price_str={item.min_price_str}
                                rate={item.rate}
                                status={item.status}
                                d_day={item.d_day}
                                due_date_str={item.due_date_str}
                                due_date={item.due_date}
                                somethings={item.somethings}
                                updated_at={item.updated_at}
                                next_it={item.next_it}
                                status_detail={item?.status_detail}
                                sale_price_str={item.sale_price_str}
                                sale_price_rate={item.sale_price_rate}
                                card_type={item.card_type}
                                date_info={item.date_info}
                            />
                        ))
                    )}
                    <div style={{ width: "100px", marginTop: "-450px", height: "50px" }} ref={sentryRef} />
                </Container>

                <Dim visible={dimVisible} />
                {searchParams.get("history") === "on" && (
                    <RecentSearchList
                        setSearchInputValue={setSearchInputValue}
                        setIsFocusSearch={setIsFocusSearch}
                        isPushKeyword={isPushKeyword}
                        setIsPushKeyword={setIsPushKeyword}
                        fetchData={(type: string, textValue: string) => {
                            setItems([]);
                            fetchData(type, textValue);
                            setSearchParams();
                        }}
                        searchInputValue={searchInputValue}
                    />
                )}

                {BottomSheetArea}
            </StyledItems>
        </>
    );
};

export default Items;

const StyledItems = styled.div`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    overflow: hidden;
`;

const ListInfo = css`
    display: flex;
    justify-content: space-between;
    height: 46px;

    align-items: center;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${Common.colors.normalTextColor};
        .color-text {
            /* color: #9a49f2; */
            color: ${Common.colors.normalTextColor};
        }
    }
    button {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: ${Common.colors.normalTextColor};
        img {
            margin-left: 6px;
            width: 12px;
            height: 7px;
        }
    }
`;

const Container = styled.div`
    min-height: 100vh;
    max-height: 100vh;
    /* padding: 0px 16px; */
    padding-top: 156px;
    z-index: 22;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const FilterArea = css`
    position: fixed;
    z-index: 9998;
    width: 100%;
    background: #ffffff;
    padding: 0px 14px;
`;

const StyledNoList = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .content {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 23px;
        .lottie {
            width: 80px;
            height: 34px;
        }
        .noListImg {
            width: 50px;
            height: 60px;
        }
        .noListText {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
        }
        .loadingText {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
            margin-top: 33px;
        }
    }
`;

const StyledToast = styled.div<{ isShowToast: boolean }>`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${(props) => (props.isShowToast ? 0.7 : 0)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 10000;
    border-radius: 14px;
    position: absolute;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
    left: 50%;
    transform: translate(-50%, 0);
`;
