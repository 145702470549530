import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import React, { useState } from "react";
import { initRealPriceDetailData } from "./useGetRealPriceDetail";

const useGetInternalTable = () => {
    //호출된 데이터 수정
    const response: any = initRealPriceDetailData.history;

    //API에서 받아온 실거래가 데이터 목록
    const [apiRealPriceData, setApiRealPriceData] = useState([]);
    const [viewRealPriceData, setViewRealPriceData] = useState<any[]>([]);
    const [allRealPriceData, setAllRealPriceData] = useState<any[]>([]);

    //실거래가 API 호출
    const tryGetRealPriceData = () => {
        setApiRealPriceData(response);
        return Promise.resolve(response);
    };

    const trySetAllRealPriceDataList = (data: any) => {
        setAllRealPriceData((prev: any) => [
            ...prev,
            {
                day: data.date,
                price: (
                    <>
                        <div>{data.price}</div>
                        <div css={pricePyeongStyle}>
                            <div className="title">평당가</div>
                            <div className="price">{data.pricePyeong}</div>
                        </div>
                    </>
                ),
                size: data.size,
                distance: data.distance,
            },
        ]);
    };

    return {
        tryGetRealPriceData,
        apiRealPriceData,
        viewRealPriceData,
        setViewRealPriceData,
        trySetAllRealPriceDataList,
        allRealPriceData,
        setAllRealPriceData,
    };
};

export default useGetInternalTable;

const pricePyeongStyle = css`
    display: flex;
    gap: 4px;
    justify-content: space-between;
    padding: 0px 4px;
    margin-top: 8px;
    & > .title {
        width: 40px;
        height: 20px;
        ${Common.textStyle.caption_R11};
        text-align: center;
        background-color: ${Common.colors.bgMint};
        border-radius: 24px;
        padding: 0px 4px 0px 4px;
        box-sizing: border-box;
    }
`;
