import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import closeIcon from "@src/assets/basicIcon/blackCloseIcon.svg";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useFindAllGoStopProductList, useFindAllProduct } from "@src/hooks/subscribe/useSubsProducts";
import { IProduct, useFindAllRoadPlanProducts, useFindAllStateLandProducts } from "@src/hooks/stateLand/useStateLand";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import ProductContents from "./ProductContents";
import { useGetStateLandSubs, useGetSubsInfo } from "@src/hooks/subscribe/useSubscribe";
import { useFindAllBannerList } from "@src/hooks/banner/useBannerList";
import SubscriptionBanner from "./SubscriptionBanner";
import SubscriptionDesc from "./SubscriptionDesc";
import SubscriptionFaq from "./SubscriptionFaq";
import SubscriptionUsingInfo from "./SubscriptionUsingInfo";
import IosBottomInfoPage from "./IosBottomInfoPage";
import IosStateLandContents from "./IosStateLandContents";
import IosProductContents from "./IosProductContents";
import IosNewProductContents from "./iosNewProductContents";
import IosStateRoadContents from "./IosRoadPlanContents";
import SubscriptionSticky from "./SubscriptionSticky";
import { useObserver } from "@src/util/stickyObserver";

interface Props { }

const IosSubscriptionInfo: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const rootBoxRef = useRef<HTMLDivElement | null>(null);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [openModal, setOpenModal] = useState<number>(0); // 1 무료구독이용중 2 구독이용중 3 필터 이용중 4 인앱 이용중 5 구독이용중변경 99 에러
    const [modalContents, setModalContents] = useState<any>(<div />);

    const { data, refetch } = useFindAllProduct(searchParams?.get("type") ?? "8");
    const { data: stateLandProducts, refetch: refetchStateLandProducts } = useFindAllStateLandProducts(
        searchParams.get("type") ?? "8",
    );
    const { data: roadPlanProducts, refetch: refetchRoadPlanProducts } = useFindAllRoadPlanProducts(
        searchParams.get("type") ?? "8",
    );

    const { data: goStopProducts, refetch: refetchGoStopProducts } = useFindAllGoStopProductList(
        searchParams.get("type") ?? "8",
    );

    const { data: banner, refetch: refetchBanner } = useFindAllBannerList("appSubscriptionPage");
    const getOrder = searchParams.get("order");


    const [isFocused, setIsFocused] = useState("topRef");
    const topRef = useObserver('topRef', setIsFocused);
    const landRef = useObserver('landRef', setIsFocused);
    const roadRef = useObserver("roadRef", setIsFocused)
    const goStopRef = useObserver('goStopRef', setIsFocused);
    const storeGoStopRef = useObserver('storeGoStopRef', setIsFocused)

    const onClickPayment = async () => {
        const res = await Promise.all([useGetSubsInfo(), useGetStateLandSubs()]);
        if (selectedProduct.type === "subscription") {
            if (res[0].isFreeIssueSubs) {
                setModalContents(onChangeModalContents(1));
                setOpenModal(1);
                return;
            }
            // if (res[0].subsNo && !res[1].totalCnt && selectedProduct?.subsCategory === 3) {
            //     setModalContents(onChangeModalContents(2));
            //     setOpenModal(2);
            //     return;
            // }
            if (res[0]?.subsNo && res[0]?.isInAppSubs && res[0].nextRenewAtToString) {
                setModalContents(onChangeModalContents(4));
                setOpenModal(4);
                return;
            }
            if (res[0]?.subsNo) {
                setModalContents(onChangeModalContents(5));
                setOpenModal(5);
                return;
            }
        }
        if (selectedProduct.type === "state_land_filter" && res[1]?.totalCnt) {
            if (
                res[1]?.allPnus === null ||
                res[1]?.allPnus?.some((val: any) => selectedProduct?.pnus?.some((pnu: any) => pnu === val))
            ) {
                setModalContents(onChangeModalContents(3));
                setOpenModal(3);
                return;
            }
        }
        const params = {
            productCode: selectedProduct.code,
            productType: selectedProduct.type,
            type: selectedProduct.type === "subscription" ? "subscription" : "state_land_filter",
            renewTerm: ["monthly", "yearly"]?.some((val) => val === selectedProduct?.renewTerm),
        };
        transferToNative(JSON.stringify(params), "payProductInApp");
    };

    const onChangeModalContents = (type: number) => {
        switch (type) {
            case 1:
                return (
                    <>
                        <p css={dialogTitleStyle}>미스고 구독 무료체험 중 입니다</p>
                        <p css={dialogContentStyle}>
                            결제를 진행하시면 무료체험이 즉시 종료되고
                            <br />더 많은 혜택의 구독이 시작돼요
                        </p>
                    </>
                );
            case 2:
                return (
                    <>
                        <p css={dialogTitleStyle}>이용 중인 구독이 있습니다</p>
                        <p css={dialogContentStyle}>
                            국공유지 이용권만 따로 구매해 보세요
                            <br />
                            <br />더 저렴한 가격으로
                            <br />
                            국공유지 검색을 이용할 수 있어요.
                        </p>
                    </>
                );
            case 3:
                return (
                    <>
                        <p css={dialogTitleStyle}>
                            선택한 지역은 이미 구매한 <br />
                            국공유지 상품이 있습니다
                            <br />
                            <br />
                            추가로 구매하시겠습니까?
                        </p>
                    </>
                );
            case 4:
                return (
                    <>
                        <p css={dialogTitleStyle}>
                            인앱 정기결제 구독 이용 중에는
                            <br />
                            다른 구독 상품으로 변경할 수 없어요.
                            <br />
                            <br />
                            구독이 만료된 후 결제해주세요
                        </p>
                    </>
                );
            case 5:
                return (
                    <>
                        <p css={dialogTitleStyle}>이용 중인 구독이 있습니다</p>
                        <p css={dialogContentStyle}>
                            새로운 구독을 결제하면
                            <br />
                            이용 중인 구독이 변경됩니다
                        </p>
                    </>
                );
            case 99:
                return (
                    <>
                        <p css={dialogTitleStyle}>에러</p>
                        <p css={dialogContentStyle}>
                            알 수 없는 이유로 에러가
                            <br />
                            발생했습니다. 다시 시도해주세요.
                        </p>
                    </>
                );
            default:
                return (
                    <>
                        <p css={dialogTitleStyle} />
                    </>
                );
        }
    };

    useEffect(() => {
        refetch();
        refetchStateLandProducts();
        refetchRoadPlanProducts();
        refetchGoStopProducts();
        refetchBanner();
    }, []);

    useEffect(() => {
        if (searchParams.get("order")) {
            const intervalId = setTimeout(() => {
                focusOrder();
            }, 300);
            return () => {
                clearTimeout(intervalId)
            }
        }
    }, [searchParams.get("order")]);

    const focusOrder = () => {
        if (searchParams.get("order") === "stateLandFilter") {
            landRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        } else if (searchParams.get("order") === "roadPlan") {
            roadRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        } else if (searchParams.get("order") === "premiumFilter") {
            goStopRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        } else if (searchParams.get("order") === "retailProfitRate") {
            storeGoStopRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }
    }

    const onScroll = (
        e: string
    ) => {
        let refName = topRef;
        if (e === "topRef") refName = topRef;
        if (e === "landRef") refName = landRef;
        if (e === "goStopRef") refName = goStopRef;
        if (e === "storeGoStopRef") refName = storeGoStopRef;
        if (refName.current) {
            if (e === "topRef") {
                refName.current.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            } else {
                refName?.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }
            setIsFocused(e);
        }
    }

    return (
        <>
            <div css={topTitleStyle}>
                <p>구독 이용권</p>
                <img
                    src={closeIcon}
                    alt=""
                    className="close"
                    onClick={() => {
                        transferToNative("done", "closeWebView");
                    }}
                />
            </div>
            <div css={rootBoxStyle}>
                <SubscriptionSticky onScroll={(refId: string) => { onScroll(refId) }} isFocus={isFocused} />
                <div css={topDescStyle} ref={topRef}>
                    특별한 혜택이 가득
                    <br />
                    미스고부동산 구독 지금 시작하세요
                </div>
                {/* <EventProductContents */}
                <IosNewProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 3)?.slice(0, 4)}
                    subsCategory={3}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                {/* <ProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 3)?.slice(0, 4)}
                    subsCategory={3}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                /> */}
                <IosProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 2)?.slice(0, 4)}
                    subsCategory={2}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <IosProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 1)?.slice(0, 4)}
                    subsCategory={1}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <IosStateLandContents
                    landRef={landRef}
                    selectedProduct={selectedProduct}
                    stateLandList={stateLandProducts?.stateLandList}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <IosStateRoadContents
                    type="road_plan"
                    divRef={roadRef}
                    selectedProduct={selectedProduct}
                    stateLandList={roadPlanProducts?.roadPlanList}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <IosStateRoadContents
                    divRef={goStopRef}
                    type="premium_filter"
                    selectedProduct={selectedProduct}
                    stateLandList={goStopProducts?.gostopList.filter((data) => data.type === 'premium_filter')}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <IosStateRoadContents
                    divRef={storeGoStopRef}
                    type="Retail_profit_rate"
                    selectedProduct={selectedProduct}
                    stateLandList={goStopProducts?.gostopList.filter((data) => data.type === 'Retail_profit_rate')}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />

                <SubscriptionBanner banner={banner} />
                <SubscriptionDesc />
                <SubscriptionFaq />
                <SubscriptionUsingInfo />
                {searchParams.get("type") === "7" && (
                    <>
                        <div css={restoreBoxStyle} onClick={() => transferToNative("", "restoreProduct")}>
                            <p>이미 이용권을 구매하셨나요?</p>
                            <p>App Store 구매내역 복원하러 가기</p>
                        </div>
                    </>
                )}
                {/* <button
                    onClick={() => {
                        const params = {
                            title: "테스트이름",
                            url: "https://triplenine.notion.site/4f3706b2294140118606bec5d9659e14?pvs=4",
                        };
                        transferToNative(JSON.stringify(params), "openExternalMap");
                    }}
                >
                    test
                </button> */}
            </div>
            <IosBottomInfoPage
                selectedProduct={selectedProduct}
                onClickChange={() => topRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" })}
                onClickSubmit={onClickPayment}
            />
            {!!openModal && (
                <ConfirmModal
                    resultMessage={modalContents}
                    onConfirm={() => {
                        if (openModal === 1 || openModal === 3 || openModal === 5) {
                            const params = {
                                productCode: selectedProduct.code,
                                productType: selectedProduct.type,
                                type: selectedProduct.type === "subscription" ? "subscription" : "state_land_filter",
                                renewTerm: ["monthly", "yearly"]?.some((val) => val === selectedProduct?.renewTerm),
                            };
                            transferToNative(JSON.stringify(params), "payProductInApp");
                            setOpenModal(0);
                        } else {
                            setModalContents(<div />);
                            setOpenModal(0);
                        }
                    }}
                    cancelText="닫기"
                    buttonText={openModal === 1 ? "시작하기" : openModal === 3 || openModal === 5 ? "결제하기" : "확인"}
                    onCancel={
                        openModal === 1 || openModal === 3 || openModal === 5
                            ? () => {
                                setModalContents(<div />);
                                setOpenModal(0);
                            }
                            : undefined
                    }
                />
            )}
        </>
    );
};

export default IosSubscriptionInfo;

const topTitleStyle = css`
    padding: 14px;
    color: #282828;
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    position: relative;
    text-align: center;
    & > .close {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0%, -50%);
        padding: 15px;
    }
`;

const rootBoxStyle = css`
    height: calc(100dvh - 56px);
    height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 200px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const topDescStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    padding: 14px;
    background-color: #fbf8f8;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const dialogTitleStyle = css`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 0;
    word-break: break-word;
`;

const restoreBoxStyle = css`
    margin: 14px;
    padding: 14px;
    background-color: #eefff5;
    color: #0c002c;
    text-align: center;
    font-weight: 400;
    p:first-of-type {
        font-size: 18px;
        line-height: 28px;
    }
    p:last-of-type {
        margin-top: 4px;
        font-size: 13px;
        line-height: 20px;
    }
`;
