import styled from "@emotion/styled";
import { Button } from "@src/components";
import { Common } from "@src/styles/Common";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backBtn from "../../../assets/LeftIcon.png";
import nextImg from "../../../assets/vimeoNextImg.png";
import prevImg from "../../../assets/vimeoPrevImg.png";
import writeGrade from "../../../assets/writeGrade.png";
import SugangCurriculum from "./SugangCurriculum";
import Lottie from "lottie-react";
import loading from "../../../assets/Loading.json";
import Data from "./Data";

import {
    useGetSugangCurriculum,
    useGetSugangDetail,
    useGetSugangResources,
    useSaveViewTime,
} from "@src/hooks/campus/useCampusSugang";
import Vimeo from "@vimeo/player";
import {
    CampusResourceResponseDto,
    CampusSugangCurriculumDataDto,
    CampusSugangCurriculumDto,
    CampusSugangCurriculumListResponseDto,
    CampusSugangDataDto,
} from "@src/hooks/campus/dto/CampusSugang.dto";
interface ToastType {
    showToast: boolean;
    toastMessage: string;
}

const Sugang = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [type, setType] = useState<string>("list");
    const [sugangData, setSugangData] = useState<CampusSugangDataDto>();
    const [sugangCurriculum, setSugangCurriculum] = useState<CampusSugangCurriculumListResponseDto>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [resource, setResource] = useState<CampusResourceResponseDto>();
    const [videoData, setVideoData] = useState<{ videoUrl: string; no: number; subOrder: number; userViewedTime: number }>({
        videoUrl: "",
        no: 0,
        subOrder: 0,
        userViewedTime: 0,
    });
    const [toast, setToast] = useState<ToastType>({ showToast: false, toastMessage: "" });
    const [isVimeoLoading, setIsVimeoLoading] = useState<boolean>(true);
    const [isDownLoad, setIsDownLoad] = useState<boolean>(false);
    const tryGetSugangDetail = async () => {
        useGetSugangDetail(location.state.no).then((res: any) => {
            setSugangData(res.data);
            useGetSugangResources(res.data.campusNo).then((resouresRes: any) => {
                setResource(resouresRes.data);
                setIsLoading(false);
            });
        });
    };

    const tryGetSugangCurriculum = async () => {
        const curriculumResponse = await useGetSugangCurriculum(location.state.no);
        setSugangCurriculum(curriculumResponse.data);
    };

    useEffect(() => {
        tryGetSugangDetail();
        tryGetSugangCurriculum();
    }, []);

    const curriculumData: CampusSugangCurriculumDataDto[] = sugangCurriculum?.list.reduce(
        (q: any, item: CampusSugangCurriculumDto, idx: number) => {
            if (q.findIndex((w: any) => w.mainOrder === item.mainOrder) === -1) {
                const newData: any = {
                    mainOrder: item.mainOrder,
                    sub: sugangCurriculum?.list
                        .filter((w: any) => w.mainOrder === item.mainOrder)
                        .sort((e: any, r: any): any => e.subOrder - r.subOrder),
                };
                q.push(newData);
            }
            return q;
        },
        [],
    );

    useEffect(() => {
        sugangCurriculum?.totalCnt &&
            sugangData &&
            curriculumData &&
            !videoData.videoUrl &&
            setVideoData({
                videoUrl: sugangData.recentVideoUrl ? sugangData.recentVideoUrl : curriculumData[0].sub[0]?.videoUrl,
                no: sugangData.recentVideoUrl ? sugangData.recentCurriculumNo : curriculumData[0].sub[0]?.no,
                subOrder: sugangData.recentVideoUrl ? sugangData.recentSubOrder : curriculumData[0].sub[0]?.subOrder,
                userViewedTime: sugangData.recentVideoUrl ? sugangData.recentViewedTime : 0,
            });
    }, [sugangData, sugangCurriculum]);

    const onClickPrev = () => {
        if (videoData.no === curriculumData[0].sub[0].no) {
            setToast({
                showToast: true,
                toastMessage: "첫번째 강의입니다",
            });
        } else {
            const prevData = sugangCurriculum?.list.filter(
                (item: CampusSugangCurriculumDto) => item.subOrder === videoData.subOrder - 1,
            );
            prevData &&
                setVideoData({
                    videoUrl: prevData[0].videoUrl,
                    no: prevData[0].no,
                    subOrder: prevData[0].subOrder,
                    userViewedTime: prevData[0].userViewedTime,
                });
        }
    };

    const onClickNext = () => {
        if (
            videoData.no ===
            curriculumData[curriculumData.length - 1].sub[curriculumData[curriculumData.length - 1].sub.length - 1].no
        ) {
            setToast({
                showToast: true,
                toastMessage: "마지막 강의입니다",
            });
        } else {
            const nextData = sugangCurriculum?.list.filter(
                (item: CampusSugangCurriculumDto) => item.subOrder === videoData.subOrder + 1,
            );
            nextData &&
                setVideoData({
                    videoUrl: nextData[0].videoUrl,
                    no: nextData[0].no,
                    subOrder: nextData[0].subOrder,
                    userViewedTime: nextData[0].userViewedTime,
                });
        }
    };

    useEffect(() => {
        tryGetSugangCurriculum();
    }, [videoData]);

    useEffect(() => {
        location.state.showToast && setToast({ showToast: true, toastMessage: "수강평 제출이 완료되었습니다" });
    }, [location]);

    useEffect(() => {
        if (toast.showToast) {
            setTimeout(() => setToast({ showToast: false, toastMessage: "" }), 2000);
        }
    }, [toast]);

    const [player, setPlayer] = useState<any>();

    //비메오 영상 등록 및 이벤트리스너 초기화
    useEffect(() => {
        player?.off("pause");
        player?.off("play");
        setPlayer(null);
        if (!isLoading) {
            setTimeout(() => {
                const iframe: any = document.querySelector("iframe");
                setPlayer(new Vimeo(iframe));
            }, 1000);
        }
    }, [isLoading, videoData]);

    const getCurrnetTime = async () => {
        const currentTime = await player?.getCurrentTime().then((s: any) => {
            return Math.floor(s);
        });
        sugangData && useSaveViewTime(sugangData.no, videoData.no, currentTime);
    };
    const intervalRef = useRef<any>();
    //비메오 시청 시간 기록
    useEffect(() => {
        if (player) {
            setIsVimeoLoading(false);
            let newInterval: any;
            player.on("play", function () {
                newInterval = setInterval(() => {
                    getCurrnetTime();
                }, 5000);
                intervalRef.current = newInterval;
            });
            player.on("pause", function () {
                clearInterval(newInterval);
                getCurrnetTime();
            });
            return () => clearInterval(newInterval);
        }
    }, [player]);

    useEffect(() => {
        setIsDownLoad(Boolean(sugangData?.downloadAt));
    }, [sugangData]);
    return (
        <>
            {isLoading ? (
                <StyledLottieDiv>
                    <div>
                        <Lottie animationData={loading} />
                    </div>
                </StyledLottieDiv>
            ) : (
                <StyledSugang>
                    <div className="SugangTitle">
                        <div className="backBtn">
                            <img
                                src={backBtn}
                                alt=""
                                onClick={() => {
                                    navigate("/campus/my");
                                }}
                            />
                        </div>
                        <div className="text">{sugangData?.title}</div>
                    </div>
                    {isVimeoLoading && (
                        <StyledVimeoLoading>
                            <div>
                                <Lottie animationData={loading} />
                            </div>
                        </StyledVimeoLoading>
                    )}
                    <div className="vimeo">
                        <iframe
                            src={
                                videoData.subOrder === 0
                                    ? `${videoData.videoUrl}#t=${videoData.userViewedTime}`
                                    : videoData.videoUrl.indexOf("?")
                                    ? `${videoData.videoUrl}&autoplay=1&muted=1&playsinline=1#t=${videoData.userViewedTime}`
                                    : `${videoData.videoUrl}?autoplay=1&muted=1&playsinline=1#t=${videoData.userViewedTime}`
                            }
                            id="amin"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="vimeoNav">
                        <div className="vimeoBtnDiv">
                            <button onClick={onClickPrev}>
                                <img src={prevImg} alt="" /> <span>이전강의</span>
                            </button>

                            <button onClick={onClickNext}>
                                <span>다음강의</span>
                                <img src={nextImg} alt="" />
                            </button>
                        </div>
                        {!sugangData?.reviewedAt && (
                            <div
                                className="writeGrade"
                                onClick={() => {
                                    navigate(`/campus/sugang/grade/${sugangData?.campusNo}`, {
                                        state: { campusNo: sugangData?.campusNo, no: sugangData?.no, title: sugangData?.title },
                                    });
                                }}
                            >
                                수강평 쓰기
                                <img src={writeGrade} alt="" />
                            </div>
                        )}
                    </div>
                    <div className="SugangDetail">
                        <div className="SugangNav">
                            <Button
                                label="강의 목차"
                                color="blue"
                                size="small"
                                handleClick={() => {
                                    setType("list");
                                }}
                                styles={{
                                    backgroundColor: type === "list" ? "#2275ee" : "#FDFAFA",
                                    color: type === "list" ? "#FFFFFF" : `${Common.colors.normalTextColor}`,
                                    fontWeight: type === "list" ? 700 : 500,
                                    transition: "all 0s",
                                }}
                            />
                            <Button
                                label="자료실"
                                color="blue"
                                size="small"
                                handleClick={() => {
                                    setType("data");
                                }}
                                styles={{
                                    backgroundColor: type === "data" ? "#2275ee" : "#FDFAFA",
                                    color: type === "data" ? "#FFFFFF" : `${Common.colors.normalTextColor}`,
                                    fontWeight: type === "data" ? 700 : 500,
                                    transition: "all 0s",
                                }}
                            />
                        </div>
                        <div className="currculum">
                            {type === "list" ? (
                                <SugangCurriculum
                                    curriculumData={curriculumData}
                                    recentMainOrder={sugangData?.recentMainOrder}
                                    videoData={videoData}
                                    setVideoData={setVideoData}
                                />
                            ) : (
                                <Data
                                    data={resource}
                                    downloadAt={sugangData?.downloadAt}
                                    registerNo={location.state.no}
                                    isDownLoad={isDownLoad}
                                    setIsDownLoad={setIsDownLoad}
                                />
                            )}
                        </div>
                    </div>
                    {toast.showToast && <StyledToast isShowToast={toast.showToast}>{toast.toastMessage}</StyledToast>}
                </StyledSugang>
            )}
        </>
    );
};

export default Sugang;

const StyledSugang = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
    .SugangTitle {
        width: 100%;
        min-height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;

        color: ${Common.colors.normalTextColor};
        position: relative;
        .text {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            /* display: flex;
            justify-content: center;
            align-items: center; */
        }
        .backBtn {
            position: absolute;
            width: 10px;
            height: 17px;
            top: 46%;
            left: 20px;
            transform: translate(0, -50%);
            & > img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .vimeo {
        /* width: 100%;
        position: relative; */
        /* min-height: 216px; */
        /* height: 225px; */
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        & > iframe {
            width: 100vw;
            height: 100%;
        }
        video {
            width: 100vw;
        }
    }
    .vimeo iframe,
    .vimeo object,
    .vimeo embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .vimeoNav {
        width: 100vw;
        background-color: #282828;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px;
        .vimeoBtnDiv {
            display: flex;
            gap: 14px;
            & > button {
                width: 94px;
                height: 32px;
                border-radius: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 400;
                font-size: 14px;
                gap: 5px;
                color: #282828;
                background-color: white;

                & > img {
                    width: 16px;
                }
            }
        }
    }
    .writeGrade {
        color: white;
        font-weight: 400;
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 7px;
        & > img {
            width: 17px;
        }
    }
    .SugangDetail {
        width: 100%;
        padding: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .SugangNav {
            width: 100%;
            gap: 8px;
            display: flex;
            & > button {
                width: 50%;
                height: 46px;
            }
        }
        .currculum {
            padding-top: 14px;
            /* height: calc(100dvh - 410px);
            overflow-y: scroll; */
        }
    }
`;

const StyledLottieDiv = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const StyledVimeoLoading = styled.div`
    width: 100%;
    height: 220px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10px;
    top: 56px;
    position: absolute;
    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const StyledToast = styled.div<{ isShowToast: boolean }>`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${(props) => (props.isShowToast ? 0.7 : 0)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 50px;
    border-radius: 14px;
    position: absolute;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
    left: 50%;
    transform: translate(-50%, 0);
`;
