import axios from "axios";
import { axiosInstance } from "@src/api/axiosConfig";
import { buildingRegisterInfo, check, items, pdf, register, search } from "./axiosPath";
import { BuildingRegisterListRequestDto } from "./dto/BuildingRegister.dto";

export const useBuildingRegisterList = async (params: BuildingRegisterListRequestDto) => {
    const queryString = new URLSearchParams(params as any).toString();
    const response = await axiosInstance.get(`${items}?${queryString}`);
    return response;
};

export const useBuildingRegisterCheck = async () => {
    const response = await axiosInstance.get(`${check}`);
    return response;
};

export const useBuildingRegisterPdf = async (id: string) => {
    const response = await axiosInstance.get(`${pdf}/${id}`);
    return response;
};

export const useBuildingRegisterSearch = async (searchText: string) => {
    const response = await axiosInstance.get(`${search}/${searchText}`, {
        timeout: 10000,
    });
    return response;
};

export const useBuildingRegisterItems = async (searchText: string, queryString: string) => {
    const response = await axiosInstance.get(`${items}/${searchText}?${queryString}`, {
        timeout: 10000,
    });
    return response;
};

export const useBuildingRegister = async (
    bldrgstSeqno: string,
    untClsfCd: string,
    regstrKindCd: string,
    address: string,
    detailAddress: string,
    bldNm: string,
    dongNm: string,
    hoNm: string,
    mainPrposNm: string,
    buildingPnu: string,
) => {
    const response = await axiosInstance.post(
        `${register}`,
        {
            bldrgstSeqno,
            untClsfCd,
            regstrKindCd,
            address,
            detailAddress,
            bldNm: bldNm || "",
            dongNm: dongNm || "",
            hoNm: hoNm || "",
            mainPrposNm: mainPrposNm || "",
            buildingPnu,
        },
        {
            timeout: 20000,
        },
    );
    return response;
};
