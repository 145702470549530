import { css } from "@emotion/react";
import { IBanner } from "@src/hooks/banner/useBannerList";
import React from "react";

interface Props {
    banner?: IBanner[];
}

const SubscriptionBanner: React.FC<Props> = ({ banner }) => {
    return (
        <>
            {!!banner?.[0]?.imgurl && (
                <div css={rootBoxStyle}>
                    <img src={banner?.[0]?.imgurl} alt="" width="100%" />
                </div>
            )}
        </>
    );
};

export default SubscriptionBanner;

const rootBoxStyle = css`
    padding: 20px 0;
`;
