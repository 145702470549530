import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const fetchData = async (
    params?: string,
): Promise<getCaseNumberSearchedList[]> => {
    const response = await axiosInstance.get(
        `${axiosPath.caseNumberList}?query=${params}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload.result || [];

    return responseData;
};
const useGetSearchItemCaseNumber = async (params: any) => {
    return await fetchData(params);
};

export type GetCallSearchedListResponseType = {
    errorCode: number;
    list: getCaseNumberSearchedList[];
    total: number;
};

export type getCaseNumberSearchedList = {
    item_id: string;
    sub_id: string;
    bid_type: number;
    item_number?: number;
    address: string;
    use: string;
    min_price: number;
};
export { useGetSearchItemCaseNumber };
