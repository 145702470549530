import React from "react";
import icoArrowDown from "../../assets/ico_arrow_down_black_bold.png";

interface DeungiDropDownCardProps {
    title: string;
    descriptions: { id: number; desc: string }[];
    active: boolean;
    onClick: () => void;
}

const DeungiDropDownCard = ({ title, descriptions, active, onClick }: DeungiDropDownCardProps) => {
    return (
        <article className="dropDownItem" onClick={onClick}>
            <div className="title">
                <h3>{title}</h3>
                <img src={icoArrowDown} className={active ? "up" : ""} />
            </div>
            <ul className={active ? "contents show" : "contents hidden"}>
                {descriptions.map(({ id, desc }) => (
                    <li key={id.toString()}>
                        <span>{desc}</span>
                    </li>
                ))}
            </ul>
        </article>
    );
};

export default DeungiDropDownCard;
