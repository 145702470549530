import React, { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import closeIcon from "@src/assets/basicIcon/blackCloseIcon.svg";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useFindAllGoStopProductList, useFindAllProduct } from "@src/hooks/subscribe/useSubsProducts";
import {
    IProduct,
    initialProductData,
    useFindAllRoadPlanProducts,
    useFindAllStateLandProducts,
} from "@src/hooks/stateLand/useStateLand";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import ProductContents from "./ProductContents";
import BottomInfoPage from "./BottomInfoPage";
import StateLandContents from "./StateLandContents";
import { useGetStateLandSubs, useGetSubsInfo } from "@src/hooks/subscribe/useSubscribe";
import { useFindAllBannerList } from "@src/hooks/banner/useBannerList";
import SubscriptionBanner from "./SubscriptionBanner";
import SubscriptionDesc from "./SubscriptionDesc";
import SubscriptionFaq from "./SubscriptionFaq";
import SubscriptionUsingInfo from "./SubscriptionUsingInfo";
import EventProductContents from "./EventProductContents";
import NewProductContents from "./NewProductContents";
import SubscriptionSticky from "./SubscriptionSticky";
import { filterProductList } from "@src/util/filterProduct";
import StateRoadContents from "./StateRoadContents";
import { useFindOneStateLandSubs } from "@src/hooks/stateLand/useGetStateLandSubsInfo";
import { useFindOneRoadPlanSubs } from "@src/hooks/roadPlan/useGetRoadPlanLandSubsInfo";
import { useObserver } from "@src/util/stickyObserver";

interface Props { }

const SubscriptionInfo: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const [selectedProduct, setSelectedProduct] = useState<any>(initialProductData);
    const [openModal, setOpenModal] = useState<number>(0); // 1 무료구독이용중 2 구독이용중 3 필터 이용중 4 인앱 이용중 5 구독이용중변경 99 에러
    const [modalContents, setModalContents] = useState<any>(<div />);
    const [selectedProductList, setSelectedProductList] = useState<IProduct[]>([]);

    const isRedirectFromBanner = searchParams.get("referrer") === "banner";
    const eventType = isRedirectFromBanner && searchParams?.get("type") === "6" ? 1 : undefined;

    const { data, refetch } = useFindAllProduct(searchParams?.get("type") ?? "6", false, eventType);
    const { data: stateLandProducts, refetch: refetchStateLandProducts } = useFindAllStateLandProducts(
        searchParams.get("type") ?? "8",
    );
    const { data: roadPlanProducts, refetch: refetchRoadPlanProducts } = useFindAllRoadPlanProducts(
        searchParams.get("type") ?? "8",
    );

    const { data: goStopProducts, refetch: refetchGoStopProducts } = useFindAllGoStopProductList(
        searchParams.get("type") ?? "8",
    );

    const { data: banner, refetch: refetchBanner } = useFindAllBannerList("appSubscriptionPage");

    //* 상품 변경 관련 ref 값
    const [isFocused, setIsFocused] = useState("topRef");
    const topRef = useObserver('topRef', setIsFocused);
    const landRef = useObserver('landRef', setIsFocused);
    const roadRef = useObserver('roadRef', setIsFocused);
    const goStopRef = useObserver('goStopRef', setIsFocused);
    const storeGoStopRef = useObserver('storeGoStopRef', setIsFocused);

    const { refetch: refetchStateLandSubs } = useFindOneStateLandSubs();

    const { refetch: refetchRoadPlanSubs } = useFindOneRoadPlanSubs();


    const onClickPayment = async () => {
        let stateLandData: any;
        let roadPlanData: any;
        refetchStateLandSubs().then((response) => {
            if (response.isFetched) {
                stateLandData = response.data;
            }
        });
        refetchRoadPlanSubs().then((response) => {
            if (response.isFetched) {
                roadPlanData = response.data;
            }
        });
        const res = await Promise.all([useGetSubsInfo(), useGetStateLandSubs()]);
        if (selectedProductList.some((data) => data.type === "subscription")) {
            if (res[0].isFreeIssueSubs) {
                setModalContents(onChangeModalContents(1));
                setOpenModal(1);
                return;
            }
            if (res[0]?.subsNo && res[0]?.isInAppSubs && res[0].nextRenewAtToString) {
                setModalContents(onChangeModalContents(4));
                setOpenModal(4);
                return;
            }
            if (res[0]?.subsNo) {
                setModalContents(onChangeModalContents(5));
                setOpenModal(5);
                return;
            }
        }
        if (
            selectedProductList.some((data) => data.type === "state_land_filter") &&
            res[1]?.totalCnt
        ) {
            if (
                stateLandData?.allPnus === null ||
                selectedProductList
                    .map((e) => stateLandData?.allPnus?.some((val: any) => e?.pnus?.some((pnu) => pnu == val)))
                    .some((data) => data === true)
            ) {
                setModalContents(onChangeModalContents(3, stateLandData, roadPlanData));
                setOpenModal(3);
                return;
            }
        }
        const params = {
            productCode: selectedProductList.map((e) => e.code),
        };
        transferToNative(JSON.stringify(params), "payProductInApp");
    };

    useEffect(() => {
        filterProductList({
            selectedProduct,
            selectedProductList,
            setSelectedProduct,
            setSelectedProductList,
            onChangeModalContents,
            setModalContents,
            setOpenModal,
        });
    }, [selectedProduct]);

    useEffect(() => {
        if (searchParams.get("order")) {
            const intervalId = setTimeout(() => {
                focusOrder();
            }, 300)
            return () => {
                clearTimeout(intervalId)
            }
        }
    }, [searchParams.get("order")]);

    const focusOrder = () => {
        if (searchParams.get("order") === "stateLandFilter") {
            landRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        } else if (searchParams.get("order") === "roadPlan") {
            roadRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        } else if (searchParams.get("order") === "premiumFilter") {
            goStopRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        } else if (searchParams.get("order") === "retailProfitRate") {
            storeGoStopRef?.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }
    }

    const onChangeModalContents = (type: number, stateLandData?: any, roadPlanData?: any) => {
        switch (type) {
            case 1:
                return (
                    <>
                        <p css={dialogTitleStyle}>미스고 구독 무료체험 중 입니다</p>
                        <p css={dialogContentStyle}>
                            결제를 진행하시면 무료체험이 즉시 종료되고
                            <br />더 많은 혜택의 구독이 시작돼요
                        </p>
                    </>
                );
            case 3:
                return (
                    <>
                        <div>
                            <p css={dialogTitleStyle}>구매 후 이용 중인 상품이 있습니다.</p>
                            <div
                                style={{
                                    display: "flex",
                                    padding: "16px 0px",
                                    fontSize: "14px",
                                    gap: "10px",
                                }}
                            >
                                <p style={{ whiteSpace: "nowrap" }}>상품명: </p>
                                <span
                                    style={{
                                        color: "#505050",
                                        width: "210px",
                                        maxHeight: "60px",
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {stateLandData?.list.map((e: any) => (
                                        <span
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflowX: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                            key={String(e.no)}
                                        >
                                            {e.productName && e.productName}
                                            <br />
                                        </span>
                                    ))}
                                </span>
                            </div>
                            <p>추가로 구매하시겠습니까?</p>
                        </div>
                    </>
                );
            case 4:
                return (
                    <>
                        <p css={dialogTitleStyle}>
                            인앱 정기결제 구독 이용 중에는
                            <br />
                            다른 구독 상품으로 변경할 수 없어요.
                            <br />
                            <br />
                            구독이 만료된 후 결제해주세요
                        </p>
                    </>
                );
            case 5:
                return (
                    <>
                        <p css={dialogTitleStyle}>이용 중인 구독이 있습니다</p>
                        <p css={dialogContentStyle}>
                            새로운 구독을 결제하면
                            <br />
                            이용 중인 구독이 변경됩니다
                        </p>
                    </>
                );
            case 6:
                return (
                    <>
                        <p>
                            구독상품에 이미 해당 상품이
                            <br />
                            포함되어 있습니다.
                            <br />
                            다른 상품을 선택해주세요.
                        </p>
                    </>
                );
            case 99:
                return (
                    <>
                        <p css={dialogTitleStyle}>에러</p>
                        <p css={dialogContentStyle}>
                            알 수 없는 이유로 에러가
                            <br />
                            발생했습니다. 다시 시도해주세요.
                        </p>
                    </>
                );
            default:
                return (
                    <>
                        <p css={dialogTitleStyle} />
                    </>
                );
        }
    };

    useEffect(() => {
        refetch();
        refetchStateLandProducts();
        refetchRoadPlanProducts();
        refetchGoStopProducts();
        refetchBanner();
    }, []);

    const onScroll = (
        e: string
    ) => {
        let refName = topRef;
        if (e === "topRef") refName = topRef;
        if (e === "landRef") refName = landRef;
        if (e === "roadRef") refName = roadRef;
        if (e === "goStopRef") refName = goStopRef;
        if (e === "storeGoStopRef") refName = storeGoStopRef;
        if (refName.current) {
            if (e === "topRef") {
                refName.current.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            } else {
                refName?.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }
            setIsFocused(e);
        }
    }

    return (
        <>
            <div css={topTitleStyle}>
                <p>구독 이용권</p>
                <img
                    src={closeIcon}
                    alt=""
                    className="close"
                    onClick={() => {
                        transferToNative("done", "closeWebView");
                    }}
                />
            </div>
            <div css={rootBoxStyle(selectedProductList.length >= 3 ? 5 : selectedProductList.length + 2)}>
                <SubscriptionSticky onScroll={(refId: string) => { onScroll(refId) }} isFocus={isFocused} />
                <div ref={topRef} css={topDescStyle}>
                    특별한 혜택이 가득
                    <br />
                    미스고부동산 구독 지금 시작하세요
                </div>
                {/* <EventProductContents */}
                <NewProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 3)?.slice(0, 4)}
                    subsCategory={3}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                    selectedProductList={selectedProductList}
                />
                {/* <ProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 3)?.slice(0, 4)}
                    subsCategory={3}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                /> */}
                <ProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 2)?.slice(0, 4)}
                    subsCategory={2}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                    selectedProducList={selectedProductList}
                />
                <ProductContents
                    selectedProduct={selectedProduct}
                    productList={data?.list?.filter((val) => val?.subsCategory === 1)?.slice(0, 4)}
                    subsCategory={1}
                    selectedProducList={selectedProductList}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <div css={premiumBanner}>
                    <p>추가 프리미엄 상품도 놓치지 마세요</p>
                </div>
                <StateLandContents
                    landRef={landRef}
                    selectedProduct={selectedProduct}
                    stateLandList={stateLandProducts?.stateLandList}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                    selectedProductList={selectedProductList}
                />
                <StateRoadContents
                    type="road_plan"
                    divRef={roadRef}
                    selectedProduct={selectedProduct}
                    stateLandList={roadPlanProducts?.roadPlanList}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                    selectedProductList={selectedProductList}
                />
                <div css={goStopBanner}>
                    <p>AI 가치평가(Go-Stop) 상품<br />따로 확인해 보세요</p>
                </div>
                <StateRoadContents
                    divRef={goStopRef}
                    type="premium_filter"
                    selectedProduct={selectedProduct}
                    stateLandList={goStopProducts?.gostopList.filter((data) => data.type === 'premium_filter')}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                    selectedProductList={selectedProductList}
                />
                <StateRoadContents
                    divRef={storeGoStopRef}
                    type="Retail_profit_rate"
                    selectedProduct={selectedProduct}
                    stateLandList={goStopProducts?.gostopList.filter((data) => data.type === 'Retail_profit_rate')}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                    selectedProductList={selectedProductList}
                />
                <SubscriptionBanner banner={banner} />
                <SubscriptionDesc />
                <SubscriptionFaq />
                <SubscriptionUsingInfo />
                {searchParams.get("type") === "7" && (
                    <>
                        <div css={restoreBoxStyle} onClick={() => transferToNative("", "restoreProduct")}>
                            <p>이미 이용권을 구매하셨나요?</p>
                            <p>App Store 구매내역 복원하러 가기</p>
                        </div>
                    </>
                )}
                {/* <button
                    onClick={() => {
                        const params = {
                            title: "테스트이름",
                            url: "https://triplenine.notion.site/4f3706b2294140118606bec5d9659e14?pvs=4",
                        };
                        transferToNative(JSON.stringify(params), "openExternalMap");
                    }}
                >
                    test
                </button> */}
            </div >

            <BottomInfoPage
                landRef={landRef}
                topRef={topRef}
                setSelectedProductList={(val: IProduct[]) => setSelectedProductList(val)}
                selectedProduct={selectedProduct}
                selectedProductList={selectedProductList}
                onClickChange={() => topRef?.current?.scrollIntoView({ behavior: "smooth" })}
                onClickSubmit={onClickPayment}
            />
            {
                !!openModal && (
                    <ConfirmModal
                        resultMessage={modalContents}
                        onConfirm={() => {
                            if (openModal === 1 || openModal === 3 || openModal === 5) {
                                const params = {
                                    productCode: selectedProductList.map((e) => e.code),
                                };
                                transferToNative(JSON.stringify(params), "payProductInApp");
                                setOpenModal(0);
                            } else {
                                setModalContents(<div />);
                                setOpenModal(0);
                            }
                        }}
                        cancelText="닫기"
                        buttonText={openModal === 1 ? "시작하기" : openModal === 3 || openModal === 5 ? "결제하기" : "확인"}
                        onCancel={
                            openModal === 1 || openModal === 3 || openModal === 5
                                ? () => {
                                    setModalContents(<div />);
                                    setOpenModal(0);
                                }
                                : () => {
                                    setModalContents(<div />);
                                    setOpenModal(0);
                                }
                        }
                    />
                )
            }
        </>
    );
};

export default SubscriptionInfo;

const goStopBanner = css`
     background-color: #B1D0FF;
    padding: 0px 14px;
    height: 100px;
    display: flex;
    align-items: center;
    & > p {
        font-size: 24px;
        font-family: Sandoll Samliphopangche;
        font-weight: 400;
        letter-spacing: -0.44px;
        color: #282828;
    }
`

const premiumBanner = css`
    background-color: #DFF0FF;
    padding: 0px 14px;
    height: 66px;
    display: flex;
    align-items: center;
    & > p {
        font-size: 24px;
        font-family: Sandoll Samliphopangche;
        font-weight: 400;
        letter-spacing: -0.44px;
        color: #282828;
    }
`

const topTitleStyle = css`
    padding: 14px;
    color: #282828;
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    position: relative;
    text-align: center;
    & > .close {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0%, -50%);
        padding: 15px;
    }
`;

const rootBoxStyle = (list: number) => css`
    height: calc(100dvh - 56px);
    height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: ${list * 100 + "px"};
    ::-webkit-scrollbar {
        display: none;
    }
`;

const topDescStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    padding: 14px;
    background-color: #fbf8f8;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const dialogTitleStyle = css`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 0;
    word-break: break-word;
`;

const restoreBoxStyle = css`
    margin: 14px;
    padding: 14px;
    background-color: #eefff5;
    color: #0c002c;
    text-align: center;
    font-weight: 400;
    p:first-of-type {
        font-size: 18px;
        line-height: 28px;
    }
    p:last-of-type {
        margin-top: 4px;
        font-size: 13px;
        line-height: 20px;
    }
`;
