import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { transferToNative } from "@src/lib/WebAppBridge";
import backArrow from "../../../assets/ico_arrow_back.png";
import Title from "@src/components/title/Title";
import { useAdItemList, useDelBanner, useReAdBanner, useAdCntBanner } from "@src/hooks/expert/expert/useExpert";
import { IAdItemListType, ModalType, IAdItemResponse } from "@src/hooks/expert/expert/dto/expert.dto";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Lottie from "lottie-react";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import DownArrow from "@src/assets/downArrowBtn.png";
import AdEndConfirmModal from "./AdEndConfirmModal";
import AdCheckModal from "./AdCheckModal";
import NoData from "@src/assets/ico_no_data.png";
import { useNavigate } from "react-router-dom";
import AdExpertCard from "./AdExpertCard";

interface Props {}

const ExpertAdList: React.FC<Props> = () => {
    const navigate = useNavigate();
    const initialState = {
        cursor: {
            sortType: 0,
            cursorId: null,
            cursorValue: null,
            updatedAt: null,
        },
        limit: 20,
        status: 0,
    };
    const modalInitialState = {
        start_date: "",
        end_date: "",
        item_id: "",
        banner_no: 0,
    };

    const [cursor, setCursor] = useState<IAdItemListType>(initialState);
    const [selectedOption, setSelectedOption] = useState<number>(0);
    const [adCount, setAdCount] = useState<number>(0);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [clickedAdEnd, setClickedAdEnd] = useState<boolean>(false);
    const [clickedReAd, setClickedReAd] = useState<boolean>(false);
    const [checkedReAd, setCheckedReAd] = useState<boolean>(false);
    const [failedAdBanner, setFailedAdBanner] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ModalType>(modalInitialState);
    const options = [
        { id: 0, label: "최신순" },
        { id: 5, label: "매각기일 가까운순" },
        { id: 12, label: "광고종료일 가까운순" },
        { id: 13, label: "전문가 문의 많은순" },
    ];
    //* 정렬필터
    const handleOptionChange = (optionId: number) => {
        setSelectedOption(optionId);
        setCursor({
            ...initialState,
            cursor: { ...initialState.cursor, sortType: optionId },
            status: cursor.status,
        });
        setFilterVisible(!filterVisible);
    };

    //*광고종료
    const handleAdEndChange = async (bannerNo: number, itemId: string) => {
        try {
            await useDelBanner({ bannerNo, itemId }).then(() => {
                setCursor({
                    ...initialState,
                    cursor: { ...initialState.cursor, sortType: cursor.cursor.sortType },
                    status: cursor.status,
                });
                refetch();
                getAdCount();
            });
        } catch (error) {
            console.error("Error fetching item list:", error);
        }
        setClickedAdEnd(false);
    };

    //재광고, 광고권 차감
    const handleReAdChange = async (itemId: string) => {
        if (adCount === 0) {
            navigate("/deungi/payment?type=3");
        } else {
            try {
                await useReAdBanner(itemId).then(() => {
                    setCursor({
                        ...initialState,
                        cursor: { ...initialState.cursor, sortType: cursor.cursor.sortType },
                        status: cursor.status,
                    });
                    refetch();
                    getAdCount();
                    setCheckedReAd(true);
                });
            } catch (error) {
                console.error("Error fetching item list:", error);
            }
        }
    };

    //광고권 개수
    const getAdCount = async () => {
        const totalData = await useAdCntBanner(3);
        if (totalData?.data?.curHaveCnt !== undefined) {
            setAdCount(totalData?.data?.curHaveCnt);
        }
    };

    const { data: getItemList, refetch, hasNextPage, fetchNextPage, isFetching } = useAdItemList(cursor);

    const handlerOnChangeType = (type: number) => {
        setCursor(() => ({
            ...initialState,
            status: type,
        }));
    };

    const [expertItemRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 0px 0px",
        delayInMs: 100,
    });

    useEffect(() => {
        refetch();
    }, [cursor]);

    useEffect(() => {
        getAdCount();
    }, []);

    const today = new Date();
    const add1Month = new Date(today);
    add1Month.setMonth(today.getMonth() + 1);
    const end_date = add1Month.toISOString().slice(0, 10);

    return (
        <div css={StyleAdListWrapper}>
            <div className="titleWrap">
                <div
                    className="backBtn"
                    onClick={() => {
                        transferToNative("done", "closeWebView");
                    }}
                >
                    <img src={backArrow} alt="뒤로가기" />
                </div>
                <Title title="광고 매물 관리" size="large" />
            </div>

            <>
                <div className="buttonWrap">
                    <button
                        onClick={() => {
                            handlerOnChangeType(0);
                        }}
                    >
                        <div className={cursor?.status === 0 ? "clickedContainer" : "container"}>
                            <span>전체 ({getItemList?.pages[0]?.totalCnt ?? 0})</span>
                        </div>
                    </button>
                    <button
                        onClick={() => {
                            handlerOnChangeType(1);
                        }}
                    >
                        <div className={cursor?.status === 1 ? "clickedContainer" : "container"}>
                            <span> 광고중({getItemList?.pages[0]?.totalActive ?? 0})</span>
                        </div>
                    </button>
                    <button
                        onClick={() => {
                            handlerOnChangeType(2);
                        }}
                    >
                        <div className={cursor?.status === 2 ? "clickedContainer" : "container"}>
                            <span> 광고종료({getItemList?.pages[0]?.totalExpired ?? 0})</span>
                        </div>
                    </button>
                </div>
                {/*필터*/}
                <div
                    className={filterVisible ? "dim" : ""}
                    onClick={() => {
                        if (filterVisible) {
                            setFilterVisible(!filterVisible);
                        }
                    }}
                />

                {getItemList?.pages[0]?.totalCnt === 0 ? (
                    <div className="noDataWrap">
                        <img src={NoData} alt="NoData" />
                        <div>광고 매물이 없어요</div>
                    </div>
                ) : (
                    <>
                        <div className="contentsWrap">
                            <div className="filterWrap">
                                {cursor?.status === 1 ? (
                                    <p>총 {getItemList?.pages[0]?.totalActive}건</p>
                                ) : cursor?.status === 2 ? (
                                    <p>총 {getItemList?.pages[0]?.totalExpired}건</p>
                                ) : (
                                    <p>총 {getItemList?.pages[0]?.totalCnt}건</p>
                                )}

                                <div className="dropdownFilter">
                                    <div className="dropdownWrap">
                                        <div className="selectedOption" onClick={() => setFilterVisible(!filterVisible)}>
                                            {options.find((option) => option.id === selectedOption)?.label}
                                            <img src={DownArrow} alt="DownArrow" />
                                        </div>
                                        {filterVisible && (
                                            <div className="options">
                                                {options.map((option) => {
                                                    return (
                                                        <div
                                                            key={option.id}
                                                            className={
                                                                option.id === selectedOption
                                                                    ? "dropdownClickedItem"
                                                                    : "dropdownItem"
                                                            }
                                                            onClick={() => handleOptionChange(option.id)}
                                                        >
                                                            {option.label}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*광고카드*/}
                        <div className="cardTabWrap">
                            {getItemList?.pages?.map((page: any, idx: number) => (
                                <div key={String(idx)}>
                                    {page?.list?.map((item: IAdItemResponse, itemIdx: number) => {
                                        return (
                                            <div key={String(itemIdx)} className="cardWrap">
                                                <AdExpertCard
                                                    item={item}
                                                    setClickedAdEnd={setClickedAdEnd}
                                                    setModalData={setModalData}
                                                    setFailedAdBanner={setFailedAdBanner}
                                                    setClickedReAd={setClickedReAd}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                            <div className="observer" ref={expertItemRef} />
                        </div>
                    </>
                )}
            </>
            {isFetching && (
                <div className="loading">
                    <Lottie className="lottie" animationData={lottie_loading} />
                </div>
            )}
            {clickedAdEnd && (
                <AdEndConfirmModal
                    title="광고 기간이 남아있습니다. 광고를 종료하시나요?"
                    desc="광고시작 3분이 지난 경우 광고권은 반환되지 않습니다"
                    start_date={modalData?.start_date.slice(0, 10)}
                    end_date={modalData?.end_date.slice(0, 10)}
                    type={1}
                    onClickCancel={() => setClickedAdEnd(false)}
                    onClickConfirm={() => {
                        handleAdEndChange(modalData?.banner_no, modalData?.item_id);
                    }}
                />
            )}
            {clickedReAd && (
                <AdEndConfirmModal
                    title="이 매물에 광고하기"
                    adCount={adCount}
                    type={2}
                    start_date={today.toISOString().substring(0, 10)}
                    end_date={end_date}
                    onClickCancel={() => setClickedReAd(false)}
                    onClickConfirm={() => {
                        handleReAdChange(modalData?.item_id);
                        setClickedReAd(false);
                    }}
                />
            )}
            {checkedReAd && (
                <AdCheckModal
                    title="매물의 전문가로 등록되었습니다"
                    type={0}
                    onClickConfirm={() => {
                        setCheckedReAd(false);
                    }}
                />
            )}
            {failedAdBanner && (
                <AdCheckModal
                    title="광고불가"
                    desc={`이 매물은 광고가 진행 중이에요\n다른 매물에 광고를 해주세요`}
                    type={1}
                    onClickConfirm={() => {
                        setFailedAdBanner(false);
                    }}
                />
            )}
        </div>
    );
};
export default ExpertAdList;

const StyleAdListWrapper = css`
    .dim {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.7);
    }
    .loading {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.2);
    }
    .titleWrap {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 14px;
        align-items: center;
        .backBtn {
            position: absolute;
            left: 14px;
            width: 10px;
            height: 17px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .title {
            font-weight: 700;
        }
    }
    .buttonWrap {
        display: flex;
        padding: 14px 0px;
        width: 100%;
        justify-content: center;
        .container {
            padding-bottom: 14px;
            border-bottom: 4px solid #dadada;
            color: #8d8d8d;
        }
        .clickedContainer {
            padding-bottom: 14px;
            border-bottom: 4px solid #0047ff;
            color: #0047ff;
            font-weight: 800;
        }
        & > button {
            width: 50%;
            height: 52px;
            align-items: center;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .contentsWrap {
        padding: 0px 14px;
    }
    .filterWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .lottie {
        width: 60px;
        margin: 150px auto;
    }
    .dropdownFilter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;
    }

    .dropdownWrap {
        height: 30px;
    }

    .selectedOption {
        height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        img {
            width: 14px;
            height: 14px;
            margin-left: 4px;
        }
    }
    .options {
        position: fixed !important;
        z-index: 2;
        width: 100vw;
        left: 0;
        padding: 24px 14px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 14px 14px 0px 0px;
        animation: appearBottomSheet 0.4s linear forwards;
        @keyframes appearBottomSheet {
            from {
                opacity: 0;
                height: 0;
                bottom: -50%;
            }

            to {
                opacity: 1;
                height: fit-content;
                bottom: 0;
            }
        }
    }

    .dropdownClickedItem {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        border-radius: 14px;
        padding: 4px 0px;
        justify-content: center;
        align-items: center;
        background: #0047ff;
        color: #fff;
        font-weight: 800;
        margin: 4px 0;
    }
    .dropdownItem {
        display: flex;
        height: 48px;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        border-radius: 14px;
        padding: 4px 0px;
        justify-content: center;
        align-items: center;
        margin: 4px 0;
    }
    .observer {
        height: 30px;
        width: 100%;
    }
    .cardTabWrap {
        height: calc(var(--vh, 1vh) * 100 - 166px);
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .noDataWrap {
        height: calc(var(--vh, 1vh) * 100 - 266px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        color: #0c002c;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.5px;
        img {
            width: 124.5px;
            height: 81.5px;
        }
    }
    .cardWrap {
        padding: 14px;
        .descContainer {
            display: flex;
            flex-direction: column;
        }
        .adDateWrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .adDateContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .adTitle {
            color: #0047ff;
            font-size: 13px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
        }
        .adDate {
            color: #8d8d8d;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-left: 6px;
            margin-bottom: 4px;
        }
        .endAdBtn {
            display: flex;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background: #f0f0f0;
            color: #8d8d8d;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .reAdBtn {
            display: flex;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background: #f6faff;
            color: #0047ff;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .adImageWrapper {
            display: flex;
            flex-direction: row;
            margin-top: 8px;
            align-items: center;
        }
        .imgContainer {
            position: relative;
        }
        .imgDay {
            position: absolute;
            top: 3px;
            left: 6px;
            color: #505050;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 28px;
        }
        .imgStatus {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 94px;
            height: 94px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
        }
        .adImage {
            width: 94px;
            height: 94px;
            border-radius: 4px;
        }
        .descWrap {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
        }
        .descTitle {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
        .descRow {
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;
        }
        .descCourt {
            color: #8d8d8d;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }
        .descUse {
            color: #0c002c;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .descUse {
            color: #0c002c;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 4px;
        }
        .descAddress {
            height: 20px;
            color: #505050;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .descBlueFont {
            color: #0047ff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
        .descBlackFont {
            color: #0c002c;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .callWrap {
            display: flex;
            gap: 14px;
            align-items: center;
            justify-content: center;
            height: 48px;
            border-radius: 4px;
            background: #f7f8fa;
            margin-top: 8px;
            padding: 0 14px;
        }
        .callCountContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 32px;

            background: #fff;
            border-radius: 4px;
            gap: 8px;
            .countWrap {
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 4px;
                color: #505050;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
            img {
                width: 24px;
                height: 24px;
            }
        }
        .inquiryBtn {
            width: 50%;
            height: 32px;
            border-radius: 24px;
            background: #0047ff;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .callBar {
            width: 0.6px;
            height: 20px;
            border-radius: 10px;
            background: #dadada;
        }
    }
`;
