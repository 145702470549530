import React from "react";

import { dialogTitleStyle, dialogContentStyle } from "./styles";

interface Props {
    errorMessage: string;
}

const RefundFailed = ({ errorMessage }: Props) => {
    return (
        <>
            <p css={dialogTitleStyle}>취소 불가</p>
            <p css={dialogContentStyle}>{errorMessage}</p>
        </>
    );
};

export default RefundFailed;
