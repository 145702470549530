import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import deungi_ticket_ico from "@src/assets/deungi/deungi_ticket_ico.png";
import deungi_tickets_ico from "@src/assets/deungi/deungi_tickets_ico.png";
import exist_deungi_ticket_ico from "@src/assets/deungi/exist_deungi_ticket_ico.png";
import exist_deungi_tickets_ico from "@src/assets/deungi/exist_deungi_tickets_ico.png";
import items_ads_ticket_ico from "@src/assets/deungi/items_ads_ticket_ico.png";
import items_ads_tickets_ico from "@src/assets/deungi/items_ads_tickets_ico.png";
import region_ads_ticket_ico from "@src/assets/deungi/region_ads_ticket_ico.png";

import { useTicketPackageList } from "@src/hooks/deungi/useTicketPackageList";
import { TicketPackageListResponseDto } from "@src/hooks/deungi/dto/PackageList.dto";
import { transferToNative } from "@src/lib/WebAppBridge";
import { checkDevice } from "./common/utils";
import { DEUNGI_POPUP_TYPE, PRODUCT_SALE_TYPE, TICKET_TYPE, TICKET_TYPE_STRING } from "./common/enum";
import { useNavigate } from "react-router-dom";

import DeungiPaymentInfoBox from "./DeungiPaymentInfoBox";
import DeungiTicketBox from "./DeungiTicketBox";
import DeungiPopup from "./DeungiPopup";
import { useIsUserExpert } from "@src/hooks/expert/useIsUserExpert";
import { useGetCheckDeungiPayment } from "@src/hooks/payment/usePayment";
import { useFindOneUserSubsInfo } from "@src/hooks/user/userApi";
import DeungiSubsCheckDialog from "./DeungiSubsCheckDialog";
import DeungiPurchaseCheckDialog from "./DeungiPurchaseCheckDialog";
import DeungiPackageCard from "./DeungiPackageCard";
import { useDeungiPinStore, useDeungiSearchText } from "@src/store/deungiPayment";
import { usePrevDeungi } from "@src/hooks/deungi/usePrevDeungi";
import { PrevDeungiResponseDto } from "@src/hooks/deungi/dto/Deungi.dto";

const DeungiPackageListBox = () => {
    const navigate = useNavigate();
    const deviceType = checkDevice();
    const containerRef = useRef(null) as any;
    const ticketBoxRef = useRef(null) as any;
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.PACKAGE_INFO_DEUNGI_TICKET);
    const [popupToggle, setPopupToggle] = useState(false);
    const [packageList, setPackageList] = useState({} as TicketPackageListResponseDto[]);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [ticketPosition, setTicketPosition] = useState(TICKET_TYPE.DEUNGI_TICKET);
    const [isUserExpert, setIsUserExpert] = useState(false);
    const [openCheckSubs, setOpenCheckSubs] = useState<boolean>(false);
    const [openCheckDeungiPurchase, setOpenCheckDeungiPurchase] = useState<number>(0);
    const [pageType, setPageType] = useState<number>(-1);
    const userDeungiSearchText = useDeungiSearchText((state: any) => state.deungiSearchText);

    const { data: checkDeunggiPayment, refetch } = useGetCheckDeungiPayment();

    //* 유저 구독 정보
    const { data: userSubscriptionInfo, refetch: refetchSubsInfo } = useFindOneUserSubsInfo();

    const userDeungiPinState = useDeungiPinStore((state: any) => state.deungiPinState);

    const getIsUserExpert = async () => {
        const res = await useIsUserExpert();
        setIsUserExpert(res.data || false);
    };

    const getPackageList = async () => {
        const saleType = deviceType === "ios" ? PRODUCT_SALE_TYPE.IOS : PRODUCT_SALE_TYPE.DISPLAYED;
        const res = await useTicketPackageList(saleType);
        setPackageList(res.data.list);
    };

    const onTicketClickHandler = (type: TICKET_TYPE, coverHeight: number) => {
        if (containerRef.current) {
            const changeType = type === 1 ? 1 : type - 1;
            const targetTop = (document.getElementsByClassName("packageBox")[(pageType === 1 ? changeType : type) - 1] as any).offsetTop - coverHeight;
            containerRef.current.scrollTo({ top: targetTop });
            navigate("/deungi/payment", {
                replace: true,
                state: {
                    type,
                },
            });
        }
    };

    const getDeungiList = async (deungiPin: string) => {
        if (deungiPin !== '') {
            const res = await usePrevDeungi(deungiPin ?? "");
            const prevDeungiInfo: PrevDeungiResponseDto = res.data;
            if (prevDeungiInfo && prevDeungiInfo.id.length > 0) {
                setPageType(0);
            } else {
                setPageType(1);
            }
        } else {
            setPageType(0);
        }
    }

    useEffect(() => {
        getDeungiList(userDeungiPinState)
    }, [userDeungiPinState])

    const onPackageClickHandler = (data: TicketPackageListResponseDto) => {
        if (!data) return;
        if (data?.type === "deungi_ticket") {
            refetch().then((res) => {
                if (res.isFetched) {
                    refetchSubsInfo().then((subs) => {
                        if (subs.isFetched) {
                            if (data?.quantity !== 1 && (!subs.data?.subsNo || subs.data?.isFreeIssueSubs)) {
                                setOpenCheckDeungiPurchase(2);
                                return;
                            }
                            if (res.data === "disallow") {
                                setOpenCheckDeungiPurchase(1);
                                return;
                            }
                            if (deviceType !== "android") {
                                const ticketType = ["", ...Object.values(TICKET_TYPE_STRING)];
                                const params = JSON.stringify({
                                    productCode: data.code,
                                    productType: data.type,
                                    ticketType: ticketType.indexOf(data.type),
                                    searchText: userDeungiSearchText ?? ""
                                });
                                transferToNative(params, "payTicketInApp");
                                //! 호환성을 위해 남겨둠. deprecated 예정
                                transferToNative(data.code, "payDeungiTicketInApp");
                            } else {
                                const params = JSON.stringify({
                                    pgType: "kcp.IP29N",
                                    product_name: data.name,
                                    product_code: data.code,
                                    discounted_price: data.discountPrice,
                                    type: data.type,
                                    searchText: userDeungiSearchText ?? "",
                                });
                                transferToNative(params, "payDeungiTicket");
                            }
                        }
                    });
                }
            });
        } else {
            if (deviceType !== "android") {
                const ticketType = ["", ...Object.values(TICKET_TYPE_STRING)];
                const params = JSON.stringify({
                    productCode: data.code,
                    productType: data.type,
                    ticketType: ticketType.indexOf(data.type),
                    searchText: userDeungiSearchText ?? ""
                });
                transferToNative(params, "payTicketInApp");

                //! 호환성을 위해 남겨둠. deprecated 예정
                transferToNative(data.code, "payDeungiTicketInApp");
            } else {
                const params = JSON.stringify({
                    pgType: "kcp.IP29N",
                    product_name: data.name,
                    product_code: data.code,
                    discounted_price: data.discountPrice,
                    type: data.type,
                    searchText: userDeungiSearchText ?? ""
                });
                transferToNative(params, "payDeungiTicket");
            }
        }
    };

    useEffect(() => {
        getIsUserExpert();
        getPackageList();
        refetchSubsInfo();
    }, []);

    useEffect(() => {
        if (containerRef.current && ticketBoxRef.current) {
            setScrollHeight(containerRef.current.scrollTop + ticketBoxRef.current.clientHeight - 56);
            containerRef.current.addEventListener("scroll", (ev: any) => {
                setScrollHeight(containerRef.current.scrollTop + ticketBoxRef.current.clientHeight - 56);
            });
        }
        return containerRef.current?.removeEventListener("scroll", () => { });
    }, [containerRef, ticketBoxRef]);

    useEffect(() => {
        if (userSubscriptionInfo?.subsNo === 0 || userSubscriptionInfo?.isFreeIssueSubs) {
            setOpenCheckSubs(true);
        }
    }, [userSubscriptionInfo]);

    return (
        <>
            <Container ref={containerRef}>
                <DeungiTicketBox
                    ticketBoxRef={ticketBoxRef}
                    ticketPosition={ticketPosition}
                    onTicketClickHandler={onTicketClickHandler}
                    isUserExpert={isUserExpert}
                    pageType={pageType}
                />
                <PackageListBox>
                    <DeungiPackageCard
                        title="실시간 등기열람권"
                        packages={
                            packageList.length > 0 ? packageList.filter((el) => el.type === TICKET_TYPE_STRING.DEUNGI_TICKET) : []
                        }
                        singleTicketImage={deungi_ticket_ico}
                        multipleTicketsImage={deungi_tickets_ico}
                        active={ticketPosition === TICKET_TYPE.DEUNGI_TICKET}
                        onTitleClick={() => {
                            setPopupType(DEUNGI_POPUP_TYPE.PACKAGE_INFO_DEUNGI_TICKET);
                            setPopupToggle(true);
                        }}
                        onPackageClick={onPackageClickHandler}
                        onScroll={(ref: any) => {
                            if (ref && scrollHeight <= ref.clientHeight) setTicketPosition(TICKET_TYPE.DEUNGI_TICKET);
                        }}
                    />
                    {
                        pageType === 0 &&
                        <DeungiPackageCard
                            title="기존 등기열람권"
                            packages={
                                packageList.length > 0
                                    ? packageList.filter((el) => el.type === TICKET_TYPE_STRING.EXIST_DEUNGI_TICKET)
                                    : []
                            }
                            singleTicketImage={exist_deungi_ticket_ico}
                            multipleTicketsImage={exist_deungi_tickets_ico}
                            active={ticketPosition === TICKET_TYPE.EXIST_DEUNGI_TICKET}
                            onTitleClick={() => {
                                setPopupType(DEUNGI_POPUP_TYPE.PACKAGE_INFO_EXIST_DEUNGI_TICKET);
                                setPopupToggle(true);
                            }}
                            onPackageClick={onPackageClickHandler}
                            onScroll={(ref: any) => {
                                if (ref && scrollHeight >= ref.offsetTop - 70) setTicketPosition(TICKET_TYPE.EXIST_DEUNGI_TICKET);
                            }}
                        />
                    }
                    {isUserExpert && (
                        <>
                            <DeungiPackageCard
                                title="매물광고권"
                                packages={
                                    packageList.length > 0
                                        ? packageList.filter((el) => el.type === TICKET_TYPE_STRING.ITEMS_ADS_TICKET)
                                        : []
                                }
                                singleTicketImage={items_ads_ticket_ico}
                                multipleTicketsImage={items_ads_tickets_ico}
                                active={ticketPosition === TICKET_TYPE.ITEMS_ADS_TICKET}
                                onTitleClick={() => {
                                    setPopupType(DEUNGI_POPUP_TYPE.PACKAGE_INFO_ITEMS_ADS_TICKET);
                                    setPopupToggle(true);
                                }}
                                onPackageClick={onPackageClickHandler}
                                onScroll={(ref: any) => {
                                    if (ref && scrollHeight >= ref.offsetTop - 70)
                                        setTicketPosition(TICKET_TYPE.ITEMS_ADS_TICKET);
                                }}
                            />
                            <DeungiPackageCard
                                title="지역광고권"
                                packages={
                                    packageList.length > 0
                                        ? packageList.filter((el) => el.type === TICKET_TYPE_STRING.REGION_ADS_TICKET)
                                        : []
                                }
                                singleTicketImage={region_ads_ticket_ico}
                                active={ticketPosition === TICKET_TYPE.REGION_ADS_TICKET}
                                onTitleClick={() => {
                                    setPopupType(DEUNGI_POPUP_TYPE.PACKAGE_INFO_REGION_ADS_TICKET);
                                    setPopupToggle(true);
                                }}
                                onPackageClick={onPackageClickHandler}
                                onScroll={(ref: any) => {
                                    if (
                                        (ref && scrollHeight >= ref.offsetTop - 70) ||
                                        (containerRef.current &&
                                            scrollHeight >= containerRef.current.scrollHeight - containerRef.current.clientHeight)
                                    )
                                        setTicketPosition(TICKET_TYPE.REGION_ADS_TICKET);
                                }}
                            />
                        </>
                    )}
                    <DeungiPaymentInfoBox isUserExpert={isUserExpert} />
                </PackageListBox>
                <DeungiPopup type={popupType} toggle={popupToggle} setToggle={setPopupToggle} />
            </Container>
            <DeungiSubsCheckDialog open={openCheckSubs} onCloseView={() => setOpenCheckSubs(false)} />
            <DeungiPurchaseCheckDialog open={openCheckDeungiPurchase} onCloseView={() => setOpenCheckDeungiPurchase(0)} />
        </>
    );
};

const PackageListBox = styled.div`
    height: fit-content;

    .packageBox {
        padding: 24px 14px 14px;

        &.active {
            background: #f6faff;
        }
        .titleBox {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            & > h3 {
                font-size: 18px;
                font-weight: 700;
            }
            & > img {
                width: 20px;
                height: 20px;
                margin-left: 8px;
            }
        }
        .items {
            display: flex;
            align-items: center;
            width: 100%;
            /* height: 80px; */
            background: #fff;
            border: 1px solid #c9c9c9;
            border-radius: 14px;
            font-size: 16px;
            box-sizing: border-box;
            padding: 14px;
            margin-bottom: 14px;

            :active {
                border: 1px solid #2275ee;
                box-shadow: inset 0 0 0 1px #2275ee;
            }
            .radioItem {
                margin-right: 10px;

                &.hidden {
                    display: none;
                }
            }

            .imageBox {
                position: relative;
                margin: 0;

                min-width: 50px;
                height: 50px;

                border-radius: 8px;
                background-color: #f6faff;

                & > img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    width: 38px;
                    height: 38px;
                }
            }
            & > div {
                display: flex;
                flex-direction: column;
                margin-left: 8px;

                & > span {
                    word-break: keep-all;
                    line-height: 1.1;
                }
                & > small {
                    margin-top: 7px;
                    color: #ff0000;
                }
            }
            & > .price {
                width: fit-content;
                min-width: 95px;
                height: 40px;
                background: #fff;
                border-radius: 8px;
                margin-left: auto;
                text-align: center;
                line-height: 40px;
                word-break: keep-all;
            }
        }
    }
`;

const Container = styled.div`
    height: 100%;
    overflow: scroll;
    background-color: white;
    .backDiv {
        width: 0;
        height: 75px;
    }
`;

export default DeungiPackageListBox;
