import styled from "@emotion/styled";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { CampusResourceDataDto, CampusResourceResponseDto } from "@src/hooks/campus/dto/CampusSugang.dto";
import { useSaveResourceRecode } from "@src/hooks/campus/useCampusSugang";
import { transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import downloadImg from "../../../assets/downLoad.png";

interface DataProps {
    data?: CampusResourceResponseDto;
    downloadAt?: string;
    registerNo: number;
    isDownLoad: boolean;
    setIsDownLoad: Dispatch<SetStateAction<boolean>>;
}

const Data = ({ data, downloadAt, registerNo, isDownLoad, setIsDownLoad }: DataProps) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const [downloadData, setDownloadData] = useState<{ pdfUrl: string; filName: string }>({
        pdfUrl: "",
        filName: "",
    });

    return (
        <>
            <StyledData>
                {data?.list.length ? (
                    data?.list.map((item: CampusResourceDataDto, idx: number) => (
                        <div className="item" key={item.createdAt + idx}>
                            <div className="dataTitle">
                                <span>
                                    {item.name}.pdf
                                    <span className="size">
                                        {item.pdfSize < 1000000
                                            ? `${(item.pdfSize * 0.001).toFixed(1)}KB`
                                            : `${(item.pdfSize * 0.000001).toFixed(1)}MB`}
                                    </span>
                                </span>
                            </div>

                            <img
                                src={downloadImg}
                                alt=""
                                onClick={() => {
                                    isDownLoad
                                        ? transferToNative(
                                              JSON.stringify({
                                                  pdfUrl: item.pdfUrl,
                                                  fileName: item.name,
                                              }),
                                              "downloadPdf",
                                          )
                                        : (setIsShow(true), setDownloadData({ pdfUrl: item.pdfUrl, filName: item.name }));
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <div className="noListText">등록된 자료가 없어요</div>
                )}
            </StyledData>
            {isShow && (
                <ConfirmModal
                    resultMessage={"강의 시청 혹은 자료 다운 시\n 환불이 불가합니다"}
                    onConfirm={() => {
                        transferToNative(
                            JSON.stringify({
                                pdfUrl: downloadData.pdfUrl,
                                fileName: downloadData.filName,
                            }),
                            "downloadPdf",
                        );
                        useSaveResourceRecode(registerNo);
                        setIsDownLoad(true);
                        setIsShow(false);
                    }}
                    onCancel={() => {
                        setIsShow(false);
                    }}
                    buttonText={"다운로드"}
                    disabled={false}
                    titleStyle={{ lineHeight: "26px" }}
                />
            )}
        </>
    );
};

export default Data;

const StyledData = styled.div`
    padding: 14px;
    width: 100vw;
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 14px;
    .item {
        padding: 8px 14px;
        height: fit-content;
        min-height: 58px;
        border-radius: 8px;
        background-color: #fbf8f8;
        color: ${Common.colors.normalTextColor};
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dataTitle {
            display: flex;
            width: 254px;
            align-items: flex-end;

            & > span {
                color: ${Common.colors.normalTextColor};
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                & > .size {
                    margin-left: 4px;
                    font-weight: 500;
                    font-size: 13px;
                    color: #8d8d8d;
                }
            }
        }

        & > img {
            width: 18px;
        }
    }
    .noListText {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        color: ${Common.colors.normalTextColor};
        width: 100%;
        text-align: center;
        margin-top: 60px;
    }
`;
