import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { useCallback, useRef } from "react";
import moment from "moment";

interface CampusTimerProps {
    title: string;
    end: string;
}

const CampusTimer = ({ title, end }: CampusTimerProps) => {
    const dayRef = useRef() as any;
    const hoursRef = useRef() as any;
    const minutesRef = useRef() as any;
    const secondsRef = useRef() as any;

    const hoursRef1 = useRef() as any;
    const minutesRef1 = useRef() as any;
    const secondsRef1 = useRef() as any;
    const countDown = useCallback(() => {
        let date = moment(end);
        let timer: any;
        function showRemaining() {
            try {
                let now = moment();
                let distDt = moment.duration(date.diff(now));
                let diffTime = moment.duration(date.diff(now)).asSeconds();
                if (diffTime < 0) {
                    clearInterval(timer);

                    if (dayRef.current) {
                        dayRef.current.innerHTML = "00";
                    }
                    if (hoursRef.current) {
                        hoursRef.current.innerHTML = "0";
                    }
                    if (minutesRef.current) {
                        minutesRef.current.innerHTML = "0";
                    }
                    if (secondsRef.current) {
                        secondsRef.current.innerHTML = "0";
                    }
                    return;
                }

                let days = String(distDt.days());
                let hours = String(distDt.hours()).split("");
                let minutes = String(distDt.minutes()).split("");
                let seconds = String(distDt.seconds()).split("");
                distDt.days() < 10 && (days = "0" + days);

                hours.length < 2 && hours.unshift("0");
                minutes.length < 2 && minutes.unshift("0");
                seconds.length < 2 && seconds.unshift("0");

                if (distDt.months() > 0) {
                    days = String(distDt.days() + 30 * distDt.months());
                }

                if (dayRef.current) {
                    dayRef.current.innerHTML = days;
                }
                if (hoursRef.current) {
                    hoursRef.current.innerHTML = hours[0];
                }
                if (minutesRef.current) {
                    minutesRef.current.innerHTML = minutes[0];
                }
                if (secondsRef.current) {
                    secondsRef.current.innerHTML = seconds[0];
                }

                if (hoursRef1.current) {
                    hoursRef1.current.innerHTML = hours[1];
                }
                if (minutesRef1.current) {
                    minutesRef1.current.innerHTML = minutes[1];
                }
                if (secondsRef1.current) {
                    secondsRef1.current.innerHTML = seconds[1];
                }
            } catch (e) {
                console.log(e);
            }
        }
        timer = setInterval(showRemaining, 500);
    }, []);
    countDown();
    return (
        <>
            <StyledCampusTimer>
                <div className="title">{title}</div>
                <div className="timer">
                    <div className="timeBox" ref={dayRef}>
                        00
                    </div>
                    일
                    <div className="timeDiv">
                        <div className="timeBox" ref={hoursRef}>
                            0
                        </div>
                        <div className="timeBox" ref={hoursRef1}>
                            0
                        </div>
                        <span>:</span>
                        <div className="timeBox" ref={minutesRef}>
                            0
                        </div>
                        <div className="timeBox" ref={minutesRef1}>
                            0
                        </div>
                        <span>:</span>
                        <div className="timeBox" ref={secondsRef}>
                            0
                        </div>
                        <div className="timeBox" ref={secondsRef1}>
                            0
                        </div>
                    </div>
                </div>
            </StyledCampusTimer>
        </>
    );
};

export default CampusTimer;

const StyledCampusTimer = styled.div`
    background-color: ${Common.colors.normalTextColor};
    padding: 24px;
    .title {
        font-weight: 700;
        display: flex;
        justify-content: center;
        font-size: 20px;
        color: white;
    }
    .timer {
        display: flex;
        margin-top: 14px;
        justify-content: center;
        align-items: flex-end;
        font-weight: 700;
        font-size: 20px;
        color: ${Common.colors.white};
        .timeBox {
            background-color: white;
            padding: 8px;
            border-radius: 4px;
            font-weight: 700;
            font-size: 24px;
            color: ${Common.colors.normalTextColor};
            margin-left: 2px;
            width: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: fit-content;
        }
        .timeDiv {
            display: flex;
            margin-left: 14px;
            align-items: center;
            & > span {
                padding: 4px;
            }
        }
    }
`;
