import React, { Fragment, useEffect, useState } from "react";
import { useFindAllFilterHistory, useRemoveHistory } from "@src/hooks/newFilter/useFindAllFilterHistory";
import ShowSelectedValueBox from "./ShowSelectedValueBox";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";
import { useFilterTextList } from "./useFilterList";
import ShowSelectedUseLocValueBox from "./ShowSelectedUseLocValueBox";
import { auctionStatusList } from "./AuctionStatusFilter";
import { auctionMasterItemList } from "./AuctionMasterItemFilter";
import { assetList, saleMethodList } from "./AuctionTypeFilter";
import { auctionSkipList } from "./AuctionSkipFilter";
import { css } from "@emotion/react";
import ico_x_grey from "@src/assets/ico_x_grey.svg";
import ico_arrow_back from "@src/assets/ico_arrow_back.png";
import empty_recent_history from "@src/assets/empty_recent_history.svg";
import { useNewFilterMapCheckStore, useNewFilterStore } from "@src/store/newFilterStore";
import { transferToNative } from "@src/lib/WebAppBridge";
import NewFilterHistoryLoc from "./NewFilterHistoryLoc";
import SelectedFilterList from "./SelectedFilterList";
import { makeUseParam } from "@src/data/itemForFilterPurpose";

interface Props {
    onClose: () => void;
}

const NewFilterHistory: React.FC<Props> = ({ onClose }) => {
    const [filterHistoryList, setfilterHistoryList] = useState<any[]>([]);
    const setFilterParams = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);

    useEffect(() => {
        useFindAllFilterHistory().then((res) => {
            setfilterHistoryList(res);
        });
    }, []);

    return (
        <div css={rootBoxStyle}>
            {filterHistoryList.length ? (
                filterHistoryList?.map((history: any) => (
                    <div key={history.hash} css={containerStyle}>
                        <div css={historyBoxRootStyle}>
                            <p className="text">{history?.updated_at}</p>
                            <img
                                src={ico_x_grey}
                                onClick={async () => {
                                    const result = await useRemoveHistory(history.no);
                                    useFindAllFilterHistory().then((res) => {
                                        setfilterHistoryList(res);
                                    });
                                }}
                            />
                        </div>
                        <SelectedFilterList filterParams={history.value} from="history" />
                        <div
                            css={historyFooterStyle}
                            onClick={() => {
                                const newFilter = {
                                    ...history.value,
                                    loc: [],
                                    // loc: history?.value?.loc?.map((v: any) => (v.includes("All") ? v.slice(0, -3) : v)),
                                    use: makeUseParam(history?.value?.use),
                                    status: history?.value?.status?.map((s: any) => Number(s)),
                                    type: Number(history?.value?.type ?? 0),
                                    counter_force: Number(history?.value?.counter_force ?? 0),
                                    skp_type: Number(history?.value?.skp_type ?? 0),
                                    auction_master_item: history?.value?.auction_master_item?.map((v: any) => Number(v)),
                                    assets: history?.value?.assets?.map((v: any) => Number(v)),
                                    sales_method: Number(history?.value?.sales_method ?? 0),
                                };

                                transferToNative(JSON.stringify(newFilter), "setFilterDone");
                                setFilterParams(newFilter);
                                onClose();
                                setFilterCheck(false);
                            }}
                        >
                            필터 적용해 검색하기 <img src={ico_arrow_back} />
                        </div>
                    </div>
                ))
            ) : (
                <div css={emptyBoxStyle}>
                    <img src={empty_recent_history} alt="" width="103px" height="82px" />
                    <p>
                        최근 검색한 필터 목록이 없어요
                        <br />
                        필터를 이용해 물건을 검색해보세요!
                    </p>
                </div>
            )}
        </div>
    );
};

export default NewFilterHistory;

const rootBoxStyle = css`
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc((var(--vh, 1vh) * 100) - 152px);
    max-height: calc(100dvh - 152px);
    max-height: calc(100vh - 152px);
    height: max-content;
    padding-bottom: 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const containerStyle = css`
    border: 1px solid rgb(235, 235, 235);
    padding-bottom: 10px;
`;

const historyBoxRootStyle = css`
    padding: 14px 14px 12px 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
        color: #8d8d8d;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const historyFooterStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: #2275ee;
    padding-right: 24px;
    padding-top: 12px;
    img {
        width: 7px;
        margin-left: 7px;
        transform: rotate(180deg);
    }
`;

const emptyBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 14px;
    padding: 36px 0;
    background-color: #ffffff;
    color: #3c3c3c;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
`;
