import { css } from "@emotion/react";

// SubsInfo
export const subsInfoContainer = css`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 12px;
`;

export const subsInfoTitle = css`
    padding: 4px 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 24px;
    border: 1px solid #c9c9c9;
    background-color: #ffffff;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    color: #505050;
`;

export const subsInfoPrice = css`
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-decoration: line-through;
    text-align: center;

    color: #8d8d8d;
`;

// IntroductionPopup
export const mainContainer = css`
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 24px;
`;

export const titleSectionContainer = css`
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 12px;
    h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;

        color: #505050;
    }

    h6 {
        text-align: center;

        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;

        color: #000000;

        .highlight {
            color: #0047ff;
        }
    }
`;

export const eventInfoSectionContainer = css`
    position: relative;

    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    align-items: center;

    border-radius: 8px;
    background-color: #edf0f6;
    box-shadow: 2.047px 2.047px 6.141px 0px rgba(4, 0, 14, 0.14);

    .plus {
        margin: 8px 0;
    }

    .arrow {
        position: absolute;
        top: 48%;
        right: 28px;
    }

    .price_result {
        margin-top: 15px;

        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 58px;

        color: #0047ff;
    }

    .warn {
        margin-top: 4px;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;

        color: #f6494b;
    }
`;

export const desc = css`
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    color: #000000;
`;

export const registrationButton = css`
    cursor: pointer;

    position: fixed;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);

    width: 332px;
    padding: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 14px;
    background-color: #2275ee;

    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    color: #ffffff;
`;
