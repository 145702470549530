import styled from "@emotion/styled";
import { CampusCurriCulumSubDataDto, CampusDetailCurriculumDataDto } from "@src/hooks/campus/dto/CampusDetail.dto";
import { Common } from "@src/styles/Common";
import React, { Fragment, useState } from "react";
import arrowDown from "../../../assets/ico_arrow_down.png";
import ico_arrow_up_black from "../../../assets/ico_arrow_up_black_bold.png";

interface CurriculumItemProps {
    item: CampusDetailCurriculumDataDto;
}

const CurriculumItem = ({ item }: CurriculumItemProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(item.mainOrder === 0 ? true : false);

    return (
        <>
            <StyledCurriculumItem isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <div className="itemTitle">
                    <div className="titleText">
                        <span className="part">Part {item.mainOrder + 1}</span>
                        {item.sub[0].title}
                    </div>
                    <img
                        src={isOpen ? ico_arrow_up_black : arrowDown}
                        alt=""
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    />
                </div>

                {isOpen &&
                    item.sub.map((data: CampusCurriCulumSubDataDto, idx: number) => {
                        const date = new Date(0);
                        date.setSeconds(data.videoLength);
                        const hhmmssFormat = date.toISOString().substr(11, 8).split(":");
                        return (
                            <div className="listItem" key={data.createdAt + idx}>
                                {data.subTitle}
                                <span>
                                    {Number(hhmmssFormat[0])
                                        ? ` ${hhmmssFormat[0]}:${hhmmssFormat[1]}:${hhmmssFormat[2]}`
                                        : `${hhmmssFormat[1]}:${hhmmssFormat[2]}`}
                                </span>
                            </div>
                        );
                    })}
            </StyledCurriculumItem>
        </>
    );
};

export default CurriculumItem;

const StyledCurriculumItem = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: ${(props) => (props.isOpen ? "fit-content" : "58px")};
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-weight: 400;
    color: ${Common.colors.normalTextColor};
    font-size: 16px;
    border-radius: 8px;
    background-color: #fbf8f8;

    .itemTitle {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${(props) => (props.isOpen ? "8px" : "0px")};
        .titleText {
            /* display: flex;
            align-items: center; */
            line-height: 22px;
            .part {
                width: 54px;
                height: 22px;
                border-radius: 24px;
                padding: 2px;
                background-color: #fecb31;
                font-weight: 400;
                font-size: 12px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: ${Common.colors.normalTextColor};
                margin-right: 4px;
            }
        }
        & > img {
            width: 16px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .listItem {
        font-weight: 400;
        font-size: 14px;
        color: ${Common.colors.normalTextColor};
        display: flex;
        padding: 8px;
        justify-content: space-between;
        /* min-height: 40px; */
        height: fit-content;
        align-items: center;
        background-color: #fbf8f8;
        line-height: 24px;
        gap: 8px;
        & > span {
            font-weight: 400;
            font-size: 12px;
            color: ${Common.colors.darkGrey04};
        }
    }
`;
