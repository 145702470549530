import { IDetailInfoParamsType } from "@src/hooks/storeGoStop/dto/storeGoStop.dto";
import create from "zustand";

type useStoreGoStopDetailType = {
    storeGoStopDetailInfo: IDetailInfoParamsType;
    setStoreGoStopDetailIfo: (detail: IDetailInfoParamsType) => void;
    resetStoreGoStopDetailInfo: () => void;
};

const initialDetailInfo: IDetailInfoParamsType = {
    origin_sale_price: 0,
    sale_price: 0,
    origin_deposit: 0,
    pyong: 0,
    deposit: 0,
    origin_monthly: 0,
    monthly: 0,
    profit_rate: 0,
    origin_rental_pyong: 0,
    rental_pyong: 0,
};

export const useStoreGoStoPDetailStore = create<useStoreGoStopDetailType>(
    (set: any) =>
        ({
            storeGoStopDetailInfo: initialDetailInfo,
            setStoreGoStopDetailIfo: async (detail: any) => {
                set(() => {
                    return {
                        storeGoStopDetailInfo: {
                            ...detail,
                        },
                    };
                });
            },
            resetStoreGoStopDetailInfo: () => set({ storeGoStopDetailInfo: initialDetailInfo }),
        } as any),
);
