import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { initialState } from "../filter/useGetFilter";

export interface mapListApiParams {
    isExpected: boolean;
    sw: {
        lntd: number;
        latd: number;
    };
    ne: {
        lntd: number;
        latd: number;
    };
    filter: any;
}
const fetchData = async (params: mapListApiParams) => {
    const response = await axiosInstance.post(`${axiosPath.newMapList}`, params);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response?.data?.payload?.list || [];

    return responseData;
};
const useGetNewMapList = (params: any) => {
    return fetchData(params);
};

const initMapListParams: mapListApiParams = {
    isExpected: true,
    sw: {
        lntd: 127.01289519727067,
        latd: 37.4548472709686,
    },
    ne: {
        lntd: 127.07852477268841,
        latd: 37.54065697838756,
    },
    filter: initialState,
};
export {   useGetNewMapList , initMapListParams };
