import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { differenceInSeconds } from "date-fns";
import { css } from "@emotion/react";
import { ConvertPeriodSubs } from "@src/util/ConvertPeriodSubs";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import timer from "@src/assets/stateLand/sandglassIcon.json";
import recommend from "@src/assets/subs/recommend.png";
import convertNumberToKorean, { convertNumberToKoreanWithThousand } from "@src/util/convertNumToKorean";
import { Radio } from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import strikethroughIcon from "@src/assets/subs/strikethroughIcon.svg";
import eventText from "@src/assets/subs/eventMessage.png";
import eventDateText from "@src/assets/subs/eventDateText.png";
import productBanner from "@src/assets/subscription/productBanner.svg";
import curlyArrow from "@src/assets/subs/curly_arrow.svg";

interface Props {
    product: IProduct;
    selectedProduct?: IProduct;
    onClickProduct: (val: IProduct) => void;
    type?: number;
    selectedProductList?: IProduct[] | any[];
}

const NewStateLandProduct: React.FC<Props> = ({ product, selectedProductList, onClickProduct, selectedProduct, type = 3 }) => {
    const [now, setNow] = useState<any>(new Date());

    const hasMonthlyInstallmentEvent = product.isEvent && product.extraData?.installment! > 0;

    const getEventDuration = (now: any) => {
        const diffTime = differenceInSeconds(new Date(product.showEndAt).getTime(), now.getTime());
        const secound = diffTime % 60;
        const min = ((diffTime - secound) % 3600) / 60;
        const hours = ((diffTime - min * 60 - secound) % 86400) / 3600;
        const day = ((diffTime - min * 60 - secound - hours * 3600) % 31536000) / 86400;
        const formatTime = `${day}일 ${hours}시간 ${min}분 ${secound}초`;

        return formatTime;
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <>
            <div
                css={productCardBoxStyle(
                    selectedProductList?.some((data) => data.code == product.code) ?? false,
                    product?.isEvent,
                )}
                onClick={() => onClickProduct(product)}
            >
                {product.recommend === 1 && <img src={recommend} alt="" className="recommend" width={45} />}
                {Date.now() < 1710687600000 && Date.now() >= 1708959600000 && (
                    <div className="eventText">
                        <img src={eventText} alt="eventText" width={88} />
                        <div css={lastDiscountTextStyle}>{product?.priceDiscountDisplay1}</div>
                    </div>
                )}
                <div css={cardContentsStyle}>
                    <div css={titleWrapStyle}>
                        <Radio
                            checked={true}
                            onChange={() => {}}
                            sx={{
                                width: 16,
                                height: 16,
                                color:
                                    selectedProductList &&
                                    selectedProductList.some((data: IProduct) => data.code === product?.code)
                                        ? "#2275EE !important"
                                        : "#C9C9C9 !important",
                            }}
                        />
                        <div css={titleStyle}>{product.name}</div>
                        {/* {product.name.includes("VIP") && <img css={eventImgBox} src={productBanner} alt="" />} */}
                    </div>
                    <div css={priceWrapStyle}>
                        {product?.term1DiscountedPrice !== product.price && (
                            <p css={priceInfoStyle}>{convertNumberToKorean(product.price)}원</p>
                        )}
                        {product?.term2DiscountedPrice ? (
                            <>
                                <div css={term2PriceStyle}>
                                    <p css={discountTextStyle}>
                                        -{Math.floor(100 - (product?.term2DiscountedPrice / product?.price) * 100)}%
                                    </p>
                                    <p css={discountPriceTextStyle}>{convertNumberToKorean(product?.term2DiscountedPrice)}원</p>
                                </div>
                                {Date.now() < 1714489200000 &&
                                    type === 3 &&
                                    convertNumberToKorean((product?.term2DiscountedPrice ?? 0) * 0.7).split("만")?.[0] !==
                                        convertNumberToKorean(product?.term1DiscountedPrice)?.replaceAll("만", "") && (
                                        <div css={term2PriceStyle}>
                                            <p css={discountTextStyle}>-30%</p>
                                            <p css={discountPriceTextStyle}>
                                                {
                                                    convertNumberToKorean((product?.term2DiscountedPrice ?? 0) * 0.7).split(
                                                        "만",
                                                    )?.[0]
                                                }
                                                만원
                                            </p>
                                        </div>
                                    )}
                                {product.code === "SUBSALE365GS02" && (
                                    <div css={term2PriceStyle}>
                                        <p css={discountTextStyle}>-30%</p>
                                        <p css={discountPriceTextStyle}>
                                            {convertNumberToKorean(
                                                Math.round(((product?.term2DiscountedPrice ?? 0) * 0.7) / 10000) * 10000,
                                            )}
                                            원
                                        </p>
                                    </div>
                                )}
                                {product.name.includes("VIP") && (
                                    <div css={term2PriceStyle}>
                                        <p css={discountTextStyle}>-42%</p>
                                        <p css={discountPriceTextStyle}>
                                            {convertNumberToKorean((product?.term2DiscountedPrice ?? 0) * 0.58).split("만")?.[0]}
                                            만원
                                        </p>
                                    </div>
                                )}
                                <div css={term1PriceStyle(hasMonthlyInstallmentEvent)}>
                                    {hasMonthlyInstallmentEvent && (
                                        <div css={monthlyInstallmentArrowStyle}>
                                            <img src={curlyArrow} alt="arrow" />
                                        </div>
                                    )}
                                    <div css={lastDiscountTextStyle}>
                                        {product?.priceDiscountDisplay1
                                            ? product?.priceDiscountDisplay1
                                            : `-${Math.floor(100 - (product?.term1DiscountedPrice / product?.price) * 100)}%`}
                                    </div>
                                    <span className="priceText">
                                        {convertNumberToKoreanWithThousand(product?.term1DiscountedPrice)}원
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div css={term1PriceStyle(hasMonthlyInstallmentEvent)}>
                                    {hasMonthlyInstallmentEvent && (
                                        <div css={monthlyInstallmentArrowStyle}>
                                            <img src={curlyArrow} alt="arrow" />
                                        </div>
                                    )}
                                    <div css={lastDiscountTextStyle}>
                                        {`-${Math.floor(100 - (product?.term1DiscountedPrice / product?.price) * 100)}%`}
                                    </div>
                                    <span className="priceText">{convertNumberToKorean(product?.term1DiscountedPrice)}원</span>
                                </div>
                            </>
                        )}
                        {hasMonthlyInstallmentEvent && (
                            <div css={monthlyInstallmentPriceStyle}>
                                3개월 할부 시 월{" "}
                                {product.term1DiscountedPrice > 0
                                    ? convertNumberToKoreanWithThousand(Math.floor(product.term1DiscountedPrice / 3))
                                          .split("")
                                          .reduce((prev, current) => (prev.includes("천") ? prev : prev + current))
                                    : "0"}
                                원
                            </div>
                        )}
                    </div>
                </div>
                {product.isEvent && now >= new Date(product.showStartAt) && now <= new Date(product.showEndAt) && (
                    <div css={eventBoxStyle}>
                        <Lottie className="lottie" animationData={timer} />
                        {getEventDuration(now)}
                    </div>
                )}
            </div>
        </>
    );
};

export default NewStateLandProduct;

const productCardBoxStyle = (active: boolean, isEvent: boolean) => css`
    border-radius: 14px;
    border: ${active ? "2px solid #2275EE" : "1px solid #dadada"};
    background-color: #ffffff;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 14px;
    margin: 14px 0;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    & > .recommend {
        position: absolute;
        transform: translate(-50%, -50%);
        top: -1px;
    }
    & > .eventText {
        position: absolute;
        bottom: ${isEvent ? "33px" : "2px"};
        left: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        /* width: 88px;
        height: 58px; */
    }
    & > .eventDateText {
        position: absolute;
        top: -15px;
        right: 14px;
    }
    ${isEvent && {
        paddingBottom: "44px",
    }};
`;

const cardContentsStyle = css`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
`;

const titleWrapStyle = css`
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    left: 0;
    max-width: 65vw;
`;
const titleStyle = css`
    overflow: hidden;
    color: #0c002c;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 26px; /* 162.5% */
`;

const eventImgBox = css`
    position: absolute;
    width: 120px;
    top: 100%;
    left: 20px;
`;

const priceWrapStyle = css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    box-sizing: border-box;
    width: 45%;
`;

const term1PriceStyle = (hasMonthlyInstallmentEvent: boolean) => css`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #faff0033;
    padding: 0px 8px;
    white-space: nowrap;

    .priceText {
        color: #ff0000;
        font-size: 18px;
        font-weight: 800;
        line-height: 26px;

        ${hasMonthlyInstallmentEvent && {
            textDecoration: "line-through",
            color: "#3c3c3c",
        }};
    }
`;

const term2PriceStyle = css`
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    display: flex;
    align-items: center;
    gap: 4px;
`;

const discountTextStyle = css`
    color: #0047ff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
`;

const lastDiscountTextStyle = css`
    border-radius: 100px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #09aa4b;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    min-width: 36px;
`;

const priceInfoStyle = css`
    color: #8d8d8d;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration: line-through;
    text-decoration-line: strikethrough;
`;

const discountPriceTextStyle = css`
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: line-through;
    text-decoration-line: strikethrough;
`;

const eventBoxStyle = css`
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff500;
    border-radius: 0 0 14px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .lottie {
        height: 40px;
        width: 40px;
    }
`;

const monthlyInstallmentArrowStyle = css`
    position: absolute;
    left: -22px;
    top: 40%;
`;

const monthlyInstallmentPriceStyle = css`
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    white-space: nowrap;

    color: #ff0000;
`;
