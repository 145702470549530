import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import arrowDown from "../../../assets/ico_arrow_down.png";
import playImg from "../../../assets/playImg.png";
import vimeoCheck from "../../../assets/vimeoCheck.png";
import vimeoCheckActive from "../../../assets/vimeoCheckActive.png";
import ico_arrow_up_black from "../../../assets/ico_arrow_up_black_bold.png";
import { CampusSugangCurriculumDataDto, CampusSugangCurriculumItemDto } from "@src/hooks/campus/dto/CampusSugang.dto";

interface CurriculumItemProps {
    item: CampusSugangCurriculumDataDto;
    recentMainOrder?: number;
    videoData: { videoUrl: string; no: number; subOrder: number; userViewedTime: number };
    setVideoData: Dispatch<SetStateAction<{ videoUrl: string; no: number; subOrder: number; userViewedTime: number }>>;
}

const SugangCurriculumItem = ({ item, recentMainOrder, videoData, setVideoData }: CurriculumItemProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    //이전 시청기록 있으면 해당 커리큘럼 열림처리
    useEffect(() => {
        if (recentMainOrder === item.mainOrder) {
            setIsOpen(true);
        }
    }, []);

    useEffect(() => {
        !isOpen && item.sub.some((i: any) => i.no === videoData.no) && setIsOpen(true);
    }, [videoData]);

    return (
        <>
            <StyledCurriculumItem isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <div className="itemTitle">
                    <div className="titleText">
                        <div className="part">Part {item.mainOrder + 1}</div>
                        {item.sub[0].title}
                    </div>
                    <img
                        src={isOpen ? ico_arrow_up_black : arrowDown}
                        alt=""
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    />
                </div>

                {isOpen &&
                    item?.sub.map((data: CampusSugangCurriculumItemDto, idx: number) => {
                        const date = new Date(0);
                        date.setSeconds(data.videoLength);
                        const hhmmssFormat = date.toISOString().substr(11, 8).split(":");
                        const check = data.videoLength * 0.9 <= data.userViewedTime;
                        return (
                            <StyledListItem
                                isActive={data.no === videoData.no}
                                className="listItem"
                                key={data.createdAt + idx}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setVideoData({
                                        videoUrl: data.videoUrl,
                                        no: data.no,
                                        subOrder: data.subOrder,
                                        userViewedTime: data.userViewedTime,
                                    });
                                }}
                            >
                                <div>
                                    {data.no === videoData.no ? (
                                        <img src={playImg} alt="" />
                                    ) : check ? (
                                        <img src={vimeoCheckActive} alt="" />
                                    ) : (
                                        <img src={vimeoCheck} alt="" />
                                    )}
                                    {data.subTitle}
                                </div>
                                <span>
                                    {Number(hhmmssFormat[0])
                                        ? ` ${hhmmssFormat[0]}:${hhmmssFormat[1]}:${hhmmssFormat[2]}`
                                        : `${hhmmssFormat[1]}:${hhmmssFormat[2]}`}
                                </span>
                            </StyledListItem>
                        );
                    })}
            </StyledCurriculumItem>
        </>
    );
};

export default SugangCurriculumItem;

const StyledCurriculumItem = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: ${(props) => (props.isOpen ? "fit-content" : "58px")};
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 16px;
    font-weight: 400;
    color: ${Common.colors.normalTextColor};
    font-size: 16px;
    border-radius: 8px;
    background-color: #fbf8f8;

    .itemTitle {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${(props) => (props.isOpen ? "8px" : "0px")};
        .titleText {
            /* display: flex;
            align-items: center; */
            line-height: 22px;
            .part {
                width: 54px;
                height: 22px;
                border-radius: 24px;
                padding: 2px;
                background-color: #fecb31;
                font-weight: 400;
                font-size: 12px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: ${Common.colors.normalTextColor};
                margin-right: 4px;
            }
        }
        & > img {
            width: 16px;
            display: flex;
            justify-content: flex-end;
        }
    }
`;

const StyledListItem = styled.div<{ isActive: boolean }>`
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    color: ${(props) => (props.isActive ? `white` : `${Common.colors.normalTextColor}`)};
    background-color: ${(props) => (props.isActive ? `${Common.colors.aucMarkerColor}` : "#fbf8f8")};

    & > div {
        display: flex;
        align-items: center;
        line-height: 24px;
        gap: 6px;

        & > img {
            width: 22px;
        }
    }
    & > span {
        font-weight: 400;
        font-size: 12px;
        color: ${(props) => (props.isActive ? `white` : `${Common.colors.normalTextColor}`)};
    }
`;
