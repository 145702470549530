import styled from "@emotion/styled";
import { useDeungiSearchText } from "@src/store/deungiPayment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type DeungiTabPropsType = {
    handleSelect: string,
    setHandleSelect: (type: string) => void;
};

const DeungiTab = ({ handleSelect, setHandleSelect }: DeungiTabPropsType) => {
    const navigate = useNavigate();
    const userDeungiSearchText = useDeungiSearchText((state: any) => state.deungiSearchText);
    const [searchParams] = useSearchParams();
    const params = userDeungiSearchText !== '' ? `&searchText=${userDeungiSearchText}` : '';

    const onTabHandler = (category: string) => {
        navigate({
            pathname: '/deungi',
            search: `?type=${category}${params}`
        });
    };

    return (
        <>
            <DeungiTabButtons>
                <div className={handleSelect === 'search' ? 'active' : ''} onClick={() => onTabHandler('search')}>실시간 등기열람</div>
                <div className={handleSelect === 'list' ? 'active' : ''} onClick={() => onTabHandler('list')}>열람내역</div>
            </DeungiTabButtons>
        </>
    );
};

const DeungiTabButtons = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 14px;

    & > div {
        width: 50%;
        height: 50px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        margin: 0 4px 0 0;
        border-radius: 8px;
        line-height: 50px;
        background: #FBF8F8;
        color: #8D8D8D;
        transition: background-color .2s ease;

        &:nth-of-type(2){
            margin: 0 0 0 4px;
        }
        &.active {
            background: #2275EE;
            color: #fff;
            font-weight: 700;
        }
    }
`;

export default DeungiTab;
