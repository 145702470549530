import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { css } from "@emotion/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import loading from "@src/assets/Loading.json";
import { useFindOneStateLandSubs, useFindSubsInfo, useFindSubsRefundAmount } from "@src/hooks/subscribe/useSubscribe";
import { transferToNative } from "@src/lib/WebAppBridge";
import UnRegularlySubsctibe from "./UnRegularlySubsctibe";
import RegularlySubscribe from "./RegularlySubscribe";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { useTokenStore } from "@src/store/TokenStore";
import useCallToken from "@src/hooks/useCallToken";

interface Props {}

const UnSubscribe: React.FC<Props> = () => {
    const { token } = useCallToken();
    const tokenStore = useTokenStore((state) => state.accessToken);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showUsedStateLand, setShowUsedStateLand] = useState<boolean>(false);
    const { data, refetch, isFetching } = useFindSubsInfo();
    const { data: subsRefundAmount, refetch: refetchSubsRefundAmount } = useFindSubsRefundAmount();

    const showModal = () => setShowErrorModal(true);

    //* 유저 국공유지 상품정보
    const { data: stateLandSubs, refetch: refetchStateLandSubs } = useFindOneStateLandSubs();

    useEffect(() => {
        if (token.length) {
            refetch();
            refetchStateLandSubs();
            refetchSubsRefundAmount();
        }
    }, [token]);

    return (
        <>
            <div css={rootBoxStyle}>
                <div css={headStyle}>
                    <ArrowBackIosNewRoundedIcon onClick={() => transferToNative(false, "closeUnsubscribeView")} />
                    <p>해지하기</p>
                    <p />
                </div>
                <div css={contentStyle}>
                    {isFetching || !data ? (
                        <div css={lottieBoxStyle}>
                            <Lottie className="lottie" animationData={loading} />
                        </div>
                    ) : !data?.nextRenewAt ? (
                        <UnRegularlySubsctibe
                            subsRefundAmount={subsRefundAmount}
                            checkUsedDay={
                                new Date(subsRefundAmount?.firstPaidAt ?? 0) >= new Date("2023-10-23") &&
                                new Date(subsRefundAmount?.firstPaidAt ?? 0) < new Date("2023-11-14") &&
                                (subsRefundAmount?.usedDays ?? 0) >= 8
                            }
                            showModal={showModal}
                            onSubmitUseStateLand={() => setShowUsedStateLand(true)}
                            stateLandSubs={stateLandSubs}
                            firstPaidAt={subsRefundAmount?.firstPaidAt}
                        />
                    ) : (
                        <RegularlySubscribe
                            checkUsedDay={
                                new Date(subsRefundAmount?.firstPaidAt ?? 0) >= new Date("2023-10-23") &&
                                new Date(subsRefundAmount?.firstPaidAt ?? 0) < new Date("2023-11-14") &&
                                (subsRefundAmount?.usedDays ?? 0) >= 8
                            }
                            showModal={showModal}
                            onSubmitUseStateLand={() => setShowUsedStateLand(true)}
                            stateLandSubs={stateLandSubs}
                            firstPaidAt={subsRefundAmount?.firstPaidAt}
                        />
                    )}
                </div>
            </div>
            {showErrorModal && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>환불불가</p>
                            <p css={dialogContentStyle}>
                                약관에 따라 구독 결제 시부터
                                <br />
                                8일 이후에는 환불이 불가합니다
                            </p>
                        </>
                    }
                    onConfirm={() => setShowErrorModal(false)}
                />
            )}
            {showUsedStateLand && (
                <ConfirmModal
                    resultMessage={
                        <>
                            <p css={dialogTitleStyle}>환불불가</p>
                            <p css={dialogContentStyle}>
                                약관에 따라 국•공유지 필터 사용 시<br />
                                구독 환불이 불가합니다
                            </p>
                        </>
                    }
                    onConfirm={() => setShowUsedStateLand(false)}
                />
            )}
        </>
    );
};
export default UnSubscribe;

const rootBoxStyle = css`
    height: 100dvh;
    height: 100vh;
    overflow: hidden;
`;

const headStyle = css`
    display: flex;
    padding: 14px;
    justify-content: space-between;
    align-items: center;
    color: #0c002c;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    height: 56px;
    position: absolute;
    top: 0;
    width: 100vw;
    background-color: #ffffff;
`;

const contentStyle = css`
    margin-top: 60px;
`;

const lottieBoxStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 100%;
    & > .lottie {
        width: 30%;
    }
`;

const dialogTitleStyle = css`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 18px;
    word-break: break-word;
`;
