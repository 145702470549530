import { css } from "@emotion/react";
import { Collapse } from "@mui/material";
import { ConvertPhoneNumber } from "@src/util/convertPhoneNumber";
import React, { useState } from "react";
import upArrow from "@src/assets/upArrow.svg";
import downArrow from "@src/assets/downArrow.svg";
import smallPhone from "../../../assets/smallPhone.svg";
import smallComments from "../../../assets/smallComments.svg";
import NewItem from "./NewItem";
import { expertInquiryFilterState, UserInquiriesType } from "@src/hooks/expert/Inquiry/dto/inquiry.dto";

interface UserCardProps {
    item: any;
    filters: expertInquiryFilterState;
    openPopup: (userPhone: string) => void;
}

const UserCard = ({ item, filters, openPopup }: UserCardProps) => {
    const [visible, setVisible] = useState<boolean>(true);
    return (
        <>
            <div css={userCardWrap}>
                <div css={contentsTitle}>
                    <div css={contentsTitleWrap}>
                        <p>{item.userName}님</p>
                        <p>({ConvertPhoneNumber(item.userPhone ?? "")})</p>
                    </div>
                    <div css={contentsCountWrap}>
                        <div css={countWrap}>
                            <div css={countButton}>
                                <div css={countButtonDim(filters.inquiryType === "message")}></div>
                                <img src={smallPhone} alt="messageTypeImg" />
                                <span>{item.callCount}</span>
                            </div>
                            <div css={countButton}>
                                <div css={countButtonDim(filters.inquiryType === "call")}></div>
                                <img src={smallComments} alt="messageTypeImg" />
                                <span>{item.messageCount}</span>
                            </div>
                        </div>
                        <div css={ItemCardShowButton}>
                            <img
                                src={visible ? upArrow : downArrow}
                                alt="openCloseImg"
                                onClick={() => {
                                    setVisible(!visible);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Collapse in={visible}>
                    {item?.Inquiries?.map((inquiry: UserInquiriesType, index: string) => (
                        <div key={index}>
                            <div css={cardTitle}>
                                <span>
                                    문의번호 {inquiry.inquiryNo}
                                    <NewItem createdAt={inquiry.createdAt} />
                                </span>
                                <span>{inquiry?.createdAt}</span>
                            </div>
                            <div css={cardContents}>
                                {inquiry?.itemSubId !== null ? (
                                    <div css={bidContents}>
                                        <div css={bidTitle}>
                                            <span>{inquiry?.bidType}</span>
                                            {inquiry?.itemSubId}
                                            {visible && (
                                                <img
                                                    src={inquiry.type === "call" ? smallPhone : smallComments}
                                                    onClick={() => {
                                                        openPopup(item?.userPhone);
                                                    }}
                                                    alt="messageTypeImg"
                                                />
                                            )}
                                        </div>
                                        <div css={bidSubTitle}>
                                            <span className="status">{inquiry?.itemStatus}</span>
                                            <span className="court">{inquiry?.court}</span>
                                            <span className="date">{inquiry?.dueDate}</span>
                                        </div>
                                        <div css={bidAddress}>{inquiry?.address}</div>
                                    </div>
                                ) : (
                                    <div css={nomralInquiryStyle}>일반 문의</div>
                                )}
                            </div>
                        </div>
                    ))}
                </Collapse>
            </div>
        </>
    );
};

export default UserCard;

const userCardWrap = css`
    width: 100%;
    height: fit-content;
    margin: 14px 0px;
    box-sizing: border-box;
    border: 0.6px solid #c9c9c9;
`;

const contentsTitle = css`
    width: 100%;
    min-height: 44px;
    height: fit-content;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    justify-content: space-between;
    padding: 10px 14px;
    img {
        width: 16px;
        height: 16px;
    }
`;
const contentsTitleWrap = css`
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    gap: 4px;
    & > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        /* white-space: nowrap; */
    }
`;
const contentsCountWrap = css`
    display: flex;
    align-items: center;
`;

const cardTitle = css`
    width: 100%;
    background-color: #edf0f6;
    padding: 4px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #3c3c3c;
        display: flex;
        align-items: center;
    }
`;

const cardContents = css`
    display: flex;
    flex-direction: column;
    padding: 8px 14px;
    box-sizing: border-box;
`;

const bidContents = css`
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    margin-top: 8px;
`;
const bidTitle = css`
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 4px;
    }
    & > img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 14px;
    }
`;

const bidSubTitle = css`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    flex-wrap: wrap;
    & > .status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 20px;
        border-radius: 4px;
        padding: 0px 4px;
        background-color: #f0f0f0;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #505050;
        white-space: nowrap;
    }
    & > .court {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
    }
    & > .date {
        color: #505050;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
    }
`;

const bidAddress = css`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    color: #505050;
`;

const countWrap = css`
    display: flex;
    width: 90px;
    height: 28px;
    border-radius: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    background-color: #f7f8fa;
    gap: 2px;
`;

const countButton = css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    border-radius: 14px;
    padding: 2px 4px;
    background-color: white;
    & > img {
        width: 16px;
        height: 16px;
    }
    & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #3c3c3c;
    }
`;

const ItemCardShowButton = css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    & > img {
        width: 16px;
        height: 16px;
    }
`;

const countButtonDim = (isDisabled: boolean) => css`
    background-color: ${isDisabled ? "#DADADA" : "white"};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    border-radius: 14px;
    padding: 2px 4px;
    position: absolute;
    opacity: ${isDisabled ? "0.7" : "0"};
`;

const nomralInquiryStyle = css`
    height: 44px;
    display: flex;
    align-items: center;
`;
