import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Grid, Radio } from "@mui/material";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import ProductCard from "./ProductCard";
import StateLandEventProduct from "../stateLand/stateLandInfo/StateLandEventProduct";
import NewStateLandProduct from "../stateLand/stateLandInfo/NewStateLandProduct";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import SingleProductCard from "./singleProductCard";
import SingleRoadPlanProductCard from "./singleRoadPlanProductCatd";
import IosRoadPlanProductCard from "./IosRoadPlanProductCard";

interface Props {
    type: string
    stateLandList?: IProduct[];
    selectedProduct: IProduct;
    onChangeSelectedProduct: (val: IProduct) => void;
    divRef: any
}

const IosStateRoadContents: React.FC<Props> = ({ stateLandList, selectedProduct, onChangeSelectedProduct, type, divRef }) => {
    const [openTooltip, setOpenTooltip] = useState<number>(0); // 1 프리미엄 2 국공유지

    const [tooltipContents, setTooltipContents] = useState<any>(<div />);

    const onChangeTooltipContent = (type: number) => {
        switch (type) {
            case 2:
                return setTooltipContents(
                    <div css={tooltipBoxStyle}>
                        <p className="title">확장예정 도로 데이터맵(제주)</p>
                        <p className="content">
                            {/* <li>3만명 돌파 기념 추가 할인 이벤트</li> */}
                            <li>도로 확장 및 신설 게획 예정인 도로 확인 가능</li>
                            <li>1년동안 제주도 한정 확장예정 도로 서비스 사용</li>
                        </p>
                    </div>,
                );
            case 3:
                return setTooltipContents(
                    <div css={tooltipBoxStyle}>
                        <p className="title">주거/토지 수익 필터</p>
                        <p className="content">
                            {/* <li>3만명 돌파 기념 추가 할인 이벤트</li> */}
                            <li>1년동안 주거 토지의 Go-Stop 확인 가능</li>
                            <li>시세차익 등 필터 사용 가능</li>
                        </p>
                    </div>,
                );
            case 4:
                return setTooltipContents(
                    <div css={tooltipBoxStyle}>
                        <p className="title">상가 Go-Stop</p>
                        <p className="content">
                            <li>1년동안 상가 예상수익률 Go-Stop 확인 가능</li>
                            <li>내돈으로 살 수 있는 상가의 예상수익률 계산 서비스 제공</li>
                        </p>
                    </div>,
                );
            default:
                return setTooltipContents(<div css={tooltipBoxStyle} />);
        }
    };
    return (
        <div ref={divRef}>
            {stateLandList && !!stateLandList?.length && (
                <div css={productBoxStyle(selectedProduct?.type === type)}>
                    <div>
                        <p css={productTitleStyle}>
                            {type === "road_plan" && "확장예정 도로만 구매하고 싶다면?"}
                            {type === "premium_filter" && "주거/토지의 수익을 얻고 싶다면?"}
                            {type === "Retail_profit_rate" && "상가 투자를 계획하고 있다면?"}
                        </p>
                        <div css={productNameStyle}>
                            <div css={titleRootStyle}>
                                <p>{stateLandList && stateLandList[0]?.name}</p>
                                <InfoRoundedIcon
                                    onClick={() => {
                                        type === "road_plan" && onChangeTooltipContent(2);
                                        type === "premium_filter" && onChangeTooltipContent(3);
                                        type === "Retail_profit_rate" && onChangeTooltipContent(4);
                                        setOpenTooltip(1);
                                    }}
                                    sx={{ width: "20px", height: "20px", color: "#c9c9c9", marginTop: "-2px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div onClick={() => onChangeSelectedProduct(stateLandList[0])}>
                        <IosRoadPlanProductCard
                            type={type}
                            product={stateLandList}
                            selectedProduct={selectedProduct}
                            onClickProduct={onChangeSelectedProduct}
                        />
                    </div>
                </div>
            )}
            {!!openTooltip && (
                <ConfirmModal
                    resultMessage={tooltipContents}
                    onConfirm={() => {
                        setOpenTooltip(0);
                        setTooltipContents(<div />);
                    }}
                />
            )}
        </div>
    );
};

export default IosStateRoadContents;

const productBoxStyle = (active: boolean) => css`
    border-top: 4px solid #f0f0f0;
    background-color: ${active ? "#DFF0FF" : "#ffffff"};
    padding: 24px 14px;
`;

const productTitleStyle = css`
    color: #0047FF;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;  
    font-family: Sandoll Samliphopangche;
    font-weight: 400;
`;

const titleRootStyle = css`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const regionButtonStyle = (active: boolean, isChecked: boolean) => css`
    border-radius: 4px;
    background-color: ${active ? "#2275EE" : !isChecked ? "#ffffff" : "#f0f0f0"};
    color: ${active ? "#ffffff" : "#0C002C"};
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    padding: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    & .price {
        font-weight: 700;
    }
`;

const productNameStyle = css`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    p {
        color: #282828;
        font-family: Sandoll Samliphopangche;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.48px;
    }
    svg {
        width: 20px;
        height: 20px;
    }
`;

const tooltipBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #0c002c;
    & > .title {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    & > .content {
        text-align: center;
        color: #0c002c;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    li {
        list-style: inside;
        margin: 0;
        padding: 0;
    }
    li::marker {
        content: "∙";
        font-size: 20px;
        margin: 0;
    }
`;
