import React, { useEffect, useState } from "react";
import { format } from "date-fns";
const today = new Date();

const useDatePickerFilter = (dateState: string[]) => {
    const [monthState, setMonthState] = useState<any>(format(today, "yyyy년 MM월"));
    const [state, setState] = useState<any>([
        {
            startDate: null,
            endDate: null,
            key: "selection",
        },
    ]);

    useEffect(() => {
        if (dateState) {
            if (dateState[0]?.length || dateState[1]?.length) {
                setState([
                    {
                        startDate: new Date(dateState[0]),
                        endDate: new Date(dateState[1]),
                        key: "selection",
                    },
                ]);
            }
        }
    }, [dateState]);

    const onChangeMonthState = (month: any) => {
        const test = new Date(month);
        const result = format(test, "yyyy년 MM월");
        setMonthState(result);
    };

    const onClickResetDate = () => {
        setState([
            {
                startDate: null,
                endDate: null,
                key: "selection",
            },
        ]);
    };

    return { monthState, state, setState, onChangeMonthState, onClickResetDate };
};

export default useDatePickerFilter;
