import styled from "@emotion/styled";
import ico_arrow_back from "@src/assets/ico_arrow_back.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useDeungiSearchText } from "@src/store/deungiPayment";
import React from "react";
import { useLocation } from "react-router-dom";

type DeungiHeaderPropsType = {
    title: string;
};

const DeungiHeader = ({ title }: DeungiHeaderPropsType) => {
    const clearUserIdStorage = useDeungiSearchText.persist.clearStorage;
    const onPrevHandler = () => {
        const currentLocation = location.href;
        if (title !== "충전하기") {
            clearUserIdStorage();
            transferToNative("done", "closeWebView");
            return;
        }

        // history.back을 시도했지만 같은 경로에 있다면 (페이지 전환이 되지 않았다면)
        // 네이티브에 요청하여 웹뷰를 종료한다
        history.back();
        setTimeout(() => currentLocation === location.href && transferToNative("done", "closeWebView"), 100);
    };

    return (
        <DeungiHeaderContainer>
            <div className="prevBtn" onClick={onPrevHandler}>
                <img src={ico_arrow_back} />
            </div>
            <div className="content">
                {title === "미스고 부동산 등기열람" && <span className="live">Live</span>}
                <span>{title}</span>
            </div>
        </DeungiHeaderContainer>
    );
};

export default DeungiHeader;

const DeungiHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    padding: 14px;

    .prevBtn {
        position: absolute;
        left: 5px;
        top: 10px;
        width: 40px;
        height: 40px;
        padding: 10px;

        & > img {
            width: 10px;
            height: 17px;
        }
    }

    .content {
        display: flex;
        align-items: center;
        & > .live {
            border-radius: 100px;
            background-color: #f6494b;
            display: inline-block;
            padding: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 11px;
            font-weight: 700;
            line-height: 10px;
            margin-right: 4px;
        }
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;
    }
`;
