import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Vimeo from "@vimeo/player";
import Lottie from "lottie-react";
import styled from "@emotion/styled";

import ConfirmModal from "@src/components/popup/ConfirmModal";
import { checkDevice } from "@src/components/deungi/common/utils";
import { useGetCampusDetail, useGetCheckCanPlay, useGetCurriculum } from "@src/hooks/campus/useCampusDetail";
import { CampusDetailCurriculumListDto, CampusDetailResponseDto } from "@src/hooks/campus/dto/CampusDetail.dto";
import useScroll from "@src/hooks/useScroll";
import CampusTimer from "./CampusTimer";
import Curriculum from "./Curriculum";
import Grade from "./Grade";
import Guide from "./Guide";
import FloatingButton from "./FloatingButton";

import { Common } from "@src/styles/Common";
import loading from "../../../assets/Loading.json";
import backBtn from "../../../assets/whiteBack.png";
import blackBtn from "../../../assets/LeftIcon.png";
import star from "../../../assets/star.png";
import classIntroduction from "../../../assets/classIntroduction.png";

const CampusDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const TAB_TITLES = [
        { key: "introduction", value: "강의소개" },
        { key: "curriculum", value: "커리큘럼" },
        { key: "grade", value: "수강평" },
        { key: "guide", value: "이용안내" },
    ];
    const tabRef = useRef<any[]>([]);
    const [currentTab, setCurrentTab] = useState<any>(TAB_TITLES[0].key);
    const guideClickRef = useRef<any>();
    const [campusData, setCampusData] = useState<CampusDetailResponseDto>();
    const [curriculum, setCurriculum] = useState<CampusDetailCurriculumListDto>();
    const [isCheck, setIsCheck] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const titleRef = useRef<any>();
    const [isShowStickyTitle, setIsShowSticyTitle] = useState<boolean>(false);
    const [isVimeoLoading, setIsVimeoLoading] = useState<boolean>(true);
    const [player, setPlayer] = useState<any>();

    const scrollRef = useRef<HTMLDivElement | null>(null);

    const scrollHeight = useScroll({ scrollRef, deps: [campusData] });

    useEffect(() => {
        if (scrollHeight < tabRef.current[3]?.offsetTop - 200) setCurrentTab(TAB_TITLES[2].key);
        if (scrollHeight < tabRef.current[2]?.offsetTop - 200) setCurrentTab(TAB_TITLES[1].key);
        if (scrollHeight < tabRef.current[1]?.offsetTop - 130) setCurrentTab(TAB_TITLES[0].key);
        if (scrollHeight >= tabRef.current[3]?.offsetTop - 135) setCurrentTab(TAB_TITLES[3].key);
        if (scrollRef.current && scrollRef.current.clientHeight >= scrollRef.current?.scrollHeight - scrollHeight - 3)
            setCurrentTab(TAB_TITLES[3].key);
    }, [scrollHeight]);

    //제목 고정
    useEffect(() => {
        if (campusData) {
            setTimeout(() => {
                if (titleRef.current) {
                    const trySetSticyTitle = (ent: any[]) => {
                        if (ent[0].isIntersecting) {
                            setIsShowSticyTitle(false);
                        } else {
                            setIsShowSticyTitle(true);
                        }
                    };
                    const observerOption2 = { threshold: 1 };
                    const titleObserver = new IntersectionObserver(trySetSticyTitle, observerOption2);

                    titleObserver.observe(titleRef.current);

                    return () => {
                        titleObserver.disconnect();
                    };
                }
            }, 300);
        }
    }, [titleRef.current, campusData]);

    const tryGetCampusDetail = async () => {
        const deviceType = checkDevice();
        // const response = await useGetCampusDetail(location.state.no, location.state.saleType);
        const response = await useGetCampusDetail(Number(params.campusNo) ?? 0, deviceType === "android" ? 6 : 7);

        // const curriculumData = await useGetCurriculum(location.state.no);
        const curriculumData = await useGetCurriculum(Number(params.campusNo) ?? 0);
        setCurriculum(curriculumData.data);
        setCampusData(response.data);
    };

    const tryCheckCanPlay = async () => {
        // const response = await useGetCheckCanPlay(location.state.no);
        const response = await useGetCheckCanPlay(Number(params.campusNo) ?? 0);
        setIsCheck(response.data !== 0 ? response.data : false);
    };

    useEffect(() => {
        tryGetCampusDetail();
        tryCheckCanPlay();
    }, [params]);

    //비메오 세팅을 통한 로딩 확인
    useEffect(() => {
        setPlayer(null);
        if (campusData) {
            setTimeout(() => {
                const iframe: any = document.querySelector("iframe");
                // iframe.setAttribute("playsinline", "");
                setPlayer(new Vimeo(iframe));
            }, 1000);
        }
    }, [campusData]);

    useEffect(() => {
        if (player) {
            setIsVimeoLoading(false);
        }
    }, [player]);

    return (
        <>
            {campusData ? (
                <StyledCampusDetailWrapper summaryInformation={campusData.contents} ref={scrollRef}>
                    {isShowStickyTitle && (
                        <div className="detailTitle">
                            <div className="backBtn">
                                <img
                                    src={blackBtn}
                                    alt=""
                                    onClick={() => {
                                        navigate("/campus");
                                    }}
                                />
                            </div>
                            <div className="text">{campusData.title}</div>
                        </div>
                    )}

                    <div className="vimeo">
                        <div className="backBtn">
                            <img
                                src={backBtn}
                                alt=""
                                onClick={() => {
                                    navigate("/campus");
                                }}
                            />
                        </div>
                        {isVimeoLoading && (
                            <StyledVimeoLoading>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </StyledVimeoLoading>
                        )}
                        <div className="player">
                            <iframe
                                src={campusData.sampleVideoUrl}
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="information">
                        <div className="tagDiv">
                            {campusData?.hashTag &&
                                campusData?.hashTag?.split(" ").map((text: string, idx: number) => (
                                    <div key={text + idx} className="tag">
                                        {text}
                                    </div>
                                ))}
                        </div>
                        <div className="title" ref={titleRef}>
                            {campusData?.title}
                        </div>
                        <div className="gradeStudents">
                            {campusData.showReview ? (
                                <div className="grade">
                                    <div>
                                        <img src={star} alt="" />
                                    </div>
                                    {campusData.avgReviewScore === 0 ? "0.0" : campusData.avgReviewScore} (
                                    {campusData.totalReviewUserCnt && campusData.totalReviewUserCnt.toLocaleString()})
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="students">
                                {campusData.showUserCnt &&
                                    `수강생 총 ${campusData.totalUserCnt && campusData.totalUserCnt.toLocaleString()}명`}
                            </div>
                        </div>
                        <div className="salePrice">
                            <span className="discount">{campusData.productInfo.priceDiscountDisplay1}</span>{" "}
                            {campusData.productInfo.discountedPrice.toLocaleString()}원
                        </div>
                        <div className="summaryInformation" dangerouslySetInnerHTML={{ __html: campusData.contents }}></div>
                    </div>
                    {new Date(campusData.eventExpiredAt).getTime() > Date.now() &&
                        new Date(campusData.eventStartAt).getTime() < Date.now() &&
                        campusData.eventTitle && <CampusTimer title={campusData.eventTitle} end={campusData.eventExpiredAt} />}

                    <div className="stickyWrap">
                        {TAB_TITLES.map(({ key, value }: { key: string; value: string }) => {
                            const isActivate = currentTab === key ? "active" : "inactive";
                            return (
                                <div
                                    key={value}
                                    className={`sticky ${isActivate}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        const element = document.getElementById(key);
                                        // behavior: 'smooth'를 사용하면 가장 끝의 요소가 실행되지 않음
                                        element?.scrollIntoView();
                                    }}
                                >
                                    {value}
                                    <div></div>
                                </div>
                            );
                        })}
                    </div>
                    <MoreInformation>
                        <div className="introduction class" ref={(element) => (tabRef.current[0] = element)}>
                            <div className="scrollAnchor" id="introduction"></div>
                            <div className="title">
                                <img src={classIntroduction} alt="" />
                            </div>
                            <img src={campusData.introImgUrl} alt="" />
                        </div>
                        <div className="curriculum class" ref={(element) => (tabRef.current[1] = element)}>
                            <div className="scrollAnchor" id="curriculum"></div>
                            <Curriculum data={curriculum} />
                        </div>
                        <div className="grade class" ref={(element) => (tabRef.current[2] = element)}>
                            <div className="scrollAnchor" id="grade"></div>
                            {/* <Grade no={location.state.no} /> */}
                            <Grade no={Number(params.campusNo) ?? 0} />
                        </div>
                        <div className="guide class" ref={(element) => (tabRef.current[3] = element)}>
                            <div className="scrollAnchor" id="guide"></div>
                            <div className="clickBox" ref={guideClickRef}></div>
                            <Guide />
                        </div>
                    </MoreInformation>
                    <FloatingButton
                        isCheck={isCheck}
                        setIsShowModal={setIsShowModal}
                        code={campusData.productInfo.code}
                        coupon={campusData.couponNo}
                    />
                </StyledCampusDetailWrapper>
            ) : (
                <StyledLottieDiv>
                    <div>
                        <Lottie animationData={loading} />
                    </div>
                </StyledLottieDiv>
            )}
            {isShowModal && (
                <ConfirmModal
                    resultMessage={"이미 수강중인 강의에요"}
                    onCancel={() => {
                        setIsShowModal(false);
                    }}
                    onConfirm={() => {
                        navigate(`/campus/sugang/${isCheck}`, { state: { no: isCheck, showToast: false } });
                    }}
                    buttonText={"강의 보기"}
                    cancelText={"닫기"}
                />
            )}
        </>
    );
};

export default CampusDetail;

const StyledCampusDetailWrapper = styled.div<{ summaryInformation: string }>`
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh; /* [3] */
    height: calc(var(--vh, 1vh) * 100); /* [4] */
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .detailTitle {
        width: 100%;
        min-height: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: ${Common.colors.normalTextColor};
        background-color: white;
        z-index: 100;
        position: fixed;
        .text {
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            /* display: flex;
            justify-content: center;
            align-items: center; */
        }
        .backBtn {
            position: absolute;
            width: 10px;
            height: 17px;
            top: 46%;
            left: 20px;
            transform: translate(0, -50%);
            & > img {
                width: 100%;
                height: 100%;
            }
        }
    }

    ::-webkit-scrollbar {
        display: none;
    }
    .vimeo {
        /* width: 100%;
        position: relative;
        min-height: 220px; */
        .player {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
        }
        /* background-color: red; */
        .player iframe,
        .player object,
        .player embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .backBtn {
            position: absolute;
            z-index: 20;
            width: 10px;
            height: 17px;
            top: 18px;
            left: 20px;
            & > img {
                width: 100%;
                height: 100%;
            }
        }

        & > iframe {
            width: 100vw;
            height: 100%;
        }
    }

    .information {
        display: flex;
        flex-direction: column;
        padding: 14px;
        position: relative;
        .tagDiv {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            font-weight: 400;
            font-size: 12px;
            color: ${Common.colors.darkGrey03};
            margin-bottom: 14px;
        }
        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: ${Common.colors.normalTextColor};
            margin-bottom: 8px;
        }
        .gradeStudents {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 8px;
            .grade {
                font-weight: 400;
                gap: 4px;
                align-items: center;
                font-size: 14px;
                display: flex;
                color: ${Common.colors.darkGrey03};
                & > div {
                    width: 16px;
                    & > img {
                        width: 100%;
                    }
                }
            }
            .students {
                font-weight: 400;
                font-size: 14px;
                color: ${Common.colors.darkGrey04};
            }
        }
        & > .salePrice {
            display: flex;
            gap: 4px;
            margin-bottom: 14px;
            color: ${Common.colors.darkGrey03};
            font-size: 20px;
            align-items: center;
            font-weight: 700;
            & > .discount {
                color: ${Common.colors.notiColor};
                font-size: 18px;
                font-weight: 700;
            }
        }
        .summaryInformation {
            width: 100%;
            min-height: ${(props) => (props.summaryInformation ? "92px" : "0px")};
            height: fit-content;
            background-color: #fbf8f8;
            border-radius: 8px;
            padding: ${(props) => (props.summaryInformation ? "8px 14px" : "0px")};
            display: flex;
            flex-direction: column;
            font-weight: 400;
            justify-content: space-evenly;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
            gap: 4px;
        }
    }
    .stickyWrap {
        display: flex;
        position: sticky;
        top: 55px;
        width: 100%;
        justify-content: space-around;
        border-bottom: 1px solid #f0f0f0;
        background-color: white;
        z-index: 10;
        .sticky {
            padding: 14px 0px;
            font-weight: 500;
            font-size: 16px;
            color: ${Common.colors.normalTextColor};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            /* & > div {
                height: 4px;
                margin-top: 14px;
                background-color: ${Common.colors.blue};
                border-radius: 4px;
            } */
        }
        .active {
            padding: 14px 0 0 0;
            font-weight: 700;
            font-size: 16px;
            color: ${Common.colors.blue};
            & > div {
                height: 4px;
                margin-top: 14px;
                background-color: ${Common.colors.blue};
                border-radius: 4px;
            }
        }
    }
`;

const MoreInformation = styled.div`
    width: 100vw;
    margin-bottom: 114px;
    .introduction {
        width: 100%;
        margin-top: 24px;
        .title {
            width: 100%;
            display: flex;
            justify-content: center;
            height: 72px;
            align-items: center;
            & > img {
                width: 115px;
                height: 36px;
            }
        }
        & > img {
            width: 100%;
        }
    }
    .class {
        width: 100%;
        position: relative;
        height: fit-content;
        .scrollAnchor {
            width: 100%;
            height: calc(30% + 105px);
            position: absolute;
            left: 0;
            top: -105px;
            z-index: -1;
        }
        .clickBox {
            width: 100%;
            height: 137px;
            position: absolute;
            left: 0;
            top: 212px;
            z-index: -1;
        }
        #guide {
            top: -131px;
        }
        #curriculum {
            height: 122px;
            top: -122px;
        }
    }
`;

const StyledLottieDiv = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 222px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const StyledVimeoLoading = styled.div`
    width: 100%;
    height: 220px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;

    position: absolute;
    & > div {
        width: 106px;
        height: 63px;

        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;
