import React from "react";
import { IFilterParamsType } from "@src/hooks/goStop/dto/goStop.dto";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

export const auctionStatusList = ["진행중"];

const GoStopAuctionStatusFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(filterParams.status.some((val: any) => !val) || !filterParams.status.length)}>
                <p className="titleText">진행상태</p>
                {allFilter ? (
                    <button className="allButton" onClick={() => setFilterParams({ ...filterParams, status: [1, 2] })}>
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={selectButtonBoxStyle}>
                {auctionStatusList.map(
                    (item, idx) =>
                        (!allFilter || !!idx) && (
                            <button
                                key={item}
                                css={selectButtonStyle(
                                    !idx
                                        ? filterParams.status.length === 2
                                        : idx === filterParams.status[0] && filterParams.status.length === 1,
                                )}
                                onClick={() => {
                                    if (idx === 0) {
                                        setFilterParams({ ...filterParams, status: [1, 2] });
                                    } else {
                                        const currentStatusList = filterParams.status || [];
                                        const newStatusList = currentStatusList.includes(idx)
                                            ? currentStatusList.length === 2
                                                ? [idx]
                                                : currentStatusList
                                            : [...currentStatusList, idx].sort();
                                        setFilterParams({ ...filterParams, status: newStatusList });
                                    }
                                }}
                            >
                                {item}
                            </button>
                        ),
                )}
            </div>
        </div>
    );
};

export default GoStopAuctionStatusFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : "#ffffff"};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: #f0f0f0;
    background-color: ${active ? Common.colors.aucMarkerColor : "#f0f0f0"};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;
