import { css } from "@emotion/react";
import { fetchAddress } from "@src/hooks/map/useAddress";
import { WebAppBridge, transferToNative } from "@src/lib/WebAppBridge";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ClearIcon from "@src/assets/ico_draw_clear.svg";
import backIcon from "@src/assets/ico_arrow_back.png";
import mapIcon from "@src/assets/ico_map.png";
import noImage from "@src/assets/noImage.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import Lottie from "lottie-react";

interface Props { }

const PanoramaView: React.FC<Props> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const mgPanoRef = useRef<any>(null);
    const mgPanoMapRef = useRef<any>(null);
    const panoViewRef = useRef<any>(null);
    const roadMarkerInPanoView = useRef<naver.maps.Marker | null>(null);
    const [isShowPanoView, setIsShowPanoView] = useState<boolean>(false);
    const [panoAddress, setPanoAddress] = useState<string>("");
    const [panoStatus, setPanoStatus] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(true);

    const getCurrentAddress = async (position: { lat: number; lng: number }) => {
        const newAddress = await fetchAddress({
            position,
        });
        setPanoAddress(newAddress);
    };


    let initLatLng = new naver.maps.LatLng(
        Number(searchParams.get("lat") ?? 37.49094871675334),
        Number(searchParams.get("lng") ?? 127.05557683885974),
    );

    WebAppBridge.isRoadView = () => {
        showPanoView(initLatLng);
        setIsLoading(true);
        naver.maps.Event.addListener(mgPanoRef.current, "pano_status", async (status: "OK" | "ERROR") => {
            if (status === "OK") {
                transferToNative(true, "checkRoadView");
                setIsLoading(false);
            } else if (status === "ERROR") {
                setPanoStatus(false);
                transferToNative(false, "checkRoadView");
                transferToNative("해당 위치에 대한 거리뷰가 없습니다.", "alertMessage");
                setIsLoading(false);
            }
        });
    };



    const showPanoView = async (center: any, panoId?: string) => {
        setIsLoading(true);
        getCurrentAddress({ lat: center.y, lng: center.x });
        setIsShowPanoView(false);
        mgPanoMapRef.current = new naver.maps.Map("panoviewMap", {
            center: center,
            zoom: 17,
            mapDataControl: false,
            mapTypeId: "hybrid",
        });
        const panoStreet = new naver.maps.StreetLayer();
        panoStreet?.setMap(mgPanoMapRef.current);
        roadMarkerInPanoView.current = new naver.maps.Marker({
            position: center,
            map: mgPanoMapRef.current,
            clickable: true,
            visible: true,
            title: "useRoadMarker",
            icon: {
                url: "https://missgo-campus.s3.ap-northeast-2.amazonaws.com/panoramaImg.png",
                anchor: new naver.maps.Point(27, 31),
            },
            zIndex: 600,
        });
        if (!mgPanoRef.current?._panoView) {
            mgPanoRef.current = new naver.maps.Panorama("panoview", {
                position: center,
                pov: {
                    pan: -135,
                    tilt: -20,
                    fov: 100,
                },
                minScale: 7,
                maxScale: 7,
                zoomControl: false,
                size: {
                    width: window.innerWidth,
                    height: window.innerHeight ?? 800,
                },
                logoControl: false,
                flightSpot: false,
                aroundControl: false,
            });
            naver.maps.Event.once(mgPanoRef.current, "pano_status", (status: "OK" | "ERROR") => {
                if (status === "OK") {
                    const newPov = mgPanoRef.current.getPov().pan % 360;
                    const drawingPov = Math.round(newPov / 22.5);
                    const roadImg: any = document.querySelector("div[title='useRoadMarker'] > img");
                    if (roadImg)
                        roadImg.style.top = `${-600 + 75 * drawingPov <= -1200
                            ? 75 * drawingPov + 600
                            : 75 * drawingPov - 600 > 0
                                ? 75 * drawingPov - 1800
                                : 75 * drawingPov - 600
                            }px`;
                }
            });
            naver.maps.Event.addListener(mgPanoRef.current, "pano_status", async (status: "OK" | "ERROR") => {
                setIsLoading(true);
                if (status === "OK") {
                    setPanoStatus(true);
                    const changePosition = mgPanoRef.current?.getPosition();
                    getCurrentAddress({
                        lat: changePosition.y,
                        lng: changePosition.x,
                    });
                    mgPanoMapRef.current?.panTo(changePosition);
                    roadMarkerInPanoView.current?.setPosition(changePosition);
                    setIsLoading(false)
                } else if (status === "ERROR") {
                    setPanoStatus(false);
                    setIsLoading(false)
                }
            });
            naver.maps.Event.addListener(mgPanoRef.current, "init", () => {
                mgPanoRef.current.setVisible(true);
            });

            naver.maps.Event.addListener(mgPanoRef.current, "pov_changed", () => {
                const newPov = mgPanoRef.current.getPov().pan % 360;
                const drawingPov = Math.round(newPov / 22.5);
                const roadImg: any = document.querySelector("div[title='useRoadMarker'] > img");
                if (roadImg)
                    roadImg.style.top = `${-600 + 75 * drawingPov <= -1200
                        ? 75 * drawingPov + 600
                        : 75 * drawingPov - 600 > 0
                            ? 75 * drawingPov - 1800
                            : 75 * drawingPov - 600
                        }px`;
            });
        } else {
            if (isShowPanoView) {
                mgPanoRef.current?.setSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
                mgPanoRef.current.setOptions({
                    minScale: 7,
                    maxScale: 7,
                });
            } else {
                mgPanoRef.current?.setSize({
                    width: window?.innerWidth,
                    height: window?.innerHeight - 400,
                });
                mgPanoRef.current.setOptions({
                    minScale: 0,
                    maxScale: 10,
                });
            }
            mgPanoRef.current.setPov({
                pan: -135,
                tilt: -20,
                fov: 100,
            });
            mgPanoRef.current.setZoom(0);
            mgPanoRef.current.setPosition(center);
            mgPanoRef.current.setVisible(true);
            const newPov = mgPanoRef.current.getPov().pan % 360;
            const drawingPov = Math.round(newPov / 22.5);
            const roadImg: any = document.querySelector("div[title='useRoadMarker'] > img");
            if (roadImg)
                roadImg.style.top = `${-600 + 75 * drawingPov <= -1200
                    ? 75 * drawingPov + 600
                    : 75 * drawingPov - 600 > 0
                        ? 75 * drawingPov - 1800
                        : 75 * drawingPov - 600
                    }px`;
        }
        naver.maps.Event.addListener(mgPanoMapRef.current, "click", (evt) => {
            const newPosition = evt.coord;
            roadMarkerInPanoView.current?.setPosition(newPosition);
            mgPanoRef.current?.setPosition(newPosition);
        });
    };

    console.log(isLoading)

    useEffect(() => {
        if (mgPanoMapRef.current && mgPanoRef.current) {
            mgPanoMapRef.current?.destroy();
            mgPanoRef.current?.setVisible(false);
        }
    }, []);

    useEffect(() => {
        showPanoView(initLatLng);
    }, [searchParams.get("lat")]);

    useEffect(() => {
        if (searchParams.get("main")) {
            setIsShowPanoView(true);
        } else {
            setIsShowPanoView(false);
        }
    }, [searchParams.get("main")]);

    useEffect(() => {
        if (isShowPanoView) {
            mgPanoRef.current?.setSize({
                width: window.innerWidth,
                height: window.innerHeight - window.innerHeight / 3
            });
            mgPanoRef.current.setOptions({
                minScale: 7,
                maxScale: 7,
            });
        } else {
            mgPanoRef.current?.setSize({
                width: window?.innerWidth,
                height: window.innerHeight
            });
            mgPanoRef.current.setOptions({
                minScale: 0,
                maxScale: 10,
            });
        }
    }, [isShowPanoView]);


    return (
        <>
            {
                isLoading &&
                <div css={loading}>
                    <Lottie animationData={lottie_loading} />
                </div>
            }
            <div css={titleBoxStyle(!!searchParams.get("main"))}>
                <div className="closeBtn" onClick={() => transferToNative("", "closeRoadView")}>
                    <ArrowBackIosIcon style={{ fontSize: "14px" }} />
                </div>
                <p>{panoAddress}</p>
            </div>
            <div id="panoview" css={panoViewStyle} ref={panoViewRef} style={{ minHeight: "100vh", minWidth: "100vw" }}>
                {!panoStatus && <img src={noImage} alt="" css={noImageStyle} />}
            </div>
            <div css={panoMapStyle(isShowPanoView)} id="panoviewMap" ref={mgPanoMapRef} />
            {searchParams.get("main") && (
                <button
                    css={showMapButton}
                    className={isShowPanoView ? "" : "inactive"}
                    onClick={() => {
                        setIsShowPanoView((pre) => !pre);
                    }}
                >
                    <img src={ClearIcon} alt="" />
                </button>
            )}
        </>
    );
};

export default PanoramaView;

const loading = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100dvh;
    position: absolute;
    z-index: 10000;
    background-color: white;
    & > div {
        width: 78px;
    }
`

const titleBoxStyle = (val: boolean) => css`
    .closeBtn {
        height: 20px;
        width: 20px;
        background: url(${backIcon}) no-repeat;
        background-size: 10px 17px;
        background-position: center;
        font-size: 0;
        text-indent: -9999px;
        outline: none;
        padding-right: 10px;
        margin-right: 10px;
    }
    background: #ffffff;
    padding: 14px 10px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    align-items: center;
    justify-content: flex-start;
    display: ${val ? "flex" : "none"};
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
`;

const panoViewStyle = css`
    width: 100vw;
    height: calc(var(--vh, 1vh) * 70);
    height: 70dvh;
    height: 70vh;
    position: relative;
    transition: all 0.2s;
`;

const panoMapStyle = (isShowPanoView: boolean) => css`
    position: fixed !important;
    bottom: 0;
    z-index: 91;
    border: 4px solid #ffffff;
    border-radius: 8px;
    width: 100vw;
    height: 30%;
    /* height: 30dvh; */
    /* height: 30vh; */
    opacity: ${isShowPanoView ? 1 : 0};
    visibility: ${isShowPanoView ? "visible" : "hidden"};
    div[title="useRoadMarker"] {
        width: 75px !important;
        height: 75px !important;
        img {
            width: 75px !important;
            height: 1200px !important;
        }
    }
    transition: all 0.2s;
`;

const showMapButton = css`
    width: 44px;
    height: 44px;
    background: #fff no-repeat;
    border-radius: 8px;
    outline: none;
    background-image: url(${ClearIcon});
    background-size: 14px 14px;
    background-position: center;
    box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.24);
    position: absolute;
    bottom: calc(var(--vh, 1vh) * 24);
    bottom: calc(24dvh);
    bottom: 21vh;
    right: 10px;
    z-index: 91;
    font-size: 0;
    text-indent: -9999px;
    &.inactive {
        top: unset;
        bottom: 14px;
        background-image: url(${mapIcon});
        background-size: 17px 17px;
    }
`;

const noImageStyle = css`
    width: 100vw;
    height: 100vh;
`;
