import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import React from "react";
import EmptyResult from "./EmptyResult";

type SearchItemAddressPropsType = {
    onClickRow: (address: { lng: string; lat: string }, place_name: string) => void;
    data: any[];
};

const SearchItemAddress = ({ onClickRow, data }: SearchItemAddressPropsType) => {
    return (
        <>
            {data.length ? (
                <ul css={ListStyleWrapperStyle}>
                    {data?.reverse().map((v: any, index: number) => {
                        const { x, y, address_name, place_name, address_type } = v;
                        const address = { lng: x, lat: y };

                        return (
                            <li key={index} onClick={() => onClickRow(address, address_type)}>
                                <span>{place_name || address_name}</span>
                                <span className="sub-address">{address_name}</span>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <EmptyResult>
                    <p>
                        <b>일치하는 지역이 없습니다.</b>
                    </p>
                    <span>정확한 지역명을 입력해 주세요</span>
                </EmptyResult>
            )}
        </>
    );
};

export default SearchItemAddress;

const ListStyleWrapperStyle = css`
    max-height: calc(100vh - 138px);
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding: 0px 14px; */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 82px;
        width: 100%;
        padding: 0px 14px;

        span {
            display: flex;
            align-items: center;
            height: 26px;
        }

        .sub-address {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #a3a3a3;
        }
    }

    li:hover {
        background: ${Common.colors.bgBlue};
        width: 100vw;
    }
`;

const EmptyStyleWrapper = css`
    text-align: center;
    margin-top: 160px;
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #9d9fa5;
        margin-bottom: 8px;
    }

    span {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #9d9fa5;
    }
`;
