import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { SearchInput } from "@src/components";
import axios from "axios";
import { Common } from "@src/styles/Common";
import { useNavigate } from "react-router-dom";
import SearchItemAddress from "@src/components/SearchItem/SearchItemAddress";
import SearchHistory from "@src/components/SearchItem/SearchHistoryList";
import { GetCallSearchedList } from "@src/hooks/search/useCallSearchedList";
import useCallToken from "@src/hooks/useCallToken";
import { GetPostSearchedList } from "@src/hooks/search/usePostSearchedList";
import { getCaseNumberSearchedList, useGetSearchItemCaseNumber } from "@src/hooks/search/useSearchItemCaseNumber";
import SearchCaseNumberList from "@src/components/SearchItem/SearchCaseNumberList";
import { useGetClickSearchItemCaseNumber } from "@src/hooks/search/useClickSearchItemCaseNumber";
import useIsLive from "@src/hooks/useIsLive";

declare global {
    interface Window {
        kakao: any;
    }
}
const isLive = useIsLive();
const env = process.env;
const initialParams = {
    name: "locationHistoryKeyword",
};

const getQueryString = (options: any) => {
    return Object.entries(options)
        .map((v) => v.join("="))
        .join("&");
};

interface MobileSearchProps {
    keyword?: string;
}
const MobileSearch = ({ keyword }: MobileSearchProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchFlag, setSearchFlag] = useState(false);
    const [selectedTab, setSelectedTab] = useState("location");
    const [caseNumberHistoryList, setCaseNumberHistoryList] = useState<string[]>([]);
    const [locationHistoryList, setLocationHistoryList] = useState<string[]>([]);
    const [caseNumberSearchList, setCaseNumberSearchList] = useState<getCaseNumberSearchedList[]>([]);
    const [mapData, setMapData] = useState<any>();

    const V_WORLD_DEFAULT = {
        key: env.REACT_APP_V_WORLD_KEY,
        domain: env.REACT_APP_V_WORLD_DOMAIN,
        request: "GetFeature",
        typename: "lt_c_adsigg_info",
        bbox: "127.055381,37.490858,127.055381,37.490858",
        srsname: "EPSG:4326",
        output: "application/json",
    };
    const [appTestKeyword, setAppTestKeyword] = useState("");

    useEffect(() => {
        // MobileTest에서 넘어온 값 확인
        setAppTestKeyword(localStorage.getItem("keyword") ?? "");
    }, []);

    useEffect(() => {
        return () => {
            // 3. unmounted 이벤트 호출
            window.missgoauction && window.missgoauction.finish();
        };
    }, []);

    const isLocationTab = () => {
        return selectedTab === "location";
    };

    const onClickRow = (address: any, address_type?: any) => {
        const url = process.env.REACT_APP_V_WORLD_API_URL;
        const query = {
            bbox: `${address.lng},${address.lat},${address.lng},${address.lat}`,
            typename: getTypeVWorld(address_type),
        };

        const queryString = getQueryString({ ...V_WORLD_DEFAULT, ...query });
        const moveToDataMapWithoutPolygon = () => {
            //vworld 호출 실패했을 경우, 좌표계 없이 지도 이동 => 폴리건 생성 불가
            const mapData = {
                geometryCoordinates: [],
                center: { lat: address.lat, lng: address.lng },
                searchKeyword: searchKeyword,
            };
            setMapData(mapData);
            navigate("/map?search=0", { state: mapData, replace: true });
        };
        // 지도 이동
        axios
            .get(`${url}?${queryString}`)
            .then((value) => {
                if (value.data.features.length) {
                    const geometryCoordinates = value.data.features[0].geometry.coordinates[0][0];

                    const mapData = {
                        geometryCoordinates,
                        center: { lat: address.lat, lng: address.lng },
                        searchKeyword: searchKeyword,
                    };
                    setMapData(mapData);
                    navigate("/map?search=0", { state: mapData });
                }
            })
            .catch((e) => {
                console.log(e);
                moveToDataMapWithoutPolygon();
            });
    };
    const onClickCaseRow = async (data: any) => {
        // let id = data.bid_type === 1 ? data.sub_id : data.item_id;
        const response = await useGetClickSearchItemCaseNumber(data.item_id);
        const mapData = {
            geometryCoordinates: [],
            center: { lat: response.latd, lng: response.lntd },
            searchKeyword: "",
            item: response,
        };
        navigate("/map?search=0", { state: mapData, replace: true });
    };

    const getTypeVWorld = (address_type: string) => {
        let typename = "lp_pa_cbnd_bubun";

        if (address_type === "REGION") {
            typename = "lt_c_adsido_info";
        }

        return typename;
    };

    /////// 카카오 로직

    const makeResultString = (historyList: string[], input?: string) => {
        let result = "";

        const set = new Set([input, ...historyList]);
        const removeDuplicateHistoryList = [...(set as any)] as string[];

        removeDuplicateHistoryList.forEach((item) => {
            result = `${item};${result}`;
        });

        return result;
    };

    const handleClickSearch = async (input: string, type: "소재지" | "사건번호") => {
        input.length && setSearchKeyword(input);
        // setSearchKeyword(input);
        const postLocationHistoryKeyword = {
            name: "locationHistoryKeyword",
            value: makeResultString(locationHistoryList, input),
        };

        if (isLocationTab()) {
            await GetPostSearchedList(postLocationHistoryKeyword);
            await getSearchList("locationHistoryKeyword");
        }

        if (input.length) {
            setSearchFlag(true);
        } else {
            setSearchFlag(false);
        }
        const ps = new window.kakao.maps.services.Places();
        const geocoder = new window.kakao.maps.services.Geocoder();

        if (type === "소재지") {
            geocoder.addressSearch(input, searchAddress);
            ps.keywordSearch(input, placesSearchCB);
        }
    };

    const handleClickCaseNumberSearch = async (input: string) => {
        const postCaseNumberHistoryKeyword = {
            name: "caseNumberHistoryKeyword",
            value: makeResultString(caseNumberHistoryList, input),
        };

        input.length && setSearchKeyword(input);
        // setSearchKeyword(input);

        if (input.length) {
            const response = await useGetSearchItemCaseNumber(input);
            await GetPostSearchedList(postCaseNumberHistoryKeyword);
            await getSearchList("caseNumberHistoryKeyword");
            setSearchFlag(true);
            setCaseNumberSearchList(response);
        } else {
            setSearchFlag(false);
        }
    };

    // 주소 검색
    const searchAddress = (result: any, status: any) => {
        // 정상적으로 검색이 완료됐으면
        setData([]);

        if (status === window.kakao.maps.services.Status.OK) {
            setData(result);
        }

        return result;
    };
    // 키워드검색
    const placesSearchCB = (data: any, status: any, pagination: any) => {
        const reverseData = data.reverse();
        if (status === window.kakao.maps.services.Status.OK) {
            setData((prev: any) => [...prev, ...reverseData]);
        }
        return data;
    };

    const getSearchList = async (name: string) => {
        const response = await GetCallSearchedList({ name });
        const removeEmptyString = response.filter((item) => item !== "");

        isLocationTab() && setLocationHistoryList(removeEmptyString.reverse());
        !isLocationTab() && setCaseNumberHistoryList(removeEmptyString.reverse());
    };

    const onRemoveHistory = async (event: any, keyword: string) => {
        event.stopPropagation();
        let filterHistory;
        if (isLocationTab()) {
            filterHistory = locationHistoryList.filter((string) => {
                return string !== keyword;
            });
        } else {
            filterHistory = caseNumberHistoryList.filter((string) => {
                return string !== keyword;
            });
        }

        const postLocationHistoryKeyword = {
            name: "locationHistoryKeyword",
            value: makeResultString(filterHistory),
        };

        const postCaseNumberHistoryKeyword = {
            name: "caseNumberHistoryKeyword",
            value: makeResultString(filterHistory),
        };

        if (isLocationTab()) {
            setLocationHistoryList(filterHistory);
            await GetPostSearchedList(postLocationHistoryKeyword);
        } else {
            setCaseNumberHistoryList(filterHistory);
            await GetPostSearchedList(postCaseNumberHistoryKeyword);
        }
        await GetCallSearchedList(initialParams);
    };

    const { token } = useCallToken();

    useEffect(() => {
        if (token.length) {
            isLocationTab() ? getSearchList("locationHistoryKeyword") : getSearchList("caseNumberHistoryKeyword");
        }
    }, [token, selectedTab]);

    const onClickHistory = (item: string) => {
        if (isLocationTab()) {
            handleClickSearch(item, "소재지");
        } else {
            handleClickCaseNumberSearch(item);
        }
    };

    const historyArea = useMemo(
        () =>
            isLocationTab() ? (
                <SearchHistory
                    historyList={locationHistoryList}
                    onRemove={onRemoveHistory}
                    onClickHistory={onClickHistory}
                    type="location"
                />
            ) : (
                <SearchHistory
                    historyList={caseNumberHistoryList}
                    onRemove={onRemoveHistory}
                    onClickHistory={onClickHistory}
                    type="caseNumber"
                />
            ),
        [locationHistoryList, selectedTab, caseNumberHistoryList],
    );

    const searchArea = useMemo(
        () =>
            isLocationTab() ? (
                <SearchItemAddress onClickRow={onClickRow} data={data} />
            ) : (
                <SearchCaseNumberList onClickRow={onClickCaseRow} data={caseNumberSearchList} />
            ),
        [data, selectedTab, caseNumberSearchList],
    );

    const historyPathRoute = () => {
        // 웹뷰 종료
        // if (historyPath === "home") return transferToNative("done", "closeWebView");

        // 웹뷰 종료
        // if (historyPath === "items") return transferToNative("done", "closeWebView");

        // 검색 (키워드 넘김)-> 지도 (키워드 넘김) -> 검색 (키워드 넘김)
        // if (historyPath === "map") {
        // const mapData = getLocalStorageData("mapData");
        // setSearchKeyword(mapData.searchKeyword);
        // return transferToNative("done", "closeWebView");
        // navigate("/map?search=0", { state: mapData });
        navigate(-1);
        // }
    };

    const onClickBack = () => {
        historyPathRoute();
    };
    useEffect(() => {
        keyword && setSearchKeyword(keyword);
    }, [keyword]);
    return (
        <div css={Wrapper}>
            <div css={SearchBox}>
                <SearchInput
                    value={searchKeyword || ""}
                    handleClickBack={onClickBack}
                    placeholder={isLocationTab() ? "건물명, 도로명, 지번검색" : "사건번호, 물건관리번호 검색"}
                    handleClick={(query) => {
                        query.length && isLocationTab() ? handleClickSearch(query, "소재지") : handleClickCaseNumberSearch(query);
                    }}
                />
                <div css={ContentContainer}>
                    <div className="btn-box">
                        <button className={isLocationTab() ? "active" : ""} onClick={() => setSelectedTab("location")}>
                            <span>소재지</span>
                        </button>
                        <button className={isLocationTab() ? "" : "active"} onClick={() => setSelectedTab("caseNumber")}>
                            <span>사건번호</span>
                        </button>
                    </div>

                    {searchFlag ? searchArea : historyArea}
                </div>
            </div>
        </div>
    );
};

const ContentContainer = css`
    /* padding: 0px 14px; */
    background-color: #fff;
    height: 100vh;
    z-index: 2;
    & > .btn-box {
        padding: 0px 14px;
    }
`;

const Wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: #fff;

    ul:hover {
        cursor: pointer;
    }

    .btn-box {
        width: 100%;
        height: 74px;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            height: 46px;
            border-radius: 8px;
            background-color: #fdfafa;

            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }

        button + button {
            margin-left: 8px;
        }
        button.active {
            background: ${Common.colors.purple800};
            color: ${Common.colors.white};
        }
    }
`;

const SearchBox = css`
    width: 100%;
    position: absolute;
    z-index: 1;
    & > div {
        /* padding: 2px; */
    }
`;

export default MobileSearch;
