import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import BottomSheetHeader from "@src/components/bottomSheet/BottomSheetHeader";
import HandlebarIcon from "@src/assets/ico_handle_bar.svg";
import AiSortTypeContent from "./AiSortTypeContent";

const sortTypeContents = [
    { name: "가까운순", value: 5 },
    { name: "최신순", value: 0 },
    { name: "높은순", value: 1 },
    { name: "낮은순", value: 2 },
    { name: "높은순", value: 3 },
    { name: "낮은순", value: 4 },
    { name: "많은순", value: 7 },
    { name: "적은순", value: 8 },
];

interface Props {
    showSortBox: boolean;
    sortType: number;
    onChangeSortType: (val: number) => void;
    onCloseSortBox: () => void;
}

const AiSortBox = ({ showSortBox, sortType, onChangeSortType, onCloseSortBox }: Props) => {
    const rootBoxRef = useRef<HTMLDivElement | null>(null);
    const [touchStart, setTouchStart] = useState<boolean>(false);
    return (
        <>
            <div>
                <div css={rootStyle(showSortBox)}>
                    <div className={showSortBox ? "dim active" : "dim"} aria-hidden onClick={onCloseSortBox} />
                    <div
                        css={buttonBoxStyle(showSortBox)}
                        onTouchMove={(evt) => {
                            setTouchStart(true);
                            230 - (window.innerHeight - evt.touches[0].clientY) > 0 &&
                                rootBoxRef.current?.style.setProperty(
                                    "transform",
                                    `translateY(${230 - (window.innerHeight - evt.touches[0].clientY)}px)`,
                                );
                            rootBoxRef.current?.style.setProperty("transition", "all 0.1s");
                        }}
                        onTouchEnd={(evt) => {
                            if (touchStart) {
                                setTouchStart(false);
                                rootBoxRef.current?.style.removeProperty("transform");
                                rootBoxRef.current?.style.removeProperty("transition");
                                210 - (window.innerHeight - evt.changedTouches[0].clientY) > 0 && onCloseSortBox();
                            }
                        }}
                    >
                        {/* <BottomSheetHeader /> */}
                        <div css={contentDiv}>
                            <AiSortTypeContent
                                title="매각기일"
                                button={sortTypeContents[0]}
                                selectedType={sortType}
                                onChangeSortType={onChangeSortType}
                            />
                            <AiSortTypeContent
                                title="업데이트"
                                button={sortTypeContents[1]}
                                selectedType={sortType}
                                onChangeSortType={onChangeSortType}
                            />
                            <AiSortTypeContent
                                title="감정가"
                                button={sortTypeContents[2]}
                                secondButton={sortTypeContents[3]}
                                selectedType={sortType}
                                onChangeSortType={onChangeSortType}
                            />
                            <AiSortTypeContent
                                title="최저가"
                                button={sortTypeContents[4]}
                                secondButton={sortTypeContents[5]}
                                selectedType={sortType}
                                onChangeSortType={onChangeSortType}
                            />
                            <AiSortTypeContent
                                title="유찰횟수"
                                button={sortTypeContents[6]}
                                secondButton={sortTypeContents[7]}
                                selectedType={sortType}
                                onChangeSortType={onChangeSortType}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AiSortBox;

const rootStyle = (isShow: boolean) => css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    opacity: 0;
    visibility: hidden;
    * {
        transition: all 0.3s;
    }
    ${isShow && {
        zIndex: 996,
        opacity: 1,
        visibility: "visible",
    }}
    .dim {
        height: 100vh;
    }
    .dim.active {
        background-color: rgba(40, 40, 40, 0.7);
        height: 100vh;
        width: 100vw;
    }
`;

const buttonBoxStyle = (isShow: boolean) => css`
    position: fixed;
    bottom: 0;
    height: 406px;
    width: 100vw;
    transform: ${!isShow ? "translateY(406px)" : "translateY(0)"};
    background: #ffffff url(${HandlebarIcon}) no-repeat 50% 14px;
    border-radius: 10px 10px 0 0;
    padding: 14px 14px 0px 14px;
`;

const contentDiv = css`
    margin-top: 10px;
`;
