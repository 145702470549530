import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

export const useGetClassList = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.campusClass}es`, { params });
    return response;
};

export const useGetMyClassList = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.campusUser}/classes`, { params });
    return response;
};
