import { css } from "@emotion/react";

import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import registDragEvent from "@src/util/registDragEvent";
import SaleCard from "../pages/map/SaleCard";
import leftIcon from "@src/assets/ico_left_arrow_black_svg.svg";
import rightIcon from "@src/assets/ico_right_arrow_black.svg";
import { Common } from "@src/styles/Common";
import { useMapBottomSheetStore } from "@src/store/MapBottomSheetStore";
import styled from "@emotion/styled";
import AD_IMAGE from "@src/assets/image_ad_map.png";
import { transferToNative } from "@src/lib/WebAppBridge";

interface TestSalePageProps {
    items: any[];
    hideLandCard: () => void;
    setIsSaleCardShow: Dispatch<SetStateAction<boolean>>;
    hidePolyline: () => void;
    onChangeTextInput: (val: string) => void;
}

const SalePage = ({ items, hideLandCard, setIsSaleCardShow, hidePolyline, onChangeTextInput }: TestSalePageProps) => {
    const sliderRef = useRef() as any;
    const currentIndex = useMapBottomSheetStore((state) => state.currentIndex);
    const setCurrentIndex = useMapBottomSheetStore((state) => state.setCurrentIndex);
    const setAnimate = useMapBottomSheetStore((state) => state.setAnimate);
    const animate = useMapBottomSheetStore((state) => state.animate);

    const [transX, setTransX] = useState(0);
    const [transY, setTransY] = useState(0);

    const SLIDER_WIDTH = screen.width;

    const inrange = (v: number, min: number, max: number) => {
        if (v < min) return min;
        if (v > max) return max;
        return v;
    };

    useEffect(() => {
        setTransX(0);
        // onChangeTextInput(items?.[currentIndex]?.address?.split[","]?.[0]);
        // onChangeTextInput(items?.[currentIndex]?.case_number);
    }, [currentIndex]);

    return (
        <>
            <div
                ref={sliderRef}
                className="overflow-hidden"
                style={{
                    width: SLIDER_WIDTH,
                    overflow: "hidden",
                }}
            >
                <div
                    className="flex"
                    style={{
                        // transform: `translateX(${-currentIndex * SLIDER_WIDTH + transX}px) translateY(${transY}px)`,
                        // transition: `transform ${animate ? 300 : 0}ms ease-in-out 0s`,
                        // display: "flex",
                        // position: "fixed",
                        // left: 0,
                        // bottom: 0,
                        // zIndex: 1400,
                        transform: `translateX(${-currentIndex * SLIDER_WIDTH + transX}px) translateY(${transY}px)`,
                        transition: `transform ${animate ? 300 : 0}ms ease-in-out 0s`,
                        display: "flex",
                        position: "fixed",
                        left: 0,
                        bottom: 0,
                        zIndex: 1400,
                    }}
                    {...registDragEvent({
                        onDragChange: (deltaX, deltaY, startPoint) => {
                            setAnimate(false);
                            setTransY(inrange(-553 + deltaY, -window.innerHeight, 0));
                            if (window.innerHeight - 248 < deltaY) {
                                if (!((currentIndex === 0 && deltaX > 0) || (currentIndex === items.length - 1 && deltaX < 0))) {
                                    setTransX(inrange(deltaX, -SLIDER_WIDTH + 10, SLIDER_WIDTH - 10));
                                }
                            }
                        },
                        onDragEnd: (deltaX, deltaY, startPoint) => {
                            const maxIndex = items.length - 1;

                            if (window.innerHeight - 248 > deltaY) {
                                if (deltaY < startPoint) {
                                    setTransY(-window.innerHeight);

                                    setTimeout(() => {
                                        // transferToNative(JSON.stringify([items[currentIndex]]), "showItemCard");
                                        // const isSubscription = localStorage.getItem("isSubscription");
                                        // isSubscription === "true"
                                        //     ? transferToNative(JSON.stringify(items[currentIndex]), "showItemDetail")
                                        //     : transferToNative("", "showSubscriptionPopup");
                                        transferToNative(JSON.stringify(items[currentIndex]), "showItemDetail");
                                    }, 500);
                                    setTimeout(() => {
                                        // setIsSaleCardShow(false);
                                        setTransY(0);
                                    }, 1000);
                                }
                                if (deltaY > startPoint) setTransY(0);
                            } else {
                                if (deltaX < -100) setCurrentIndex(inrange(currentIndex + 1, 0, maxIndex));
                                if (deltaX > 100) setCurrentIndex(inrange(currentIndex - 1, 0, maxIndex));
                            }

                            setAnimate(true);
                            setTransX(0);
                        },
                    })}
                    onTransitionEnd={() => {
                        setAnimate(false);
                    }}
                >
                    {items.map((item, i) => (
                        <div key={i} className="flex-shrink-0" css={itemBox}>
                            <SaleCard
                                item={item}
                                hideLandCard={hideLandCard}
                                setIsSaleCardShow={setIsSaleCardShow}
                                hidePolyline={hidePolyline}
                            />
                            <div>
                                <LoadingWrapper>
                                    <img src={AD_IMAGE} />
                                </LoadingWrapper>
                            </div>
                        </div>
                    ))}
                </div>
                <PageNation
                    style={{
                        transform: `translateY(${transY}px)`,

                        transition: `transform ${animate ? 300 : 0}ms ease-in-out 0s`,
                    }}
                >
                    <div
                        className="iconDiv"
                        onClick={() => {
                            setAnimate(true);
                            setCurrentIndex(inrange(currentIndex - 1, 0, items.length - 1));
                        }}
                    >
                        <img src={leftIcon} alt="" />{" "}
                    </div>
                    <div>
                        {currentIndex + 1} / {items.length}
                    </div>
                    <div
                        className="iconDiv"
                        onClick={() => {
                            setAnimate(true);
                            setCurrentIndex(inrange(currentIndex + 1, 0, items.length - 1));
                        }}
                    >
                        <img src={rightIcon} alt="" />
                    </div>
                </PageNation>
            </div>
        </>
    );
};

const itemBox = css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const LoadingWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    z-index: 999;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
const PageNation = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70px;
    z-index: 1500;
    /* ${Common.textStyle.text1_R13} */
    & > div {
        font-size: 13px;
        font-weight: 400;
        color: ${Common.colors.normalTextColor};
        text-align: center;
    }

    & > .iconDiv {
        display: flex;
        align-items: center;
        padding: 5px;
        & > img {
            width: 7px;
            height: 11px;
        }
    }
    position: fixed;
    bottom: 14px;
    left: ${(window.innerWidth - 70) / 2}px;
`;

export default SalePage;
