import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import star from "../../../assets/scoreStar.png";
import activeStar from "../../../assets/activeScoreStar.png";
import { Common } from "@src/styles/Common";
import { CampusDetailReview } from "@src/hooks/campus/dto/CampusDetail.dto";

interface GradeItemProps {
    review: CampusDetailReview;
}

const GradeItem = ({ review }: GradeItemProps) => {
    const [data, setData] = useState<any[]>([]);
    let i: any;
    useEffect(() => {
        const newData = [];
        for (i = 0; i < 5; i++) {
            newData.push(review.score > i);
        }
        setData(newData);
    }, []);

    return (
        <>
            <StyledGradeItem>
                <div className="name">
                    <span>{review.name.replace(/.$/, "*")}</span>님
                </div>
                <div className="star">
                    <div className="starImg">
                        {data.map((item: boolean, idx: number) => (
                            <img src={item ? activeStar : star} alt="" key={String(item) + idx} />
                        ))}
                    </div>
                    <span>{review.score}.0</span>
                </div>
                <div className="textBox">{review.contents}</div>
            </StyledGradeItem>
        </>
    );
};

export default GradeItem;

const StyledGradeItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px;
    height: 346px;
    margin-left: 14px;
    min-width: 248px;
    background-color: white;
    font-weight: 400;
    font-size: 13px;
    color: ${Common.colors.normalTextColor};
    border-radius: 14px;

    .name {
        font-size: 14px;
        margin-bottom: 8px;
        & > span {
            font-weight: 700;
            font-size: 16px;
        }
    }
    .star {
        margin-bottom: 15px;
        display: flex;
        gap: 8px;
        .starImg {
            display: flex;
            gap: 4px;
            & > img {
                width: 12px;
            }
        }
        & > span {
            font-weight: 400;
            font-size: 12px;
            color: ${Common.colors.normalTextColor};
        }
    }
    .textBox {
        line-height: 20px;
        height: 260px;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-line-clamp: 13;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }
`;
