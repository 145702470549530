import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ico_close from "@src/assets/ico_close.png";

type DeungiComboPropsType = {
    option: string;
    options: string[];
    toggle: boolean;
    setOption: (option: any) => void;
    setToggle: (flag: boolean) => void;
};

const DeungiCombo = ({ option, options, toggle, setOption, setToggle }: DeungiComboPropsType) => {
    const [pageName, setPageName] = useState("");
    const [searchParams] = useSearchParams();

    const onBackHandler = () => {
        setToggle(false);
    };

    const onClickHandler = (e: any, option: any) => {
        setToggle(false);
        setOption(option);

        e.stopPropagation();
    };

    useEffect(() => {
        const page = searchParams.get("type");
        setPageName(page || "search");
    }, []);

    if (!toggle) return <></>;

    return (
        <Container onClick={onBackHandler}>
            <ComboBox>
                {pageName === "list" ? (
                    <>
                        <h3>날짜 선택</h3>
                        <div className="exitBox" onClick={onBackHandler}>
                            <img src={ico_close} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {options.map((el) => {
                    return (
                        <div key={el} className={el === option ? "item active" : "item"} onClick={(e) => onClickHandler(e, el)}>
                            {el}
                        </div>
                    );
                })}
            </ComboBox>
        </Container>
    );
};

const ComboBox = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 14px;
    background: #fff;
    border-radius: 10px 10px 0 0;
    animation: up 0.3s;
    box-shadow: 0 0 10px #5e5e5e;

    h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .exitBox {
        position: absolute;
        top: 10px;
        right: 0;
        padding: 20px;

        & > img {
            width: 14px;
            height: 14px;
        }
    }
    .item {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-radius: 14px;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 20px;
        transition: all 0.3s ease;

        &:active,
        &.active {
            color: #fff;
            font-weight: 700;
            background: #0047ff;
        }
    }

    @keyframes up {
        from {
            bottom: -500px;
        }
        to {
            bottom: 0;
        }
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s;
    z-index: 100;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;
export default DeungiCombo;
