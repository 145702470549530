import React from "react";
import { css } from "@emotion/react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import nextArrow from "../../assets/ico_arrow_next_black.png";

interface Props {
    value: string;
    onDeleteValue: () => void;
}

const SelectedLocationValue: React.FC<Props> = ({ value, onDeleteValue }) => {
    const parts = value?.split(">");
    return (
        <div css={rootBoxStyle}>
            {parts.map((part, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <img src={nextArrow} alt="next" />}
                    {part}
                </React.Fragment>
            ))}
            {onDeleteValue && <CancelRoundedIcon onClick={onDeleteValue} sx={{ color: "#B1AEB8", marginTop: "-1px" }} />}
        </div>
    );
};

export default SelectedLocationValue;

const rootBoxStyle = css`
    border-radius: 24px;
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 20px;
    color: #0c002c;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    width: max-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 8px 14px;
    img {
        width: 16px;
        height: 16px;
        margin-bottom: 4px;
    }
`;
