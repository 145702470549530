export const itemForFilterAssetType = [
    {
        title: "전체",
        id: "0",
        isActive: true
    },
    {
        title: "압류재산",
        id: "1",
        isActive: false
    },
    {
        title: "국공유재산",
        id: "2",
        isActive: false
    },
    {
        title: "신탁재산",
        id: "3",
        isActive: false
    },
    {
        title: "기타재산",
        id: "4",
        isActive: false
    },
];
