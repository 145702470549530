const top = [
    {
        type: "top",
        name: "서울",
        code: 1,
    },
    {
        type: "top",
        name: "세종",
        code: 1,
    },
];

const mid: { [key: string]: any } = {
    서울: [
        {
            type: "mid",
            name: "강남구",
            code: 11,
        },
    ],
    세종: [
        {
            type: "mid",
            name: "충청구",
            code: 11,
        },
    ],
};
const bot: { [key: string]: any } = {
    강남구: [
        {
            type: "bot",
            name: "개포동",
            code: 111,
        },
        {
            type: "bot",
            name: "논현동",
            code: 112,
        },
        {
            type: "bot",
            name: "대치동",
            code: 113,
        },
        {
            type: "bot",
            name: "도곡동",
            code: 114,
        },
        {
            type: "bot",
            name: "삼성동",
            code: 115,
        },
        {
            type: "bot",
            name: "세곡동",
            code: 116,
        },
        {
            type: "bot",
            name: "수서동",
            code: 117,
        },
        {
            type: "bot",
            name: "신사동",
            code: 118,
        },
        {
            type: "bot",
            name: "압구정동",
            code: 119,
        },
        {
            type: "bot",
            name: "역삼동",
            code: 120,
        },
        {
            type: "bot",
            name: "율현동",
            code: 121,
        },
        {
            type: "bot",
            name: "일원동",
            code: 122,
        },
        {
            type: "bot",
            name: "자곡동",
            code: 123,
        },
        {
            type: "bot",
            name: "청담동",
            code: 124,
        },
    ],
};
export { top, mid, bot };
