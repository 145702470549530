import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery } from "react-query";

const getRegionAdsPaymentItems = async (params: any) => {
    const reponse = await axiosInstance.get(`${axiosPath.roadPlanPaymentPath}`, {
        params,
    });
    return reponse.data;
};

export const useFindAllRegionAdsPayments = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["regionAdsPaymentItems"],
        queryFn: ({ pageParam }) => getRegionAdsPaymentItems({ ...params, page: pageParam }),
        getNextPageParam: (_lastPage: any, pages: any) => {
            return pages[0]?.totalCnt > pages?.length * 20 ? pages.length + 1 : null;
        },
        enabled,
    });
};
