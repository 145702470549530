import styled from "@emotion/styled";
import React from "react";
import title from "../../../assets/guideTitle.png";
import GuideItem from "./GuideItem";

const Guide = () => {
    return (
        <>
            <StyledGuide>
                <div className="GuideTitle">
                    <img src={title} alt="" />
                </div>
                <div className="guideContents">
                    <GuideItem
                        text={"부동산 경매 캠퍼스 강의 이용안내"}
                        contents={
                            <div className="textBox">
                                - 부동산 경매 캠퍼스 강의 이용에 대한 사항은 회사가 별도로 정한 [미스고 유료서비스 이용약관]에
                                따릅니다.
                                <br />
                                - 미스고 부동산 캠퍼스 강의를 결제하신 경우 ‘내 강의실’에서 해당 강의를 수강기간 동안 수강하실 수
                                있습니다.
                                <br />
                                - 서비스 점검으로 인해 일시적으로 강의 수강이 제한될 수 있습니다.
                                <br />
                                - 강의 결제 후 강의를 수강하거나 강의 자료를 1개 이상 다운로드한 경우 환불받을 수 없습니다. <br />
                                - 또한, 구독 쿠폰 등 강의 구매 혜택이 지급된 경우 지급된 혜택 사용 시 강의를 환불받을 수 없습니다.
                                <br />
                                - 부정한 방법으로 환불받는 경우 환불 금액을 회원에게 통지할 수 있으며 회사는 사전통지 없이 회원을
                                제재할 수 있습니다.
                                <br />
                                -쿠폰 및 무료로 제공된 강의는 취소 및 환불 대상이 아닙니다.
                                <br />
                                -수강기간은 일시 정지하실 수 없으며, 구매하신 수강권(수강 기간)을 이용 중 변경하는 것은
                                불가능합니다.
                                <br />
                                -비방성, 악의적 수강평은 게시판 관리자 판단 및 재량에 따라 삭제 처리 될 수 있습니다.
                                <br />
                            </div>
                        }
                    />
                    <GuideItem
                        text={"부동산 경매 캠퍼스 강의 환불/취소 안내"}
                        contents={
                            <div className="textBox">
                                - 결제한 강의는 수강하지 않고 강의 자료를 다운로드하지 않은 경우에 한 해, 구매 후 7일 이내에
                                자주묻는질문에서 직접 구매 취소할 수 있으며 강의 영상 일부를 수강하거나 자료 다운로드 시
                                부분환불은 불가합니다. <br />- 강의를 구성하는 영상의 일부 재생 시 수강한 것으로 간주합니다.{" "}
                                <br />- 구매 후 7일 이내임에도 {`‘내정보>고객센터>자주묻는질문>강의 취소/환불하기’에 '취소 불가'`}{" "}
                                상태라면 다음 중 하나 이상의 경우에 해당 합니다.
                                <br />
                                1) 강의 결제 후 강의를 수강한 경우
                                <br />
                                2) 강의 자료를 1개 이상 다운로드한 경우
                                <br />
                                3) 구독 쿠폰 등 강의 구매 혜택으로 지급된 혜택을 사용한 경우
                                <br />
                                4) Google Play 결제를 통해 구매한 경우
                                <br />
                                5) iOS APP Store 결제를 통해 구매한 경우
                                <br /> - iOS APP Store 결제로 구매한 강의의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.
                                <br /> - Google Play 결제로 구매한 강의는 구매 후 48시간 이내에는 Google Play 고객센터를 통해,
                                48시간 이후에는 고객센터를 통해 구매 취소할 수 있습니다.
                                <br /> - 구매 취소나 환불 시에는 결제한 수단으로 환불됩니다. <br />- 구독 혜택, 쿠폰 등 무료로
                                지급된 강의는 환불 대상에서 제외됩니다.
                            </div>
                        }
                    />
                </div>
            </StyledGuide>
        </>
    );
};

export default Guide;

const StyledGuide = styled.div`
    margin-top: 34px;
    width: 100%;
    .GuideTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        & > img {
            width: 113px;
            height: 38px;
        }
    }
    .guideContents {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 18px;
        padding: 14px;
    }
`;
