import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useFilterStore } from "@src/store/filterStore";
import React from "react";
import { appearBottomSheetBackground } from "../bottomSheet/BottomSheet";

type DimPropsType = {
    visible: boolean;
    zIndex?: number;
};

const Dim = ({ visible, zIndex }: DimPropsType) => {
    const setDimVisible = useFilterStore((state) => state.setDimVisible);
    const setFilterVisible = useFilterStore((state) => state.setFilterVisible);
    const filterVisible = useFilterStore((state) => state.filterVisible);

    const onClick = () => {
        setDimVisible(filterVisible);
        setFilterVisible(false);
    };

    return <DimContainer visible={visible} zIndex={zIndex} onClick={onClick} />;
};

export default Dim;
export const DimContainer = styled.div<{ visible: boolean; zIndex?: number }>`
    position: fixed;
    display: none;
    ${({ visible, zIndex }) =>
        visible &&
        css`
            display: block;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: ${zIndex || 1};
            animation: ${appearBottomSheetBackground} 0.2s linear forwards;
        `};
`;
