import styled from "@emotion/styled";
import { Button } from "@src/components";
import { checkDevice } from "@src/components/deungi/common/utils";
import { transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction } from "react";

interface FloatingButtonProps {
    isCheck: boolean;
    setIsShowModal: Dispatch<SetStateAction<boolean>>;
    code: string;
    coupon: number;
}

const FloatingButton = ({ isCheck, setIsShowModal, code, coupon }: FloatingButtonProps) => {
    const deviceType = checkDevice();
    return (
        <>
            <StyledFloatingButton>
                <div className="text">강의 이용 및 환불/취소 안내를 확인 하였으며, 결제에 동의합니다.</div>
                <Button
                    color="newBlue"
                    handleClick={() => {
                        isCheck
                            ? setIsShowModal(true)
                            : transferToNative(code, deviceType === "android" ? "payForPG" : "payForInApp");
                    }}
                    label={coupon ? "수강하고 미스고 이용권 받기" : "바로 수강하기"}
                    size="medium"
                    deleteBtn={true}
                />
            </StyledFloatingButton>
        </>
    );
};

export default FloatingButton;

const StyledFloatingButton = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: absolute;
    bottom: 20px;
    z-index: 11;

    .text {
        height: 28px;
        width: 100%;
        background-color: ${Common.colors.grey02};
        font-weight: 400;
        font-size: 11px;
        color: ${Common.colors.normalTextColor};
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & > button {
        width: calc(100vw - 28px);
        height: 56px;
    }
`;
