import React from "react";

import { dialogTitleStyle, dialogContentStyle } from "./styles";

interface NoRefundsExpirationDateProps {
    title: string;
}

const NoRefundsExpirationDate = ({ title }: NoRefundsExpirationDateProps) => {
    return (
        <>
            <p css={dialogTitleStyle}>취소 불가</p>
            <p css={dialogContentStyle}>
                {title}을 사용하거나
                <br />
                구매한 지 8일이 지나 직접 환불이 불가한 결제내역입니다
                <br />
                <br />
                고객센터 1:1 문의하기로 문의주시기
                <br /> 바랍니다
            </p>
        </>
    );
};

export default NoRefundsExpirationDate;
