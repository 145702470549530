import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import React from "react";
import { useInfiniteQuery, useMutation } from "react-query";

const fetchCancelRefundItemList = async (params: any, ticketType: number) => {
    const response = await axiosInstance.get(`${axiosPath.ticket}/items/${ticketType}`, { params });
    return response.data;
};

export const getCancelRefundItemList = (params: any, ticketType: number, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["getCancelRefundItemList", params],
        queryFn: ({ pageParam }) => fetchCancelRefundItemList({ ...params, page: pageParam }, ticketType),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage = pages[0].totalCnt > pages.length * 20 ? pages.length + 1 : undefined;
            return newPage;
        },
    });
};

export const useRefundTicket = (onSuccess: (datum: any) => void = () => {}, onError: (err: AxiosError) => void = () => {}) => {
    const url = `${axiosPath.refundTicket}`;
    return useMutation(
        async (data: any) => {
            const body: any = {
                ticket_no: data,
            };
            try {
                const res = await axiosInstance.post(`${url}`, body);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
