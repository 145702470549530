import styled from "@emotion/styled";
import { CampusClassResponseDto } from "@src/hooks/campus/dto/CampusList.dto";
import { Common } from "@src/styles/Common";
import React from "react";
import { useNavigate } from "react-router-dom";
import thumbnail from "../../assets/thumbnail.png";

interface ClassProps {
    item: CampusClassResponseDto;
}

const Class = ({ item }: ClassProps) => {
    const navigate = useNavigate();

    return (
        <>
            <StyledClass
                onClick={() => {
                    // navigate(`/campus/detail/${item.no}?no=${item.no}&saleType=${item.productInfo.saleType}`, {
                    //     state: { no: item.no, saleType: item.productInfo.saleType },
                    // });
                    navigate(`/campus/detail/${item.no}`);
                }}
            >
                <div className="thumbnailDiv">
                    <img src={item.thumbnailUrl} alt="" />
                </div>
                <div className="textBox">
                    <div className="title">{item.title}</div>
                    <div className="sub">
                        <div className="saleStudents">
                            <div className="discount">{item.productInfo.priceDiscountDisplay1}</div>
                            <div className="students">
                                {item.showUserCnt ? `수강생 총 ${item.totalUserCnt && item.totalUserCnt.toLocaleString()}명` : ""}
                            </div>
                        </div>
                        <div className="priceDate">
                            <span>{item.productInfo.discountedPrice.toLocaleString()}원</span>
                            {item.productInfo.serveDuration}
                            {item.productInfo.serveDurationType === "day" && "일"}
                            {item.productInfo.serveDurationType === "month" && "개월"}
                            {item.productInfo.serveDurationType === "year" && "년"}
                        </div>
                    </div>
                </div>
            </StyledClass>
        </>
    );
};

export default Class;

const StyledClass = styled.div`
    padding: 14px 0;
    display: flex;
    gap: 8px;
    border-bottom: 1px solid ${Common.colors.grey02};
    .thumbnailDiv {
        width: 140px;
        height: 100px;
        border-radius: 8px;
        & > img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }
    .textBox {
        width: calc(100% - 140px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > .title {
            ${Common.textStyle.body2_R16};
            color: ${Common.colors.normalTextColor};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        & > .sub {
            display: flex;
            flex-direction: column;
            gap: 6px;
            & > .saleStudents {
                display: flex;
                justify-content: space-between;
                & > .discount {
                    color: ${Common.colors.notiColor};
                    font-size: 13px;
                    font-weight: 700;
                }
                & > .students {
                    color: ${Common.colors.darkGrey03};
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            & > .priceDate {
                & > span {
                    color: ${Common.colors.normalTextColor};
                    font-size: 14px;
                    font-weight: 700;
                    margin-right: 4px;
                }
                color: ${Common.colors.darkGrey03};
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
`;
