import { css } from "@emotion/react";
import subscrbe from "@src/assets/subscription/subscribe.svg";
import subscrbeV2 from "@src/assets/subscription/subscribeV2.svg";
import stateLand from "@src/assets/subscription/stateLand.svg";
import stateLandV2 from "@src/assets/subscription/stateLandV2.svg";
import goStop from "@src/assets/subscription/goStop.svg";
import roadPlanV2 from "@src/assets/subscription/roadPlanV2.svg";
import roadPlan from "@src/assets/subscription/roadPlan.svg";
import storeGoStop from "@src/assets/subscription/storeGoStop.svg";
import storeGoStopV2 from "@src/assets/subscription/storeGoStopV2.svg";
import goStopV2 from "@src/assets/subscription/goStopV2.svg";

import React from "react";
import { useState } from "react";
import { getDeviceType } from "@src/lib/WebAppBridge";
import { useSearchParams } from "react-router-dom";

interface Props {
    stickyRef?: any;
    onScroll: (refId: string) => void;
    isFocus: string;
}

const SubscriptionSticky: React.FC<Props> = ({ stickyRef, onScroll, isFocus }) => {

    const [searchParams] = useSearchParams();
    const stickyList = [
        {
            keyId: "aosAuction",
            id: "topRef",
            title: "경공매\n구독",
            imgSrc: subscrbe,
            clickImg: subscrbeV2
        },
        {
            keyId: "aosStateLand",
            id: "landRef",
            title: "국유지\n검색 구독",
            imgSrc: stateLand,
            clickImg: stateLandV2,
        },
        {
            keyId: "aosRoadPlan",
            id: "roadRef",
            title: "확장예정\n도로\n데이터맵",
            imgSrc: roadPlan,
            clickImg: roadPlanV2,
        },
        {
            keyId: "aosPremiumFilter",
            id: "goStopRef",
            title: "주거/토지\nGo-Stop",
            imgSrc: goStop,
            clickImg: goStopV2,
        },
        {
            keyId: "aosStoreGoStop",
            id: "storeGoStopRef",
            title: "상가\nGo-Stop",
            imgSrc: storeGoStop,
            clickImg: storeGoStopV2,
        },
    ];

    const iosStickyList = [
        {
            keyId: "iosAuction",
            id: "topRef",
            title: "경공매\n구독",
            imgSrc: subscrbe,
            clickImg: subscrbeV2
        },
        {
            keyId: "iosStateLand",
            id: "landRef",
            title: "국유지\n검색 구독",
            imgSrc: stateLand,
            clickImg: stateLandV2,
        },
        {
            keyId: "iosPremiumFilter",
            id: "goStopRef",
            title: "주거/토지\nGo-Stop",
            imgSrc: goStop,
            clickImg: goStopV2,
        },
        {
            keyId: "iosStoreGoStop",
            id: "storeGoStopRef",
            title: "상가\nGo-Stop",
            imgSrc: storeGoStop,
            clickImg: storeGoStopV2,
        },
    ];

    return (
        <div ref={stickyRef} css={stickyBox}>
            {iosStickyList && stickyList &&
                searchParams.get("type") === "6" ? (stickyList.map((list) => {
                    return (
                        <div
                            key={list.keyId}
                            onClick={() => {
                                onScroll(list.id)
                            }}
                            style={{
                                color: isFocus === list.id ? "white" : "",
                                backgroundColor: isFocus === list.id ? "#0047ff" : "",
                                border: isFocus === list.id ? "2px solid #0047FF" : "2px solid transparent",
                                boxSizing: "border-box",
                            }}
                            className="contentBox"
                        >
                            <div className="imgBox">
                                <img src={isFocus === list.id ? list.clickImg : list.imgSrc} alt="" />
                            </div>
                            <p>{list.title}</p>
                        </div>
                    )
                })) : (iosStickyList.map((list) => {
                    return (
                        <div
                            key={list.keyId}
                            onClick={() => {
                                onScroll(list.id)
                            }}
                            style={{
                                color: isFocus === list.id ? "white" : "",
                                backgroundColor: isFocus === list.id ? "#0047ff" : "",
                                border: isFocus === list.id ? "2px solid #0047FF" : "2px solid transparent",
                                boxSizing: "border-box",
                            }}
                            className="contentBox"
                        >
                            <div className="imgBox">
                                <img src={isFocus === list.id ? list.clickImg : list.imgSrc} alt="" />
                            </div>
                            <p>{list.title}</p>
                        </div>
                    )
                }))
            }
        </div >
    );
};

export default SubscriptionSticky;

const stickyBox = css`
    box-shadow: 0px 4px 6px 0px rgba(4, 0, 14, 0.10);
    width: 100%;
    height: 109px;
    position: sticky;
    z-index: 2;
    top: 0px;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;

    .contentBox {
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 110px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 12px;

        .imgBox {
            height: 50px;
            img {
                max-width: 60px;
            }
        }

        & > p {
            text-align: center;
        }
    }
`;
