import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Fragment, useEffect, useRef, useState } from "react";
import close from "../../../assets/ico_close.png";
import starActive from "../../../assets/icon-star.png";
import star from "../../../assets/icon-star-gray.png";
import { useLocation, useNavigate } from "react-router-dom";
import data from "@src/data/locationForMultiMarkerExample";
import { useWriteReview } from "@src/hooks/campus/useCampusSugang";
import { CampusScoreDto } from "@src/hooks/campus/dto/CampusSugang.dto";

interface ToastType {
    showToast: boolean;
    toastMessage: string;
}

const WriteGrade = () => {
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [text, setText] = useState<string>("");
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [toast, setToast] = useState<ToastType>({ showToast: false, toastMessage: "" });
    const navigate = useNavigate();
    const location = useLocation();
    const [scores, setScores] = useState<CampusScoreDto[]>([
        {
            key: 0,
            active: false,
        },
        {
            key: 1,
            active: false,
        },
        {
            key: 2,
            active: false,
        },
        {
            key: 3,
            active: false,
        },
        {
            key: 4,
            active: false,
        },
    ]);

    useEffect(() => {
        scores[0].active ? setIsDisable(false) : setIsDisable(true);
    }, [scores]);

    const trySubmit = async () => {
        const response = await useWriteReview(
            location.state.campusNo,
            text,
            scores.filter((score: CampusScoreDto) => score.active === true).length,
        );
        return response;
    };

    useEffect(() => {
        if (toast.showToast) {
            setTimeout(() => setToast({ showToast: false, toastMessage: "" }), 2000);
        }
    }, [toast]);
    // const inputRef = useRef<any>();
    // const allViewRef = useRef<any>();
    const inputRef = useRef<any>();
    return (
        <>
            <StyledWriteGrade isDisable={isDisable} isFocus={isFocus}>
                <div
                    className="closeBtn"
                    onClick={() => {
                        navigate(`/campus/sugang/${location.state.no}`, { state: { no: location.state.no } });
                    }}
                >
                    <img src={close} alt="" />
                </div>
                <div ref={inputRef}>
                    <div className="title">{location.state.title}</div>
                    <div className="subTitle">후기를 남겨주세요</div>
                    <div className="scoreDiv">
                        {scores.map((score: CampusScoreDto, idx: number) => (
                            <img
                                src={score.active ? starActive : star}
                                alt=""
                                key={idx}
                                onClick={() => {
                                    const newData = scores.map((data: CampusScoreDto, i: number) => ({
                                        ...data,
                                        active: i <= idx,
                                    }));
                                    setScores(newData);
                                }}
                            />
                        ))}
                    </div>
                    <textarea
                        name="textBox"
                        id="1"
                        placeholder="자유롭게 의견을 적어주세요(최대 1000자)"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        maxLength={1000}
                        onBlur={() => {
                            inputRef.current?.style.setProperty("transform", `translateY(0)`);
                        }}
                        onFocus={() => {
                            inputRef.current?.style.setProperty("transform", `translateY(calc(var(--vh, 1vh) * -24))`);
                        }}
                    ></textarea>
                </div>
                <div
                    className="submitBtn"
                    onClick={() => {
                        if (!isDisable) {
                            trySubmit()
                                .then((res: any) => {
                                    navigate(`/campus/sugang/${location.state.no}`, {
                                        state: { no: location.state.no, showToast: true },
                                    });
                                })
                                .catch((err) => {
                                    setToast({
                                        showToast: true,
                                        toastMessage: "다시 시도해주세요",
                                    });
                                });
                        }
                    }}
                >
                    제출하기
                </div>
                <StyledToast isShowToast={toast.showToast}>{toast.toastMessage}</StyledToast>
            </StyledWriteGrade>
        </>
    );
};

export default WriteGrade;

const StyledWriteGrade = styled.div<{ isDisable: boolean; isFocus: boolean }>`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    align-items: center;
    .closeBtn {
        position: absolute;
        top: 21px;
        right: 21px;
        z-index: 10;
        & > img {
            width: 14px;
        }
    }
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: transform 150ms ease-out;
        .title {
            margin-top: 136px;
            text-align: center;
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            padding: 0 16vw;
            word-break: keep-all;
            color: ${Common.colors.normalTextColor};
        }
        .subTitle {
            margin-top: 48px;
            font-weight: 400;
            font-size: 18px;
            color: ${Common.colors.normalTextColor};
        }
        .scoreDiv {
            margin-top: 16px;
            display: flex;
            gap: 18px;
            & > img {
                width: 35px;
            }
        }
        & > textarea {
            background-color: #fbf8f8;
            border-radius: 14px;
            padding: 14px;
            outline: none;
            border: none;
            width: calc(100dvw - 34px);
            /* height: 260px; */
            min-height: 260px;
            margin-top: 24px;
            color: ${Common.colors.normalTextColor};
            resize: none;
            ::placeholder {
                color: #a3a3a3;
                font-weight: 400;
                font-size: 16px;
            }
        }
    }
    .submitBtn {
        width: calc(100dvw - 34px);
        height: 56px;
        background-color: #c3c3c3;
        background-color: ${(props) => (props.isDisable ? "#c3c3c3" : `${Common.colors.aucMarkerColor}`)};
        border-radius: 14px;
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 400;
        font-size: 18px;
        position: absolute;
        bottom: 24px;
    }
`;

const StyledToast = styled.div<{ isShowToast: boolean }>`
    width: calc(100vw - 14px);
    height: 40px;
    padding: 8px 14px;
    background-color: ${Common.colors.darkGrey03};
    color: #ffffff;
    opacity: ${(props) => (props.isShowToast ? 0.7 : 0)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 50px;
    border-radius: 14px;
    position: fixed;
    bottom: 100px;
    transition: all 0.4s;
    font-size: 14px;
    font-weight: 400;
    left: 50%;
    transform: translate(-50%, 0);
`;
