export const itemForFilterMaster = [
    {
        title: "전체",
        id: "0",
        isActive: true,
    },
    {
        title: "유치권",
        id: "1",
        isActive: false,
    },
    {
        title: "분묘기지권",
        id: "2",
        isActive: false,
    },
    {
        title: "법정지상권",
        id: "3",
        isActive: false,
    },
    {
        title: "위반건축물",
        id: "4",
        isActive: false,
    },
    {
        title: "농취증필요",
        id: "5",
        isActive: false,
    },
    {
        title: "토지만입찰",
        id: "6",
        isActive: false,
    },
    {
        title: "건물만입찰",
        id: "7",
        isActive: false,
    },
    {
        title: "토지별도등기",
        id: "8",
        isActive: false,
    },
    {
        title: "지분매각",
        id: "9",
        isActive: false,
    },
    {
        title: "대지권미등기",
        id: "10",
        isActive: false,
    },
    {
        title: "HUG 임차권 인수조건 변경",
        id: "11",
        isActive: false,
    },
];
