import styled from "@emotion/styled";
import { Button } from "@src/components";
import { Common } from "@src/styles/Common";
import { numberToCommaNumber } from "@src/util/numberToCommaNumber";
import React, { Dispatch, SetStateAction } from "react";
import { ToastType } from "../deungi/DeungiTicketCancelList";

interface DeungiTicketCancelItemProps {
    item: any;
    setIsModalShow: Dispatch<SetStateAction<boolean>>;
    setOrderNo: Dispatch<SetStateAction<number>>;
    setToast: Dispatch<SetStateAction<ToastType>>;
    setModalContents: Dispatch<SetStateAction<any>>;
}

const CampusRefundItem = ({ item, setIsModalShow, setOrderNo, setToast, setModalContents }: DeungiTicketCancelItemProps) => {
    const CancelButton = () => {
        if (item.refundStateToString === "취소하기") {
            return (
                <Button
                    label="취소하기"
                    color="blue"
                    size="small"
                    handleClick={() => {
                        setIsModalShow(true);
                        setOrderNo(item.no);
                        setModalContents({
                            text: (
                                <>
                                    <div>강의 수강을 취소하시겠습니까?</div>
                                    <div className="subs">결제액이 환불되며 강의 수강이 취소됩니다.</div>
                                </>
                            ),
                            type: "refund",
                        });
                    }}
                    styles={{ height: "28px", backgroundColor: "#FFDAD8", color: "#0C002C", lineHeight: 0 }}
                />
            );
        }
        if (item.refundStateToString === "취소불가") {
            return (
                <Button
                    label="취소불가"
                    color="blue"
                    size="small"
                    handleClick={() => {
                        // setToast({ showToast: true, toastMessage: "취소가 불가한 내역입니다" });
                        setIsModalShow(true);
                        setModalContents({
                            text: (
                                <>
                                    <div>취소불가</div>
                                    <div className="subs">
                                        {item.paidCardName === "App Store" || item.paidCardName === "Google Play"
                                            ? "인앱 스토어 결제의 경우 취소/환불은 App Store, Google Play 스토어 앱에서 가능합니다"
                                            : `취소불가 사유에 해당되는 결제내역입니다\n \n 취소불가 사유: 강의를 수강함, 자료를 다운로드\n받음, 혜택 사용, 구매한 지 7일이 지남.`}
                                    </div>
                                </>
                            ),
                            type: "cantRefund",
                        });
                    }}
                    styles={{ height: "28px", backgroundColor: "#DADADA", color: "#0C002C", lineHeight: 0 }}
                />
            );
        }
        if (item.refundStateToString === "취소완료") {
            return (
                <Button
                    label="취소완료"
                    color="blue"
                    size="small"
                    handleClick={() => {
                        setToast({ showToast: true, toastMessage: "취소가 완료된 내역입니다" });
                    }}
                    styles={{ height: "28px", backgroundColor: "#F0F0F0", color: "#8D8D8D", lineHeight: 0 }}
                />
            );
        }
    };

    return (
        <>
            <StyledItemWrap>
                <div>
                    <span className="subsText">{item?.startedAtToString}</span>
                    <>{CancelButton()}</>
                </div>
                <div className="row">
                    <div>{item.productName} 결제</div>
                    <div className="cardText">
                        <span>{item.paidCardName}</span> <span className="subsText">{numberToCommaNumber(item.paidPrice)}원</span>
                    </div>
                </div>
            </StyledItemWrap>
        </>
    );
};

export default CampusRefundItem;

const StyledItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-bottom: 0.6px solid #c9c9c9;
    padding: 14px;
    gap: 12px;
    justify-content: center;
    .subsText {
        color: ${Common.colors.darkGrey04};
        ${Common.textStyle.title6_R14};
    }
    & > .row {
        display: flex;
        align-items: flex-end;
        & > div {
            font-size: 16px;
        }
        & > .cardText {
            display: flex;
            width: fit-content;
            justify-content: flex-end;
            color: ${Common.colors.normalTextColor};
            white-space: nowrap;
            ${Common.textStyle.title6_R14};
            & > span {
                margin-left: 4px;
            }
        }
    }
    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${Common.colors.normalTextColor};
        ${Common.textStyle.title3_R16};

        & > .grayText {
            color: ${Common.colors.darkGrey04};
            ${Common.textStyle.title6_R14};
        }
        & > span {
            color: ${Common.colors.blue};
            ${Common.textStyle.title6_R14};
            text-align: center;
        }
    }
`;
