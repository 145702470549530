import React from "react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import sadJson from "@src/assets/unSubscription/sad.json";
import { transferToNative } from "@src/lib/WebAppBridge";
import { IUserStateLandSubsInfo } from "@src/hooks/subscribe/useSubscribe";

interface Props {
    checkUsedDay: boolean;
    showModal: () => void;
    onSubmitUseStateLand: () => void;
    stateLandSubs?: IUserStateLandSubsInfo;
    firstPaidAt?: string;
}

const RegularlySubscribe: React.FC<Props> = ({ checkUsedDay, showModal, onSubmitUseStateLand, stateLandSubs, firstPaidAt }) => {
    const navigate = useNavigate();
    return (
        <div css={rootBoxStyle}>
            <div css={imageBoxStyle}>
                <Lottie className="lottie" animationData={sadJson} />
            </div>
            <div css={contentRootBoxStyle}>
                <div css={contentBoxStyle}>
                    <p css={titleTextStyle}>구독 해지를 원하시나요?</p>
                    <p css={descTextStyle}>
                        구독을 유지하면 매달 혜택받은 가격으로
                        <br />
                        이용할 수 있습니다
                    </p>
                    <button css={containButtonStyle} onClick={() => transferToNative(false, "closeUnsubscribeView")}>
                        유지하기
                    </button>
                </div>
                <div css={contentBoxStyle}>
                    <p css={titleTextStyle}>원하는 해지 방식을 선택해주세요</p>
                    <p css={descTextStyle}>다음 결제를 원하지 않으신가요?</p>
                    <p css={unSubsTextButtonStyle} onClick={() => navigate("refund", { state: { status: false, checkUsedDay } })}>
                        해지예약하기
                    </p>
                </div>
                <div css={contentBoxStyle}>
                    <p css={descTextStyle}>구독을 즉시 취소/환불하고 싶나요?</p>
                    <p
                        css={unSubsTextButtonStyle}
                        onClick={() => {
                            if (checkUsedDay) {
                                showModal();
                                return;
                            }
                            const checkSubsStateLandInfo = stateLandSubs?.list?.filter((val) => val.isSubs);
                            if (checkSubsStateLandInfo?.[0]?.firstUsedAt && new Date(firstPaidAt ?? 0) < new Date("2024-01-10")) {
                                onSubmitUseStateLand();
                                return;
                            }
                            navigate("refund", { state: { status: true, checkUsedDay } });
                        }}
                    >
                        즉시 해지하기
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RegularlySubscribe;

const rootBoxStyle = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100dvh;
    height: 100vh;
    overflow-y: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const imageBoxStyle = css`
    padding: 24px 24px 48px;
    .lottie {
        width: 200px;
        height: 200px;
    }
`;

const contentRootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 48px;
    width: 100vw;
    padding: 0 14px;
`;

const contentBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

const titleTextStyle = css`
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
`;

const descTextStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
`;

const unSubsTextButtonStyle = css`
    color: #8d8d8d;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-position: under;
    cursor: pointer;
`;

const containButtonStyle = css`
    margin-top: 10px;
    padding: 14px;
    border-radius: 14px;
    background-color: #2275ee;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
`;
