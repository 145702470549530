import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import icoConvertMeter from "@src/assets/ico_convert_meter.png";
import icoConvertPyeong from "@src/assets/ico_convert_pyeong.png";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";

interface Props {}

const StateLandAreaFilter: React.FC<Props> = () => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);
    const [inputValues, setInputValues] = useState<string[]>([
        stateLandFilter?.area?.[0] ?? stateLandFilter?.pyong?.[0],
        stateLandFilter?.area?.[1] ?? stateLandFilter?.pyong?.[1],
    ]);
    const [type, setType] = useState<"pyong" | "area">("pyong");
    const handleClick = () => {
        setType((pre) => (pre === "area" ? "pyong" : "area"));
    };

    const onChangeValue = useCallback(
        (evt: ChangeEvent<HTMLInputElement>, index: number) => {
            const newVal = evt.target.value;
            if (isNaN(Number(newVal))) {
                return;
            }
            const newValue = [...inputValues];
            newValue[index] = newVal;
            setInputValues(newValue);
        },
        [inputValues],
    );

    useEffect(() => {
        if (!stateLandFilter[type]?.every((val, idx) => val === inputValues[idx])) {
            const newStateLandFilter = {
                ...stateLandFilter,
                [type]: inputValues.map((v) => (v ? v : null)),
            };
            setStateLandFilter(newStateLandFilter);
        }
    }, [inputValues]);

    useEffect(() => {
        if (type === "pyong" && stateLandFilter.area?.[0]) {
            const newStateLandFilter = {
                ...stateLandFilter,
                pyong: stateLandFilter.area,
            };
            delete newStateLandFilter?.area;
            setStateLandFilter(newStateLandFilter);
        }
        if (type === "area" && stateLandFilter.pyong?.[0]) {
            const newStateLandFilter = {
                ...stateLandFilter,
                area: stateLandFilter.pyong,
            };
            delete newStateLandFilter?.pyong;
            setStateLandFilter(newStateLandFilter);
        }
    }, [type]);

    return (
        <>
            <div css={titleBoxStyle}>
                <p>면적</p>
                <img
                    src={type === "pyong" ? icoConvertMeter : icoConvertPyeong}
                    alt="단위변환버튼"
                    onClick={handleClick}
                    width={56}
                    height={28}
                />
            </div>
            <div css={inputRootBoxStyle}>
                <div css={inputBoxStyle}>
                    <input
                        placeholder="최소 없음"
                        css={inputStyle}
                        value={inputValues[0] ?? ""}
                        onChange={(evt) => onChangeValue(evt, 0)}
                    />
                    <p className="unit">{type == "pyong" ? "평" : "㎡"}</p>
                </div>
                <p>~</p>
                <div css={inputBoxStyle}>
                    <input
                        placeholder="최대 없음"
                        css={inputStyle}
                        value={inputValues[1] ?? ""}
                        onChange={(evt) => onChangeValue(evt, 1)}
                    />
                    <p className="unit">{type == "pyong" ? "평" : "㎡"}</p>
                </div>
            </div>
        </>
    );
};

export default StateLandAreaFilter;

const titleBoxStyle = css`
    padding: 14px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const inputRootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 14px;
    width: 100%;
    box-sizing: border-box;
`;

const inputBoxStyle = css`
    position: relative;
    & > .unit {
        position: absolute;
        top: 50.1%;
        right: 5px;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const inputStyle = css`
    padding: 10px 4px;
    padding-right: 28px;
    border-radius: 4px;
    background-color: #f0f0f0;
    box-sizing: border-box;
    width: 100%;
    text-align: right;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    border: none;
    ::placeholder {
        color: #8d8d8d;
    }
`;
