import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import React from "react";

interface titleProps {
    title: string;
    size: "large" | "small";
}
function Title({ title, size }: titleProps) {
    return (
        <div className={`${size} title`} css={titleWrap}>
            {title}
        </div>
    );
}

const titleWrap = css`
    font-style: normal;
    font-weight: 400;
    color: ${Common.colors.normalTextColor};

    &.large {
        font-size: 18px;
        line-height: 28px;
    }
    &.small {
        font-size: 16px;
        line-height: 26px;
    }
`;

export default Title;
