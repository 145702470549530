import React, { useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface InfoCardProps {
    text: any;
    height: number;
    bottomCheck: string;
}

const InfoCard = ({ text, height, bottomCheck }: InfoCardProps) => {
    const SLIDER_WIDTH = screen.width;
    return (
        <div css={wrap({ SLIDER_WIDTH, height, bottomCheck })}>
            <div className="flex">
                <div css={cardWrap(height as number)}>
                    <div className="Contents">
                        <div css={contentWrapper}>{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoCard;

const wrap = ({ SLIDER_WIDTH, height, bottomCheck }: { SLIDER_WIDTH: any; height: number; bottomCheck: string }) => css`
    width: ${SLIDER_WIDTH};
    position: absolute;
    bottom: ${bottomCheck};
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    z-index: 999;
`;
const cardWrap = (height: number) => css`
    display: flex;
    height: fit-content;
    /* ${height ? `min-height: ${height}px` : "100px"}; */
    box-sizing: border-box;
    background: ${Common.colors.white};
    border-radius: 14px;
    text-align: center;
    .Contents {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .topSideData {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;
    }
    .titleContent {
        display: flex;
        gap: 4px;
        align-items: center;
        & > span {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
        }
    }
`;

const contentWrapper = css`
    display: flex;
    width: 92vw;
    justify-content: center;
    align-items: center;
`;
