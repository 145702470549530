import axios from "axios";
import React, { useEffect, useRef } from "react";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import missgoLogo from "@src/assets/missgo_logo.svg";
import { css } from "@emotion/react";
import useMultipleStates from "@src/hooks/coupon/useMultipleStates";
import { useNavigate } from "react-router-dom";

/**
 * @author 김선진
 * @title 쿠폰등록 페이지(iOS)
 * @description 앱에서 내정보-쿠폰 누르면 사용자의 default 브라우저로 이동,
 * 쿠폰등록 페이지가 뜨는데 거기서 '회원이메일, 쿠폰코드' 입력 후 등록한다.
 */

export default function RegisterCouponIOS() {
    const {
        state: { email, couponCode, isModalShow, isRegistered, resultMessage },
        action,
    } = useMultipleStates();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const checkEmail = () => {
        let regExp = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        return regExp.test(email);
    };

    const onRegisterCoupon = async () => {
        const url = process.env.REACT_APP_API_URL;
        // api 호출(쿠폰등록 IOS)
        try {
            const response = await axios({
                url: `${url}/payment/registerCouponIos`,
                // ↓ 서버에서 optional로 바꾸었음. 보안상 문제도 있음
                // headers: { Authorization: ACCESS_TOKEN },
                method: "POST",
                data: {
                    email: email,
                    coupon_code: couponCode,
                },
            });
            let {
                data: {
                    payload: { errCode, message, result },
                },
            } = response;
            if (errCode === 0 || result === "success") {
                action({ type: "isRegistered", payload: true });
            } else {
                action({ type: "isRegistered", payload: false });
            }
            action({ type: "isModalShow", payload: true });
            action({ type: "resultMessage", payload: message });
        } catch (error) {
            console.error(`error: ${error}`);
        }
    };

    useEffect(() => {
        emailInputRef.current && emailInputRef.current.focus();
    }, []);

    return (
        <div css={Wrapper}>
            <div>
                <div css={LogoContainer}>
                    <img src={missgoLogo} alt="logo" width={86} height={26} />
                </div>
                <p css={GuideText}>
                    미스고옥션 회원이메일과 쿠폰코드를 <br />
                    입력하여 주시기 바랍니다.
                </p>
                <div css={Common.Container}>
                    <label css={Common.Label}>회원이메일</label>
                    <input
                        type="email"
                        name="email"
                        value={email || ""}
                        onChange={(e) => action({ type: "email", payload: e.target })}
                        placeholder="이메일 주소를 입력해주세요"
                        tabIndex={1}
                        ref={emailInputRef}
                        css={Common.Input("email", email)}
                    />
                    {!checkEmail() && email && <p css={Email.Notice}>{"올바른 이메일을 입력해주세요"}</p>}
                </div>
                <div css={Common.Container}>
                    <label css={Common.Label}>쿠폰코드</label>
                    <input
                        type="text"
                        name="couponCode"
                        value={couponCode || ""}
                        onChange={(e) => action({ type: "couponCode", payload: e.target })}
                        placeholder="쿠폰코드를 입력해주세요"
                        tabIndex={2}
                        css={Common.Input("couponCode", couponCode)}
                    />
                    <button
                        onClick={onRegisterCoupon}
                        disabled={!(couponCode && checkEmail())}
                        css={Button(!(couponCode && checkEmail()))}
                    >
                        등록하기
                    </button>
                </div>
                <div css={CouponNotice.Wrapper}>
                    <p css={CouponNotice.Message}>
                        ※ 등록 유효기간이 만료된 쿠폰은 사용할 수 없으며 본 쿠폰은 이벤트 상품으로 유효기간 연장 및 현금으로 환불,
                        교환이 불가합니다. <br /> ※ 쿠폰코드를 등록한 경우, 즉시 적용됩니다. <br /> ※ 이미 정기구독을 이용 중인
                        회원은 중복 등록이 불가하므로 정기구독이 만료되고 난 뒤 쿠폰을 사용할 수 있습니다. <br /> ※ 이용권은 쿠폰
                        페이지에서 즉시 적용할 수 있고, 금액 할인권은 결제창에서 적용할 수 있습니다. <br /> ※ 발급된 할인쿠폰의
                        유효기간은 내부 사정에 따라 조기 종료 및 변경될 수 있습니다. <br /> ※ 쿠폰 등록과 관련된 문의 사항이
                        있으신 경우 [‘미스고옥션‘ 앱 접속- 내 정보- 고객센터- 문의하기]로 남겨주시기 바랍니다.
                    </p>
                </div>

                {isModalShow && (
                    <ConfirmModal
                        resultMessage={resultMessage}
                        onConfirm={() => {
                            action({ type: "isModalShow", payload: false });
                            isRegistered && navigate("/successCoupon");
                        }}
                        buttonText={"확인"}
                        disabled={false}
                    />
                )}
            </div>
        </div>
    );
}

const Wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    height: 100vh;
    ::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
    }
    scrollbar-width: none;
`;

const LogoContainer = css`
    margin-left: 14px;
    margin-top: 70px;
`;

const GuideText = css`
    margin: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
`;

const Common = {
    Container: css`
        display: flex;
        flex-direction: column;
    `,
    Label: css`
        margin-top: 14px;
        margin-left: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    `,
    Input: (type: string, value: string) => css`
        margin-top: 14px;
        margin-bottom: ${type === "email" ? "4px" : "14px"};
        margin-left: 14px;
        margin-right: 14px;
        height: 54px;
        background-color: ${value.length >= 1 ? "#FBF8F8" : "#FFFFFF"};
        border-width: 1px;
        border-style: solid;
        border-color: ${value.length >= 1 ? "#0C002C" : "#B1AEB8"};
        border-radius: 14px;
        text-indent: 14px;
        font-size: 16px;
        color: #382e51;
    `,
};

const Email = {
    Notice: css`
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ff0000;
        margin-left: 28px;
        margin-right: 28px;
    `,
};

const Button = (btnDisabled: boolean) => css`
    height: 56px;
    border-radius: 14px;
    border: none;
    background-color: ${btnDisabled ? "#C3C3C3" : "#2275EE"};
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 34px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
`;

const CouponNotice = {
    Wrapper: css`
        background-color: #fbf8f8;
    `,
    Message: css`
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 14px;
        padding-right: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #a3a3a3;
    `,
};
