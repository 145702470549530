import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { css } from "@emotion/react";
import ico_filter from "@src/assets/ico_filter.png";
import ico_filter_white from "@src/assets/ico_filter_white.png";
import ico_filter_gray from "@src/assets/ico_filter_gray.png";
import { Link, useLocation } from "react-router-dom";
import useGetLocations from "@src/hooks/filter/useGetLocations";
import LocationFilter from "./LocationFilter";
import ContentHeader from "./ContentHeader";
import styled from "@emotion/styled";
import useSelectedFilter from "@src/hooks/filter/useSelectedFilter";
import { itemForFilterMaster } from "@src/data/itemForFilterMaster";
import SelectedFilter from "./SelectedFilter";
import { opposingPowerData } from "@src/data/itemForFilterOpposePower";
import WithoutFilter from "./WithoutFilter";
import useDatePickerFilter from "@src/hooks/filter/useDatePickerFilter";
import CustomDateRangePicker from "./Calendar";
import RangeSelected from "./RangeSelected";
import useRangeSelected from "@src/hooks/filter/useRangeSelected";
import { itemForFilterProgress } from "@src/data/itemForFilterProgress";
import { itemForFilterBidCount } from "@src/data/itemForFilterBidCount";
import ResetFilterButton from "./ResetFilterButton";
import { initialState, useFilterStore } from "@src/store/filterStore";
import FilterFooter from "./FilterFooter";
import PurposeFilter from "./PurposeFilter";
import useGetPurpose, { PurposeDataTypes } from "@src/hooks/filter/useGetPurpose";
import BidCountInput from "./BidCountInput";
import { getTabType, Tab, TabTypeButton, tabTypeData } from "@src/pages/Filter";
import { itemForFilterDisposition } from "@src/data/itemForFilterDisposition";
import { itemForFilterAssetType } from "@src/data/itemForFilterAssetType";
import AiResetConfirmModal from "@src/pages/aiFilter/AiResetConfirmModal";
import { appearBottomSheetBackground } from "../bottomSheet/BottomSheet";
import FilterHistory from "./FilterHistory";
import useCallToken from "@src/hooks/useCallToken";
import EmptyResult from "../SearchItem/EmptyResult";
import ToastMessage from "./ToastMessage";
import EmptyLocation from "./EmptyLocation";
import { isEmpty } from "lodash";
import produce from "immer";
import { format } from "date-fns";
import { numberToKorean } from "@src/util/numberToKoreanPrice";
import { purposeData } from "@src/data/itemForFilterPurpose";
import { Common } from "@src/styles/Common";

interface OutFilterProps {
    setIsBackgroundVisible?: Dispatch<SetStateAction<boolean>>;
    isShowFilter?: boolean;
    type?: string;
    isDisableFilter?: boolean;
}

const OutFilter = ({ setIsBackgroundVisible, isShowFilter, type, isDisableFilter = false }: OutFilterProps) => {
    const [buttonState, setButtonState] = useState("");
    const [withoutState, setWithoutState] = useState(false);
    const [allPNU, setAllPNU] = useState<string[]>([]);
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [bidCount, setBidCount] = useState({
        minCount: "",
        maxCount: "",
    });

    const location = useLocation();

    const getString = (data: any, key: string) => {
        const resultData: string[] = [];

        if (key === "assets" && !isEmpty(data["assets"])) {
            data["assets"].forEach((item: string) => {
                if (item === "1") {
                    resultData.push("압류재산");
                }

                if (item === "2") {
                    resultData.push("국공유재산");
                }

                if (item === "3") {
                    resultData.push("신탁재산");
                }

                if (item === "4") {
                    resultData.push("기타재산");
                }
            });
        }

        if (key === "type") {
            if (data["type"] === "1") {
                resultData.push("경매");
            }

            if (data["type"] === "2") {
                resultData.push("공매");
            }
        }

        if (key === "counter_force") {
            if (data["counter_force"] === "2") {
                resultData.push("대항력 있음");
            }

            if (data["counter_force"] === "1") {
                resultData.push("대항력 없음");
            }

            if (data["counter_force"] === "3") {
                resultData.push("미상");
            }
        }

        if (key === "auction_master_item" && !isEmpty(data["auction_master_item"])) {
            data["auction_master_item"].forEach((item: string) => {
                if (item === "1") {
                    resultData.push("유치권");
                }

                if (item === "2") {
                    resultData.push("분묘기지권");
                }

                if (item === "3") {
                    resultData.push("법정지상권");
                }

                if (item === "4") {
                    resultData.push("위반건축물");
                }

                if (item === "5") {
                    resultData.push("농취증필요");
                }

                if (item === "6") {
                    resultData.push("토지만입찰");
                }

                if (item === "7") {
                    resultData.push("건물만입찰");
                }

                if (item === "8") {
                    resultData.push("토지별도등기");
                }

                if (item === "9") {
                    resultData.push("지분매각");
                }

                if (item === "10") {
                    resultData.push("대지권미등기");
                }
                if (item === "11") {
                    resultData.push("HUG 임차권 인수조건 변경");
                }
            });
        }

        if (key === "status" && data["status"][0] !== "0") {
            data["status"].forEach((item: string) => {
                if (item === "1") {
                    resultData.push("진행예정");
                }

                if (item === "2") {
                    resultData.push("진행중");
                }

                if (item === "3") {
                    resultData.push("매각");
                }

                if (item === "4") {
                    resultData.push("기타");
                }
            });
        }

        if (key === "skp_type") {
            if (data["skp_type"] === "1") {
                resultData.push("신건");
            }

            if (data["skp_type"] === "2") {
                resultData.push("유찰");
            }
        }

        if (key === "skp_cnt" && data["skp_cnt"][0] !== "" && data["skp_cnt"][1] !== "") {
            resultData.push(`유찰횟수 ${data["skp_cnt"][0]} ~ ${data["skp_cnt"][1]}`);
        }

        if (key === "due_date" && data["due_date"][0] !== "" && data["due_date"][1] !== "") {
            resultData.push(`매각기일 ${data["due_date"][0]} ~ ${data["due_date"][1]}`);
        }

        if (key === "est_price" && data["est_price"][0] !== "" && data["est_price"][1] !== "") {
            if (data["est_price"][1] === data["est_price"][0]) {
                resultData.push(`감정가 ~ ${data["est_price"][0]}`);
            } else {
                resultData.push(`감정가 ${data["est_price"][0]} ~ ${data["est_price"][1]}`);
            }
        }

        if (key === "est_price" && data["est_price"][0] === "" && data["est_price"][1] !== "") {
            resultData.push(`감정가 ~ ${data["est_price"][1]}`);
        }

        if (key === "est_price" && data["est_price"][0] !== "" && data["est_price"][1] === "") {
            resultData.push(`300억 ~`);
        }

        if (key === "min_price" && data["min_price"][0] !== "" && data["min_price"][1] !== "") {
            if (data["min_price"][0] === data["min_price"][1]) {
                resultData.push(`최저입찰가 ~ ${data["min_price"][0]}`);
            } else {
                resultData.push(`최저입찰가 ${data["min_price"][0]} ~ ${data["min_price"][1]}`);
            }
        }

        if (key === "min_price" && data["min_price"][0] === "" && data["min_price"][1] !== "") {
            resultData.push(`최저입찰가 ~ ${data["min_price"][1]}`);
        }

        if (key === "min_price" && data["min_price"][0] !== "" && data["min_price"][1] === "") {
            resultData.push(`300억 ~`);
        }

        if (key === "sales_method") {
            if (data["sales_method"] === "1") {
                resultData.push(`매각`);
            }
            if (data["sales_method"] === "2") {
                resultData.push(`임대`);
            }
        }

        return resultData;
    };

    const getRoutePath = () => {
        if (location.pathname.includes("/items")) return "/items";
        return location.pathname;
    };

    const routePath = getRoutePath();

    const setDimVisible = useFilterStore((state) => state.setDimVisible);
    const filterState = useFilterStore((state) => state.filterState);
    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const setFilterVisible = useFilterStore((state) => state.setFilterVisible);
    const filterVisible = useFilterStore((state) => state.filterVisible);

    useEffect(() => {
        setDimVisible(filterVisible);
        setIsBackgroundVisible && setIsBackgroundVisible(filterVisible);
    }, [filterVisible]);

    const [tabState, setTabState] = useState("전체");

    const { token } = useCallToken();

    useEffect(() => {
        if (token) {
            setTabState(tabTypeData[filterState.type as any]);
        }
        setWithoutState(filterState.auction_master_except);
    }, [filterState, token]);

    useEffect(() => {
        setDimVisible(false);
    }, []);

    // 처분방식
    const {
        selectedState: itemForFilterDispositionState,
        onSelectedItem: onSelectedDispositionItem,
        onClickReset: onClickResetDisposition,
    } = useSelectedFilter(itemForFilterDisposition, filterState.sales_method);

    // 자산구분
    const {
        selectedState: itemForFilterAssetState,
        onSelectedItem: onSelectedAssetItem,
        onClickReset: onClickResetAsset,
    } = useSelectedFilter(itemForFilterAssetType, isEmpty(filterState.assets) ? ["0"] : filterState.assets);

    // 경공매
    const itemForFilterTypeArea = useMemo(() => {
        return (
            <FilterContainer visible={filterVisible}>
                <ContentHeader
                    title="경공매"
                    onClick={() => {
                        setFilterVisible(filterVisible);
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />
                <Tab>
                    {tabTypeData.map((item, index) => (
                        <TabTypeButton
                            isActiveTab={item === tabState}
                            key={index}
                            onClick={() => {
                                const resultParams = {
                                    ...filterState,
                                    type: getTabType[item],
                                };
                                setStoreFilterState(resultParams, true);
                                setTabState(item);
                            }}
                        >
                            <span>{item}</span>
                        </TabTypeButton>
                    ))}
                </Tab>
                {tabState === "공매" ? (
                    <>
                        <Border />
                        <ContentHeader title="처분방식" state={itemForFilterDispositionState} />

                        <SelectedFilter
                            onSelectReset={onClickResetDisposition}
                            type="uniary"
                            selectedState={itemForFilterDispositionState}
                            onSelectedItem={onSelectedDispositionItem}
                            isMulti={false}
                        />

                        <Border />
                        <ContentHeader title="자산구분" state={itemForFilterAssetState} />
                        <SelectedFilter
                            type="uniary"
                            onSelectReset={onClickResetAsset}
                            selectedState={itemForFilterAssetState}
                            onSelectedItem={onSelectedAssetItem}
                        />
                    </>
                ) : (
                    <></>
                )}
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
                <FilterFooter
                    itemForFilterDispositionState={itemForFilterDispositionState}
                    itemForFilterAssetState={itemForFilterAssetState}
                    onSubmit={() => console.log("진행상태!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />
            </FilterContainer>
        );
    }, [itemForFilterAssetState, itemForFilterDispositionState, tabState, filterState, filterVisible, bidCount]);

    // 유찰횟수
    const {
        selectedState: itemForFilterBidCountState,
        onSelectedItem: onSelectedBidCount,
        onClickReset: onClickResetBidCount,
    } = useSelectedFilter(itemForFilterBidCount, filterState?.skp_type);

    // 유찰횟수
    const itemForFilterBidCountStateArea = useMemo(() => {
        return (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    itemForFilterBidCountState={itemForFilterBidCountState}
                    onSubmit={() => console.log("진행상태!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />
                <ContentHeader
                    title="유찰횟수"
                    onClick={() => {
                        setFilterVisible(filterVisible);
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />
                <SelectedFilter
                    type="uniary"
                    onSelectReset={onClickResetBidCount}
                    selectedState={itemForFilterBidCountState}
                    onSelectedItem={onSelectedBidCount}
                    isMulti={false}
                />
                {itemForFilterBidCountState[2].isActive && (
                    <BidCountInput
                        bidCount={{
                            minCount: filterState.skp_cnt[0],
                            maxCount: filterState.skp_cnt[1],
                        }}
                        type="setStore"
                    />
                )}
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        );
    }, [itemForFilterBidCountState, filterState, filterVisible, bidCount]);

    // 진행상태
    const {
        selectedState: itemForFilterProgressState,
        onSelectedItem: onSelectedProgressItem,
        onClickReset: onClickResetProgress,
    } = useSelectedFilter(itemForFilterProgress, filterState?.status);

    // 진행상태
    const progressStateArea = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    itemForFilterProgressState={itemForFilterProgressState}
                    onSubmit={() => console.log("진행상태!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />
                <ContentHeader
                    title="진행상태"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />

                <SelectedFilter
                    type="uniary"
                    onSelectReset={onClickResetProgress}
                    selectedState={itemForFilterProgressState}
                    onSelectedItem={onSelectedProgressItem}
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [itemForFilterProgressState, filterState, filterVisible],
    );

    // 최저입찰가
    const {
        startValue: lowBidPriceStartValue,
        endValue: lowBidPriceEndValue,
        onClickValue: onClickLowBidPriceValue,
        onClickReset: onClickLowBidPriceReset,
    } = useRangeSelected(filterState?.min_price);

    // 최저입찰가
    const lowBidPrice = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    lowBidPrice={{
                        lowBidPriceStartValue: lowBidPriceStartValue,
                        lowBidPriceEndValue: lowBidPriceEndValue,
                    }}
                    onSubmit={() => console.log("진행상태!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />
                <ContentHeader
                    title="최저입찰가"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />
                <RangeSelected
                    startValue={lowBidPriceStartValue?.value || ""}
                    endValue={lowBidPriceEndValue?.value || ""}
                    onClickValue={onClickLowBidPriceValue}
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [lowBidPriceStartValue, lowBidPriceEndValue, filterState, filterVisible],
    );

    // 감정가
    const {
        startValue: appraisedPriceStartValue,
        endValue: appraisedPriceEndValue,
        onClickValue: onClickAppraisedValue,
        onClickReset: onClickAppraisedReset,
    } = useRangeSelected(filterState?.est_price);

    // 감정가
    const appraisedPriceArea = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    appraisedPrice={{
                        appraisedPriceStartValue: appraisedPriceStartValue,
                        appraisedPriceEndValue: appraisedPriceEndValue,
                    }}
                    onSubmit={() => console.log("진행상태!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />

                <ContentHeader
                    title="감정가"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />
                <RangeSelected
                    startValue={appraisedPriceStartValue?.value || ""}
                    endValue={appraisedPriceEndValue?.value || ""}
                    onClickValue={onClickAppraisedValue}
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [appraisedPriceStartValue, appraisedPriceEndValue, filterState, filterVisible],
    );

    // 매각기일
    const {
        monthState,
        state: dateState,
        setState: setDateState,
        onChangeMonthState,
        onClickResetDate,
    } = useDatePickerFilter(filterState?.due_date);

    // 매각기일
    const sellDateArea = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    dateState={dateState}
                    onSubmit={() => console.log("진행상태!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />

                <ContentHeader
                    title="매각기일"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />
                <CustomDateRangePicker
                    monthState={monthState}
                    state={dateState}
                    setState={setDateState}
                    onChangeMonthState={onChangeMonthState}
                    type="setStore"
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [dateState, filterState, monthState, filterVisible],
    );

    // 소재지
    const {
        locationData: locationState,
        activeData,
        selectedType,
        handleSelect,
        selectedAll,
        onClickRemove,
        setActiveData,
        resetActiveData,
        activePNUState,
        setActivePNUState,
        resetLocationData,
        setSubmitPNUData,
        submitPNUData,
        getActiveData,
        toastVisible,
        setToastVisible,
    } = useGetLocations(filterState.loc);

    useEffect(() => {
        getActiveData();
    }, []);

    // 소재지
    const LocationArea = useMemo(() => {
        return (
            <FilterContainer visible={filterVisible}>
                <ContentHeader
                    title="소재지"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />
                {submitPNUData.length ? (
                    <></>
                ) : (
                    <div style={{ height: 50, display: "flex", alignItems: "center", padding: "0px 14px" }}>
                        <EmptyLocation />
                    </div>
                )}
                <FilterFooter
                    locationState={activeData}
                    onSubmit={() => console.log("진행상태!")}
                    type="부분필터"
                    onClickRemove={onClickRemove}
                    setSubmitPNUData={setSubmitPNUData}
                />
                <LocationFilter
                    style={{ height: "270px" }}
                    handleSelect={handleSelect}
                    activeData={activeData}
                    locationData={locationState}
                    selectedType={selectedType}
                    setActiveData={setActiveData}
                    resetActiveData={resetActiveData}
                    activePNUState={activePNUState}
                    setActivePNUState={setActivePNUState}
                    resetLocationData={resetLocationData}
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
                <ToastMessage visible={toastVisible} setToastVisible={setToastVisible}>
                    최대 10개까지 선택 가능합니다.
                </ToastMessage>
            </FilterContainer>
        );
    }, [
        activeData,
        locationState,
        filterState,
        filterVisible,
        activePNUState,
        selectedType,
        submitPNUData,
        toastVisible,
        allPNU,
    ]);

    //용도
    const {
        handleSelect: handlePurposeSelect,
        purposeState,
        query,
        handleSelectAll,
        onClickRemove: onClickRemovePurpose,
        resetPurpose,
    } = useGetPurpose(filterState?.use);

    // 용도
    const purposeArea = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <ContentHeader
                    title="용도"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    type={"uniary"}
                />

                <FilterFooter
                    purposeState={purposeState}
                    onClickRemovePurpose={onClickRemovePurpose}
                    onSubmit={() => console.log("용도!")}
                    type={"부분필터"}
                />
                <PurposeFilter
                    style={{ height: "270px" }}
                    handleSelectAll={handleSelectAll}
                    handleSelect={handlePurposeSelect}
                    purposeState={purposeState}
                    query={query}
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [purposeState, query, filterState, filterVisible],
    );

    // 경매고수물건
    const {
        selectedState: itemForFilterMasterState,
        onSelectedItem: onSelectedMasterItem,
        onClickReset: onClickResetMaster,
    } = useSelectedFilter(itemForFilterMaster, filterState?.auction_master_item);

    // 경매고수물건
    const MasterArea = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    itemForFilterMasterState={itemForFilterMasterState}
                    onSubmit={() => console.log("용도!")}
                    type={"부분필터"}
                    style={{ display: "none" }}
                />
                <ContentHeader
                    title="경매고수물건"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    state={itemForFilterMasterState}
                    type={"uniary"}
                />

                <SelectedFilter
                    type="uniary"
                    selectedState={itemForFilterMasterState}
                    onSelectedItem={onSelectedMasterItem}
                    onSelectReset={onClickResetMaster}
                />
                <WithoutFilter withoutState={withoutState} setWithoutState={setWithoutState} type="setStore" />

                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [itemForFilterMasterState, withoutState, filterState, filterVisible],
    );

    // 대항력
    const {
        selectedState: opposingPowerState,
        onSelectedItem: onSelectedOpposingPowerItem,
        onClickReset: onClickResetOpposingPower,
    } = useSelectedFilter(opposingPowerData, filterState?.counter_force);

    // 대항력
    const OpposingPowerArea = useMemo(
        () => (
            <FilterContainer visible={filterVisible}>
                <FilterFooter
                    opposingPowerState={opposingPowerState}
                    onSubmit={() => console.log("대항력!")}
                    type={"부분필터"}
                    style={{ opacity: "0%", visibility: "hidden" }}
                />
                <ContentHeader
                    title="대항력"
                    buttonText="전체"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                    }}
                    state={opposingPowerState}
                    type={"uniary"}
                />
                <SelectedFilter
                    type="uniary"
                    onSelectReset={onClickResetOpposingPower}
                    selectedState={opposingPowerState}
                    onSelectedItem={onSelectedOpposingPowerItem}
                    isMulti={false}
                />
                <ResetFilterButton onResetClick={() => setResetModalVisible(true)} />
            </FilterContainer>
        ),
        [opposingPowerState, filterState, filterVisible],
    );

    const onClickReset = () => {
        resetActiveData();
        handlePurposeSelect("전체", "중분류", undefined, "All");
        resetPurpose();
        onClickResetOpposingPower();
        onClickResetMaster();
        onClickResetDisposition();
        onClickResetAsset();
        onClickResetProgress();
        onClickResetBidCount();
        onClickResetDate();
        onClickAppraisedReset();
        onClickLowBidPriceReset();
        setWithoutState(false);
        setResetModalVisible(false);
        setTabState("전체");
        setStoreFilterState({
            ...filterState,
            type: "0",
            loc: [],
            use: [],
            counter_force: "0",
            auction_master: [],
            auction_master_item: [],
            auction_master_except: false,
            except_item: [],
            status: ["1", "2"],
            skp_type: "",
            skp_cnt: ["", ""],
            due_date: ["", ""],
            est_price: ["", ""],
            min_price: ["", ""],
            sales_method: "0",
            assets: [],
        });
    };

    const filterHistory = useFilterStore((state) => state.filterHistory);
    const getFilterHistory = useFilterStore((state) => state.getFilterHistory);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);

    useEffect(() => {
        token && getFilterHistory();
    }, [token]);
    const result: string[] = [];
    useEffect(() => {
        filterHistory.forEach((history) => {
            history.value.loc.forEach((item) => {
                if (!result.includes(item)) {
                    result.push(item);
                }
            });
        });

        setAllPNU(result);

        setUpdateHistoryFilterFlag(false);
    }, [filterHistory]);

    /// 최근검색

    const { activeData: activePNU, submitPNUData: submitPNUDataHistory } = useGetLocations(allPNU);

    const updateHistoryFilterFlag = useFilterStore((state) => state.updateHistoryFilterFlag);
    const recentSearch = useMemo(() => {
        return (
            <FilterContainer visible={filterVisible} style={{ maxHeight: "calc(100vh - 102px)" }}>
                <ContentHeader
                    title="최근 검색한 필터"
                    buttonText="전체"
                    onClick={() => {
                        setFilterVisible(false);
                        setButtonState("");
                        filterHistory.forEach((history) => {
                            history.value.loc.forEach((item) => {
                                if (!result.includes(item)) {
                                    result.push(item);
                                }
                            });
                        });
                        getFilterHistory();
                    }}
                    state={opposingPowerState}
                    type={"uniary"}
                />
                <div style={{ height: "calc(100vh - 172px)", overflow: "scroll" }}>
                    {filterHistory.length ? (
                        filterHistory.map((history, index) => {
                            return (
                                <FilterHistory
                                    key={`${history.updated_at}_${index}`}
                                    history={history.value}
                                    updateAt={history.updated_at}
                                    no={history.no}
                                    activePNU={activePNU}
                                    allPNU={allPNU}
                                />
                            );
                        })
                    ) : (
                        <EmptyResult>
                            <>
                                <b>최근 검색한 필터 목록이 없어요</b>
                                <span>필터를 이용해 물건을 검색해보세요!</span>
                            </>
                        </EmptyResult>
                    )}
                </div>
            </FilterContainer>
        );
    }, [
        filterState, //
        filterVisible, //
        filterHistory,
        activePNU,
        allPNU,
        activeData, //
        selectedType, //
        updateHistoryFilterFlag,
        submitPNUDataHistory,
    ]);
    // activeData, locationState, filterState, filterVisible, activePNUState, selectedType, submitPNUData, toastVisible

    const renderFilter = (buttonState: string) => {
        if (buttonState === "소재지") return LocationArea;
        if (buttonState === "대항력") return OpposingPowerArea;
        if (buttonState === "경매고수물건") return MasterArea;
        if (buttonState === "매각기일") return sellDateArea;
        if (buttonState === "감정가") return appraisedPriceArea;
        if (buttonState === "최저입찰가") return lowBidPrice;
        if (buttonState === "진행상태") return progressStateArea;
        if (buttonState === "유찰횟수") return itemForFilterBidCountStateArea;
        if (buttonState === "용도") return purposeArea;
        if (buttonState === "경공매") return itemForFilterTypeArea;
        if (buttonState === "최근검색") return recentSearch;
    };

    const [tabButtons, setTabButtons] = useState([
        {
            title: "최근검색",
            key: "",
            selected: false,
            selectedChild: "",
        },
        {
            title: "경공매",
            key: "type",
            selected: false,
            selectedChild: "",
        },
        {
            title: "소재지",
            key: "loc",
            selected: false,
            selectedChild: "",
        },
        {
            title: "용도",
            key: "use",
            selected: false,
            selectedChild: "",
        },
        {
            title: "대항력",
            key: "counter_force",
            selected: false,
            selectedChild: "",
        },
        {
            title: "경매고수물건",
            key: "auction_master_item",
            selected: false,
            selectedChild: "",
        },
        {
            title: "진행상태",
            key: "status",
            selected: false,
            selectedChild: "",
        },
        {
            title: "유찰횟수",
            key: "skp_type",
            selected: false,
            selectedChild: "",
        },
        {
            title: "매각기일",
            key: "due_date",
            selected: false,
            selectedChild: "",
        },
        {
            title: "감정가",
            key: "est_price",
            selected: false,
            selectedChild: "",
        },
        {
            title: "최저입찰가",
            key: "min_price",
            selected: false,
            selectedChild: "",
        },
    ]);

    useEffect(() => {
        const nextState = produce(tabButtons, (draft) => {
            draft.forEach((item) => {
                const selectedStrings = isEmpty(getString(filterState, item.key)) ? "" : getString(filterState, item.key);

                if (isEmpty(selectedStrings)) {
                    item.selectedChild = "";
                }

                if (!isEmpty(selectedStrings) || item.key === "use" || item.key === "loc") {
                    if (item.key === "type") {
                        const selectedString = `${selectedStrings[0]}`;
                        item.selectedChild = selectedString;
                    }

                    if (item.key === "loc") {
                        const selectedString = isEmpty(filterState.loc) ? "" : "소재지";

                        item.selectedChild = selectedString;
                    }

                    if (item.key === "use") {
                        const selectedString = isEmpty(filterState.use) ? "" : "용도";
                        item.selectedChild = selectedString;
                    }

                    if (item.key === "counter_force") {
                        const selectedString = `${selectedStrings[0]}`;
                        item.selectedChild = selectedString;
                    }

                    if (item.key === "auction_master_item") {
                        const selectedString = `${selectedStrings[0]}`;

                        if (selectedStrings.length === 1) {
                            item.selectedChild = `${selectedString} ${filterState.auction_master_except ? "제외" : ""}`;
                        }

                        if (selectedStrings.length !== 1) {
                            item.selectedChild = `${selectedStrings[0]} 외 ${selectedStrings.length - 1} ${
                                filterState.auction_master_except ? "제외" : ""
                            } `;
                        }
                    }

                    if (item.key === "status") {
                        const selectedString = `${selectedStrings[0]}`;

                        if (selectedStrings.length === 1) {
                            item.selectedChild = selectedString;
                        }

                        if (selectedStrings.length !== 1) {
                            item.selectedChild = `${selectedStrings[0]} 외 ${selectedStrings.length - 1} `;
                        }
                    }

                    if (item.key === "skp_type") {
                        const selectedString = `${selectedStrings[0]}`;
                        const isEmptySkipCount = filterState.skp_cnt.every((item) => item === "" || item === "0");
                        if (selectedString === "유찰" && !isEmptySkipCount) {
                            item.selectedChild = `${selectedString} ${filterState.skp_cnt[0]} ~ ${filterState.skp_cnt[1]}`;
                            return;
                        }
                        item.selectedChild = selectedString;
                    }

                    if (item.key === "due_date") {
                        const selectedString = `${selectedStrings[0]}`;
                        const removeString = selectedString.replace("매각기일", "").replaceAll(" ", "");
                        const splitRemoveString = removeString.split("~");
                        const formatStartDate = splitRemoveString[0].substr(2);
                        const formatEndDate = splitRemoveString[1].substr(2);

                        item.selectedChild = `${formatStartDate} ~ ${formatEndDate}`;
                        if (formatStartDate === formatEndDate) {
                            item.selectedChild = `${formatStartDate}`;
                        }
                    }

                    if (item.key === "est_price") {
                        if (selectedStrings[0] === "300억 ~") {
                            item.selectedChild = selectedStrings[0];
                        } else {
                            item.selectedChild = numberToKorean(selectedStrings[0]);
                        }
                    }

                    if (item.key === "min_price") {
                        if (selectedStrings[0] === "300억 ~") {
                            item.selectedChild = selectedStrings[0];
                        } else {
                            item.selectedChild = numberToKorean(selectedStrings[0]);
                        }
                    }
                }
            });
        });

        setTabButtons(nextState);
    }, [filterState]);

    const TabOnclick = (title: string) => {
        if (title === buttonState) {
            setFilterVisible(false);
            setButtonState("");
        } else {
            setFilterVisible(true);
            setButtonState(title);
        }
    };

    useEffect(() => {
        if (isDisableFilter) {
            setFilterVisible(false);
            setButtonState("");
        }
    }, [isDisableFilter]);

    return (
        <>
            <StlyedContainer isShowFilter={isShowFilter} type={type}>
                {isDisableFilter ? (
                    <div css={DisableAllFilter}>
                        <button onClick={() => setFilterVisible(false)}>
                            <img src={ico_filter_gray} />
                            <span>전체 필터</span>
                        </button>
                    </div>
                ) : (
                    <Link to="/filter" state={routePath}>
                        <button css={AllFilter} onClick={() => setFilterVisible(false)}>
                            <img src={ico_filter_white} />
                            <span>전체 필터</span>
                        </button>
                    </Link>
                )}
                <div className="filter_button_scroll_wrapper">
                    {tabButtons.map((item, index) => (
                        <TabButton
                            key={`${item}_${index}`}
                            isSelected={item.title === buttonState || item.selectedChild !== ""}
                            onClick={() => {
                                // setFilterVisible(true);
                                // setButtonState(item.title);
                                TabOnclick(item.title);
                            }}
                            disabled={isDisableFilter}
                        >
                            <span>{item.selectedChild === "" ? item.title : item.selectedChild}</span>
                        </TabButton>
                    ))}
                </div>

                {renderFilter(buttonState)}
                {resetModalVisible ? (
                    <AiResetConfirmModal
                        text="매물필터를 초기화할까요?"
                        onClickCancel={() => setResetModalVisible(false)}
                        onClickConfirm={onClickReset}
                    />
                ) : (
                    <></>
                )}
            </StlyedContainer>
        </>
    );
};

export default OutFilter;

export const Dim = styled.div<{ visible: boolean; zIndex?: number }>`
    position: fixed;
    display: none;
    ${({ visible, zIndex }) =>
        visible &&
        css`
            display: block;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: ${zIndex || 1};
            animation: ${appearBottomSheetBackground} 0.2s linear forwards;
        `};
`;

const FilterContainer = styled.div<{ visible: boolean }>`
    background: white;
    position: absolute;
    display: ${({ visible }) => (visible ? "block" : "none")};
    top: 104px;
    left: 0px;
    width: 100%;
    max-height: 470px;
    z-index: 2;

    button + button {
        margin-left: 0px;
    }
`;

const StlyedContainer = styled.div<{ isShowFilter: boolean | undefined; type: string | undefined }>`
    display: flex;
    align-items: center;
    height: ${(props) => (props.isShowFilter && props.type === "map" ? "56px" : props.isShowFilter ? "48px" : "0px")};
    opacity: ${(props) => (props.isShowFilter ? 1 : 0)};
    visibility: ${(props) => (props.isShowFilter ? "visible" : "hidden")};
    transition: all 0.2s ease;
    z-index: 3;

    button + button {
        margin-left: 8px;
    }

    & > a {
        height: ${(props) => (props.isShowFilter ? "32px" : "0px")};
        & > button {
            opacity: ${(props) => (props.isShowFilter ? 1 : 0)};
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);
            visibility: ${(props) => (props.isShowFilter ? "visible" : "hidden")};
            height: ${(props) => (props.isShowFilter ? "30px !important" : "0px")};
            transition: all 0.2s ease;
        }
    }
    .filter_button_scroll_wrapper {
        display: flex;
        overflow-x: scroll;
        z-index: 2;
        height: ${(props) => (props.isShowFilter ? "32px" : "0px")};
        opacity: ${(props) => (props.isShowFilter ? 1 : 0)};
        visibility: ${(props) => (props.isShowFilter ? "visible" : "hidden")};
        transition: all 0.2s ease;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        ::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
        }
    }
`;

const TabButton = styled.button<{ isSelected: boolean; disabled: boolean }>`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 24px;
    box-shadow: ${({ disabled }) => (disabled ? 0 : "1px 2px 4px rgba(0, 0, 0, 0.14)")};
    padding: 8px 16px 8px 16px;
    background: ${({ isSelected, disabled }) =>
        // disabled ? `${Common.colors.grey02}` : isSelected ? ` ${Common.colors.aucMarkerColor}` : `${Common.colors.bgBlue06}`};
        disabled ? `${Common.colors.grey02}` : isSelected ? ` ${Common.colors.aucMarkerColor}` : `${Common.colors.white}`};
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isSelected, disabled }) =>
            disabled ? `${Common.colors.grey04}` : isSelected ? ` ${Common.colors.white}` : `${Common.colors.normalTextColor}`};
    }
`;

const AllFilter = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: ${Common.colors.aucMarkerColor};
    min-width: 102px;
    border-radius: 24px;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.white};
    }

    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const DisableAllFilter = css`
    display: flex;
    height: 32px;
    /* background: ${Common.colors.grey02}; */
    min-width: 102px;
    border-radius: 24px;
    & > button {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        background: ${Common.colors.grey02};
        min-width: 102px;
        border-radius: 24px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.grey04};
    }

    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const Border = styled.div`
    border: 1px solid #eceff4;
    margin-top: 18px;
`;
