import { css } from "@emotion/react";
import ToastMessage from "@src/components/filter/ToastMessage";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { ISubscribeRefundAmount } from "@src/hooks/subscribe/useSubscribe";
import { transferToNative } from "@src/lib/WebAppBridge";
import { differenceInDays, format, set } from "date-fns";
import React from "react";

interface Props {
    data?: ISubscribeRefundAmount;
    showRefundDataModal: boolean;
    onChangeRefundData: (val: boolean) => void;
    onSubmitRemoveSubs: () => void;
    showToast: boolean;
    onCloseToast: (val: boolean) => void;
}

const UnsubsribeNow: React.FC<Props> = ({
    data,
    showRefundDataModal,
    onChangeRefundData,
    onSubmitRemoveSubs,
    showToast,
    onCloseToast,
}) => {
    const refundData = () => {
        return (
            <div css={modalContentStyle}>
                <p className="titleText">구독 환불하기</p>
                <p className="titleDescText">환불금액을 확인해주세요</p>
                <div className="priceText">
                    <p>구독 결제금액</p>
                    <p>{data?.paidPrice?.toLocaleString()}원</p>
                </div>
                {data?.penaltyType === 1 && (
                    <div className="priceText">
                        <p>취소 위약금 (결제액 30%)</p>
                        <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                    </div>
                )}
                {data?.penaltyType === 2 && (
                    <div className="priceText">
                        <p>취소 위약금 (정가 10%)</p>
                        <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                    </div>
                )}
                {data?.penaltyType === 3 && (
                    <div className="priceText">
                        <p>취소 위약금 (정가 30%)</p>
                        <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                    </div>
                )}
                {data?.penaltyType === 4 && (
                    <div className="priceText">
                        <p>취소 위약금 (정가 20%)</p>
                        <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                    </div>
                )}
                <div className="priceText usedText">
                    <p>구독 사용금액</p>
                    <p>{data?.usedDaysPrice?.toLocaleString()}원</p>
                </div>
                <div className="refundText">
                    <p>총 환불금액</p>
                    <p>{data?.refundPrice?.toLocaleString()}원</p>
                </div>
            </div>
        );
    };
    return (
        <>
            <div css={rootBoxStyle}>
                <div css={subsInfoBoxStyle}>
                    <p className="titleText">⏱ 내 잔여 구독기간</p>
                    <p className="contentText">
                        {format(new Date(data?.expiredAt ?? 0), "yy년 MM월 dd일까지")}
                        <br />({(data?.totalDays ?? 0) - (data?.usedDays ?? 0)}일 남음)
                    </p>
                </div>
                <div css={descBoxStyle}>
                    <p>🤚 잠시만요</p>
                    <p>
                        <span className="discountText">{data?.benefitPrice?.toLocaleString()}원 할인혜택</span>을 받고있어요
                    </p>
                </div>
                <div css={priceInfoBoxStyle}>
                    <div className="productPrice">
                        <p>정가</p>
                        <p>{data?.productPrice?.toLocaleString()}원</p>
                    </div>
                    <div className="discountPrice">
                        <p>할인 금액</p>
                        <p>{data?.benefitPrice?.toLocaleString()}원</p>
                    </div>
                    <div className="paidPrice">
                        <p>내 구독 결제금액</p>
                        <p>{data?.paidPrice?.toLocaleString()}원</p>
                    </div>
                </div>
                <div css={descBoxStyle}>
                    <p>😱 지금 해지하시면 약관에 따라</p>
                    <p>
                        <span className="penaltyText">
                            {(data?.usedDaysPrice ?? 0) + (data?.penaltyPrice ?? 0) >= (data?.paidPrice ?? 0)
                                ? data?.paidPrice?.toLocaleString()
                                : ((data?.usedDaysPrice ?? 0) + (data?.penaltyPrice ?? 0))?.toLocaleString()}
                            원이 공제
                        </span>
                        되고 환불됩니다
                    </p>
                </div>
                <div css={priceInfoBoxStyle}>
                    <p>※ 공제액</p>
                    {data?.penaltyType === 1 && (
                        <div className="penaltyPrice">
                            <p>취소 위약금 (결제액 30%)</p>
                            <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                        </div>
                    )}
                    {data?.penaltyType === 2 && (
                        <div className="penaltyPrice">
                            <p>취소 위약금 (정가 10%)</p>
                            <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                        </div>
                    )}
                    {data?.penaltyType === 3 && (
                        <div className="penaltyPrice">
                            <p>취소 위약금 (정가 30%)</p>
                            <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                        </div>
                    )}
                    {data?.penaltyType === 4 && (
                        <div className="penaltyPrice">
                            <p>취소 위약금 (정가 20%)</p>
                            <p>{data?.penaltyPrice?.toLocaleString()}원</p>
                        </div>
                    )}
                    <div className="productPrice">
                        <p>구독 사용금액</p>
                        <p>{data?.usedDaysPrice?.toLocaleString()}원</p>
                    </div>
                </div>
                <div css={footerStyle}>
                    <p>사용금액은 정가기준으로 일할계산하여 사용하신 일수만큼 공제됩니다.</p>
                </div>
                <button css={containButtonStyle} onClick={() => transferToNative(false, "closeUnsubscribeView")}>
                    유지하기
                </button>
                <p css={unSubsTextButtonStyle} onClick={() => onChangeRefundData(true)}>
                    즉시 해지하기
                </p>
            </div>
            {showRefundDataModal && (
                <ConfirmModal
                    resultMessage={refundData()}
                    onConfirm={() => {
                        onChangeRefundData(false);
                        onSubmitRemoveSubs();
                    }}
                    onCancel={() => onChangeRefundData(false)}
                    buttonText="최종 환불하기"
                />
            )}
            <ToastMessage
                visible={showToast}
                setToastVisible={() => {
                    onCloseToast(false);
                }}
                addStyles={{ top: "unset", bottom: "50px" }}
            >
                구독 해지가 완료 되었습니다.
            </ToastMessage>
        </>
    );
};

export default UnsubsribeNow;

const rootBoxStyle = css`
    padding: 0 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    gap: 24px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 24px;
    height: 100dvh;
    height: 100vh;
`;

const subsInfoBoxStyle = css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #dff0ff;
    padding: 14px;
    width: 100%;
    .titleText {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
    }
    .contentText {
        text-align: right;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
    }
`;

const descBoxStyle = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    .discountText {
        color: #0047ff;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
    .penaltyText {
        color: #f00000;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
`;

const priceInfoBoxStyle = css`
    border-radius: 8px;
    background-color: #f6faff;
    padding: 14px;
    gap: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .productPrice {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
    }
    .discountPrice {
        color: #0047ff;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        border-bottom: 0.6px solid #0c002c;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px 14px;
    }
    .paidPrice {
        border-radius: 8px;
        background-color: #0047ff;
        padding: 14px 24px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .penaltyPrice {
        color: #f00000;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 14px;
    }
`;

const footerStyle = css`
    color: #8d8d8d;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    background-color: #f7f8fa;
    padding: 8px 14px;
`;

const containButtonStyle = css`
    margin-top: 10px;
    padding: 14px;
    border-radius: 14px;
    background-color: #2275ee;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
`;

const unSubsTextButtonStyle = css`
    color: #8d8d8d;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-underline-position: under;
    cursor: pointer;
    margin-top: 10px;
    padding-bottom: 78px;
`;

const modalContentStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    .titleText {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    .titleDescText {
        margin-bottom: 6px;
    }
    .priceText {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .usedText {
        padding-bottom: 8px;
        border-bottom: 0.6px solid #8d8d8d;
    }
    .refundText {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
    }
`;
