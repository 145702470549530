import React from "react";
import { css } from "@emotion/react";
import { transferToNative } from "@src/lib/WebAppBridge";
import ConfirmModal from "../popup/ConfirmModal";

interface Props {
    open: number;
    onCloseView: () => void;
}

const DeungiPurchaseCheckDialog: React.FC<Props> = ({ open, onCloseView }) => {
    return (
        <>
            {!!open && (
                <ConfirmModal
                    onCancel={onCloseView}
                    onConfirm={() => {
                        transferToNative("msgaapp://deeplink/product/subscription", "moveToDeepLink");
                        onCloseView();
                    }}
                    cancelText="닫기"
                    buttonText="시작하기"
                    resultMessage={
                        <div css={contentBoxStyle}>
                            <p className="title">
                                {open === 2 ? "유료구독 회원만 구매 가능해요" : "유료구독 회원만 열람할 수 있어요"}
                            </p>
                            <p className="content">지금 구독을 시작하고 데이터맵과 맞춤형 매물 정보 열람을 이용해 보세요!</p>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default DeungiPurchaseCheckDialog;

const contentBoxStyle = css`
    & > .title {
        color: #0c002c;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    & > .content {
        margin-top: 8px;
        padding-bottom: 10px;
        color: #0c002c;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const buttonStyle = css`
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 14px;
`;
