import React from "react";

import icoTooltipInfo from "@src/assets/ico_tooltip_info.png";
import { TicketPackageListResponseDto } from "@src/hooks/deungi/dto/PackageList.dto";

interface DeungiPackageCardProps {
    title: string;
    packages: TicketPackageListResponseDto[];
    singleTicketImage: string;
    multipleTicketsImage?: string;
    active: boolean;
    onTitleClick: () => void;
    onPackageClick: (data: TicketPackageListResponseDto) => void;
    onScroll: (ref: HTMLElement | null) => void;
}

const DeungiPackageCard = ({
    title,
    packages,
    singleTicketImage,
    multipleTicketsImage,
    active,
    onTitleClick,
    onPackageClick,
    onScroll,
}: DeungiPackageCardProps) => {
    return (
        <section className={active ? "packageBox active" : "packageBox"} ref={(ref) => onScroll(ref)}>
            <div className={"titleBox"} onClick={onTitleClick}>
                <h3>{title}</h3>
                <img src={icoTooltipInfo} />
            </div>
            {packages.length > 0 &&
                packages.map((el) => {
                    return (
                        <article key={el.code} id={el.code} className="items" onClick={() => onPackageClick(el)}>
                            <div className="imageBox">
                                {multipleTicketsImage ? (
                                    <img src={el.quantity > 1 ? multipleTicketsImage : singleTicketImage} />
                                ) : (
                                    <img src={singleTicketImage} />
                                )}
                            </div>
                            <div>
                                <span>{el.name}</span>
                                {el.discountString && <small>{el.discountString}</small>}
                            </div>
                            <span className="price">{el.discountPrice.toLocaleString()}원</span>
                        </article>
                    );
                })}
        </section>
    );
};

export default DeungiPackageCard;
