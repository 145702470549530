import { css } from '@emotion/react';
import { useState } from 'react';
import pencil from '../../../src/assets/storeGoStop/pencil.svg';
import convertNumberToKorean from '@src/util/convertNumToKorean';
import React from 'react';

interface StoreGoStopDetailInfoInputProps {
    label: string;
    value: any;
    onChange: (e: any) => void;
    onKeyUp: (val: boolean) => void;
}

const StoreGoStoopCalInput = ({ label, value, onChange, onKeyUp }: StoreGoStopDetailInfoInputProps) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const onFocus = (e: any) => {
        setIsFocus(true);
        onKeyUp(true);
    };
    const onBlur = () => {
        setIsFocus(false);
        onKeyUp(false)
    };

    return (
        <>
            <div css={inputWrapStyle}>
                <span>{label}</span>
                <input
                    inputMode="numeric"
                    type="text"
                    placeholder="직접 입력해보세요"
                    css={goStopInputStyle}
                    value={
                        isFocus
                            ? value
                            : convertNumberToKorean(Number(value + '0000')).length > 0
                                // ? Number(value - value % 10000) < 10000
                                //     ? value
                                //     : convertNumberToKorean(Number(value - value % 10000))
                                ? convertNumberToKorean(Math.floor(Number(value * 10000)))
                                : '0'
                    }
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <img src={pencil} alt="pencil" />
            </div>
        </>
    );
};
export default StoreGoStoopCalInput;

const goStopInputStyle = css`
    border: none;
    width: 70%;
    background-color: #fbf8f8;
    height: 42px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 2.75rem 0 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: right;
    outline: none;
`;

const inputWrapStyle = css`
    width: 100%;
    box-sizing: border-box;
    padding: 4px 0.825rem;
    gap: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        white-space: nowrap;
    }
    & > img {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 13px;
        right: 22px;
    }
`;
