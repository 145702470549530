import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

const fetchData = async () => {
    const response = await axiosInstance.get(`${axiosPath.searchedList}?name=mapUserLastPosition`);
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response?.data?.payload.data || [];
    const value = responseData.length !== 0 ? JSON.parse(responseData[0].value) : undefined;
    return value;
};

const useGetUserLastPosition = async () => {
    return await fetchData();
};

const usePostUserLastPosition = async (params: { zoom: number; lat: number; lng: number }) => {
    const PARAMS = JSON.stringify(params);
    const response = await axiosInstance.post(`${axiosPath.searchedList}`, { name: "mapUserLastPosition", value: PARAMS });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
};

export { useGetUserLastPosition, usePostUserLastPosition };
