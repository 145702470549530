import { css } from "@emotion/react";
import { IAiRecommendList } from "@src/hooks/aiRecommend/useAiRecommend";
import React, { Fragment, useRef } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from "react-query";
import Lottie from "lottie-react";
import loading from "@src/assets/Loading.json";
import AiBottomSheetItem from "@src/components/aiFilter/AiBottomSheetItem";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useAiRecommendFilterStore, useUserAiSettingSotre } from "@src/store/AiRecommendFilterStore";

interface Props {
    content: any;
    data?: InfiniteData<IAiRecommendList>;
    hasNextPage: boolean;
    isFetching: boolean;
    fetchNextPage: (
        options?: FetchNextPageOptions | undefined,
    ) => Promise<InfiniteQueryObserverResult<IAiRecommendList, unknown>>;
}

const AiRecommendListBox = ({ content, data, hasNextPage, isFetching, fetchNextPage }: Props) => {
    const userAiSettingFilterState = useUserAiSettingSotre((state) => state.userAiSettingState);
    const scrollTopRef = useRef<HTMLDivElement | null>(null);

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 3px 0px",
        delayInMs: 100,
    });

    return (
        <>
            <div ref={content} css={rootBoxStyle}>
                {data ? (
                    <div css={listBoxStyle} ref={scrollTopRef}>
                        {data?.pages?.map((page) =>
                            page?.totalCount === 0 ? (
                                <div key={page.cursor} css={noDataBoxStyle}>
                                    조건에 맞는 매물이 없습니다.
                                </div>
                            ) : (
                                page?.list?.map((item: any) => (
                                    <AiBottomSheetItem
                                        onClick={() =>
                                            transferToNative(
                                                JSON.stringify({ item_id: item.itemId, bid_type: item.bidType }),
                                                "onClickItem",
                                            )
                                        }
                                        key={JSON.stringify(item)}
                                        item={item}
                                    />
                                ))
                            ),
                        )}
                        {data?.pages?.[0]?.totalCount > 20 && <div ref={loadingRef} css={loadMoreBoxStyle} />}
                    </div>
                ) : (
                    <></>
                )}
                {isFetching && (
                    <div css={lottieBoxStyle}>
                        <Lottie animationData={loading} className="lottie" />
                    </div>
                )}
            </div>
        </>
    );
};

export default AiRecommendListBox;

const rootBoxStyle = css`
    overflow: hidden auto;
    -webkit-overflow-scrolling: touch;
    padding: 0px 14px;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    height: calc(100dvh - 170px);
`;

const loadMoreBoxStyle = css`
    padding: 20px;
    margin-top: -430px;
`;

const listBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

const lottieBoxStyle = css`
    .lottie {
        width: 80px;
        margin: 0 auto;
    }
`;

const noDataBoxStyle = css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;
