import styled from "@emotion/styled";
import OutFilter from "@src/components/filter/OutFilter";
import ListSearchInput from "@src/components/input/ListSearchInput";
import { transferToNative } from "@src/lib/WebAppBridge";
import React, { useState } from "react";
import hideFilter from "../../assets/hideFilter.png";
import showFilter from "../../assets/showFilter.png";
import { useFilterStore } from "@src/store/filterStore";

interface Props {}

const MapFilter: React.FC<Props> = () => {
    const [isBackgroundVisible, setIsBackgroundVisible] = useState<boolean>(false);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const [searchTextFromApp, setSearchTextFromApp] = useState("");
    const setFilterVisible = useFilterStore((state) => state.setFilterVisible);
    const setDimVisible = useFilterStore((state) => state.setDimVisible);
    return (
        <>
            <StyledFilterWrap isBackgroundVisible={isBackgroundVisible} isShowFilter={isShowFilter}>
                <div className="searchWrap">
                    <div className="imgDiv">
                        <img
                            src={isShowFilter ? hideFilter : showFilter}
                            alt=""
                            onClick={() => {
                                setIsShowFilter(!isShowFilter);
                                setFilterVisible(false);
                                setDimVisible(false);
                                setIsBackgroundVisible(false);
                            }}
                        />
                    </div>
                    <div
                        className="inputWrap"
                        onClick={() => {
                            // setIsMarkerHide({
                            //     product: false,
                            //     real: false,
                            // });
                            const param = { search_text: searchTextFromApp };
                            transferToNative(JSON.stringify(param), "showSearchView");
                        }}
                    >
                        <ListSearchInput keyword={searchTextFromApp} />
                    </div>
                </div>
                <OutFilter setIsBackgroundVisible={setIsBackgroundVisible} isShowFilter={isShowFilter} type={"map"} />
            </StyledFilterWrap>
        </>
    );
};

export default MapFilter;

const StyledFilterWrap = styled.div<{ isBackgroundVisible: boolean; isShowFilter: boolean }>`
    /* position: fixed;
    z-index: 991; */
    width: 100%;
    padding: 0px 16px;
    background-color: ${(props) => (props.isBackgroundVisible ? "#FFFFFF" : `rgba(255, 255, 255, 0)`)};
    /* background-color: rgba(255, 255, 255, 0); */
    height: ${(props) => (props.isBackgroundVisible ? "calc(var(--vh, 1vh) * 100)" : "fit-content")};
    input {
        background-color: #fff;
    }
    & > .searchWrap {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 8px;
        & > .inputWrap {
            width: 100%;
            & > div {
                height: 48px;
                & > input {
                    height: 40px;
                }
                & > div {
                    top: calc(50% - 10px);
                    width: 20px;
                    height: 20px;
                }
            }
        }
        & > .imgDiv {
            width: 40px;
            height: 40px;
            box-sizing: border-box;
            img {
                width: 40px;
                height: 40px;
                box-sizing: border-box;
            }
        }
    }
`;
