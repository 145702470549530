import styled from "@emotion/styled";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import { Common } from "@src/styles/Common";
import React from "react";

type AiFooterPropsType = {
    isPrev?: boolean;
    isReset?: boolean;
    isNext?: boolean;
    isShowList?: boolean;
    onClickNext?: () => void;
    onClickReset?: () => void;
    onClickPrev?: () => void;
    onClickShowList?: () => void;
    style: any;
};

const AiFooter = ({
    isPrev,
    isReset,
    isNext,
    isShowList,
    onClickNext,
    onClickReset,
    onClickPrev,
    onClickShowList,
    style,
}: AiFooterPropsType) => {
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const AiFilterDataList = useAiFilterStore((state) => state.aiFilterDataList);
    return (
        <Container style={style}>
            {isReset && (
                <button onClick={onClickReset} className="reset">
                    전체 초기화
                </button>
            )}
            {isPrev && (
                <button onClick={onClickPrev} className="prev">
                    이전
                </button>
            )}
            {isNext && (
                <button
                    onClick={onClickNext}
                    style={{ flex: !isPrev ? 2 : 1 }}
                    className={!AiFilterDataList.length ? "disabled" : "next"}
                    disabled={!AiFilterDataList.length}
                >
                    다음
                </button>
            )}
            {isShowList && (
                <button
                    onClick={onClickShowList}
                    className={!AiFilterDataList.length ? "disabled" : "showList"}
                    disabled={!AiFilterDataList.length}
                >
                    매물보기
                </button>
            )}
        </Container>
    );
};

export default AiFooter;

const Container = styled.div`
    position: absolute;
    width: calc(100% - 2px);
    margin-left: -13px;
    display: flex;
    flex: 1;
    row-gap: 12px;
    padding: 14px 12px;
    border-top: 6px solid #f0f0f0;
    background-color: #ffffff;
    button {
        flex: 1;
        height: 54px;
        border-radius: 14px;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
    }

    button + button {
        margin-left: 12px;
    }
    .reset {
        background: #fbf8f8;
    }

    .prev {
        background: ${Common.colors.bgMint};
    }

    .next {
        background: ${Common.colors.aucMarkerColor};
        color: white;
    }
    .showList {
        background: ${Common.colors.aucMarkerColor};
        color: white;
    }
    .disabled {
        background: #dadada;
        color: #0c002c;
    }
`;
