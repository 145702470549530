import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { ISimpleLandData } from "@src/hooks/map/useSimpleLandData";
import ClearIcon from "@src/assets/basicIcon/blackCloseIcon.svg";
import { isShowLabel } from "@src/util/isShowBottomSheetLabel";

interface Props {
    index: number;
    data: ISimpleLandData;
    onDelete: (index: number) => void;
}

const MeasureLandCard: React.FC<Props> = ({ index, data, onDelete }) => {
    const [viewData, setViewData] = useState<any>({});

    useEffect(() => {
        if (!data?.building) return;
        const { origin } = data.building;
        const { total, single } = origin;
        if (!total || !Object.keys(total).length) return matchCard(single, false);
        matchCard(total, true);
    }, [data]);

    const matchCard = (item: any, isTotal: boolean) => {
        // 총괄표제부, 표제부 데이터 모두 없을 경우 토지카드 노출
        if (isTotal) {
            if (item.length !== 0) {
                if (Array.isArray(item)) {
                    setViewData(item[0]);
                } else {
                    setViewData(item);
                }
            }
        } else {
            if (item.length !== 0) {
                if (Array.isArray(item)) {
                    const arr = item.filter((v) => v?.regstrKindCdNm.includes("집합"));
                    if (!arr[0]) {
                        setViewData(item[0]);
                    } else {
                        setViewData(arr[0]);
                    }
                } else {
                    setViewData(item);
                }
            }
        }
    };

    return (
        <>
            <div css={rootBoxStyle(!!data?.roadAddress)}>
                <div css={topContentBoxStyle(!!data?.roadAddress)}>
                    <div css={addressBoxStyle}>
                        <div className="addressBox">
                            <div className="index">
                                <p>{index + 1}</p>
                            </div>
                            <p className="address">{data?.address}</p>
                        </div>
                        <button onClick={() => onDelete(index)} className="closeBtn">
                            <img src={ClearIcon} alt="" />
                        </button>
                    </div>
                    <div css={roadAddressBoxStyle}>
                        {data?.roadAddress && (
                            <>
                                <p className="name">도로명</p>
                                <p>{data?.roadAddress}</p>
                            </>
                        )}
                    </div>
                </div>
                <div css={bottomContentBoxStyle}>
                    {data?.fieldType === "land" ? (
                        <>
                            <div>
                                <p>{data?.land?.lndcgrCodeNm}</p>
                                <p>{data?.land?.use}</p>
                            </div>
                            <div>
                                <p>토지면적 {data?.size?.pyong ?? "-"}</p>
                                <p>{data?.land?.owner}</p>
                            </div>
                        </>
                    ) : data?.building?.buildingType === 1 ? (
                        <>
                            <div>
                                {isShowLabel(data?.land?.use) && <p>{data?.land?.use}</p>}
                                {isShowLabel(data?.building?.buildingNm) && <p> {data?.building?.buildingNm}</p>}
                                {isShowLabel(data?.building?.mainUse) && <p> {data?.building?.mainUse}</p>}
                                {isShowLabel(data?.building?.useAprDay) && <p> 준공년도 {data?.building?.useAprDay}</p>}
                            </div>
                            <div>
                                {isShowLabel(data?.building?.platArea?.split(".")?.[0]) ? (
                                    <p>대지면적 {data?.building?.platArea}</p>
                                ) : (
                                    isShowLabel(data?.size?.pyong ?? "") && <p>대지면적 {data?.size?.pyong}</p>
                                )}
                                {/* {isShowLabel(data?.building?.totArea?.split(".")?.[0]) && ( */}
                                {isShowLabel(data?.building?.totArea) && <p>연면적 {data?.building?.totArea}</p>}
                                <p>
                                    층수({data?.building?.grndFlrCnt}/{data?.building?.ugrndFlrCnt})
                                </p>
                            </div>
                        </>
                    ) : data?.building?.buildingType === 2 ? (
                        <>
                            <div>
                                {isShowLabel(data?.land?.use) && <p>{data?.land?.use}</p>}
                                {isShowLabel(data?.building?.buildingNm) && <p> {data?.building?.buildingNm}</p>}
                                {isShowLabel(data?.building?.mainUse) && <p> {data?.building?.mainUse}</p>}
                                {isShowLabel(data?.building?.useAprDay) && <p> 준공년도 {data?.building?.useAprDay}</p>}
                            </div>
                            <div>
                                {isShowLabel(data?.size?.pyong ?? "") ? (
                                    <p>토지면적 {data?.size?.pyong}</p>
                                ) : (
                                    isShowLabel(data?.land?.size) && <p>토지면적 {data?.land?.size} </p>
                                )}
                                {isShowLabel(data?.building?.totArea) && <p>연면적 {data?.building?.totArea}</p>}
                                <p>
                                    층수({data?.building?.grndFlrCnt}/{data?.building?.ugrndFlrCnt})
                                </p>
                            </div>
                        </>
                    ) : data?.building?.buildingType === 3 ? (
                        <>
                            <div>
                                {isShowLabel(viewData?.bldNm) && <p> {viewData?.bldNm} </p>}
                                {isShowLabel(data?.land?.use) && <p>{data?.land?.use}</p>}
                            </div>
                            <div>
                                {isShowLabel(viewData?.useAprDay) && (
                                    <p>준공년도 {viewData?.useAprDay.toString().replace(/(\d{4})(\d{4})/g, "$1년")} </p>
                                )}
                                {isShowLabel(viewData?.hhldCnt) && <p> {viewData?.hhldCnt} </p>}
                                {isShowLabel(viewData?.hoCnt) && <p> {viewData?.hoCnt} </p>}
                                <p>
                                    층수({data?.building?.grndFlrCnt}/{data?.building?.ugrndFlrCnt})
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <p>{data?.land?.lndcgrCodeNm}</p>
                                <p>{data?.land?.use}</p>
                            </div>
                            <div>
                                <p>토지면적 {data?.size?.pyong ?? "-"}</p>
                                <p>{data?.land?.owner}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MeasureLandCard;

const rootBoxStyle = (hasRoadAddress: boolean) => css`
    border-radius: 4px;
    background-color: #ffffff;
    padding: 14px;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    align-items: center;
    width: 100%;
    height: 152px;
    /* height: 140px; */
    flex-direction: column;
    gap: ${hasRoadAddress ? "12px" : "14px"};
    box-sizing: border-box;
    /* overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    } */
`;

const topContentBoxStyle = (hasRoadAddress: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    flex-direction: column;
    padding-bottom: ${hasRoadAddress ? "8px" : "14px"};
    border-bottom: 0.6px solid #b1aeb8;
`;

const addressBoxStyle = css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    & > .addressBox {
        display: flex;
        align-items: flex-start;
        gap: 4px;
        & > .index {
            margin-top: 1px;
            border-radius: 50%;
            background-color: #2275ee;
            color: #ffffff;
            font-size: 13px;
            font-weight: 700;
            line-height: 20px;
            width: 21px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                padding-top: 1px;
            }
        }
        & > .address {
            color: #0c002c;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
        }
    }
    & > .closeBtn {
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const roadAddressBoxStyle = css`
    display: flex;
    align-items: baseline;
    gap: 4px;
    width: 100%;
    & > .name {
        border-radius: 4px;
        border: 1px solid #fe513f;
        padding: 0 4px;
        color: #fe513f;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        white-space: nowrap;
    }
    white-space: nowrap;
    color: #0c002c;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
`;

const bottomContentBoxStyle = css`
    width: 100%;
    color: #505050;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        line-height: 15px;
    }
`;
