import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import deungi_alarm_setting from "@src/assets/deungi/deungi_alarm_setting.png";
import deungi_alarm_banner from "@src/assets/deungi/deungi_alarm_banner_02.png";
import deungi_alarm_banner_arrow from "@src/assets/deungi/deungi_alarm_banner_arrow.png";
import deungi_alarm_list_ico_01 from "@src/assets/deungi/deungi_alarm_list_ico_01.png";
import ico_deungi_empty from "@src/assets/ico_deungi_empty.png";
import lottie_loading from "@src/assets/lottie_loading_deungi.json";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import { useDeungiChangeAlarmList } from "@src/hooks/deungi/useDeungiAlarm";
import dayjs from "dayjs";
import { DeungiAlarmLogsDto, DeungiAlarmResponseDto, DeungiAlarmSubListDto } from "@src/hooks/deungi/dto/DeungiAlarm.dto";
import { el } from "date-fns/locale";
import { DEUNGI_CHANGE_STATE, DEUNGI_POPUP_TYPE } from "./common/enum";
import { dateTimeFormat } from "./common/utils";
import ToastMessage from "./ToastMessage";
import DeungiPopup from "./DeungiPopup";
import { transferToNative } from "@src/lib/WebAppBridge";
import { css } from "@emotion/react";
import whitePlusIcon from "@src/assets/basicIcon/whitePlusIcon.svg";

const DeungiAlarmList = () => {
    const [isLoad, setIsLoad] = useState(false);
    const [message, setMessage] = useState("");
    const [popupData, setPopupData] = useState({});
    const [popupToggle, setPopupToggle] = useState(false);
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.ALARM_OVER_3);
    const [bannerAlarmList, setBannerAlarmList] = useState([] as DeungiAlarmLogsDto[]);
    const [alarmTotalCount, setAlarmTotalCount] = useState(0);
    const [alarmList, setAlarmList] = useState([] as DeungiAlarmResponseDto[]);
    const [periodText, setPeriodText] = useState<string>("");
    const [boxShadow, setBoxShadow] = useState(false);
    const [rollingIdx, setRollingIdx] = useState(0);
    const navigate = useNavigate();

    const getAlarmList = async () => {
        const res = await useDeungiChangeAlarmList();

        if (res.data && res.data.list.length > 0) {
            setAlarmList(() => {
                return res.data.list.filter(
                    (el: DeungiAlarmResponseDto) =>
                        dayjs(el.expired_at) > dayjs() &&
                        el.deleted_at === null &&
                        [DEUNGI_CHANGE_STATE.CHANGE, DEUNGI_CHANGE_STATE.INVALID_OWNER].includes(el.change_state),
                );
            });
            setPeriodText(res.data.periodText);
            setAlarmTotalCount(res.data.totalCnt);
        } else {
            setAlarmList([]);
            setAlarmTotalCount(0);
        }

        setIsLoad(true);
    };

    const onDeungiOpenHandler = (id: string) => {
        const alarmInfo = alarmList.filter((el) => el._id === id);

        if (alarmInfo.length > 0) {
            setPopupData(alarmInfo[0]);
            setPopupType(DEUNGI_POPUP_TYPE.OPEN_WITH_ADDRESS);
            setPopupToggle(true);
        }
    };

    const onClickDeungiHandler = () => {
        transferToNative("", "showDeungiView");
    };

    const onClickOptionHandler = () => {
        navigate("?option=true");
    };

    const onClickSubmitHandler = () => {
        if (alarmTotalCount === 0) {
            navigate("/deungi/alarm/setting?isFirst=true");
        } else {
            if (alarmList.length >= 3) {
                setPopupType(DEUNGI_POPUP_TYPE.ALARM_OVER_3);
                setPopupToggle(true);
            } else {
                navigate("/deungi/alarm/search");
            }
        }
    };

    const getLabelColor = (state: string) => {
        switch (state) {
            case "접수완료":
            case "등기완료":
                return "blue";
            case "조사대기":
            case "각하":
            case "취하":
            case "소유자불일치":
                return "red";
            default:
                return "gray";
        }
    };

    useEffect(() => {
        /**
         * 초기 알림 내역 조회
         * 로딩 이후 페이지 컴포넌트를 판단한다
         * 1. 내역이 없는 경우 > 안내 페이지
         * 2. 내역이 있는 경우 > 확인중인 알람이 있는 경우 > 확인중 알람 내역 페이지
         * 3. 내역기 있는 경우 > 확인중인 알람이 없는 경우 > 알람 내역 없어요 페이지
         */
        getAlarmList();
    }, []);

    useEffect(() => {
        if (alarmList.length > 0) {
            const alarmLogs: DeungiAlarmLogsDto[] = [];

            alarmList.filter((el1: DeungiAlarmResponseDto) =>
                el1.alarm_logs?.map((el2: DeungiAlarmLogsDto) => {
                    if (dayjs(el2.inDate).add(7, "day").diff() >= 0) alarmLogs.push({ ...el2, _id: el1._id });
                }),
            );
            setBannerAlarmList(alarmLogs.sort((el1, el2) => +new Date(el2.inDate) - +new Date(el1.inDate)));
        }
    }, [alarmList]);

    const [type, setType] = useState<number>(1);

    return (
        <Container>
            <div css={buttonBoxStyle}>
                <button css={buttonStyle(type === 1)} onClick={() => setType(1)}>
                    <p>알림 신청</p>
                </button>
                <button css={buttonStyle(type === 2)} onClick={() => setType(2)}>
                    <p>알림 내역</p>
                </button>
            </div>
            <div className={"settingBtn"} onClick={onClickOptionHandler}>
                <img src={deungi_alarm_setting} />
            </div>
            {type === 1 && (
                <>
                    <NoList>
                        <div className="banner">
                            <img src={deungi_alarm_banner} />
                        </div>
                        <div className="contents">
                            <div style={{ animationDelay: "0.2s" }}>
                                <h3>회원이면 누구나 무료!</h3>
                            </div>
                            <div style={{ animationDelay: "0.4s" }}>
                                <h3>실시간으로 즉시 확인할 수 있어요</h3>
                            </div>
                            {/* <div style={{ animationDelay: "0.9s" }}>
                            <h3>신청 방법</h3>
                            <div className={"boxArrow"}>
                                <div>주소 등록</div>
                                <img src={deungi_alarm_banner_arrow} />
                                <div>소유자 입력</div>
                                <img src={deungi_alarm_banner_arrow} />
                                <div>신청 완료</div>
                            </div>
                            <p>
                                신청한 주소지에 변동이 발생하면 알림이 전송돼요
                                <br />
                                알림은 이메일이나 앱푸시로 받을 수 있어요
                            </p>
                        </div> */}
                        </div>
                    </NoList>
                </>
            )}
            {type === 2 && (
                <>
                    {!isLoad ? (
                        <div className={"loading"}>
                            <Lottie animationData={lottie_loading} />
                        </div>
                    ) : (
                        <AlarmList>
                            {/* {bannerAlarmList.length > 0 && ( */}
                            {alarmList.length !== 0 && (
                                <div className={boxShadow ? "rollingBox isScroll" : "rollingBox"} css={titleBoxStyle}>
                                    <p>
                                        {periodText} 발생한<span className={"highLight"}>등기변동</span> 이 있어요
                                    </p>
                                    {/* <div
                                className={"changedBox"}
                                onAnimationIteration={() => {
                                    setRollingIdx((prev) => (bannerAlarmList.length - 1 <= prev ? 0 : prev + 1));
                                }}
                            >
                                <div>
                                    <h3>
                                        <span>{bannerAlarmList[rollingIdx].purpose}</span> 되었어요
                                    </h3>
                                    <p>실시간 등기열람으로 변동을 확인하세요</p>
                                </div>
                                <button onClick={() => onDeungiOpenHandler(bannerAlarmList[rollingIdx]._id)}>열람하기</button>
                            </div> */}
                                </div>
                            )}
                            {/* )} */}
                            {alarmList.length === 0 ? (
                                <div className={"emptyBox"}>
                                    <img src={ico_deungi_empty} />
                                    <h3>확인된 등기변동이 없어요</h3>
                                    <p>신청내역은 우측 상단 설정 버튼 {">"} 신청내역에서 확인 가능해요</p>
                                </div>
                            ) : (
                                <div
                                    className={"alarmBox"}
                                    onScroll={(e: any) => {
                                        if (e.target?.scrollTop > 0) setBoxShadow(true);
                                        else setBoxShadow(false);
                                    }}
                                >
                                    {alarmList.map((el1: DeungiAlarmResponseDto, idx: number) => {
                                        return (
                                            <div
                                                key={el1._id}
                                                className={"alarmItem"}
                                                style={{ animationDelay: `${0.3 * idx}s` }}
                                            >
                                                <h4>
                                                    <img src={deungi_alarm_list_ico_01} />
                                                    {el1.address}
                                                </h4>
                                                {el1.list.length > 0 ? (
                                                    el1.list.map((el2: DeungiAlarmSubListDto) => {
                                                        return (
                                                            <div key={el2.id} className={"changedList"}>
                                                                <p>
                                                                    <label className={getLabelColor(el2.state)}>
                                                                        {el2.state}
                                                                    </label>
                                                                    <small>
                                                                        {el1.alarm_logs?.filter((log) => log.id === el2.id)[0]
                                                                            ?.receptionDate?.length
                                                                            ? dateTimeFormat(
                                                                                  new Date(
                                                                                      el1.alarm_logs?.filter(
                                                                                          (log) => log.id === el2.id,
                                                                                      )[0]?.receptionDate || el1.updated_at,
                                                                                  ),
                                                                              )
                                                                            : ""}
                                                                    </small>
                                                                </p>
                                                                <h3>
                                                                    <span css={stateInfoTextStyle(el2.purpose)}>
                                                                        {el2.purpose}
                                                                    </span>{" "}
                                                                    되었어요
                                                                </h3>
                                                                실시간 등기열람으로 변동을 확인하세요
                                                                <button onClick={() => onDeungiOpenHandler(el1._id)}>
                                                                    실시간 등기부등본 열람
                                                                </button>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className={"changedList"}>
                                                        <p>
                                                            <label className={"red"}>소유자불일치</label>
                                                            <small>{dateTimeFormat(el1.updated_at)}</small>
                                                        </p>
                                                        <h3>
                                                            <span css={stateInfoTextStyle("근저당권설정")}>등기변동이</span>{" "}
                                                            되었어요
                                                        </h3>
                                                        소유자가 일치하지 않아 자세한 정보를 알 수 없어요 <br />
                                                        설정 {">"} 신청내역에서 소유자를 수정해주세요
                                                        <button onClick={() => onDeungiOpenHandler(el1._id)}>
                                                            실시간 등기부등본 열람
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </AlarmList>
                    )}
                </>
            )}
            {type === 2 && (
                <button css={submitButtonStyle} onClick={onClickSubmitHandler}>
                    <img src={whitePlusIcon} alt="whitePlusIcon" width={20} height={20} />
                    알림추가
                </button>
            )}
            {type === 1 && (
                <button className={"submitBtn"} onClick={onClickSubmitHandler}>
                    등기변동 알림 신청
                </button>
            )}
            <DeungiPopup type={popupType} toggle={popupToggle} setToggle={setPopupToggle} data={popupData} />
            <ToastMessage message={message} setMessage={setMessage} />
        </Container>
    );
};

const AlarmList = styled.div`
    position: relative;
    animation: fadeIn 0.3s forwards;

    .alarmBox {
        height: calc(100dvh - 107px);
        overflow: scroll;
        margin-top: 6px;
        padding-bottom: 100px;
    }
    .alarmItem {
        opacity: 0;
        animation: moveUp 0.5s forwards;
    }
    .emptyBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 13px;
        line-height: 1.4;
        height: calc(100dvh - 210px);
        padding: 14px;
        word-break: keep-all;
        text-align: center;

        & > h3 {
            font-size: 16px;
            margin-bottom: 8px;
        }
        & > img {
            width: 110px;
            margin-bottom: 48px;
        }
    }
    .alarmItem,
    .rollingBox {
        /* border-bottom: 7px solid #edf0f6; */
        padding: 14px;
        font-size: 14px;
        transition: all 0.2s ease;
        &.isScroll {
            box-shadow: 0 5px 5px rgba(40, 40, 40, 0.3);
            z-index: 2;
        }
        & > h3 {
            margin: 7px 0 14px;
            font-size: 16px;
        }
        & > h4 {
            display: flex;
            align-items: flex-start;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
            line-height: 1.4;
            color: #0c0c0c;

            & > img {
                width: 18px;
                margin-right: 4px;
            }
        }
        & > .changedBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            background: #edf0f6;
            line-height: 1.6;
            border-radius: 4px;
            font-size: 13px;
            animation: alarmEffect 4s infinite;

            & > div {
                & > h3 {
                    font-size: 16px;

                    & > span {
                        color: #ff0000;
                    }
                }
                & > p {
                    color: #505050;
                }
            }
            & > button {
                height: fit-content;
                font-size: 14px;
                padding: 2px 4px;
                line-height: 1.6;
                border-radius: 4px;
                color: #fff;
                background: #2275ee;
            }
        }
        & > .changedList {
            margin-bottom: 8px;

            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }

            & > p {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 12px;
                margin-bottom: 8px;
                color: #0c002c;
                & > label {
                    border-radius: 24px;
                    padding: 4px;
                    margin-right: 4px;
                    background-color: #ffffff;
                    &.blue {
                        color: #0047ff;
                    }
                    &.red {
                        color: #ff0000;
                    }
                    &.gray {
                        color: #505050;
                    }
                }
            }
            /* & > div { */
            padding: 8px;
            border-bottom: 1px solid #c9c9c9;
            border-radius: 4px;
            background: #f7f8fa;
            line-height: 1.4;
            text-align: left;

            & > h3 {
                position: relative;
                margin: 7px 0 4px;
                font-size: 16px;
                z-index: 2;
            }
            & > button {
                width: 100%;
                padding: 14px;
                font-size: 14px;
                color: #fff;
                background: #0047ff;
                border-radius: 4px;
                margin-top: 14px;
            }
            /* } */
        }
    }
    .highLight {
        position: relative;
        color: #ff0000;

        &:after {
            content: "";
            width: 100%;
            height: 12px;
            display: inline-block;
            background: #faff00;
            position: absolute;
            bottom: -5px;
            left: 0;
            z-index: -1;
            transition: 0.2s all;
        }
    }
    @keyframes alarmEffect {
        0% {
            opacity: 0;
        }
        5% {
            transform: translateY(-20px) scale(0.7);
            opacity: 0;
        }
        20%,
        90% {
            transform: translateY(0) scale(1);
            opacity: 1;
        }
        100% {
            transform: translateY(5px);
            opacity: 0;
        }
    }
`;

const NoList = styled.div`
    position: relative;
    height: calc(100dvh - 56px);
    overflow: scroll;
    animation: fadeIn 0.3s forwards;
    padding-bottom: 100px;

    .banner {
        padding: 14px;
        & > img {
            width: 100%;
        }
    }
    .contents {
        & > div {
            position: relative;
            border: 1px solid #c9c9c9;
            color: #0c002c;
            background: #f6faff;
            padding: 7px;
            margin: 14px;
            border-radius: 4px;
            line-height: 1.4;
            opacity: 0;
            animation: moveUp 1s forwards;

            & > h3 {
                font-size: 16px;
                font-weight: 700;
                margin: 7px;
            }
            & > label {
                position: absolute;
                top: -1px;
                right: 14px;
                background: #fff500;
                padding: 6px 8px;
                font-size: 14px;
                line-height: 1.4;
            }
            & > p {
                margin: 7px;
                font-size: 14px;
                & > span {
                    color: #2275ee;
                }
            }
            & > button {
                width: 100%;
                background: #0047ff;
                color: #f6faff;
                font-size: 14px;
                line-height: 1.6;
                border-radius: 4px;
                padding: 8px;
                cursor: pointer;
            }
            & > .boxArrow {
                display: flex;
                margin: 7px;
                gap: 4px;
                font-size: 14px;
                margin-bottom: 10px;
                justify-content: space-between;
                justify-self: self-start;
                align-items: center;

                & > div {
                    width: 100%;
                    padding: 4px;
                    text-align: center;
                    background: #fff;
                    border: 1px solid #c9c9c9;
                    border-radius: 4px;
                    word-break: keep-all;
                }
                & > img {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
`;

const Container = styled.div`
    position: relative;
    height: 100dvh;

    .submitBtn {
        position: fixed;
        bottom: 28px;
        left: 14px;
        right: 14px;
        border-radius: 14px;
        line-height: 1.6;
        background: #2275ee;
        padding: 14px;
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        z-index: 2;
        cursor: pointer;
    }
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100dvh - 56px);

        & > div {
            width: 78px;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes moveUp {
        from {
            transform: translateY(50px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const titleBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #0c002c;
    font-size: 16px !important;
    font-weight: 400;
    white-space: pre-wrap;
    .highLight {
        position: relative;
        color: #ff0000;
        font-weight: 700;
        margin-left: 4px;
        &:after {
            content: "";
            width: 100%;
            height: 12px;
            display: inline-block;
            background: #faff00;
            position: absolute;
            bottom: -5px;
            left: 0;
            z-index: -1;
            transition: 0.2s all;
        }
    }
`;

const stateInfoTextStyle = (val: string) => css`
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: ${val === "근저당권설정" ? "#ff0000" : val === "근저당권말소" ? "#0047FF" : "#0A2540"};
    font-weight: 700;
    display: inline-block;
`;

const buttonBoxStyle = css`
    display: flex;
    align-items: center;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-bottom: 0.6px solid #dadada;
`;

const buttonStyle = (active: boolean) => css`
    cursor: pointer;
    color: ${active ? "#0047FF" : "#000000"};
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    p {
        ${active && { borderBottom: "2px solid #0047FF" }}
        padding: 8px 14px;
    }
`;

const submitButtonStyle = css`
    border-radius: 100px;
    background-color: #2275ee;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    width: 72px;
    height: 72px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    position: fixed;
    bottom: 24px;
    right: 24px;
`;

export default DeungiAlarmList;
