import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation } from "react-query";

//* 관심매물 등록/삭제
export const useSaverFavoriteItem = (
    onSuccess: (datum: any, t: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
    onSettled: () => void = () => {},
) => {
    return useMutation(
        async (item: string) => {
            try {
                const res = await axiosInstance.post(`${axiosPath.favoriteItemPath}/${item}`);
                return res;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
            onSettled: onSettled,
        },
    );
};

export const useUpdateFavoriteItemMemo = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: { memo: string; id: string }) => {
            const newParam: any = { ...params };
            delete newParam.id;
            try {
                const res = await axiosInstance.put(`${axiosPath.itemPath}/${params.id}/favorite/memo`, {
                    ...newParam,
                });
                return res;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
