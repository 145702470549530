import styled from "@emotion/styled";
import React from "react";

const EmptyResult = ({ children }: any) => {
    return <Container>{children}</Container>;
};

export default EmptyResult;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
    text-align: center;
    padding-top: 160px;

    p {
        font-size: 14px;
        line-height: 25px;
        color: #9d9fa5;
    }

    b {
        font-size: 14px;
        line-height: 25px;
    }

    span {
        font-size: 14px;
        letter-spacing: 0px;
        text-align: center;
        color: #9d9fa5;
    }

    img {
        margin-bottom: 24px;
    }
`;
