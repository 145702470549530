import styled from "@emotion/styled";
import { useFilterStore } from "@src/store/filterStore";
import React from "react";

type BidCountInputPropsType = {
    bidCount: {
        minCount: string;
        maxCount: string;
    };
    onChange?: (e: any) => void;
    type?: string;
};

const BidCountInput = ({ bidCount, onChange, type }: BidCountInputPropsType) => {
    const filterState = useFilterStore((state) => state.filterState);
    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);

    const realLoading = (e: any) => {
        const { value, name } = e.target;
        const isNumber = /^[0-9]+$/;
        const numberValue = Number(value);

        if (value !== "") {
            if (!isNumber.test(value)) return;
            if (!isNumber.test(value) && numberValue > 99) return;
        }

        if (name === "minCount") {
            setStoreFilterState(
                {
                    ...filterState,
                    skp_cnt: [value, bidCount.maxCount],
                },
                true,
            );
        }

        if (name === "maxCount") {
            setStoreFilterState(
                {
                    ...filterState,
                    skp_cnt: [bidCount.minCount, value],
                },
                true,
            );
        }
    };

    return (
        <RangeInputContainer>
            <input
                onChange={type === "setStore" ? realLoading : onChange}
                name="minCount"
                value={bidCount.minCount}
                maxLength={3}
                placeholder="최소"
                type="tel"
            />{" "}
            <span>~</span>{" "}
            <input
                onChange={type === "setStore" ? realLoading : onChange}
                name="maxCount"
                value={bidCount.maxCount}
                placeholder="최대"
                maxLength={3}
                type="tel"
            />
        </RangeInputContainer>
    );
};

export default BidCountInput;

export const RangeInputContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    padding: 14px 16px 0px 16px;

    span {
        padding: 0px 7px;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0px;
        color: #a3a3a3;
    }

    input {
        height: 48px;
        flex: 1;
        width: 151.5px;
        left: 16px;
        top: 0px;
        border-radius: 8px;
        background: #fbf8f8;
        border: none;
        padding: 0px 13px;
    }

    input::placeholder {
        height: 24px;
        width: 27px;
        left: 13px;
        top: 14px;
    }
`;
