import React, { useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useQueryClient } from "react-query";
import { css } from "@emotion/react";

import RefundDialog from "@src/components/Dialog/refund/RefundDialog";
import MgDialog from "@src/components/Dialog";
import RefundsDialogComponents, { DialogType } from "@src/components/Dialog/refund/RefundsDialogComponents";
import { useFindAllRegionAdsPayments } from "@src/hooks/expert/useFindAllRegionAdsPayments";
import { useFindAllCampusPayments, useSaverPgRefund, useSaverPgRefundV2 } from "@src/hooks/payment/useRefund";
import { Common } from "@src/styles/Common";
import { useFindAllTicketPayments } from "@src/hooks/deungi/useCharginUseList";

const CampuseRefundV2 = () => {
    const queryClient = useQueryClient();

    const guideTextRef = useRef<HTMLDivElement>(null);

    const [openDialog, setOpenDialog] = useState<number>(0);
    const [dialogType, setDialogType] = useState<keyof DialogType | null>(null);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { data, refetch, fetchNextPage, hasNextPage, isFetching } = useFindAllCampusPayments(
        {
            page: 1,
            limit: 20,
        },
        true,
    );

    const refundOrder = useSaverPgRefund(
        () => {
            refetch();
            onCloseDialog();
            queryClient.invalidateQueries({
                queryKey: ["campusPaymentItems"],
            });
        },
        (err) => {
            setSelectedPayment(null);
            const newError: any = err.response?.data;
            setErrorMessage(newError?.message);
            setDialogType("RefundFailed");
        },
    );
    const refundOrderToss = useSaverPgRefundV2(
        () => {
            refetch();
            onCloseDialog();
            queryClient.invalidateQueries({
                queryKey: ["campusPaymentItems"],
            });
        },
        (err) => {
            setSelectedPayment(null);
            const newError: any = err.response?.data;
            setErrorMessage(newError?.message);
            setDialogType("RefundFailed");
        },
    );

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: false,
        delayInMs: 100,
    });

    const onCloseDialog = () => {
        setOpenDialog(0);
        setTimeout(() => {
            setSelectedPayment(null);
        }, 500);
    };

    const onSubmitRefund = () => {
        if (selectedPayment) {
            if (selectedPayment.pgAgency === "toss") {
                refundOrderToss.mutate({
                    orderNo: selectedPayment?.orderNo,
                    amount: selectedPayment?.totalPrice,
                    comments: "",
                });
            } else {
                refundOrder.mutate(selectedPayment?.orderNo);
            }
        }
    };

    const onClickCancel = (type: number, payment: any) => {
        if (type === 0) {
            if (payment?.paidCardName?.includes("Store")) {
                setDialogType("RefundsToStore");
            } else {
                setDialogType("NoRefundsExpirationDate");
            }

            setOpenDialog(2);
            return;
        }
        if (type === 1) {
            setDialogType("CancelTicket");
            setSelectedPayment(payment);
            setOpenDialog(1);
            return;
        }
        if (type === 2) {
            setDialogType("CancelAlreadyCompleted");
            setOpenDialog(2);
            return;
        }
    };

    return (
        <>
            <RefundDialog
                ref={loadingRef}
                guideTextOffsetHeight={guideTextRef.current?.offsetHeight}
                data={data}
                isFetching={isFetching}
                onClickCancelButton={onClickCancel}
                ticketType="강의"
            >
                <div css={footerRootStyle(false)} ref={guideTextRef}>
                    실시간 등기열람권 충전 환불/취소 안내
                    <br /> - 열람권은 사용하지 않은 경우에 한 해, 구매 후 7일 이내에 자주묻는질문에서 직접 구매 취소할 수
                    있습니다.
                    <br /> - 구매 후 7일 이내임에도 {"‘내정보>고객센터>자주묻는질문>열람권 취소/환불하기’"}에 {"'취소 불가'"}
                    상태라면 다음 중 하나 이상의 경우에 해당합니다.
                    <br />
                    1) 열람권 패키지의 일부를 사용하여서 직접 환불이 불가능한 경우
                    <br /> 2) Google Play 결제를 통해 구매한 경우 <br />
                    3) iOS APP Store 결제를 통해 구매한 경우 <br />- 위 경우에 해당하는 결제 건의 구매 취소를 희망할 경우, 열람권
                    유효 기간 내에 고객센터로 문의해 주세요.
                    <br /> - 단, iOS APP Store 결제로 구매한 열람권의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.
                    <br /> - Google Play 결제로 구매한 열람권은 구매 후 48시간 이내에는 Google Play 고객센터를 통해, 48시간
                    이후에는 고객센터를 통해 구매 취소할 수 있습니다.
                    <br /> - 구매 취소나 환불 시에는 결제한 수단으로 환불됩니다.
                    <br /> - 구독 혜택 등 무료로 지급된 열람권은 환불 대상에서 제외됩니다.
                </div>
            </RefundDialog>
            <MgDialog
                open={openDialog !== 0}
                onCloseView={onCloseDialog}
                width="284px"
                confirmStyle={{ fontSize: "18px" }}
                cancelStyle={{ fontSize: "18px" }}
                cancelText={selectedPayment ? "닫기" : undefined}
                onClickSubmit={onSubmitRefund}
                confirmText={selectedPayment ? "환불하기" : undefined}
            >
                <RefundsDialogComponents dialogType={dialogType} title="강의" errorMessage={errorMessage} />
            </MgDialog>
        </>
    );
};

export default CampuseRefundV2;

const footerRootStyle = (props: boolean) => css`
    visibility: ${props ? "hidden" : "visible"};
    background-color: ${Common.colors.grey01};
    color: ${Common.colors.greyPrimary};
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 20px 14px;
    margin-top: 30px;
    li {
        list-style: inside;
    }
    & > .linkLi {
        text-decoration-line: underline;
        text-underline-position: under;
        cursor: pointer;
    }
`;
