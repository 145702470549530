import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { transferToNative } from "@src/lib/WebAppBridge";
import blackCloseIcon from "@src/assets/basicIcon/blackCloseIcon.svg";
import shortsSkipIcon from "@src/assets/shorts/shortsSkipIcon.png";
import skipIcon from "@src/assets/shorts/skipIcon.svg";

interface Props {}

const SpeechShorts: React.FC<Props> = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const mutedRef = useRef<any>(true);
    const [muted, setMuted] = useState(true);

    const isMoveToSpeech = () => {
        videoRef.current?.pause();
        transferToNative("msgaapp://deeplink/home/speechRecognition", "moveToDeepLink");
    };

    useEffect(() => {
        videoRef.current?.addEventListener("ended", () => {
            isMoveToSpeech();
        });
        videoRef.current?.addEventListener("click", () => {
            if (mutedRef.current) {
                mutedRef.current = false;
                setMuted(false);
            }
            videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause();
        });
    }, []);

    return (
        <>
            <div css={headStyle}>
                <p>음성 매물검색 사용법</p>
                <img src={blackCloseIcon} alt="close" onClick={() => transferToNative("done", "closeWebView")} />
            </div>
            <button css={buttonStyle} onClick={isMoveToSpeech}>
                SKIP <img src={skipIcon} alt="skipIcon" />
            </button>
            <video
                autoPlay={true}
                muted={muted}
                css={videoStyle}
                ref={videoRef}
                playsInline={true}
                controlsList="nodownload"
                src={"https://missgo-landing.s3.ap-northeast-2.amazonaws.com/image/pcImage/speechShorts.mp4"}
            />
        </>
    );
};

export default SpeechShorts;

const headStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    color: #0c002c;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    position: relative;
    width: 100vw;
    background-color: #ffffff;
    img {
        position: absolute;
        top: 50%;
        right: 14px;
        padding: 14px;
        transform: translate(0, -50%);
    }
`;

const buttonStyle = css`
    position: fixed;
    top: 66px;
    right: 10px;
    padding: 2px 4px;
    background-color: #ffffff;
    color: #0c002c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 4px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 24px;
    border: 0.6px solid #0c002c;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.14);
    z-index: 10;
`;

const videoStyle = css`
    width: 100vw;
    height: calc(100dvh - 56px);
    height: calc(100vh - 56px);
`;
