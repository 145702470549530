import produce from "immer";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

export type SelectedItemFilter = {
    title: string;
    id: string;
    isActive: boolean;
    type?: string;
};

const useSelectedFilter = (data: SelectedItemFilter[], selectedLocalStorageData?: string[] | string, type?: string) => {
    const [selectedState, setSelectedState] = useState<SelectedItemFilter[]>(data);

    useEffect(() => {
        if (selectedLocalStorageData) {
            const withoutAll = selectedState.filter((item) => item.title !== "전체");

            const isAll = withoutAll.every((item) => {
                return selectedLocalStorageData.includes(item.id);
            });

            if (
                (selectedLocalStorageData.length === 1 && selectedLocalStorageData[0] === "0") ||
                isEmpty(selectedLocalStorageData)
            )
                return;

            const nextState = produce(selectedState, (draft) => {
                draft.forEach((item) => {
                    if (!isAll && !selectedLocalStorageData.includes("0") && item.title === "전체") {
                        item.isActive = false;
                    }

                    if (selectedLocalStorageData.includes(item.id)) {
                        item.isActive = true;
                    }

                    if (isAll && item.title === "전체") {
                        item.isActive = true;
                    }

                    if (isAll && item.title !== "전체") {
                        item.isActive = false;
                    }
                });
            });

            setSelectedState(nextState);
        }
    }, [selectedLocalStorageData]);

    useEffect(() => {
        if (type === "noReal") {
            const withoutAll = selectedState.filter((item) => item.title !== "전체");

            const isAll = withoutAll.every((item) => item.isActive);
            const withAll = selectedState.every((item) => item.isActive === false);

            if (isAll) {
                const nextState = produce(selectedState, (draft) => {
                    draft.map((item) => {
                        if (item.title === "전체") {
                            item.isActive = true;
                        }
                        if (item.title !== "전체") {
                            item.isActive = false;
                        }
                        return;
                    });
                });

                setSelectedState(nextState);
            }
        }
    }, [selectedState]);

    const onSelectedItem = (id: string, isMulti = true) => {
        if (isMulti) {
            setSelectedState(
                selectedState.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isActive: !item.isActive,
                        };
                    }

                    if (item.id === "0") {
                        return {
                            ...item,
                            isActive: false,
                        };
                    }
                    return item;
                }),
            );
        }

        if (!isMulti) {
            setSelectedState(
                selectedState.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isActive: !item.isActive,
                        };
                    }

                    if (item.id !== id) {
                        return {
                            ...item,
                            isActive: false,
                        };
                    }

                    if (item.id === "0") {
                        return {
                            ...item,
                            isActive: false,
                        };
                    }

                    return item;
                }),
            );
        }
    };

    const onClickReset = () => {
        setSelectedState(
            selectedState.map((item) => {
                if (item.id === "0") {
                    return {
                        ...item,
                        isActive: true,
                    };
                } else {
                    return {
                        ...item,
                        isActive: false,
                    };
                }
            }),
        );
    };

    return { selectedState, onSelectedItem, onClickReset };
};

export default useSelectedFilter;
