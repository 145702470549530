import styled from "@emotion/styled";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import { AiItemListType } from "@src/hooks/filter/useGetAiItemList";
import useCallToken from "@src/hooks/useCallToken";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import { removeAll } from "@src/store/filterStore";
import { isEmpty } from "lodash";
// import { GetItemListType, initialParams, useGetItem } from "@src/hooks/item/useGetItem";

import React, { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import AiBottomSheetItem from "./AiBottomSheetItem";
import { useLocation } from "react-router-dom";

interface AiBottomSheetItemListProps {
    content: any;
    data: AiItemListType[];
    setTarget: any;
}

const AiBottomSheetItemList = ({ content, data, setTarget }: AiBottomSheetItemListProps) => {
    const AiFilterDataList = useAiFilterStore((state) => state.aiFilterDataList);
    const AiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const getAiFilterCount = useAiFilterStore((state) => state.getAiFilterCount);
    const getAiFilterDataList = useAiFilterStore((state) => state.getAiFilterDataList);
    const AiFilterCount = useAiFilterStore((state) => state.aiFilterCount);
    const setNextIt = useAiFilterStore((state) => state.setNextIt);
    const nextIt = useAiFilterStore((state) => state.next_it);
    const sortId = useAiFilterStore((state) => state.selectedSortId);
    const AiFilterTotalCnt = useAiFilterStore((state) => state.aiFilterTotalCnt);
    const scrollTopRef = useRef<any>();
    const aiFilterTotalCnt = useAiFilterStore((state) => state.aiFilterTotalCnt);

    const [items, setItems] = useState<AiItemListType[]>([]);

    const [isNextIt, setIsNextIt] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isMore, setIsMore] = useState(true);
    const { pathname } = useLocation();

    const fetchData = async (type?: string) => {
        if (AiFilterDataList?.length !== AiFilterTotalCnt) {
            setIsLoading(true);

            if (!isEmpty(AiFilterDataList)) {
                getAiFilterDataList({ ...AiFilterState }, AiFilterCount, sortId);
            }

            // if (isEmpty(AiFilterDataList)) {
            if (AiFilterCount === "-1") {
                setIsMore(false);
            }

            setIsLoading(false);
            if (type === "filterChanged") {
                return setItems(AiFilterDataList);
            }
        }
    };

    const { token } = useCallToken();

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: token.length ? isMore : false,
        onLoadMore: fetchData,
        disabled: false,
        rootMargin: "0px 0px 3px 0px",
        delayInMs: 1000,
    });
    const [isSubscriptionUser, setIsSubscriptionUser] = useState<boolean | null>(null);
    useEffect(() => {
        if (token.length) {
            const isSubscription = localStorage.getItem("isSubscription");
            const isNull = typeof isSubscription == "object";
            if (isNull) {
                setIsSubscriptionUser(true);
            } else {
                setIsSubscriptionUser(JSON.parse(isSubscription));
            }
        }
    }, [token]);

    useEffect(() => {
        scrollTopRef.current && scrollTopRef.current.scrollIntoView();
    }, [aiFilterTotalCnt]);

    const showData = () => {
        if (AiFilterCount === "-1" && AiFilterDataList.length === 0) {
            return <NoItemDiv>{pathname.includes("ai") ? "AI추천 매물이 없어요" : "조건에 맞는 매물이 없습니다"}</NoItemDiv>;
        }
        if (
            AiFilterDataList.length === 0 ||
            AiFilterDataList[0].item_id === "" ||
            AiFilterState.price === "" ||
            AiFilterState.price === "0"
        ) {
            return <NoItemDiv>{pathname.includes("ai") ? "투자금을 입력해주세요" : "보유 현금을 입력해주세요"}</NoItemDiv>;
        } else {
            return (
                <>
                    <div ref={scrollTopRef} />
                    {AiFilterDataList.map((item, idx) => (
                        <AiBottomSheetItem
                            onClick={() => {
                                if (isSubscriptionUser === false) {
                                    transferToNative("", "showSubscriptionPopup");
                                } else {
                                    transferToNative(
                                        JSON.stringify({ item_id: item.itemId, bid_type: item.bidType }),
                                        "onClickItem",
                                    );
                                }
                            }}
                            key={idx}
                            item={item}
                        />
                    ))}
                    <div ref={sentryRef} style={{ width: "100%", height: "40px" }}></div>
                </>
            );
        }
    };

    return (
        <>
            <Wrapper>
                {/* {AiFilterDataList.length === 0 ||
                    (AiFilterDataList[0].item_id === "" && <NoItemDiv>AI추천 매물이 없어요</NoItemDiv>)}
                {AiFilterDataList.length !== 0 && AiFilterDataList[0].item_id !== "" && (
                    <>
                        {AiFilterDataList.map((item, idx) => (
                            <AiBottomSheetItem key={idx} item={item} />
                        ))}
                        <div ref={setTarget} style={{ width: "100%", height: "40px" }}></div>
                    </>
                )} */}
                {/* {AiFilterDataList.length === 0 ? (
                    <NoItemDiv>AI추천 매물이 없어요</NoItemDiv>
                ) : AiFilterDataList[0].item_id === "" ? (
                    <NoItemDiv>AI추천 매물이 없어요</NoItemDiv>
                ) : (
                    <>
                        {AiFilterDataList.map((item, idx) => (
                            <AiBottomSheetItem key={idx} item={item} />
                        ))}
                        <div ref={sentryRef} style={{ width: "100%", height: "40px" }}></div>
                    </>
                )} */}
                {showData()}
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

const NoItemDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;


export default AiBottomSheetItemList;
