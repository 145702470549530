import styled from "@emotion/styled";
import ico_arrow_back from "@src/assets/ico_arrow_back.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

type DeungiHeaderPropsType = {
    title: string;
    path?: string;
    isClose?: boolean;
};

const DeungiAlarmHeader = ({ title, path, isClose }: DeungiHeaderPropsType) => {
    const navigate = useNavigate();

    const onPrevHandler = () => {
        const currentLocation = location.href;

        if (isClose) {
            transferToNative("done", "closeWebView");
        } else if (path) {
            navigate(path);
        } else {
            history.back();
            setTimeout(() => currentLocation === location.href && transferToNative("done", "closeWebView"), 100);
        }
    };

    return (
        <DeungiHeaderContainer>
            <div className="prevBtn" onClick={onPrevHandler}>
                <img src={ico_arrow_back} />
            </div>
            <div className="content">
                <span>{title}</span>
            </div>
        </DeungiHeaderContainer>
    );
};

export default DeungiAlarmHeader;

const DeungiHeaderContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    padding: 14px;

    .prevBtn {
        position: absolute;
        left: 5px;
        top: 10px;
        width: 40px;
        height: 40px;
        padding: 10px;

        & > img {
            width: 10px;
            height: 17px;
        }
    }

    .content {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;
    }
`;
