import React, { useEffect } from "react";
import StateLandFilter from ".";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";
import { useFindOneStateLandSubs } from "@src/hooks/stateLand/useGetStateLandSubsInfo";
import { useFindOneStateLandFilter, useSaverStateLandFilter } from "@src/hooks/inventory/useInventory";

const StateLandUserFilter = () => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);
    //*국공유지 정보
    const { data: stateLandSubs, refetch: refetchStateLandSubs } = useFindOneStateLandSubs();
    //*국공유지 필터 인벤토리 불러오기
    const { data: userStateLandFilter, refetch: refetchUserStateLandFilter } = useFindOneStateLandFilter();
    //*국공유지 필터 인벤토리 저장
    const saveStateLandFilter = useSaverStateLandFilter();

    useEffect(() => {
        refetchStateLandSubs();
        refetchUserStateLandFilter().then((res) => {
            if (JSON.stringify(res.data) !== JSON.stringify(stateLandFilter)) {
                setStateLandFilter(res.data);
            }
        });
    }, []);

    return (
        <StateLandFilter
            isShow={true}
            onCloseView={() => {
                transferToNative(JSON.stringify(stateLandFilter), "setFilterDone");
                if (JSON.stringify(userStateLandFilter) !== JSON.stringify(stateLandFilter)) {
                    saveStateLandFilter.mutate(JSON.stringify(stateLandFilter));
                }
            }}
            stateLandSubs={stateLandSubs}
        />
    );
};

export default StateLandUserFilter;
