import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@src/components";
import Title from "@src/components/title/Title";
import { getDeviceType, transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import React, { Fragment, useEffect, useState } from "react";
import backArrow from "../../assets/ico_arrow_back.png";
import Class from "./Class";
import MyClass from "./MyClass";
import noClass from "../../assets/noClass.png";
import { checkDevice } from "../../components/deungi/common/utils";
import { useGetClassList, useGetMyClassList } from "@src/hooks/campus/useCampusList";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useCallToken from "@src/hooks/useCallToken";
import Lottie from "lottie-react";
import loading from "../../assets/Loading.json";
import { CampusClassResponseDto, CampusMyClassDto, MyClassFilterDto } from "@src/hooks/campus/dto/CampusList.dto";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "@src/components/popup/ConfirmModal";

const Campus = () => {
    const { token } = useCallToken();
    const { path } = useParams();
    const navigate = useNavigate();
    const [type, setType] = useState<string>(path ? path : "home");
    const [campusList, setCampusList] = useState<CampusClassResponseDto[]>([]);
    const [myCampusList, setMyCampusList] = useState<CampusMyClassDto[]>([]);
    const [isMore, setIsMore] = useState<boolean>(true);
    const deviceType = checkDevice();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [itemNo, setItemNo] = useState<number>(0);

    const tryGetClassList = async () => {
        const page = Math.floor(campusList.length / 10) + 1;
        const response = await useGetClassList({ page: page, limit: 10, saleType: deviceType === "android" ? 6 : 7 });
        if (campusList.length + response.data.list.length === response.data.totalCnt) {
            setIsMore(false);
        } else {
            setIsMore(true);
        }
        setIsLoading(false);
        if (campusList.length) {
            setCampusList(campusList.concat(response.data.list));
        } else {
            setCampusList(response.data.list);
        }
    };

    const tryGetMyClassList = async () => {
        const status = filter.filter((item: MyClassFilterDto) => item.active === true);
        const page = Math.floor(myCampusList.length / 10) + 1;
        const response = await useGetMyClassList({ page: page, limit: 10, status: status[0].key });
        if (myCampusList.length + response.data.list.length === response.data.totalCnt) {
            setIsMore(false);
        } else {
            setIsMore(true);
        }
        setIsLoading(false);
        if (myCampusList.length) {
            setMyCampusList(myCampusList.concat(response.data.list));
        } else {
            setMyCampusList(response.data.list);
        }
    };

    const initialFilter = [
        {
            key: 0,
            text: "전체",
            active: true,
        },
        {
            key: 1,
            text: "수강중",
            active: false,
        },
        {
            key: 2,
            text: "수강완료",
            active: false,
        },
    ];
    const [filter, setFilter] = useState<MyClassFilterDto[]>(initialFilter);

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: token.length ? isMore : false,
        onLoadMore: type === "home" ? tryGetClassList : tryGetMyClassList,
        disabled: false,
        rootMargin: "0px 0px 300px 0px",
        delayInMs: 500,
    });

    useEffect(() => {
        setIsLoading(true);
        setCampusList([]);
        setMyCampusList([]);
        type === "home" ? tryGetClassList() : tryGetMyClassList();
    }, [type, filter]);

    return (
        <>
            <StyledCampusWrapper>
                <div className="titleWrap">
                    <div
                        className="backBtn"
                        onClick={() => {
                            transferToNative("done", "closeWebView");
                        }}
                    >
                        <img src={backArrow} alt="" />
                    </div>
                    <Title title="부동산 경매 캠퍼스" size="large" />
                </div>
                <div className="buttonWrap">
                    <Button
                        label="전체 강의"
                        color="blue"
                        size="small"
                        handleClick={() => {
                            setType("home");
                        }}
                        styles={{
                            backgroundColor: type === "home" ? "#2275ee" : "#FBF8F8",
                            color: type === "home" ? "#FFFFFF" : "#8D8D8D",
                            fontWeight: type === "home" ? 700 : 500,
                            transition: "all 0s",
                        }}
                    />
                    <Button
                        label="내 강의실"
                        color="blue"
                        size="small"
                        handleClick={() => {
                            setType("my");
                        }}
                        styles={{
                            backgroundColor: type === "my" ? "#2275ee" : "#FBF8F8",
                            color: type === "my" ? "#FFFFFF" : "#8D8D8D",
                            fontWeight: type === "my" ? 700 : 500,
                            transition: "all 0s",
                        }}
                    />
                </div>
                {type === "my" && (
                    <StyledFilter>
                        {filter.map((item: MyClassFilterDto, idx: number) => {
                            return (
                                <Fragment key={idx + item.text}>
                                    <label
                                        className={item.active ? "active" : ""}
                                        onClick={() => {
                                            const newFilter = [
                                                {
                                                    key: 0,
                                                    text: "전체",
                                                    active: false,
                                                },
                                                {
                                                    key: 1,
                                                    text: "수강중",
                                                    active: false,
                                                },
                                                {
                                                    key: 2,
                                                    text: "수강완료",
                                                    active: false,
                                                },
                                            ];

                                            if (item.active !== true) {
                                                newFilter[idx].active = true;
                                                setMyCampusList([]);
                                                setIsLoading(true);
                                                setFilter(newFilter);
                                            }
                                        }}
                                    >
                                        {item.text}
                                    </label>
                                </Fragment>
                            );
                        })}
                    </StyledFilter>
                )}

                <StyledLectureList type={type}>
                    <>
                        {isLoading ? (
                            <StyledLottieDiv>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </StyledLottieDiv>
                        ) : type === "home" ? (
                            campusList.length === 0 ? (
                                <div className="noList">
                                    <img className="noClass" src={noClass} alt="" />
                                    <div className="noListText">강의가 없어요</div>
                                </div>
                            ) : (
                                campusList.map((item: CampusClassResponseDto, idx: number) => (
                                    <Class item={item} key={item.createdAt + idx} />
                                ))
                            )
                        ) : myCampusList.length === 0 ? (
                            <div className="noList">
                                <img className="noClass" src={noClass} alt="" />
                                <div className="noListText">수강한 강의가 없어요</div>
                            </div>
                        ) : (
                            myCampusList.map((item: CampusMyClassDto, idx: number) => (
                                <MyClass
                                    item={item}
                                    key={item.createdAt + idx}
                                    setIsShowModal={setIsShowModal}
                                    setItemNo={setItemNo}
                                />
                            ))
                        )}
                        <div style={{ width: "100px", height: "50px" }} ref={sentryRef} />
                    </>
                </StyledLectureList>
                {isShowModal && (
                    <>
                        <StyledModal>
                            <StyledModalContents>
                                <span>수강기한이 만료된 강의에요</span>
                                {itemNo !== -1 && <div className="sub">다시 수강하시겠습니까?</div>}
                            </StyledModalContents>
                            <div className="buttonDiv">
                                <button className="cancelButton" onClick={() => setIsShowModal(false)}>
                                    {itemNo !== -1 ? "취소" : "확인"}
                                </button>
                                {itemNo !== -1 && (
                                    <button
                                        className="confirmButton"
                                        onClick={() =>
                                            navigate(`/campus/detail/${itemNo}`, {
                                                state: { no: itemNo, saleType: deviceType === "android" ? 6 : 7 },
                                            })
                                        }
                                    >
                                        수강하기
                                    </button>
                                )}
                            </div>
                        </StyledModal>
                        <StyledDim onClick={() => setIsShowModal(false)}></StyledDim>
                    </>
                )}
            </StyledCampusWrapper>
        </>
    );
};

export default Campus;

const StyledCampusWrapper = styled.div`
    & > .titleWrap {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 14px;
        align-items: center;
        & > .backBtn {
            position: absolute;
            left: 14px;
            width: 10px;
            height: 17px;

            & > img {
                width: 100%;
                height: 100%;
            }
        }
        & > .title {
            font-weight: 700;
        }
    }
    & > .buttonWrap {
        display: flex;
        padding: 14px;
        width: 100%;
        gap: 8px;

        justify-content: center;
        & > button {
            width: 50%;
            height: 52px;
            align-items: center;
            font-size: 16px;
            line-height: 26px;
        }
    }
`;
const StyledLectureList = styled.div<{ type: string }>`
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* height: calc(100dvh - 182px); */
    height: ${(props) =>
        props.type === "home" ? `calc(calc(var(--vh, 1vh) * 100) - 136px)` : `calc(calc(var(--vh, 1vh) * 100) - 182px)`};
    padding: 0 14px;
    ::-webkit-scrollbar {
        display: none;
    }
    .noList {
        margin-top: calc((calc(var(--vh, 1vh) * 100) - 182px) / 4);
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: center;
        .noClass {
            width: 213px;
            height: 141px;
        }
        .noListText {
            font-size: 17px;
            font-weight: 400;
            line-height: 26px;
            color: ${Common.colors.normalTextColor};
        }
    }
`;

const StyledFilter = styled.div`
    display: flex;
    gap: 8px;
    padding: 0 14px 14px 14px;
    label {
        width: fit-content;
        padding: 0 14px;
        min-width: 53px;
        min-height: 32px;
        color: ${Common.colors.normalTextColor};
        background-color: ${Common.colors.grey01};
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
    }
    .active {
        color: white;
        background-color: ${Common.colors.aucMarkerColor};
    }
`;

const StyledLottieDiv = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 136px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 106px;
        height: 63px;
        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;

const StyledModalContents = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    gap: 8px;
    color: ${Common.colors.normalTextColor};
    text-align: center;
    white-space: pre-wrap;
    .sub {
        font-size: 14px;
        line-height: 24px;
    }
`;

const StyledModal = styled.div`
    width: 312px;
    max-width: 90dvw;
    padding: 14px;
    height: max-content;
    background-color: ${Common.colors.white};
    border-radius: 14px;
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .buttonDiv {
        margin-top: 24px;
        width: 100%;
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: space-between;
        & > button {
            flex: 1;
            width: 50%;
            border-radius: 14px;
            padding: 14px 0;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }
        .cancelButton {
            background-color: ${Common.colors.grey02};
            color: ${Common.colors.navyPrimary};
        }
        .confirmButton {
            background-color: ${Common.colors.aucMarkerColor};
            color: ${Common.colors.white};
        }
    }
`;

const StyledDim = styled.div`
    position: fixed;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100dvw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
`;
