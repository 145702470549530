import styled from "@emotion/styled";
import AiFooter from "@src/components/aiFilter/AiFooter";
import AiHeader from "@src/components/aiFilter/AiHeader";
import AiResultTotal from "@src/components/aiFilter/AiResultTotal";
import AiTitle from "@src/components/aiFilter/AiTitle";
import FilterFooter from "@src/components/filter/FilterFooter";
import LocationFilter from "@src/components/filter/LocationFilter";
import ToastMessage from "@src/components/filter/ToastMessage";
import useGetLocations from "@src/hooks/filter/useGetLocations";
import { FilterParamsType, useAiFilterStore } from "@src/store/AiFilterStore";
import { useFilterStore } from "@src/store/filterStore";
import produce from "immer";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AiResetConfirmModal from "./AiResetConfirmModal";

const AiFilterLocation = () => {
    const [aiSelectedLocation, setAiSelectedLocation] = useState<string[]>([]);
    const [resetVisible, setResetVisible] = useState(false);

    const { state } = useLocation() as {
        state: FilterParamsType;
    };

    const setAiFilterState = useAiFilterStore((state) => state.setAiFilterState);
    const getAiFilterCount = useAiFilterStore((state) => state.getAiFilterCount);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);
    const updateHistoryFilterFlag = useFilterStore((state) => state.updateHistoryFilterFlag);
    const aiFilterCount = useAiFilterStore((state) => state.aiFilterCount);

    const navigate = useNavigate();

    useEffect(() => {
        getAiFilterCount({
            ...state,
            loc: aiSelectedLocation,
        });

        setUpdateHistoryFilterFlag(false);
    }, [aiSelectedLocation, state]);

    // 소재지
    const {
        locationData: locationState,
        activeData,
        selectedType,
        handleSelect,
        onClickRemove,
        setActiveData,
        resetActiveData,
        activePNUState,
        setActivePNUState,
        resetLocationData,
        setSubmitPNUData,
        toastVisible,
        setToastVisible,
    } = useGetLocations(state.loc);

    useEffect(() => {
        setUpdateHistoryFilterFlag(false);
        if (isEmpty(aiSelectedLocation) && !isEmpty(activeData.city)) {
            const nextState = produce(activeData, (draft) => {
                draft.city[0].isActive = true;
            });
            setActiveData(nextState);
        }
    }, [locationState, activeData]);

    const handleSubmit = (selectedLocation: string[]) => {
        setAiFilterState({
            ...state,
            loc: selectedLocation,
        });
    };

    const locationArea = useMemo(
        () => (
            <FilterContainer>
                <FilterFooter
                    locationState={activeData}
                    onSubmit={() => console.log("진행상태!")}
                    type="AI"
                    setAiSelectedLocation={setAiSelectedLocation}
                    onClickRemove={onClickRemove}
                    setSubmitPNUData={setSubmitPNUData}
                />
                <LocationFilter
                    style={{ height: "270px" }}
                    handleSelect={handleSelect}
                    activeData={activeData}
                    locationData={locationState}
                    selectedType={selectedType}
                    setActiveData={setActiveData}
                    resetActiveData={resetActiveData}
                    activePNUState={isEmpty(activePNUState) ? ["All"] : activePNUState}
                    setActivePNUState={setActivePNUState}
                    resetLocationData={resetLocationData}
                />
            </FilterContainer>
        ),
        [
            activePNUState,
            locationState,
            selectedType,
            activeData,
            setSubmitPNUData,
            state,
            aiSelectedLocation,
            updateHistoryFilterFlag,
        ],
    );

    const onClickNext = () => {
        handleSubmit(aiSelectedLocation);
        navigate("/aiFilter/location/purpose", { state: { ...state, loc: aiSelectedLocation } });
    };

    const onClickReset = () => {
        setResetVisible(true);
    };

    const onClickCancel = () => {
        setResetVisible(false);
    };

    const onClickConfirm = async () => {
        await handleSubmit([]);
        resetActiveData();
        resetLocationData();
        setResetVisible(false);
    };

    const onClickGoBack = () => {
        navigate("/aiFilter", { state: state });
    };

    return (
        <>
            <Container>
                <AiHeader onClickBack={onClickGoBack} />
                <AiTitle>
                    <h2>
                        관심있는 <span className="color-text">소재지</span>를 선택해주세요.
                    </h2>
                    <p>
                        선택한 소재지에 따라 맞춤형
                        <br />
                        매물 정보를 확인할 수 있어요
                    </p>
                </AiTitle>
                <AiResultTotal result={aiFilterCount} />
                <Border />
                {locationArea}

                <AiFooter style={{ bottom: "0" }} isReset isNext onClickNext={onClickNext} onClickReset={onClickReset} />
            </Container>
            {resetVisible ? (
                <AiResetConfirmModal text="필터를 초기화할까요?" onClickCancel={onClickCancel} onClickConfirm={onClickConfirm} />
            ) : (
                <></>
            )}
            <ToastMessage visible={toastVisible} setToastVisible={setToastVisible}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
        </>
    );
};

export default AiFilterLocation;

const Container = styled.div`
    position: relative;
    padding: 0px 14px;
    height: 100vh;
`;

const Border = styled.div`
    width: 100vw;
    margin-left: -14px;
    height: 12px;
    background: #edf0f6;
`;

const FilterContainer = styled.div`
    position: relative;
    background: white;
    width: 100vw;
    margin-left: -14px;
    max-height: 470px;

    button + button {
        margin-left: 0px;
    }
`;
