import React, { useState } from "react";
import { css } from "@emotion/react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConvertPyeongButton from "@src/components/button/ConvertPyeongButton";
import convertToPyeong from "@src/util/convertToPyeong";

interface Props {
    totalPolygonLandSize: { pyong: number; square: number };
    onClose: () => void;
    onDelete: (index: number) => void;
    length: number;
}

const NewMeasureCard: React.FC<Props> = ({ totalPolygonLandSize, onClose, onDelete, length }) => {
    const [sizeType, setSizeType] = useState<"pyeong" | "meter">("meter");
    const handleCovertType = (type: "meter" | "pyeong") => {
        setSizeType(type);
    };

    return (
        <div css={rootStyle}>
            <div css={topContentBoxStyle}>
                <p>{totalPolygonLandSize?.pyong <= 0 ? "더하고자 하는 면적을 지도에서 탭 해주세요" : "총 면적"}</p>
                <button onClick={onClose} className="clearBtn">
                    <ClearRoundedIcon />
                </button>
            </div>
            {totalPolygonLandSize?.pyong > 0 && (
                <>
                    <div css={contentBoxStyle}>
                        <p>
                            {sizeType === "meter"
                                ? `${new Intl.NumberFormat("ko").format(
                                      Number((totalPolygonLandSize?.square * 0.3025).toFixed(2)),
                                  )}평`
                                : totalPolygonLandSize?.square.toFixed(0).length > 6
                                ? `${new Intl.NumberFormat("ko").format(
                                      Number((totalPolygonLandSize?.square / 1000000).toFixed(0)),
                                  )}km`
                                : `${new Intl.NumberFormat("ko").format(Number(totalPolygonLandSize?.square.toFixed(0)))}m`}
                            {sizeType === "pyeong" && <sup>2</sup>}
                        </p>
                        <div className="convertBtn">
                            <ConvertPyeongButton handleConvert={handleCovertType} />
                        </div>
                    </div>
                    <div css={buttonBoxStyle}>
                        <button className="lastDeleteButton" onClick={() => onDelete(length - 1)}>
                            마지막 선택 삭제
                        </button>
                        <button className="allDeleteButton" onClick={() => onDelete(-1)}>
                            전체취소
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default NewMeasureCard;

const rootStyle = css`
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 991;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    justify-content: center;
    padding: 14px;
    background-color: #ffffff;
    border-radius: 14px 14px 0px 0px;
`;

const topContentBoxStyle = css`
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    color: #0c002c;
    border-radius: 14px 14px 0px 0px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 330px) {
        p {
            max-width: 200px;
            word-break: keep-all;
            line-height: 15px;
        }
    }
    .clearBtn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

const contentBoxStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 33px;
    p {
        font-weight: 500;
        font-size: 16px;
        color: #0c002c;
    }
    & > .convertBtn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0%, -46%);
    }
`;

const buttonBoxStyle = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    white-space: nowrap;
    & > .lastDeleteButton {
        border-radius: 8px;
        background-color: #0047ff;
        color: #ffffff;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        padding: 14px;
        border: 1px solid inherit;
    }
    & > .allDeleteButton {
        border-radius: 8px;
        background-color: #ffffff;
        color: #0047ff;
        width: 100%;
        padding: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(0, 0, 0, 0.08);
    }
`;
