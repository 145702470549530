import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import fetchJsonp from "fetch-jsonp";

interface ILocationParams {
    lat: number;
    lng: number;
}

export interface IPolygonApiParams {
    zoom: number;
    latitude: number;
    longitude: number;
}
export interface ILocationResultType {
    bbox: any[];
    geometryCoordinates: any[];
    center: ILocationParams;
    address: string;
    code: string;
    zoomCheck?: boolean;
}

const V_WORLD_DEFAULT = {
    key: process.env.REACT_APP_V_WORLD_KEY,
    domain: process.env.REACT_APP_V_WORLD_DOMAIN,
    request: "GetFeature",
    srsname: "EPSG:4326",
    // output: "application/json",
    output: "text/javascript",
    maxfeatures: 1000,
};

//? 읍면동 정보 가져오기
const fetchDongLocationData = async (params: ILocationParams) => {
    const url = process.env.REACT_APP_V_WORLD_API_URL;
    const newParams: any = {
        ...V_WORLD_DEFAULT,
        maxfeatures: 50,
        typename: "lt_c_ademd_info",
        bbox: `${params.lng},${params.lat},${params.lng},${params.lat}`,
    };
    try {
        const res = await fetchJsonp(`${url}?${new URLSearchParams(newParams)}`, {
            jsonpCallbackFunction: "parseResponse",
            timeout: 3000,
        });
        const { bbox, features } = await res.json();
        const { geometry, properties } = features[0];
        const newCode = properties?.emd_cd;
        const reg = /^(00|11|41|28|26|30|27|31|29)/;
        const newPositionCheck = reg.test(newCode);
        const result = {
            bbox,
            center: params,
            geometryCoordinates: geometry.coordinates[0] || [],
            address: properties.full_nm,
            code: properties?.emd_cd,
            zoomCheck: newPositionCheck,
        };
        return result;
    } catch (error) {
        console.log("err>>");
        return;
    }
};

//? 시군구 정보 가져오기
const fetchSigunguLocationData = async (params: ILocationParams) => {
    const url = process.env.REACT_APP_V_WORLD_API_URL;
    const newParams: any = {
        ...V_WORLD_DEFAULT,
        maxfeatures: 50,
        typename: "lt_c_adsigg",
        bbox: `${params.lng},${params.lat},${params.lng},${params.lat}`,
    };
    try {
        const res = await fetchJsonp(`${url}?${new URLSearchParams(newParams)}`, {
            jsonpCallbackFunction: "parseResponse",
            timeout: 3000,
        });
        const { bbox, features } = await res.json();
        const { geometry, properties } = features[0];
        const newCode = properties?.sig_cd;
        const reg = /^(00|11|41|28|26|30|27|31|29)/;
        const newPositionCheck = reg.test(newCode);
        const result = {
            bbox,
            center: params,
            geometryCoordinates: geometry.coordinates[0] || [],
            address: properties.full_nm,
            code: properties?.sig_cd,
            zoomCheck: newPositionCheck,
        };
        return result;
    } catch (error) {
        console.log("err>>");
        return;
    }
};
//? 시도 정보 가져오기
const fetchSidoLocationData = async (params: ILocationParams) => {
    const url = process.env.REACT_APP_V_WORLD_API_URL;
    const newParams: any = {
        ...V_WORLD_DEFAULT,
        maxfeatures: 50,
        typename: "lt_c_adsido",
        bbox: `${params.lng},${params.lat},${params.lng},${params.lat}`,
    };
    try {
        const res = await fetchJsonp(`${url}?${new URLSearchParams(newParams)}`, {
            jsonpCallbackFunction: "parseResponse",
            timeout: 3000,
        });
        const { bbox, features } = await res.json();
        const { geometry, properties } = features[0];
        const newCode = properties?.ctprvn_cd;
        const reg = /^(00|11|41|28|26|30|27|31|29)/;
        const newPositionCheck = reg.test(newCode);
        const result = {
            bbox,
            center: params,
            geometryCoordinates: geometry.coordinates[0] || [],
            address: properties.ctp_kor_nm,
            code: properties?.ctprvn_cd,
            zoomCheck: newPositionCheck,
        };
        return result;
    } catch (error) {
        console.log("err>>");
        return;
    }
};

export const useGetAdemdDongPolyCoord = async (params: ILocationParams) => {
    return fetchDongLocationData(params);
};

export const useGetSigunguPolyCoord = async (params: ILocationParams) => {
    return fetchSigunguLocationData(params);
};

export const useGetSidoPolyCoord = async (params: ILocationParams) => {
    return fetchSidoLocationData(params);
};

//* 내재화 폴리곤 가져오기
export const useGetPolygonByCenter = async (params: IPolygonApiParams) => {
    const result = await axiosAsync
        .post(axiosPath.mapFieldPolygonPath, { ...params })
        .then((res) => {
            return res?.data;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return undefined;
        });
    return result;
};

//* 내재화 폴리곤 가져오기
export const useGetPolygonByClick = async (params: IPolygonApiParams) => {
    const result = await axiosAsync
        .post(axiosPath.mapClickFieldPolygonPath, { ...params })
        .then((res) => {
            return res?.data;
        })
        .catch((err) => {
            console.log("err>>>", err);
            return undefined;
        });
    return result;
};
