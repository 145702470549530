import React, { useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useQueryClient } from "react-query";
import { css } from "@emotion/react";

import RefundDialog from "@src/components/Dialog/refund/RefundDialog";
import RefundsDialogComponents, { DialogType } from "@src/components/Dialog/refund/RefundsDialogComponents";
import MgDialog from "@src/components/Dialog";
import { useFindAllGoStopPayments } from "@src/hooks/goStop/useFindAllGoStopPayments";
import { useSaverPgRefund, useSaverPgRefundV2 } from "@src/hooks/payment/useRefund";
import { Common } from "@src/styles/Common";

const StoreGoStopDialog = () => {
    const queryClient = useQueryClient();

    const guideTextRef = useRef<HTMLDivElement>(null);

    const [openDialog, setOpenDialog] = useState<number>(0);
    const [dialogType, setDialogType] = useState<keyof DialogType | null>(null);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { data, refetch, fetchNextPage, hasNextPage, isFetching } = useFindAllGoStopPayments(
        {
            page: 1,
            limit: 20,
            gostopType: 2,
        },
        true,
    );

    const refundOrder = useSaverPgRefund(
        () => {
            refetch();
            onCloseDialog();
            queryClient.invalidateQueries({
                queryKey: ["goStopPaymentItems"],
            });
        },
        (err) => {
            setSelectedPayment(null);
            const newError: any = err.response?.data;
            setErrorMessage(newError?.message);
            setDialogType("RefundFailed");
        },
    );

    const refundOrderToss = useSaverPgRefundV2(
        () => {
            refetch();
            onCloseDialog();
            queryClient.invalidateQueries({
                queryKey: ["goStopPaymentItems"],
            });
        },
        (err) => {
            setSelectedPayment(null);
            const newError: any = err.response?.data;
            setErrorMessage(newError?.message);
            setDialogType("RefundFailed");
        },
    );

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: false,
        delayInMs: 100,
    });

    const onCloseDialog = () => {
        setOpenDialog(0);
        setTimeout(() => {
            setSelectedPayment(null);
        }, 500);
    };

    const onSubmitRefund = () => {
        if (selectedPayment) {
            console.log(selectedPayment);
            if (selectedPayment.pgAgency === "toss") {
                console.log("toss");
                refundOrderToss.mutate({
                    orderNo: selectedPayment?.orderNo,
                    amount: selectedPayment?.totalPrice,
                    comments: "",
                });
            } else {
                console.log("portone");
                refundOrder.mutate(selectedPayment?.orderNo);
            }
        }
    };

    const onClickCancel = (type: number, payment: any) => {
        if (type === 0) {
            if (payment?.paidCardName?.includes("Store")) {
                setDialogType("RefundsToStore");
            } else {
                setDialogType("NoRefundsExpirationDate");
            }

            setOpenDialog(2);
            return;
        }
        if (type === 1) {
            setDialogType("CancelTicket");
            setSelectedPayment(payment);
            setOpenDialog(1);
            return;
        }
        if (type === 2) {
            setDialogType("CancelAlreadyCompleted");
            setOpenDialog(2);
            return;
        }
    };

    return (
        <>
            <RefundDialog
                ref={loadingRef}
                guideTextOffsetHeight={guideTextRef.current?.offsetHeight}
                data={data}
                isFetching={isFetching}
                onClickCancelButton={onClickCancel}
                ticketType="이용권"
            >
                <div css={footerRootStyle(false)} ref={guideTextRef}>
                    상가 수익률 Go-Stop 이용권 환불/취소 안내
                    <li>
                        “상가 수익률 Go-Stop 이용권”은 결제 시부터 7일 이내 서비스 미이용 시 전액 환불 받을 수 있습니다.(서비스
                        이용 기준은 로그 기준으로 합니다.)
                    </li>
                    <li>
                        결제 후 사용내역이 있거나 결제 시부터 7일 이후에는 할인혜택이 소멸되어 정상금액에서 결제일부터
                        해지신청일까지의 이용일수에 해당하는 금액(=이용금액) 및 상품 정가 금액(결제 금액이 아님)의 20%(=위약금)를
                        제외한 나머지 금액이 환불됩니다.
                    </li>
                    <li>구매 취소나 환불 시에는 결제한 수단으로 환불됩니다.</li>
                    <li>
                        구매 후 7일 이내임에도 ‘내정보{">"}고객센터{">"}자주묻는질문{">"}상가 수익률 Go-Stop 이용권
                        취소/환불하기’에 {"'"}취소 불가{"'"} 상태라면 다음 중 하나 이상의 경우에 해당합니다. <br />
                        1) 상가 수익률 Go-Stop 서비스를 사용하여 직접 환불이 불가능한 경우 <br />
                        2) Google Play 결제를 통해 구매한 경우 <br />
                        3) iOS APP Store 결제를 통해 구매한 경우
                    </li>
                    <li>단, iOS APP Store 결제로 구매한 이용권의 구매 취소는 APPLE 고객센터를 통해서만 가능합니다.</li>
                    <li>
                        Google Play 결제로 구매한 이용권 는 구매 후 48시간 이내에는 Google Play 고객센터를 통해, 48시간 이후에는
                        고객센터를 통해 구매 취소할 수 있습니다.
                    </li>
                    <li>구독 혜택 등 무료로 지급된 이용권은 환불 대상에서 제외됩니다.</li>
                    <li>본 약관은 2024년 5월 31일부터 시행됩니다.</li>
                </div>
            </RefundDialog>
            <MgDialog
                open={openDialog !== 0}
                onCloseView={onCloseDialog}
                width="284px"
                confirmStyle={{ fontSize: "18px" }}
                cancelStyle={{ fontSize: "18px" }}
                cancelText={selectedPayment ? "닫기" : undefined}
                onClickSubmit={onSubmitRefund}
                confirmText={selectedPayment ? "환불하기" : undefined}
            >
                <RefundsDialogComponents dialogType={dialogType} title="상가 Go-Stop" errorMessage={errorMessage} />
            </MgDialog>
        </>
    );
};

export default StoreGoStopDialog;

const footerRootStyle = (props: boolean) => css`
    visibility: ${props ? "hidden" : "visible"};
    background-color: ${Common.colors.grey01};
    color: ${Common.colors.greyPrimary};
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 20px 14px;
    margin-top: 30px;
    li {
        list-style: inside;
    }
    & > .linkLi {
        text-decoration-line: underline;
        text-underline-position: under;
        cursor: pointer;
    }
`;
