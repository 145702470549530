import fetchJsonp from "fetch-jsonp";

const env = process.env;

interface paramsProp {
    pnu: string;
    isNormalBuilding: boolean;
}

const getQueryString = (pnu: string) => {
    const key = env.REACT_APP_V_WORLD_KEY;
    return `?key=${key}&pnu=${pnu}&format=json`;
};

const fetch = async ({ pnu, isNormalBuilding }: paramsProp) => {
    const URL = env.REACT_APP_PUBLIC_DATA_PORTAL_API_URL;
    const queryString = getQueryString(pnu);

    //vworld: 국토교통부_건축물대장_개별주택가격정보서비스
    const normalURL = `https://api.vworld.kr/ned/data/getIndvdHousingPriceAttr${queryString}&numOfRows=40`;
    //공공데이터포털: 국토교통부_건축물대장_공동주택가격정보서비스
    const notNormalPath = "/1611000/nsdi/ApartHousingPriceService/attr/getApartHousingPriceAttr";
    const notNormalURL = `${URL}${notNormalPath}${queryString}`;
    let result: any[] = [];

    if (isNormalBuilding) {
        //개별주택가격정보
        const getHousePriceData = await fetchJsonp(normalURL, {
            jsonpCallbackFunction: "housePriceDatad2",
            timeout: 5000,
        });
        const normalRes = await getHousePriceData.json();
        const field = normalRes.indvdHousingPrices.field;
        field.length &&
            field.map((item: any) => {
                const { stdrYear, stdrMt, housePc } = item;
                result.push({
                    stdr: `${stdrYear}.${stdrMt}`,
                    stdrYear,
                    stdrMt,
                    price: housePc,
                });
            });
    } else {
        //공동주택가격정보
        // const notNormalRes = (await getXmlHttpRequest(notNormalURL).then((res: any) => JSON.parse(res))) as any;
        // if (notNormalRes.apartHousingPrices?.field === undefined) return [];
        // const field = notNormalRes.apartHousingPrices.field;
        // field.length &&
        //     field.map((item: any) => {
        //         const { stdrYear, stdrMt, pblntfPc, prvuseAr } = item;
        //         result.push({
        //             stdrYear,
        //             stdrMt,
        //             price: pblntfPc,
        //             pricePyeong: convertToPyeong(pblntfPc / prvuseAr),
        //         });
        //     });
        // return result;
        result = [];
    }
    return result;
};
const useGetBuildingPrice = async ({ pnu, isNormalBuilding }: paramsProp) => {
    return new Promise((resolve, _reject) => {
        resolve(fetch({ pnu, isNormalBuilding }));
    }).then((res) => res);
};

export default useGetBuildingPrice;
