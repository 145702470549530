import styled from "@emotion/styled";
import ico_arrow_back from "@src/assets/ico_arrow_back.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

type ToastMessageType = {
    message: string;
    setMessage: (message: string) => void;
};

const ToastMessage = ({ message, setMessage }: ToastMessageType) => {
    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        // 내용이 변경되면 애니메이션 재시작
        if (message !== "") {
            setIsLoad(false);
            setTimeout(() => setIsLoad(true), 10);
        }
    }, [message]);

    return (
        <Container>
            {isLoad && (
                <Message
                    onAnimationEnd={() => {
                        setIsLoad(false);
                        setMessage("");
                    }}
                >
                    {message}
                </Message>
            )}
        </Container>
    );
};

export default ToastMessage;

const Message = styled.div`
    width: 100%;
    background: rgba(40, 40, 40, 0.7);
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
    padding: 8px 14px;
    border-radius: 14px;
    animation: fadeInOut 3s forwards;

    @keyframes fadeInOut {
        0%,
        100% {
            transform: translateY(5px);
            opacity: 0;
        }
        10%,
        90% {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

const Container = styled.div`
    position: fixed;
    bottom: 95px;
    left: 14px;
    right: 14px;
    z-index: 9;
`;
