import React, { useState } from "react";
import { css } from "@emotion/react";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import recommend from "@src/assets/subs/recommend.png";
import { Grid } from "@mui/material";
import { ConvertPeriodSubs } from "@src/util/ConvertPeriodSubs";
import { differenceInSeconds } from "date-fns";
import ProductCard from "./ProductCard";
import NewStateLandProduct from "../stateLand/stateLandInfo/NewStateLandProduct";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import IosNewStateLandProduct from "../stateLand/stateLandInfo/IosNewStateLandProduct";
import IosSubscriptionSmallCard from "./IosSubscriptionSmallCard";

interface Props {
    selectedProduct: IProduct;
    onChangeSelectedProduct: (val: IProduct) => void;
    subsCategory: number;
    productList?: IProduct[];
}

const IosNewProductContents: React.FC<Props> = ({ selectedProduct, onChangeSelectedProduct, subsCategory, productList }) => {
    const [openTooltip, setOpenTooltip] = useState<number>(0); // 1 프리미엄 2 국공유지

    const [tooltipContents, setTooltipContents] = useState<any>(<div />);
    return (
        <>
            <div css={rootBoxStyle(subsCategory === selectedProduct?.subsCategory)}>
                <div>
                    <div css={titleRootStyle}>
                        <p css={titleTextStyle}>프리미엄</p>
                    </div>
                    <p css={titleBoxStyle}>
                        {selectedProduct?.subsCategory === subsCategory ? selectedProduct?.name : productList?.[0]?.name}
                    </p>
                    <div
                        css={descBoxStyle}
                        dangerouslySetInnerHTML={{
                            __html:
                                selectedProduct?.subsCategory === subsCategory
                                    ? selectedProduct?.displayDescription
                                        .replaceAll("background-color: #ffffff;", "")
                                        .replaceAll("background-color", "padding: 0px 4px; background-color") ?? ""
                                    : productList?.[0]?.displayDescription
                                        .replaceAll("background-color: #ffffff;", "")
                                        .replaceAll("background-color", "padding: 0px 4px; background-color") ?? "",
                        }}
                    />
                </div>
                {productList && productList?.length > 2 ? <>
                    {
                        <>
                            {productList?.slice(0, 1)?.map((product: IProduct, idx: any) => (
                                <IosNewStateLandProduct
                                    key={JSON.stringify(product)}
                                    product={product}
                                    selectedProduct={selectedProduct}
                                    onClickProduct={onChangeSelectedProduct}
                                    type={3}
                                />
                            ))}
                            {
                                productList.length > 3 &&
                                productList?.slice(1, 2)?.map((product: IProduct, idx: any) => (
                                    <IosNewStateLandProduct
                                        key={JSON.stringify(product)}
                                        product={product}
                                        selectedProduct={selectedProduct}
                                        onClickProduct={onChangeSelectedProduct}
                                        type={3}
                                    />
                                ))
                            }
                            <div css={smallCardContainer}>
                                {productList?.slice(productList.length > 3 ? 2 : 1)?.map((product: IProduct, idx: any) => (
                                    <IosSubscriptionSmallCard
                                        key={JSON.stringify(product)}
                                        product={product}
                                        selectedProduct={selectedProduct}
                                        onChangeSelectedProduct={onChangeSelectedProduct}
                                        subsCategory={3}
                                    />
                                ))}
                            </div>
                        </>
                    }
                </> : productList?.map((product: IProduct, idx: any) => (
                    <IosNewStateLandProduct
                        key={JSON.stringify(product)}
                        product={product}
                        selectedProduct={selectedProduct}
                        onClickProduct={onChangeSelectedProduct}
                        type={3}
                    />
                ))}
            </div>
            {!!openTooltip && (
                <ConfirmModal
                    resultMessage={tooltipContents}
                    onConfirm={() => {
                        setOpenTooltip(0);
                        setTooltipContents(<div />);
                    }}
                />
            )}
        </>
    );
};

export default IosNewProductContents;

const smallCardContainer = css` 
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 14px;
`

const rootBoxStyle = (active: boolean) => css`
    border-bottom: 4px solid #f0f0f0;
    background-color: ${active ? "#dff0ff" : "#ffffff"};
    padding: 24px 14px;
`;

const productTitleStyle = css`
    color: #282828;
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
    font-family: "S-Core Dream4";
`;

const titleRootStyle = css`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const titleTextStyle = css`
    font-size: 22px;
    font-family: Sandoll Samliphopangche;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.44px;
    color: #282828;
`;

const typeBoxStyle = css`
    font-size: 22px;
    font-family: Sandoll Samliphopangche;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
    letter-spacing: -0.44px;
    color: #0047ff;
`;

const titleBoxStyle = css`
    color: #0047ff;
    font-family: Sandoll Samliphopangche;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.4px;
`;

const descBoxStyle = css`
    margin-top: 8px;
    ul {
        list-style: unset;
        li {
            list-style: unset;
            margin-left: 22px;
            margin-bottom: 8px;
            list-style-position: outside;
            text-align: left !important;
            & > span {
                font-weight: 700;
                display: inline-block;
            }
        }
    }
`;

const productRootBoxStyle = css`
    padding-top: 14px;
`;

const productCardBoxFirstStyle = (active: boolean, isEvent: boolean) => css`
    position: relative;
    border-radius: 14px;
    border: ${active ? "2px solid #0047FF" : "1px solid #dadada"};
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 7px 14px;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    flex-direction: column;
    gap: 4px;
    ${isEvent && {
        paddingBottom: "42px",
    }};
    & > .recommend {
        position: absolute;
        top: -5px;
        transform: translate(-50%, -50%);
    }
    & > .productInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        color: #0c002c;
        & > .name {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }
        & > .desc {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
    }
    & > .priceBox {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        & > .regularPrice {
            & > .text {
                color: #8d8d8d;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-decoration: line-through;
                text-decoration-color: #8d8d8d;
            }
            & > .discount {
                margin-left: 8px;
                color: #f00000;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
        & > .salePrice {
            & > .text {
                color: #0047ff;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
            & > .period {
                color: #3c3c3c;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
`;

const productCardBoxSecondStyle = (active: boolean, isEvent: boolean) => css`
    position: relative;
    border-radius: 14px;
    border: ${active ? "2px solid #0047FF" : "1px solid #dadada"};
    background-color: #ffffff;
    box-sizing: border-box;
    padding: ${isEvent ? "6px 0 30px" : "18px 0"};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 100%;
    justify-content: center;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    & > .recommend {
        position: absolute;
        top: -2px;
        left: 15px;
        transform: translate(-50%, -50%);
    }
    & > .name {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
    }
    & > .priceBox {
        text-align: center;
        & > .regularPrice {
            & > .text {
                color: #8d8d8d;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-decoration: line-through;
                text-decoration-color: #8d8d8d;
            }
            & > .discount {
                margin-left: 8px;
                color: #f00000;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
        & > .salePrice {
            & > .text {
                color: #0047ff;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
            & > .period {
                color: #3c3c3c;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
`;

const tooltipBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #0c002c;
    & > .title {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    & > .content {
        text-align: center;
        color: #0c002c;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    li {
        list-style: inside;
        margin: 0;
        padding: 0;
    }
    li::marker {
        content: "∙";
        font-size: 20px;
        margin: 0;
    }
`;
