import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import ico_check_white from "@src/assets/ico_check_white.svg";
import React from "react";
import { useFilterStore } from "@src/store/filterStore";

type WithoutFilterPropsType = {
    withoutState: boolean;
    setWithoutState: (state: boolean) => void;
    type?: string;
};

const WithoutFilter = ({ withoutState, setWithoutState, type }: WithoutFilterPropsType) => {
    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);
    const filterState = useFilterStore((state) => state.filterState);

    return (
        <WithoutFilterContainer>
            <div
                style={{ width: 32, paddingTop: 4 }}
                onClick={() => {
                    const resultParams = {
                        ...filterState,
                        auction_master_except: !withoutState,
                    };

                    setStoreFilterState(resultParams, true);
                    setWithoutState(!withoutState);
                }}
            >
                <CheckBox isActive={withoutState}>
                    <img src={ico_check_white} />
                </CheckBox>
            </div>
            <div>
                <h3>제외하기</h3>
                <div>
                    <span className="example">선택한 필터에 해당하는 매물 제외하고 매물보기</span>
                    <br />
                    <span className="ex">ex. 지분매각 필터 선택 후 제외하기 체크시 지분매각 매물을 제외하고 보여줍니다</span>
                </div>
            </div>
        </WithoutFilterContainer>
    );
};

export default WithoutFilter;

const WithoutFilterContainer = styled.div`
    display: flex;
    padding: 0px 14px;
    margin-top: 24px;

    h3 {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0px;
    }

    .example {
        font-size: 13px;
        line-height: 20px;
        color: #505050;
    }

    .ex {
        font-size: 12px;
        line-height: 18px;
        color: #a3a3a3;
    }
`;

const CheckBox = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px !important;
    height: 20px !important;
    background: ${({ isActive }) => (isActive ? Common.colors.aucMarkerColor : "#A3A3A3")};
    border-radius: 100%;
    margin-right: 14px;
    img {
        width: 10px;
    }
`;
