import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DeungiHeader from "@src/components/deungi/DeungiHeader";
import DeungiTab from "@src/components/deungi/DeungiTab";
import DeungiTicketBoard from "@src/components/deungi/DeungiTicketBoard";
import DeungiContents from "@src/components/deungi/DeungiContents";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeungiWatch from "@src/components/deungi/DeungiWatch";
import DeungiReadingAlarm from "@src/components/deungi/DeungiReadingAlarm";
import { TICKET_TYPE } from "@src/components/deungi/common/enum";
import { SubmitDeungiRequestDto } from "@src/hooks/deungi/dto/Deungi.dto";
import { useSelectedDeungi, useSelectedExistDeungi } from "@src/store/deungiPayment";
import { useDeungiSubmit, useExistDeungiSubmit } from "@src/hooks/deungi/useDeungiSubmit";

const DeungiMain = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [watchInfo, setWatchInfo] = useState("");
    const [handleSelect, setHandleSelect] = useState(searchParams.get("type") || "search");
    useEffect(() => {
        setHandleSelect(searchParams.get("type") || "search");
        setWatchInfo(searchParams.get("watch") || "");
    }, [searchParams]);



    return (
        <Container>
            <DeungiWatch watchInfo={watchInfo} />
            <DeungiHeader title="미스고 부동산 등기열람" />
            <DeungiTab handleSelect={handleSelect} setHandleSelect={setHandleSelect} />
            <DeungiTicketBoard />
            <DeungiContents handleSelect={handleSelect} />
            <DeungiReadingAlarm handleSelect={handleSelect} />
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: 100vh;
    background-color: white;
    height: calc(var(--vh, 1vh) * 100);
    animation: fadeIn 0.5s forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const Wrapper = styled.div``;

export default DeungiMain;
