import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery } from "react-query";

export const useGetCharginList = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.CharginPath}`, {
        params,
    });

    return response;
};

export const useGetUseList = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.deungiList}`, {
        params,
    });

    return response;
};

export const useGetUsedList = async () => {
    const response = await axiosInstance.get(`${axiosPath.ticketUsedList}`);

    return response;
};

export const useRefundTicket = async (id: number) => {
    const body: any = {
        ticket_no: id,
    };
    const response = await axiosInstance.post(`${axiosPath.refundTicket}`, body);

    return response;
};

export const useGetLiveCnt = async () => {
    const response = await axiosInstance.get(`${axiosPath.ticketCount}`);
    return response;
};

const getTicketPaymentItems = async (params: any) => {
    const reponse = await axiosInstance.get(`${axiosPath.deungiMerPath}`, {
        params,
    });
    return reponse.data;
};

export const useFindAllTicketPayments = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["ticketPaymentItems"],
        queryFn: ({ pageParam }) => getTicketPaymentItems({ ...params, page: pageParam }),
        getNextPageParam: (_lastPage: any, pages: any) => {
            return pages[0]?.totalCnt > pages?.length * 20 ? pages.length + 1 : null;
        },
        enabled,
    });
};
