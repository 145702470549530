import React, { useState } from "react";
import { css } from "@emotion/react";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import recommend from "@src/assets/subs/recommend.png";
import { Grid } from "@mui/material";
import { ConvertPeriodSubs } from "@src/util/ConvertPeriodSubs";
import { differenceInSeconds } from "date-fns";
import ProductCard from "./ProductCard";
import IosProductCard from "./IosProductCard";

interface Props {
    selectedProduct: IProduct;
    onChangeSelectedProduct: (val: IProduct) => void;
    subsCategory: number;
    productList?: IProduct[];
}

const IosProductContents: React.FC<Props> = ({ selectedProduct, onChangeSelectedProduct, subsCategory, productList }) => {
    const totalCount = productList?.length;

    return (
        <div css={rootBoxStyle(subsCategory === selectedProduct?.subsCategory)}>
            <div>
                <p css={typeBoxStyle}>
                    {subsCategory === 3 && "프리미엄"}
                    {subsCategory === 2 && "스탠다드"}
                    {subsCategory === 1 && "베이직"}
                </p>
                <p css={titleBoxStyle}>
                    {selectedProduct?.subsCategory === subsCategory ? selectedProduct?.name : productList?.[0]?.name}
                </p>
                <div
                    css={descBoxStyle}
                    dangerouslySetInnerHTML={{
                        __html:
                            selectedProduct?.subsCategory === subsCategory
                                ? selectedProduct?.displayDescription
                                    .replaceAll("background-color: #ffffff;", "")
                                    .replaceAll("background-color", "padding: 0px 4px; background-color") ?? ""
                                : productList?.[0]?.displayDescription
                                    .replaceAll("background-color: #ffffff;", "")
                                    .replaceAll("background-color", "padding: 0px 4px; background-color") ?? "",
                    }}
                />
            </div>
            <Grid container spacing={1.75} css={productRootBoxStyle} alignItems="stretch">
                {productList?.map((product, idx) => {
                    const checkWidth = totalCount === 2 ? 6 : !idx || idx === 3 ? 12 : 6;
                    const checkCSS =
                        totalCount === 2
                            ? productCardBoxSecondStyle
                            : !idx || idx === 3
                                ? productCardBoxFirstStyle
                                : productCardBoxSecondStyle;
                    return (
                        <IosProductCard
                            key={JSON.stringify(product)}
                            product={product}
                            width={checkWidth}
                            mainCSS={checkCSS}
                            selectedProduct={selectedProduct}
                            onChangeSelectedProduct={onChangeSelectedProduct}
                        />
                    );
                })}
            </Grid>
        </div>
    );
};

export default IosProductContents;

const rootBoxStyle = (active: boolean) => css`
    border-bottom: 4px solid #f0f0f0;
    background-color: ${active ? "#dff0ff" : "#ffffff"};
    padding: 24px 14px;
`;

const typeBoxStyle = css`
    color: #282828;
    font-family: Sandoll Samliphopangche;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.48px;
`;

const titleBoxStyle = css`
    color: #0047ff;
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
    font-family: "S-Core Dream4";
    /* color: #0047ff;
    font-family: Sandoll Samliphopangche;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.4px; */
`;

const descBoxStyle = css`
    margin-top: 8px;
    ul {
        list-style: unset;
        li {
            list-style: unset;
            margin-left: 22px;
            margin-bottom: 8px;
            list-style-position: outside;
            text-align: left !important;
            & > span {
                font-weight: 700;
                display: inline-block;
            }
        }
    }
`;

const productRootBoxStyle = css`
    padding-top: 14px;
`;

const productCardBoxFirstStyle = (active: boolean, isEvent: boolean) => css`
    position: relative;
    border-radius: 14px;
    border: ${active ? "2px solid #0047FF" : "1px solid #dadada"};
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 7px 14px;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    display: flex;
    flex-direction: column;
    gap: 4px;
    ${isEvent && {
        paddingBottom: "42px",
    }};
    & > .recommend {
        position: absolute;
        top: -5px;
        transform: translate(-50%, -50%);
    }
    & > .productInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        color: #0c002c;
        & > .name {
            font-size: 16px;
            line-height: 26px;
        }
        & > .desc {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
    }
    & > .priceBox {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        & > .regularPrice {
            & > .text {
                color: #8d8d8d;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-decoration: line-through;
                text-decoration-color: #8d8d8d;
            }
            & > .discount {
                margin-left: 8px;
                color: #f00000;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
        & > .salePrice {
            & > .text {
                color: #0047ff;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
            & > .period {
                color: #3c3c3c;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
`;

const productCardBoxSecondStyle = (active: boolean, isEvent: boolean) => css`
    position: relative;
    border-radius: 14px;
    border: ${active ? "2px solid #0047FF" : "1px solid #dadada"};
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 18px 14px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    justify-content: center;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    & > .recommend {
        position: absolute;
        top: -2px;
        left: 15px;
        transform: translate(-50%, -50%);
    }
    & > .name {
        color: #0c002c;
        font-size: 16px;
        line-height: 26px;
    }
    & > .priceBox {
        & > .regularPrice {
            & > .text {
                color: #8d8d8d;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-decoration: line-through;
                text-decoration-color: #8d8d8d;
            }
            & > .discount {
                margin-left: 8px;
                color: #f00000;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
        & > .salePrice {
            & > .text {
                color: #0047ff;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
            }
            & > .period {
                color: #3c3c3c;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
`;
