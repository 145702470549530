import React from "react";
import { IFilterParamsType } from ".";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

interface StatusMap {
    [key: string]: number | number[];
}

export const auctionStatusList = ["전체", "진행중", "변경", "매각", "기타"];
export const publicAuctionStatusList = ["전체", "진행중", "매각", "기타"];

export const statusValue: StatusMap = {
    전체: 0,
    진행중: [1, 2],
    매각: 3,
    기타: 4,
    변경: 5,
};

export function findKeyByValueFromStatus(targetValue: number) {
    for (const key in statusValue) {
        if (Array.isArray(statusValue[key])) {
            const value = statusValue[key] as number[];
            if (value.includes(targetValue)) {
                return key;
            }
        }
        if (statusValue[key] === targetValue) {
            return key;
        }
    }
    return null;
}

function createStatusMap(list: string[]): StatusMap {
    return list.reduce((acc, status) => {
        acc[status] = statusValue[status];
        return acc;
    }, {} as StatusMap);
}

const AuctionStatusFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    const statusList = filterParams.type === 2 ? publicAuctionStatusList : auctionStatusList;
    const statusMap = createStatusMap(statusList);

    const onClickStatus = (newStatus: number | number[]) => {
        const statusTotalCount = Object.values(statusMap)
            .flat()
            .reduce((acc, cur) => acc + (Array.isArray(cur) ? cur.length : 1), 0);
        const combinedStatus = Array.isArray(newStatus)
            ? [...filterParams.status, ...newStatus]
            : [...filterParams.status, newStatus];

        const uniqueStatus = Array.from(new Set(combinedStatus));
        const isStatusAll = newStatus === 0 || uniqueStatus.length === statusTotalCount - 1;

        if (isStatusAll) {
            setFilterParams({ ...filterParams, status: [] });
            return;
        }

        let updatedStatus = [...filterParams.status];

        if (Array.isArray(newStatus)) {
            updatedStatus =
                updatedStatus.filter((status) => !newStatus.includes(status)).length === updatedStatus.length
                    ? Array.from(new Set([...updatedStatus, ...newStatus]))
                    : updatedStatus.filter((status) => !newStatus.includes(status));
        } else {
            const isDuplicate = updatedStatus.includes(newStatus);
            if (isDuplicate) {
                updatedStatus = [...updatedStatus.filter((status) => status !== newStatus)];
            } else {
                updatedStatus.push(newStatus);
            }
        }

        setFilterParams({ ...filterParams, status: updatedStatus.sort() });
    };

    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(filterParams.status.some((val) => !val) || !filterParams.status.length)}>
                <p className="titleText">진행상태</p>
                {allFilter ? (
                    <button className="allButton" onClick={() => setFilterParams({ ...filterParams, status: [] })}>
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={selectButtonBoxStyle}>
                {statusList.map((item, idx) => {
                    const index = statusValue[item];

                    const checkActive = index
                        ? filterParams.status?.some((val) => (Array.isArray(index) ? index.includes(val) : val === index))
                        : filterParams.status.some((val) => !val) || !filterParams.status.length;
                    return (
                        (!allFilter || !!idx) && (
                            <button
                                key={item}
                                css={selectButtonStyle(checkActive)}
                                onClick={() => {
                                    onClickStatus(index);
                                }}
                            >
                                {item}
                            </button>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default AuctionStatusFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: ${Common.colors.grey02};
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;
