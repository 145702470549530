import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { ClickAwayListener } from "@mui/material";
import { Common } from "@src/styles/Common";
import { usePushSearchKeyword } from "@src/hooks/item/useRecentSearchList";
import filterOff from "@src/assets/filterOff.png";
import filterOn from "@src/assets/filterOn.png";
import inputXBtn from "@src/assets/inputXBtn.png";
import ico_search_black_bold from "@src/assets/ico_search_black_bold.svg";
import micIcon from "@src/assets/basicIcon/micIcon.svg";
import { WebAppBridge, transferToNative } from "@src/lib/WebAppBridge";
import { clear, time } from "console";
import { useNewFilterSearchText, useNewFilterStore } from "@src/store/newFilterStore";

interface ItemsSearchInputProps {
    isShowFilter: boolean;
    setIsShowFilter: Dispatch<SetStateAction<boolean>>;
    searchInputValue: string;
    setSearchInputValue: (SearchKeyword: string) => void;
    isFocusSearch: boolean;
    setIsFocusSearch: Dispatch<SetStateAction<boolean>>;
    setIsPushKeyword: Dispatch<SetStateAction<boolean>>;
    isPushKeyword: boolean;
    fetchData: (type: string) => void;
}

const ItemsSearchInput = ({
    isShowFilter,
    setIsShowFilter,
    searchInputValue,
    setSearchInputValue,
    isFocusSearch,
    setIsFocusSearch,
    setIsPushKeyword,
    isPushKeyword,
    fetchData,
}: ItemsSearchInputProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [checkFocus, setCheckFocus] = useState<boolean>(false);
    const filterState = useNewFilterStore((state) => state.newFilterState);
    const setStoreFilterState = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setFilterSearchText = useNewFilterSearchText((state) => state.setNewFilterSearchText);
    const inputRef = useRef<any>();

    WebAppBridge.setSpeechResult = (val: string) => {
        setSearchInputValue(val);
    };

    const onFocusCheck = (evt: React.FocusEvent<HTMLTextAreaElement>) => {
        const element = evt.target;
        element.selectionStart = element.value.length; // 커서 마지막으로 이동
    };
    useEffect(() => {
        if (inputRef?.current) {
            if (inputRef.current?.scrollHeight < 64 || !searchInputValue || !isFocusSearch) {
                inputRef.current.style.setProperty("height", "");
            } else {
                inputRef.current.style.setProperty("height", `${inputRef.current?.scrollHeight}px`);
            }
        }
    }, [searchInputValue, isFocusSearch]);

    useEffect(() => {
        if (searchInputValue.length > 0) {
            const timer = setTimeout(() => {
                setFilterSearchText(searchInputValue);
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        } else return;
    }, [searchInputValue]);

    return (
        <>
            <div css={InputWrapper(inputRef.current?.scrollHeight > 40, isFocusSearch)}>
                <div className="searchWrap">
                    <img
                        src={ico_search_black_bold}
                        className="magnifyGlass"
                        onClick={() => {
                            setIsFocusSearch(true);
                            !searchInputValue && setSearchParams(new URLSearchParams({ history: "on" }));
                        }}
                    />
                </div>
                <ClickAwayListener
                    onClickAway={() => {
                        setIsFocusSearch(false);
                        inputRef.current.scrollTo({
                            top: 0,
                        });
                    }}
                >
                    <textarea
                        placeholder="소재지, 사건번호 검색"
                        value={searchInputValue}
                        ref={inputRef}
                        onChange={(e: any) => {
                            setSearchInputValue(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                isPushKeyword && searchInputValue.length > 1 && usePushSearchKeyword(searchInputValue);
                                fetchData("filterChanged");
                                inputRef.current.blur();
                                setIsPushKeyword(false);
                            }
                        }}
                        onClick={() => {
                            setIsFocusSearch(true);
                            !searchInputValue && setSearchParams(new URLSearchParams({ history: "on" }));
                        }}
                        onFocusCapture={onFocusCheck}
                    />
                </ClickAwayListener>
                {navigator.userAgent.toLowerCase().includes("iphone") && (
                    <img
                        src={micIcon}
                        alt=""
                        css={micIconStyle}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            transferToNative("webSearch", "onClickSearchMic");
                        }}
                    />
                )}
                <div className="imgWrapper">
                    {searchInputValue && <img src={inputXBtn} className="XBtn" onClick={() => setSearchInputValue("")} />}
                </div>
                <div className="imgDiv">
                    <img
                        src={isShowFilter ? filterOn : filterOff}
                        alt=""
                        onClick={() => searchParams.get("history") !== "on" && setIsShowFilter(!isShowFilter)}
                    />
                </div>
            </div>
        </>
    );
};

export default ItemsSearchInput;
const InputWrapper = (isUpTwoLine: boolean, isFocus: boolean) => css`
    position: relative;
    width: 100%;
    height: max-content;
    padding: 4px 0px;
    box-sizing: border-box;
    z-index: 3;
    display: flex;
    gap: 8px;
    /* margin-top: 10px; */
    textarea {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: calc(100% - 48px);
        padding: 9px 36px 8px 40px;
        min-height: 40px;
        height: 40px;
        max-height: 63px;
        resize: none;
        opacity: 1;
        outline: none;
        border: 1px solid rgb(227, 227, 230);
        border-radius: 8px;
        font-size: 14px;
        background: #ffffff;
        overflow: ${isFocus ? "scroll" : "hidden"};
        line-height: ${isUpTwoLine ? "24px" : "22px"};
        color: ${Common.colors.normalTextColor};
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    textarea::-webkit-scrollbar {
        display: none;
    }
    textarea:disabled {
        background-color: #fff;
        opacity: 1;
    }

    textarea:focus {
        outline: none;
        border: 1px solid ${Common.colors.normalTextColor};
    }

    textarea:disabled {
        background-color: #fff;
        opacity: 1;
    }

    textarea:focus {
        outline: none;
        border: 1px solid ${Common.colors.normalTextColor};
    }

    .searchWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 14px;
        top: 14px;
        gap: 8px;
        height: 24px;
        .magnifyGlass {
            width: 20px;
            height: 20px;
        }
    }
    .imgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 82px;
        top: 13.5px;
        gap: 8px;
        height: 24px;
        .XBtn {
            width: 16px;
            height: 16px;
        }
    }
    .imgDiv {
        width: 42px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
        }
    }
`;

const micIconStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 48px;
    top: 50%;
    transform: translate(0, -50%);
    gap: 8px;
    padding: 8px;
`;
