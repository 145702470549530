import useGetInternalTable from "@src/hooks/map/useGetInternalTable";
import React, { useMemo } from "react";
import InternalTable from "./InternalTable";
import { InternalTableColumns } from "./InternalTableColumns";

const IntrenalTablePage = () => {
    const { tryGetRealPriceData, trySetAllRealPriceDataList, viewRealPriceData, setViewRealPriceData, allRealPriceData } =
        useGetInternalTable();

    const columnsData = InternalTableColumns();
    const columns: any = useMemo(() => columnsData, []);

    return (
        <>
            <InternalTable
                isTabChange={false}
                columns={columns}
                tryGetData={tryGetRealPriceData}
                setAllTableList={trySetAllRealPriceDataList}
                viewingData={viewRealPriceData}
                setViewingData={setViewRealPriceData}
                allTableDataList={allRealPriceData}
            />
        </>
    );
};

export default IntrenalTablePage;
