import React, { useRef, useEffect, useState } from "react";
import * as echarts from "echarts";
import { Common } from "@src/styles/Common";
import { css } from "@emotion/react";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import convertStrToPrice from "@src/util/convertStrToPrice";

interface lineChartProps {
    xAxis: any[];
    chartData: any[];
    isPyeong?: boolean;
    isUnitShow?: boolean;
}
function LineChart({ xAxis, chartData, isPyeong, isUnitShow = true }: lineChartProps) {
    const chartRef = useRef(null);
    const [summary, setSummary] = useState({ date: "", value: "000000원" });
    const [options, setOptions] = useState<any>({});
    useEffect(() => {
        let dataLength = chartData.length - 1;
        let xAxisLength = xAxis.length - 1;
        setSummary({ date: `${xAxis[xAxisLength]}년`, value: `${convertStrToPrice(chartData[dataLength])}원` });
    }, [chartData]);
    useEffect(() => {
        setOptions({
            grid: {
                height: "auto",
            },
            dataset: [
                {
                    id: "test",
                    source: xAxis,
                },
            ],
            xAxis: {
                type: "category",
                data: xAxis,
                splitNumber: 5,
                axisTick: {
                    alignWithLabel: true,
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: `${Common.colors.grey03}`,
                    },
                },
                axisLabel: {
                    color: `${Common.colors.darkGrey02}`,
                    fontWeight: 400,
                    fontSize: 11,
                    lineHeight: 20,
                    align: "center",
                    formatter: function (value: any) {
                        let formatting = value.substring(2);
                        return `'${formatting}`;
                    },
                },
            },
            yAxis: {
                type: "value",
                offset: 25,
                axisLine: {
                    lineStyle: {
                        color: `${Common.colors.grey03}`,
                    },
                },
                axisLabel: {
                    color: `${Common.colors.darkGrey02}`,
                    inside: true,
                    formatter: (value: any, index: any) => {
                        if (index === 0) return "";
                        let result = convertNumberToKorean(value);
                        return result + "원";
                    },
                    verticalAlign: "top",
                    align: "left",
                    // rotate: 15,
                    fontWeight: 400,
                    fontSize: 10,
                    lineHeight: 20,
                },
            },
            axisPointer: [
                {
                    z: 10,
                    lineStyle: {
                        type: "solid",
                        color: `${Common.colors.darkGrey03}`,
                    },
                },
            ],
            series: [
                {
                    z: 30,
                    type: "line",
                    data: chartData,
                    cursor: "none",
                    smooth: true, //라인 부드럽게
                    symbol: "circle", //라인 위 동그라미
                    symbolSize: 9,
                    showSymbol: chartData.length === 1,
                    encode: {
                        x: "Year",
                        y: "Price",
                        itemName: "Year",
                        tooltip: ["Price"],
                    },
                    lineStyle: {
                        width: 1,
                        type: "solid",
                        color: `${Common.colors.blue}`,
                    },
                    itemStyle: {
                        color: `${Common.colors.chartDotColor}`,
                        borderColor: `${Common.colors.chartDotColor}`,
                    },
                    connectNulls: true, //빈값 연결
                },
            ],
            tooltip: {
                trigger: "axis",
                className: "tooltip",
                formatter: (value: any) => {
                    const { axisValue, data } = value[0];
                    let result = convertNumberToKorean(data);
                    setSummary({ date: `${axisValue}년`, value: `${convertStrToPrice(data)}원` });
                    return `${axisValue}년 <br/>${result}원`;
                },
                textStyle: {
                    fontFamily: "GmarketSansTTF",
                    fontWeight: 400,
                    fontSize: 11,
                    lineHeight: 20,
                    align: "center",
                },
                alwaysShowContent: true,
            },
            textStyle: {
                fontFamily: "GmarketSansTTF",
                fontWeight: 400,
                fontSize: 11,
                lineHeight: 20,
            },
        });
    }, [chartData]);
    useEffect(() => {
        if (chartRef.current) {
            const chart = echarts.init(chartRef.current);

            chart.setOption(options);
        }
    }, [options, chartRef]);

    return (
        <>
            <div css={summPanel}>
                <span className="date">기준연도 {summary.date}</span>
                <br />
                <span className="value">
                    {isPyeong
                        ? `${summary.value}${isUnitShow ? " (평당)" : ""}`
                        : `${summary.value}${isUnitShow ? " (㎡당)" : ""}`}
                </span>
            </div>
            {chartData.length > 1 && (
                <div
                    ref={chartRef}
                    style={{
                        width: "100%",
                        height: "280px",
                        overflow: "none",
                    }}
                    css={lineChartWrap}
                />
            )}
        </>
    );
}
const lineChartWrap = css`
    margin: 0 auto;
    .tooltip {
        z-index: 2 !important;
        background-color: ${Common.colors.white};
        box-shadow: none;
    }
`;
const summPanel = css`
    height: fit-content;
    text-align: center;
    background-color: ${Common.colors.bgMint};
    padding: 4px 0;
    margin-top: 28px;
    margin-left: 14px;
    margin-right: 14px;
    .date {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: ${Common.colors.normalTextColor};
    }
    .value {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: ${Common.colors.blue};
    }
`;

export default LineChart;
