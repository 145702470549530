import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { useState } from "react";
import arrowDown from "../../../assets/ico_arrow_down.png";
import ico_arrow_up_black from "../../../assets/ico_arrow_up_black_bold.png";

interface GuideItemProps {
    text: string;
    contents: any;
}

const GuideItem = ({ text, contents }: GuideItemProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(text === "부동산 경매 캠퍼스 강의 이용안내" ? true : false);

    return (
        <>
            <StyledGuideItem isOpen={isOpen}>
                <StyledGuideButton
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <div className="text">{text}</div>
                    <img src={isOpen ? ico_arrow_up_black : arrowDown} />
                </StyledGuideButton>
                {isOpen && contents}
            </StyledGuideItem>
        </>
    );
};

export default GuideItem;

const StyledGuideItem = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 14px;
    background-color: #fbf8f8;
    width: 100%;
    height: ${(props) => (props.isOpen ? "fit-content" : "52px")};
    border-radius: 14px;
    padding: 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #a3a3a3;
    white-space: wrap;
    .textBox {
        height: fit-content;
        width: 100%;
    }
`;

const StyledGuideButton = styled.div`
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: ${Common.colors.normalTextColor};
    background-color: #fbf8f8;
    align-items: center;
    & > img {
        width: 12px;
    }
`;
