import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { defaultStateLandParams } from "../stateLand/useGetStateLand";

export const useFindOneStateLandFilter = (enabled: boolean = false) => {
    return useQuery(
        ["stateLandFilter"],
        async () => {
            const result = await axiosInstance
                .get(`${axiosPath.inventory}?key=stateLandFilter`)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    if (err?.response?.data?.message === "인벤토리가 존재하지 않습니다") {
                        return defaultStateLandParams;
                    }
                    return err;
                });
            return result;
        },
        {
            enabled,
        },
    );
};

export const useSaverStateLandFilter = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: string) => {
            try {
                const res = await axiosInstance.post(axiosPath.inventory, {
                    key: "stateLandFilter",
                    value: params,
                });
                return res?.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
