import React from "react";

export const InternalTableColumns = () => {
    return [
        {
            accessor: "day",
            Header: () => <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>거래일</div>,

            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>{value}</div>
            ),
        },
        {
            accessor: "price",
            Header: () => <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>금액</div>,

            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>{value}</div>
            ),
        },
        {
            accessor: "size",
            Header: () => <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>면적</div>,

            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>{value}</div>
            ),
        },
        {
            accessor: "distance",
            Header: () => <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>거리</div>,

            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100%", textAlign: "center", boxSizing: "border-box" }}>{value}</div>
            ),
        },
    ];
};
