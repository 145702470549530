import { axiosInstance } from "@src/api/axiosConfig";

const { naver } = window;
const naverMaps = naver.maps;

const fetchData = async (coords: any, orders = "addr") => {
    let { _lat: lat, _lng: lng } = coords;
    const url = process.env.REACT_APP_NAVER_MAP_API_URL_MAP_REVERSE;
    const response = await axiosInstance.get(
        `${url}?coords=${lng},${lat}&orders=${orders}&output=json&X-NCP-APIGW-API-KEY-ID=v3uhenngxa`,
    );
    if (response.status !== naverMaps.Service.Status.OK) return;

    let results = response?.data.results[0];

    const { land, region } = results;
    const { number1, number2 } = land;

    let si = region?.area1?.name ?? "";
    let gu = region?.area2?.name ?? "";
    let dong = region?.area3?.name ?? "";
    let isMountain = land.type;
    let etc = isMountain === "2" ? "산" : "";

    let jibun = number2 ? `${number1}-${number2}` : number1;

    let address = `${si} ${gu} ${dong} ${etc} ${jibun}`;
    return {
        si,
        gu,
        dong,
        etc,
        jibun,
        address,
        isMountain,
    };
};

const useGetAddressFromCoords = (coords: any, orders?: string): any => {
    return fetchData(coords, orders);
};

export default useGetAddressFromCoords;
