import React, { useState } from "react";
import { css } from "@emotion/react";
import { Accordion, AccordionDetails, AccordionSummary, Divider, styled } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

interface Props {}

const SubscriptionFaq: React.FC<Props> = () => {
    const faqList = [
        {
            title: "유료로 결제해야 서비스를 이용할 수 있나요?",
            contents: (
                <p css={descStyle}>
                    필지(토지·건물) 상세 정보 열람, 물건 검색은 무료로 이용할 수 있으나 경공매 물건 상세 정보 열람, AI추천,
                    Go-Stop(등기분석), 전문가 매칭은 유료서비스로 제공되고 있습니다.
                    <br />
                    <br />
                    또한 회원가입 시 24시간 무료체험이 제공되오니 체험해보시고 이용바랍니다.
                </p>
            ),
        },
        {
            title: "구독 신청 시 의무 사용기간이 있나요?",
            contents: (
                <p css={descStyle}>
                    아니요. 의무 사용 기간은 없으며 언제든지 해지예약(자동결제 해지) 및 즉시해지를 할 수 있습니다.
                    <br />
                    <br />
                    1. 해지예약(자동결제 해지)
                    <br />- 현재 남은 구독 기간까지 사용하고 다음 결제가 되지 않도록 해지하는 것으로
                    [내정보&gt;고객센터&gt;자주묻는질문]에서 언제든지 회원님이 직접 해지하실 수 있습니다.
                    <br />
                    2. 즉시해지
                    <br />- 결제 후 7일 이내 유료서비스 미이용(물건, 필지 상세 미열람) 시 결제금액을 모두 환불받으실 수 있습니다.
                    [내정보&gt;고객센터&gt;자주묻는질문]을 통해 환불이 가능합니다. 환불 시 구독이 즉시 종료되고 사용 기간에 따라
                    환불 금액이 다르니 자세한 내용은 환불액 관련 질문을 참고해주세요(정기구독 환불액은 어떻게 계산되나요?)
                </p>
            ),
        },
        {
            title: "구독을 결제하면 어떤 방식으로 금액이 지불하나요?",
            contents: (
                <p css={descStyle}>
                    미스고 구독은 구독 상품에 따라 결제 방식이 달라집니다.
                    <br />
                    일시납 상품인 1년 이용권과 1개월 이용권을 구매한 경우 일시납으로 상품금액이 결제되며 구독 기간동안 미스고 유료
                    서비스를 이용하실 수 있습니다.
                    <br />
                    정기결제 상품인 월 정기구독 상품을 구매한 경우 최초 결제 시 선택한 카드로 매월 자동 결제되며 자동결제를 원하지
                    않으시다면 고객센타에서 언제든지 구독을 해지할 수 있습니다.
                </p>
            ),
        },
        {
            title: "현금결제도 가능한가요?",
            contents: (
                <p css={descStyle}>
                    [현금결제 방법]
                    <br />
                    1. 하단의 [계좌정보 안내]에 따라 상품 금액 입금
                    <br />
                    2. [“미스고” 앱 접속-내정보-고객센터-문의하기]를 통해 입금자명, 입금액, 미스고 아이디, 현금영수증 발행
                    여부(발행을 원하시는 경우, 현금영수증 발급 신청자 성함과 휴대폰 번호 기재) 내용을 꼭 기재하여 주시기 바랍니다.
                    <br />
                    [계좌정보 안내]
                    <br />
                    계좌정보: 기업은행 448-052496-04-017 예금주: (주)미스고
                    <br />
                    <br />
                    [상품 안내]
                    <br />
                    무통장입금은 1년 이용권, 1개월 이용권만 결제 가능합니다.
                    <br />
                    <br />
                    자세한 상품 가격은 [미스고 구독 상세화면]을 참고해주시기 바랍니다.
                    <br />
                    APP: 홈 화면 {"->"} 구독 신청하기 버튼 클릭
                    <br />
                    PC: 메인 화면 {"->"}구독하기 버튼 클릭
                    <br />
                    <br />※ 구독 상품 지급은 평일 영업시간(9시~17시) 기준 내에 입금하시면 확인 후 처리 예정이며 영업시간 외(평일
                    및 주말, 공휴일 포함) 입금하신 경우, 영업일 기준 익일 이내에 처리해 드릴 예정입니다.
                </p>
            ),
        },
        {
            title: "한 계정으로 PC, 모바일 로그인이 가능한가요?",
            contents: (
                <p css={descStyle}>
                    미스고 부동산은 PC, 모바일이 연동되어 한 번의 가입으로 PC 및 모바일 사용이 가능해요. 2대의 PC, 3대의 모바일
                    기기만 동시 접속이 가능한 점 참고해주세요!
                </p>
            ),
        },
        {
            title: "한 아이디로 여러명이 사용할 수 있나요?",
            contents: (
                <p css={descStyle}>
                    미스고부동산은 한 아이디당 2대의 PC, 3대의 모바일 기기접속이 가능해요 하나의 아이디를 타인과 공유하여 동시
                    접속이 발견될 경우, 서비스가 제한될 수 있으니 유념해주세요.자세한 사항은 이용약관을 참고해 주세요!
                </p>
            ),
        },
        {
            title: "경매캠퍼스 무제한 한 달 열람은 언제, 어떻게 지급되나요?",
            contents: (
                <p css={descStyle}>
                    경매캠퍼스 무제한 한 달 열람이 혜택으로 지급되는 구독 상품을 구매한 경우 캠퍼스 강의는 구독 구매 후 영업일
                    기준 2일 이내에 지급됩니다. 지급된 강의는 [부동산 경매 캠퍼스{">"}내 강의실] 에서 확인할 수 있습니다.
                </p>
            ),
        },
    ];
    const [currentNum, setCurrentNum] = useState<number>(0);
    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle}>자주묻는 질문</div>
            <div css={contentRootStyle}>
                {faqList?.map((faq, idx) => (
                    <TabAccordion
                        square
                        key={idx.toString()}
                        expanded={currentNum === idx}
                        onChange={() => {
                            setCurrentNum((pre) => (pre === idx ? -1 : idx));
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                            <div>{faq?.title}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider sx={{ margin: "0 18px", padding: 0 }} />
                            <div css={contentStyle}>{faq?.contents}</div>
                        </AccordionDetails>
                    </TabAccordion>
                ))}
            </div>
        </div>
    );
};

export default SubscriptionFaq;

const TabAccordion = styled(Accordion)({
    boxShadow: "none",
    margin: "24px 0",
    backgroundColor: "#F6FAFF",
    borderRadius: "14px",
    "&:before": {
        display: "none",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
        color: "#2275EE",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "inherit",
        backgroundColor: "#F6FAFF",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "14px",
        borderRadius: "14px 14px 0 0",
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    // "& .MuiAccordionSummary-content.Mui-expanded": {
    //     margin: "10px 0",
    // },
    "& .MuiAccordionSummary-root": {
        backgroundColor: "#F6FAFF",
        color: "#282828",
        padding: "14px",
        lineHeight: "24px",
        fontWeight: 700,
        fontSize: "14px",
        translation: "all 0.2s",
        borderRadius: "14px",
    },
    "& .MuiAccordionDetails-root": {
        color: "#282828",
        padding: 0,
    },
});

const rootBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
`;

const contentRootStyle = css`
    max-width: 1000px;
    width: calc(100% - 60px);
`;

const titleBoxStyle = css`
    text-align: center;
    font-family: "Sandoll Samliphopangche";
    padding-bottom: 24px;
    color: #0c002c;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
`;

const contentStyle = css`
    padding: 14px;
`;

const moreInfoStyle = css`
    padding: 24px 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    a {
        color: #0047ff;
    }
`;

const descStyle = css`
    color: #505050;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
