import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

export const useGetCampusDetail = async (no: number, saleType: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusClass}/${no}`, {
        params: {
            saleType,
        },
    });
    return response;
};

export const useGetCurriculum = async (no: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusClass}/${no}/curriculums`);
    return response;
};

export const useGetReviews = async (no: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusClass}/${no}/reviews`, {
        params: {
            page: 1,
            limit: 5,
        },
    });
    return response;
};

export const useGetCheckCanPlay = async (no: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusClass}/check/${no}`);

    return response;
};
