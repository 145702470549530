import { axiosPath } from "@src/api/axiosPath";
import { useFilterStore } from "@src/store/filterStore";
import axios from "axios";
import produce from "immer";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
export const initialLocationData = {
    city: [],
    dvsn: [],
    sec: [],
};

export type SelectedAllType = {
    city: boolean;
    dvsn: boolean;
    sec: boolean;
};
let selectedCity = "";
let selectedDvsn = "";
let selectedSec = "";
let selectedType = "";

const ENV_URL: any = process.env.REACT_APP_REGION_API;

const useGetLocations = (selectedLoc?: string[], type?: string) => {
    const updateHistoryFilterFlag = useFilterStore((state) => state.updateHistoryFilterFlag);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);
    const [query, setQuery] = useState("0000000000");
    const [locationData, setLocationData] = useState<LocationsDataType>(initialLocationData);
    const [selectedAll, setSelectedAll] = useState<SelectedAllType>({
        city: false,
        dvsn: false,
        sec: false,
    });
    const [selected, setSelected] = useState("");
    const [selectedParams, setSelectedParams] = useState([""]);
    let mockData = initialLocationData;
    const [activeData, setActiveData] = useState<LocationsDataType>(mockData);
    const [submitPNUData, setSubmitPNUData] = useState<string[]>([]);
    const [toastVisible, setToastVisible] = useState(false);

    const resetLocationData = () => {
        const nextState = produce(locationData, (draft) => {
            draft.dvsn = [];
            draft.sec = [];
        });

        setLocationData(nextState);
    };
    const [activePNUState, setActivePNUState] = useState<string[]>(["All"]);

    const getCityTypePNU = (pnu: string) => {
        const CityPNU = pnu.slice(0, 2);

        const result: string[] = [];
        selectedLoc?.filter((selectedPNU: string) => {
            const selectedPNUCity = selectedPNU.slice(0, 2);
            if (selectedPNUCity === CityPNU) {
                result.push(selectedPNU);
            }
        });

        return result.length !== 0;
    };

    const getDvsnTypePNU = (pnu: string) => {
        const cityDVSN = pnu.slice(0, 5);
        const result: string[] = [];
        selectedLoc?.filter((selectedPNU: string) => {
            if (selectedPNU.includes(cityDVSN)) {
                result.push(selectedPNU);
            }
        });

        return result.length !== 0;
    };

    const getCityPNU = async (cityPNU: string) => {
        const sliceCityPNU = cityPNU.slice(0, 2);
        if (selectedLoc) {
            const sliceLoc = selectedLoc?.map((item) => item.slice(0, item.length));
            const response = await getResponseLocation(`${sliceCityPNU}00000000`, false);

            const data = response.map((item: any) => {
                if (item.cortarName === "전체") {
                    return {
                        ...item,
                        isActive: sliceLoc.includes("00000000") ? true : false,
                    };
                }
                return item;
            });

            return data;
        }
    };

    const getDvsnPNU = async (DvsnPNU: string) => {
        const sliceDvsnPNU = DvsnPNU.slice(0, 5);
        if (selectedLoc) {
            const sliceDvsn = selectedLoc?.map((item) => item.slice(5, item.length));
            const response = await getResponseLocation(`${sliceDvsnPNU}00000`, false);

            const data = response.map((item: any) => {
                if (item.cortarName === "전체") {
                    return {
                        ...item,
                        isActive: sliceDvsn.includes("00000") ? true : false,
                    };
                }
                return item;
            });

            return data;
        }
    };

    const getActiveData = async () => {
        if (selectedLoc) {
            const nextState = await produce(activeData, async (draft) => {
                const response = await getResponseLocation("0000000000");
                draft.city = response;
            });

            const copyNextState = cloneDeep(nextState);

            const test2 = await Promise.all(
                copyNextState.city.map(async (city2) => {
                    if (isEmpty(selectedLoc) && city2.cortarName === "전국") {
                        return {
                            ...city2,
                            isActive: true,
                        };
                    }

                    if (!isEmpty(selectedLoc) && city2.cortarName === "전국") {
                        return {
                            ...city2,
                            isActive: false,
                        };
                    }

                    if (getCityTypePNU(city2.cortarNo)) {
                        return {
                            ...city2,
                            isActive: true,
                            children: await getCityPNU(city2.cortarNo),
                        };
                    }
                    return city2;
                }),
            )
                .then((res) => {
                    return Promise.all(
                        res.map(async (city) => {
                            if (getCityTypePNU(city.cortarNo)) {
                                const test = await Promise?.all(
                                    city.children.map(async (dvsn: LocationDataType) => {
                                        if (getDvsnTypePNU(dvsn.cortarNo)) {
                                            return {
                                                ...dvsn,
                                                isActive: true,
                                                children: await getDvsnPNU(dvsn.cortarNo),
                                            };
                                        }

                                        if (dvsn.cortarName === "전체") {
                                            return {
                                                ...dvsn,
                                                isActive: false,
                                            };
                                        }

                                        return dvsn;
                                    }),
                                );
                                return { ...city, children: test };
                            }
                            return city;
                        }),
                    );
                })
                .then((res2) => {
                    res2.forEach((city) => {
                        if (getCityTypePNU(city.cortarNo)) {
                            city.children.forEach((dvsn: any) => {
                                if (getDvsnTypePNU(dvsn.cortarNo)) {
                                    const withoutAll = dvsn.children.filter((sec: any) => sec.title !== "전체");
                                    const isAll = withoutAll.every((sec: any) => {
                                        return !selectedLoc.includes(sec.cortarNo);
                                    });

                                    dvsn.children.forEach((sec: any) => {
                                        if (selectedLoc.includes(sec.cortarNo)) {
                                            sec.isActive = true;
                                        }

                                        if (!selectedLoc.includes(sec.cortarNo)) {
                                            sec.isActive = false;
                                        }

                                        if (dvsn.isActive && isAll && sec.cortarName === "전체") {
                                            sec.isActive = true;
                                        }
                                    });
                                }
                            });
                        }
                    });

                    return res2;
                });
            setActiveData({
                ...nextState,
                city: test2,
            });
            setUpdateHistoryFilterFlag(true);
        }
    };

    useEffect(() => {
        setUpdateHistoryFilterFlag(false);
    }, []);

    useEffect(() => {
        if ((selectedLoc && updateHistoryFilterFlag === false) || type === "noReal") {
            getActiveData();
        }
    }, [selectedLoc]);

    const isClickAll = (cortarName: string) => {
        const filterData = ["전체", "전국"];
        return filterData.includes(cortarName);
    };

    const getCortarType = (cortarNo: string) => {
        const parseCortarNo = cortarNo.substring(4, 10);

        if (parseCortarNo === "000000") {
            return "dvsn";
        }

        return "sec";
    };

    const getResponseLocation = async (query: string, isAll = true) => {
        const response = await axios.get(`${ENV_URL}/regions/list?cortarNo=${query}`).then((res) => res.data.regionList);
        response.forEach((item: any) => {
            item.isActive = false;
            item.children = [];
        });

        response.unshift({
            cortarNo: `${query}All`,
            centerLat: 0,
            centerLon: 0,
            cortarName: query === "0000000000" ? "전국" : "전체",
            children: [],
            cortarType: getCortarType(response[0].cortarNo),
            isActive: isAll,
        });

        return response;
    };

    const getLocation = async () => {
        const queryPNU = query.replace("All", "");
        const response = await axios.get(`${ENV_URL}/regions/list?cortarNo=${queryPNU}`).then((res) => res.data.regionList);
        response.forEach((item: any) => {
            item.isActive = false;
        });

        if (response[0].cortarType === "city") {
            const nextState = produce(locationData, (draft) => {
                draft.city = response;
                draft.city.unshift({
                    cortarNo: "All",
                    centerLat: 0,
                    centerLon: 0,
                    cortarName: "전국",
                    cortarType: "city",
                    isActive: true,
                    children: [
                        {
                            cortarNo: `All`,
                            centerLat: 0,
                            centerLon: 0,
                            cortarName: "전국",
                            cortarType: "dvsn",
                            isActive: false,
                        },
                    ],
                });
            });
            setLocationData(nextState);
            return;
        }
        if (response[0].cortarType === "dvsn") {
            const nextState = produce(locationData, (draft) => {
                draft.dvsn = response;
                draft.dvsn.unshift({
                    cortarNo: `${queryPNU}All`,
                    centerLat: 0,
                    centerLon: 0,
                    children: [],
                    cortarName: "전체",
                    cortarType: "dvsn",
                    isActive: false,
                });

                draft.sec = [];
            });
            setLocationData(nextState);
            return;
        }
        if (response[0].cortarType === "sec") {
            const nextState = produce(locationData, (draft) => {
                draft.sec = response;
                draft.sec.unshift({
                    cortarNo: `${query}All`,
                    centerLat: 0,
                    centerLon: 0,
                    cortarName: "전체",
                    cortarType: "sec",
                    isActive: false,
                });
            });
            setLocationData(nextState);
            return;
        }

        if (response) {
            mockData = response;
        }
    };
    useEffect(() => {
        if (query !== "All") {
            getLocation();
        }
    }, [query]);

    const handleSelect = async (selected: string, cortarTypeData: LocationDataType[], selectedName: string) => {
        setSelected(selected);
        selectedType = cortarTypeData[1].cortarType;

        let responseLocationData: any;
        if (submitPNUData.length > 9 && !submitPNUData.includes(selected) && selectedName !== "전체") {
            setToastVisible(true);
            return;
        }
        setToastVisible(false);

        if (selectedType !== "sec" && !isClickAll(selectedName)) {
            responseLocationData = await getResponseLocation(selected);
        }

        const result = cortarTypeData.map((item) => {
            if (item.cortarNo === selected) {
                return {
                    ...item,
                    isActive: !item.isActive,
                };
            }
            return item;
        });

        const nextState = produce(locationData, (draft) => {
            if (selectedType === "city") {
                if (selected === "All") {
                    setSelectedAll({
                        ...selectedAll,
                        city: !selectedAll.city,
                    });
                }

                selectedCity = selectedName;
                draft.city = draft.city.map((item) => {
                    if (item.cortarNo !== selected) {
                        return {
                            ...item,
                            isActive: false,
                        };
                    }

                    if (item.cortarNo === "All" && "All" === selected) {
                        return {
                            ...item,
                            isActive: !item.isActive,
                        };
                    }
                    return item;
                });
            }
            if (selectedType === "dvsn") {
                if (selected === "1") {
                    setSelectedAll({
                        ...selectedAll,
                        dvsn: !selectedAll.dvsn,
                    });
                }
                selectedDvsn = selectedName;

                draft[selectedType] = result;
                draft.dvsn = draft.dvsn.map((item) => {
                    if (item.cortarNo !== selected) {
                        return {
                            ...item,
                            isActive: false,
                        };
                    } else return item;
                });
            }
            if (selectedType === "sec") {
                if (selected === "2") {
                    setSelectedAll({
                        ...selectedAll,
                        sec: !selectedAll.sec,
                    });
                }
                selectedSec = selectedName;
                draft[selectedType] = result;
            }
        });

        ///////////////////////////////////////////////////////////////

        let res = activeData["city"].map((item) => item.cortarNo);

        if (selectedType === "city") {
            selectedDvsn = "";
            selectedSec = "";

            const nextState2 = produce(activeData, (draft) => {
                nextState["city"].forEach((city) => {
                    if (city.isActive && !res.includes(selected)) {
                        draft.city = activeData.city.concat(city);
                    }

                    draft.city = draft.city.map((city2: LocationDataType) => {
                        if (city2.cortarName === selectedCity && isEmpty(city2.children)) {
                            return {
                                ...city2,
                                children: responseLocationData,
                            };
                        }

                        if (city2.cortarName === selectedCity) {
                            const withoutAll = city2.children.filter((dvsn: LocationDataType) => dvsn.cortarName !== "전체");
                            const isEmptyActive = withoutAll.every((dvsn: LocationDataType) => dvsn.isActive === false);

                            if (isEmptyActive) {
                                city2.children[0].isActive = true;
                            }

                            if (!isEmptyActive) {
                                city2.children[0].isActive = false;
                            }

                            city2.isActive = true;
                        }

                        return city2;
                    });

                    if (city.cortarName !== selectedCity) {
                        draft.city = draft.city.map((city2: LocationDataType) => {
                            const isNotIncludeActive2 = city2.children.every((dvsn: LocationDataType) => {
                                return dvsn.isActive === false;
                            });

                            if (isNotIncludeActive2 && !isEmpty(city2.children)) {
                                if (city2.cortarName !== selectedCity) {
                                    return {
                                        ...city2,
                                        isActive: false,
                                    };
                                }

                                if (city2.cortarName === selectedCity) {
                                    return {
                                        ...city2,
                                        isActive: true,
                                    };
                                }
                            }

                            return city2;
                        });
                    }
                });
            });

            setActiveData(nextState2);
        }

        if (selectedType === "dvsn") {
            const testState = produce(activeData, (draft) => {
                draft.city = draft.city.map((city: LocationDataType) => {
                    return {
                        ...city,
                        children: city.children.map((dvsn: LocationDataType, index: number) => {
                            const isNotIncludeActive = dvsn.children.every((sec: LocationDataType) => sec.isActive === false);

                            if (selected.includes("All") && selectedCity === city.cortarName) {
                                dvsn.isActive = false;
                                dvsn.children.forEach((sec: LocationDataType) => {
                                    sec.isActive = false;
                                });
                            }

                            if (!isEmpty(dvsn.children) && isNotIncludeActive) {
                                if (dvsn.cortarNo === selected) {
                                    dvsn.children[0].isActive = true;
                                }

                                if (dvsn.children.every((item: any) => item.isActive === false)) {
                                    dvsn.isActive = false;
                                }
                            }

                            if (isNotIncludeActive && dvsn.cortarName === "전체" && selectedCity === city.cortarName) {
                                dvsn.isActive = false;
                            }

                            if (selectedDvsn !== "전체" && selectedCity === city.cortarName && dvsn.cortarName === "전체") {
                                dvsn.isActive = false;
                            }

                            if (dvsn.cortarName === selectedDvsn && !isEmpty(dvsn.children)) {
                                const selectedNextState = nextState["dvsn"][index];
                                if (selectedNextState.isActive && dvsn.cortarName === selectedDvsn) {
                                    return {
                                        ...dvsn,
                                        isActive: true,
                                    };
                                }
                            }

                            if (dvsn.cortarName === selectedDvsn && isEmpty(dvsn.children)) {
                                const selectedNextState = nextState["dvsn"][index];

                                if (selectedNextState.isActive && dvsn.cortarNo === selected) {
                                    return {
                                        ...dvsn,
                                        isActive: true,
                                        children: responseLocationData || [],
                                    };
                                }
                            }

                            return dvsn;
                        }),
                    };
                });
            });

            setActiveData(testState);
        }

        if (selectedType === "sec") {
            const selectedNextState = produce(activeData, (draft) => {
                draft.city = draft.city.map((city: LocationDataType) => {
                    return {
                        ...city,
                        children: city.children.map((dvsn: LocationDataType) => {
                            if (dvsn.cortarName.includes("전국") && selectedCity === city.cortarName) {
                                dvsn.isActive = false;
                            }

                            return {
                                ...dvsn,
                                children: dvsn.children.map((sec: LocationDataType) => {
                                    if (selectedSec === sec.cortarName && dvsn.cortarName === selectedDvsn) {
                                        sec.isActive = !sec.isActive;
                                    }

                                    if (
                                        selected.includes("All") &&
                                        sec.cortarName !== "전체" &&
                                        dvsn.cortarName === selectedDvsn
                                    ) {
                                        sec.isActive = false;
                                    }

                                    if (
                                        !selected.includes("All") &&
                                        sec.cortarName === "전체" &&
                                        dvsn.cortarName === selectedDvsn
                                    ) {
                                        sec.isActive = false;
                                    }

                                    return sec;
                                }),
                            };
                        }),
                    };
                });
            });

            setActiveData(selectedNextState);
        }

        setLocationData(nextState);

        if (selectedType !== "sec") {
            setQuery(selected);
        }
    };

    const onClickRemove = (location: string) => {
        const parseSelectedLocationString = location.split(">");
        const selectedLocation = parseSelectedLocationString[parseSelectedLocationString.length - 1];

        const nextState = produce(activeData, (draft) => {
            draft.city = draft.city.map((city) => {
                return {
                    ...city,
                    children: city.children.map((dvsn: LocationDataType) => {
                        if (dvsn.cortarNo === selectedLocation) {
                            return {
                                ...dvsn,
                                isActive: false,
                            };
                        }

                        return {
                            ...dvsn,
                            children: dvsn.children.map((sec: LocationDataType) => {
                                if (sec.cortarNo === selectedLocation) {
                                    return {
                                        ...sec,
                                        isActive: false,
                                    };
                                } else {
                                    return sec;
                                }
                            }),
                        };
                    }),
                };
            });
        });

        setActiveData(nextState);
    };

    const getActivePNU = () => {
        activeData.city.map((city) => {
            if (city.isActive && !activePNUState.includes(city.cortarNo)) {
                setActivePNUState(activePNUState.concat(city.cortarNo));
            }
            if (!city.isActive && activePNUState.includes(city.cortarNo)) {
                const filterData = activePNUState.filter((cortarNo) => cortarNo !== city.cortarNo);
                setActivePNUState(filterData);
            }

            city.children?.map((dvsn: LocationDataType) => {
                if (dvsn.isActive && !activePNUState.includes(dvsn.cortarNo)) {
                    setActivePNUState(activePNUState.concat(dvsn.cortarNo));
                }
                if (!dvsn.isActive && activePNUState.includes(dvsn.cortarNo)) {
                    const filterData = activePNUState.filter((cortarNo) => cortarNo !== dvsn.cortarNo);

                    setActivePNUState(filterData);
                }

                dvsn.children.map((sec: LocationDataType) => {
                    if (sec.isActive && !activePNUState.includes(sec.cortarNo)) {
                        setActivePNUState([sec.cortarNo, ...activePNUState]);
                    }
                    if (!sec.isActive && activePNUState.includes(sec.cortarNo)) {
                        const filterData = activePNUState.filter((cortarNo) => cortarNo !== sec.cortarNo);
                        setActivePNUState(filterData);
                    }
                });
            });
        });
    };

    // 번뇌
    useEffect(() => {
        getActivePNU();

        if (activePNUState.length === 1 && activePNUState[0] === "All") {
            setActivePNUState(activePNUState.filter((item) => item !== "All"));
        }
    }, [activeData, selected, locationData, submitPNUData]);

    useEffect(() => {
        const nextState = produce(activeData, (draft) => {
            if (!isEmpty(submitPNUData)) {
                draft.city[0].isActive = false;
            }
        });

        setActiveData(nextState);
    }, [submitPNUData]);

    const resetActiveData = () => {
        const nextState = produce(activeData, (draft) => {
            draft.city.forEach((city) => {
                city.isActive = false;

                if (city.cortarName === "전국") {
                    city.isActive = true;
                }

                if (!isEmpty(city.children)) {
                    city.children.forEach((dvsn: any) => {
                        dvsn.isActive = false;

                        if (dvsn.cortarName === "전체") {
                            if (dvsn.children.length !== 0) {
                                dvsn.children[0].isActive = false;
                            }
                        }

                        let isAllFalse = dvsn.children.every((sec: any) => sec.isActive === false);
                        dvsn.children.forEach((sec: any) => {
                            sec.isActive = false;
                        });

                        if (isAllFalse) {
                            dvsn.children = [];
                        }
                    });
                }
            });
        });

        const nextLocationState = produce(locationData, (draft) => {
            draft.city[0].isActive = true;
            draft["dvsn"] = [];
        });

        setActivePNUState(["All"]);
        setLocationData(nextLocationState);
        setActiveData(nextState);
    };

    return {
        locationData,
        resetLocationData,
        activeData,
        selectedType,
        handleSelect,
        selectedAll,
        onClickRemove,
        setActiveData,
        selectedParams,
        setSelectedParams,
        resetActiveData,
        activePNUState,
        setActivePNUState,
        getActiveData,
        setSubmitPNUData,
        submitPNUData,
        toastVisible,
        setToastVisible,
    };
};
export default useGetLocations;
export type LocationDataType = {
    cortarNo: string;
    centerLat: number;
    centerLon: number;
    cortarName: string;
    cortarType: string;
    isActive: boolean;
    children?: any;
};
export type LocationsDataType = {
    city: LocationDataType[];
    dvsn: LocationDataType[];
    sec: LocationDataType[];
};
