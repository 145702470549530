import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

interface IPinParam {
    lat: number;
    lng: number;
}

export interface IPinInformation {
    address?: string;
    buildingName?: string;
    latlng?: any;
    pnu?: string;
    roadAddr?: string;
    clickedLatLng?: any;
}

//* 핀 마커 가져오기
const fetchData = async () => {
    const response = await axiosInstance.get(`${axiosPath.searchedList}?name=pinMarker`);
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response?.data?.payload.data || [];
    const value: IPinParam[] = responseData.length !== 0 ? JSON.parse(responseData[0].value) : [];
    return value;
};

const useFindPinMarker = async () => {
    return await fetchData();
};

//* 핀 마커 저장하기
const useSaverPinMarker = async (params: IPinParam[]) => {
    const PARAMS = JSON.stringify(params);
    const response = await axiosInstance.post(`${axiosPath.searchedList}`, { name: "pinMarker", value: PARAMS });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
};

export { useFindPinMarker, useSaverPinMarker };
