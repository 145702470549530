import React from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import { transferToNative } from "@src/lib/WebAppBridge";

interface Props {
    selectedProduct: IProduct;
    onClickChange: () => void;
    onClickSubmit: () => Promise<void>;
}

const IosBottomInfoPage: React.FC<Props> = ({ selectedProduct, onClickChange, onClickSubmit }) => {
    const [searchParams] = useSearchParams();

    return (
        <>

            <div css={rootBoxStyle}>
                <div css={productInfoBoxStyle}>
                    <p className="title">선택한 구독</p>
                    {selectedProduct &&
                        <div css={productInfoStyle}>
                            <p className="name">{selectedProduct?.name ?? "-"}</p>
                            <button className="btn" onClick={onClickChange}>
                                변경
                            </button>
                        </div>
                    }
                </div>
                {searchParams.get("type") === "7" && (
                    <div css={inAppAgreeBoxStyle}>구독 이용 및 해지/취소 안내를 확인 하였으며, 결제에 동의합니다.</div>
                )}
                <div css={buttonBoxStyle}>
                    <button css={paymentButtonStyle} onClick={onClickSubmit}>
                        결제하기
                    </button>
                </div>
            </div>
        </>
    );
};

export default IosBottomInfoPage;

const rootBoxStyle = css`
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100vw;
    /* padding: 14px 14px 14px 24px; */
`;

const inAppAgreeBoxStyle = css`
    color: #3c3c3c;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    padding: 4px 14px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
`;

const productInfoBoxStyle = css`
    border-radius: 4px 4px 0 0;
    padding: 8px 14px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > .title {
        color: #0047ff;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
`;

const productInfoStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .name {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
    }
    & > .btn {
        padding: 0 8px;
        border-radius: 8px;
        border: 1px solid #0047ff;
        color: #0047ff;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
`;

const buttonBoxStyle = css`
    padding: 0 14px 24px;
`;

const paymentButtonStyle = css`
    padding: 14px;
    border-radius: 14px;
    background-color: #2275ee;
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
    box-sizing: border-box;
`;
