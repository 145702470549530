import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React from "react";
import { css } from "@emotion/react";
import { appearAnimation } from "../animation/FeadIn";
import { PurposeDataTypes } from "@src/hooks/filter/useGetPurpose";

type PurposeFilterPropsType = {
    handleSelect: (selected: string, type: "대분류" | "중분류", submitPurposeData?: string[], id?: string) => void;
    handleSelectAll: (selectedQuery: string, id: string) => void;
    purposeState: PurposeDataTypes;
    query: string;
    style?: any;
    submitPurposeData?: string[];
};

let selectedQuery: string;

const PurposeFilter = ({
    handleSelect,
    purposeState,
    query,
    handleSelectAll,
    style,
    submitPurposeData,
}: PurposeFilterPropsType) => {
    return (
        <Container style={style}>
            <Wrapper>
                <ContentWrapper width={"50%"} index={0}>
                    <div className="title">대분류</div>
                    {Object.keys(purposeState).map((purpose) => (
                        <Button
                            key={purpose}
                            onClick={() => {
                                selectedQuery = purpose;
                                handleSelect(purpose, "대분류");
                            }}
                            isActive={purposeState[purpose].isActive}
                        >
                            <span>{purpose}</span>
                        </Button>
                    ))}
                </ContentWrapper>

                <ContentWrapper width={"50%"} index={1}>
                    <div className="title">중분류</div>
                    {purposeState[query].children.map((purpose) => (
                        <Button
                            key={purpose.id}
                            onClick={() => {
                                // addFooterItem(purpose.title);
                                if (purpose.title === "전체" && purpose.id !== "All") {
                                    handleSelectAll(selectedQuery, purpose.id);
                                    return;
                                }
                                handleSelect(selectedQuery, "중분류", submitPurposeData, purpose.id);
                            }}
                            isActive={purpose.isActive}
                        >
                            <span>{purpose.title}</span>
                        </Button>
                    ))}
                </ContentWrapper>
            </Wrapper>
        </Container>
    );
};

export default PurposeFilter;

const Container = styled.div`
    height: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    background: #eceff4;
`;

const Button = styled.button<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 100%;
    border-radius: 8px;
    padding: 6px 10px 6px 10px;
    background: #fdfafa;
    margin-top: 8px;
    transition: color 0.3s linear;

    ${({ isActive }) => {
        if (isActive) {
            return css`
                background: ${Common.colors.aucMarkerColor};
                color: #ffffff;
                transition: color 0.3s linear;
            `;
        }
    }};

    span {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        margin-right: 4px;
    }
`;

const ContentWrapper = styled.div<{ width: string; index: number }>`
    max-height: 100%;
    width: ${({ width }) => width};
    overflow: scroll;
    background: white;
    padding: 0px 8px;
    padding-top: 52px;
    padding-bottom: 14px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    animation: ${appearAnimation} 0.5s;

    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }

    .title {
        font-size: 14px;
        line-height: 24px;
        background: #ffffff;
        position: absolute;
        top: 0;
        left: ${({ index }) => {
            if (index === 1) return "50%";
            if (index === 2) return "50%";
            return 0;
        }};
        display: flex;
        height: 40px;
        width: ${({ width }) => width};
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #b1aeb8;
    }
`;
