import { useQuery } from "react-query";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";

export const useGetCheckDeungiPayment = () => {
    return useQuery<string>(
        ["checkdeunggiPayment"],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.checkDeungiPaymentPath)
                .then((res) => {
                    //"disallow"
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return result;
        },
        { enabled: false },
    );
};
