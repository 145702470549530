import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

export const useGetSugangDetail = async (no: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusUser}/class/${no}`);
    return response;
};

export const useGetSugangCurriculum = async (no: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusUser}/class/${no}/curriculums`);
    return response;
};

export const useGetSugangResources = async (no: number) => {
    const response = await axiosInstance.get(`${axiosPath.campusClass}/${no}/resources`, {
        params: {
            page: 1,
            limit: 9999,
        },
    });
    return response;
};

export const useWriteReview = async (no: number, contents: string, score: number) => {
    const body = {
        score,
        contents,
    };
    const response = await axiosInstance.post(`${axiosPath.campusClass}/${no}/review`, body);
    return response;
};

export const useSaveViewTime = async (no: number, curriculumNo: number, viewedTime: number) => {
    const body = {
        curriculumNo: curriculumNo,
        viewedTime: viewedTime,
    };
    const response = await axiosInstance.put(`${axiosPath.campusUser}/class/${no}`, body);
    return response;
};

export const useSaveResourceRecode = async (no: number) => {
    const response = await axiosInstance.put(`${axiosPath.campusUser}/class/${no}/resource`);
    return response;
};
