import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { SubmitDeungiRequestDto } from "./dto/Deungi.dto";

export const useDeungiSubmit = async (params: SubmitDeungiRequestDto) => {
    const response = await axiosInstance.post(`${axiosPath.submitDeungi}`, params);
    return response;
};

export const useExistDeungiSubmit = async (params: SubmitDeungiRequestDto) => {
    const response = await axiosInstance.post(`${axiosPath.submitExistDeungi}`, params);
    return response;
};
