import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useInfiniteQuery, useMutation } from "react-query";

export const useGetRefundList = async (type: string, params: any) => {
    const response = await axiosInstance.get(`${axiosPath.refundPG}/refunds/${type}`, { params });
    return response;
};

export const useRefundPayment = async (orderNo: number) => {
    const response = await axiosInstance.post(`${axiosPath.refundPG}/refund/${orderNo}`);
    return response;
};

const getCampusPaymentItems = async (params: any) => {
    const reponse = await axiosInstance.get(`${axiosPath.refundPG}/refunds/campus_registration`, {
        params,
    });
    return reponse.data;
};

// * 캠퍼스 환불 리스트
export const useFindAllCampusPayments = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["campusPaymentList"],
        queryFn: ({ pageParam }) => getCampusPaymentItems({ ...params, page: pageParam }),
        getNextPageParam: (_lastPage: any, pages: any) => {
            return pages[0]?.totalCnt > pages?.length * 20 ? pages.length + 1 : null;
        },
        enabled,
    });
};

//* pg 결제 환불하기
export const useSaverPgRefund = (onSuccess: (datum: any) => void = () => {}, onError: (err: AxiosError) => void = () => {}) => {
    return useMutation(
        async (param: number) => {
            try {
                const res = await axiosInstance.post(`${axiosPath.paymentRefundPath}/${param}`);
                return res;
            } catch (error) {
                console.log("err>>>", error);
                throw error;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

interface PGRefundParams {
    orderNo: number;
    amount: number;
    comments: string;
}

export const useSaverPgRefundV2 = (onSuccess: (datum: any) => void = () => {}, onError: (err: AxiosError) => void = () => {}) => {
    return useMutation(
        async (param: PGRefundParams) => {
            try {
                const res = await axiosInstance.post(`${axiosPath.paymentV2RefundPath}`, param);
                return res;
            } catch (error) {
                console.log("err>>>", error);
                throw error;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
