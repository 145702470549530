import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { useQuery } from "react-query";

const nestApi = process.env.REACT_APP_NEST_API_URL;

export const useFindOneRoadWidth = (pnu: string) => {
    return useQuery(
        ["roadWidth", pnu],
        async () => {
            const newUrl = `${nestApi}/datamap/getRoadWidth/${pnu}`;
            const result = await axiosAsync
                .get(newUrl)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>");
                    throw err;
                });
            return result;
        },
        { enabled: false },
    );
};

export const useGetAreaSize = async (pnu: string) => {
    const newUrl = `${nestApi}/datamap/getAreaSize/${pnu}`;
    const result = await axiosAsync
        .get(newUrl)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log("err>>");
            throw err;
        });
    return result;
};

export const useFindOneAreaSize = (pnu: string) => {
    return useQuery(
        ["areaSize", pnu],
        async () => {
            const newUrl = `${nestApi}/datamap/getAreaSize/${pnu}`;
            const result = await axiosAsync
                .get(newUrl)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>");
                    throw err;
                });
            return result;
        },
        { enabled: false },
    );
};
