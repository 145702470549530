import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFilterParamsType } from ".";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import downArrowIcon from "@src/assets/ico_arrow_down_bold.svg";

interface Props {
    filterParams: IFilterParamsType;
    setFilterParams: (filterState: IFilterParamsType) => void;
    allFilter?: boolean;
    onClickDone?: () => void;
}

export const auctionSkipList = ["전체", "신건", "유찰"];

const AuctionSkipFilter: React.FC<Props> = ({ filterParams, setFilterParams, allFilter = true, onClickDone }) => {
    const onChangeRange = (evt: ChangeEvent<HTMLInputElement>, type: number) => {
        const newVal = evt.target.value;
        if (isNaN(Number(newVal))) {
            return;
        }
        const newSkipCount = type === 0 ? [newVal, filterParams.skp_cnt[1]] : [filterParams.skp_cnt[0], newVal];
        setFilterParams({ ...filterParams, skp_cnt: newSkipCount });
    };

    const onBlurEvent = (evt: ChangeEvent<HTMLInputElement>, type: number) => {
        const newVal = evt.target.value;
        const newSkipCount = type === 0 ? [newVal, filterParams.skp_cnt[1]] : [filterParams.skp_cnt[0], newVal];

        if (Number(newVal) < 1 && type === 0) {
            setFilterParams({ ...filterParams, skp_cnt: ["1", filterParams.skp_cnt[1]] });
        } else if (Number(newVal) < Number(filterParams.skp_cnt[0]) && type === 1) {
            setFilterParams({ ...filterParams, skp_cnt: [filterParams.skp_cnt[0], filterParams.skp_cnt[0]] });
        } else if (Number(newVal) > Number(filterParams.skp_cnt[1]) && type === 0) {
            setFilterParams({ ...filterParams, skp_cnt: [newVal, newVal] });
        } else {
            setFilterParams({ ...filterParams, skp_cnt: newSkipCount });
        }
    };
    return (
        <div css={rootBoxStyle}>
            <div css={titleBoxStyle(!filterParams.skp_type)}>
                <p className="titleText">유찰횟수</p>
                {allFilter ? (
                    <button
                        className="allButton"
                        onClick={() => {
                            setFilterParams({ ...filterParams, skp_type: 0, skp_cnt: ["", ""] });
                        }}
                    >
                        전체
                    </button>
                ) : (
                    <button className="downButton" onClick={() => onClickDone && onClickDone()}>
                        <img src={downArrowIcon} alt="" />
                    </button>
                )}
            </div>
            <div css={selectButtonBoxStyle}>
                {auctionSkipList.map(
                    (item, idx) =>
                        (!allFilter || !!idx) && (
                            <button
                                key={item}
                                css={selectButtonStyle(idx ? idx === filterParams.skp_type : !filterParams.skp_type)}
                                onClick={() => {
                                    idx
                                        ? setFilterParams({
                                              ...filterParams,
                                              skp_type: idx,
                                              skp_cnt:
                                                  idx === 1
                                                      ? ["", ""]
                                                      : filterParams?.skp_cnt[0] === ""
                                                      ? ["1", ""]
                                                      : filterParams?.skp_cnt,
                                          })
                                        : setFilterParams({ ...filterParams, skp_type: 0, skp_cnt: ["", ""] });
                                }}
                            >
                                {item}
                            </button>
                        ),
                )}
            </div>
            {filterParams.skp_type === 2 && (
                <div css={rangeInputBoxStyle}>
                    <input
                        onChange={(evt) => onChangeRange(evt, 0)}
                        name="minCount"
                        value={filterParams.skp_cnt[0]}
                        maxLength={3}
                        onBlur={(evt) => {
                            onBlurEvent(evt, 0);
                        }}
                        placeholder="최소"
                        type="tel"
                    />
                    <span>~</span>
                    <input
                        onChange={(evt) => onChangeRange(evt, 1)}
                        name="maxCount"
                        value={filterParams.skp_cnt[1]}
                        placeholder="최대"
                        onBlur={(evt) => {
                            onBlurEvent(evt, 1);
                        }}
                        maxLength={3}
                        type="tel"
                    />
                </div>
            )}
        </div>
    );
};

export default AuctionSkipFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
`;

const titleBoxStyle = (active: boolean) => css`
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleText {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: -0.4px;
    }
    .allButton {
        background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
        color: ${active ? "#ffffff" : "#0c002c"};
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .downButton {
        padding: 4px 13px;
        border-radius: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

const selectButtonStyle = (active: boolean) => css`
    padding: 8px 14px;
    border-radius: 8px;
    background-color: ${Common.colors.grey02};
    background-color: ${active ? Common.colors.aucMarkerColor : Common.colors.grey02};
    color: ${active ? "#ffffff" : "#0c002c"};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
`;

const selectButtonBoxStyle = css`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    padding: 14px;
    flex-wrap: wrap;
`;

const rangeInputBoxStyle = css`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 14px 16px;
    span {
        margin: 0 7px;
        padding: 0px 7px;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0px;
        color: #a3a3a3;
    }
    input {
        height: 48px;
        flex: 1;
        width: 151.5px;
        left: 16px;
        top: 0px;
        border-radius: 8px;
        background: #fbf8f8;
        border: none;
        padding: 0px 13px;
    }
    input::placeholder {
        height: 24px;
        width: 27px;
        left: 13px;
        top: 14px;
    }
`;
