import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import ico_list_search_black from "@src/assets/ico_search_black_bold.svg";
import ico_arrow_left_black from "@src/assets/ico_arrow_left_black.svg";
import styled from "@emotion/styled";

interface ISearchInput {
    placeholder: string;
    handleClick: (searchItem: string) => void;
    isDisabled?: boolean;
    value?: string;
    handleClickBack?: () => void;
}

function SearchInput({ placeholder, handleClick, handleClickBack, isDisabled = false, value }: ISearchInput) {
    const [searchItem, setSearchItem] = useState("");
    const inputRef = useRef() as any;

    const onClick = () => {
        searchItem.replace(" ", "");
        handleClick(searchItem);
        inputRef.current.blur();
    };
    const handleKeyUP = (e: KeyboardEvent<HTMLInputElement>) => e.key === "Enter" && onClick();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchItem(e.target.value);
    };

    useEffect(() => {
        value && setSearchItem(value);
    }, [value]);
    return (
        <div css={searchStyle}>
            {handleClickBack && (
                <div onClick={handleClickBack}>
                    <img src={ico_arrow_left_black} />
                </div>
            )}
            <Input
                ref={inputRef}
                isGoBack={handleClickBack}
                value={searchItem}
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                onKeyUp={handleKeyUP}
                disabled={isDisabled}
                onBlur={onClick}
            />

            <button css={searchButton} onClick={onClick} disabled={isDisabled}>
                <img src={ico_list_search_black} />
            </button>
        </div>
    );
}

const searchStyle = css`
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
    z-index: 20;

    div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 10px;
        width: 24px;
        height: 24px;

        img {
            width: 10px;
        }
    }
`;
const Input = styled.input<any>`
    width: 100%;

    height: 64px;
    padding-left: ${({ isGoBack }) => (isGoBack ? "42px" : "22px")};
    padding-right: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    box-shadow: 1px 2px 4px 0px #00000024;
    font-size: 16px;
    line-height: 26px;

    &::placeholder {
        color: ${Common.colors.gray500};
        font-size: 16px;
        line-height: 26px;
    }
    outline: none;
`;
const searchButton = css`
    position: absolute;
    right: 0px;
    z-index: 9999;
    font-size: 0;
    padding: 0px 14px;
    top: 25px;

    img {
        width: 20px;
        height: 20px;
    }
`;
export default SearchInput;
