import styled from "@emotion/styled";
import DeungiHeader from "@src/components/deungi/DeungiHeader";
import DeungiPackageListBox from "@src/components/deungi/DeungiPackageListBox";
import React, { useEffect, useState } from "react";

const DeungiPayment = () => {
    return (
        <Container>
            <DeungiHeader title="충전하기" />
            <div className="box">
                <DeungiPackageListBox />
            </div>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    animation: fadeIn 0.5s forwards;
    background-color: white;

    .box {
        position: absolute;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        overflow: auto;
        overscroll-behavior-y: none;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

export default DeungiPayment;
