import React from "react";
import { css } from "@emotion/react";
import ConfirmModal from "../popup/ConfirmModal";

interface Props {
    open: boolean;
    onCloseView: () => void;
}

const DeungiSubsCheckDialog: React.FC<Props> = ({ open, onCloseView }) => {
    return (
        <>
            {open && (
                <ConfirmModal
                    onConfirm={onCloseView}
                    // confirmStyle={{ fontSize: "18px", padding: "14px", lineHeight: "28px" }}
                    resultMessage={
                        <div css={contentStyle}>
                            비구독 또는 무료 구독 회원의 경우,
                            <br />
                            ‘실시간 등기열람권 1장’ 상품만 구매 가능하며 4장 이상은 구독회원에게만 제공됩니다.
                        </div>
                    }
                />
            )}
        </>
    );
};

export default DeungiSubsCheckDialog;

const contentStyle = css`
    color: #0c002c;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;
