import { purposeData } from "@src/data/itemForFilterPurpose";
import { useAiFilterStore } from "@src/store/AiFilterStore";
import produce from "immer";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

const useGetPurpose = (selectedState?: string[], type?: string) => {
    const [query, setQuery] = useState("전체");
    const [purposeState, setPurposeState] = useState<PurposeDataTypes>(purposeData);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [selectedMiddle, setSelectedMiddle] = useState("");
    const [toastVisible, setToastVisible] = useState(false);
    const aiFilterState = useAiFilterStore((state) => state.aiFilterState);
    const [submitPurposeData, setSubmitPurposeData] = useState<string[]>([]);
    const keys = Object.keys(purposeState);
    const [firstTime, setFirstTime] = useState<boolean>(true);

    // 용도용
    useEffect(() => {
        if (selectedState) {
            const nextState = produce(purposeState, (draft) => {
                keys.forEach((key) => {
                    const withoutAll = purposeState[key].children.filter((item) => item.title !== "전체");
                    const withoutAllId = withoutAll.map((item) => item.id);

                    const isAll = withoutAllId.every((children) => selectedState.includes(children));
                    draft[key].children.map((child) => {
                        if (child.id !== "All") {
                            if (!isAll && selectedState.includes(child.id)) {
                                child.isActive = true;
                                draft[key].isActive = true;
                            }
                            if (isAll && child.title === "전체") {
                                child.isActive = true;
                                draft[key].isActive = true;
                            }

                            if (isAll && child.title !== "전체") {
                                child.isActive = false;
                            }

                            if (draft[key].isActive) {
                                draft["전체"].isActive = false;
                            }
                        }
                    });
                });
            });
            setPurposeState(nextState);
        }
    }, [selectedState]);

    const handleSelect = async (
        selected: string = "전체",
        type: "대분류" | "중분류",
        submitPurposeData?: string[],
        id?: string,
    ) => {
        const key = Object.keys(purposeState);
        setQuery(selected);

        if (id && submitPurposeData) {
            if (submitPurposeData.length > 9 && !submitPurposeData.includes(id)) {
                setToastVisible(true);
                return;
            }
        }
        setToastVisible(false);

        const nextState = produce(purposeState, (draft) => {
            if (draft[selected].children.every((child) => child.isActive === false)) {
                draft[selected].isActive = false;
            }
            const isNotIncludeActive = purposeState[selected].children.every((item2) => {
                return item2.isActive === false;
            });

            if (isNotIncludeActive && selected !== "전체") {
                draft[selected].children[0].isActive = true;
            }

            key.forEach((item) => {
                const isNotIncludeActive = purposeState[item].children.every((item2) => {
                    return item2.isActive === false;
                });

                if (isNotIncludeActive) {
                    draft[item].isActive = false;
                }
            });

            draft[selected].isActive = true;
        });

        setPurposeState(nextState);

        if (type === "대분류" && selected === "전체") {
            const nextState = produce(purposeState, (draft) => {
                if (selected !== "전체") {
                    draft["전체"].children[0].isActive = false;
                    draft["전체"].isActive = false;
                }

                if (selected === "전체") {
                    keys.forEach((key) => {
                        if (key !== "전체") {
                            draft[key].isActive = false;
                        }

                        draft[key].children.map((item) => {
                            item.isActive = false;
                        });
                    });
                    draft["전체"].isActive = true;
                }
            });

            setPurposeState(nextState);
            return;
        }

        if (type === "중분류") {
            setSelectedMiddle(selected);

            const nextState = produce(purposeState, (draft) => {
                const isActiveAll = draft[selected].children.every((child) => child.isActive === true);
                draft[selected].children.forEach((child) => {
                    draft[selected].children[0].isActive = isActiveAll;

                    if (child.id === id) {
                        child.isActive = !child.isActive;
                    }
                });
            });

            setIsSelectedAll(
                nextState[selected].children.filter((item) => item.title !== "전체").every((item) => item.isActive === true),
            );

            setPurposeState(nextState);
        }
    };

    const handleSelectAll = (selected: string, id: string) => {
        const nextState = produce(purposeState, (draft) => {
            if (id?.includes("all")) {
                draft["전체"].isActive = false;
            }

            draft[selected].children = draft[selected].children.map((child) => {
                if (child.title !== "전체") {
                    return {
                        ...child,
                        isActive: false,
                    };
                }

                if (child.title === "전체") {
                    return {
                        ...child,
                        isActive: !child.isActive,
                    };
                }
                return child;
            });
        });

        setIsSelectedAll(!isSelectedAll);
        setPurposeState(nextState);
    };

    const onClickRemove = (selectedTitle: string) => {
        const parseSelectedLocationString = selectedTitle.split(">");
        const selectedPurpose = parseSelectedLocationString[parseSelectedLocationString.length - 1];
        const selectedQuery = parseSelectedLocationString[0];

        const nextState = produce(purposeState, (draft) => {
            draft[selectedQuery].children = draft[selectedQuery].children.map((child) => {
                if (child.title === selectedPurpose) {
                    return {
                        ...child,
                        isActive: false,
                    };
                }
                return child;
            });
        });

        setPurposeState(nextState);
    };

    const resetPurpose = () => {
        setPurposeState(purposeData);
    };

    return {
        purposeState,
        query,
        handleSelect,
        handleSelectAll,
        onClickRemove,
        resetPurpose,
        toastVisible,
        setToastVisible,
        setSubmitPurposeData,
        submitPurposeData,
    };
};
export default useGetPurpose;
export type PurposeDataType = {
    title: string;
    id: string;
    isActive: boolean;
};
export type PurposeDataTypes = {
    [key: string]: { children: PurposeDataType[]; isActive: boolean };
};
