import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import React, { Fragment, useState } from "react";
import AiBottomSheetTagItem from "./AiBottomSheetTagItem";
import { differenceInDays, format } from "date-fns";
import convertNumberToKorean from "@src/util/convertNumToKorean";

import noImg from "@src/assets/noImg.png";
import goImg from "@src/assets/typeGo.png";
import stopImg from "@src/assets/typeStop.png";
import wraningImg from "@src/assets/typeWarning.png";
import ico_price_up from "@src/assets/ico_price_up.svg";
import ico_like_red from "@src/assets/ico_like_red.png";
import ico_like_grey from "@src/assets/ico_like_grey.png";
import ico_price_down from "@src/assets/ico_price_down_bold.svg";

interface AiBottomSheetItemProps {
    item: any;
    onClick: () => void;
}

const AiBottomSheetItem = ({ item, onClick }: AiBottomSheetItemProps) => {
    return (
        <Container>
            <Wrapper>
                <ContentNav>
                    <div>
                        <span>{item.bidType === 2 ? "공매" : "경매"}</span>
                        <b>{item.bidType === 2 ? item.itemId : item.subId}</b>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <ContentTextDayStartTime>
                            {/* {item.bidType === 2 && "~"} */}
                            {item.bidType === 1 && format(new Date(item.dueDate * 1000), "yyyy.MM.dd")}
                        </ContentTextDayStartTime>
                        <ContentTextDayStart>
                            {item.bidType === 1 && (
                                <>
                                    {differenceInDays(new Date(), new Date(item.dueDate * 1000)) > 0
                                        ? "입찰종료"
                                        : differenceInDays(new Date(), new Date(item.dueDate * 1000)) === 0
                                        ? "D-day"
                                        : `D${differenceInDays(new Date(), new Date(item.dueDate * 1000))}`}
                                </>
                            )}
                            {item.bidType === 2 && (
                                <>
                                    <span
                                        className="title_date"
                                        style={{
                                            marginLeft: "8px",
                                        }}
                                    >
                                        {item.dDay}
                                    </span>
                                </>
                            )}
                            {/* {differenceInDays(new Date(), new Date(item.dueDate * 1000)) > 0
                                ? "입찰종료"
                                : differenceInDays(new Date(), new Date(item.dueDate * 1000)) === 0
                                ? "D-day"
                                : `D${differenceInDays(new Date(), new Date(item.dueDate * 1000))}`} */}
                        </ContentTextDayStart>
                    </div>
                </ContentNav>
                <Content onClick={onClick}>
                    <ContentImg type={item.bidType} skpCnt={item.skpCnt}>
                        {item.image ? (
                            <img src={item.image} alt="매물 이미지" className="itemImg" />
                        ) : (
                            <img src={noImg} className="itemImg" />
                        )}
                        <div> {item.skpCnt === 0 ? "신건" : `유찰 ${item.skpCnt}회`}</div>
                        {item.gostop === "GO" && <img src={goImg} alt="" className="goStop" />}
                        {item.gostop === "STOP" && <img src={stopImg} alt="" className="goStop" />}
                        {item.gostop === "주의" && <img src={wraningImg} alt="" className="goStop" />}
                    </ContentImg>
                    <ContentTextWrap>
                        <ContentTextTitleWrapper>
                            <ContentTextTitle>{item.useDetail}</ContentTextTitle>
                        </ContentTextTitleWrapper>
                        <Address>{item.address.addressJibun}</Address>
                        <ContentTextPrice>
                            감정가{" "}
                            {convertNumberToKorean(
                                Number(item.estPrice.toString().slice(0, item.estPrice.toString().length - 4) + "0000"),
                            ).replace("억", "억 ") &&
                                convertNumberToKorean(
                                    Number(item.estPrice.toString().slice(0, item.estPrice.toString().length - 4) + "0000"),
                                ).replace("억", "억 ") + "원"}
                        </ContentTextPrice>
                        <ContentTextDay></ContentTextDay>
                        <ContentTextQuotePrice>
                            <span>최저가</span>
                            {convertNumberToKorean(
                                Number(item.minPrice.toString().slice(0, item.minPrice.toString().length - 4) + "0000"),
                            ).replace("억", "억 ") &&
                                convertNumberToKorean(
                                    Number(item.minPrice.toString().slice(0, item.minPrice.toString().length - 4) + "0000"),
                                ).replace("억", "억 ") + "원"}
                            {item.discountRate === "(0%)" ? null : (
                                <span>
                                    {item.discountRate.slice(1, item.discountRate.length - 2) === "0" && (
                                        <>
                                            <span style={{ color: "black", marginLeft: "8px" }}>
                                                {item.discountRate.slice(1, item.discountRate.length - 2)}%
                                            </span>
                                        </>
                                    )}
                                    {Number(item.discountRate.slice(1, item.discountRate.length - 2)) < 0 && (
                                        <>
                                            <img src={ico_price_down} alt="상승률 하락률" />
                                            {-Number(item.discountRate.slice(1, item.discountRate.length - 2))}%
                                        </>
                                    )}
                                    {Number(item.discountRate.slice(1, item.discountRate.length - 2)) > 0 && (
                                        <>
                                            <img src={ico_price_up} alt="상승률 하락률" />
                                            <span style={{ color: "red" }}>
                                                {item.discountRate.slice(1, item.discountRate.length - 2)}%
                                            </span>
                                        </>
                                    )}
                                </span>
                            )}
                        </ContentTextQuotePrice>
                        <ContentFooter>
                            <div className="tags">
                                {item.remarks
                                    ? Object.keys(item.remarks).map((something: any) => {
                                          let data: any = "";
                                          switch (something) {
                                              case "certFarm":
                                                  data = `${item.remarks.certFarm ? "농취증필요" : ""}`;
                                                  break;
                                              case "landSepReg":
                                                  data = `${item.remarks.landSepReg ? "토지별도등기" : ""}`;
                                                  break;
                                              case "landUnreg":
                                                  data = `${item.remarks.landUnreg ? "대지권미등기" : ""}`;
                                                  break;
                                              case "hugChange":
                                                  data = `${item.remarks.hug_change ? "HUG 임차권 인수조건 변경" : ""}`;
                                                  break;
                                              case "onlyBuid":
                                                  data = `${item.remarks.onlyBuid ? "건물만입찰" : ""}`;
                                                  break;
                                              case "onlyLand":
                                                  data = `${item.remarks.onlyLand ? "토지만입찰" : ""}`;
                                                  break;
                                              case "rLien":
                                                  data = `${item.remarks.rLien ? "유치권" : ""}`;
                                                  break;
                                              case "rSurface":
                                                  data = `${item.remarks.rSurface ? "법정지상권" : ""}`;
                                                  break;
                                              case "rTomb":
                                                  data = `${item.remarks.rTomb ? "분묘기지권" : ""}`;
                                                  break;
                                              case "shrSale":
                                                  data = `${item.remarks.shrSale ? "지분매각" : ""}`;
                                                  break;
                                              case "violation":
                                                  data = `${item.remarks.violation ? "위반건축물" : ""}`;
                                                  break;
                                              default:
                                                  data = "";
                                          }
                                          return (
                                              data && (
                                                  <Fragment key={something}>
                                                      <AiBottomSheetTagItem item={data} />
                                                  </Fragment>
                                              )
                                          );
                                      })
                                    : null}
                            </div>
                            <div>
                                {item.isFavorite ? (
                                    <LikeImg src={ico_like_red} alt="favorite" />
                                ) : (
                                    <LikeImg src={ico_like_grey} alt="least favorite" />
                                )}
                            </div>
                        </ContentFooter>
                    </ContentTextWrap>
                </Content>
            </Wrapper>
        </Container>
    );
};

const Wrapper = styled.div`
    /* display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
    box-sizing: border-box; */
`;

const Container = styled.div`
    /* min-height: 100vh;
    max-height: 100vh; */
    /* padding: 0px 16px; */
    /* padding-top: 152px; */
    z-index: 22;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const Content = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    /* display: flex;
    flex: 1;
    gap: 8px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between; */
`;
const LikeImg = styled.img`
    max-width: 20px;
    max-height: 18px;
`;
const ContentFooter = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .tags {
        display: flex;
        /* width: 100%; */
        flex: 1;
        /* overflow-x: auto; */
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .tag {
            height: 18px;
            border-radius: 4px;
            color: #3784ff;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            white-space: nowrap;
            color: ${Common.colors.normalTextColor};
            clear: both;
        }
        .tag + .tag {
            margin-left: 2px;
        }
        .tag.last-word {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const ContentNav = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 16px;
    border-top: 0.6px solid #dadada;
    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
    b {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: ${Common.colors.darkGrey03};
        margin-left: 4px;
    }
`;

const ContentImg = styled.div<{ type: number; skpCnt?: number | undefined }>`
    position: relative;
    box-sizing: border-box;
    & > .itemImg {
        object-fit: fill;
        width: 108px;
        height: 108px;
        border-radius: 8px;
    }
    & > .goStop {
        position: absolute;
        left: 12px;
        bottom: 2px;
        width: 84px;
        z-index: 6;
    }
    & > div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        top: 8px;
        left: 8px;
        min-width: 42px;
        height: 22px;
        text-align: center;
        padding: 1px 8px;
        background-color: ${(props) =>
            props.skpCnt === 0 ? `${Common.colors.purpleSecondary}` : `${Common.colors.yellowSecondary}`};
        z-index: 5;
        font-size: 13px;
        font-weight: 400;
        color: ${(props) => (props.skpCnt === 0 ? `${Common.colors.white}` : `${Common.colors.normalTextColor}`)};
    }
`;

const ContentTextWrap = styled.div`
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    width: 70%;
    margin-left: 8px; */
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 8px;
`;

const ContentTextTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ContentTextTitle = styled.div`
    ${Common.textStyle.title5_M14};
    font-weight: 400;
`;

const ContentTextCountFailed = styled.div<{ type: number }>`
    ${Common.textStyle.title5_M14};
    /* color: ${Common.colors.redSecondary} */
    color: ${({ type }) => (type === 0 ? Common.colors.purpleSecondary : Common.colors.redSecondary)};
    font-weight: 700;
`;

const ContentTextPrice = styled.div`
    color: ${Common.colors.navyPrimary};
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 4px;
`;

const ContentTextDay = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ContentTextDayStart = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ff0000;
    margin-left: 8px;
    margin-top: 2px;
`;

const ContentTextDayStartTime = styled.div`
    color: ${Common.colors.darkGrey03};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
`;

const ContentTextQuotePrice = styled.div`
    /* ${Common.textStyle.body1_M16}; */
    font-size: 14px;
    line-height: 160%;
    font-weight: 700;
    color: ${Common.colors.navyPrimary};
    display: flex;
    align-items: center;
    margin-top: 2px;
    & > span {
        font-size: 14px;
        font-weight: 700;
        color: ${Common.colors.blue};
        margin-right: 5px;
        display: flex;
        height: 100%;
        align-items: center;
        & > img {
            margin-left: 9px;
            margin-right: 5px;
            display: flex;
            align-items: center;
        }
    }
`;

const Address = styled.div`
    width: 100%;
    min-height: 20px;
    ${Common.textStyle.text1_R13}
    white-space: nowrap;
    overflow: hidden;
    line-height: 20px;
    text-overflow: ellipsis;
    margin-top: 3px;
`;

const HashTagList = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    overflow: auto;
    white-space: nowrap;
    /* scrollbar-width: none;
    -ms-overflow-style: none; */
    ::-webkit-scrollbar {
        display: none;
    }
`;

export default AiBottomSheetItem;
