import { css } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";
import whiteMessage from "@src/assets/whiteMessage.svg";
import whitePhone from "@src/assets/whitePhone.svg";
import ico_close from "@src/assets/ico_close.png";
import { ConvertPhoneNumber } from "@src/util/convertPhoneNumber";
import { transferToNative } from "@src/lib/WebAppBridge";

interface ConnectionPopupProps {
    userPhone: string;
    closePopup: () => void;
}

const ConnectionPopup = ({ userPhone, closePopup }: ConnectionPopupProps) => {
    return (
        <>
            <div css={DimCss}></div>
            <div css={PopupWrap}>
                <img
                    src={ico_close}
                    css={closeButton}
                    onClick={() => {
                        closePopup();
                    }}
                    alt="closeIcon"
                />
                <div css={title}>상담 신청자에게 연결할까요?</div>
                <div css={ButtonWrap}>
                    <button
                        onClick={() => {
                            const data = { type: "message", recipient: userPhone };
                            transferToNative(JSON.stringify(data), "inquiryToExpert");
                        }}
                    >
                        <img src={whiteMessage} alt="messageTypeImg" />
                        <span>문자하기</span>
                    </button>
                    <button
                        onClick={() => {
                            const data = { type: "call", recipient: userPhone };
                            transferToNative(JSON.stringify(data), "inquiryToExpert");
                        }}
                    >
                        <img src={whitePhone} alt="messageTypeImg" />
                        <span>전화하기</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default ConnectionPopup;

const DimCss = css`
    width: 100vw;
    height: 100vh;
    background-color: #282828;
    opacity: 0.7;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
`;

const closeButton = css`
    width: 14px;
    height: 14px;
    position: absolute;
    top: 19px;
    right: 19px;
`;

const PopupWrap = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 172px;
    align-items: center;
    width: 312px;
    height: fit-content;
    padding: 14px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    border-radius: 14px;
    z-index: 3;
    gap: 24px;
`;

const title = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #0c002c;
`;

const ButtonWrap = css`
    display: flex;

    width: 100%;
    gap: 8px;
    height: 56px;
    box-sizing: border-box;
    & > button {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        height: 100%;
        background-color: #2275ee;
        border-radius: 14px;
        padding: 14px;
        box-sizing: border-box;
        & > img {
            width: 24px;
            height: 24px;
        }
        & > span {
            font-size: 18px;
            line-height: 28px;
            color: white;
            font-weight: 400;
        }
    }
`;
