import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery } from "react-query";

const getGoStopPaymentItems = async (params: any) => {
    const reponse = await axiosInstance.get(`${axiosPath.goStopPaymentPath}`, {
        params,
    });
    return reponse.data;
};

export const useFindAllGoStopPayments = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: ["goStopPaymentItems", params.gostopType],
        queryFn: ({ pageParam }) => getGoStopPaymentItems({ ...params, page: pageParam }),
        getNextPageParam: (_lastPage: any, pages: any) => {
            return pages[0]?.totalCnt > pages?.length * 20 ? pages.length + 1 : null;
        },
        enabled,
    });
};
