import { css } from "@emotion/react";
import React, { useEffect } from "react";
import { Common } from "@src/styles/Common";

interface InternalTableButtonProps {
    data: any;
    trySetData: () => void;
    isOpen: boolean;
    isKindOfButton: boolean;
    tryClose: () => void;
    tryReOpen: () => void;
}

function InternalTableButton({ data, trySetData, isOpen, isKindOfButton, tryClose, tryReOpen }: InternalTableButtonProps) {
    return (
        <>
            {data.length >= 5 && (
                <>
                    {isKindOfButton ? (
                        <>
                            {isOpen ? (
                                <button
                                    css={tableButtonStyle}
                                    onClick={() => {
                                        tryReOpen();
                                    }}
                                >
                                    더보기
                                </button>
                            ) : (
                                <button
                                    css={tableButtonStyle}
                                    onClick={() => {
                                        trySetData();
                                    }}
                                >
                                    더보기
                                </button>
                            )}
                        </>
                    ) : (
                        <button
                            css={tableButtonStyle}
                            onClick={() => {
                                tryClose();
                            }}
                        >
                            접기
                        </button>
                    )}
                </>
            )}
            {/* {isChangeBtn ? (
                <button css={tableButtonStyle} onClick={tryClose}>
                    접기
                </button>
            ) : ( */}
            {/* <button css={tableButtonStyle}>더보기</button> */}
            {/* )} */}
        </>
    );
}
const tableButtonStyle = css`
    background-color: ${Common.colors.blue};
    width: 100%;
    height: 56px;
    border-radius: 14px;
    color: ${Common.colors.white};
    ${Common.textStyle.display3_R18};
    line-height: 0;
`;

export default InternalTableButton;
