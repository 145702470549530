import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { IAdItemResponse, ModalType } from "@src/hooks/expert/expert/dto/expert.dto";
import Call from "@src/assets/call.png";
import Text from "@src/assets/text.png";

type AdExpertCardPropsType = {
    item: IAdItemResponse;
    setClickedAdEnd: Dispatch<SetStateAction<boolean>>;
    setModalData: Dispatch<SetStateAction<ModalType>>;
    setFailedAdBanner: Dispatch<SetStateAction<boolean>>;
    setClickedReAd: Dispatch<SetStateAction<boolean>>;
};

const AdExpertCard = ({ item, setClickedAdEnd, setModalData, setFailedAdBanner, setClickedReAd }: AdExpertCardPropsType) => {
    const navigate = useNavigate();
    return (
        <div className="descContainer">
            <div className="adDateWrapper">
                <div className="adDateContainer">
                    <p className="adTitle">{item?.is_ended ? "광고종료" : "광고중"}</p>
                    <span className="adDate">
                        {item?.start_date?.slice(0, 10)} ~ {item?.end_date?.slice(0, 10)}
                    </span>
                </div>
                {!item?.is_ended ? (
                    <button
                        className="endAdBtn"
                        onClick={() => {
                            setClickedAdEnd(true);
                            setModalData((prevState) => ({
                                ...prevState,
                                start_date: item?.start_date,
                                end_date: item?.end_date,
                                item_id: item?.item_id,
                                banner_no: item?.banner_no,
                            }));
                        }}
                    >
                        광고종료
                    </button>
                ) : item?.is_ended ? (
                    item?.no_readvertisement ? (
                        <button
                            className="endAdBtn"
                            onClick={() => {
                                setFailedAdBanner(true);
                            }}
                        >
                            재광고
                        </button>
                    ) : (
                        <button
                            className="reAdBtn"
                            onClick={() => {
                                setClickedReAd(true);
                                setModalData((prevState) => ({
                                    ...prevState,
                                    start_date: item?.start_date,
                                    end_date: item?.end_date,
                                    item_id: item?.item_id,
                                    banner_no: item?.banner_no,
                                }));
                            }}
                        >
                            재광고
                        </button>
                    )
                ) : null}
            </div>
            <div className="adImageWrapper">
                <div className="imgContainer">
                    <img className="adImage" src={item?.image} alt="image" />
                    <b className="imgDay">{item?.d_day !== "입찰종료" && item?.d_day}</b>
                    {(item?.status === "매각" || item?.status === "취하" || item?.status === "기각") && (
                        <div className="imgStatus">{item?.status}</div>
                    )}
                </div>
                <div className="descWrap">
                    <div className="descTitle">{item?.case_number}</div>
                    <div className="descCourt">
                        {item?.court} {item?.due_date}
                    </div>
                    <div className="descUse">{item?.use}</div>
                    <div className="descAddress">{item?.address}</div>
                    <div className="descRow">
                        <div className="descBlueFont">최저가</div>
                        <div className="descBlackFont">{item?.min_price_str}</div>
                        <div className="descBlueFont">{item?.rate.replace(/-/g, "▼")}</div>
                    </div>
                </div>
            </div>
            <div className="callWrap">
                <div className="callCountContainer">
                    <div className="countWrap">
                        <img src={Call} alt="call" />
                        <div>{item?.call_count}</div>
                    </div>
                    <div className="callBar" />
                    <div className="countWrap">
                        <img src={Text} alt="text" />
                        <div>{item?.inquiry_count}</div>
                    </div>
                </div>
                <button
                    className="inquiryBtn"
                    onClick={() => {
                        navigate("/expert/inquiry");
                    }}
                >
                    문의내역 확인하기
                </button>
            </div>
        </div>
    );
};

export default AdExpertCard;
