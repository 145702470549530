import React from "react";

import RefundsToStore from "./RefundsToStore";
import NoRefundsExpirationDate from "./NoRefundsExpirationDate";
import CancelTicket from "./CancelTicket";
import CancelAlreadyCompleted from "./CancelAlreadyCompleted";
import RefundFailed from "./RefundFailed";

export interface DialogType {
    RefundsToStore: JSX.Element;
    NoRefundsExpirationDate: JSX.Element;
    CancelTicket: JSX.Element;
    CancelAlreadyCompleted: JSX.Element;
    RefundFailed: JSX.Element;
}

const componentDialogType = {
    RefundsToStore: RefundsToStore,
    NoRefundsExpirationDate: NoRefundsExpirationDate,
    CancelTicket: CancelTicket,
    CancelAlreadyCompleted: CancelAlreadyCompleted,
    RefundFailed: RefundFailed,
};

interface Props {
    dialogType: keyof DialogType | null;
    title: string;
    errorMessage: string;
}

const RefundsDialogComponents: React.FC<Props> = ({ dialogType, title, errorMessage }) => {
    const Component = dialogType && componentDialogType[dialogType];

    if (!Component) {
        return null;
    }

    return <Component title={title} errorMessage={errorMessage} />;
};

export default RefundsDialogComponents;
