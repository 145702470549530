import styled from "@emotion/styled";
import React from "react";

const EmptyLocation = () => {
    return <Container>👉 지역을 선택해보세요</Container>;
};

export default EmptyLocation;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 36px;
    border-radius: 24px;
    padding: 8px 14px 8px 14px;
    background: #c5ffe9;

    font-size: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: #0c002c;
    white-space: nowrap;
`;
