import { css } from "@emotion/react";
import Title from "@src/components/title/Title";
import React, { Fragment, useEffect, useState } from "react";
import backArrow from "../../../assets/ico_arrow_back.png";
import expertComment from "../../../assets/expertComment.svg";
import expertPhone from "../../../assets/expertPhone.svg";
import { Common } from "@src/styles/Common";
import ico_arrow_down from "@src/assets/boldArrowDown.png";
import Card from "./Card";
import SortBottomSheet from "./SortBottomSheet";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import ko from "date-fns/locale/ko";
import calendarImg from "@src/assets/calendarImg.svg";
import { useGetExpertInquiry } from "@src/hooks/expert/Inquiry/useInquiry";
import ItemCard from "./ItemCard";
import UserCard from "./UserCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import emptyImg from "@src/assets/ico_deungi_empty.png";
import ConnectionPopup from "./ConnectionPopup";
import { transferToNative } from "@src/lib/WebAppBridge";
import { expertInquiryFilterState } from "@src/hooks/expert/Inquiry/dto/inquiry.dto";
import { useNavigate } from "react-router-dom";

const Inquiry = () => {
    const sortTypeList = ["최신순", "매물별 모아보기", "회원별 모아보기"];
    const [visible, setVisible] = useState<boolean>(false);
    const [sortStatus, setSortStatus] = useState<string>("최신순");
    const [state, setState] = useState<{ startDate: Date; endDate: Date; key: string }[]>([
        {
            startDate: new Date(format(new Date(), "yyyy-MM-01")),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    const history = window.history.length;
    const navigate = useNavigate();

    const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);

    const [filters, setFilters] = useState<expertInquiryFilterState>({
        startDate: format(new Date(), "yyyy-MM-01"),
        endDate: format(new Date(), "yyyy-MM-dd"),
        sortType: 0,
        inquiryType: "",
        page: 1,
        limit: 10,
    });

    const { data, refetch, hasNextPage, fetchNextPage } = useGetExpertInquiry(filters);

    useEffect(() => {
        refetch();
    }, [filters]);

    const changeFilters = (inquiryType: string) => {
        if (filters.inquiryType) {
            setFilters({ ...filters, inquiryType: "" });
        } else {
            setFilters({ ...filters, inquiryType });
        }
    };

    const [sentryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 300px 0px",
        delayInMs: 100,
    });

    const [connectionUserPhoneNum, setConnectionUserPhoneNum] = useState<string>("");
    const [isShowConnectionPopup, setIsShowConnectionPopup] = useState<boolean>(false);
    const changeSortType = (sortNum: number, sortString: string) => {
        setFilters({ ...filters, sortType: sortNum });
        setSortStatus(sortString);
        setVisible(false);
    };

    const closePopup = () => {
        setIsShowConnectionPopup(false);
        setConnectionUserPhoneNum("");
    };
    const openPopup = (userPhone: string) => {
        setIsShowConnectionPopup(true);
        setConnectionUserPhoneNum(userPhone);
    };

    return (
        <>
            <div className="titleWrap" css={titleWrap}>
                <div
                    className="backBtn"
                    onClick={() => {
                        history > 1 ? navigate("/expertAdList") : transferToNative("done", "closeWebView");
                    }}
                >
                    <img src={backArrow} alt="backButtonImg" />
                </div>
                <Title title="문의 관리" size="large" />
            </div>
            <div css={filterWrap}>
                <div
                    css={calendarDateRangeWrap}
                    onClick={() => {
                        setIsShowCalendar(!isShowCalendar);
                    }}
                >
                    <img src={calendarImg} alt="calendarImg" /> {format(new Date(filters.startDate ?? new Date()), "yy.MM.dd")} ~{" "}
                    {format(new Date(filters.endDate ?? new Date()), "yy.MM.dd")}
                </div>
                <div css={inquiryTypeWrap(filters.inquiryType === "call", filters.inquiryType)}>
                    <div
                        css={inquiryTypeButton(filters.inquiryType === "call", "call")}
                        onClick={() => {
                            changeFilters("call");
                        }}
                    >
                        <img src={expertPhone} alt="messageTypeImg" />
                        <span>{data?.pages[0]?.callCount}</span>
                    </div>
                    <div
                        css={inquiryTypeButton(filters.inquiryType === "message", "message")}
                        onClick={() => {
                            changeFilters("message");
                        }}
                    >
                        <img src={expertComment} alt="messageTypeImg" />
                        <span>{data?.pages[0].messageCount}</span>
                    </div>
                </div>
            </div>
            {data &&
                (data?.pages[0].list.length ? (
                    <div css={inquiryContents}>
                        <div css={inquirySortWrap}>
                            <span>총 {data?.pages[0].totalCount}건</span>
                            <button onClick={() => setVisible(!visible)} css={inquirySort}>
                                {sortStatus} <img src={ico_arrow_down} alt="arrowDownImg" />
                            </button>
                        </div>
                        <div css={inquiryList}>
                            {data?.pages.map((page: any) => {
                                return page.list.map((item: any, index: string) => (
                                    <Fragment key={index}>
                                        {filters.sortType === 0 && <Card key={index} item={item} openPopup={openPopup} />}
                                        {filters.sortType === 1 && (
                                            <ItemCard key={index} item={item} filters={filters} openPopup={openPopup} />
                                        )}
                                        {filters.sortType === 2 && (
                                            <UserCard key={index} item={item} filters={filters} openPopup={openPopup} />
                                        )}
                                    </Fragment>
                                ));
                            })}

                            <div css={infiniteScrollBar} ref={sentryRef}></div>
                        </div>
                    </div>
                ) : (
                    <div css={noDataWrap}>
                        <img src={emptyImg} alt="noDataImg" />
                        <span>문의 내역이 없어요</span>
                    </div>
                ))}

            {visible && (
                <>
                    <div css={sortDim} onClick={() => setVisible(!visible)}></div>
                    <SortBottomSheet sortStatus={sortStatus} changeSortType={changeSortType} sortTypeList={sortTypeList} />
                </>
            )}
            {isShowCalendar && (
                <>
                    <div css={sortDim}></div>
                    <div css={dateRangeWrap(state)}>
                        <div css={calendarRangeWrap}>
                            <img src={calendarImg} alt="calendarImg" />{" "}
                            {format(new Date(state[0].startDate ?? new Date()), "yy.MM.dd")} ~
                            {format(new Date(state[0].endDate ?? new Date()), "yy.MM.dd")}
                        </div>
                        <DateRangePicker
                            editableDateInputs={true}
                            onChange={(item: any) => setState([item.selection])}
                            ranges={state}
                            months={1}
                            direction="vertical"
                            scroll={{ enabled: true }}
                            maxDate={new Date()}
                            minDate={new Date("2023-01-01")}
                            locale={ko}
                            staticRanges={[]}
                            inputRanges={[]}
                            monthDisplayFormat={"yyyy년 MM월"}
                        />
                        <div css={calendarButtonWrap}>
                            <button
                                onClick={() => {
                                    setIsShowCalendar(!isShowCalendar);
                                }}
                            >
                                닫기
                            </button>
                            <button
                                onClick={() => {
                                    setFilters({
                                        ...filters,
                                        startDate: format(state[0].startDate, "yyyy-MM-dd"),
                                        endDate: format(state[0].endDate, "yyyy-MM-dd"),
                                    });
                                    setIsShowCalendar(!isShowCalendar);
                                }}
                            >
                                적용하기
                            </button>
                        </div>
                    </div>
                </>
            )}
            {isShowConnectionPopup && <ConnectionPopup userPhone={connectionUserPhoneNum} closePopup={closePopup} />}
        </>
    );
};

export default Inquiry;

const titleWrap = css`
    display: flex;
    justify-content: center;
    position: relative;
    padding: 20px;
    align-items: center;
    & > .backBtn {
        position: absolute;
        left: 20px;
        width: 10px;
        height: 17px;

        & > img {
            width: 100%;
            height: 100%;
        }
    }
    & > .title {
        font-weight: 700;
    }

    & > .buttonWrap {
        display: flex;
        padding: 14px;
        width: 100%;
        gap: 8px;

        justify-content: center;
        & > button {
            width: 50%;
            height: 52px;
            align-items: center;
            font-size: 16px;
            line-height: 26px;
        }
    }
`;

const filterWrap = css`
    display: flex;
    width: 100%;
    height: 48px;
    padding: 8px;
    justify-content: space-between;
    background-color: #f7f8fa;
    align-items: center;
`;

const dateRangeWrap = (ranges: any) => css`
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 4;
    height: 472px;
    width: 312px;
    .rdrDateRangePickerWrapper {
        border-radius: 14px;
        height: 472px !important;
        width: 312px !important;
    }
    .rdrDateRangeWrapper.rdrCalendarWrapper {
        border-radius: 14px;
        /* height: 374px; */
        height: 472px !important;
        width: 312px !important;
    }
    .rdrInfiniteMonths {
        height: 274px !important;
        width: 312px !important;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .rdrDateDisplayWrapper {
        border-radius: 14px 14px 0px 0px;
        background-color: white !important;
        height: 68px;
        box-sizing: border-box !important;
    }
    .rdrDateDisplay {
        display: none !important;
        margin: 14px 14px 0px 14px !important;
        background-color: #f7f8fa !important;
        box-sizing: border-box !important;
        height: 40px;
        border-radius: 8px !important;
    }
    .rdrDateDisplayItemActive {
        border-color: none !important;
    }
    .rdrDateInput {
        border-radius: 0px !important;
        background-color: #f7f8fa !important;
        box-shadow: none !important;
        border: none !important;
    }
    .rdrMonths {
        display: flex;
        flex-direction: column;
    }
    .rdrDefinedRangesWrapper {
        display: none !important;
    }
    .rdrNextPrevButton {
        display: none !important;
    }
    .rdrMonthAndYearPickers {
        display: none !important;
    }
    .rdrMonthAndYearWrapper {
        display: none !important;
    }
    .rdrWeekDays {
        height: 32px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        margin: 0 0.8333em;
        padding: 0 !important;
        border-bottom: 0.6px solid #c9c9c9 !important;
        & > .rdrWeekDay:nth-of-type(1) {
            color: #a3a3a3 !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & > .rdrWeekDay:nth-of-type(7) {
            color: #a3a3a3 !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & > .rdrWeekDay {
            color: #0c002c !important;
            width: 28px !important;
            height: 28px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .rdrMonth {
        width: 312px !important;
        height: fit-content !important;
    }
    .rdrMonthName {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #0c002c !important;
    }
    .rdrDayPassive {
        opacity: 0;
    }
    .rdrDay {
        display: flex;
        justify-content: center;
        align-items: center;
        & > .rdrDayNumber {
            & > span {
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
                color: #0c002c !important;
            }
        }
    }
    .rdrDayWeekend {
        & > .rdrDayNumber {
            & > span {
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
                color: #a3a3a3 !important;
            }
        }
    }

    .rdrStartEdge.rdrEndEdge {
        background: ${Common.colors.aucMarkerColor};
        height: 28px;
        width: 28px;
        z-index: 1;
        border-radius: 24px;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            span {
                z-index: 2;
                font-weight: 400;
                color: white !important;
            }
        }

        .rdrDayActive {
            display: none;
        }
    }

    .rdrDayStartPreview {
        border: none !important;
    }

    .rdrStartEdge {
        position: unset;
        background: ${Common.colors.aucMarkerColor} !important;

        height: 28px;
        width: 28px;
        z-index: 1;
        border-radius: 24px;
        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            width: 100%;
            ${ranges[0].endDate !== ranges[0].startDate && {
                backgroundImage: "linear-gradient(to right, white 50%, #dff0ff 50%)",
            }};
            ${ranges[0].endDate === ranges[0].startDate && {
                backgroundImage: "none",
                background: "none",
            }};

            span {
                z-index: 2;
                font-weight: 400;
                color: white !important;
            }
        }

        .rdrDayActive {
            display: none;
        }
    }
    .rdrEndEdge {
        position: unset;
        background: ${Common.colors.aucMarkerColor};
        height: 28px;
        width: 28px;
        border-radius: 24px;
        z-index: 1;

        & + .rdrDayNumber,
        & + span + .rdrDayNumber {
            background: #dff0ff;

            ${ranges[0].endDate !== ranges[0].startDate && {
                backgroundImage: "linear-gradient(to right, #dff0ff 50%, white 50%)",
            }};
            ${ranges[0].endDate === ranges[0].startDate && {
                backgroundImage: "none",
                background: "none",
            }};

            span {
                z-index: 2;
                color: white !important;
            }
        }
    }
    .rdrDayNumber {
        span {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
            color: black;
            z-index: 1;
        }
    }

    .rdrInRange {
        ${ranges[0].endDate === null &&
        ranges[0].startDate === null && {
            backgroundColor: "white !important",
        }};
    }

    .rdrInRange:first-of-type {
        background-color: #dff0ff;
    }
    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after {
        background: none;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after {
        background: none;
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrDayNumber span:after {
        background: none;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
        color: black;
    }
    .rdrStartEdge.rdrEndEdge + .rdrDayNumber {
        background: none !important;
        background-image: none !important;
    }
`;

const inquiryTypeWrap = (isActive: boolean, type?: string) => css`
    display: flex;
    width: 148px;
    height: 100%;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    & > div:nth-of-type(1) {
        border-radius: 4px 0 0 4px;
    }
    & > div:nth-of-type(2) {
        border-radius: 0 4px 4px 0;
    }
`;

const inquiryTypeButton = (isActive: boolean, type?: string) => css`
    display: flex;
    width: 72px;
    height: 100%;
    box-sizing: border-box;
    background-color: ${isActive ? (type === "call" ? "#0047FF" : "#09AA4B") : "white"};
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    & > span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${isActive ? "white" : "#505050"};
    }
    & > img {
        width: 24px;
        height: 24px;
        filter: ${!isActive
            ? type === "call"
                ? "invert(34%) sepia(100%) saturate(7499%) hue-rotate(226deg) brightness(100%) contrast(109%)"
                : "invert(49%) sepia(54%) saturate(3584%) hue-rotate(115deg) brightness(92%) contrast(93%)"
            : "invert(99%) sepia(0%) saturate(2131%) hue-rotate(233deg) brightness(123%) contrast(100%)"};
    }
`;

const inquiryContents = css`
    width: 100%;
    padding: 8px 14px 14px 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
`;
const inquirySortWrap = css`
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;

const inquirySort = css`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${Common.colors.normalTextColor};
    img {
        margin-left: 6px;
        width: 12px;
        height: 7px;
    }
`;

const inquiryList = css`
    width: 100%;
    height: calc(100vh - 144px);
    padding-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const sortDim = css`
    width: 100%;
    height: 100%;
    background-color: #282828;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
`;

const calendarButtonWrap = css`
    position: fixed;
    height: 98px;
    width: 100%;
    bottom: 0px;
    background-color: white;
    border-radius: 0px 0px 14px 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 14px 14px 14px;
    gap: 8px;

    & > button {
        width: 50%;
        height: 56px;
        background-color: blue;
        border-radius: 14px;
    }
    & > button:nth-of-type(1) {
        background-color: #ebebeb;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    & > button:nth-of-type(2) {
        background-color: #2275ee;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: #ffffff;
    }
`;

const calendarRangeWrap = css`
    width: 284px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
    position: absolute;

    top: 14px;
    left: 14px;
    background-color: #f7f8fa;
    & > img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }
`;

const calendarDateRangeWrap = css`
    width: 188px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #3c3c3c;
    background-color: white;
    box-sizing: border-box;
    white-space: nowrap;
    & > img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }
`;

const infiniteScrollBar = css`
    padding: 100px;
    margin-top: -400px;
`;

const noDataWrap = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 164px);
    box-sizing: border-box;
    gap: 24px;
    & > img {
        width: 124px;
        height: 83px;
    }
    & > span {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        color: #0c002c;
    }
`;
