import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction } from "react";
import closeIcon from "../../assets/ico_close.png";

interface itemType {
    claim_amount_marker: string;
    address: string;
    bid_type: number;
    bid_type_detail?: number;
    item_id: string;
    latd: number;
    lntd: number;
    min_price: number;
    min_price_detail: string;
    min_price_marker: string;
    square: number;
    sub_id: string;
    use: string;
    view_count: number;
    load_address: string;
    item_number?: string;
    claim_amount_detail: string;
}

interface SaleCardProps {
    item: itemType;
    hideLandCard: () => void;
    setIsSaleCardShow: Dispatch<SetStateAction<boolean>>;
    hidePolyline: () => void;
}

const SaleCard = ({ item, hideLandCard, setIsSaleCardShow, hidePolyline }: SaleCardProps) => {
    return (
        <>
            <div
                css={saleCard}
                className={"summCard"}
                onClick={() => {
                    // const isSubscription = localStorage.getItem("isSubscription");
                    // isSubscription === "true"
                    //     ? transferToNative(JSON.stringify(item), "showItemDetail")
                    //     : transferToNative("", "showSubscriptionPopup");
                    transferToNative(JSON.stringify(item), "showItemDetail");
                }}
            >
                <div className="Header">
                    <div className="handle" />
                </div>
                <div className="Contents">
                    <div className="topSideData">
                        <div className="title">
                            <div className="titleContent">
                                {item.bid_type_detail === 1 && <div className="auction">경매</div>}
                                {item.bid_type_detail === 2 && <div className="shortSale">공매</div>}
                                {item.bid_type_detail === 3 && <div className="shortSale">공매임대</div>}
                                {item.bid_type_detail === 4 && <div className="auction">지분경매</div>}
                                {item.bid_type_detail === 5 && <div className="shortSale">지분공매</div>}
                                {item.bid_type_detail === 6 && <div className="expected">예정매물</div>}
                                <div className="titleText">{item.use}</div>
                                <span>
                                    {item.sub_id} {item.item_number && `(${item.item_number})`}
                                </span>
                            </div>
                            <div className="cancle">
                                <img
                                    className="cancleImg"
                                    css={closeIcoImg}
                                    src={closeIcon}
                                    alt=""
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        hidePolyline();
                                        hideLandCard();
                                        setIsSaleCardShow(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="lowPrice">
                            {item.bid_type_detail === 6 ? (
                                item.claim_amount_marker === "" || item.claim_amount_marker === "비공개" ? null : (
                                    <>
                                        청구금액<span>{item.claim_amount_detail}</span>
                                    </>
                                )
                            ) : (
                                <>
                                    최저입찰가<span>{item.min_price_detail}</span>
                                </>
                            )}
                        </div>
                        {/* <div className="managementNumber">
                            물건관리번호 <span>{item.sub_id}</span>
                        </div> */}
                    </div>

                    <div className="row"></div>
                    <div className="BottomSideData">
                        <div className="text">{item.address}</div>
                        <div className="roadAddress">
                            {item.load_address && <span>도로명</span>}
                            {item.load_address}
                        </div>
                        <div className="dataCheck">조회 {item.view_count ? item.view_count : 0}</div>
                        {item.bid_type_detail === 6 &&
                            (item.claim_amount_marker === "" || item.claim_amount_marker === "비공개") && (
                                <div className="row_noPrice" />
                            )}
                    </div>
                </div>

                {/* <div className="otherContents">
                    <div className="check">조회 {item.view_count ? item.view_count : 0}</div>
                </div> */}
            </div>
        </>
    );
};

const saleCard = css`
    min-width: 100vw;
    width: 100vw;
    height: fit-content;
    min-height: 266.5px;
    box-sizing: border-box;
    padding: 10px 14px 14px 14px;
    background: ${Common.colors.white};
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 14px 14px 0px 0px;
    z-index: 1000;
    .Header {
        height: 15px;
        & > .handle {
            width: 40px;
            height: 4px;
            border-radius: 2px;
            background-color: ${Common.colors.grey04};
            margin: auto;
        }
    }
    .Contents {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 18px;
    }
    .topSideData {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .titleContent {
        display: flex;
        gap: 4px;
        align-items: center;
        & > span {
            font-weight: 400;
            font-size: 14px;
            color: ${Common.colors.normalTextColor};
        }
    }
    .auction {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        width: fit-content;
        height: 20px;
        font-size: 11px;
        font-weight: 400;
        border-radius: 4px;
        background-color: ${Common.colors.aucMarkerColor};
        color: white;
    }
    .shortSale {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        width: fit-content;
        height: 20px;
        font-size: 11px;
        font-weight: 400;
        border-radius: 4px;
        background-color: ${Common.colors.pubMarkerColor};
        color: white;
    }
    .expected {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 4px;
        width: fit-content;
        height: 20px;
        font-size: 11px;
        font-weight: 400;
        border-radius: 4px;
        background-color: ${Common.colors.expcMarkerColor};
        color: white;
    }
    .titleText {
        ${Common.textStyle.title2_M16}
        color:${Common.colors.normalTextColor};
        font-weight: 700;
    }
    .lowPrice {
        ${Common.textStyle.title6_R14}
        color:${Common.colors.darkGrey01};
        display: flex;
        align-items: center;
        margin-top: 4px;
        & > span {
            font-weight: 700;
            margin-left: 4px;
            color: ${Common.colors.normalTextColor};
        }
    }
    .managementNumber {
        ${Common.textStyle.title6_R14}
        color:${Common.colors.darkGrey01};
        display: flex;
        align-items: center;
        & > span {
            margin-left: 4px;
            color: ${Common.colors.normalTextColor};
        }
    }
    .row {
        height: 0.6px;
        width: 100%;
        background-color: ${Common.colors.grey};
        margin-top: 10px;
        margin-bottom: 14px;
    }
    .row_noPrice {
        height: 20px;
        width: 100%;
    }
    .roadAddress {
        font-weight: 400;
        font-size: 14px;
        color: ${Common.colors.normalTextColor};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 12px;
        & > span {
            width: 43px;
            height: 18px;
            font-weight: 400;
            font-size: 12px;
            color: ${Common.colors.redSecondary};
            border-radius: 4px;
            border: 1px solid ${Common.colors.redSecondary};
            padding: 3px;
            margin-right: 4px;
        }
    }
    .BottomSideData {
        ${Common.textStyle.title6_R14}
        color:${Common.colors.normalTextColor};
        .text {
            width: calc(100vw - 28px);
            line-height: 24px;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        ${Common.textStyle.title6_R14};
        color: ${Common.colors.normalTextColor};
        /* width: 100%; */
        width: calc(100vw - 28px);
        line-height: 24px;
        white-space: normal;
        min-height: 134px;
    }
    .pagenation {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: ${Common.colors.normalTextColor};
        gap: 12px;
        text-align: center;
        & > img {
            width: 7px;
            height: 11px;
        }
    }
    .check {
        position: absolute;
        /* justify-content: flex-end; */

        /* bottom: 30px; */
        right: 0px;
        ${Common.textStyle.text1_R13}
        color:${Common.colors.greyPrimary}
    }
    .dataCheck {
        ${Common.textStyle.text1_R13}
        color:${Common.colors.greyPrimary};
        display: flex;
        justify-content: flex-end;
        margin-top: 4px;
    }
    .otherContents {
        position: absolute;
        bottom: 42px;
        display: flex;
        width: ${window.innerWidth - 28}px;
        align-items: center;
        justify-content: center;
    }
`;

const closeIcoImg = css`
    width: 16px;
    height: 16px;
    box-sizing: border-box;
`;

export default SaleCard;
