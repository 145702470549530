import React from "react";
import { useLocation } from "react-router-dom";

import closeIcon from "@src/assets/ico_close.png";
import { css } from "@emotion/react";

interface Props {
    onClickBack: () => void;
}

const AiRecommendTitle = ({ onClickBack }: Props) => {
    const { pathname } = useLocation();
    return (
        <div css={rootStyle}>
            {!pathname.includes("small") ? (
                <div className="title">
                    <span>AI</span> 추천 매물
                </div>
            ) : (
                <div className="title">소액 투자</div>
            )}
            <button css={buttonStyle} onClick={onClickBack}>
                <img src={closeIcon} />
            </button>
        </div>
    );
};

export default AiRecommendTitle;

const rootStyle = css`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 56px;
    border-radius: 0px;
    box-shadow: 0px 4px 4px 0px rgba(4, 0, 14, 0.08);
    img {
        width: 14px;
        height: 14px;
    }
    & > .title {
        padding: 14px 0px;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0c002c;
        span {
            color: #2d54df;
        }
    }
`;

const buttonStyle = css`
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 15px;
    height: 56px;
    cursor: pointer;
`;
