import React from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { IProduct } from "@src/hooks/stateLand/useStateLand";
import { transferToNative } from "@src/lib/WebAppBridge";
import BottomInfoSingle from "./BottomInfoSingle";
import BottomInfoMultiple from "./BottomInfoMultiple";
import convertStrToPrice from "@src/util/convertStrToPrice";
import circleWithStart from "@src/assets/subs/circle_with_start.svg";

interface Props {
    selectedProduct: IProduct;
    onClickChange: () => void;
    onClickSubmit: () => Promise<void>;
    selectedProductList: IProduct[];
    setSelectedProductList: (val: IProduct[]) => void;
    topRef?: any;
    landRef?: any;
}

const BottomInfoPage: React.FC<Props> = ({
    selectedProduct,
    setSelectedProductList,
    onClickChange,
    onClickSubmit,
    selectedProductList,
    landRef,
    topRef,
}) => {
    const [searchParams] = useSearchParams();

    return (
        <>
            <div css={rootBoxStyle}>
                <div css={productInfoBoxStyle}>
                    <p className="productInfoBoxTitle">선택한 상품</p>
                    <BottomInfoSingle
                        topRef={topRef}
                        selectedProductList={selectedProductList}
                        setSelectedProductList={(val: IProduct[]) => setSelectedProductList(val)}
                    />
                    <BottomInfoMultiple
                        landRef={landRef}
                        selectedProductList={selectedProductList}
                        setSelectedProductList={(val: IProduct[]) => setSelectedProductList(val)}
                        selectedProductMultiList={selectedProductList.filter(
                            (data) =>
                                data.type == "state_land_filter" ||
                                data.type == "road_plan" ||
                                data.type === "premium_filter" ||
                                data.type === "Retail_profit_rate",
                        )}
                    />
                    {selectedProductList.length > 0 &&
                        selectedProductList.some((product) => product.isEvent && product.extraData?.installment! > 0) && (
                            <div css={monthlyInstallmentDescStyle}>
                                결제 시, 할부 개월을 꼭{" "}
                                <div>
                                    <img src={circleWithStart} alt="할부개월" className="product_circle_start" />
                                    <strong>{3}개월</strong>로{" "}
                                </div>
                                선택해 주세요!
                            </div>
                        )}
                </div>
                <div css={priceContainer}>
                    <p className="priceTitle">총 결제금액</p>
                    <p className="price">
                        {selectedProductList.length > 0
                            ? convertStrToPrice(
                                  String(selectedProductList.map((data) => data.term1DiscountedPrice).reduce((a, b) => a + b)),
                              )
                            : "0"}
                        원
                    </p>
                </div>
                {searchParams.get("type") === "8" && (
                    <div css={inAppAgreeBoxStyle}>구독 이용 및 해지/취소 안내를 확인 하였으며, 결제에 동의합니다.</div>
                )}
                <div css={buttonBoxStyle}>
                    <button
                        disabled={selectedProductList.length == 0}
                        css={paymentButtonStyle(selectedProductList.length > 0)}
                        onClick={onClickSubmit}
                    >
                        구매하기
                    </button>
                </div>
            </div>
        </>
    );
};

export default BottomInfoPage;

const rootBoxStyle = css`
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100vw;
    /* padding: 14px 14px 14px 24px; */
`;

const priceContainer = css`
    display: flex;
    border-top: 1px solid #dadada;
    padding: 16px 14px;
    width: 100%;
    justify-content: space-between;

    .priceTitle {
        font-size: 14px;
        color: #3c3c3c;
    }

    .price {
        font-size: 18px;
        color: #0047ff;
        font-weight: bold;
    }
`;

const inAppAgreeBoxStyle = css`
    color: #8d8d8d;
    padding: 0px 16px;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
`;

const productInfoBoxStyle = css`
    border-radius: 4px 4px 0 0;
    padding: 8px 14px;
    display: flex;
    flex-direction: column;
    max-height: 40vh;
    overflow-y: scroll;
    gap: 4px;

    .productInfoBoxTitle {
        color: #0047ff;
        padding: 10px 0px;
    }
`;

const productInfoStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .name {
        color: #0c002c;
        font-size: 16px;
        font-weight: 700;
    }
    & > .btn {
        padding: 0 8px;
        border-radius: 8px;
        border: 1px solid #0047ff;
        color: #0047ff;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
`;

const buttonBoxStyle = css`
    padding: 0 14px 24px;
`;

const paymentButtonStyle = (disabled: boolean) => css`
    padding: 14px;
    border-radius: 14px;
    background-color: ${disabled ? "#2275ee" : "#EBEBEB"};
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    color: ${disabled ? "#ffffff" : "#0C002C"};
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
    box-sizing: border-box;
`;

const monthlyInstallmentDescStyle = css`
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    display: flex;

    & > div {
        position: relative;
    }
    & .product_circle_start {
        position: absolute;
        bottom: 0;
        left: -4px;
    }
`;
