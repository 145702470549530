import { css } from "@emotion/react";
import { IProduct, initialProductData } from "@src/hooks/stateLand/useStateLand";
import React, { useEffect, useState } from "react";
import closeIcon from "@src/assets/subscription/grayCloseIcon.svg";

interface Props {
    topRef: any;
    selectedProductList?: IProduct[] | any;
    setSelectedProductList?: (val: IProduct[]) => void;
}

const BottomInfoSingle = ({ selectedProductList, topRef, setSelectedProductList }: Props) => {
    const [selectedProduct, setSelectedProduct] = useState<IProduct>(initialProductData);

    useEffect(() => {
        if (selectedProductList.some((list: IProduct) => list.type === "subscription")) {
            setSelectedProduct(selectedProductList.filter((list: IProduct) => list.type == "subscription")[0]);
        }
    }, [selectedProductList]);

    return (
        <>
            {selectedProductList.some((list: any) => list.type === "subscription") && (
                <div css={singleContainer}>
                    <div css={titleContainer}>
                        <p>구독 상품</p>
                        <button
                            onClick={() => {
                                topRef?.current.scrollIntoView({
                                    block: "start",
                                    behavior: "smooth",
                                });
                            }}
                        >
                            변경
                        </button>
                    </div>
                    <div css={productContainer}>
                        <p className="">{selectedProduct?.name}</p>
                        <button
                            onClick={() => {
                                setSelectedProductList &&
                                    setSelectedProductList(
                                        selectedProductList.filter((data: any) => data.code !== selectedProduct.code),
                                    );
                            }}
                        >
                            <img src={closeIcon} alt="" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomInfoSingle;

const singleContainer = css``;

const titleContainer = css`
    display: flex;
    align-items: center;
    gap: 10px;
    & > p {
        font-size: 14px;
        color: #8d8d8d;
    }
    & > button {
        color: white;
        background: #2275ee;
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 16px;
    }
`;

const productContainer = css`
    display: flex;
    margin: 16px 0px;
    color: #0c002c;
    background-color: #f7f8fa;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    & > p {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
