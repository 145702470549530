import styled from "@emotion/styled";
import { CampusDetailReview } from "@src/hooks/campus/dto/CampusDetail.dto";
import { useGetReviews } from "@src/hooks/campus/useCampusDetail";
import React, { useEffect, useState } from "react";
import title from "../../../assets/gradeTitle.png";
import GradeItem from "./GradeItem";

interface GradeProps {
    no: number;
}

const Grade = ({ no }: GradeProps) => {
    const [reviews, setReviews] = useState<CampusDetailReview[]>([]);

    const tryGetReviews = async () => {
        const response = await useGetReviews(no);
        setReviews(response.data.list);
    };

    useEffect(() => {
        tryGetReviews();
    }, []);

    return (
        <>
            <StyledGrade>
                <div className="gradeTitle">
                    <img src={title} alt="" />
                </div>
                <div className="gradeContents">
                    {reviews.map((review: CampusDetailReview, idx: number) => {
                        return <GradeItem key={review.createdAt + idx} review={review} />;
                    })}
                </div>
            </StyledGrade>
        </>
    );
};

export default Grade;

const StyledGrade = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffe3ac;
    padding-top: 40px;

    .gradeTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        & > img {
            width: 89px;
            height: 38px;
        }
    }
    .gradeContents {
        display: flex;
        width: 100vw;
        padding: 22px 0px;
        overflow-x: scroll;
        padding-right: 14px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;
