import { axiosAsync } from "@src/api/abortableAxiosConfig";
import { axiosInstance, createJWTToken } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { getDeviceType } from "@src/lib/WebAppBridge";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

//* 유저 정보 가져오기
export const useUserInfo = (enabled: boolean = false) => {
    const tokenValue = typeof window !== "undefined" && localStorage.getItem("accessToken");
    return useQuery(
        ["user", tokenValue],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.userInfoPath)
                .then((res) => {
                    return res.data.payload.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return result;
        },
        {
            enabled,
        },
    );
};

export const useUpdateFieldDetailCount = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: IFieldDetailCountDTO) => {
            const device = getDeviceType() === "android" ? "aos" : getDeviceType() === "ios" ? "ios" : "mobile";
            const newParams = { ...params, from: device };
            try {
                const response = await axiosInstance.put(axiosPath.fieldDetailCountPath, { ...newParams });
                return response;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//? 유저 구독정보 가져오기
export const useFindOneUserSubsInfo = (enabled: boolean = false) => {
    return useQuery<IUserSubsInfo>(
        [
            "userSubs",
            typeof window !== "undefined" && localStorage?.getItem("userSession")
                ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                : "",
        ],
        async () => {
            try {
                const result = await axiosInstance
                    .get(axiosPath.userSubsInfoPath)
                    .then((res) => {
                        const error = res as any;
                        if (error?.response?.data?.message?.includes("구독")) {
                            return initialUserSubsInfo;
                        }
                        return res?.data ?? initialUserSubsInfo;
                    })
                    .catch((err) => {
                        if (err?.response?.data?.message?.includes("구독")) {
                            return initialUserSubsInfo;
                        }
                        console.log("err>>>", err);
                        return initialUserSubsInfo;
                    });
                return result;
            } catch (error: any) {
                if (error?.response?.data?.message?.includes("구독")) {
                    return initialUserSubsInfo;
                }
                console.log("err>>", error);
                return initialUserSubsInfo;
            }
        },
        {
            enabled,
            retry: 0,
        },
    );
};

//? 유저 푸시알림 정보
export const useFindOneUserPushAgreements = (enabled: boolean = false) => {
    return useQuery<IPushAgreement[]>(
        [
            "userPushAgreements",
            typeof window !== "undefined" && localStorage?.getItem("userSession")
                ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                : "",
        ],
        async () => {
            try {
                const result = await axiosInstance.get(axiosPath.agreements);
                return result?.data;
            } catch (error: any) {
                console.log("err>>", error);
                throw error;
            }
        },
        {
            enabled,
        },
    );
};

export const useFindOneUserAiPushAgreement = (enabled: boolean = false) => {
    return useQuery<IPushAgreement[] | any>(
        [
            "userPushAgreements",
            typeof window !== "undefined" && localStorage?.getItem("userSession")
                ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                : "",
        ],
        async () => {
            try {
                const result = await axiosInstance.get(axiosPath.agreements);
                const currentData = result?.data?.filter((v: any) => v.type.includes("agree_push_recommend_item"));
                return currentData ? currentData : [];
            } catch (error: any) {
                console.log("err>>", error);
                throw error;
            }
        },
        {
            enabled,
        },
    );
};

export const useUpdateUserPushAgreement = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: IPushAgreement[]) => {
            const newParams = { userAgreements: params };
            try {
                const response = await axiosInstance.put(axiosPath.agreements, { ...newParams });
                return response;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export interface IFieldDetailCountDTO {
    pnu: string;
    from?: string;
    cookieNo?: string;
}
export interface IUserSubsInfo {
    subsNo: number;
    orderProductNo: number;
    name: string;
    productName: string;
    subsName: string;
    nextPrice: number;
    expiredAt: string;
    expiredAtToString?: string;
    nextRenewAt?: string;
    nextRenewAtToString?: string;
    nextRenewAtBackup: string;
    isSubsCancelReserve: boolean;
    isInAppSubs: boolean;
    isFreeIssueSubs: boolean;
    serveDurationType: string;
    canBuyStateLand: boolean;
}

export const initialUserSubsInfo: IUserSubsInfo = {
    subsNo: 0,
    orderProductNo: 0,
    name: "",
    productName: "",
    subsName: "",
    nextPrice: 0,
    expiredAt: "",
    expiredAtToString: "",
    nextRenewAt: "",
    nextRenewAtToString: "",
    nextRenewAtBackup: "",
    isSubsCancelReserve: true,
    isInAppSubs: true,
    isFreeIssueSubs: false,
    serveDurationType: "none",
    canBuyStateLand: false,
};

export interface IPushAgreement {
    type: string;
    agreement: boolean;
    dueDate?: number[];
}
