import { axiosInstance, createJWTToken } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

export interface IUserInquiryItem {
    no?: number;
    category?: string;
    title?: string;
    contents?: string;
    createdAt?: string;
    state?: string;
    createdAtToString?: string;
    imgUrl?: {
        name?: string;
        path?: string;
    }[];
}

export const getInquiryItemDetail = (param: number) => {
    const fetchInquiryDetail = async () => {
        const response = await axiosInstance.get(`${axiosPath.inquiry}/${param}`);
        return response?.data;
    };
    return useQuery<IUserInquiryItem>(
        [
            "useInquiryItemDetail",
            typeof window !== "undefined" && localStorage?.getItem("userSession")
                ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                : "",
        ],
        fetchInquiryDetail,
        {
            enabled: false,
        },
    );
};

//*이미지 보내기
export const useSaverInquiryImg = (onSuccess: (datum: any) => void = () => {}, onError: (err: AxiosError) => void = () => {}) => {
    return useMutation(
        async (formData) => {
            try {
                const res = await axiosInstance.post(`${axiosPath.inquiry}/upload/image`, formData, {
                    headers: {
                        Authorization:
                            typeof window !== "undefined" &&
                            createJWTToken(
                                localStorage?.getItem("userSession")
                                    ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                                    : "",
                            ),
                    },
                });
                return res;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//* 문의 등록

export const useSaverInquiryContent = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params) => {
            try {
                const res = await axiosInstance.post(`${axiosPath.inquiry}`, params, {
                    headers: {
                        Authorization:
                            typeof window !== "undefined" &&
                            createJWTToken(
                                localStorage?.getItem("userSession")
                                    ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                                    : "",
                            ),
                    },
                });
                return res;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//*문의 수정
export const useEditInquiryDetail = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async ({ inquiryChatNo, inquiryParams }: { inquiryChatNo: number; inquiryParams: IUserInquiryItem }) => {
            try {
                const res = await axiosInstance.put(`${axiosPath.inquiry}/${inquiryChatNo}`, inquiryParams, {
                    headers: {
                        Authorization:
                            typeof window !== "undefined" &&
                            createJWTToken(
                                localStorage?.getItem("userSession")
                                    ? JSON.parse(localStorage?.getItem("userSession") ?? "")?.value?.accessToken
                                    : "",
                            ),
                    },
                });
                return res;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
