import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { DeungiListRequestDto } from "./dto/DeungiList.dto";

export const useDeungiList = async (params: DeungiListRequestDto) => {
    const queryString = new URLSearchParams(params as any).toString();
    const response = await axiosInstance.get(`${axiosPath.deungiList}?${queryString}`);
    return response;
};

export const useDeungiListByPnu = async (pnu: string) => {
    const response = await axiosInstance.get(`${axiosPath.deungiListByPnu}?pnu=${pnu}`);
    return response;
};