import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { IProduct, useFindAllStateLandProducts } from "@src/hooks/stateLand/useStateLand";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { transferToNative } from "@src/lib/WebAppBridge";
import closeIcon from "@src/assets/basicIcon/whiteCloseIcon.svg";
import { useGetStateLandSubs, useGetSubsInfo } from "@src/hooks/subscribe/useSubscribe";
import { Grid, Radio } from "@mui/material";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import StateLandEventProduct from "./StateLandEventProduct";
import StateLandDesc from "./StateLandDesc";
import StateLandProduct from "./StateLandProduct";
import NewStateLandProduct from "./NewStateLandProduct";
import { useFindAllProduct } from "@src/hooks/subscribe/useSubsProducts";
import ProductContents from "@src/pages/subscription/ProductContents";

interface Props {}

const StateLandInfo: React.FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const { data: productData, refetch: refetchProductData } = useFindAllProduct(searchParams?.get("type") ?? "8");
    const { data, refetch } = useFindAllStateLandProducts(searchParams.get("type") ?? "8");

    const [openModal, setOpenModal] = useState<number>(0); // 1 무료구독이용중 2 구독이용중 3 필터 이용중 4 인앱 이용중 5 구독이용중변경 99 에러
    const [openTooltip, setOpenTooltip] = useState<number>(0); // 1 프리미엄 2 국공유지
    const [selectedProduct, setSelectedProduct] = useState<any>(data?.subsList?.[0]);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const onChangeModalContents = (type: number) => {
        switch (type) {
            case 1:
                return (
                    <>
                        <p css={dialogTitleStyle}>미스고 구독 무료체험 중 입니다</p>
                        <p css={dialogContentStyle}>
                            결제를 진행하시면 무료체험이 즉시 종료되고
                            <br />더 많은 혜택의 구독이 시작돼요
                        </p>
                    </>
                );
            case 2:
                return (
                    <>
                        <p css={dialogTitleStyle}>이용 중인 구독이 있습니다</p>
                        <p css={dialogContentStyle}>
                            국공유지 이용권만 따로 구매해 보세요
                            <br />
                            <br />더 저렴한 가격으로
                            <br />
                            국공유지 검색을 이용할 수 있어요.
                        </p>
                    </>
                );
            case 3:
                return (
                    <>
                        <p css={dialogTitleStyle}>
                            선택한 지역은 이미 구매한 <br />
                            국공유지 상품이 있습니다
                            <br />
                            <br />
                            추가로 구매하시겠습니까?
                        </p>
                    </>
                );
            case 4:
                return (
                    <>
                        <p css={dialogTitleStyle}>
                            인앱 정기결제 구독 이용 중에는
                            <br />
                            다른 구독 상품으로 변경할 수 없어요.
                            <br />
                            <br />
                            구독이 만료된 후 결제해주세요
                        </p>
                    </>
                );
            case 5:
                return (
                    <>
                        <p css={dialogTitleStyle}>이용 중인 구독이 있습니다</p>
                        <p css={dialogContentStyle}>
                            새로운 구독을 결제하면
                            <br />
                            이용 중인 구독이 변경됩니다
                        </p>
                    </>
                );
            case 99:
                return (
                    <>
                        <p css={dialogTitleStyle}>에러</p>
                        <p css={dialogContentStyle}>
                            알 수 없는 이유로 에러가
                            <br />
                            발생했습니다. 다시 시도해주세요.
                        </p>
                    </>
                );
            default:
                return (
                    <>
                        <p css={dialogTitleStyle} />
                    </>
                );
        }
    };

    const [modalContents, setModalContents] = useState<any>(<div />);
    const [tooltipContents, setTooltipContents] = useState<any>(<div />);
    const onChangeTooltipContent = (type: number) => {
        switch (type) {
            case 1:
                return setTooltipContents(
                    <div css={tooltipBoxStyle}>
                        <p className="title">프리미엄 1년 구독권</p>
                        <p className="content">
                            {/* <li>선착순 50분 추가할인 이벤트</li> */}
                            <li>스탠다드 1년 구독 ＋ 국공유지 검색추가권(전국）</li>
                            <li>경매캠퍼스 무제한 1년 수강</li>
                            <br />※ 스탠다드 1년 구독 혜택
                            <br />
                            <li>실시간 등기열람 매월 3장 지급</li>
                            <li>전국 경·공매 정보 열람 가능</li>
                            <li>
                                터치 방식 데이터맵, AI추천, Go-Stop(등기분석), 매일 업데이트 되는 실거래가, 입찰대리 전문가 매칭
                                등 제공
                            </li>
                        </p>
                    </div>,
                );
            case 2:
                return setTooltipContents(
                    <div css={tooltipBoxStyle}>
                        <p className="title">국공유지 추가권</p>
                        <p className="content">
                            {/* <li>3만명 돌파 기념 추가 할인 이벤트</li> */}
                            <li>1년동안 구매지역 국공유지 지도 검색 기능 사용</li>
                        </p>
                    </div>,
                );
            default:
                return setTooltipContents(<div css={tooltipBoxStyle} />);
        }
    };

    const onClickProduct = (product: IProduct) => {
        setSelectedProduct(product);
    };

    const onClickSubmit = async () => {
        const res = await Promise.all([useGetSubsInfo(), useGetStateLandSubs()]);
        if (selectedProduct.type === "subscription") {
            if (res[0].isFreeIssueSubs) {
                setModalContents(onChangeModalContents(1));
                setOpenModal(1);
                return;
            }
            // if (res[0].subsNo && !res[1].totalCnt && selectedProduct?.subsCategory === 3) {
            //     setModalContents(onChangeModalContents(2));
            //     setOpenModal(2);
            //     return;
            // }
            if (res[0]?.subsNo && res[0]?.isInAppSubs && res[0].nextRenewAtToString) {
                setModalContents(onChangeModalContents(4));
                setOpenModal(4);
                return;
            }
            if (res[0]?.subsNo) {
                setModalContents(onChangeModalContents(5));
                setOpenModal(5);
                return;
            }
        }
        if (selectedProduct.type === "state_land_filter" && res[1]?.totalCnt) {
            if (
                res[1]?.allPnus === null ||
                res[1]?.allPnus?.some((val: any) => selectedProduct?.pnus?.some((pnu: any) => pnu === val))
            ) {
                setModalContents(onChangeModalContents(3));
                setOpenModal(3);
                return;
            }
        }
        const params = {
            productCode: selectedProduct.code,
            productType: selectedProduct.type,
            type:
                selectedProduct.subsCategory === 3
                    ? "state_land_subscription"
                    : selectedProduct.type === "subscription"
                    ? "subscription"
                    : "state_land_filter",
            renewTerm: ["monthly", "yearly"]?.some((val) => val === selectedProduct?.renewTerm),
        };
        if (videoRef.current) {
            videoRef.current.pause();
        }
        transferToNative(JSON.stringify(params), "payProductInApp");
    };

    useEffect(() => {
        searchParams.get("type") &&
            refetch().then((res) => {
                if (res.isFetched) {
                    setSelectedProduct(res.data?.subsList?.[0]);
                }
            });
        refetchProductData();
    }, [searchParams.get("type")]);

    useEffect(() => {
        console.log("selectedProduct", selectedProduct);
    }, [selectedProduct]);

    return (
        <>
            <div css={topTitleStyle}>
                {/* <p>국•공유지 필터 상품 LINE-UP</p> */}
                <p>구독권 LINE-UP</p>
                <img
                    src={closeIcon}
                    alt=""
                    className="close"
                    onClick={() => {
                        if (videoRef.current) {
                            videoRef.current.pause();
                        }
                        transferToNative("done", "closeWebView");
                    }}
                />
            </div>
            <div css={rootBoxStyle}>
                {data?.subsList && !!data?.subsList?.length && (
                    <div
                        css={productBoxStyle(selectedProduct?.subsCategory === 3 || !selectedProduct?.type)}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            onClickProduct(data?.subsList?.[0]);
                        }}
                    >
                        <div css={productTitleBoxStyle}>
                            <p css={productTitleStyle}>구독과 국•공유지 필터를 하나로</p>
                            <div css={productNameStyle}>
                                {/* <Radio
                                    checked={true}
                                    onChange={() => {}}
                                    sx={{
                                        width: "16px",
                                        height: "16px",
                                        color:
                                            selectedProduct?.type === "subscription"
                                                ? "#2275EE !important"
                                                : "#C9C9C9 !important",
                                    }}
                                /> */}
                                <p>프리미엄 구독</p>
                                <InfoRoundedIcon
                                    onClick={() => {
                                        onChangeTooltipContent(1);
                                        setOpenTooltip(1);
                                    }}
                                    sx={{ width: "20px", height: "20px", color: "#c9c9c9", marginTop: "-2px" }}
                                />
                            </div>
                        </div>
                        {/* <div
                            css={descStyle}
                            dangerouslySetInnerHTML={{
                                __html:
                                    data?.subsList?.[0]?.displayDescription
                                        ?.replaceAll("background-color: #ffffff;", "")
                                        ?.replaceAll("background-color", "padding: 0px 4px; background-color") ?? "",
                            }}
                        /> */}
                        {data?.subsList?.map((product) => (
                            // <StateLandEventProduct
                            <NewStateLandProduct
                                selectedProduct={selectedProduct}
                                product={product}
                                key={JSON.stringify(product)}
                                onClickProduct={onClickProduct}
                            />
                        ))}
                    </div>
                )}
                <ProductContents
                    selectedProduct={selectedProduct}
                    productList={productData?.list?.filter((val) => val?.subsCategory === 2)?.slice(0, 4)}
                    subsCategory={2}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                <ProductContents
                    selectedProduct={selectedProduct}
                    productList={productData?.list?.filter((val) => val?.subsCategory === 1)?.slice(0, 4)}
                    subsCategory={1}
                    onChangeSelectedProduct={(val: IProduct) => setSelectedProduct(val)}
                />
                {data?.stateLandList && !!data?.stateLandList?.length && (
                    <div
                        css={productBoxStyle(selectedProduct?.type === "state_land_filter")}
                        onClick={() => selectedProduct?.type !== "state_land_filter" && onClickProduct(data?.stateLandList?.[0])}
                    >
                        <div css={productTitleBoxStyle}>
                            <p css={productTitleStyle}>이미 구독 중이신가요?</p>
                            <div css={productNameStyle}>
                                {/* <Radio
                                    checked={true}
                                    onChange={() => {}}
                                    sx={{
                                        width: "16px",
                                        height: "16px",
                                        color:
                                            selectedProduct?.type === "state_land_filter"
                                                ? "#2275EE !important"
                                                : "#C9C9C9 !important",
                                    }}
                                /> */}
                                <p>
                                    {selectedProduct?.type === "state_land_filter"
                                        ? selectedProduct?.name ?? "국•공유지 검색추가"
                                        : data?.stateLandList?.[0]?.name}
                                </p>
                                <InfoRoundedIcon
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        onChangeTooltipContent(2);
                                        setOpenTooltip(2);
                                    }}
                                    sx={{ color: "#c9c9c9", marginTop: "-2px" }}
                                />
                            </div>
                        </div>
                        {/* <div
                            css={descStyle}
                            dangerouslySetInnerHTML={{
                                __html:
                                    selectedProduct?.subsCategory === 0
                                        ? selectedProduct?.displayDescription
                                              ?.replaceAll("background-color: #ffffff;", "")
                                              ?.replaceAll("background-color", "padding: 0px 4px; background-color")
                                        : data?.stateLandList?.[0]?.displayDescription
                                              ?.replaceAll("background-color: #ffffff;", "")
                                              ?.replaceAll("background-color", "padding: 0px 4px; background-color") ?? "",
                            }}
                        /> */}
                        {/* <StateLandEventProduct */}
                        <NewStateLandProduct
                            selectedProduct={selectedProduct}
                            product={selectedProduct?.subsCategory === 0 ? selectedProduct : data?.stateLandList?.[0]}
                            onClickProduct={onClickProduct}
                            type={0}
                        />
                        <Grid container spacing={1}>
                            {data?.stateLandList?.map((product, idx) => (
                                <Grid key={JSON.stringify(product)} item xs={4}>
                                    <div
                                        css={regionButtonStyle(
                                            idx
                                                ? selectedProduct?.code === product?.code
                                                : selectedProduct?.code === product?.code || selectedProduct?.subsCategory === 3,
                                            selectedProduct?.subsCategory === 0,
                                        )}
                                        onClick={() => onClickProduct(product)}
                                    >
                                        <p>{product.pnusToString}</p>
                                        <p className="price">{convertNumberToKorean(product.term1DiscountedPrice)}원</p>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )}
                <StateLandDesc videoRef={videoRef} />
                {searchParams.get("type") === "7" && (
                    <>
                        <div css={restoreBoxStyle} onClick={() => transferToNative("", "restoreProduct")}>
                            <p>이미 이용권을 구매하셨나요?</p>
                            <p>App Store 구매내역 복원하러 가기</p>
                        </div>
                    </>
                )}
                <div css={submitBoxStyle}>
                    <button className="submitBtn" onClick={onClickSubmit}>
                        결제하기
                    </button>
                </div>
            </div>
            {!!openModal && (
                <ConfirmModal
                    resultMessage={modalContents}
                    onConfirm={() => {
                        if (openModal === 1 || openModal === 3 || openModal === 5) {
                            const params = {
                                productCode: selectedProduct.code,
                                productType: selectedProduct.type,
                                type:
                                    selectedProduct.subsCategory === 3
                                        ? "state_land_subscription"
                                        : selectedProduct.type === "subscription"
                                        ? "subscription"
                                        : "state_land_filter",
                                renewTerm: ["monthly", "yearly"]?.some((val) => val === selectedProduct?.renewTerm),
                            };
                            if (videoRef.current) {
                                videoRef.current.pause();
                            }
                            transferToNative(JSON.stringify(params), "payProductInApp");
                            setOpenModal(0);
                        } else {
                            setModalContents(<div />);
                            setOpenModal(0);
                        }
                    }}
                    onCancel={
                        openModal === 1 || openModal === 3 || openModal === 5
                            ? () => {
                                  setModalContents(<div />);
                                  setOpenModal(0);
                              }
                            : undefined
                    }
                    buttonText={openModal === 1 ? "시작하기" : openModal === 3 || openModal === 5 ? "결제하기" : "확인"}
                />
            )}
            {!!openTooltip && (
                <ConfirmModal
                    resultMessage={tooltipContents}
                    onConfirm={() => {
                        setOpenTooltip(0);
                        setTooltipContents(<div />);
                    }}
                />
            )}
        </>
    );
};

export default StateLandInfo;

const topTitleStyle = css`
    padding: 14px;
    background-color: #282828;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    position: relative;
    text-align: center;
    & > .close {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(-50%, -50%);
    }
`;

const rootBoxStyle = css`
    height: calc(100dvh - 56px);
    height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 100px;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const productTitleStyle = css`
    color: #282828;
    font-size: 16px;
    font-weight: 200;
    line-height: 26px;
    font-family: "S-Core Dream4";
`;

const productNameStyle = css`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    p {
        color: #282828;
        font-family: Sandoll Samliphopangche;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.48px;
    }
    svg {
        width: 20px;
        height: 20px;
    }
`;

const productBoxStyle = (active: boolean) => css`
    background-color: ${active ? "#DFF0FF" : "#ffffff"};
    padding: 24px 14px;
`;

const productTitleBoxStyle = css`
    position: relative;
`;

const descStyle = css`
    & > ul {
        list-style: inside;
        & > li {
            list-style: inside;
            color: #3c3c3c;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            margin-left: 22px;
            margin-bottom: 8px;
            list-style-position: outside;
            & > span {
                font-weight: 700;
                color: #0c002c;
                display: inline-block;
            }
        }
    }
`;

const dialogTitleStyle = css`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

const dialogContentStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 0;
    word-break: break-word;
`;

const regionButtonStyle = (active: boolean, selected: boolean) => css`
    border-radius: 4px;
    background-color: ${active ? "#2275EE" : selected ? "#ffffff" : "#F0F0F0"};
    color: ${active ? "#ffffff" : "#0C002C"};
    box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
    padding: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    & .price {
        font-weight: 700;
    }
`;

const submitBoxStyle = css`
    position: fixed;
    bottom: 0;
    padding: 0 14px 24px;
    width: 100%;
    & > .submitBtn {
        padding: 14px;
        background-color: #2275ee;
        border-radius: 14px;
        box-shadow: 2px 2px 6px 0px rgba(4, 0, 14, 0.14);
        width: 100%;
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
`;

const eventFloatBoxStyle = css`
    position: absolute;
    top: -13px;
    right: 0px;
    color: #ffffff;
    text-align: center;
    font-size: 13px;
    letter-spacing: -0.26px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 120px;
    background-color: #ff0000;
    height: 70px;
    width: 70px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.24) inset, 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    & > .percentText {
        font-size: 11px;
        letter-spacing: -0.44px;
    }
`;

const tooltipBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #0c002c;
    & > .title {
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
    & > .content {
        text-align: center;
        color: #0c002c;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    li {
        list-style: inside;
        margin: 0;
        padding: 0;
    }
    li::marker {
        content: "∙";
        font-size: 20px;
        margin: 0;
    }
`;

const restoreBoxStyle = css`
    margin: 14px;
    padding: 14px;
    background-color: #eefff5;
    color: #0c002c;
    text-align: center;
    font-weight: 400;
    p:first-of-type {
        font-size: 18px;
        line-height: 28px;
    }
    p:last-of-type {
        margin-top: 4px;
        font-size: 13px;
        line-height: 20px;
    }
`;
