import React from "react";
import { css } from "@emotion/react";

export default function CouponRegistrationCompleted() {
    return <p css={Message}>{"사용 중이던 서비스로 이동하여 주세요."}</p>;
}

const Message = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
`;
