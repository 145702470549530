import React from "react";

export const test2Columns = () => {
    return [
        {
            accessor: "a",
            Header: () => <div style={{ width: "32px", textAlign: "center" }}>주/부</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "32px", textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "b",
            Header: () => <div style={{ width: "158px", textAlign: "center" }}>건축물</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "158px", textAlign: "center" }}>{value}</div>,
        },
        {
            accessor: "c",
            Header: () => <div style={{ width: "98px", textAlign: "center" }}>용도</div>,
            Cell: ({ cell: { value } }: any) => <div style={{ width: "98px", textAlign: "center" }}>{value}</div>,
        },
    ];
};
