import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { getDeviceType, transferToNative } from "@src/lib/WebAppBridge";
import backArrow from "@src/assets/ico_arrow_back.png";
import Title from "@src/components/title/Title";
import GoStopFilter from "./goStopFilter/GoStopFilter";
import { useGoStopFilterStore } from "@src/store/GoStopFilterStore";
import { useGoStopList, useSaverGoStopFilter, useGetGoStopFilters, useGoStopSubs } from "@src/hooks/goStop/useGoStop";
import useInfiniteScroll from "react-infinite-scroll-hook";
import GoStopListCard from "./GoStopListCard";
import { IGoStopResListDto, ICursorType } from "@src/hooks/goStop/dto/goStop.dto";
import { useNavigate } from "react-router-dom";
import { initialFilterParams } from "./goStopFilter/GoStopFilter";
import Lottie from "lottie-react";
import loading from "@src/assets/Loading.json";
import GoStopCheckModal from "./GoStopCheckModal";
import GoStopConfirmModal from "./GoStopConfirmModal";

import NextArrow from "@src/assets/goStop/nextArrow.png";
import PrevArrow from "@src/assets/goStop/prevArrow.png";
import DownArrow from "@src/assets/goStop/downArrow.png";
import GreyArrow from "@src/assets/goStop/greyArrow.png";
import LockIcon from "@src/assets/goStop/lockIcon.png";
import NoMoreImg from "@src/assets/goStop/noMore.png";
import closeBtn from "@src/assets/ico_close.png";
import GoStopTutorial from "./goStopTutorial";

const GoStopList = () => {
    const navigate = useNavigate();
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [clickedGoStopFilter, setClickedGoStopFilter] = useState<boolean>(false);
    const filterParams = useGoStopFilterStore((state) => state.goStopFilterState);
    const setFilterParams = useGoStopFilterStore((state) => state.setStoreGoStopFilterState);
    const [selectedOption, setSelectedOption] = useState<number>(16);
    const [checkedFilterSubsUserModal, setCheckedFilterSubsUserModal] = useState<boolean>(false);
    const [checkedSubsUserModal, setCheckedSubsUserModal] = useState<boolean>(false);

    const {
        data: getInfoFilterData,
        refetch: filterRefetch,
        isFetching: isFetchingFilterData,
    } = useGetGoStopFilters("gostopFilter");

    const saverGostopFilter = useSaverGoStopFilter();

    const onSubmitSaveGoStopFilter = () => {
        if (JSON.stringify(getInfoFilterData) !== JSON.stringify(filterParams)) {
            saverGostopFilter.mutate(filterParams);
        }
    };

    useEffect(() => {
        filterRefetch();
    }, []);

    useEffect(() => {
        if (getInfoFilterData?.type !== undefined) {
            setFilterParams({
                ...filterParams,
                ...getInfoFilterData,
            });
        }
    }, [getInfoFilterData]);

    const [cursor, setCursor] = useState<ICursorType>({
        sortType: 16,
        cursorValue: null,
        cursorId: null,
        updatedAt: null,
    });
    const goStopType = ["전체", "GO", "주의", "STOP"];
    const [selectedGoStopType, setSelectedGoStopType] = useState<string>("전체");
    const [tutorial, setTutorial] = useState(false);
    const [tutorialEnable, setTutorialEnable] = useState(false);

    const handleNextButtonClick = () => {
        const currentIndex = goStopType.indexOf(selectedGoStopType);
        const nextIndex = (currentIndex + 1) % goStopType.length;
        setSelectedGoStopType(goStopType[nextIndex]);
    };

    const handlePrevButtonClick = () => {
        const currentIndex = goStopType.indexOf(selectedGoStopType);
        const previousIndex = (currentIndex - 1 + goStopType.length) % goStopType.length;
        setSelectedGoStopType(goStopType[previousIndex]);
    };

    const sortTypeOptions = [
        { id: 15, label: "시세차익 높은순" },
        { id: 16, label: "시세차익 낮은순" },
        { id: 5, label: "매각기일 가까운순" },
        { id: 0, label: "업데이트 최신순" },
        { id: 1, label: "감정가 높은순" },
        { id: 2, label: "감정가 낮은순" },
        { id: 3, label: "최저가 낮은순" },
        { id: 4, label: "최저가 높은순" },
        { id: 7, label: "유찰횟수 많은순" },
        { id: 8, label: "유찰횟수 낮은순" },
    ];

    const filterParam = {
        cursor,
        filter: filterParams,
        limit: 20,
        gostopType: selectedGoStopType === "전체" ? [] : [selectedGoStopType],
    };

    const { data: subsInfo } = useGoStopSubs();
    const { data, refetch, hasNextPage, fetchNextPage, isFetching } = useGoStopList(filterParam, true);

    const [goStopRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 300px 0px",
        delayInMs: 100,
    });

    useEffect(() => {
        !isFetchingFilterData && refetch();
        if (getInfoFilterData?.type !== undefined) {
            onSubmitSaveGoStopFilter();
        }
    }, [cursor, filterParams]);

    useEffect(() => {
        if (subsInfo) {
            if (localStorage.getItem("tutorialDisable") === null && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지'))) {
                setTutorial(true);
            } else {
                return;
            }
        }
    }, [subsInfo])

    return (
        <>
            {tutorial &&
                <GoStopTutorial
                    tutorialEnable={tutorialEnable}
                    setTutorial={(val: boolean) => setTutorial(val)}
                    setTutorialEnable={(val: any) => setTutorialEnable(val)} />
            }
            <div css={goStopWrap}>
                <div className="titleWrap">
                    <div className="BtnWrap">
                        <button
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <img className="backBtn" src={backArrow} alt="뒤로가기" />
                        </button>
                        <button
                            onClick={() => {
                                transferToNative("done", "closeWebView");
                            }}
                        >
                            <img className="closeBtn" src={closeBtn} alt="닫기" />
                        </button>
                    </div>
                    <Title title="주거/토지 Go-Stop" size="large" />
                </div>
                <div className="typeWrap">
                    <p>{selectedGoStopType}</p>
                    <div className="typeBtnWrap">
                        <button onClick={handlePrevButtonClick}>
                            <img src={PrevArrow} alt="PrevArrow" />
                        </button>
                        <button onClick={handleNextButtonClick}>
                            <img src={NextArrow} alt="NextArrow" />
                        </button>
                    </div>
                </div>
                {/*상단필터*/}
                <div css={filterLock}>
                    {subsInfo && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) && (
                        <div
                            className="filterWrap"
                            onClick={() => {
                                setCheckedFilterSubsUserModal(!checkedFilterSubsUserModal);
                            }}
                        >
                            <img src={LockIcon} alt="lock" />
                        </div>
                    )}
                    {subsInfo && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) &&
                        <div className="filterBox">
                            <p>주거/토지 수익 필터 구매 시, <br />시세차익을 확인할 수 있어요</p>
                        </div>
                    }
                    <GoStopFilter
                        subs={subsInfo?.list.some((data: any) => data.productName.includes('주거/토지'))}
                        setClickedGoStopFilter={setClickedGoStopFilter}
                        onSubmitSaveGoStopFilter={onSubmitSaveGoStopFilter}
                        getInfoFilterData={getInfoFilterData}
                    />
                </div>
                <div className="line" />
                {/*정렬필터*/}
                <div css={sortLock}>
                    <div className="contentsWrap">
                        <div className="filterWrap">
                            <p className="totalCntWrap">총 매물 {data?.pages?.[0]?.totalCnt?.toLocaleString()}건</p>
                            <div className="dropdownFilter">
                                <div className="dropdownWrap">
                                    <div
                                        className={!subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) ? "selectedLockOption" : "selectedOption"}
                                        onClick={() => {
                                            subsInfo && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) ? setFilterVisible(false) : setFilterVisible(true);
                                        }}
                                    >
                                        {subsInfo && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) && (
                                            <div
                                                className="sortWrap"
                                                onClick={() => {
                                                    setCheckedFilterSubsUserModal(true);
                                                }}
                                            >
                                                <img src={LockIcon} alt="lock" />
                                            </div>
                                        )}
                                        {sortTypeOptions.find((option) => option.id === selectedOption)?.label}
                                        {subsInfo && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) ? (
                                            <img src={GreyArrow} alt="greyArrow" />
                                        ) : (
                                            <img src={DownArrow} alt="DownArrow" />
                                        )}
                                    </div>
                                    {filterVisible && (
                                        <div className="options">
                                            <div className="sortTypeWrap">
                                                <span>시세차익</span>
                                                <div className="btnContainer">
                                                    <button
                                                        className={selectedOption === 15 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(15);
                                                            setCursor({ ...cursor, sortType: 15 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        높은순
                                                    </button>
                                                    <button
                                                        className={selectedOption === 16 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(16);
                                                            setCursor({ ...cursor, sortType: 16 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        낮은순
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="sortTypeWrap">
                                                <span>매각기일</span>
                                                <div className="btnContainer">
                                                    <button
                                                        className={selectedOption === 5 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(5);
                                                            setCursor({ ...cursor, sortType: 5 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        가까운순
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="sortTypeWrap">
                                                <span>업데이트</span>
                                                <div className="btnContainer">
                                                    <button
                                                        className={selectedOption === 0 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(0);
                                                            setCursor({ ...cursor, sortType: 0 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        최신순
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="sortTypeWrap">
                                                <span>감정가</span>
                                                <div className="btnContainer">
                                                    <button
                                                        className={selectedOption === 1 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(1);
                                                            setCursor({ ...cursor, sortType: 1 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        높은순
                                                    </button>
                                                    <button
                                                        className={selectedOption === 2 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(2);
                                                            setCursor({ ...cursor, sortType: 2 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        낮은순
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="sortTypeWrap">
                                                <span>최저가</span>
                                                <div className="btnContainer">
                                                    <button
                                                        className={selectedOption === 4 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(4);
                                                            setCursor({ ...cursor, sortType: 3 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        높은순
                                                    </button>
                                                    <button
                                                        className={selectedOption === 3 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(3);
                                                            setCursor({ ...cursor, sortType: 4 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        낮은순
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="sortTypeWrap">
                                                <span>유찰횟수</span>
                                                <div className="btnContainer">
                                                    <button
                                                        className={selectedOption === 7 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(7);
                                                            setCursor({ ...cursor, sortType: 7 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        많은순
                                                    </button>
                                                    <button
                                                        className={selectedOption === 8 ? "dropdownClickedItem" : "dropdownItem"}
                                                        onClick={() => {
                                                            setSelectedOption(8);
                                                            setCursor({ ...cursor, sortType: 8 });
                                                            setFilterVisible(!filterVisible);
                                                        }}
                                                    >
                                                        낮은순
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*고스톱카드*/}
                {data?.pages?.[0]?.totalCnt === 0 ? (
                    <div css={noMoreWrap}>
                        <img src={NoMoreImg} alt="no more" />
                        <p>일치하는 매물이 없습니다.</p>
                    </div>
                ) : (
                    <>
                        {isFetching && (
                            <StyledVimeoLoading>
                                <div>
                                    <Lottie animationData={loading} />
                                </div>
                            </StyledVimeoLoading>
                        )}
                        <div className="cardWrap">
                            {data?.pages?.map((page, idx) => (
                                <div key={idx}>
                                    {page?.list?.map((item: IGoStopResListDto, itemIdx: number) => (
                                        <div
                                            key={String(itemIdx)}
                                            onClick={() => {
                                                transferToNative(JSON.stringify(item), "onClickItem");
                                            }}
                                        >
                                            <GoStopListCard item={item} goStopType={filterParam?.gostopType} />
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className="observer" ref={goStopRef} />
                        </div>
                    </>
                )}

                {/* <div
                className={filterVisible ? "dim" : ""}
                onClick={() => {
                    if (filterVisible) {
                        setFilterVisible(!filterVisible);
                    }
                    if (checkedFreeModal) {
                        setCheckedFreeModal(!checkedFreeModal);
                    }
                }}
            /> */}
                {subsInfo && !clickedGoStopFilter && !subsInfo?.list.some((data: any) => data.productName.includes('주거/토지')) && (
                    <div className="buttonDiv">
                        <button
                            onClick={() => {
                                setCheckedFilterSubsUserModal(!checkedFilterSubsUserModal)
                            }}
                        >
                            프리미엄 필터 서비스 신청하기
                        </button>
                    </div>
                )}
                {checkedFilterSubsUserModal && (
                    <GoStopConfirmModal
                        title={` 주거/토지 수익 필터\n서비스 상품 구매 회원에게만 제공되는\n기능입니다.`}
                        desc={`지금 상품을 구매하고 시세차익 높은 매물을\n확인해 보세요!`}
                        onClickCancel={() => {
                            setCheckedFilterSubsUserModal(!checkedFilterSubsUserModal);
                        }}
                        onClickConfirm={() => {
                            if (getDeviceType() === "android") {
                                navigate("/subscriptionInfo?type=6&order=premiumFilter")
                            }

                            if (getDeviceType() === "ios") {
                                navigate("/ios-subscriptionInfo?type=7&order=premiumFilter")
                            }
                            setCheckedFilterSubsUserModal(!checkedFilterSubsUserModal);
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default GoStopList;

const goStopWrap = css`
    .cardWrap {
        height: calc(var(--vh, 1vh) * 100 - 246px);
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .buttonDiv {
        position: fixed;
        bottom: 28px;
        left: 14px;
        width: 100%;
        height: 56px;
        z-index: 99;
        button {
            width: calc(100% - 28px);
            height: 56px;
            border-radius: 14px;
            padding: 14px;
            background-color: #0047FF;
            color: white;
            display: flex;
            white-space: pre-wrap;
            align-items: center;
            justify-content: center;
            box-shadow: 2px 2px 6px rgba(4, 0, 14, 0.14);
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            border: none;
            outline: none;
            font-family: "GmarketSansTTF", sans-serif;
        }
    }
    .titleWrap {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        padding: 14px;
        align-items: center;
        .BtnWrap {
            position: absolute;
            left: 0;
            width: 10px;
            height: 17px;
            display: flex;
            width: 100%;
            padding: 0 14px;
            justify-content: space-between;
            .closeBtn {
                width: 14px;
                height: 14px;
            }
            .backBtn {
                width: 10px;
                height: 16px;
            }
        }
        .title {
            font-weight: 700;
        }
    }

    .dim {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        z-index: 99;
        background: rgba(0, 0, 0, 0.7);
    }
    .typeWrap {
        display: flex;
        position: relative;
        height: 56px;
        border-radius: 14px;
        background: #f0f0f0;
        margin: 14px 14px 0 14px;
        justify-content: center;
        align-items: center;
        color: #0047ff;
        text-align: center;
        font-family: Gmarket Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
    }
    .typeBtnWrap {
        position: absolute;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 14px;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .line {
        height: 6px;
        background-color: #f7f8fa;
    }
    .contentsWrap {
        width: 100%;
        padding: 14px;
    }
    .filterWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .lottie {
        width: 60px;
        margin: 150px auto;
    }
    .dropdownFilter {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        overflow: hidden;
    }
    .dropdownWrap {
        height: 30px;
    }

    .selectedOption {
        height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        img {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }
    .selectedLockOption {
        height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        color: #8d8d8d;
        font-weight: 500;
        img {
            width: 16px;
            height: 16px;
            margin-left: 4px;
        }
    }
    .options {
        position: fixed !important;
        z-index: 100;
        width: 100vw;
        left: 0;
        padding: 24px 24px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 14px 14px 0px 0px;
        animation: appearBottomSheet 0.4s linear forwards;
        @keyframes appearBottomSheet {
            from {
                opacity: 0;
                height: 0;
                bottom: -50%;
            }

            to {
                opacity: 1;
                height: fit-content;
                bottom: 0;
            }
        }
    }
    .sortTypeWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        span {
            width: 74px;
            text-align: center;
            color: #0c002c;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
            margin-right: 10px;
        }
    }
    .btnContainer {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
        .dropdownClickedItem {
            background: #0047ff;
            color: #fff;
            font-weight: 800;
            width: 100%;
            height: 46px;
            border-radius: 8px;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }
        .dropdownItem {
            width: 100%;
            height: 46px;
            border-radius: 8px;
            background: #fbf8f8;
            color: #0c002c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
        }
    }
    .totalCntWrap {
        margin-top: 3px;
    }
    .observer {
        height: 30px;
        width: 100%;
    }
`;

const filterLock = css`
    position: relative;

    .filterBox {
        position: absolute;
        z-index: 11;
        top: 50px;
        width: fit-content;
        & > p {
            position: relative;
            font-size: 14px;
            background-color: #fff500;
            color: #0c002c;
            width: fit-content;
            padding: 8px;
            border-radius: 4px;
            margin-left: 30px;
            top: -10px;
            line-height: 20px;
        }

        & > p::after {
            content: '';
            position: absolute;
            top: -12px;
            left: 10%;
            margin-left: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent #fff500 transparent;
        }
    }
    .filterWrap {
        position: absolute;
        top: 10px;
        width: 100%;
        height: 40px;
        background: rgba(0, 0, 0, 0.2);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            display: flex;
            justify-content: center;
            align-items: center;
            /* position: absolute;
            top: 10px; */
            width: 19.98px;
            height: 24px;
        }
    }
`;

const sortLock = css`
    position: relative;
    .sortWrap {
        position: absolute;
        top: 0;
        width: 300px;
        height: 30px;
        border-radius: 24px;
        background: rgba(0, 0, 0, 0.2);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 19.98px;
            height: 24px;
        }
    }
`;

const noMoreWrap = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #0c002c;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 124px;
    img {
        width: 60px;
        height: 60px;
        margin-bottom: 14px;
    }
`;

const StyledVimeoLoading = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 500px);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;
    position: absolute;
    & > div {
        width: 106px;
        height: 63px;
        background-color: white;
        & > div {
            width: 100%;
            height: 100%;
        }
    }
`;
