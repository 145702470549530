import { keyframes } from "@emotion/react";

export const appearAnimation = keyframes`
  from {
    opacity: 0;    
  }

  to {
    opacity: 1;
  }
`;

export const appearAnimation2 = keyframes`
  from {
    background: rgba(255, 255, 255, 255);
  }

  to {
    /* opacity: 0; */
    background: rgba(255, 255, 255, 0);
  }
`;

export const BottomToUp = keyframes`
  0% {
    opacity: 0;
    /* bottom: 20px; */
    /* bottom: 30px; */
  }

  20% {
    opacity: 1;
    /* bottom: 200px; */
  }

  80% {
    opacity: 1;
    /* bottom: 200px; */
  }

  100% {
    /* display: none; */
    display: none;
    opacity: 0;
  }
`;
