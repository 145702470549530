import { css } from "@emotion/react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import closeBtn from "@src/assets/ico_close.png";
import resetIcon from "@src/assets/storeGoStop/reset.svg";
import calIcon from "@src/assets/storeGoStop/cal.svg";
import StoreGoStoopCalInput from "./StoreGoStopCalInput";
import { useSearchParams } from "react-router-dom";
import { useGoStopAuctionDetail } from "@src/hooks/storeGoStop/useStoreGoStop";
import { useStoreGoStoPDetailStore } from "@src/store/storeGoStopDetailInfo";
import convertNumberToKorean from "@src/util/convertNumToKorean";
import InfoRounded from "@mui/icons-material/InfoRounded";
import { transferToNative } from "@src/lib/WebAppBridge";
import { useAuctionDetail } from "@src/hooks/goStop/useGoStop";
import ConfirmModal from "@src/components/popup/ConfirmModal";

const StoreGoStopCalculate = () => {

    const [searchParams] = useSearchParams();

    const detailInfo = useStoreGoStoPDetailStore((state) => state.storeGoStopDetailInfo);
    const setDetailInfo = useStoreGoStoPDetailStore((state) => state.setStoreGoStopDetailIfo);

    const { data: goStopDetail, refetch: refetchGoStopDetail } = useGoStopAuctionDetail(
        searchParams.get('itemId') ?? '',
    );

    const {
        data: auctionDetail,
        refetch,
    } = useAuctionDetail(searchParams.get('itemId') ?? '');

    const bottomRef = useRef<any>(null);
    const infoRef = useRef<any>(null);

    useEffect(() => {
        if (searchParams.get("itemId")) {
            refetchGoStopDetail();
            refetch();
        }
    }, [searchParams.get("itemId")])

    useEffect(() => {
        if (window) {
            let prevVisualViewport = window.visualViewport?.height;
            const handleVisualViewportResize = () => {
                const currentVisualViewport = Number(window.visualViewport?.height);
                if (
                    prevVisualViewport &&
                    prevVisualViewport - 30 > currentVisualViewport
                ) {
                    const scrollHeight = Number(
                        window.document.scrollingElement?.scrollHeight
                    );
                    const scrollTop =
                        scrollHeight - Number(window.visualViewport?.height);
                    window.scrollTo(0, scrollTop); // 입력창이 키보드에 가려지지 않도록 조절
                }
                prevVisualViewport = Number(window.visualViewport?.height);
            };

            if (window.visualViewport) {
                window.visualViewport.onresize = handleVisualViewportResize;
            }
        }
    }, []);
    // auctionDetail?.payload?.result?.section_initial

    useEffect(() => {
        if (goStopDetail?.storeGostop) {
            setDetailInfo({
                deposit: goStopDetail?.storeGostop?.rent_conversion_deposit / 10000 ?? 0,
                origin_deposit: goStopDetail?.storeGostop?.rent_conversion_deposit / 10000 ?? 0,
                sale_price:
                    Number(auctionDetail?.payload?.result?.section_initial?.min_price?.replaceAll('원', '').replaceAll(',', '')) / 10000 ?? 0,
                origin_sale_price:
                    Number(auctionDetail?.payload?.result?.section_initial?.min_price?.replaceAll('원', '').replaceAll(',', '')) / 10000 ?? 0,
                monthly: goStopDetail?.storeGostop?.rental_month / 10000 ?? 0,
                origin_monthly: goStopDetail?.storeGostop?.rental_month / 10000 ?? 0,
                profit_rate: goStopDetail?.storeGostop?.store_profit_rate ?? 0,
                origin_rental_pyong: goStopDetail?.storeGostop?.rental_pyong / 10000 ?? 0,
                rental_pyong: goStopDetail?.storeGostop?.rental_pyong / 10000 ?? 0,
                pyong: Number(auctionDetail?.payload?.result?.section_initial?.buid_area?.split(" ")?.[1]?.replace("평", "")?.replaceAll("(", "")?.replaceAll(")", "")) ?? 0
            })
        }
    }, [goStopDetail, auctionDetail])

    const [showCal, setShowCal] = useState(false);
    const [keyUp, setKeyUp] = useState(false);

    const numberRegx = /[^0-9]/;

    const [openDialog, setOpenDialog] = useState(0);

    const investmentResult =
        Number(detailInfo.origin_sale_price) - Number(detailInfo.origin_deposit);

    const topRef = useRef<any>(null)
    const inputRef = useRef<any>(null);

    const calStart = () => {
        topRef.current.scrollIntoView({
            block: "start",
            behavior: "smooth"
        })
        setDetailInfo({
            ...detailInfo,
            origin_deposit: detailInfo.deposit,
            origin_monthly: detailInfo.monthly,
            origin_sale_price: detailInfo.sale_price,
            origin_rental_pyong: (detailInfo.monthly / Math.floor(detailInfo.pyong)) / 10000
        });
    };

    const resetData = () => {

        setDetailInfo({
            ...detailInfo,
            deposit: goStopDetail?.storeGostop?.rent_conversion_deposit ?? 0,
            origin_deposit: goStopDetail?.storeGostop?.rent_conversion_deposit ?? 0,
            sale_price:
                Number(auctionDetail?.payload?.result?.section_initial?.min_price?.replaceAll('원', '').replaceAll(',', '')) ?? 0,
            origin_sale_price:
                Number(auctionDetail?.payload?.result?.section_initial?.min_price?.replaceAll('원', '').replaceAll(',', '')) ?? 0,
            monthly: goStopDetail?.storeGostop?.rental_month ?? 0,
            origin_monthly: goStopDetail?.storeGostop?.rental_month ?? 0,
            origin_rental_pyong: goStopDetail?.storeGostop?.rental_pyong ?? 0,
        });
    };

    useEffect(() => {
        if (showCal) {
            bottomRef.current.scrollIntoView({
                block: "start",
                behavior: "smooth"
            })
        }
    }, [showCal]);



    useEffect(() => {
        if (keyUp) {
            bottomRef.current.scrollIntoView({
                block: "start",
                behavior: "smooth"
            })
        }
    }, [keyUp])


    return (
        <>
            <div css={rootBoxStyle(keyUp)}>
                <div className="topBox">
                    <div ref={topRef} css={titleBox}>
                        <p>상가 Go-Stop</p>
                        <img onClick={() => { transferToNative("done", "closeWebView"); }} className="closeBtn" src={closeBtn} alt="닫기" />
                    </div>
                    <div css={slogan}>
                        <p>투자 예상수익률&nbsp;<span>{investmentResult == 0 ? "0" : (Number(detailInfo.origin_monthly) * 12 / investmentResult * 100).toFixed(2)}%</span>&nbsp; 예상돼요!</p>
                    </div>
                    <div css={resultBox}>
                        <p className="title">투자금 계산</p>
                        <div className="price">
                            <p className="priceTitle">매매가</p>
                            <p className="priceDesc">
                                {convertNumberToKorean(Number(detailInfo.origin_sale_price) * 10000 - Number(detailInfo.origin_sale_price) * 10000 % 10000)}
                                원
                            </p>
                        </div>
                        <div className="price">
                            <p className="priceTitle">임대 보증금
                                <div onClick={() => setOpenDialog(1)}>
                                    <InfoRounded sx={{ color: "#B1AEB8" }} />
                                </div>
                            </p>
                            <p className="priceDesc">
                                -{' '}
                                {convertNumberToKorean(Number(detailInfo.origin_deposit) * 10000 - Number(detailInfo.origin_deposit) * 10000 % 10000)}
                                원</p>
                        </div>
                        <div className="borderLine"></div>
                        <div className="price">
                            <p className="priceTitle">투자금</p>
                            <p className="priceResult">{convertNumberToKorean(investmentResult * 10000 - investmentResult * 10000 % 10000)}</p>
                        </div>
                    </div>
                    <div css={resultBox}>
                        <p className="title">연수익 계산</p>
                        <div className="price">
                            <p className="tooltip">인근지역의 임차데이터에서 가져온</p>
                            <p className="priceTitle">예상 월 임대료
                                <div onClick={() => setOpenDialog(2)}>
                                    <InfoRounded sx={{ color: "#B1AEB8" }} />
                                </div>
                            </p>
                            <p className="descMulti">
                                {convertNumberToKorean(Number(detailInfo.origin_monthly) * 10000 - Number(detailInfo.origin_monthly) * 10000 % 10000)}
                                원
                                <span>({convertNumberToKorean(detailInfo.origin_rental_pyong * 10000)}원/평당)</span>
                            </p>
                        </div>
                        <div className="priceSingle">
                            <p>X 12개월</p>
                        </div>
                        <div className="borderLine"></div>
                        <div className="price">
                            <p className="priceTitle">연수익</p>
                            <p className="priceResult">
                                {
                                    convertNumberToKorean(Number(detailInfo.origin_monthly) * 10000 * 12 - Number(detailInfo.origin_monthly) * 10000 * 12 % 10000)
                                }
                                원</p>
                        </div>
                    </div>
                    <p css={selfCalSlogan}>내가 가진 돈으로 <span>투자 시<br />얼마나 수익을 얻을 수 있을까요?</span></p>
                    <p
                        css={selfCalBtn}
                        onClick={() => {
                            setShowCal(prev => !prev);
                        }}>
                        직접 계산하기<KeyboardArrowUpIcon style={{ rotate: showCal ? "0deg" : "180deg" }} />
                    </p>
                    <div ref={inputRef} css={selfCalSection(showCal)}>
                        <StoreGoStoopCalInput
                            onKeyUp={(val: boolean) => setKeyUp(val)}
                            label="매매가"
                            value={detailInfo?.sale_price === 0 ? '' : Math.floor(detailInfo?.sale_price)}
                            onChange={(e) => {
                                if (Number(e.target.value) > 100000000 || numberRegx.test(e.target.value)) {
                                    return;
                                }
                                setDetailInfo({
                                    ...detailInfo,
                                    sale_price: e.target.value,
                                });
                            }}
                        />
                        <StoreGoStoopCalInput
                            onKeyUp={(val: boolean) => setKeyUp(val)}
                            label="임대 보증금"
                            value={detailInfo?.deposit === 0 ? '' : Math.floor(detailInfo?.deposit)}
                            onChange={(e) => {
                                if (Number(e.target.value) > 100000000 || numberRegx.test(e.target.value)) {
                                    return;
                                }
                                setDetailInfo({
                                    ...detailInfo,
                                    deposit: e.target.value,
                                });
                            }}
                        />
                        <StoreGoStoopCalInput
                            onKeyUp={(val: boolean) => setKeyUp(val)}
                            label="월 임대료"
                            value={detailInfo?.monthly === 0 ? '' : Math.floor(detailInfo?.monthly)}
                            onChange={(e) => {
                                if (Number(e.target.value) > 100000000 || numberRegx.test(e.target.value)) {
                                    return;
                                }
                                setDetailInfo({
                                    ...detailInfo,
                                    monthly: e.target.value,
                                });
                            }}
                        />
                        <div css={buttonBox}>
                            <button onClick={resetData}>
                                <img src={resetIcon} alt="" />
                                초기화
                            </button>
                            <button ref={bottomRef} onClick={calStart}>
                                <img src={calIcon} alt="" />
                                계산하기
                            </button>
                        </div>
                    </div>
                </div>
                <div css={bottomInfo(document.body.scrollHeight - 746)}>본 정보는 대법원 경매정보 및 감정평가서와 기타 공부 상의 내용을 기초로 제공됩니다. 내용의 변경 및 입력 착오로 사실과 다를 수 있으며, 면책 조건으로 정보를 제공합니다. 제공된 정보는 사전 동의 없이 무단수집하거나, 무단 전재 및 재배포 할 수 없습니다.</div>
            </div >
            {
                openDialog !== 0 && <ConfirmModal
                    onConfirm={() => {
                        setOpenDialog(0)
                    }}
                    buttonText="확인"
                    resultMessage={
                        <>
                            {
                                openDialog === 1 && <div css={contentBoxStyle}>
                                    <p className="title">
                                        <b>임대 보증금</b>이란?
                                    </p>
                                    <p className="content">임대차 환산보증금 계산을 통해 산출된 <br />결과값으로 실제 임대 보증금의 값과 <br />다를 수 있습니다.</p>
                                </div>
                            }
                            {
                                openDialog === 2 && <div css={contentBoxStyle}>
                                    <p className="title">
                                        <b>예상 월 임대료</b>란?
                                    </p>
                                    <p className="content">인근지역의 임차데이터를 바탕으로 평당 <br />임차료를 계산하여 나온 미스고 자체<br />데이터입니다.</p>
                                </div>
                            }
                        </>
                    }
                />
            }
        </>
    )
}

export default StoreGoStopCalculate;

const contentBoxStyle = css`
    .title {
        line-height: 24px;
    }    
    .content {
        line-height: 24px;
        margin-top: 10px;
    }
`

const buttonBox = css`
    display: flex;
    width: 100%;
    gap: 8px;
    & > button {
        width: 50%;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        gap: 10px;
        line-height: 24px;
        padding: 14px 0px;
        & > img {
            width: 20px;
            height: 20px;
        }
    }

    & > button:nth-of-type(1) {
        color: #505050;
        background-color: #F0F0F0;
    }

    & > button:nth-of-type(2) {
        color: #fff;
        background-color: #0047FF;
    }
`

const selfCalSection = (hidden: boolean) => css`
    display: ${hidden ? "flex" : "none"};
    margin-top: 16px;
    gap: 10px;
    flex-direction: column;
`

const rootBoxStyle = (keyUp: boolean) => css`
    width: 100vw;
    height: 100vh;
    padding: 0px 14px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;

    .topBox {
        padding-bottom: ${keyUp ? "232px" : "0px"};
    }
`

const bottomInfo = (height: number) => css`
    padding: 14px;
    width: 100vw;
    background-color: #FBF8F8;
    color: #A3A3A3;
    line-height: 20px;
    transform: translateX(-14px);
    font-size: 12px;
    margin-top: 24px;
`

const selfCalBtn = css`
    text-align: center;
    margin-top: 8px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: #0047FF;
    align-items: center;

`

const selfCalSlogan = css`
    text-align: center;
    margin-top: 8px;
    line-height: 22px;
    & > span {
        font-weight: bold;
    }
`

const resultBox = css`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #DADADA;
    padding: 14px;
    gap: 16px;

    .borderLine {
        width: 100%;
        border-bottom: 1px solid #0c002c;
        padding-top: 12px;
        margin-bottom: 4px;
    }

    .title {
        font-size: 18px;
        color: #2275EE;
        font-weight: bold;
        margin-bottom: 24px;
    }

    .price {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        color: #0C002C;
        
        .tooltip {
            color: #0c002c;
            background-color: #FFF500;
            border-radius: 4px;
            font-size: 12px;
            padding: 0px 4px;
            line-height: 20px;
            position: absolute;
            top: -24px;
        }

        .tooltip::after{
            content: "";
            position: absolute;
            top: 100%;
            left: 7%;
            margin-left: -5px;
            border-width: 6px;
            border-style: solid;
            border-color: #fff500 transparent transparent transparent;
        }

        .priceTitle {
            display: flex;
            align-items: center;
        }

        .priceDesc {
            font-weight: bold;
        }

        .priceResult {
            font-weight: bold;
            color: #ff0000;
        }

        .descMulti {
            font-weight: bold;
            display: flex;
            flex-direction: column;
            gap: 3px;
            align-items: flex-end;
            & > span {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
    .priceSingle {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
        color: #0C002C;
    }
`

const titleBox = css`
    display: flex;
    color: #0c002c;
    width: 100vw;
    justify-content: center;
    font-weight: 700;
    padding: 16px 0px;
    position: fixed;
    background-color: white;
    z-index: 100;
    transform: translateX(-14px);
    & > img{
        width: 14px;
        height: 14px;
        right: 14px;
        position: fixed;
        z-index: 10;
    }
`

const slogan = css`
    font-size: 16px;
    margin-top: 58px;
    font-weight: bold;
    width: 100%;

    & > p {
        background-color: #FAFF0033;
        width: 100%;
        height: 36px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items:center;
    }

    & > p > span {
        color: #ff0000;
        font-size: 20px;
    }
`