import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";
import { IRegion, useFindLocation } from "@src/hooks/newFilter/useGetLocation";
import StateLandFilterHeader from "./StateLandFilterHeader";
import SelectedLocationValue from "./SelectedLocationValue";
import { IUserStateLandSubs } from "@src/hooks/stateLand/useGetStateLandSubsInfo";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { tr } from "date-fns/locale";

interface Props {
    onSubmitShowToast: () => void;
    onSubmitShowRegionModal: () => void;
    stateLandSubs?: IUserStateLandSubs;
}

const StateLandLocationFilter: React.FC<Props> = ({ onSubmitShowToast, stateLandSubs, onSubmitShowRegionModal }) => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);
    const [sidoListCount, setSidoListCount] = useState<number>(0);

    const [sidoList, setSidoList] = useState<any>();
    const [sigunguList, setSigunguList] = useState<any>();
    const [emdList, setEmdList] = useState<any>();
    const [selectedSido, setSelectedSido] = useState<any>();
    const [selectedSigungu, setSelectedSigungu] = useState<any>();
    const [showLocText, setShowLocText] = useState<any[]>([]);
    const sidoRef = useRef<any>();
    const makeLocText = async (location: string) => {
        let sidoText = "";
        let sigunguText = "";
        let emdText = "";
        sidoText = await useFindLocation("0000000000").then((res) => {
            const selectedSido = res.filter((val: any) => val?.cortarNo?.slice(0, 2) === location.slice(0, 2));
            return selectedSido?.[0]?.cortarName ?? "";
        });
        if (location.slice(2) !== "00000000") {
            sigunguText = await useFindLocation(`${location.slice(0, 2)}00000000`).then((res) => {
                const selectedSigungu = res.filter((val: any) => val?.cortarNo?.slice(0, 5) === location.slice(0, 5));
                return selectedSigungu?.[0]?.cortarName;
            });
        } else {
            return { name: `${sidoText} > 전체`, value: location };
        }
        if (location.slice(5) !== "00000") {
            emdText = await useFindLocation(`${location.slice(0, 5)}00000`).then((res) => {
                const selectedEmd = res.filter((val: any) => val?.cortarNo === location);
                return selectedEmd?.[0]?.cortarName;
            });
        } else {
            emdText = "전체";
        }
        return {
            name: `${sidoText} > ${sigunguText} > ${emdText}`,
            value: location,
        };
    };
    const settingLocText = async () => {
        const result = await Promise.all(
            stateLandFilter?.location?.map(async (val) => {
                return await makeLocText(val);
            }),
        );
        setShowLocText(result);
    };

    //* 시도 전체 선택
    const onClickAllSido = () => {
        setSelectedSigungu(null);
        setSelectedSido(null);
        setStateLandFilter({ ...stateLandFilter, location: [] });
    };

    //* 시도 선택
    const onClickSido = (item: IRegion, active: boolean) => {
        setSelectedSido(item);
        let newLoc: string[] = [];
        if (active) {
            // newLoc = [...stateLandFilter.location].filter(
            //     (v) => !v.startsWith(item.cortarNo.slice(0, 2)),
            // );
            // setStateLandFilter({
            //     ...stateLandFilter,
            //     location: [...newLoc],
            // });
        } else {
            newLoc = [...stateLandFilter.location, item.cortarNo];
            setStateLandFilter({
                ...stateLandFilter,
                location: [...newLoc],
            });
        }
        !newLoc.length && sidoRef.current.scrollTo(0, 0);
    };

    //* 시군구 전체 선택
    const onClickAllSigungu = () => {
        if (stateLandFilter.location.some((val) => val === selectedSido?.cortarNo)) {
            // const newLoc = [...stateLandFilter.location].filter(
            //     (v) => v !== selectedSido?.cortarNo,
            // );
            // setStateLandFilter({
            //     ...stateLandFilter,
            //     location: [...newLoc],
            // });
            // setSelectedSido(null);
            // setSelectedSigungu(null);
        } else {
            const newLoc = [
                ...stateLandFilter.location.filter((v) => !v.startsWith(selectedSido?.cortarNo.slice(0, 2))),
                selectedSido?.cortarNo,
            ];
            if (newLoc.length > 10) {
                onSubmitShowToast();
                return;
            }
            setStateLandFilter({
                ...stateLandFilter,
                location: [...newLoc],
            });
            setSelectedSigungu(null);
        }
    };

    //* 시군구 선택
    const onClickSigungu = (item: IRegion, active: boolean) => {
        setSelectedSigungu(item);
        if (active) {
            // const newLoc = [
            //     ...stateLandFilter.location,
            // ].filter(
            //     (v) =>
            //         !v.startsWith(
            //             item.cortarNo.slice(0, 5),
            //         ),
            // );
            // !newLoc.some((v) =>
            //     v.startsWith(
            //         selectedSido?.cortarNo?.slice(
            //             0,
            //             2,
            //         ),
            //     ),
            // ) && setSelectedSigungu(null);
            // setStateLandFilter({
            //     ...stateLandFilter,
            //     location: [...newLoc],
            // });
        } else {
            const newLoc = [...stateLandFilter.location.filter((v) => v !== selectedSido?.cortarNo), item.cortarNo];
            const checkMajor = item.cortarNo.slice(0, 2);
            const dulplicateCheck = new Set(newLoc.map((v) => v.slice(0, 5)));
            const checkActiveList = Array.from(dulplicateCheck).filter(
                (val) => val.startsWith(checkMajor) && val.slice(0, 5) !== selectedSido?.cortarNo.slice(0, 5),
            );
            const listLengthCheck = sigunguList.length === checkActiveList.length;
            if (listLengthCheck) {
                const changeLoc = [...stateLandFilter.location.filter((v) => !v.startsWith(checkMajor)), selectedSido?.cortarNo];
                if (changeLoc.length > 10) {
                    onSubmitShowToast();
                    return;
                }
                setStateLandFilter({
                    ...stateLandFilter,
                    location: [...changeLoc],
                });
            } else {
                if (newLoc.length > 10) {
                    onSubmitShowToast();
                    return;
                }
                setStateLandFilter({
                    ...stateLandFilter,
                    location: [...newLoc],
                });
            }
        }
    };

    //* 읍면동 전체 선택
    const onClickAllEmd = () => {
        if (stateLandFilter.location.some((val) => val === selectedSigungu?.cortarNo)) {
            const newLoc = [...stateLandFilter.location].filter((v) => v !== selectedSigungu?.cortarNo);
            setStateLandFilter({
                ...stateLandFilter,
                location: [...newLoc],
            });
            setSelectedSigungu(null);
        } else {
            const newLoc = [
                ...stateLandFilter.location.filter((v) => !v.startsWith(selectedSigungu?.cortarNo.slice(0, 5))),
                selectedSigungu?.cortarNo,
            ];
            setStateLandFilter({
                ...stateLandFilter,
                location: [...newLoc],
            });
        }
    };

    //* 읍면동 선택
    const onClickEmd = (item: IRegion, active: boolean) => {
        if (active) {
            const newLoc = [...stateLandFilter.location].filter((v) => v !== item.cortarNo);
            setStateLandFilter({
                ...stateLandFilter,
                location: [...newLoc],
            });
            !newLoc.some((v) => v.startsWith(selectedSigungu?.cortarNo?.slice(0, 5))) && setSelectedSigungu(null);
        } else {
            const newLoc = [...stateLandFilter.location.filter((v) => v !== selectedSigungu?.cortarNo), item.cortarNo];
            const checkMajor = item.cortarNo.slice(0, 5);
            const checkActiveList = newLoc.filter((val) => val.startsWith(checkMajor) && val !== selectedSigungu?.cortarNo);
            const listLengthCheck = emdList.length === checkActiveList.length;
            if (listLengthCheck) {
                const changeLoc = [
                    ...stateLandFilter.location.filter((v) => !v.startsWith(checkMajor)),
                    selectedSigungu?.cortarNo,
                ];
                if (changeLoc.length > 10) {
                    onSubmitShowToast();
                    return;
                }
                setStateLandFilter({
                    ...stateLandFilter,
                    location: [...changeLoc],
                });
            } else {
                if (newLoc.length > 10) {
                    onSubmitShowToast();
                    return;
                }
                setStateLandFilter({
                    ...stateLandFilter,
                    location: [...newLoc],
                });
            }
        }
    };

    useEffect(() => {
        if (stateLandFilter?.location?.length) {
            settingLocText();
        } else {
            setShowLocText([]);
        }
    }, [stateLandFilter?.location]);

    useEffect(() => {
        useFindLocation("0000000000").then((res) => {
            //* 지역별 국유지에 따른 시도 리스트
            const useList = !stateLandSubs?.allPnus
                ? res
                : res
                      ?.filter((q: any) => stateLandSubs?.allPnus?.some((pnu) => pnu.toString() === q.cortarNo?.slice(0, 2)))
                      .concat({
                          cortarName: "전국",
                          cortarNo: "0000000000",
                          centerLat: 0,
                          centerLon: 0,
                          cortarType: "sido",
                      })
                      .concat(
                          res?.filter(
                              (q: any) => !stateLandSubs?.allPnus?.some((pnu) => pnu.toString() === q.cortarNo?.slice(0, 2)),
                          ),
                      );
            setSidoList(useList);
            setSidoListCount(useList?.length);
            setSigunguList([]);
            setEmdList([]);
        });
    }, [stateLandSubs]);

    useEffect(() => {
        if (selectedSido) {
            useFindLocation(selectedSido.cortarNo).then((res) => {
                setSigunguList(res);
                setEmdList([]);
            });
        } else {
            setSigunguList([]);
        }
    }, [selectedSido]);

    useEffect(() => {
        if (selectedSigungu) {
            useFindLocation(selectedSigungu.cortarNo).then((res) => {
                setEmdList(res);
            });
        } else {
            setEmdList([]);
        }
    }, [selectedSigungu]);

    return (
        <>
            <StateLandFilterHeader title="소재지" />
            <Grid container css={subTitleBoxStyle}>
                <Grid item xs={4}>
                    <p className="subTitleText">시/도</p>
                </Grid>
                <Grid item xs={4.5}>
                    <p className="subTitleText">시/구/군</p>
                </Grid>
                <Grid item xs={3.5}>
                    <p className="subTitleText">동/읍/면</p>
                </Grid>
            </Grid>
            <Grid container css={selectBoxRootStyle}>
                <Grid item xs={4} css={selectBoxStyle} ref={sidoRef}>
                    {!stateLandSubs?.allPnus && (
                        <button
                            css={selectButtonStyle({
                                active: !stateLandFilter.location.length,
                                disabled: !!stateLandSubs?.allPnus,
                            })}
                            onClick={() => {
                                stateLandSubs?.allPnus ? onSubmitShowRegionModal() : onClickAllSido();
                            }}
                        >
                            전국
                        </button>
                    )}
                    {sidoList?.map((item: any, idx: number) => {
                        let checkActive: boolean = false;
                        if (stateLandFilter.location.length) {
                            checkActive = stateLandFilter.location.some((val) => val?.startsWith(item.cortarNo.slice(0, 2)));
                        }
                        const isDisabled =
                            !!stateLandSubs?.allPnus &&
                            !stateLandSubs?.allPnus?.some((pnu) => pnu.toString() === item.cortarNo?.slice(0, 2));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle({
                                    active: checkActive,
                                    disabled: stateLandSubs?.allPnus ? isDisabled : undefined,
                                })}
                                onClick={() =>
                                    !stateLandSubs?.allPnus
                                        ? onClickSido(item, checkActive)
                                        : isDisabled
                                        ? onSubmitShowRegionModal()
                                        : onClickSido(item, checkActive)
                                }
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
                <Grid item xs={4.5} css={selectBoxStyle}>
                    {!!sigunguList?.length && (
                        <button
                            css={selectButtonStyle({
                                active: stateLandFilter.location.some((val) => val === selectedSido?.cortarNo),
                            })}
                            onClick={onClickAllSigungu}
                        >
                            전체
                        </button>
                    )}
                    {sigunguList?.map((item: any) => {
                        const activeListLengthCheck = stateLandFilter.location.some((val) => val === selectedSido?.cortarNo);
                        let checkActive: boolean = true;
                        activeListLengthCheck
                            ? (checkActive = false)
                            : (checkActive = stateLandFilter.location.some(
                                  (val) => val.slice(0, 5) === item.cortarNo.slice(0, 5),
                              ));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle({ active: checkActive })}
                                onClick={() => onClickSigungu(item, checkActive)}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
                <Grid item xs={3.5} css={selectBoxStyle}>
                    {!!emdList?.length && (
                        <button
                            css={selectButtonStyle({
                                active: stateLandFilter.location.some((val) => val === selectedSigungu?.cortarNo),
                            })}
                            onClick={onClickAllEmd}
                        >
                            전체
                        </button>
                    )}
                    {emdList?.map((item: any) => {
                        const activeListLengthCheck = stateLandFilter.location.some((val) => val === selectedSigungu?.cortarNo);
                        let checkActive: boolean = true;
                        activeListLengthCheck
                            ? (checkActive = false)
                            : (checkActive = stateLandFilter.location.some((val) => val === item.cortarNo));
                        return (
                            <button
                                key={item.cortarNo}
                                css={selectButtonStyle({ active: checkActive })}
                                onClick={() => onClickEmd(item, checkActive)}
                            >
                                {item.cortarName}
                            </button>
                        );
                    })}
                </Grid>
            </Grid>

            <div css={rootBoxStyle}>
                <StateLandFilterHeader title="선택한 소재지" />
                <div css={summaryBoxStyle}>
                    {showLocText.length ? (
                        showLocText.map((locData: any) => (
                            <SelectedLocationValue
                                value={locData.name}
                                onDeleteValue={() => {
                                    const newLoc = [...stateLandFilter.location].filter((val) => val !== locData.value);
                                    setStateLandFilter({
                                        ...stateLandFilter,
                                        location: [...newLoc],
                                    });
                                }}
                                key={locData.name}
                            />
                        ))
                    ) : (
                        <p className="noData">전체 (선택한 소재지가 없습니다)</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default StateLandLocationFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
    padding-bottom: 24px;
    box-sizing: border-box;
`;

const summaryBoxStyle = css`
    padding: 14px;
    display: flex;
    align-items: center;
    width: 100vw;
    max-height: 140px;
    box-sizing: border-box;
    height: max-content;
    gap: 8px;
    overflow-x: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    & > .noData {
        color: #8d8d8d;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    &::-webkit-scrollbar {
        border-radius: 4px;
        width: 6px;
        height: 6px;
        margin-right: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: #f0f0f0;
    }
`;

const subTitleBoxStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 0.6px solid #b1aeb8;
    font-size: 14px;
    .subTitleText {
        padding: 8px 14px;
        background-color: #ffffff;
        color: #0c002c;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        box-sizing: border-box;
        width: 100%;
    }
`;

const selectBoxRootStyle = css`
    display: flex;
    align-items: center;
    border-bottom: 0.6px solid #b1aeb8;
`;

const selectBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 12px 10px;
    gap: 8px;
    height: 230px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        border-radius: 4px;
        width: 4px;
        height: 4px;
        margin-right: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: #f0f0f0;
    }
`;

const selectButtonStyle = ({ active, disabled }: { active: boolean; disabled?: boolean }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    background: #f0f0f0;
    transition: color 0.3s linear;
    font-size: 14px;
    font-weight: 500;
    background: ${disabled ? "#dadada" : active ? "#FF6933" : "#f0f0f0"};
    color: ${disabled ? "#8d8d8d" : active ? "#ffffff" : "inherit"};
    font-weight: ${disabled ? "400" : "500"};
    :disabled {
        background-color: #dadada;
        color: #8d8d8d;
        font-weight: 400;
    }
`;
