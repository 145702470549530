import { css } from "@emotion/react";

export const dialogTitleStyle = css`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
`;

export const dialogContentStyle = css`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 18px;
`;
