import React, { Fragment } from "react";
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import { useStateLandFilterStore } from "@src/store/stateLandFilter";
import StateLandFilterHeader from "./StateLandFilterHeader";

interface Props {}

export const managementFilterList = [
    { title: "전체", value: "" },
    { title: "매각대상", value: "매각대상" },
    { title: "대부대상", value: "대부대상" },
    { title: "대부 사용중", value: "대부 사용중" },
    { title: "매각제한", value: "매각제한" },
];

const AssetManagementFilter: React.FC<Props> = () => {
    const stateLandFilter = useStateLandFilterStore((state) => state.stateLandFilter);
    const setStateLandFilter = useStateLandFilterStore((state) => state.setStateLandFilter);

    const onClickFilter = (filter: string) => {
        if (stateLandFilter.status.some((v) => v === filter)) {
            const newOwnedBy = [...stateLandFilter.status].filter((owned) => owned !== filter);
            setStateLandFilter({
                ...stateLandFilter,
                status: newOwnedBy,
            });
        } else {
            const newOwnedBy = [...stateLandFilter.status, filter];
            newOwnedBy.length === 4
                ? setStateLandFilter({ ...stateLandFilter, status: [] })
                : setStateLandFilter({
                      ...stateLandFilter,
                      status: newOwnedBy,
                  });
        }
    };
    return (
        <>
            <StateLandFilterHeader title="일반재산 관리상태" />
            {stateLandFilter.type.includes("일반재산 외") && <p css={redTextStyle}>분류에서 “일반재산”을 선택해주세요</p>}
            <Grid container css={filterBoxStyle} spacing={1}>
                {managementFilterList.map((filter, idx) => (
                    <Grid item xs={4} key={filter.title}>
                        <button
                            css={filterButtonStyle({
                                active: idx
                                    ? stateLandFilter.status?.some((val) => val === filter.title)
                                    : !stateLandFilter.status.length,
                                idx: idx,
                            })}
                            onClick={() => {
                                idx
                                    ? onClickFilter(filter.title)
                                    : setStateLandFilter({
                                          ...stateLandFilter,
                                          status: [],
                                      });
                            }}
                            disabled={stateLandFilter.type.includes("일반재산 외")}
                        >
                            {filter.title}
                        </button>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default AssetManagementFilter;

const filterButtonStyle = ({ active, idx }: { active: boolean; idx: number }) => css`
    padding: 8px 4px;
    border-radius: 4px;
    background-color: ${active ? (idx === 1 ? "#FFF500" : idx === 2 ? "#FFF500" : "#FF6933") : "#F0F0F0"};
    color: ${active && !/(1|2)/.test(idx.toString()) ? "#ffffff" : "#0c002c"};
    border: ${!idx ? "2px solid inherit" : idx <= 2 ? "2px solid #fff500" : "2px solid #ff6933"};
    font-weight: ${active ? 700 : 400};
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    :disabled {
        background-color: #dadada;
        color: #8d8d8d;
        font-weight: 400;
    }
`;

const filterBoxStyle = css`
    width: 100%;
    padding: 0 14px 24px;
`;

const redTextStyle = css`
    padding: 0 14px 8px;
    color: #f00000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`;
